import * as React from 'react';
import {IInformSummary} from "../../model/inform/InformSummary";
import {getSubcomponentsRating, IInformSubcomponentRating} from "./informUtil";

export interface IInformSubcomponentsRatingTable {
    summary: IInformSummary;
}

export enum InformSubcomponentsRating {
    BUSINESS = "Business",
    INVESTMENT_STAFF = "Staff",
    INVESTMENT_PROCESS = "Process",
    RISK_MANAGEMENT = "Risk",
    PERFORMANCE = "Perf",
    TERMS_CONDITIONS = "Fee"
}

export const InformSubcomponentsRatingChanges: React.FunctionComponent<IInformSubcomponentsRatingTable> = (props) => {
    const renderTableHeader = () => {
        return <thead>
        <tr className="inform-rating-header">
            <th className="inform-rating-column-left">Subcomponent</th>
            <th>Upgrades</th>
            <th>Downgrades</th>
        </tr>
        </thead>;
    };

    const getRatingUpgradedValue =
        (subComponent: string, subComponentsRating: Map<string, IInformSubcomponentRating>) => {
            const rating = subComponentsRating.get(subComponent);
            return rating ? rating.upgraded : "---";
    };

    const getRatingDowngradedValue =
        (subComponent: string, subComponentsRating: Map<string, IInformSubcomponentRating>) => {
            const rating = subComponentsRating.get(subComponent);
            return rating ? rating.downgraded : "---";
    };

    const renderTableBody = () => {
        const rating = getSubcomponentsRating(props.summary);
        return <tbody>
        <tr className="inform-rating-row">
            <td className="inform-rating-row-header">Business</td>
            <td className="inform-rating-value">{getRatingUpgradedValue(InformSubcomponentsRating.BUSINESS, rating)}</td>
            <td>{getRatingDowngradedValue(InformSubcomponentsRating.BUSINESS, rating)}</td>
        </tr>
        <tr className="inform-rating-row">
            <td className="inform-rating-row-header">Investment Staff</td>
            <td>{getRatingUpgradedValue(InformSubcomponentsRating.INVESTMENT_STAFF, rating)}</td>
            <td>{getRatingDowngradedValue(InformSubcomponentsRating.INVESTMENT_STAFF, rating)}</td>
        </tr>
        <tr className="inform-rating-row">
            <td className="inform-rating-row-header">Investment Process</td>
            <td>{getRatingUpgradedValue(InformSubcomponentsRating.INVESTMENT_PROCESS, rating)}</td>
            <td>{getRatingDowngradedValue(InformSubcomponentsRating.INVESTMENT_PROCESS, rating)}</td>
        </tr>
        <tr className="inform-rating-row">
            <td className="inform-rating-row-header">Risk Management</td>
            <td>{getRatingUpgradedValue(InformSubcomponentsRating.RISK_MANAGEMENT, rating)}</td>
            <td>{getRatingDowngradedValue(InformSubcomponentsRating.RISK_MANAGEMENT, rating)}</td>
        </tr>
        <tr className="inform-rating-row">
            <td className="inform-rating-row-header">Performance</td>
            <td>{getRatingUpgradedValue(InformSubcomponentsRating.PERFORMANCE, rating)}</td>
            <td>{getRatingDowngradedValue(InformSubcomponentsRating.PERFORMANCE, rating)}</td>
        </tr>
        <tr className="inform-rating-row">
            <td className="inform-rating-row-header">Terms & Conditions</td>
            <td>{getRatingUpgradedValue(InformSubcomponentsRating.TERMS_CONDITIONS, rating)}</td>
            <td>{getRatingDowngradedValue(InformSubcomponentsRating.TERMS_CONDITIONS, rating)}</td>
        </tr>
        </tbody>;
    };

    return <table data-testid="inform-subcomponent-rating-changes-table">
        {renderTableHeader()}
        {renderTableBody()}
    </table>;
};