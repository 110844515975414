import * as chroma from "chroma-js";
import * as d3 from "d3";
import {ScaleOrdinal} from "d3-scale";
import {ODD_CHART_VERTICAL_BAR_CHART_COLOR_SCALE} from "../../../css/Colors";

export interface IBarChartData {
    key: string;
    data: Record<string, number>;
}

export function generateColorScale(keys: string[], colors: string[]): ScaleOrdinal<string, any> {
    return d3.scaleOrdinal()
        .domain(keys)
        .range(chroma.scale(colors).colors(keys.length));
}

export function colorOddBarChart(keys: string[]): ScaleOrdinal<string, any> {
    return generateColorScale(keys, ODD_CHART_VERTICAL_BAR_CHART_COLOR_SCALE);
}

export function formatCurrencyTicks(value: number, sigFigs: number, includeDollarSign: boolean = false): string {
    const specifier = `${includeDollarSign ? "$" : ""}.${sigFigs}s`;
    const d3Format = d3.format(specifier);
    return d3Format(value).toUpperCase().replace("G", "B");
}