import * as React from "react";
import {useEffect} from "react";
import {IReportConfig} from "../../api/PowerBiReportingApi";
import {getSuccessData, REQUEST_STATES, RequestState} from "../common/commonStates";
import {LoadingSpinner} from "../icons/LoadingSpinner.component";
import {IconButton, Tooltip} from "@mui/material";
import {powerBiEmbedReport, powerBiFullScreen, powerBiPrint, powerBiReset} from "../../utils/powerBiUtil";
import {PowerBiExport} from "./PowerBiExport";
import {FormattedMessage} from "react-intl";

export interface IPowerBiReportProps {
    reportConfig: RequestState<IReportConfig>;
    displayPrintLink: boolean;
    clientReportId?: number;
}

export const REPORT_CONTAINER_ID = "powerbi-report-container";
export const PowerBiReportComponent: React.FunctionComponent<IPowerBiReportProps> = (props: IPowerBiReportProps) => {
    const embedReport = () => {
        const reportConfig: IReportConfig | null = getSuccessData(props.reportConfig);

        if (props.reportConfig.kind !== REQUEST_STATES.REQUEST_FAILED && reportConfig && reportConfig.embedUrl) {
            powerBiEmbedReport(REPORT_CONTAINER_ID, reportConfig);
        } else {
            powerBiReset(REPORT_CONTAINER_ID);
        }
    };

    useEffect(() => {
        embedReport();
    }, [props.reportConfig]);

    const printReport = () => {
        powerBiPrint(REPORT_CONTAINER_ID);
    };

    const renderError = () => {
        return props.reportConfig.kind === REQUEST_STATES.REQUEST_FAILED
                ? <div id="power-bi-report__error">Failed</div> : null;
    };

    const renderPrintLink = () => {
        return props.reportConfig.kind !== REQUEST_STATES.REQUEST_FAILED && props.displayPrintLink
            ? <div id="power-bi-report__print" onClick={printReport} className="link-16 clickable"> Print Report</div>
            : null;
    };

    const showFullScreen = () => {
        powerBiFullScreen(REPORT_CONTAINER_ID);
    };

    function showExportButton() {
        return (props.reportConfig.kind === REQUEST_STATES.REQUEST_SUCCEEDED)
            ? props.reportConfig.data.allowExport
            : false;
    }

    const renderFullScreenAndExportButtons = () => {
        return props.reportConfig.kind !== REQUEST_STATES.REQUEST_FAILED
            ? <div className="powerbi-report__report-buttons">
                <PowerBiExport reportContainerId = {REPORT_CONTAINER_ID}
                               clientReportId = {props.clientReportId}
                               showExportButton={showExportButton()}
                />
                <Tooltip
                    title={<FormattedMessage id="client-reporting.full-screen" defaultMessage="Full Screen"/>}
                         PopperProps={{
                             disablePortal: true,
                         }}>
                    <IconButton
                        id="powerbi-report__full-screen-button"
                        tabIndex={0}
                        onClick={showFullScreen}>
                        <div className="full-screen-icon fa-regular fa-expand"/>
                    </IconButton>
                </Tooltip>

        </div>
            : null;
    };

    const renderLoadingSpinner = () => {
        return props.reportConfig.kind === REQUEST_STATES.REQUESTED
            ? <LoadingSpinner />
            : null;
    };

    return <div data-testid="powerbi-report__container">
        {renderLoadingSpinner()}
        {renderError()}
        {renderPrintLink()}
        {renderFullScreenAndExportButtons()}
        <div id={REPORT_CONTAINER_ID}/>
    </div>;
};

