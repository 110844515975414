import {capitalizeFirstLetter} from "../utils/commonUtil";

export enum UserTypeEnum {
    CLIENT = "CLIENT",
    CONSULTANT = "CONSULTANT",
    ADMIN = "ADMIN",
    THIRD_PARTY = "THIRD_PARTY",
    UNKNOWN = "UNKNOWN",
    ATC_ADMIN = "ATC_ADMIN",
}

export const getUserTypes = () => {
    return Object.keys(UserTypeEnum)
        .filter((type) => (UserTypeEnum[type as keyof typeof UserTypeEnum] !== UserTypeEnum.UNKNOWN))
        .map((type) => ({name: capitalizeFirstLetter(UserTypeEnum[type as keyof typeof UserTypeEnum]), id: null}));
};

export interface IUserInfo {
    firstName: string;
    lastName: string;
    email: string;
    lastLoginDate: string;
    userType: UserTypeEnum;
    arbtSecurityId: string;
    planIds: number[];
}

export class UserInfo implements IUserInfo {
    constructor(
        public firstName: string,
        public lastName: string,
        public email: string,
        public lastLoginDate: string,
        public userType: UserTypeEnum,
        public arbtSecurityId: string,
        public planIds: number[]) {}
}

