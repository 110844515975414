import * as React from "react";
import {OperationalDueDiligenceApi} from "../../../api/OperationalDueDiligenceApi";
import {OperationalDueDiligenceDocument} from "../../../model/OperationalDueDiligenceDocument.model";

export interface IReportProps {
    productBackstopId: number;
    oddProductDocument: OperationalDueDiligenceDocument | null;
}

export const ReportComponent: React.FunctionComponent<IReportProps> = ({oddProductDocument, productBackstopId}) => {
    function download(item: OperationalDueDiligenceDocument) {
        OperationalDueDiligenceApi.getOddDocumentDownload (
            productBackstopId, item.backstopId, makeNewFileName(item), true);
    }

    function makeNewFileName(oddDocument: OperationalDueDiligenceDocument) {
        const filename = oddDocument!.filename!.toString();
        return oddDocument.title + filename.substring(filename.lastIndexOf("."));
    }

    return oddProductDocument
        ? <div id="odd_report__button" data-testid="odd_report__button">
                <a className="odd-product__report-download-link clickable"  onClick={() => download(oddProductDocument)}>
                    View Report
                </a>
            </div>
        : null;
};

ReportComponent.displayName = "ReportComponent";
