import {AxiosResponse} from "axios";
import {Map} from "immutable";

import {ISelectValue} from "../components/common/Select.component";
import {mapProduct, mapProductSummary} from "../components/product/ProductMapper";
import {Product} from "../model/product/Product.model";
import {ProductSummary} from "../model/product/ProductSummary";
import {ProductTicker} from "../model/product/ProductTicker";
import {ApiBase} from "./ApiBase";
import {IApiResult} from "./AxiosWealthManagementApi";

export default class ProductApi {
    public static requestPortfolioProductSummariesById(): Promise<IApiResult<Map<number, ProductSummary>>> {
        return ApiBase.processGet("/product-summaries/portfolio",
            (response: AxiosResponse) => {
                return (Object as any).entries(response.data)
                    .reduce(this.productSummaryEntryReducer, Map<number, ProductSummary>());
            });
    }

    public static requestProductUniverseSummariesById(): Promise<IApiResult<Map<number, ProductSummary>>> {
        return ApiBase.processGet("/product-summaries/universe",
            (response: AxiosResponse) => {
                return (Object as any).entries(response.data)
                    .reduce(this.productSummaryEntryReducer, Map<number, ProductSummary>());
            });
    }

    public static requestProduct(backstopId: number): Promise<Product> {
            return ApiBase.processGetUnwrapped(
                "/v2/products/" + backstopId,
                (response: AxiosResponse) => mapProduct(response.data),
            );
    }

    public static requestProductTickers(backstopId: number): Promise<ProductTicker[]> {
            return ApiBase.processGetUnwrapped(
                `/products/${backstopId}/tickers`,
                (response: AxiosResponse) => {
                    return response.data;
                });
    }

    public static requestShareClasses(): Promise<ISelectValue[]> {
            return ApiBase.processGetUnwrapped(
                "/share-classes",
                (response: AxiosResponse) => {
                    return response.data;
                });
    }

    public static getProductByTicker = (ticker: string): Promise<number | null> => {
        return ApiBase.processGetUnwrapped(
            `/tickers/${ticker}/product`,
                (response: AxiosResponse<string>) => {
                    if (response.data.length === 0) {
                       return null;
                    } else {
                       return Number.parseInt(response.data, 10);
                    }
                });
    };

    public static removeProductTicker(productId: number, productTickerId: number): Promise<void> {
        return ApiBase.processDelete(`/products/${productId}/tickers/${productTickerId}`)
            .then(() => Promise.resolve());
    }

    public static addProductTicker = (productBackstopId: number, ticker: string, shareClassId: string):
        Promise<void> => {
        return ApiBase.processPost(`/products/${productBackstopId}/ticker`, {ticker, shareClassId})
            .then(() => Promise.resolve());
    };

    private static productSummaryEntryReducer =
        (all: Map<number, ProductSummary>, [key, value]: (any)[]) => {
        return all.set(parseInt(key, 10), mapProductSummary(value));
    };
}
