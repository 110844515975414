import {Dispatch} from "redux";
import {Action} from "redux-actions";
import {CalendarApi, ICalendarMeeting} from "../../api/CalendarApi";
import {Map} from "immutable";

export enum CalendarActions {
    CALENDAR_DATA_REQUESTED = "CALENDAR_DATA_REQUESTED",
    CALENDAR_DATA_REQUEST_SUCCEEDED = "CALENDAR_DATA_REQUEST_SUCCEEDED",
    CALENDAR_DATA_REQUEST_FAILED = "CALENDAR_DATA_REQUEST_FAILED",
    CLEAR_CALENDAR_DATA = "CLEAR_CALENDAR_DATA",
    CURRENT_CALENDAR_YEAR = "CALENDAR_CURRENT_YEAR",
    CALENDAR_MEETINGS_UPDATED = "CALENDAR_MEETINGS_UPDATED"
}

export function requestCalendarData(dispatch: Dispatch<Action<Map<string, ICalendarMeeting>>>) {
    dispatch({type: CalendarActions.CALENDAR_DATA_REQUESTED});

    return CalendarApi.requestCalendarMeetingsWithTopics()
        .then((response: Map<string, ICalendarMeeting>) => {
            dispatch({
                type: CalendarActions.CALENDAR_DATA_REQUEST_SUCCEEDED,
                payload: response,
            });
        }).catch((error) => {
            dispatch({
                type: CalendarActions.CALENDAR_DATA_REQUEST_FAILED,
                payload: error,
            });
        });
}

export function clearCalendarData(dispatch: Dispatch) {
    dispatch({
        type: CalendarActions.CLEAR_CALENDAR_DATA,
    });
}

export function setCalendarCurrentYear(dispatch: Dispatch, payload: number | undefined) {
    dispatch({
        type: CalendarActions.CURRENT_CALENDAR_YEAR,
        payload
    });
}

export function updateCalendarMeetings(dispatch: Dispatch, payload: Map<string, ICalendarMeeting> | undefined) {
    dispatch( {
        type: CalendarActions.CALENDAR_MEETINGS_UPDATED,
        payload
    });
}