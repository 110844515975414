import * as React from "react";
import {useState} from "react";
import {UploadHoldings} from "../investments/upload/UploadHoldings";
import {UploadHoldingsCashflow} from "../investments/upload/UploadHoldingCashflow.component";
import {LoadingSpinner} from "../icons/LoadingSpinner.component";
import {UploadOdd} from "../odd-iq/UploadOdd";
import {TimerComponent} from "../common/Timer.component";
import {UploadOddSec} from "../odd-iq/UploadOddSec";
import {withVerifyAccess} from "../common/withVerifyAccess";
import {UserTypeEnum} from "../../model/UserInfo.model";

export interface IUploadComponentProps {
    isLoading: boolean;
    setIsLoading: React.Dispatch<React.SetStateAction<boolean>>;
}

export const AdminUploadPage: React.FunctionComponent = () => {
    const [isLoadingInvestments, setIsLoadingInvestments] = useState<boolean>(false);

    return <div>
            <h3>Load Investments data</h3>
            <TimerComponent isRunning={isLoadingInvestments}/>
            {isLoadingInvestments ? <LoadingSpinner/> : null}
            <UploadHoldings isLoading={isLoadingInvestments} setIsLoading={setIsLoadingInvestments}/>
            <br/>
            <UploadHoldingsCashflow isLoading={isLoadingInvestments} setIsLoading={setIsLoadingInvestments}/>
            <br/>
            <UploadOdd/>
            <br/>
            <UploadOddSec/>
    </div>;
};

export default withVerifyAccess(AdminUploadPage, UserTypeEnum.ADMIN);
