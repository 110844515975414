import * as React from "react";

export class LoadingComponent extends React.Component<{}, any> {
    public constructor(props: any) {
        super(props);
    }

    public render() {
        return <div className="wealth-parent main-content" data-testid="loading-component">Loading...</div>;
    }
}
