import * as React from "react";

export interface ITooltipProps {
    label?: string;
    iconClass?: string;
    hoverTitle?: string;
    hoverText: string[];
}

export const Tooltip: React.FunctionComponent<ITooltipProps> = (props) => {
    const renderHoverText = () => {
        const hoverText = props.hoverText
            .map((line, index) => <ul key={index}>{line}</ul>);

        return <span className="tooltip__hover" data-testid="tooltip__hover">
                {props.hoverTitle && <div className="tooltip__hover-header" data-testid="tooltip__hover-header">
                    {props.hoverTitle}
                </div>}
            <div>{hoverText}</div>
            </span>;
    };

    return <span className="tooltip__container" data-testid="tooltip__container">
            {props.iconClass && <i className={props.iconClass} data-testid="tooltip__icon"/>}
        {props.label && <div>{props.label}</div>}
        {renderHoverText()}
    </span>;
};