import * as React from "react";
import {useEffect, useState} from "react";
import {UserTypeEnum} from "../../model/UserInfo.model";
import {withVerifyAccess} from "../common/withVerifyAccess";
import {ClientApi, IClient} from "../../api/ClientApi";
import {LoadingSpinner} from "../icons/LoadingSpinner.component";
import {AdminCreateClientComponent} from "./AdminCreateClient.component";
import {History} from "history";

export interface IAdminEditClientPageProps {
    match: {
        params: {
            clientId: string;
        };
    };
    history: History;
}
export const AdminEditClientPage: React.FunctionComponent<IAdminEditClientPageProps> =
    (props: IAdminEditClientPageProps) => {

    const [client, setClient] = useState<IClient | undefined>(undefined);

    const clientId = Number.parseInt(props.match.params.clientId, 10);

    useEffect(() => {
        ClientApi.requestClient(clientId).then((response) => {
            setClient(response);
        });
    }, [props]);

    const cancelEdit = () => {
        props.history.push("/admin-client-plan");
    };

    const renderClient = () => {
        return client
            ? <div>
                <h2 className="admin-edit-client-page__client-name">{client.name}</h2>
                <AdminCreateClientComponent
                    onCancel={cancelEdit}
                    client={client}
                />
            </div>
            : <LoadingSpinner/>;
    };

    return <div className="page-container new-common-styles admin-edit-client-page__container">
        <h1 className="admin-edit-client-page__header">Edit Client</h1>
        {renderClient()}
    </div>;
};

export default withVerifyAccess(AdminEditClientPage, UserTypeEnum.ADMIN);