import {IHolding} from "../../api/HoldingsApi";
import {IQuarterAndYear, Quarter} from "../../utils/dateUtil";

export class Holding implements IHolding {
    public static builder(): HoldingBuilder {
        return new HoldingBuilder();
    }

    public constructor(
        public readonly name: string,
        public readonly assets: number,
        public readonly assetClass: string,
        public readonly productId: number | null,
        public readonly cashflow: number,
        public readonly inflow: number,
        public readonly outflow: number,
        public readonly gains: number,
        public readonly asOfDate: Date,
        public readonly quarterAndYear: IQuarterAndYear,
        public readonly tier: string,
        public readonly tierId: number) {

    }
}

class HoldingBuilder {
    private mName: string;
    private mAssets: number;
    private mAssetClass: string;
    private mProductId: number | null;
    private mCashflow: number;
    private mInflow: number;
    private mOutflow: number;
    private mGains: number;
    private mAsOfDate: Date;
    private mQuarterAndYear: IQuarterAndYear;
    private mTier: string;
    private mTierId: number;

    public name(value: string) {
        this.mName = value;
        return this;
    }

    public assets(value: number) {
        this.mAssets = value;
        return this;
    }

    public assetClass(value: string) {
        this.mAssetClass = value;
        return this;
    }

    public productId(value: number | null) {
        this.mProductId = value;
        return this;
    }

    public cashflow(value: number) {
        this.mCashflow = value;
        return this;
    }

    public inflow(value: number) {
        this.mInflow = value;
        return this;
    }

    public outflow(value: number) {
        this.mOutflow = value;
        return this;
    }

    public gains(value: number) {
        this.mGains = value;
        return this;
    }

    public asOfDate(value: Date) {
        this.mAsOfDate = value;
        return this;
    }

    public quarterAndYear(value: IQuarterAndYear) {
        this.mQuarterAndYear = value;
        return this;
    }

    public tier(value: string) {
        this.mTier = value;
        return this;
    }

    public tierId(vlue: number) {
        this.mTierId = vlue;
        return this;
    }

    public build(): Holding {
        return new Holding(
            this.mName || "",
            this.mAssets || 0,
            this.mAssetClass || "",
            this.mProductId || null,
            this.mCashflow || 0,
            this.mInflow || 0,
            this.mOutflow || 0,
            this.mGains || 0,
            this.mAsOfDate || new Date(1,1,2001),
            this.mQuarterAndYear || {
            quarter: Quarter.Q1,
            year: 0},
            this.mTier || "Target Date Funds",
            this.mTierId || 1
        );
    }
}
