import * as React from "react";
import { ICalendarMeeting, ICalendarQuarter } from "../../api/CalendarApi";
import {getCalendarQuarters} from "./CalendarUtils";
import {CalendarProps, ICalendarFromStoreProps} from "./CalendarDragDrop";
import {getSuccessData} from "../common/commonStates";
import {calendarMeetingsSelector} from "../../../mainReducerMapSelectors";
import {connect, useDispatch} from "react-redux";
import {multipleApiRequesterWrapper} from "../common/MultipleApiRequesterWrapper";
import {calendarMeetingsPair} from "../common/RequesterPairs";
import {setCalendarCurrentYear} from "./Calendar.actions";
import {CalendarQuarter} from "./CalendarQuarter";
import {IApplicationRootState} from "../../../applicationState";

export const CalendarForClient: React.FunctionComponent<CalendarProps> = (props) => {
    const calendarYears = [props.currentYear, props.currentYear+1, props.currentYear+2];
    const calendarQuarters = getCalendarQuarters(calendarYears);

    const dispatch = useDispatch();

    const getQuarterMeeting = (quarter: ICalendarQuarter): ICalendarMeeting | undefined => {
        return props.calendarMeetings && props.calendarMeetings.has(quarter.key)
            ? props.calendarMeetings.get(quarter.key)
            : undefined;
    };

    const renderQuarter = (quarter: ICalendarQuarter) => {
        return <CalendarQuarter
                          key={quarter.key}
                          quarter={quarter}
                          calendarMeeting={getQuarterMeeting(quarter)}
                          onViewChange={props.onViewChange} />;
    };

    const renderYear = (year: number) => {
        return <div className="client-calendar__year" key={year}>
            <p className="client-calendar__year-header">{year}</p>
            {
                calendarQuarters
                    .filter((quarter) => quarter.year === year)
                    .sort((a, b) => a.quarterNumber - b.quarterNumber)
                    .map(renderQuarter)
            }
        </div>;
    };

    const prevYear = () => {
        setCalendarCurrentYear(dispatch, props.currentYear-1);
    };

    const nextYear = () => {
        setCalendarCurrentYear(dispatch, props.currentYear+1);
    };

    return  <div className="calendar__grid">
        <div className="clickable client-calendar__years-scroll-left" onClick={prevYear}>{"<"}</div>
        {calendarYears.map(renderYear)}
        <div className="clickable client-calendar__years-scroll-right" onClick={nextYear}>{">"}</div>
    </div>;
};

export const mapStateToProps = (state: IApplicationRootState): ICalendarFromStoreProps => {
    return {
        calendarMeetings: getSuccessData(calendarMeetingsSelector(state))!,
    };
};

const connectedComponent = connect<ICalendarFromStoreProps>(mapStateToProps)(CalendarForClient);

export default multipleApiRequesterWrapper(
    connectedComponent, [
        calendarMeetingsPair
    ]
);