import * as React from "react";
import {useEffect, useState} from "react";
import {hasConsultantLevelAccess, isClientUser, isThirdPartyUser} from "../../utils/sessionUtil";
import {
    clientReportingEnabled,
    hasClientReports,
    performanceReportingEnabled,
    powerBiClientReportsSelector,
    powerBiReportCategorySelector
} from "../../../mainReducerMapSelectors";
import {connect, useDispatch} from "react-redux";
import {ErrorComponent} from "../base/Error.component";
import {NoAccessNotification} from "../base/header/NoAccessNotification";
import PerformanceReportingTab from "./PerformanceReportingTab";
import {ClientReporting} from "./ClientReporting";
import {multipleApiRequesterWrapper} from "../common/MultipleApiRequesterWrapper";
import {powerBiClientReportsPair} from "../common/RequesterPairs";
import {getSuccessData} from "../common/commonStates";
import {setCurrentCategory} from "./PowerBiReporting.actions";
import {handleEnter} from "../../utils/commonUtil";
import { ClientReport } from "src/main/model/ClientReport.model";
import {FormattedMessage} from "react-intl";
import {IApplicationRootState} from "../../../applicationState";

export interface IPowerBiReportingPagePropsFromStore {
    clientReportingEnabled: boolean;
    hasClientReports: boolean;
    performanceReportingEnabled: boolean;
    clientReports: ClientReport[];
    currentTab?: string;
}

export enum ClientReportingTabs {
    PERFORMANCE_TAB = "PERFORMANCE REPORTING",
    ESG_TAB = "ESG REPORTING",
    TDF_TAB = "TDF REPORTING"
}

export const PowerBiReportingPage: React.FunctionComponent<IPowerBiReportingPagePropsFromStore> = (props) => {
    const[filteredReports, setFilteredReports] = useState<ClientReport[]>([]);
    const dispatch = useDispatch();

    const bySequence = (r1: any, r2: any) => r1.sequence - r2.sequence;

    const setCurrentTab = (category: string) =>
        dispatch(setCurrentCategory(category));

    const getSelected = (currentTab: string) => props.currentTab
        ? currentTab.toUpperCase() === props.currentTab.toUpperCase() ? "selected" : ""
        : "";

    const getOrderedCategories = (inReports: ClientReport[]) => {
        if(isThirdPartyUser()) return [];

        const unique = inReports.reduce((prev: { name: string, sequence: number }[], curr: ClientReport) => {
            const category = {name: curr.categoryName, sequence: curr.categorySequence};
            const find = prev.find((c) => c.name === category.name);
            if (!find) {
                return [...prev, category];
            } else {
                return prev;
            }
        }, []);

        return unique!.sort(bySequence);
    };

    const renderClientTab = (index: number, category: string) => {
        return <div  key={`category-${index}`}
                     className={`powerbi-reporting__tab clickable ${getSelected(category)}`}
                     data-testid="powerbi-reporting__tab"
                     tabIndex={0}
                     onKeyUp={handleEnter(() => setCurrentTab(category.toUpperCase()))}
                     onClick={() => setCurrentTab(category.toUpperCase())}>
            <FormattedMessage id={`client-reporting.${category}`} defaultMessage={category.toUpperCase()}/>
        </div>;
    };

    const renderPerformanceTab = () => {
        return props.performanceReportingEnabled
        ? <div className={`powerbi-reporting__tab clickable ${getSelected(ClientReportingTabs.PERFORMANCE_TAB)}`}
               data-testid="powerbi-reporting__tab"
               tabIndex={0}
               onKeyUp={handleEnter(() => setCurrentTab(ClientReportingTabs.PERFORMANCE_TAB))}
               onClick={() => setCurrentTab(ClientReportingTabs.PERFORMANCE_TAB)}>
                <FormattedMessage id="filters.performance-reports" defaultMessage="PERFORMANCE REPORTING"/>
        </div>
            : null;
    };

    const renderClientReportsTabs = (categories: {name: string; sequence: number }[]) => {
        return props.hasClientReports
            ? categories
                .map((category: { name: string; sequence: number }, index) =>
                    renderClientTab(index, category.name))
                : null;
    };

    const renderTabs = (categories: {name: string; sequence: number }[]) => {
        return <div className="powerbi-reporting__tabs-container">
            {renderPerformanceTab()}
            {renderClientReportsTabs(categories)}
        </div>;
    };

    const renderClientAccessNotification = () => {
        return hasConsultantLevelAccess() && !props.clientReportingEnabled
            ? <NoAccessNotification message={"The Client Reporting section is currently hidden from client users of this plan."} />
            : null;
    };

    const setDefaultTab = (reports: ClientReport[]) => {
        if (!props.currentTab) {
            if (props.performanceReportingEnabled) {
                setCurrentTab(ClientReportingTabs.PERFORMANCE_TAB);
            } else {
                const categories: { name: string; sequence: number }[] = props.hasClientReports && reports.length > 0
                    ? getOrderedCategories(reports)
                    : [];
                if (categories.length > 0) {
                    setCurrentTab(categories[0].name.toUpperCase());
                }
            }
        }
    };



    const renderCurrentTabComponent = () => {
        if(!props.currentTab) {
            return null;
        }

        if(props.currentTab === ClientReportingTabs.PERFORMANCE_TAB.toString()){
            return <PerformanceReportingTab/>;
        }

        else {
           return filteredReports && filteredReports.length > 0
               ? <ClientReporting currentTab={props.currentTab} clientReports={filteredReports}/>
               : null;
        }
    };

    const renderContent = () => {
        if (!props.performanceReportingEnabled && props.hasClientReports && filteredReports?.length === 0) {
            return null;
        }

        const categories: { name: string; sequence: number }[] = props.hasClientReports
            ? getOrderedCategories(filteredReports)
            : [];

        return <div>
            {renderTabs(categories)}
            <div className="powerbi-reporting__tab-component-container">
                {renderCurrentTabComponent()}
            </div>
        </div>;
    };

    const showClientReporting = () => {
        return (props.clientReportingEnabled || hasConsultantLevelAccess())
            && (props.performanceReportingEnabled || props.hasClientReports);
    };

    useEffect(() => {
        const filtered = isClientUser()
            ? props.clientReports.filter((r) => r.clientEnabled)
            : props.clientReports;
        setFilteredReports(filtered);

        if (props.hasClientReports) {
            setDefaultTab(filtered);
        } else {
            setDefaultTab([]);
        }
    }, [props]);

    return showClientReporting()
        ? <div className="new-common-styles">
            {renderClientAccessNotification()}
            <div className="page-container new-common-styles powerbi-reporting__page">
                <h1 className="powerbi-reporting__title">
                    <FormattedMessage id="header.clientReportingItem" defaultMessage="Client Reporting"/>
                </h1>
                {renderContent()}
            </div>
        </div>
        : <ErrorComponent />;
};

export const mapStateToProps = (state: IApplicationRootState): IPowerBiReportingPagePropsFromStore => {
    return {
        clientReportingEnabled: clientReportingEnabled(state),
        hasClientReports: hasClientReports(state),
        performanceReportingEnabled: performanceReportingEnabled(state),
        clientReports: getSuccessData(powerBiClientReportsSelector(state))!,
        currentTab: powerBiReportCategorySelector(state),
    };
};

const connectedComponent = connect(mapStateToProps)(PowerBiReportingPage);

export default multipleApiRequesterWrapper(
    connectedComponent, [
        powerBiClientReportsPair,
    ]
);

