import {AnyAction, Dispatch} from "redux";
import {Action, createAction} from "redux-actions";
import {ThunkAction} from "redux-thunk";
import {IApiResult} from "../../api/AxiosWealthManagementApi";
import {InReportApi} from "../../api/InReportApi";
import PlanApi from "../../api/PlanApi";
import ProductApi from "../../api/ProductApi";
import {ApiError} from "../../model/ApiError.model";
import {IInReportGroup} from "../../model/product/inreports/InReportSummary.model";
import {Product, ProductError} from "../../model/product/Product.model";
import {ProductTicker} from "../../model/product/ProductTicker";
import {dispatchHeaderNotificationActions} from "../base/header/HeaderActions";
import {INotificationObject, NotificationTypes} from "../base/header/HeaderReducer";
import {ProductPageTab} from "./ProductPage";

export enum ProductActions {
    PRODUCT_REQUESTED = "PRODUCT_REQUESTED",
    SET_ACTIVE_PRODUCT = "SET_ACTIVE_PRODUCT",

    PRODUCT_RECEIVED = "PRODUCT_RECEIVED",
    PRODUCT_ERROR_RECEIVED = "PRODUCT_ERROR_RECEIVED",

    PRODUCT_TICKER_SELECTED = "PRODUCT_TICKER_SELECTED",

    PRODUCT_ADDED_TO_PLAN_PORTFOLIO = "PRODUCT_ADDED_TO_PLAN_PORTFOLIO",
    PRODUCT_REMOVED_FROM_PLAN_PORTFOLIO = "PRODUCT_REMOVED_FROM_PLAN_PORTFOLIO",

    MOST_RECENT_INREPORT_SUMMARY_REQUESTED = "MOST_RECENT_INREPORT_SUMMARY_REQUESTED",
    MOST_RECENT_INREPORT_SUMMARY_RESOLVED = "MOST_RECENT_INREPORT_SUMMARY_RESOLVED",
    MOST_RECENT_INREPORT_SUMMARY_ERROR_RECEIVED = "MOST_RECENT_INREPORT_SUMMARY_ERROR_RECEIVED",
    CLEAR_RECENT_INREPORT = "CLEAR_RECENT_INREPORT",

    REQUEST_TICKER_DATA_SUCCEEDED = "REQUEST_TICKER_DATA_SUCCEEDED",
    REQUEST_TICKER_DATA_FAILED = "REQUEST_TICKER_DATA_FAILED",
    TICKER_DATA_REQUESTED = "TICKER_DATA_REQUESTED",
    CLEAR_TICKER_DATA = "CLEAR_TICKER_DATA",

    ADD_PRODUCT_TICKER_SUCCEEDED = "ADD_PRODUCT_TICKER_SUCCEEDED",

    SET_CURRENT_PRODUCT_TAB = "SET_CURRENT_PRODUCT_TAB",
}

export class TickerSelection {
    public productId: number;
    public tickerId: string;
}

export const setActiveProductAction: (arg: number) => Action<number> =
    createAction(ProductActions.SET_ACTIVE_PRODUCT, (backstopId: number) => backstopId );

export const productReceivedAction: (arg: Product) => Action<Product> =
    createAction(ProductActions.PRODUCT_RECEIVED, (product: Product) => product);

export const productTickerSelected: (arg: TickerSelection) => Action<TickerSelection> =
    createAction(ProductActions.PRODUCT_TICKER_SELECTED, (ticker: TickerSelection) => ticker);

export const productErrorReceivedAction: (arg: ProductError) => Action<ProductError> =
    createAction(ProductActions.PRODUCT_ERROR_RECEIVED, (error: ProductError) => error);

// export const productReturnSummaryResolved = createAction<IApiResult<ReturnSummary>, IApiResult<ReturnSummary>>(
//     ProductActions.PRODUCT_RETURN_SUMMARY_RESOLVED,
//     (returnSummary: IApiResult<ReturnSummary>) => returnSummary,
// );
//
// export const productReturnSummaryRequested = createAction(
//     ProductActions.PRODUCT_RETURN_SUMMARY_REQUESTED,
// );
//
// export const productReturnSummaryErrorReceived = createAction<ApiError, ApiError>(
//     ProductActions.PRODUCT_RETURN_SUMMARY_ERROR_RECEIVED,
//     (apiError: ApiError) => apiError,
// );
//
// export const productGrowth10kChartReceived = createAction<Growth10kChart, Growth10kChart>(
//     ProductActions.PRODUCT_GROWTH_10K_CHART_RECEIVED,
//     (chart: Growth10kChart) => chart,
// );
//
// export const productGrowth10kChartErrorReceived = createAction<ApiError, ApiError>(
//     ProductActions.PRODUCT_GROWTH_10K_CHART_ERROR_RECEIVED,
//     (apiError: ApiError) => apiError,
// );
//
// export const productGrowth10kChartRequested = createAction(
//     ProductActions.PRODUCT_GROWTH_10K_CHART_REQUESTED,
// );
//
// export const productQuarterlyPerformanceSummaryResolved =
//     createAction<IApiResult<QuarterlyPerformanceSummary>, IApiResult<QuarterlyPerformanceSummary>>(
//         ProductActions.PRODUCT_QUARTERLY_PERFORMANCE_RESOLVED,
//         (quarterlyPerformanceSummary: IApiResult<QuarterlyPerformanceSummary>) => quarterlyPerformanceSummary,
//     );
//
// export const productQuarterlyPerformanceSummaryErrorReceived = createAction<ApiError, ApiError>(
//     ProductActions.PRODUCT_QUARTERLY_PERFORMANCE_ERROR_RECEIVED,
//     (apiError: ApiError) => apiError,
// );
//
// export const productQuarterlyPerformanceSummaryRequested = createAction(
//     ProductActions.PRODUCT_QUARTERLY_PERFORMANCE_REQUESTED,
// );

export const requestProduct = (backstopId: number)
    : ThunkAction<Promise<void>, void, null, Action<Product | ApiError>> => {
    return (dispatch) => {
        dispatch({type: ProductActions.PRODUCT_REQUESTED});
        return ProductApi.requestProduct(backstopId)
            .then((payload: Product) => {
                dispatch(productReceivedAction(payload));
            }, (error) => {
                dispatch(productErrorReceivedAction(error));
            });
    };
};

export const setActiveProduct = (backstopId: number) => {
    return (dispatch: Dispatch<Action<number>>) => {
        dispatch(setActiveProductAction(backstopId));
    };
};

export const requestMostRecentInReports = (backstopId: number) => {
    return (dispatch: Dispatch<Action<IInReportGroup | undefined>>) => {
        dispatch({type: ProductActions.MOST_RECENT_INREPORT_SUMMARY_REQUESTED});

        return InReportApi.requestLatestInReportSummaryForProductId(backstopId)
            .then((response: IApiResult<IInReportGroup | undefined>) => {
                dispatch({
                    type: ProductActions.MOST_RECENT_INREPORT_SUMMARY_RESOLVED,
                    payload: response.data,
                });
            }).catch((error) => {
                dispatch({
                    type: ProductActions.MOST_RECENT_INREPORT_SUMMARY_ERROR_RECEIVED,
                    payload: error,
                });
            });
    };
};

export const removeProductFromPlanPortfolio = (product: Product)
    : ThunkAction<Promise<void>, void, null, Action<Product>> => {
    return (dispatch) => {
        return PlanApi.removeProductFromPlanPortfolio(product.backstopId).then(() => {
            dispatch(productRemovedFromPlanPortfolio(product));
        });
    };
};

export const addProductToPlanPortfolio = (product: Product)
    : ThunkAction<Promise<void>, void, null, Action<Product>> => {
    return (dispatch) => {
       return PlanApi.addProductToPlanPortfolio(product.backstopId).then(() => {
          dispatch(productAddedToPlanPortfolio(product));
       });
    };
};

export const selectTicker = (tickerSelection: TickerSelection) => {
    return (dispatch: Dispatch<Action<TickerSelection>>) => {
        dispatch(productTickerSelected(tickerSelection));
    };
};

export function productRemovedFromPlanPortfolio(product: Product) {
    return {
        type: ProductActions.PRODUCT_REMOVED_FROM_PLAN_PORTFOLIO,
        payload: product,
    };
}

export function productAddedToPlanPortfolio(product: Product) {
    return {
        type: ProductActions.PRODUCT_ADDED_TO_PLAN_PORTFOLIO,
        payload: product,
    };
}

export const requestTickersForProduct = (productBackstopId: number) =>
    (dispatch: Dispatch<Action<ProductTicker[]>>) => {
    dispatch({type: ProductActions.TICKER_DATA_REQUESTED});

    ProductApi.requestProductTickers(productBackstopId).then((tickers) => {
        dispatch({type: ProductActions.REQUEST_TICKER_DATA_SUCCEEDED, payload: tickers});
    }).catch((failure: any) => {
        dispatch({type: ProductActions.REQUEST_TICKER_DATA_FAILED, payload: failure});
    });
};

export const clearTickerData = () => (dispatch: Dispatch<AnyAction>) => {
        dispatch({type: ProductActions.CLEAR_TICKER_DATA});
};

export const deleteProductTicker = (productBackstopId: number, productTickerId: number) =>
    (dispatch: Dispatch<AnyAction>) => {
    ProductApi.removeProductTicker(productBackstopId, productTickerId).then( () => {
        clearTickerData()(dispatch);
    });
};

export const addProductTicker =
    (productBackstopId: number, ticker: string, shareClassId: string) =>
    (dispatch: Dispatch<AnyAction>) => {
    ProductApi.addProductTicker(productBackstopId, ticker, shareClassId).then( () => {
        clearTickerData()(dispatch);
        dispatch({type: ProductActions.ADD_PRODUCT_TICKER_SUCCEEDED});
    }, () => {
        const notificationObject: INotificationObject = {
            message: "error adding ticker: ticker for productBackstopId: 1",
            notificationType: NotificationTypes.FAILURE,
        };
        dispatchHeaderNotificationActions(notificationObject, 5000)(dispatch);
    });
};

export const setCurrentProductTab = (tab: ProductPageTab) => {
    return (dispatch: Dispatch) => {
        dispatch({
            type: ProductActions.SET_CURRENT_PRODUCT_TAB,
            payload: tab,
        });
    };
};

export default {
    requestProduct,
    requestMostRecentInReports,
    removeProductFromPlanPortfolio,
    addProductToPlanPortfolio,
    selectTicker,
    setActiveProduct,
    clearTickerData,
    deleteProductTicker,
};
