import {Map} from "immutable";
import {Action, Dispatch} from "redux";
import {ThunkAction} from "redux-thunk";
import ProductApi from "../../api/ProductApi";
import {ProductSummary} from "../../model/product/ProductSummary";
import {throwErrorOnNullOrUndefined} from "../../utils/errorUtil";
import {hasConsultantLevelAccess} from "../../utils/sessionUtil";
import {ISelectValue} from "../common/Select.component";

export enum AllProductsActions {
    REQUEST_SHARE_CLASSES_SUCCEEDED = "REQUEST_SHARE_CLASSES_SUCCEEDED",
    REQUEST_SHARE_CLASSES_FAILED = "REQUEST_SHARE_CLASSES_FAILED",
    SHARE_CLASSES_REQUESTED = "SHARE_CLASSES_REQUESTED",
    PRODUCT_UNIVERSE_SUMMARIES_RECEIVED = "PRODUCT_UNIVERSE_SUMMARIES_RECEIVED",
    ALL_PRODUCT_SUMMARIES_REQUESTED = "ALL_PRODUCT_SUMMARIES_REQUESTED",
}

const allProductsRequestedAction = () => {
    return {
        type: AllProductsActions.ALL_PRODUCT_SUMMARIES_REQUESTED,
    };
};

const productUniverseReceivedAction = (payload: Map<number, ProductSummary>) => {
    return {
        type: AllProductsActions.PRODUCT_UNIVERSE_SUMMARIES_RECEIVED,
        payload,
    };
};

export const getAllProducts = (dispatch: Dispatch<Action<AllProductsActions>>) => {
    dispatch(allProductsRequestedAction());

    const productsPromise = (hasConsultantLevelAccess())
        ? ProductApi.requestProductUniverseSummariesById()
        : ProductApi.requestPortfolioProductSummariesById();

    productsPromise.then(
        (allProducts) => {
            const productUniverse = throwErrorOnNullOrUndefined(allProducts.data);
            dispatch(productUniverseReceivedAction(productUniverse));
        },
    );
};

export const requestShareClasses = (dispatch: Dispatch<Action<AllProductsActions>>): void => {
    dispatch({
        type: AllProductsActions.SHARE_CLASSES_REQUESTED,
    });
    ProductApi.requestShareClasses().then((shareClasses: ISelectValue[]) => {
        dispatch({
            type: AllProductsActions.REQUEST_SHARE_CLASSES_SUCCEEDED,
            payload: shareClasses,
        });
    }, (rejection) =>
        dispatch({
            type: AllProductsActions.REQUEST_SHARE_CLASSES_FAILED,
            payload: rejection,
        }));
};

export const requestAllProducts = (): ThunkAction<void, void, null, Action<void>> => {
    return getAllProducts;
};
