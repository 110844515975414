import * as React from "react";
import {IOddResponse} from "../../../api/OddIqApi";

export interface IExceptionResponse {
    response: IOddResponse;
    questionTitle: string;
    expanded: boolean;
    handleClick: (questionId: number) => void;
}

export const ExceptionResponse: React.FunctionComponent<IExceptionResponse> = (props) => {
    const renderDescription = () => {
        return props.response.description
            ?  <p className="__exception-question-description">{props.response.description}</p>
            : null;
    };

    const renderBestPractice = () => {
        return props.response.bestPractice
            ? <div>
                <div className ="__response-detail-title">Best Practice</div>
                <div className="__exception-question-best-practice">
                    <p>{props.response.bestPractice}</p>
                </div>
            </div>
            : null;
    };

    const renderManagerResponse = () => {
        return props.response.managerResponse ? <div className="__exception-question-manager-response"
                     dangerouslySetInnerHTML={{
                    __html: `<p>${props.response.managerResponse.replace("\n", "</p><p>")}</p>`
                }}/>
                : <div className="__exception-question-manager-response">
                     <p>The manager did not provide any additional information.</p>
                  </div>;
    };

    const renderResponseDetails = () => {
        return props.expanded
            ? <div className = "__response-container">
                {renderDescription()}
                {renderBestPractice()}
                <div className ="__response-detail-title">Manager Response</div>
                {renderManagerResponse()}
             </div>
            : null;
    };

    return <div key={props.response.questionId}>
        <i className="fas fa-chevron-right fa-sm clickable __right-arrow"
           onClick = {() => props.handleClick(props.response.questionId)}/>
        <span className="__exception-question-title teal clickable"
              onClick = {() => props.handleClick(props.response.questionId)}>{props.questionTitle}</span>
        {renderResponseDetails()}
    </div>;
};

