import {Action, Dispatch, Middleware, MiddlewareAPI} from "redux";
import PlanApi from "../../api/PlanApi";
import {UserApi} from "../../api/UserApi";
import {AdminActions} from "./adminActions";

export const adminMiddleware: Middleware =
    (api: MiddlewareAPI<Dispatch<Action<string>>>) =>
        (next: Dispatch<Action<void>>) =>
            async <A extends Action>(action: A) => {
                switch (action.type) {
                    case (AdminActions.GET_ALL_USER_EDIT_DETAILS): {
                        UserApi.requestUsers()
                            .then(({ data, error }) => {
                                if (error) {
                                    api.dispatch({
                                        type: AdminActions.FAILED_GET_ALL_USER_EDIT_DETAILS,
                                        payload: { error },
                                    });
                                }
                                if (data) {
                                    api.dispatch({
                                        type: AdminActions.SET_ALL_USER_EDIT_DETAILS,
                                        payload: data,
                                    });
                                }
                            })
                            .catch((reason) => {
                                api.dispatch({
                                    type: AdminActions.FAILED_GET_ALL_USER_EDIT_DETAILS,
                                    payload: reason,
                                });
                            });
                        break;
                    }
                    case (AdminActions.GET_ALL_CLIENTS_AND_PLANS): {
                        PlanApi.requestClientsAndPlans()
                            .then(({ data, error }) => {
                                if (error) {
                                    api.dispatch({
                                        type: AdminActions.FAILED_GET_ALL_CLIENTS_AND_PLANS,
                                        payload: { error },
                                    });
                                }
                                if (data) {
                                    api.dispatch({
                                        type: AdminActions.SET_ALL_CLIENTS_AND_PLANS,
                                        payload: data,
                                    });
                                }
                            })
                            .catch((reason) => {
                                api.dispatch({
                                    type: AdminActions.FAILED_GET_ALL_CLIENTS_AND_PLANS,
                                    payload: reason,
                                });
                            });
                        break;
                    }
                }

                return next(action);
            };
