import * as React from "react";
import {connect} from "react-redux";
import {allHoldingsSelector, hasHoldings, investmentsEnabled, userType} from "../../../mainReducerMapSelectors";
import {IHolding} from "../../api/HoldingsApi";
import {getSuccessData} from "../common/commonStates";
import {allHoldingsRequestPair} from "../common/RequesterPairs";
import {UserTypeEnum} from "../../model/UserInfo.model";
import {multipleApiRequesterWrapper} from "../common/MultipleApiRequesterWrapper";
import {InvestmentsComponent} from "./Investments.component";
import {IApplicationRootState} from "../../../applicationState";

export interface IInvestmentsFromStore {
    holdings: IHolding[];
    hasHoldings: boolean;
    userType: UserTypeEnum;
    investmentsEnabled: boolean;
}

export interface IInvestmentsFromState {
    year?: number | undefined;
    month?: number;
}

export enum HoldingsGroupBy {
    BY_ASSET_CLASS,
    BY_TIER
}

export const InvestmentsAllPlansPage: React.FunctionComponent<IInvestmentsFromStore & IInvestmentsFromState> = (props) => {
    return <InvestmentsComponent holdingsForPlan={props.holdings}
                                 holdingsUniverse={props.holdings}
                                 hasHoldings={props.hasHoldings}
                                 userType={props.userType}
                                 investmentsEnabled={props.investmentsEnabled} />;
};

export const mapStateToProps = (state: IApplicationRootState) => {
    return {
        holdings: getSuccessData(allHoldingsSelector(state))!,
        hasHoldings: hasHoldings(state),
        userType: userType(state),
        investmentsEnabled: investmentsEnabled(state),
    };
};

const connectedComponent = connect<IInvestmentsFromStore>(mapStateToProps)(InvestmentsAllPlansPage);

export default multipleApiRequesterWrapper(
    connectedComponent,
    [allHoldingsRequestPair],
);
