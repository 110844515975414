import * as React from "react";
import {useState} from "react";
import {IHolding} from "../../api/HoldingsApi";
import {formatCurrency} from "../../utils/numberUtil";
import {getDataSum, getHoldingsForGroupOrdered, IData, toTitleCase} from "./InvestmentsDataUtil";
import {HoldingsGroupBy} from "./InvestmentsPage";

export interface IInvestmentsStrategiesTableProps {
    currentGroupData: IData;
    allGroupsData: IData[];
    monthlyHoldings: IHolding[];
    groupBy: HoldingsGroupBy;
}

export enum SortBy {
    STRATEGY = "STRATEGY",
    PLAN_PCT = "PLAN_PCT",
    GROUP_PCT = "GROUP_PCT",
    GROUP = "GROUP",
    AMOUNT = "AMOUNT",
}

export const InvestmentsStrategiesTable: React.FunctionComponent<IInvestmentsStrategiesTableProps> = (props) => {
    const [sorting, setSorting] = useState({sortBy: SortBy.AMOUNT, descending: true});

    const strategies: IHolding[] = getHoldingsForGroupOrdered(
        props.monthlyHoldings,
        props.currentGroupData.name,
        props.groupBy,
        sorting.sortBy,
        sorting.descending
    );

    const getGroupPct = (strategy: IHolding) => {
        return (strategy.assets * 100 / props.currentGroupData.value).toFixed(2) + "%";
    };

    const getPortfolioPct = (strategy: IHolding) => {
        return (strategy.assets * 100 / getDataSum(props.allGroupsData)).toFixed(2) + "%";
    };

    const renderStrategy = (strategy: IHolding, index: number) => {
     return <tr key={`${strategy.name}-${index}`}>
         <td data-testid="investments__strategy-table-strategy">{strategy.name}</td>
         <td data-testid="investments__strategy-table-plan-ptc">{getPortfolioPct(strategy)}</td>
         <td data-testid="investments__strategy-table-group-ptc">{getGroupPct(strategy)}</td>
         {renderAssetClassTd(strategy)}
         <td className="investments__strategy-table-amount" data-testid="investments__strategy-table-amount">
             {formatCurrency(strategy.assets)}
         </td>
     </tr>;
    };

    const renderAssetClassTd = (strategy: IHolding) => {
        return props.groupBy === HoldingsGroupBy.BY_ASSET_CLASS
            ? <td data-testid="investments__strategy-table-group">{toTitleCase(strategy.tier)}</td>
            : <td>{toTitleCase(strategy.assetClass)}</td>;
    };

    function sortOnCategory(sortBy: SortBy) {
        let descending = true;

        if (sorting.sortBy === sortBy) {
            descending = !sorting.descending;
        }

        setSorting({sortBy, descending});
    }

    function renderTitleAndArrow(category: SortBy, displayName: string) {
        let name = <div className="unsorted-header clickable">{displayName}</div>;
        let arrow = <div/>;

        if (category === sorting.sortBy) {
            name = <div className="sorted-header clickable">{displayName}</div>;
            if (sorting.descending) {
                arrow = <div className="fal fa-arrow-down"/>;
            } else {
                arrow = <div className="fal fa-arrow-up"/>;
            }
        }

        return <div className="investments__header">
            {name}
            {arrow}
        </div>;
    }

    return <div className="investments__strategy-table-div">
        <table className="investments__strategy-table" data-testid="investments__strategy-table">
            <thead>
            <tr data-testid="investments__column-header-row">
                <th className="investments__column-headers"
                    data-testid="investments__column-headers-strategy"
                    onClick={() => sortOnCategory(SortBy.STRATEGY)}>
                    {renderTitleAndArrow(SortBy.STRATEGY, "Strategy")}
                </th>
                <th className="investments__column-headers"
                    data-testid="investments__column-headers-plan-ptc"
                    onClick={() => sortOnCategory(SortBy.PLAN_PCT)}>
                    {renderTitleAndArrow(SortBy.PLAN_PCT, "% Plan")}
                </th>
                <th className="investments__column-headers"
                    data-testid="investments__column-headers-group-ptc"
                    onClick={() => sortOnCategory(SortBy.GROUP_PCT)}>
                    {renderTitleAndArrow(SortBy.GROUP_PCT, props.groupBy === HoldingsGroupBy.BY_ASSET_CLASS ? "% Asset Class" : "% Tier")}
                </th>
                <th className="investments__column-headers"
                    data-testid="investments__column-headers-group"
                    onClick={() => sortOnCategory(SortBy.GROUP)}>
                    {renderTitleAndArrow(SortBy.GROUP, props.groupBy === HoldingsGroupBy.BY_ASSET_CLASS ? "Tier" : "Asset Class")}
                </th>
                <th className="investments__column-headers-amount"
                    data-testid="investments__column-headers-amount"
                    onClick={() => sortOnCategory(SortBy.AMOUNT)}>
                    {renderTitleAndArrow(SortBy.AMOUNT, "Amount (USD)")}
                </th>
            </tr>
            </thead>
            <tbody>
                {strategies.map(renderStrategy)}
            </tbody>
        </table>
    </div>;
};
