import {IApplicationRootState} from "../../../applicationState";
import {
    portfolioPlanTreeExpandedRowsInner,
    portfolioPlanTreeSelectedDateInner,
    portfolioPlanTreeSelectedProductInner
} from "./PortfolioReducer";

export function portfolioPlanTreeExpandedRows(state: IApplicationRootState) {
    return portfolioPlanTreeExpandedRowsInner(state.portfolioPlanTreeState);
}

export function portfolioPlanTreeSelectedDate(state: IApplicationRootState) {
    return portfolioPlanTreeSelectedDateInner(state.portfolioPlanTreeState);
}

export function portfolioPlanTreeSelectedProduct(state: IApplicationRootState) {
    return portfolioPlanTreeSelectedProductInner(state.portfolioPlanTreeState);
}