import * as React from "react";
import {connect} from "react-redux";
import {IInReportGroup, InReportSummary} from "../../../model/product/inreports/InReportSummary.model";
import {InReportSummaryComponent} from "./InReportSummaryComponent";
import {getSuccessData, REQUEST_STATES, RequestState} from "../../common/commonStates";
import {ErrorComponent} from "../../base/Error.component";
import {mostRecentInReports} from "../../../../mainReducerMapSelectors";
import {FormattedMessage} from "react-intl";
import {IApplicationRootState} from "../../../../applicationState";

interface IInReportsRequesterPropsFromParent {
    productBackstopId: number;
}

interface IInReportsRequesterPropsFromStore {
    inReportGroup: RequestState<IInReportGroup>;
}

export type IInReportsRequesterProps = IInReportsRequesterPropsFromParent
    & IInReportsRequesterPropsFromStore;

export const InReportsComponent: React.FunctionComponent<IInReportsRequesterProps> = (props) => {
    if (props.inReportGroup.kind === REQUEST_STATES.NOT_REQUESTED
        || props.inReportGroup.kind === REQUEST_STATES.REQUESTED) {
        return null;
    }

    if (props.inReportGroup.kind === REQUEST_STATES.REQUEST_FAILED) {
        return <ErrorComponent/>;
    }

    const inReportGroup = getSuccessData(props.inReportGroup);

    const renderSeparator = () => {
        return <div className="product__document-separator">
            <div className="spacer-dash--small"/>
        </div>;
    };

    if (!inReportGroup?.inBriefSummary && !inReportGroup?.inTotalSummary && !inReportGroup?.inFormSummary ) {
        return <div className="product__inreports">
            <h2 className="product__inreports-header gray">
                <FormattedMessage
                    id="product.in-reports"
                    defaultMessage="InReports"
                />
            </h2>
            {renderSeparator()}
            <FormattedMessage
                id="product.no-docs"
                defaultMessage="No current documents available for this strategy."
            />
        </div>;
    }

    return <div className="product__inreports">
        <h2 className="product__inreports-header gray">
            <FormattedMessage
                id="product.in-reports"
                defaultMessage="InReports"
                />
        </h2>
        {renderSeparator()}
        {renderInReportComponent(inReportGroup?.inBriefSummary, "InBrief")}
        {renderInReportComponent(inReportGroup?.inTotalSummary, "InTotal")}
        {renderInReportComponent(inReportGroup?.inFormSummary, "InForm")}
    </div>;

    function renderInReportComponent(inReportSummary: InReportSummary | undefined | null, linkName: string) {
        if (!inReportSummary) {
            return null;
        }

        return <InReportSummaryComponent
            productBackstopId={props.productBackstopId}
            inReportSummary={inReportSummary}
            linkName={linkName}/>;
    }
};

export const mapStateToProps = (store: IApplicationRootState): IInReportsRequesterPropsFromStore => {
    return {
        inReportGroup: mostRecentInReports(store),
    };
};

const connector = connect<IInReportsRequesterPropsFromStore>(
    mapStateToProps,
);

const connectedComponent = connector(InReportsComponent);
export default connectedComponent;
