import * as React from "react";
import {useState} from "react";
import {useDispatch} from "react-redux";
import {IClient} from "../../api/ClientApi";
import {ClientTypes} from "../../model/Client.model";
import {prominentButtonStyle, secondaryButtonStyle} from "../common/buttonStyles";
import {RaisedButton} from "../common/RaisedButton";
import {setHeaderNotification} from "../base/header/HeaderActions";
import {NotificationTypes} from "../base/header/HeaderReducer";
import {SelectComponent} from "../common/Select.component";
import {TextFieldComponent} from "../common/TextField.component";
import adminActions from "./adminActions";
import {COUNTRY_SELECT_VALUES} from "./AdminAllClients";
import {scrollToElement} from "../../utils/browserUtil";

export interface IAdminCreateClientProps {
    onCancel: () => void;
    client?: IClient;
}

export const AdminCreateClientComponent: React.FunctionComponent<IAdminCreateClientProps> = (props) => {
    const [submitClicked, setSubmitClicked] = useState<boolean>(false);
    const [clientName, setClientName] = useState<string>(props.client ? props.client.name : "");
    const [crmId, setCrmId] = useState<string>(props.client ? props.client.crmId.toString() :"");
    const [selectedCountry, setSelectedCountry] = useState<number | undefined>(
        props.client ? props.client.countryId : undefined);
    const [selectedType, setSelectedType] = useState<string | undefined>(
        props.client ? props.client.clientType : undefined
    );

    const dispatch = useDispatch();

    const renderClientNameField = () => {
        const handleChange = (e: React.ChangeEvent<HTMLSelectElement>) => {
            return setClientName(e.target.value);
        };

        return <TextFieldComponent
            name="client_name"
            className="admin-add-client__client-name-field"
            type="text"
            fullWidth={true}
            value={clientName}
            handleChange={handleChange}
            placeholder="Client Name"
            renderRequired={true}
            submitClicked={submitClicked}
        />;
    };

    const renderCrmIdField = () => {
        const handleChange = (e: React.ChangeEvent<HTMLSelectElement>) => {
            return setCrmId(e.target.value);
        };

        return <TextFieldComponent
            name="crm_id"
            className="admin-add-client__crm-id-field"
            type="text"
            fullWidth={true}
            value={crmId}
            handleChange={handleChange}
            placeholder="CRM ID"
            renderRequired={true}
            submitClicked={submitClicked}
        />;
    };

    const renderCountriesSelect = () => {
        const handleChange = (e: any) => {
            return setSelectedCountry(e.target.value);
        };

        return <div>
            <SelectComponent
                id="admin-add-client__countries"
                values={COUNTRY_SELECT_VALUES}
                selected={selectedCountry}
                width={280}
                handleChange={handleChange}
                placeholder="Select Country..."
                renderRequired={true}
                submitClicked={submitClicked}
            />
        </div>;
    };

    const renderClientTypes = () => {
        const handleChange = (e: any) => {
            return setSelectedType(e.target.value);
        };

        return <div>
            <SelectComponent
                id="user-edit__client-type"
                values={Object.keys(ClientTypes).map((type) => {
                    return {
                        id: type,
                        name: type,
                    };
                })}
                selected={selectedType}
                width={280}
                submitClicked={submitClicked}
                handleChange={handleChange}
                placeholder="Client Type..."
                renderRequired={true}
            />
        </div>;
    };

    const isEmptyInput = () => {
        return (!selectedCountry
            || !selectedType
            || clientName === ""
            || crmId === "");
    };

    const handleSave = () => {
        setSubmitClicked(false);

        if (isEmptyInput()) {
            setSubmitClicked(true);
            return;
        }

        if (isNaN(parseInt(crmId, 10))) {
            dispatch(setHeaderNotification(
                {message: "Error: Invalid CRM ID", notificationType: NotificationTypes.FAILURE},
                5000));
            return;
        }

        const client: IClient = {
            name: clientName,
            crmId: parseInt(crmId, 10),
            clientType: selectedType!,
            countryId: selectedCountry!
        };

        if (props.client) {
            client.id = props.client.id;
            dispatch(adminActions.updateClient(client));
        } else {
            dispatch(adminActions.saveClient(client));
        }
        scrollToElement(".main__header", 0);

        setSubmitClicked(false);
    };

    const renderHeader = () => {
        return props.client
        ? null : <h2 className="admin-add-client__header">Add New Client</h2>;
    };

    return <div className="admin-add-client__container" data-testid="admin-create-client__container">
        {renderHeader()}
        <div className="small">All fields are required.</div>
        <br/>
        <div className="admin-add-client__input-fields">
            <div className="admin-client-plan__label">Client Name</div>
            {renderClientNameField()}
            <br/>
            <div className="admin-client-plan__label">CRM ID</div>
            {renderCrmIdField()}
            <br/>
            <div className="admin-client-plan__label">Country</div>
            {renderCountriesSelect()}
            <br/>
            <div className="admin-client-plan__label">Client Type</div>
            {renderClientTypes()}
        </div>
        <div className="admin-add-client__buttons">
            <RaisedButton className="admin-add-client__cancel-button"
                          style={secondaryButtonStyle}
                          primary={false}
                          onClick={props.onCancel}>
                Cancel
            </RaisedButton>
            <div className="buttons-spacer-between"/>
            <RaisedButton className="admin-add-client__save-button"
                          style={prominentButtonStyle}
                          primary={true}
                          onClick={handleSave}>
                Save Client
            </RaisedButton>
        </div>
    </div>;
};
