import * as React from "react";
import {navigateTo} from "../../../navigateTo";
import {getBrowserLocationPath} from "../../api/BrowserWrapper";
import SubNav from "../base/SubNav.component";
import {UPDATES_PATHS} from "../base/NavItems";
import {hasHoldings, hasResearchAccess, investmentsEnabled, userType} from "../../../mainReducerMapSelectors";
import {useSelector} from "react-redux";
import {showInvestments} from "../../utils/navigationUtils";

export enum PortfolioNavItems {
    INVESTMENTS = "investments",
    PORTFOLIO = "portfolio",
    UPDATES = "strategy-updates",
}

export const PortfolioNav: React.FunctionComponent = () => {
    const hasResearch = useSelector(state => hasResearchAccess(state));
    const hasHoldingsAccess = useSelector(state => hasHoldings(state));
    const hasInvestmentsEnabled = useSelector(state => investmentsEnabled(state));
    const currentUserType = useSelector(state => userType(state));

    const portfolioNavItem = {
        id: PortfolioNavItems.PORTFOLIO,
        name: "Portfolio",
    };

    const updatesNavItem = {
        id: PortfolioNavItems.UPDATES,
        name: "Updates",
    };

    const investmentsNavItem = {
        id: PortfolioNavItems.INVESTMENTS,
        name: "Investments",
    };

    function isSelected(id: string) {
        return getBrowserLocationPath()?.includes(`${id}`);
    }

    function getMenuItems() {
        const items = [];

        if (showInvestments(hasHoldingsAccess, currentUserType, hasInvestmentsEnabled)) {
            items.push(investmentsNavItem);
        }
        items.push(portfolioNavItem);

        if (hasResearch) {
            items.push(updatesNavItem);
        }
        return items;
    }

    function isStrategyUpdateUrl() {
        return !!UPDATES_PATHS.find((item) => getBrowserLocationPath().includes(item));
    }

    function isPortfolioUrl() {
        return ["managers", "products", "portfolio"].find((item) => getBrowserLocationPath().includes(item));
    }

    function getSelectedMenuItem() {
        return isStrategyUpdateUrl()
            ? updatesNavItem.id
            : isPortfolioUrl()
                ? portfolioNavItem.id
                : getMenuItems()
                    .map((item) => item.id)
                    .find(isSelected);
    }

    function selectNavItem(id: string) {
        const selectedItem = findMenuItem(id);
        navigateTo(`/${selectedItem.id}`);
    }

    function findMenuItem(id: string) {
        return getMenuItems().find((item) => item.id.toString() === id)!;
    }

    return <SubNav
        menuItems={getMenuItems()}
        searchEnabled={false}
        onItemSelected={selectNavItem}
        selectedMenuItem={getSelectedMenuItem()}
    />;
};
