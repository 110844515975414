import {getTranslation} from "./translationUtil";

export enum Movement {
    UPGRADED = "UPGRADED",
    DOWNGRADED = "DOWNGRADED",
    NO_CHANGE = "NO_CHANGE",
}

export const NO_PREVIOUS_RATING = "no previous rating";
export const NO_CURRENT_RATING = "no current rating";
export const NO_CHANGE = "no change";
export const NOT_RATED = "Not Rated";
export const WEAK = "Weak";
export const AVERAGE = "Average";
export const ABOVE_AVERAGE = "Above Average";
export const STRONG = "Strong";
export const NR_RATING = "NR";
export const QUALIFIED = "Qualified";
export const IN_REVIEW = "In Review";
export const BUY_CLOSED = "Buy (Closed)";
export const BUY = "Buy";
export const SELL = "Sell";
export const NEUTRAL = "NEUTRAL";
export const GOOD = "GOOD";
export const BAD = "BAD";
export const WARNING = "WARNING";
export const UPGRADED = "upgraded";
export const DOWNGRADED = "downgraded";
export const A1_PASS = "A1 Pass";
export const A2_PASS = "A2 Pass";
export const PASS = "Pass";
export const FAIL = "Fail";
export const CONDITIONAL_PASS = "Conditional Pass";
export const NOT_RECOMMENDED = "Not Recommended";

export enum EsgRating {
    LIMITED = "Limited",
    INTEGRATED = "Integrated",
    ADVANCED = "Advanced"
}

export const OddRatingList =
    [A1_PASS, A2_PASS, PASS, CONDITIONAL_PASS, FAIL, NR_RATING] as const; // in hierarchical order
export type OddRating = typeof OddRatingList[number];

export interface IDisplayedRating {
    [key: string]: {text: string, class: string};
}

export const OddDisplayedRatings: IDisplayedRating = {
    [PASS]: {text: PASS, class: "__pass"},
    [A1_PASS]: {text: A1_PASS, class: "__pass"},
    [A2_PASS]: {text: A2_PASS, class: "__pass"},
    [CONDITIONAL_PASS]: {text: CONDITIONAL_PASS, class: "__pass"},
    [FAIL]: {text: FAIL, class: "__fail"},
    [NR_RATING]: {text: "Not Rated", class: "__not-rated"},
};

export const OddRatingDescriptions = (rating: string) => {
    switch (rating) {
        case PASS:
            return getTranslation(`product-odd-rating-tooltip.${rating}`
                , "Aon noted no material operational concerns.");
        // eslint-disable-next-line max-len
        case A1_PASS:
            return getTranslation(`product-odd-rating-tooltip.${rating}`
                ,  "Aon noted no material operational concerns and considers the firm’s operations largely align with a well-controlled operating environment.");
        // eslint-disable-next-line max-len
        case A2_PASS:
            return getTranslation(`product-odd-rating-tooltip.${rating}`
                ,  "The firm’s operations largely align with a well-controlled operating environment, with limited exceptions. Managers may be rated within this category due to resource limitations or where isolated areas do not align with best practice.");
        // eslint-disable-next-line max-len
        case CONDITIONAL_PASS:
            return getTranslation(`product-odd-rating-tooltip.${rating}`
                ,  "Aon noted specific operational concerns that the firm has agreed to address in a reasonable timeframe. Upon resolution, Aon will review the firm’s rating.");
        // eslint-disable-next-line max-len
        case FAIL:
            return getTranslation(`product-odd-rating-tooltip.${rating}`
                ,  "Aon noted material operational concerns that introduce the potential for economic or reputational exposure.");
    }
};
