import {Set as ImmutableSet} from "immutable";
import * as React from "react";
import {useState} from "react";
import {
    IOddResponse,
    IOddQuestionEntity,
    IOddSectionEntity,
    IOddSectionsQuestions,
    OddLevelId,
    OddResponseCode,
} from "../../../api/OddIqApi";
import {mapQuestionIdToQuestionEntity, mapSectionToResponses} from "../OddIqUtils";
import {ExceptionResponse} from "./ExceptionResponse.component";
import {byName, compareInsensitive} from "../../../utils/listUtil";

export interface IExceptionBreakdownTable {
    oddSectionsQuestions: IOddSectionsQuestions;
    oddProductQuestionResponse: IOddResponse[];
}

export const ExceptionsBreakdown: React.FunctionComponent<IExceptionBreakdownTable> = (props) => {
    const DUMMY_QUESTION_ID = -1;

    const [openPanels, setOpenPanels] = useState(ImmutableSet<string>());

    const [expandedQuestionId, setExpandedQuestionId] = useState(DUMMY_QUESTION_ID);

    const sectionsToExceptionsMap = mapSectionToResponses(props.oddSectionsQuestions, props.oddProductQuestionResponse)
        .map((val) =>
            val!.filter((response) =>
                response.responseCodeId === OddResponseCode.EXCEPTION))
        .toMap();

    const questionIdToQuestionEntityMap = mapQuestionIdToQuestionEntity(props.oddSectionsQuestions.questions);

    const allSectionPanelKeys = props.oddSectionsQuestions.questions.reduce(
        (acc: ImmutableSet<string>, question: IOddQuestionEntity) => {
            if (hasExceptionsInLevel(question.sectionId, question.levelId)) {
                return acc.add(generateOpenPanelKey(question.sectionId, question.levelId));
            } else {
                return acc;
            }
    }, ImmutableSet<string>());

    function generateOpenPanelKey(sectionId: number, levelId: OddLevelId) {
        return `${sectionId},${levelId}`;
    }

    function hasExceptionsInLevel(sectionId: number, levelId: OddLevelId) {
        return (sectionsToExceptionsMap
            .get(sectionId)!
            .filter((response) => questionIdToQuestionEntityMap.get(response.questionId)!.levelId === levelId)
            .length > 0);
    }

    function expandAllMode(): boolean {
        return openPanels.size <= allSectionPanelKeys.size / 2;
    }

    function renderIcon(sectionId: number, levelId: OddLevelId) {
        const openPanelsKey = generateOpenPanelKey(sectionId, levelId);
        const icon = openPanels.get(openPanelsKey) ? "fa-chevron-up" : "fa-chevron-down";

        return <i className={`far ${icon} __expand-icon`}/>;
    }

    function getQuestionTitle(questionId: number) {
        return questionIdToQuestionEntityMap.get(questionId)!.questionTitle;
    }

   const handleExceptionClick = (questionId: number) => {
        if (questionId === expandedQuestionId) {
            setExpandedQuestionId(DUMMY_QUESTION_ID);
        } else {
            setExpandedQuestionId(questionId);
        }
   };

    function renderExceptionQuestions(sectionId: number, levelId: OddLevelId) {
        const openPanelsKey = generateOpenPanelKey(sectionId, levelId);

        return openPanels.get(openPanelsKey)! ?
            <div className="__exception-questions teal">
                {sectionsToExceptionsMap
                    .get(sectionId)!
                    .filter((response) => questionIdToQuestionEntityMap.get(response.questionId)!.levelId === levelId)
                    .sort((response1, response2) => {
                        return compareInsensitive(getQuestionTitle(response1.questionId),
                                                    getQuestionTitle(response2.questionId));
                    })
                    .map((response) => {
                        return <ExceptionResponse key = {response.questionId}
                                                  response = {response}
                                                  questionTitle = {getQuestionTitle(response.questionId)}
                                                  expanded = {response.questionId === expandedQuestionId}
                                                  handleClick={handleExceptionClick}/>;
                        })
                }
            </div>
            : <div className="__header-margin-spacer"/>;
    }

    function renderSectionNameWithRisk(section: IOddSectionEntity, levelId: OddLevelId, exceptionCount: number) {
         return <div className="__section-header clickable"
                  onClick={() => {
                      const openPanelKey = generateOpenPanelKey(section.id, levelId);
                      const newPanelState = openPanels.has(openPanelKey)
                          ? openPanels.remove(openPanelKey)
                          : openPanels.add(openPanelKey);
                      setOpenPanels(newPanelState);
                      setExpandedQuestionId(DUMMY_QUESTION_ID);
                  }}
                >
                     <div className="__section-name">
                         <div className="section-title teal small">{section.name}</div>
                         <div className="__risk-areas">({exceptionCount})</div>
                     </div>
                     {renderIcon(section.id, levelId)}
                 </div>;
    }

    function renderSectionNameNoRisk(name: string) {
        return <div className="__section-header">
            <div className="__section-name">
                <div className="section-title small">{name}</div>
                <div className="__risk-areas">No risk areas identified</div>
            </div>
        </div>;
    }

    function renderSection(section: IOddSectionEntity, levelId: OddLevelId) {
        const exceptionCount = sectionsToExceptionsMap
            .get(section.id)!
            .filter((response) => questionIdToQuestionEntityMap.get(response.questionId)!.levelId === levelId)
            .length;

        return <div key={section.name}>
            <div className="__section-panel">
                {exceptionCount === 0
                    ? renderSectionNameNoRisk(section.name)
                    : renderSectionNameWithRisk(section, levelId, exceptionCount)}
                {renderExceptionQuestions(section.id, levelId)}
            </div>
        </div>;
    }

    function renderSections(levelId: OddLevelId) {
        const questionsWithSection = props.oddSectionsQuestions.questions.filter((question) =>
            question.levelId === levelId);
        return props.oddSectionsQuestions.sections
            .filter((section) => questionsWithSection
                .find((question) => question.sectionId === section.id))
            .sort(byName)
            .map((section) => {
                return renderSection(section, levelId);
            });
    }

    return <div id="oddiq-exceptions-breakdown__container" className="accordion" data-testid="odd-exceptions-breakdown">
        <div>
            <div className="__title">
                Breakdown of Exceptions
                <span className="__expand-all clickable link"
                     onClick={() => {
                         const newState = expandAllMode()
                             ? openPanels.union(allSectionPanelKeys)
                             : openPanels.clear();
                         setOpenPanels(newState);
                         setExpandedQuestionId(DUMMY_QUESTION_ID);
                     }}
                >
                    {expandAllMode() ? "Expand All" : "Collapse All"}
                </span>
            </div>
            <div>
                <div>
                    <div className="__level-header">
                        Manager Exceptions
                    </div>
                    <div className="__bottom_line" />
                </div>
                <div className="__manager-level-sections">
                    {renderSections(OddLevelId.Manager)}
                </div>
                <div>
                    <div className="__level-header">
                        Strategy Exceptions
                    </div>
                    <div className="__bottom_line" />
                </div>
                <div className="__strategy-level-sections">
                    {renderSections(OddLevelId.Strategy)}
                </div>
            </div>
        </div>
    </div>;
};
