import {Map} from "immutable";
import * as React from "react";
import {connect} from "react-redux";
import {bindActionCreators, Dispatch} from "redux";
import {
    allFlashReportsSummariesSelector,
    allManagersSelector,
    allMeetingNotesSelector,
    hasClientResearchEnabled,
    hasResearchAccess,
} from "../../../../mainReducerMapSelectors";
import {FlashReportSummary} from "../../../model/FlashReportSummary.model";
import {IStrategyUpdate} from "../../../model/IStrategyUpdate.model";
import {ManagerResponse} from "../../../model/manager/ManagerResponse.model";
import {MeetingNoteSummary} from "../../../model/MeetingNoteSummary.model";
import {byDate} from "../../../utils/dateUtil";
import {byName} from "../../../utils/listUtil";
import {ErrorComponent} from "../../base/Error.component";
import {getSuccessData} from "../../common/commonStates";
import {
    allFlashReportsSummariesRequestPair,
    allManagersRequestPair,
    allMeetingsNotesRequestPair,
} from "../../common/RequesterPairs";
import AllFlashReportsActions from "../../flash-report/AllFlashReportsActions";
import {flashReportActions} from "../../flash-report/flashReportActions";
import meetingNoteActions from "../../meeting-note/meetingNoteActions";
import strategyUpdatesActions, {ALL_MANAGERS} from "./strategyUpdatesActions";
import {StrategyUpdatesPaginator} from "./StrategyUpdatesPaginator.component";
import {FormattedMessage} from "react-intl";
import {multipleApiRequesterWrapper} from "../../common/MultipleApiRequesterWrapper";
import {NoAccessNotification} from "../../base/header/NoAccessNotification";
import {SelectComponentReact} from "../../common/ReactSelectComponent";
import {ISelectValue} from "../../common/Select.component";
import {getTranslation} from "../../../utils/translationUtil";
import {IApplicationRootState} from "../../../../applicationState";

export interface IStrategyUpdatesActions {
    actions: {
        requestAllFlashReportSummaries: typeof AllFlashReportsActions.requestAllFlashReportSummaries;
        getFlashReportPDF: typeof flashReportActions.getFlashReportPDF;
        getMeetingNotePdf: typeof meetingNoteActions.getMeetingNotePdf;
        updateSelectedManager: (value: number) => any;
    };
}

interface IStrategyUpdatesPropsFromStore {
    flashReportSummaries: Map<number, FlashReportSummary>;
    selectedManager: number;
    allManagers: Map<number, ManagerResponse>;
    allMeetingNoteSummaries: Map<number, MeetingNoteSummary>;
    hasClientResearchEnabled: boolean;
    hasResearchAccess: boolean;
}

export type IStrategyUpdateProps = IStrategyUpdatesActions &
    IStrategyUpdatesPropsFromStore;

export const StrategyUpdatesComponent: React.FunctionComponent<IStrategyUpdateProps> = (props) => {


    const renderNoResearchNotification = () => {
        return !props.hasClientResearchEnabled
            ? <NoAccessNotification message={getTranslation(
                "research.hidden-from-clients",
                "The Managers & Strategies section is currently hidden from client users of this plan.")}
            />
            : null;
    };

    const renderUpdates = () => {
        const filteredUpdates = getFilteredUpdates();

        if (filteredUpdates.length === 0) {
            return renderNoUpdatesMessage();
        } else {
            return <StrategyUpdatesPaginator
                updates={filteredUpdates.sort(byDate)}
            />;
        }
    };

    const renderNoUpdatesMessage = () => {
        return (
            <div className="strategy-updates__no-updates-message" data-testid="strategy-updates__no-updates-message">
                There are no updates for the manager you've selected.
                <div className="clear-filter-container">
                    <div className="clear-filter link-16 clickable"
                         onClick={() => {
                             props.actions.updateSelectedManager(ALL_MANAGERS);
                         }}>
                        All Updates
                    </div>
                </div>
            </div>
        );
    };

    const getFilteredUpdates = () => {
        return getUpdatesList()
            .filter((it) => isSelectedManager(it));
    };

    const isSelectedManager = (update: IStrategyUpdate) => {
        return props.selectedManager === ALL_MANAGERS ||
            update.managerIds.find((it) => it === props.selectedManager);
    };

    const getUpdatesList = (): IStrategyUpdate[] => {
        return ([
            ...props.flashReportSummaries.valueSeq().toArray(),
            ...props.allMeetingNoteSummaries.valueSeq().toArray(),
        ]) as IStrategyUpdate[];
    };

    const renderManagerFilterMenu = () => {
        const managers: ISelectValue[] = [{
                id: ALL_MANAGERS,
                name: getTranslation("filters.all-managers","All Managers")}]
            .concat(props.allManagers.valueSeq()
            .toArray()
            .filter((it) => it.inPortfolio)
            .map((it: ManagerResponse) => ({id: it.backstopId, name: it.name}))
            .sort(byName));

        const handleChange = (manager: ISelectValue) => {
            props.actions.updateSelectedManager(manager.id as number);
        };

        const getSelectedManager = (id: number): ISelectValue => {
            return managers.find((m) => m.id === id)!;
        };

        return <div className="strategy-updates__manager-filter">
            <div className="strategy-updates__filter-container">
                <div className="strategy-updates__filter-label">
                    <FormattedMessage
                        id="research.filter-label"
                        defaultMessage="Filter:"
                    />
                </div>
                <div className="strategy-updates__filter">
                    <div className="strategy-updates__spacer" data-testid="strategy-updates__spacer">
                        <SelectComponentReact
                            className="strategy-updates__managers-dropdown"
                            isMulti={false}
                            menuIsOpen={undefined}
                            options={managers}
                            value={getSelectedManager(props.selectedManager)}
                            onChange={handleChange}
                            submitClicked={false}
                            getOptionLabel={(option: ISelectValue) => option.name}
                            getOptionValue={(option: ISelectValue) => option!.id!.toString(10)}
                            renderRequired={false}
                            stylesObject={{width: "500px"}}
                        />
                    </div>
                    <a className="strategy-updates__clear-filter link-14 clickable"
                       data-testid="strategy-updates__clear-filter"
                       onClick={() => props.actions.updateSelectedManager(ALL_MANAGERS)}>
                        <FormattedMessage
                            id = "research.clear-filters"
                            defaultMessage= "Clear Filters"
                        />
                    </a>
                </div>
            </div>
        </div>;
    };

    return !props.hasClientResearchEnabled && !props.hasResearchAccess
        ? <ErrorComponent/>
        : <>
            {renderNoResearchNotification()}
            <div className="main-content strategy-updates__content new-common-styles">
                <h1 className="strategy-updates__title blue">
                    <FormattedMessage id="updates.title" defaultMessage="Updates"/>
                </h1>
                {renderManagerFilterMenu()}
                {renderUpdates()}
            </div>
        </>;

};

export const mapDispatchToProps = (dispatch: Dispatch): IStrategyUpdatesActions => {
    return {
        actions: bindActionCreators({
            requestAllFlashReportSummaries: AllFlashReportsActions.requestAllFlashReportSummaries,
            getFlashReportPDF: flashReportActions.getFlashReportPDF,
            getMeetingNotePdf: meetingNoteActions.getMeetingNotePdf,
            updateSelectedManager: strategyUpdatesActions.updateSelectedManager,
        }, dispatch),
    };
};

export const mapStateToProps = (state: IApplicationRootState): IStrategyUpdatesPropsFromStore => {
    return {
        flashReportSummaries: getSuccessData(allFlashReportsSummariesSelector(state))!,
        selectedManager: state.strategyUpdatesPageState!.selectedManager,
        allManagers: getSuccessData(allManagersSelector(state))!,
        allMeetingNoteSummaries: getSuccessData(allMeetingNotesSelector(state))!,
        hasClientResearchEnabled: hasClientResearchEnabled(state),
        hasResearchAccess: hasResearchAccess(state),
    };
};

const connector = connect<IStrategyUpdatesPropsFromStore, IStrategyUpdatesActions>(mapStateToProps, mapDispatchToProps);

export default multipleApiRequesterWrapper(
    connector(StrategyUpdatesComponent),
    [
        allMeetingsNotesRequestPair,
        allManagersRequestPair,
        allFlashReportsSummariesRequestPair,
    ],
);
