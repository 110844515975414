import {ISelectValue} from "../components/common/Select.component";
import {SharedDocument} from "../model/SharedDocument.model";
import {compareInsensitive} from "./listUtil";

export enum SortOptions {
    TIME_PERIOD = "Time Period",
    RECENT = "Recently Added",
    ALPHA_ASC = "Document Title (A-Z)",
    ALPHA_DESC = "Document Title (Z-A)",
}

export function getSortingItems(): ISelectValue[] {
    return Object.keys(SortOptions)
        .map((key: keyof typeof SortOptions) =>
            ({
                id: SortOptions[key],
                name: SortOptions[key],
            }),
        );
}

export const getSortBy = (sortOptions: SortOptions) => (doc1: SharedDocument, doc2: SharedDocument) => {
    switch (sortOptions) {
        case (SortOptions.RECENT):
            return byDocumentsByCreatedMillisThenName(doc1, doc2, false);
        case (SortOptions.ALPHA_ASC):
            return compareInsensitive(doc1.title, doc2.title);
        case (SortOptions.ALPHA_DESC):
            return compareInsensitive(doc2.title, doc1.title);
        default:
            return byDocumentsByTimePeriodThenName(doc1, doc2, false);
    }
};

const byDocumentsByCreatedMillisThenName = (doc1: SharedDocument, doc2: SharedDocument, ascending: boolean) => {
        const dateComp = (ascending
            ? (doc1.createdMillis - doc2.createdMillis)
            : (doc2.createdMillis - doc1.createdMillis));
        return dateComp || (compareInsensitive(doc1.title, doc2.title));
};

const byDocumentsByTimePeriodThenName = (doc1: SharedDocument, doc2: SharedDocument, ascending: boolean) => {
        const dateComp = (ascending
            ? (doc1.date.getTime() - doc2.date.getTime())
            : (doc2.date.getTime() - doc1.date.getTime()));
        return dateComp || (compareInsensitive(doc1.title, doc2.title));
};
