import * as React from "react";
import {ReactElement} from "react";
import {FormattedMessage} from "react-intl";
import {IHomeContentBoxDataItem, IHomeContentBoxDownloadableDataItem} from "../../model/IHomeListItem";
import {handleEnter, isNullOrUndefined} from "../../utils/commonUtil";
import {formatShortDate} from "../../utils/dateUtil";
import {TrackedLink} from "../base/TrackedLink.component";
import {multipleApiRequesterWrapper} from "../common/MultipleApiRequesterWrapper";
import {IRequesterApiPair} from "../common/MultiDataGenericRequester";

export interface IHomeContentBoxProps {
    title: string;
    translationId?: string;
    values: IHomeContentBoxDataItem[];
    shouldTitlesBeLinks: boolean;
    shouldDocumentsDownload: boolean;
}

export class HomeContentBox extends React.Component<IHomeContentBoxProps, any> {
    public render() {
        return <div data-testid={`${this.props.title.toLowerCase().replace(" ", "-")}`}>
            <h5 className="title-small gray">
                <FormattedMessage id={`home.${this.props.translationId}`} defaultMessage={this.props.title}/>
            </h5>
            <div className="document-list view-all">
                {this.renderSeparator()}
                {this.getData(this.props.values)}
            </div>
        </div>;
    }

    private getData(dataList: IHomeContentBoxDataItem[]) {
        const data: any[] = isNullOrUndefined(dataList) ? [] : dataList;
        return data.map((it, index) => this.renderItemContentRow(it, index === data.length - 1));
    }

    private renderItemContentRow(item: IHomeContentBoxDataItem, isLast: boolean) {
        return <div key={item.backstopId}>
            <div className="document-list__document" data-testid="document-list__document">
                <div>
                    {this.renderTitle(item)}
                    {this.renderDate(item)}
                </div>
                {this.renderDownloadButton(item)}
            </div>
            {isLast ? "" : this.renderSeparator()}
        </div>;
    }

    private renderTitle(item: IHomeContentBoxDataItem) {
        return this.props.shouldTitlesBeLinks ? this.renderTitleAsLink(item) : this.renderTitleNotAsLink(item);
    }

    private renderTitleAsLink(item: IHomeContentBoxDataItem) {
        return (
            <div className="document-list__document__info__title">
                <TrackedLink to={item.url} className="link-14" gaCategory="" gaAction="Link" gaLabel="">
                    {item.title}
                </TrackedLink>
            </div>);
    }

    private renderTitleNotAsLink(item: IHomeContentBoxDataItem) {
        return (<div className="document-list__document__info__title body-14">{item.title}</div>);
    }

    private renderDate(item: IHomeContentBoxDataItem) {
        if (item.date) {
            return <div className="document-list__document-date date-12" data-testid="document-list__document-date">
                {formatShortDate(item.date!)}
            </div>;
        }
    }

    private renderDownloadButton(item: IHomeContentBoxDataItem) {
        return this.props.shouldDocumentsDownload ?
            <div className="document-list__document__download fal fa-download"
                 aria-label = {`Download ${item.title}`}
                 tabIndex = {0}
                 onKeyUp={handleEnter(() => this.download(item as IHomeContentBoxDownloadableDataItem))}
                 onClick={() => this.download(item as IHomeContentBoxDownloadableDataItem)}/> :
            <div/>;
    }

    private renderSeparator() {
        return <div className="document-list__document-separator">
            <div className="spacer-dash--small"/>
            <div className="spacer-dash--small"/>
        </div>;
    }

    private download(item: IHomeContentBoxDownloadableDataItem) {
        if (item.url && item.url.trim().length > 0 && item.type === "White Paper") {
            window.open(item.url, "_blank");
        }
        else {
            item.download();
        }
    }
}

export const contentBox = (homeContentProps: IHomeContentBoxProps) =>
    <HomeContentBox title={homeContentProps.title}
                    translationId={homeContentProps.translationId}
                    values={homeContentProps.values}
                    shouldTitlesBeLinks={homeContentProps.shouldTitlesBeLinks}
                    shouldDocumentsDownload={homeContentProps.shouldDocumentsDownload}
    />;

export const connectedHomeContentBox =
    (requesterPairArray: IRequesterApiPair[],
     childInBox: () => ReactElement<HomeContentBox>) => {

        return multipleApiRequesterWrapper(
            childInBox,
            requesterPairArray,
            undefined,
            null,
        );
    };
