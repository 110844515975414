import axios, {AxiosInstance} from "axios";
import * as https from "https";
import {getConfig} from "../utils/envUtil";
import {getUserLoggingOut} from "../utils/sessionUtil";
import {planId} from "../utils/planUtil";
import {oktaSignOut} from "../utils/oktaUtil";

export class AxiosConnectionFactory {
    public static clearAxiosInstance() {
        this.axiosInstance = null;
    }

    public static getAxiosInstance(): AxiosInstance {
        if (!this.axiosInstance) {
            this.axiosInstance = this.buildAxios();
        }

        return this.axiosInstance;
    }

    public static getAxiosInstanceWithoutSessionData(token: string): AxiosInstance {
        if (!this.axiosInstance) {
            this.axiosInstance = this.buildAxiosWithoutSessionData(token);
        }

        return this.axiosInstance;
    }

    private static axiosInstance: AxiosInstance | null;

    private static buildAxios(): AxiosInstance {
        const instance = axios.create({
            baseURL: getConfig().FRONTEND_CONFIG_BACKEND_URL,
            httpsAgent: new https.Agent({}),
            withCredentials: true,
            withXSRFToken: true,
        });
        this.setResponseHandler(instance);

        this.setHeaders(instance);

        return instance;
    }

    private static setResponseHandler(instance: AxiosInstance) {
        instance.interceptors.response.use(
            (value) => {
                return value;
            },
            (error) => {
                if (!getUserLoggingOut() && error.response && error.response.status
                    && (error.response.status === 401 || error.response.status === 403)) {
                    oktaSignOut()
                        .then(() => console.error("=============> User unauthorized or forbidden"))
                        .catch((err) => {
                                // console.error("=============>oktaSignOut error: ", err);
                            }
                        );
                }

                return Promise.reject(error);
            });
    }

    private static setHeaders(instance: AxiosInstance) {
        instance.interceptors.request.use((requestConfig) => {
            // do we need this check?
            // const sessionData = getSessionData();
            // if (sessionData === null) {
            //     console.error("=============> in setHeaders. Session is null");
            // }

            requestConfig.headers.set("PlanId", planId());
            return requestConfig;
        });
    }

    private static buildAxiosWithoutSessionData(token: string): AxiosInstance {
        const instance = axios.create({
            baseURL: getConfig().FRONTEND_CONFIG_BACKEND_URL,
            httpsAgent: new https.Agent({}),
            withCredentials: true,
            withXSRFToken: true,
        });

        this.setResponseHandler(instance);

        this.setHeadersWithoutSessionData(instance, token);

        return instance;
    }

    private static setHeadersWithoutSessionData(instance: AxiosInstance, token: string) {
        instance.interceptors.request.use((requestConfig) => {
            requestConfig.headers.Authorization = "Bearer " + token;
            return requestConfig;
        });
    }

}
