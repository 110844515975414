import {Map} from "immutable";
import {Action, handleActions} from "redux-actions";
import {FlashReportSummary} from "../../model/FlashReportSummary.model";
import {NOT_REQUESTED, REQUEST_SUCCEEDED, REQUESTED, RequestState} from "../common/commonStates";
import {AllFlashReportsActions} from "./AllFlashReportsActions";

export interface IAllFlashReportsStore {
    allFlashReportSummaries: RequestState<Map<number, FlashReportSummary>>;
}

export const initialState: IAllFlashReportsStore = {
    allFlashReportSummaries: NOT_REQUESTED,
};

export function allFlashReportsSummariesInner(state: IAllFlashReportsStore):
    RequestState<Map<number, FlashReportSummary>> {
    return state.allFlashReportSummaries;
}

const allFlashReportSummariesRequested = (state: IAllFlashReportsStore): IAllFlashReportsStore => {
    return {
        ...state,
        allFlashReportSummaries: REQUESTED,
    };
};

const allFlashReportSummariesReceived = (state: IAllFlashReportsStore,
                                         action: Action<Map<number, FlashReportSummary>>): IAllFlashReportsStore => {
    return {
        ...state,
        allFlashReportSummaries: REQUEST_SUCCEEDED(action.payload!),
    };
};

const reducerMap = {
    [AllFlashReportsActions.ALL_FLASH_REPORT_SUMMARIES_REQUESTED]: allFlashReportSummariesRequested,
    [AllFlashReportsActions.ALL_FLASH_REPORT_SUMMARIES_RECEIVED]: allFlashReportSummariesReceived,
};

type Payloads = Map<number, FlashReportSummary>;
export default handleActions<IAllFlashReportsStore, Payloads>(reducerMap, initialState);
