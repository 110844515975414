import {AxiosResponse} from "axios";
import {UserDetails} from "../model/UserDetails.model";
import {throwErrorOnNullOrUndefined} from "../utils/errorUtil";
import {ApiBase} from "./ApiBase";
import {AxiosConnectionFactory} from "./AxiosConnectionFactory";
import {IApiResult} from "./AxiosWealthManagementApi";

export async function getUserDetailsFromBackend(token?: string):
    Promise<UserDetails> {
    let userDetailsPromise: UserDetails;

    if (token) {
        AxiosConnectionFactory.clearAxiosInstance();
        userDetailsPromise = await ApiBase.processGetWithToken<UserDetails>(
            "/users/loggedin",
            reader,
            token,
        ).then(
            (apiResult: IApiResult<UserDetails>) => Promise.resolve(apiResult.data!),
            (reason) => Promise.reject("In getUserDetailsFromBackend: " + reason),
        );
    } else {
        userDetailsPromise = await ApiBase.processGet<UserDetails>(
            "/users/loggedin",
            reader,
        ).then(
            (apiResult: IApiResult<UserDetails>) => Promise.resolve(apiResult.data!),
            (reason) => Promise.reject("In getUserDetailsFromBackend: " + reason),
        );
    }

    AxiosConnectionFactory.clearAxiosInstance();
    return userDetailsPromise;
}

function reader(axiosResponse: AxiosResponse<UserDetails>): UserDetails {
    const userDetailsFromResponse = axiosResponse.data;
    const {firstName, username, userType} = userDetailsFromResponse;
    return new UserDetails(
        throwErrorOnNullOrUndefined(firstName, "firstName"),
        throwErrorOnNullOrUndefined(username, "username"),
        throwErrorOnNullOrUndefined(userType, "userType"),
    );
}
