import * as React from "react";
import {useEffect} from "react";
import {IOddProduct, IOddProductsAccess, IOddQuestionSummary, IOddResponse} from "../../../api/OddIqApi";
import {isIE, scrollElementToTop, scrollToElement} from "../../../utils/browserUtil";
import {compareInsensitive} from "../../../utils/listUtil";
import {getSuccessData} from "../../common/commonStates";
import {oddProductsRequestPairs, oddProductsAccessSelector, oddPortfolioView} from "../OddIqRequesterPairs";
import {connect} from "react-redux";
import {multipleApiRequesterWrapper} from "../../common/MultipleApiRequesterWrapper";
import {Link} from "react-router-dom";
import {getPortfolioOddProducts} from "../OddIqUtils";
import {IApplicationRootState} from "../../../../applicationState";

export interface IOddStrategiesWithExceptionsPropsFromParent {
    oddExceptionResponses: IOddResponse[];
    currentQuestionId: number;
    oddQuestionSummaries?: IOddQuestionSummary[];
}

export interface IOddStrategiesWithExceptionsPropsFromStore {
    oddProductsAccess: IOddProductsAccess;
    oddPortfolioView: boolean;
}

export type IOddStrategiesWithExceptionsProps = IOddStrategiesWithExceptionsPropsFromParent &
    IOddStrategiesWithExceptionsPropsFromStore;

export const OddStrategiesWithExceptions: React.FunctionComponent<IOddStrategiesWithExceptionsProps> = (props) => {
    const responsesForQuestion = props.oddExceptionResponses.filter((response) =>
        response.questionId === props.currentQuestionId);

    const exceptionProductIds = props.oddQuestionSummaries
        ? props.oddQuestionSummaries!.
            filter((summary) => summary.questionId === props.currentQuestionId)[0]
            .exceptionProducts
        : [];

    const oddProducts = props.oddProductsAccess.allAndPortfolioAccess && props.oddPortfolioView
        ? getPortfolioOddProducts(props.oddProductsAccess)
        : props.oddProductsAccess.accessibleProducts;

    function renderProductName(product: IOddProduct) {
        return <Link className="clickable odd-summary__product-link" to={`/odd-products/${product.vehicleId}`}>
            <span className="odd-summary__product-name">{product.name}</span>
        </Link>;
    }

    function renderProductRows() {
        const products: IOddProduct[] = props.oddQuestionSummaries
            ? oddProducts.filter((product) => exceptionProductIds.includes(product.id))
                .sort((p1: IOddProduct, p2: IOddProduct) => {
                    return compareInsensitive(p1.name, p2.name);
                })
            : oddProducts
                    .filter((product) =>
                        responsesForQuestion
                            .findIndex((response) => product.id === response.productId) > -1)
            .sort((p1: IOddProduct, p2: IOddProduct) => {
                    return compareInsensitive(p1.name, p2.name);
                });

        return products.map((product, index) => {
            return <tr key={index}>
                <td className="__product-name data">{renderProductName(product)}</td>
            </tr>;
        });
    }

    function renderTable() {
        return responsesForQuestion.length > 0 || exceptionProductIds.length > 0
            ? <table className="__strategies-with-exceptions-table">
                <thead>
                <tr>
                    <th className="column-header">Strategies with Exceptions</th>
                </tr>
                </thead>
                <tbody>
                    {renderProductRows()}
                </tbody>
            </table>
            : <div className="__no-exceptions">There are no strategies with this exception.</div>;
    }

    useEffect(() => {
        const offset = isIE() ? 133 : 100;
        scrollToElement(".__strategies-with-exceptions-table-container", offset, true);
        scrollElementToTop("#__strategies-outer-container.show");
    }, []);

    return <div className="__strategies-with-exceptions-table-container"
                data-testid="odd-strategies-with-exceptions">
        {renderTable()}
    </div>;
};

export const mapStateToProps = (state: IApplicationRootState): IOddStrategiesWithExceptionsPropsFromStore => {
    return {
        oddProductsAccess: getSuccessData(oddProductsAccessSelector(state))!,
        oddPortfolioView: oddPortfolioView(state),
    };
};

const connected = connect<IOddStrategiesWithExceptionsPropsFromStore, {}>(mapStateToProps)(OddStrategiesWithExceptions);

export default multipleApiRequesterWrapper(
    connected, [
        oddProductsRequestPairs,
        ]
);