import {FlashReportSummary} from "../../model/FlashReportSummary.model";
import {parseDate} from "../../utils/dateUtil";

export const mapFlashReportSummary = (flashReportData: any) => {
    return new FlashReportSummary(
        flashReportData.backstopId,
        flashReportData.title,
        parseDate(flashReportData.effectiveDate),
        flashReportData.managers,
        [],
        undefined,
        flashReportData.fileName);
};

export const mapFlashReportSummaryWithProducts = (flashReportData: any) => {
    return new FlashReportSummary(
        flashReportData.backstopId,
        flashReportData.title,
        parseDate(flashReportData.effectiveDate),
        flashReportData.managers,
        flashReportData.products,
        flashReportData.hasProductAttachment,
        flashReportData.fileName);
};
