import * as React from "react";
import {useState} from "react";
import {ClientsAndPlans, IClientPlanInfo} from "../../model/ClientsAndPlans.model";
import {ISelectValue} from "../common/Select.component";
import {FiltersComponent} from "../common/Filters.component";
import {AdminAllClientsTable} from "./AdminAllClientsTable";
import {ClientsAndPlansMapToArray, filterClientsAndPlans} from "./AdminAllClientsUtils";

export interface IAdminAllClientsProps {
    adminClientsInfoState: ClientsAndPlans;
}

export enum Country {
    USA = 1,
    Canada,
    UK
}

export const COUNTRY_SELECT_VALUES: ISelectValue[]= [
    {id: Country.USA, name: "USA"},
    {id: Country.Canada, name: "Canada"},
    {id: Country.UK, name: "UK"},
];

export enum FilterBy {
    CLIENT = "Client",
    PLAN = "Plan",
    PLAN_COUNTRY = "Plan Country",
    PLAN_LANGUAGE = "Plan Language",
    POWERBI_ID = "PowerBI ID"
}

export enum FilterOperator {
    CONTAINS = "Contains",
    DOESNT_CONTAIN = "Does not Contain",
    EQUALS = "Equals",
    DOESNT_EQUAL = "Does not Equal",
    NOT_EMPTY = "Not empty"
}

export interface IFilter {
    filterBy: FilterBy,
    operator: FilterOperator,
    value: string
}

export const AdminAllClients: React.FunctionComponent<IAdminAllClientsProps> = (props: IAdminAllClientsProps) => {
    const clientsAndPlans: IClientPlanInfo[] = ClientsAndPlansMapToArray(props.adminClientsInfoState);

    const [filterBy, setFilterBy] = useState<FilterBy>(FilterBy.CLIENT);
    const [filterOperator, setFilterOperator] = useState<FilterOperator>(FilterOperator.CONTAINS);
    const [filterValue, setFilterValue] = useState<string>("");
    const [filteredClientsAndPlans, setFilteredClientsAndPlans] = useState<IClientPlanInfo[]>(clientsAndPlans);

    const createSelectValue = (value: string) => {
        return {name: value, id: value};
    };

    const getFilterFields = (): ISelectValue[] => {
        return Object.values(FilterBy).map(createSelectValue);
    };

    const getFilterOperators = (): ISelectValue[] => {
        return Object.values(FilterOperator).map(createSelectValue);
    };

    const handleFilterChange = (e: any, code: string) => {
        if (code === "filter_field") {
            setFilterBy(e.target.value);
        } else if (code === "filter_operator") {
            setFilterOperator(e.target.value);
        } else if (code === "filter-value") {
            setFilterValue(e.target.value);
        }
    };

    const handleClearFilters = () => {
        setFilterBy(FilterBy.CLIENT);
        setFilterOperator(FilterOperator.CONTAINS);
        setFilterValue("");
        setFilteredClientsAndPlans(clientsAndPlans);
    };

    const handleApplyFilter = () => {
        const filter: IFilter = {
            filterBy,
            operator: filterOperator,
            value: filterValue
        };
        setFilteredClientsAndPlans(filterClientsAndPlans(
            clientsAndPlans,
            filter
        ));
    };

    const renderFilters = () => {
        return <div className="admin-all-clients__filters">
            <FiltersComponent
                filters={[
                    {
                       values: getFilterFields(),
                       selectedValue: filterBy,
                       code: "filter_field",
                       width: 150
                    },
                    {
                        values: getFilterOperators(),
                        selectedValue: filterOperator,
                        code: "filter_operator",
                        width: 250
                    },
                ]}
                onFilterChangeHandler={handleFilterChange}
                onClearFilters={handleClearFilters}
                onApplyFilter={handleApplyFilter}
                textField={{
                    values: [],
                    selectedValue: filterValue,
                    code: "filter-value",
                    width: 150
                }}
                />
        </div>;
    };

    return <div data-testid="admin-all-clients__container">
        {renderFilters()}
        <AdminAllClientsTable clientsAndPlans={filteredClientsAndPlans}/>
    </div>;
};