import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import * as React from "react";
import {useEffect, useState} from "react";
import {AonColors, LegacyColors} from "../../../../css/Colors";
import {IPlanInfo} from "../../model/ClientsAndPlans.model";
import {UserInfo} from "../../model/UserInfo.model";
import {byEmail} from "../../utils/listUtil";
import MyClientUser from "./MyClientUser.component";
import {Accordion, AccordionDetails, AccordionSummary} from "@mui/material";

export interface IMyClientPlanProps {
    plan: IPlanInfo;
    users: UserInfo[];
    expandedFromParent: boolean;
}
export const MyClientPlan: React.FunctionComponent<IMyClientPlanProps> = (props) => {
    const [expanded, setExpanded] = useState(props.expandedFromParent);

    useEffect(() => {
        setExpanded(props.expandedFromParent);
    }, [props]);

    return renderPlan();

    function renderPlan() {
        const expandIcon = <ExpandMoreIcon/>;
        return <Accordion expanded={expanded}
                          key={props.plan.name}
                          className={`my-clients__plan-panel my-clients__plan-panel__${props.plan.id}`}
                          onChange={() => {
                              setExpanded(!expanded);
                          }}
                          style={{marginTop: 0, marginBottom: 0}}
                          data-testid={`my-clients__plan-panel__${props.plan.id}`}
        >
            <AccordionSummary expandIcon={expandIcon}
                              style={{
                                  fontSize: "16px",
                                  fontWeight: expanded ? 500 : 300,
                                  backgroundColor: expanded ? AonColors.ActualWhite : LegacyColors.CardBackground,
                              }}
                              data-testid={`my-clients__plan-panel`}
            >
                {props.plan.name}
            </AccordionSummary>
            <AccordionDetails>
                {renderUsers(props.plan, props.users)}
            </AccordionDetails>
        </Accordion>;
    }

    function renderUsers(plan: IPlanInfo, userInfos: UserInfo[]) {
        return <table key={plan.id} className="my-clients__user-table" data-testid="my-clients__user-table">
            <thead>{renderHeaders()}</thead>
            <tbody>{userInfos.filter((user) => user.planIds.includes(plan.id))
                .sort(byEmail)
                .map((it, index) => renderUser(it, index, plan))}</tbody>
        </table>;
    }

    function renderUser(user: UserInfo, index: number, plan: IPlanInfo) {
        return <MyClientUser
            user={user}
            index={index}
            plan={plan}
            key={index}
        />;
    }
};

function renderHeaders() {
    return <tr className="my-clients__user-table-row my-clients__user-table-headers"
               data-testid="my-clients__user-table-row my-clients__user-table-headers">
        <th className="my-clients__user-table-column my-clients__user-info__first-name-header">
            FIRST NAME
        </th>
        <th className="my-clients__user-table-column my-clients__user-info__last-name-header">
            LAST NAME
        </th>
        <th className="my-clients__user-table-column my-clients__user-info__email-header">
            EMAIL
        </th>
        <th className="my-clients__user-table-column my-clients__user-info__user-type-header">
            ROLE
        </th>
        <th className="my-clients__user-table-column my-clients__user-info__last-login-date-header">
            LAST LOGIN
        </th>
        <th/>
    </tr>;
}
