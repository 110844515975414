import {
    A1_PASS,
    A2_PASS,
    ABOVE_AVERAGE,
    AVERAGE,
    CONDITIONAL_PASS,
    DOWNGRADED,
    EsgRating,
    FAIL,
    Movement,
    NO_CHANGE,
    NO_CURRENT_RATING,
    NO_PREVIOUS_RATING,
    NOT_RATED,
    NR_RATING,
    PASS,
    STRONG,
    UPGRADED,
    WEAK,
} from "./ratingConstants";
import {getTranslation} from "./translationUtil";

export const getMovementIconClass = (movement: Movement | null) => {
    switch (movement) {
        case Movement.UPGRADED:
            return "upgraded";
        case Movement.DOWNGRADED:
            return "downgraded";
        case Movement.NO_CHANGE:
            return "no-change";
        default:
            return "";
    }
};

export const getEsgMovementIconClass = (rating: string | null, movement: Movement | null) => {
    let esgMovement = movement;
    if(rating === "3" && movement === Movement.DOWNGRADED) esgMovement = Movement.NO_CHANGE;
    if(rating === "4" && movement === Movement.UPGRADED) esgMovement = Movement.NO_CHANGE;
    return getMovementIconClass(esgMovement);
};

export const getRatingIconClass = (rating: string | null) => {
    switch (rating) {
        case "1":
            return "weak";
        case "2":
            return "average";
        case "3":
            return "above-average";
        case "4":
            return "strong";
        default:
            return "";
    }
};

export const getEsgRatingIconClass = (rating: string | null) => {
    switch (rating) {
        case "1":
            return "limited";
        case "2":
            return "integrated";
        case "3":
            return "advanced";
        case "4":
            return "advanced";
        default:
            return "";
    }
};


export const getInformRatingIconClass = (rating: string | null) => {
    switch (rating?.toLowerCase()) {
        case "very poor":
            return "limited";
        case "poor":
            return "integrated";
        case "ok":
            return "advanced";
        default:
            return "";
    }
};

export interface IInformRating {
    rating: string,
    ratingClass: string,
    ratingText: string
}
export const INFORM_RATINGS: IInformRating[] = [
    {rating: "very poor", ratingClass: "limited", ratingText: "The component or factor in isolation fails to meet our desired criteria."},
    {rating: "poor", ratingClass: "integrated", ratingText: "The component or factor in isolation falls short in meeting our desired criteria."},
    {rating: "ok", ratingClass: "advanced", ratingText: "The component or factor in isolation meets our desired criteria."},
    {rating: "", ratingClass: "", ratingText: ""},
];

export const verbosePreviousRatingMessage = (currentRating: string | null,
                                             previousRating: string | null): string => {
    if (currentRating === "" || currentRating === null || currentRating === NR_RATING) {
        return getTranslation("research-product.rating-no-current",  NO_CURRENT_RATING);
    }

    if (previousRating === "" || previousRating === null || previousRating === NR_RATING) {
        return getTranslation("research-product.rating-no-previous", NO_PREVIOUS_RATING);
    }

    if (previousRating === currentRating) {
        return getTranslation("research-product.rating-no-change", NO_CHANGE);
    }

    if (isNumeric(currentRating) && isNumeric(previousRating)) {
        return getTrend(currentRating, previousRating);
    } else {
        return getODDTrend(currentRating, previousRating);
    }
};

const isNumeric = (obj: any) => {
    return (obj - parseFloat(obj) + 1) >= 0;
};

const getODDTrend = (currentRating: string, previousRating: string): string => {
    const currentRatingRanks = getODDRatingRanksAscending(currentRating);
    const previousRatingRanks = getODDRatingRanksAscending(previousRating);

    if (currentRatingRanks !== null && previousRatingRanks !== null) {
        if (currentRatingRanks[0] > previousRatingRanks[previousRatingRanks.length - 1]) {
            return getTranslation("research-product.upgraded", UPGRADED);
        } else if (currentRatingRanks[currentRatingRanks.length - 1] < previousRatingRanks[0]) {
            return getTranslation("research-product.downgraded", DOWNGRADED);
        } else {
            return getTranslation("research-product.rating-no-change", NO_CHANGE);
        }
    }

    return getTranslation(`research-product-rating.${previousRating}`, previousRating);
};

const getTrend = (currentRating: string, previousRating: string): string => {
    if (parseInt(currentRating, 10) > parseInt(previousRating, 10)) {
        return getTranslation("research-product.upgraded", UPGRADED);
    }

    if (parseInt(currentRating, 10) < parseInt(previousRating, 10)) {
        return getTranslation("research-product.downgraded", DOWNGRADED);
    }

    return getTranslation(`research-product-rating.${previousRating}`, previousRating);
};

const getODDRatingRanksAscending = (oddRating: string | null): number[] | null => {
    const orderedRatingCategories = [[FAIL], [CONDITIONAL_PASS], [A2_PASS, PASS], [A1_PASS, PASS]];
    const ratings: number[] = [];

    orderedRatingCategories.forEach((ratingCategory: string[], ratingIndex: number) => {
        if (ratingCategory.find((rating: string) => rating.toLowerCase() === oddRating!.toLowerCase())) {
            ratings.push(ratingIndex);
        }
    });

    if (ratings.length === 0) {
        return null;
    }

    return ratings.sort();
};

export const splitLines = (text: string): string[] => {
    let reduced = replaceAll(text, "\r\n", "\n");
    reduced = replaceAll(reduced, "\r", "\n");
    return reduced.split("\n");
};

export const replaceAll = (input: string, search: string, replacement: string) => {
    return input.split(search).join(replacement);

};

export function getRatingText(currentRatingText: string | null): string {
    if (currentRatingText === null) {
        return "";
    }

    switch (currentRatingText.toLowerCase()) {
        case "nr":
            return getTranslation("product-sub-component-rating.Not-Rated", NOT_RATED);
        case "1":
            return getTranslation("product-sub-component-rating.Weak", WEAK);
        case "2":
            return getTranslation("product-sub-component-rating.Average", AVERAGE);
        case "3":
            return getTranslation("product-sub-component-rating.Above-Average", ABOVE_AVERAGE);
        case "4":
            return getTranslation("product-sub-component-rating.Strong", STRONG);
        default:
            return getTranslatedRatingText(currentRatingText);
    }
}

export function getEsgRatingText(currentRatingText: string | null): string {
    if (currentRatingText === null) {
        return "";
    }

    switch (currentRatingText.toLowerCase()) {
        case "nr":
            return getTranslation("product-sub-component-rating.Not-Rated", NOT_RATED);
        case "1":
            return getTranslation("product-sub-component-rating.Limited", EsgRating.LIMITED);
        case "2":
            return getTranslation("product-sub-component-rating.Integrated", EsgRating.INTEGRATED);
        case "3":
            return getTranslation("product-sub-component-rating.Advanced", EsgRating.ADVANCED);
        case "4":
            return getTranslation("product-sub-component-rating.Advanced", EsgRating.ADVANCED);
        default:
            return getTranslatedRatingText(currentRatingText);
    }
}

function getTranslatedRatingText(currentRatingText: string) {
    switch (currentRatingText) {
        case "Alert":
            return getTranslation( "product-sub-component-rating.Alert", "Alert");
        case "Pass":
            return getTranslation( "product-sub-component-rating.Pass", "Pass");
        case "Not Assessed":
            return getTranslation( "product-sub-component-rating.Not Assessed", "Not Assessed");
        default:
            return currentRatingText;
    }
}

export function truncateText(input: string | null | undefined, length: number) {
    if (!input || input.length <= length) {
        return input;
    }
    const lastSpaceIndex = input.substr(0, length).lastIndexOf(" ");
    return (lastSpaceIndex === -1)
        ? `${input.substr(0, length)}...`
        : `${input.substr(0, lastSpaceIndex)}...`;
}

export const getInformRatingTranslatedComments = (rating: string | null, comments: string | null) => {
    switch (rating) {
        case "Pass":
            return getTranslation(
                "product-inform-comments.Pass",
                "This component in isolation meets or exceeds our desired criteria.");
        case "Alert":
            return getTranslation(
                "product-inform-comments.Alert",
                "This component in isolation does not meet our desired criteria, or the lack of data on this component means that we are not able to judge whether it meets our desired criteria.");
        case"Not Assessed":
            return getTranslation(
                "product-inform-comments.Not Assessed",
                "There is a lack of data, which means that we are not able to assess this component, however we do not consider this in isolation to justify an Alert.");
        default: return comments;
    }
};
