import * as React from "react";
import {DefaultDisclaimer} from "./DefaultDisclaimer";
import {hasAonTrustCompany} from "../../../mainReducerMapSelectors";
import {connect} from "react-redux";
import {AonTrustCompanyDisclaimer} from "./AonTrustCompanyDisclaimer";
import {IApplicationRootState} from "../../../applicationState";

export interface IDisclaimerPageProps {
    aonTrustCompany: boolean
}
export const DisclaimerPage: React.FunctionComponent<IDisclaimerPageProps> = (props) => {
    return props.aonTrustCompany ? <AonTrustCompanyDisclaimer/> : <DefaultDisclaimer/>;
};

export const mapStateToProps = (state: IApplicationRootState): IDisclaimerPageProps => {
    return {
        aonTrustCompany: hasAonTrustCompany(state)
    };
};

const connected = connect<IDisclaimerPageProps>(mapStateToProps)(DisclaimerPage);

export default connected;
