import {Map} from "immutable";
import * as React from "react";
import {connect} from "react-redux";
import {AnyAction, bindActionCreators, Dispatch} from "redux";
import {allFlashReportsSummariesSelector} from "../../../mainReducerMapSelectors";
import {ApiError} from "../../model/ApiError.model";
import {FlashReport} from "../../model/FlashReport.model";
import {FlashReportSummary} from "../../model/FlashReportSummary.model";
import {ErrorComponent} from "../base/Error.component";
import {getSuccessData} from "../common/commonStates";
import {allFlashReportsSummariesRequestPair} from "../common/RequesterPairs";
import FlashReportComponent from "./FlashReport.component";
import {flashReportActions, getFlashReport} from "./flashReportActions";
import {multipleApiRequesterWrapper} from "../common/MultipleApiRequesterWrapper";
import {IApplicationRootState} from "../../../applicationState";

export interface IFlashReportPagePropsFromActions {
    actions: {
        getFlashReport: typeof flashReportActions.getFlashReport;
    };
}

export interface IFlashReportPagePropsFromStore {
    allFlashReportSummaries: Map<number, FlashReportSummary>;
    flashReport: FlashReport;
    error?: ApiError;
}

export interface IFlashReportPagePropsFromParent {
    match: {
        params: {
            flashId: string;
        };
    };
}

export type IFlashReportPageProps = IFlashReportPagePropsFromParent
    & IFlashReportPagePropsFromActions
    & IFlashReportPagePropsFromStore;

export class FlashReportPage extends React.Component<IFlashReportPageProps> {

    public render() {
        const {error, flashReport, allFlashReportSummaries} = this.props;

        if (error) {
            return <ErrorComponent/>;
        }

        const summary = allFlashReportSummaries.get(this.getFlashId());
        if (!summary) {
            return <ErrorComponent/>;
        }

        return <div className="wealth-parent main-content" data-testid="wealth-parent main-content">
            <FlashReportComponent {...{
                id: "flash-report-id-1",
                flashReport,
                flashReportSummary: summary,
            }}/>
        </div>;
    }

    public componentDidMount() {
        this.props.actions.getFlashReport(this.getFlashId());
    }

    private getFlashId() {
        return parseInt(this.props.match.params.flashId, 10);
    }
}

export const mapStateToProps = (state: IApplicationRootState): IFlashReportPagePropsFromStore => {
    return {
        allFlashReportSummaries: getSuccessData(allFlashReportsSummariesSelector(state))!,
        flashReport: state.flashReportState!.flashReport!,
        error: state.flashReportState!.error,
    };
};

export const mapDispatchToProps = (dispatch: Dispatch<AnyAction>): IFlashReportPagePropsFromActions => {
    return {
        actions: bindActionCreators({
            getFlashReport,
        }, dispatch),
    };
};

const connectedComponent = connect<IFlashReportPagePropsFromStore, IFlashReportPagePropsFromActions>(
    mapStateToProps,
    mapDispatchToProps,
)(FlashReportPage);

export default multipleApiRequesterWrapper(
    connectedComponent,
    [
        allFlashReportsSummariesRequestPair,
    ],
);
