import {store} from "../../configureStore";
import {currentPlan} from "../../mainReducerMapSelectors";
import {hasConsultantLevelAccess} from "./sessionUtil";

export const planName = (): string => {
    return currentPlan(store.getState())?.name ?? "Plan is not set";
};

export const planId = (): string => {
    const id = currentPlan(store.getState())?.id;
    return id?.toString() ?? "Plan is not set";
};

export const showMasterLectureSeries = (): boolean => {
    const isRightPlan = planName() === "Empower Results";
    return (hasConsultantLevelAccess() && isRightPlan);
};