import * as React from "react";
import {Product} from "../../../model/product/Product.model";
import {IOddResponse, IOddSectionsQuestions} from "../../../api/OddIqApi";
import {FormattedMessage} from "react-intl";
import {RatingStatus} from "../rating/RatingStatus.component";
import {AllOddDocumentsByProduct} from "./AllOddDocumentsByProduct";
import {PotentialRiskAreas} from "../../odd-iq/odd-product-page/PotentialRiskAreas";
import {ExceptionsBreakdown} from "../../odd-iq/odd-product-page/ExceptionsBreakdown.component";
import {Map as ImmutableMap} from "immutable";
import {formatShortDate} from "../../../utils/dateUtil";
import moment = require("moment");
import {OddIqMethodologyComponent} from "../../odd-iq/OddIqMethodology.component";
import {NR_RATING} from "../../../utils/ratingConstants";

export interface IODDProps {
    product: Product;
    oddIqEnabled: boolean;
    oddProductQuestionResponse: IOddResponse[] | null;
    oddSectionsQuestions: IOddSectionsQuestions | null ;
    oddProductDateResponded: ImmutableMap<number, string> | null;
}

export const OperationalDueDiligence: React.FunctionComponent<IODDProps> =
    (props) => {

    const {oppDueDiligenceRatings, currentRating} = props.product;
    const rating = oppDueDiligenceRatings
        ? oppDueDiligenceRatings!.rating!
        : undefined;

    const hasRating = rating && rating !== NR_RATING;
    const productResponses = props.oddProductQuestionResponse
        ?.filter((responses) => responses.productBackstopId === props.product.backstopId)
        ?? [];

    function renderSeparator() {
        return <div className="product__document-separator">
            <div className="spacer-dash--small"/>
        </div>;
    }

    function hasOddDetails() {
        const hasMProductResponse = !!props.oddProductQuestionResponse
            ?.find((response) => response.productBackstopId === props.product.backstopId);

        const hasData =
            props.oddProductDateResponded &&
            props.oddProductQuestionResponse &&
            props.oddSectionsQuestions;

        return hasData && hasMProductResponse;
    }

    const renderDateResponded = () => {
        const dateResponded = props.oddProductDateResponded?.get(props.product.backstopId);

        if (!dateResponded) {
            return null;
        }

        return <div className="product-odd__iq-date-responded">
            Data Collection Date: {formatShortDate(moment(dateResponded).toDate())}
        </div>;
    };

    function renderOddIq() {
        return  props.oddIqEnabled && hasOddDetails()
            ? <div className="product-odd__iq-container">
                <div className="product-odd__iq-title-container">
                    <div className="product-odd__iq-title">
                        <h2>Potential Risk Areas or Exceptions</h2>
                    </div>
                    <OddIqMethodologyComponent mode="Product"/>
                </div>
                {renderDateResponded()}
                <PotentialRiskAreas responsesForProduct={productResponses}
                                    oddSectionsQuestions={props.oddSectionsQuestions!} />

                <ExceptionsBreakdown
                    oddSectionsQuestions={props.oddSectionsQuestions!}
                    oddProductQuestionResponse={productResponses}/>
            </div>
            : null;
    }

    function renderRating() {
        return hasRating
        ?  <div>
                <RatingStatus {...{
                    currentRating: oppDueDiligenceRatings!.rating,
                    currentRatingDate: oppDueDiligenceRatings!.ratingEffectiveDate,
                    previousRating: oppDueDiligenceRatings!.ratingPrevious,
                    previousRatingDate: oppDueDiligenceRatings!.previousRatingEffectiveDate,
                    ratingType: currentRating.ratingType,
                    isOperationalDueDiligenceRating: true,
                    hasOddDetails: false,
                    productBackstopId: props.product.backstopId,
                    showTooltip: true,
                    oddIqEnabled: props.oddIqEnabled,
                }} />
            </div>
            : null;
    }

    return <div id="product-summary-odd__container" className="product__preview-container" data-testid="product__odd">
                {renderRating()}
                <div className="product__preview">
                    <div className="rating-comments__container">
                        <div className="rating-comments__paragraph">
                                    {hasRating ? oppDueDiligenceRatings!.ratingComments : null}
                        </div>
                    </div>
                    <div className={hasRating ? "product__documents-container" : "product__documents-container not-rated"}>
                            <h2 className="gray">
                                <FormattedMessage
                                    id="product.documents"
                                    defaultMessage="Documents"
                                />
                            </h2>
                            {renderSeparator()}
                            <AllOddDocumentsByProduct productBackstopId={props.product.backstopId} />
                    </div>
                </div>
                {renderOddIq()}
            </div>;
};
