import {IRequesterApiPair} from "../common/MultiDataGenericRequester";
import {
    requestOddDocuments,
    requestOddManagers,
    requestOddProductDateRespondedForCurrentPlan,
    requestOddProducts,
    requestOddResponsesByBackstopForCurrentPlan,
    requestOddSectionsAndQuestions
} from "./OddIqActions";
import {RequestState} from "../common/commonStates";
import {OperationalDueDiligenceDocument} from "../../model/OperationalDueDiligenceDocument.model";
import {
    oddCurrentQuestionIdInner,
    oddDocumentsSelectorInner,
    oddManagersSelectorInner, oddPortfolioViewInner,
    oddProductDateRespondedSelectorInner,
    oddProductsAccessSelectorInner,
    oddResponsesByBackstopInner,
    operationalDueDiligenceQuestionSummaryInner,
    operationalDueDiligenceSectionsQuestionsInner
} from "./OddIqReducer";
import {
    IOddManager,
    IOddProductsAccess,
    IOddResponse,
    IOddSectionsQuestions,
    IOddResponseSummary
} from "../../api/OddIqApi";
import {Map} from "immutable";
import {IApplicationRootState} from "../../../applicationState";


export const oddDocumentsSelector =
    (state: IApplicationRootState): RequestState<OperationalDueDiligenceDocument[]> => {
        return oddDocumentsSelectorInner(state.oddIQStore!);
};

export const oddManagersSelector =
(state: IApplicationRootState): RequestState<IOddManager[]> => {
    return oddManagersSelectorInner(state.oddIQStore!);
};

export const oddProductsAccessSelector =
    (state: IApplicationRootState): RequestState<IOddProductsAccess> => {
        return oddProductsAccessSelectorInner(state.oddIQStore!);
    };

export const operationalDueDiligenceQuestionSummarySelector =
    (state: IApplicationRootState): RequestState<IOddResponseSummary> => {
        return operationalDueDiligenceQuestionSummaryInner(state.oddIQStore!);
    };

export const operationalDueDiligenceSectionsQuestionsSelector =
    (state: IApplicationRootState): RequestState<IOddSectionsQuestions> => {
        return operationalDueDiligenceSectionsQuestionsInner(state.oddIQStore!);
    };

export const oddResponsesByBackstopSelector =
    (state: IApplicationRootState): RequestState<IOddResponse[]> => {
        return oddResponsesByBackstopInner(state.oddIQStore!);
    };

export const oddProductDateRespondedSelector = (state: IApplicationRootState): RequestState<Map<number, string>> => {
    return oddProductDateRespondedSelectorInner(state.oddIQStore);
};

export const oddCurrentQuestionId = (state: IApplicationRootState): number | undefined => {
    return oddCurrentQuestionIdInner(state.oddIQStore);
};

export const oddPortfolioView = (state: IApplicationRootState): boolean => {
    return oddPortfolioViewInner(state.oddIQStore);
};

export const oddDocumentsRequestPair: IRequesterApiPair = {
    apiRequest: requestOddDocuments,
    selector: oddDocumentsSelector,
};

export const oddManagersRequestPair: IRequesterApiPair = {
    apiRequest: requestOddManagers,
    selector: oddManagersSelector,
};

export const oddSectionsQuestionsRequestPair: IRequesterApiPair = {
    apiRequest: requestOddSectionsAndQuestions,
    selector: operationalDueDiligenceSectionsQuestionsSelector,
};

export const oddResponsesByBackstopForCurrentPlan: IRequesterApiPair = {
    apiRequest: requestOddResponsesByBackstopForCurrentPlan,
    selector: oddResponsesByBackstopSelector,
};

export const oddProductDateRespondedPair: IRequesterApiPair = {
    apiRequest: requestOddProductDateRespondedForCurrentPlan,
    selector: oddProductDateRespondedSelector,
};

export const oddProductsRequestPairs: IRequesterApiPair = {
    apiRequest: requestOddProducts,
    selector: oddProductsAccessSelector,
};