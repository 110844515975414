import * as React from "react";
import {ISelectValue, SelectComponent} from "./Select.component";
import {FormattedMessage} from "react-intl";
import {formattedMessageTransform} from "../../utils/translationUtil";
import {handleEnter} from "../../utils/commonUtil";
import {TextFieldComponent} from "./TextField.component";

export interface IFiltersComponent {
    filters: IFilter[];
    onFilterChangeHandler: (e: any, filterCode: string) => void;
    onClearFilters: () => void;
    textField?: IFilter;
    onApplyFilter?: () => void;
}

export interface IFilter {
    values: ISelectValue[];
    selectedValue: string | number | null;
    code: string;
    width: number;
}

export const FiltersComponent: React.FunctionComponent<IFiltersComponent> = (props) => {
    return <div className="filters-component__container">
        <div className="filters-component__filters-container">
            <div className="filters-component__filters-label">
                <FormattedMessage id="filters.label" defaultMessage="Filter:"/>
            </div>
            <div className="filters-component__filters">
                {props.filters.map(renderSelect)}
                {renderTextField(props.textField)}
                {renderApplyFilter()}
                <a className="clear-filters filters-component__clear-filters link-14 clickable"
                   tabIndex={0}
                   onKeyUp={handleEnter(props.onClearFilters)}
                   onClick={props.onClearFilters}>
                    <FormattedMessage
                        id="filters.clear-filters"
                        defaultMessage="Clear Filters"
                    />
                </a>
            </div>

        </div>
    </div>;

    function renderSelect(filter: IFilter) {
        return <div className="filters-component__spacer" key={filter.code}>
            <SelectComponent id={`filters-component__${filter.code}`}
                             classNameSuffix={`filters-${filter.code}`}
                             width={filter.width}
                             values={filter.values}
                             selected={filter.selectedValue}
                             menuItemTransform={formattedMessageTransform}
                             handleChange={(e: any) => props.onFilterChangeHandler(e, filter.code)}/>
        </div>;
    }

    function renderTextField(textField?: IFilter) {
        return textField
        ? <div className="filters-component__spacer" key={textField.code}>
                <TextFieldComponent
                    id={"filters-component__text-field"}
                    name={textField.code}
                    className={"filters-component__text-field"}
                    type="text"
                    fullWidth={true}
                    value={textField.selectedValue!.toString()}
                    handleChange={(e: any) => props.onFilterChangeHandler(e, textField.code)}
                    placeholder={""}
                    renderRequired={false}
                />
            </div>
            : null;
    }

    function renderApplyFilter() {
        return props.onApplyFilter
        ? <a className="filters-component__apply-filters link-14 clickable"
             tabIndex={0}
             onKeyUp={handleEnter(props.onApplyFilter!)}
             onClick={props.onApplyFilter!}>
                <FormattedMessage
                    id="filters.apply-filters"
                    defaultMessage="Apply Filters"
                />
            </a>
            : null;
    }
};
