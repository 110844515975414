import {Map} from "immutable";
import {Action, handleActions} from "redux-actions";
import {CapitalMarketAssumption} from "../../model/thought-leadership/CapitalMarketAssumption";
import {MediumTermView} from "../../model/thought-leadership/MediumTermView.model";
import {IMarketOutlookDocument} from "../../model/thought-leadership/MarketOutlookDocument.model";
import {WhitePaper} from "../../model/thought-leadership/WhitePaper.model";
import {assertNever} from "../../utils/errorUtil";
import {NOT_REQUESTED, REQUEST_FAILED, REQUEST_SUCCEEDED, REQUESTED, RequestState} from "../common/commonStates";
import {
    ThoughtLeadershipActions,
    ThoughtLeadershipDataRequested,
    ThoughtLeadershipDataResponse,
    ThoughtLeadershipRequestError,
} from "./ThoughtLeadershipActions";

export interface IThoughtLeadershipState {
    capitalMarketAssumptionsState: RequestState<Map<number, CapitalMarketAssumption>>;
    whitePapersState: RequestState<WhitePaper[]>;
    mediumTermViewsState: RequestState<MediumTermView[]>;
    quarterlyInvestmentOutlookState: RequestState<IMarketOutlookDocument[]>;
    marketBackgroundsState: RequestState<IMarketOutlookDocument[]>
}

export const initialState: IThoughtLeadershipState = {
    capitalMarketAssumptionsState: NOT_REQUESTED,
    whitePapersState: NOT_REQUESTED,
    mediumTermViewsState: NOT_REQUESTED,
    quarterlyInvestmentOutlookState: NOT_REQUESTED,
    marketBackgroundsState: NOT_REQUESTED,
};

export const capitalMarketAssumptionsInner =
    (state: IThoughtLeadershipState): RequestState<Map<number, CapitalMarketAssumption>> => {
    return state.capitalMarketAssumptionsState;
};

export const mediumTermViewsInner = (state: IThoughtLeadershipState): RequestState<MediumTermView[]> => {
    return state.mediumTermViewsState;
};

export const whitePapersInner = (state: IThoughtLeadershipState): RequestState<WhitePaper[]> => {
    return state.whitePapersState;
};

export const quarterlyInvestmentOutlookInner = (state?: IThoughtLeadershipState):
    RequestState<IMarketOutlookDocument[]> => {
    return state ? state.quarterlyInvestmentOutlookState : NOT_REQUESTED;
};

export const marketBackgroundsInner = (state?: IThoughtLeadershipState):
    RequestState<IMarketOutlookDocument[]> => {
    return state ? state.marketBackgroundsState : NOT_REQUESTED;
};

const handleThoughtLeadershipDataRequested =
    (state: IThoughtLeadershipState, action: Action<ThoughtLeadershipDataRequested>): IThoughtLeadershipState => {
        const payload = action.payload!;
        switch (payload.kind) {
            case "MTV": return {...state, mediumTermViewsState: REQUESTED};
            case "CMA": return {...state, capitalMarketAssumptionsState: REQUESTED};
            case "White Paper": return {...state, whitePapersState: REQUESTED};
            case "QIO": return {...state, quarterlyInvestmentOutlookState: REQUESTED};
            case "MB": return {...state, marketBackgroundsState: REQUESTED};
            default: return assertNever(payload); // error here if there are missing cases
        }
    };

const handleThoughtLeadershipDataReceived =
    (state: IThoughtLeadershipState, action: Action<ThoughtLeadershipDataResponse>): IThoughtLeadershipState => {
    const payload = action.payload!;
    switch (payload.kind) {
        case "MTV": return {...state, mediumTermViewsState: REQUEST_SUCCEEDED(payload.data)};
        case "CMA": return {...state, capitalMarketAssumptionsState: REQUEST_SUCCEEDED(payload.data)};
        case "White Paper": return {...state, whitePapersState: REQUEST_SUCCEEDED(payload.data)};
        case "QIO": return {...state, quarterlyInvestmentOutlookState: REQUEST_SUCCEEDED(payload.data)};
        case "MB": return {...state, marketBackgroundsState: REQUEST_SUCCEEDED(payload.data)};
        default: return assertNever(payload); // error here if there are missing cases
    }
};

const handleThoughtLeadershipError =
    (state: IThoughtLeadershipState, action: Action<ThoughtLeadershipRequestError>): IThoughtLeadershipState => {
        const payload = action.payload!;
        switch (payload.kind) {
            case "MTV": return {...state, mediumTermViewsState: REQUEST_FAILED(payload.error)};
            case "CMA": return {...state, capitalMarketAssumptionsState: REQUEST_FAILED(payload.error)};
            case "White Paper": return {...state, whitePapersState: REQUEST_FAILED(payload.error)};
            case "QIO": return {...state, quarterlyInvestmentOutlookState: REQUEST_FAILED(payload.error)};
            case "MB": return {...state, marketBackgroundsState: REQUEST_FAILED(payload.error)};
            default: return assertNever(payload); // error here if there are missing cases
        }
    };

const reducerMap = {
    [ThoughtLeadershipActions.THOUGHT_LEADERSHIP_DATA_REQUESTED]: handleThoughtLeadershipDataRequested,
    [ThoughtLeadershipActions.THOUGHT_LEADERSHIP_DATA_RECEIVED]: handleThoughtLeadershipDataReceived,
    [ThoughtLeadershipActions.THOUGHT_LEADERSHIP_REQUEST_ERROR]: handleThoughtLeadershipError,
};

type Payloads = ThoughtLeadershipDataResponse | ThoughtLeadershipRequestError;
export default handleActions<IThoughtLeadershipState, Payloads>(reducerMap, initialState);
