import {ProductSummary} from "../../model/product/ProductSummary";
import {IAttributeFilter, IFilterItem} from "../../model/Research.model";
import {byDate} from "../../utils/dateUtil";
import {getConfig} from "../../utils/envUtil";
import {byName, byNameReversed} from "../../utils/listUtil";
import {BUY_CLOSED} from "../../utils/ratingConstants";
import {
    groupProductSummariesByManager,
    groupProductSummariesByManagerAndDate,
    sortProductsByName,
} from "../../utils/researchUtil";
import {
    AdditionalFilterItem,
    AssetClassFilter,
    ManagerSortOption,
    RatingTypeFilter,
} from "./research-filters/FilterAndSort.menuItems";

export function getPageSize() {
    const parsedSize = parseInt(getConfig().FRONTEND_CONFIG_RESEARCH_PAGE_SIZE, 10);

    return isNaN(parsedSize) ? 20 : parsedSize;
}

export function applyFilters(productSummaries: ProductSummary[],
                             assetClass: AssetClassFilter,
                             ratingType: RatingTypeFilter,
                             attributes?: IAttributeFilter): ProductSummary[] {

    const filteredProduct = productSummaries
            .filter((it) => isSelectedAssetClass(it, assetClass))
            .filter((it) => isSelectedRatingType(it, ratingType));

    if (attributes) {
        return attributes.attributeMap
            .reduce((output: ProductSummary[], val: IFilterItem<string>[], key: AdditionalFilterItem) => {
                    return applyAttributesFilters(output, key, val);
                }, filteredProduct);
    }
    return filteredProduct;
}

function applyAttributesFilters(
    productSummaries: ProductSummary[],
    attribute: AdditionalFilterItem,
    attributeValues: IFilterItem<string>[],
): ProductSummary[] {
    if (attributeValues.length === 0) {
        return productSummaries;
    }

    const prop =
        <T extends string>(key: T) =>
            <U extends { [P in T]: U[T] }>(value: U) =>
                (value[key] as any).toString();

    const getAttributeValue = prop(attribute.toLowerCase());

    return productSummaries
        .filter((it) => attributeValues.some((x) => x.value === getAttributeValue(it as any)));
}

function isSelectedAssetClass(product: ProductSummary, selectedAssetClass: string) {
    return selectedAssetClass === AssetClassFilter.ALL.toString() ||
        product.assetType.toString() === selectedAssetClass;
}

function isSelectedRatingType(product: ProductSummary, selectedRatingType: string) {
    return selectedRatingType === RatingTypeFilter.ALL.toString() ||
        selectedRatingType === product.currentRating ||
        (selectedRatingType === RatingTypeFilter.BUY.toString() && product.currentRating === BUY_CLOSED);
}

export function groupAndSortProducts(productSummaries: ProductSummary[], sortOption: ManagerSortOption) {
    if (shouldGroupByDate(sortOption)) {
        return groupProductSummariesByManagerAndDate(productSummaries)
            .sort(byName)
            .sort(byDate)
            .map((it) => sortProductsByName(it));
    } else {
        return groupProductSummariesByManager(productSummaries)
            .sort(shouldSortDescending(sortOption) ? byNameReversed : byName)
            .map((it) => sortProductsByName(it));
    }
}

function shouldGroupByDate(selectedSortOption: string) {
    return selectedSortOption === ManagerSortOption.RECENT_RATING.toString();
}

function shouldSortDescending(selectedSortOption: string) {
    return selectedSortOption === ManagerSortOption.ALPHA_DESC.toString();
}
