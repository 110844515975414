import * as React from "react";
import {IHolding} from "../../../api/HoldingsApi";
import {isIE} from "../../../utils/browserUtil";
import {
    cashflowGroupAndSortByDate,
    cashflowGroupAndSortByQuarter,
    cashflowSortedByKey,
    getCashflowCsvContent,
    getTitle,
} from "./CashFlowsUtils";

export const csvContentFormat = "data:text/csv;charset=utf-8,";
export interface ICashFlowsExportProps {
    holdings: IHolding[];
    isOvertime: boolean;
    quarterlySelected: boolean;
    selectedAssetClass: string;
    selectedProduct: string;
    selectedAssetClassSort: string;
    id: string;
}
export const CashFlowsExport: React.FunctionComponent<ICashFlowsExportProps> = (props) => {
    const csvContent = csvContentFormat + encodeURIComponent(getCsvContent(props.isOvertime));
    const csvFileName = getCsvExportFileName(props.isOvertime);

    return <span className="cash-flows__csv-export-button"
                 data-testid="cash-flows__csv-export-button">{renderDownloadButton()}</span>;

    function renderDownloadButton() {
        return isIE()
            ? <span className="fal fa-download clickable" onClick={downloadForIE}/>
            /* eslint-disable-next-line react/forbid-dom-props */
            : <a id={props.id} className="fal fa-download" download={csvFileName} href={csvContent}
                 data-testid="fa-download"/>;
    }

    function getCsvExportFileName(isOvertime: boolean) {
        const baseName = getTitle(isOvertime, props.selectedAssetClass, props.selectedProduct).replace(/ /g, "-");
        return isOvertime
            ? (props.quarterlySelected ? `${baseName}-cashflow-by-quarter.csv` : `${baseName}-cashflow-by-month.csv`)
            : `${baseName}-over-timeframe.csv`;
    }

    function getCsvContent(isOvertime: boolean) {
        const cashflows = isOvertime
            ? (props.quarterlySelected
                    ? cashflowGroupAndSortByQuarter(props.holdings, false)
                    : cashflowGroupAndSortByDate(props.holdings, false)
            )
            : cashflowSortedByKey(props.holdings, props.selectedAssetClass, props.selectedAssetClassSort);

        return getCashflowCsvContent(cashflows, isOvertime, props.quarterlySelected, props.selectedAssetClass);
    }

    function downloadForIE() {
        const blob = new Blob([getCsvContent(props.isOvertime)], {type: csvContentFormat});
        (window as any).navigator.msSaveOrOpenBlob(blob, csvFileName);
    }
};
