import * as React from "react";
import {useState} from "react";
import {CashFlowsApi} from "../../../api/CashFlowsApi";
import {defaultButtonStyle} from "../../common/buttonStyles";
import {RaisedButton} from "../../common/RaisedButton";
import {throwErrorOnNullOrUndefined} from "../../../utils/errorUtil";
import {IUploadComponentProps} from "../../admin/AdminUploadPage";

export const UploadHoldingsCashflow: React.FunctionComponent<IUploadComponentProps> = (props) => {
    const {isLoading, setIsLoading} = props;

    const [selectedFile, setSelectedFile] = useState<File | undefined>(undefined);
    const [uploadResult, setUploadResult] = useState<string>("");
    const [value, setValue] = useState<any>("");

    const handleFileSelection = (e: { target: { files: FileList | null, value: any } }) => {
        if (e.target.files === null) {
            return;
        }

        setSelectedFile(e.target.files[0]);
        setValue(e.target.value);
    };

    const resetFileSelection = () => {
        setIsLoading(false);
        setSelectedFile(undefined);
        setValue("");
    };

    const uploadFileToCashFlow = () => {
        setIsLoading(true);

        const formData = new FormData();
        formData.append("file", throwErrorOnNullOrUndefined(selectedFile), selectedFile!.name);

        CashFlowsApi.uploadCashFlows(formData)
            .then(({ data, error }) => {
                setUploadResult(`Upload succeeded: ${data && (!error)}`);

                resetFileSelection();
            })
            .catch((error) => {
                resetFileSelection();
                setUploadResult(`Upload failed: response: ${error.errorNumber} reason: ${error.message}`);
            });
    };

    return <div>
        Upload Cashflow {/* put a space to the left */}
        <input
            id="cashflow-upload__file-input"
            type="file"
            onChange={handleFileSelection}
            value={value}
            disabled={props.isLoading}
            data-testid="cashflow-upload__file-input"
        />
        <RaisedButton style={defaultButtonStyle}
                      primary={false}
                      disabled={isLoading || !selectedFile}
                      className="cashflow-upload__upload-button"
                      onClick={uploadFileToCashFlow}>
            Upload
        </RaisedButton>
        <div className="cashflow-upload__result" data-testid="cashflow-upload__result">
            {uploadResult}
        </div>
    </div>;
};
