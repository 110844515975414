import {InsightsApi} from "../../api/InsightsApi";
import {IHomeContentBoxDownloadableDataItem, IHomeListItem} from "../IHomeListItem";

export class WhitePaper implements IHomeListItem {

    constructor(public date: Date,
                public backstopId: number,
                public title: string,
                public fileName: string,
                public isUri: boolean,
                public fileUri: string) {}

    public transform(): IHomeContentBoxDownloadableDataItem {
            return {
                title: this.title,
                date: this.date,
                backstopId: this.backstopId,
                url: this.isUri ? this.fileUri : "",
                type: "White Paper",
                download: () => InsightsApi.getDocumentDownload(this.backstopId, this.fileName, true)
            };
    }

}
