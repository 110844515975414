export class OperationalDueDiligenceDocument {
    public static builder() {
        return new OperationalDueDiligenceBuilder();
    }

    constructor(public backstopId: number,
                public title: string,
                public filename: string | null,
                public effectiveDate: Date,
                public products: number[]
                ) {
    }

}

class OperationalDueDiligenceBuilder {
    private mBackstopId: number;
    private mTitle: string;
    private mFilename: string;
    private mEffectiveDate: Date;
    private mProducts: number[];

    public backstopId(value: number) {
        this.mBackstopId = value;
        return this;
    }

    public title(value: string) {
        this.mTitle = value;
        return this;
    }

    public filename(value: string) {
        this.mFilename = value;
        return this;
    }

    public effectiveDate(value: Date) {
        this.mEffectiveDate = value;
        return this;
    }

    public products(value: number[]) {
        this.mProducts = value;
        return this;
    }

    public build() {
        return new OperationalDueDiligenceDocument(
            this.mBackstopId || 0,
            this.mTitle || "",
            this.mFilename || "",
            this.mEffectiveDate || Date(),
            this.mProducts || [],
        );
    }
}
