import * as React from "react";
import {InReportApi} from "../../../api/InReportApi";
import {InReportSummary} from "../../../model/product/inreports/InReportSummary.model";
import {formatShortDate} from "../../../utils/dateUtil";

export interface InReportSummaryComponentProps {
    inReportSummary: InReportSummary;
    productBackstopId: number;
    linkName: string;
}

export const InReportSummaryComponent: React.FunctionComponent<InReportSummaryComponentProps> = (props) => {
    return <div className="document-list" data-testid="in-report-summary">
        <div className="document-list__document-inreport">
            <div className="document-name-container">
                <div className="inreport-name clickable link-16"
                     onClick={() => dwonloadInNewTab(props.inReportSummary)}>
                    {props.linkName}
                </div>
                <div className="document-list__document-date date-12">
                    {formatShortDate(props.inReportSummary.effectiveDate)}
                </div>
            </div>
        </div>
    </div>;

    function dwonloadInNewTab(inBriefSummary: InReportSummary) {
        InReportApi.downloadInReportPdf(
            props.productBackstopId,
            inBriefSummary.backstopId,
            inBriefSummary.filename,
        );
    }
};
