import {UserTypeEnum} from "./UserInfo.model";

export class ClientUser {
    public static builder(): ClientUserBuilder {
        return new ClientUserBuilder();
    }

    constructor(
        public id: number,
        public firstName: string,
        public lastName: string,
        public userType: UserTypeEnum,
        public email: string,
        public planIds: number[]) {}
}

class ClientUserBuilder {
    private mId: number;
    private mFirstName: string;
    private mLastName: string;
    private mEmail: string;
    private mPlanIds: number[];
    private mUserType: UserTypeEnum;

    public id(value: number) {
        this.mId = value;
        return this;
    }

    public firstName(value: string) {
        this.mFirstName = value;
        return this;
    }

    public lastName(value: string) {
        this.mLastName = value;
        return this;
    }

    public email(value: string) {
        this.mEmail = value;
        return this;
    }

    public planIds(value: number[]) {
        this.mPlanIds = value;
        return this;
    }

    public userType(value: UserTypeEnum) {
        this.mUserType = value;
        return this;
    }

    public build() {
        return new ClientUser(
            this.mId || -1,
            this.mFirstName || "firstName",
            this.mLastName || "lastName",
            this.mUserType || UserTypeEnum.UNKNOWN,
            this.mEmail || "email",
            this.mPlanIds || [],
        );
    }
}
