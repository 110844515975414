import {Map} from "immutable";
import {Action, Dispatch} from "redux";
import {ThunkAction} from "redux-thunk";
import {MeetingNotesApi} from "../../api/MeetingNotesApi";
import {MeetingNoteSummary} from "../../model/MeetingNoteSummary.model";
import {throwErrorOnNullOrUndefined} from "../../utils/errorUtil";

export enum AllMeetingNoteSummariesActions {
    ALL_MEETING_NOTE_SUMMARIES_REQUESTED = "ALL_MEETING_NOTE_SUMMARIES_REQUESTED",
    ALL_MEETING_NOTE_SUMMARIES_MAP_RECEIVED = "ALL_MEETING_NOTE_SUMMARIES_MAP_RECEIVED",
    ALL_MEETING_NOTE_SUMMARIES_REQUEST_FAILED = "ALL_MEETING_NOTE_SUMMARIES_REQUEST_FAILED",
}

const allMeetingNoteSummariesRequestedAction = () => {
    return {
        type: AllMeetingNoteSummariesActions.ALL_MEETING_NOTE_SUMMARIES_REQUESTED,
    };
};

const allMeetingNoteSummariesReceivedAction = (payload?: Map<number, MeetingNoteSummary>) => {
    return {
        type: AllMeetingNoteSummariesActions.ALL_MEETING_NOTE_SUMMARIES_MAP_RECEIVED,
        payload,
    };
};

export const getAllMeetingNoteSummaries = (dispatch: Dispatch<Action<AllMeetingNoteSummariesActions>>) => {
    dispatch(allMeetingNoteSummariesRequestedAction());

    MeetingNotesApi.requestAllMeetingNoteSummariesMap()
        .then(
            (allMeetingNoteSummaries) => {
                const payload = throwErrorOnNullOrUndefined(allMeetingNoteSummaries.data);
                dispatch(allMeetingNoteSummariesReceivedAction(payload));
            },
        ).catch((error) => {
           dispatch({
               type: AllMeetingNoteSummariesActions.ALL_MEETING_NOTE_SUMMARIES_REQUEST_FAILED,
               payload: error,
           });
        });
};

export const requestAllMeetingNoteSummaries = (): ThunkAction<void, void, null, Action<void>> => {
    return (dispatch) => {
        getAllMeetingNoteSummaries(dispatch);
    };
};
