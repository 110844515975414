import * as React from "react";
import {useEffect, useRef, useState} from "react";
import {OddReport} from "../../model/OddReport.model";
import {getScrollOffset} from "../../utils/browserUtil";
import {OddReportComponent} from "./OddReportComponent";

export interface IOddReportsPaginatorProps {
    reports: OddReport[];
    pageSize: number;
}

export const OddReportsPaginator: React.FunctionComponent<IOddReportsPaginatorProps> = (props) => {
    const [pageNumber, setPageNumber] = useState<number>(1);

    const pageNumberRef = useRef(1);

    if (props.reports.length > props.pageSize) {
        document.body.style.overflow = "scroll";
    } else {
        document.body.style.overflow = "";
    }

    const pageCount = Math.floor((props.reports.length - 1) / props.pageSize) + 1;

    const shouldLoadMore = () => {
        return pageNumberRef.current < pageCount;
    };

    const isScrollingDown = () => {
        return (window.innerHeight + getScrollOffset()) >= (document.body.offsetHeight - 500);
    };

    const addPage = () => {
        pageNumberRef.current++;
        setPageNumber(pageNumberRef.current);
    };

    const onScroll = () => {
        if (shouldLoadMore() && isScrollingDown()) {
            addPage();
        }
    };

    const renderUpdateRows = () => {
        const visibleCount = Math.min(pageNumber * props.pageSize, props.reports.length);

        return props.reports.slice(0, visibleCount)
            .map((report, index) => {
                return <OddReportComponent
                    key={report.backstopId}
                    report={report}
                    isLast={index === visibleCount - 1}
                />;
            });
    };

    useEffect(() => {
        setPageNumber(1);
        pageNumberRef.current = 1;
    }, [props]);

    useEffect(() => {
        window.addEventListener("scroll", onScroll, false);
        return () => {
            window.removeEventListener("scroll", onScroll, false);
        };
    }, []);

    return <div>
        {renderUpdateRows()}
    </div>;
};