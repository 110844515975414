import {TextField} from "@mui/material";
import * as React from "react";
import {connect} from "react-redux";
import {Action, bindActionCreators, Dispatch} from "redux";
import {navigateTo} from "../../../navigateTo";
import {AxiosWealthManagementApiImpl} from "../../api/AxiosWealthManagementApi";
import {prominentButtonStyle} from "../common/buttonStyles";
import {RaisedButton} from "../common/RaisedButton";
import feedbackPageActions, {feedbackPageAction} from "./feedbackPageAction";
import {FormattedMessage} from "react-intl";
import {getTranslation} from "../../utils/translationUtil";

export interface IFeedbackPageRootState {
    feedbackState: { feedbackText: string };
}

export interface IFeedbackPageActions {
    actions: { feedbackPageAction: typeof feedbackPageAction };
}

export interface IFeedbackPagePropsFromStore {
    feedbackText: string;
    router?: any;
}

export type IFeedbackPageProps =
    IFeedbackPageActions
    & IFeedbackPagePropsFromStore;

interface IFeedbackPageState {
    invalidSubmission: boolean;
    submitSuccess: boolean;
}

export class FeedbackPage extends React.Component<IFeedbackPageProps, IFeedbackPageState> {
    constructor(props: IFeedbackPageProps) {
        super(props);
        this.state = {
            invalidSubmission: false,
            submitSuccess: false,
        };
    }

    public componentDidUpdate() {
        if (this.state.submitSuccess) {
            navigateTo("/feedback-success");
        }
    }

    public render() {
        return <div className="main-content feedback-container" data-testid="feedback-container">
            <h1>
               <FormattedMessage id={"feedback.header"} defaultMessage = "Feedback"/>
            </h1>
            <label>
                <p className="large">
                    <FormattedMessage id={"feedback.paragraph"}
                                      defaultMessage =
                                          "We are always interested in your feedback! Please use this form to send us a message regarding your experience."
                    />
                </p>
            </label>
            <div className="text-field-container">
                <TextField id="feedback-textfield"
                           data-testid="feedback-textfield"
                           value={this.props.feedbackText}
                           name="text"
                           label={getTranslation("feedback.enter-feedback", "Enter feedback")}
                           color="primary"
                           className="feedback-text-input"
                           type="text"
                           autoFocus={true}
                           style={{fontSize: "15px", width: "100%"}}
                           multiline={true}
                           onChange={(event) => {
                               this.handleTextOnChange(event);
                           }}
                           error={this.state.invalidSubmission}
                           helperText={this.getErrorText()}
                           variant={'standard'}
                />
            </div>
            <RaisedButton
                className="feedback-page__send-button"
                style={prominentButtonStyle}
                primary={true}
                onClick={() => this.handleOnButtonClick()}>
                <FormattedMessage id="feedback.send" defaultMessage="Send" />
            </RaisedButton>
        </div>;
    }

    private getErrorText(): string {
        return this.state.invalidSubmission && !this.props.feedbackText ? "Required" : "";
    }

    private handleTextOnChange(event: any) {
        this.setState({invalidSubmission: false});

        this.props.actions.feedbackPageAction(event.target.value);
    }

    private handleOnButtonClick = () => {
        this.setState({invalidSubmission: true});

        if (this.props.feedbackText) {
            AxiosWealthManagementApiImpl.sendFeedback(this.props.feedbackText).then(() => {
                this.props.actions.feedbackPageAction("");
                this.setState({submitSuccess: true});
            });
        }
    };
}

export const mapDispatchToProps = (dispatch: Dispatch<Action<void>>): any => {
    return {actions: bindActionCreators(feedbackPageActions, dispatch)};
};

export const mapStateToProps = (state: IFeedbackPageRootState): any => {
    return {feedbackText: state.feedbackState.feedbackText};
};

const connectedComponent = connect(mapStateToProps, mapDispatchToProps)(FeedbackPage);
export default connectedComponent;
