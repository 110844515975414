import * as React from "react";
import {
    CalendarApi,
    ICalendarMeeting,
    ICalendarQuarter,
    ICalendarTemplateTopic,
    ICalendarTopic,
    ICalendarTopicToSave
} from "../../api/CalendarApi";
import {DragDropContext, DropResult} from "react-beautiful-dnd";
import {CalendarTemplate} from "./CalendarTemplate";
import {getCalendarQuarters, getTopicsToSave, hasTemplateTopic, keyToYearAndQuarter} from "./CalendarUtils";
import {CalendarQuarter} from "./CalendarQuarter";
import {Map} from "immutable";
import {CALENDAR_VIEW} from "./CalendarPage";
import {connect, useDispatch} from "react-redux";
import {calendarMeetingsSelector} from "../../../mainReducerMapSelectors";
import {getSuccessData} from "../common/commonStates";
import {multipleApiRequesterWrapper} from "../common/MultipleApiRequesterWrapper";
import {calendarMeetingsPair} from "../common/RequesterPairs";
import {setCalendarCurrentYear, updateCalendarMeetings} from "./Calendar.actions";
import {IApplicationRootState} from "../../../applicationState";

export interface ICalendarFromStoreProps {
    calendarMeetings: Map<string, ICalendarMeeting>;
}
export interface ICalendarFromParentProps {
    currentYear: number;
    onViewChange: (meetingOrTopicId: number | undefined, view: CALENDAR_VIEW) => void;
    templateTopics?: ICalendarTemplateTopic[];
}

export type CalendarProps = ICalendarFromStoreProps & ICalendarFromParentProps;

export const CalendarDragDrop: React.FunctionComponent<CalendarProps> = (props) => {

    const dispatch = useDispatch();

    const calendarYears = [props.currentYear, props.currentYear+1, props.currentYear+2];

    const calendarQuarters = getCalendarQuarters(calendarYears);

    const requestUpdatedQuarterMeeting = (quarterKey: string): Promise<ICalendarMeeting> => {
        if (props.calendarMeetings.has(quarterKey)) {
            const meeting = props.calendarMeetings.get(quarterKey)!;
            return CalendarApi.requestCalendarMeeting(meeting.id);
        } else {
            const yearAndQuarter = keyToYearAndQuarter(quarterKey);
            return CalendarApi.requestCalendarMeetingByQuarter(yearAndQuarter.year, yearAndQuarter.quarter);
        }
    };

    const updateMeetingInStore = (key: string, updatedMeeting: ICalendarMeeting) => {
        const updatedMeetings = Map<string, ICalendarMeeting>().set(key, updatedMeeting);
        updateCalendarMeetings(dispatch, updatedMeetings);
    };

    const handleDeleteTopic = (quarter: ICalendarQuarter, topic: ICalendarTopic) => {
        CalendarApi.deleteCalendarTopic(topic.id)
            .then(() => {
                requestUpdatedQuarterMeeting(quarter.key)
                    .then((result) => {
                        updateMeetingInStore(quarter.key, result);
                });
            });
    };

    const saveTopics = (topicsToSave: ICalendarTopicToSave[]) => {
        CalendarApi.createTopics(topicsToSave)
            .then((response) => {
                updateCalendarMeetings(dispatch, response);
            });
    };

    const moveTopicSave = (sourceQuarterKey: string,
                             destinationQuarterKey: string, topicToSave: ICalendarTopicToSave) => {
       CalendarApi.moveTopic(topicToSave)
            .then((response) => {
                updateCalendarMeetings(dispatch, response);
            });
    };

    const reorderTopicsSave = (quarterKey: string, meeting: ICalendarMeeting) => {
        const orderedTopicIds = meeting.topics.map((topic) => topic.id);
        CalendarApi.reorderTopics(meeting.id, orderedTopicIds)
            .then((result) => {
                updateMeetingInStore(quarterKey, result);
            });
    };

    const handleDragEnd = (result: DropResult) => {
        const { source, destination } = result;
        if (!destination) return;

        const sourceId = source.droppableId;
        const destinationId = destination!.droppableId;

        if (destinationId === "template") return;

        if (sourceId === "template") {
            const topicsToSave = getTopicsToSave(
                props.calendarMeetings,
                props.templateTopics!,
                destination.droppableId,
                destination.index,
                source.index,
                props.currentYear
            );

            saveTopics(topicsToSave);
            return;
        }

        if (destinationId === sourceId) {
            //reordering
            if(destination.index === source.index) return;

            const meeting = props.calendarMeetings.get(sourceId)!;
            const deleted = meeting.topics.splice(source.index, 1);
            meeting.topics.splice(destination.index, 0, deleted[0]);
            reorderTopicsSave(sourceId, meeting);
        } else {
            //move from one quarter to another
            const sourceMeeting = props.calendarMeetings.get(sourceId)!;
            const originalTopic = sourceMeeting.topics[source.index];
            const destinationYearAndQuarter = keyToYearAndQuarter(destinationId);
            const allowDupes = props.templateTopics!.find(x => x.id === originalTopic.templateTopicId)!.allowDupes;

            if (allowDupes || !hasTemplateTopic(originalTopic.templateTopicId, props.calendarMeetings, destinationId)) {
                const updatedTopicToSave: ICalendarTopicToSave = {
                    ...originalTopic,
                    year: destinationYearAndQuarter.year,
                    quarter: destinationYearAndQuarter.quarter,
                    order: destination.index
                };
                moveTopicSave(sourceId, destinationId, updatedTopicToSave);
            }
        }
    };

    const getQuarterMeeting = (quarter: ICalendarQuarter): ICalendarMeeting | undefined => {
        return props.calendarMeetings && props.calendarMeetings.has(quarter.key)
            ? props.calendarMeetings.get(quarter.key)
            : undefined;
    };

    const renderQuarter = (quarter: ICalendarQuarter) => {
       return <CalendarQuarter key={quarter.key}
                               calendarMeeting={getQuarterMeeting(quarter)}
                               quarter={quarter}
                               handleDelete={handleDeleteTopic}
                               onViewChange={props.onViewChange}
       />;
    };

    const renderYear = (year: number) => {
        return <div className="client-calendar__year" key={year}>
            <p className="client-calendar__year-header">{year}</p>
            {
                calendarQuarters
                    .filter((quarter) => quarter.year === year)
                    .sort((a, b) => a.quarterNumber - b.quarterNumber)
                    .map(renderQuarter)
            }
        </div>;
    };

    const prevYear = () => {
        setCalendarCurrentYear(dispatch, props.currentYear-1);
    };

    const nextYear = () => {
        setCalendarCurrentYear(dispatch, props.currentYear+1);
    };

    return  <DragDropContext onDragEnd={handleDragEnd}>
        <CalendarTemplate templateTopics={props.templateTopics!}/>
        <div className="calendar__grid">
            <div className="clickable client-calendar__years-scroll-left" onClick={prevYear}>{"<"}</div>
            {calendarYears.map(renderYear)}
            <div className="clickable client-calendar__years-scroll-right" onClick={nextYear}>{">"}</div>
        </div>
    </DragDropContext>;
};

export const mapStateToProps = (state: IApplicationRootState): ICalendarFromStoreProps => {
    return {
        calendarMeetings: getSuccessData(calendarMeetingsSelector(state))!,
    };
};

const connectedComponent = connect<ICalendarFromStoreProps>(mapStateToProps)(CalendarDragDrop);

export default multipleApiRequesterWrapper(
    connectedComponent, [
        calendarMeetingsPair
    ]
);