import * as d3 from "d3";
import * as React from "react";
import {useEffect, useState} from "react";
import {LegacyColors} from "../../../../../css/Colors";
import {parseShortDate} from "../../../utils/dateUtil";
import {
    CashflowsChartingHelper,
    chartSvg,
    getChartWidth,
    IChartVariables,
    VERTICAL_NET_BAR_WIDTH,
    verticalChartDimensions,
} from "./CashFlowsChartingUtils";
import {cashflowGroupAndSortByDate, cashflowGroupAndSortByQuarter, ICashflowsProps} from "./CashFlowsUtils";
import {formatCurrencyTicks} from "../../../utils/d3Util";

export interface ICashFlowsOverTimeProps extends ICashflowsProps {
    showInflowOutflow: boolean;
    byQuarter: boolean;
}

const suffix = "-vertical";
let chartingHelper: CashflowsChartingHelper;

export const CashFlowsOverTimeChart: React.FunctionComponent<ICashFlowsOverTimeProps> = (props) => {
    const [svgWidth, setSvgWidth] = useState(getChartWidth());

    function drawChartOfWidth() {
        chartingHelper = new CashflowsChartingHelper(
            true,
            svgWidth,
            suffix,
            verticalChartDimensions.leftAndRight,
            verticalChartDimensions.topAndBottom,
        );
        drawGraph();
    }

    useEffect(() => {
        window.addEventListener("resize", () => {
            setSvgWidth(getChartWidth());
            },
        );
    }, []);

    useEffect(() => {
        drawChartOfWidth();
    }, [svgWidth, props]);

    return <div data-testid="cash-flow__over-time-chart-container">
        <svg id={`${chartSvg}${suffix}`}
             width={svgWidth}
             height={verticalChartDimensions.height + verticalChartDimensions.topAndBottom * 2}
             transform="translate(20, 0)"/>
    </div>;

    function drawGraph() {
        chartingHelper.cleanUpGraph();
        chartingHelper.setupGraph();

        const cashFlowsByKey = props.byQuarter
            ? cashflowGroupAndSortByQuarter(props.holdings, true)
            : cashflowGroupAndSortByDate(props.holdings, true);

        chartingHelper.setCashFlowsByKey(cashFlowsByKey);

        const bars = chartingHelper.getBarsVariables(props.showInflowOutflow);

        renderXAxis1(bars[0]);
        renderXAxis2(bars[0]);
        renderYAxis(bars[0]);
        bars.map((bar, index) => renderBar(bar, props.showInflowOutflow && index === 0));
        chartingHelper.renderLegend(bars);
    }

    function renderXAxis1(chartVariables: IChartVariables) {
        const xAxis = d3.axisBottom(chartVariables.xBandScale)
            .tickSizeOuter(0);

        const formatNoYear = d3.timeFormat("%b");

        const formatTicks = (d: string) => {
            if (props.byQuarter) {
                return d.split(" ")[0];
            }

            const date = parseShortDate(d);
            return formatNoYear(date);
        };

        const displayYearChange = (d: string, idx: number) => {
            if (props.byQuarter) {
                const quarter = d.split(" ")[0];
                const year = d.split(" ")[1];
                return (idx === 0 ||  quarter === "Q1") ? year : "";
            }

            const date = parseShortDate(d);

            return (idx === 0 || date.getMonth() === 0) ? date.getFullYear() : "";
        };

        chartVariables.chartRef.append("g")
            .call(xAxis.tickFormat(formatTicks))
            .attr("transform", `translate(0,${verticalChartDimensions.height - verticalChartDimensions.topAndBottom * 2})`)
            .call((g) => g.attr("class", "cashflow-bar-chart__x-tick"))
            .selectAll(".tick")
            .append("text")
            .text(displayYearChange)
            .attr("fill", "currentColor")
            .attr("y", 37)
            .attr("font-weight", "bold");
    }

    function renderXAxis2(chartVariables: IChartVariables) {
        const xAxis2 = d3.axisBottom(chartVariables.xBandScale)
            .tickSizeInner(verticalChartDimensions.height - (verticalChartDimensions.topAndBottom * 2))
            .tickSizeOuter(0);

        const ticks = chartVariables.chartRef.append("g")
            .call(xAxis2)
            .attr("transform", `translate(0,${verticalChartDimensions.height / 2 - verticalChartDimensions.topAndBottom})`)
            .selectAll(".tick")
            .style("color", LegacyColors.LightGray);

        ticks
            .join((data) => data)
            .attr("transform", (d: any) => {
                return `translate(${chartVariables.xBandScale(d)! + chartVariables.xBandScale.bandwidth() / 2}, -${verticalChartDimensions.height / 2 - verticalChartDimensions.topAndBottom})`;
            });

        ticks.selectAll("text")
            .remove();
    }

    function renderYAxis(chartVariables: IChartVariables) {

        const [min, max] = chartVariables.yLinearScale.domain();

        const step = 7;
        const stepValue = (max - min) / (step - 1);
        const tickValues = d3.range(min, max + stepValue, stepValue);

        if (tickValues.length > 7) {
            tickValues.splice(7);
        }

        const yAxis = d3.axisLeft(chartVariables.yLinearScale)
            .tickValues(tickValues)
            .tickFormat((d: number) => formatCurrencyTicks(d, 2, true))
            .tickSizeOuter(0);

        chartVariables.chartRef.append("g")
            .attr("class", "cashflow-chart__vertical-yaxis")
            .call(yAxis)
            .selectAll(".tick");

    }

    function addRect(chartVariables: IChartVariables, barWidth: number, xAlignment: number) {
        return chartVariables.chartRef
            .selectAll("rect")
            .data(chartVariables.dataByKey)
            .join("rect")
            .attr("x", (d) => chartVariables.xBandScale(d.key!)! + xAlignment)
            .attr("y", (d) => chartVariables.yLinearScale(Math.max(0, d.value!))!)
            .attr("width", barWidth)
            .attr("height", (d) => Math.abs(chartVariables.yLinearScale(d.value!)! - chartVariables.yLinearScale(0)!))
            .attr("fill", chartVariables.barColor ? chartVariables.barColor : "red")
            .attr("class", "cash-flow-vertical-bar-chart__bar")
            .attr("key", (d) => d.key!);
    }

    function renderBar(chartVariables: IChartVariables, hideLabels: boolean) {
        const barWidth = chartVariables.barWidth ? chartVariables.barWidth : VERTICAL_NET_BAR_WIDTH;
        const xOffset = (chartVariables.xBandScale.bandwidth() - VERTICAL_NET_BAR_WIDTH) / 2;
        const xAlignment = chartVariables.barWidth
            ? VERTICAL_NET_BAR_WIDTH + xOffset
            : xOffset;

        const bar = addRect(chartVariables, barWidth, xAlignment);

        if (chartVariables.patternUrl) {
            const patternBarChartRef = d3.select(`#${chartingHelper.getCashflowPatternBarG()}`);
            const barPattern = addRect({...chartVariables, chartRef: patternBarChartRef}, barWidth, xAlignment);
            barPattern.attr("fill", `url(#${chartVariables.patternUrl})`);
        }

        const label = bar.select("g")
            .data(chartVariables.dataByKey)
            .join("g")
            .attr("transform", (d) => `translate(${chartVariables.xBandScale(d.key!)! + xAlignment + barWidth / 2}, ${chartVariables.yLinearScale(d.value!)})`);

        if (!hideLabels) {
            const getLabelTransform = (d: any) => {
                return d.value > 0 ? "-5px" : "15px";
            };

            label.append("text")
                .text((d) => formatCurrencyTicks(d.value, 3))
                .attr("text-anchor", "middle")
                .attr("dy", getLabelTransform)
                .attr("class", "cash-flow-bar-chart__amount");
        }

    }
};
