import * as React from "react";
import {AonColors, LegacyColors} from "../../../../css/Colors";
import {IProductInfo} from "../../model/product/IProductInfo";
import {Product} from "../../model/product/Product.model";
import {Card} from "../common/Card";
import {
    productCardChildrenStyle,
    productCardHeaderStyle,
    productExpandableButtonStyle,
} from "../common/cardStyles";
import {LoadingSpinner} from "../icons/LoadingSpinner.component";
import RatingIconComponent from "./icons/RatingIcon.component";
import ProductPreview from "./ProductPreview.component";
import {RatingTypeFilter} from "./research-filters/FilterAndSort.menuItems";

export interface IProductCardProps {
    productSummary: IProductInfo;
    product: Product | null;
    productExpandedCallback: (productBackstopId: number) => void;
}

export interface IProductCardState {
    isExpanded: boolean;
}

export class ProductCard extends React.Component<IProductCardProps, IProductCardState> {
    constructor(props: any) {
        super(props);

        this.state = {isExpanded: false};
    }

    public render() {
        const backstopId = this.props.productSummary.backstopId;

        return <Card
                className={`product-card product-card--${backstopId} ${this.getProductCardHoverClass()} clickable`}
                onExpandChange={() => this.handleExpandChange(this.state.isExpanded)}
                expanded={this.state.isExpanded}
                header={this.productCardTitle()}
                showExpandableButton={true}
                headerContainerStyle={{
                    ...productCardHeaderStyle,
                    backgroundColor: this.backgroundColorAtBackstopId(),
                }}
                childrenContainerStyle={{
                    ...productCardChildrenStyle,
                    backgroundColor: this.backgroundColorAtBackstopId(),
                }}
                expandableButtonStyle={productExpandableButtonStyle}>
                    {this.renderProductPreview()}
            </Card>;
    }

    private getProductCardHoverClass() {
        return this.state.isExpanded ? "" : "card--hover";
    }

    private backgroundColorAtBackstopId(): string {
        return this.state.isExpanded ? AonColors.ActualWhite : LegacyColors.CardBackground;
    }

    private handleExpandChange(expanded: boolean) {
        if (!expanded) {
            this.props.productExpandedCallback(this.props.productSummary.backstopId);
        }
        this.setState({isExpanded: !this.state.isExpanded});
    }

    private renderProductPreview() {
        const {product} = this.props;

        if (!product) {
            return <div>
                <LoadingSpinner/>
            </div>;
        }

        return <ProductPreview product={product}/>;
    }

    private productCardTitle() {
        const {productSummary} = this.props;

        return (
            <div className="product-card__header">
                <RatingIconComponent rating={productSummary.currentRating as RatingTypeFilter}/>
                <div className="product-card__product-name">{productSummary.name}</div>
            </div>
        );
    }
}
