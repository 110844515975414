import {IInformSubComponentDetails} from "./InformSubComponentDetails";

export enum InformSubcomponentType {
    BUSINESS = "Business",
    INVESTMENT_STAFF = "Investment Staff",
    INVESTMENT_PROCESS = "Investment Process",
    RISK_MANAGEMENT = "Risk Management",
    TERMS_AND_CONDITIONS = "Terms & Conditions",
    PERFORMANCE = "Performance",
    ESG = "ESG"
}

export interface IInformSubComponent {
    backstopId: number,
    strategyName: string,
    ifaRating: string,
    ifaRatingEffectiveDate: string,
    summary?: IInformSubComponentSummary,
    details?: IInformSubComponentDetails,
    type: InformSubcomponentType
}

export interface IInformSubComponentSummary {
    rating: string,
    ratingChange: string,
    upgraded: string,
    downgraded: string,
    dataCompletion: string
}

export class InformSubComponentSummary implements IInformSubComponentSummary {
    public static builder(): InformSubComponentSummaryBuilder {
        return new InformSubComponentSummaryBuilder();
    }

    constructor(
        public rating: string,
        public ratingChange: string,
        public upgraded: string,
        public downgraded: string,
        public dataCompletion: string
    ) {}
}

class InformSubComponentSummaryBuilder {
    private mRating: string;
    private mRatingChange: string;
    private mUpgraded: string;
    private mDowngraded: string;
    private mDataCompletion: string;

    public rating(value: string) {
        this.mRating = value;
        return this;
    }

    public ratingChange(value: string) {
        this.mRatingChange = value;
        return this;
    }

    public upgraded(value: string) {
        this.mUpgraded = value;
        return this;
    }

    public downgraded(value: string) {
        this.mDowngraded = value;
        return this;
    }

    public dataCompletion(value: string) {
        this.mDataCompletion = value;
        return this;
    }

    public build(): InformSubComponentSummary {
        return new InformSubComponentSummary(
            this.mRating || "Poor",
            this.mRatingChange || "No Change",
            this.mUpgraded || "0",
            this.mDowngraded || "0",
            this.mDataCompletion || "50.00%",
        );
    }
}