import {AnyAction, Dispatch} from "redux";
import {dispatchHeaderNotificationActions} from "../base/header/HeaderActions";
import {INotificationObject, NotificationTypes} from "../base/header/HeaderReducer";

export enum MasterLectureSeriesActions {
    DISPLAY_BETA_MESSAGE = "DISPLAY_BETA_MESSAGE",
    HIDE_BETA_MESSAGE = "HIDE_BETA_MESSAGE",
}

export const displayBetaMessage = (message: string) => {
    return (dispatch: Dispatch<AnyAction>) => {
        dispatch({ type: MasterLectureSeriesActions.DISPLAY_BETA_MESSAGE });

        const notification: INotificationObject = {
            message,
            notificationType: NotificationTypes.SUCCESS,
        };
        dispatchHeaderNotificationActions(notification, -1)(dispatch);
    };
};

export const hideBetaMessage = () => {
    return (dispatch: Dispatch<AnyAction>) => {
        dispatch({ type: MasterLectureSeriesActions.HIDE_BETA_MESSAGE });

        const notification: INotificationObject = {
            message: "",
            notificationType: NotificationTypes.SUCCESS,
        };
        dispatchHeaderNotificationActions(notification, 0)(dispatch);
    };
};
