import {Map} from "immutable";
import {Action, handleActions} from "redux-actions";
import {Product} from "../../model/product/Product.model";
import {ProductSummary} from "../../model/product/ProductSummary";
import {
    getSuccessData,
    NOT_REQUESTED,
    REQUEST_FAILED,
    REQUEST_SUCCEEDED,
    REQUESTED,
    RequestState,
} from "../common/commonStates";
import {ISelectValue} from "../common/Select.component";
import {AllProductsActions} from "./AllProductsActions";
import {ProductActions} from "./productActions";

export interface IAllProductsStore {
    productUniverseSummaries: RequestState<Map<number, ProductSummary>>;
    portfolioProductSummaries: RequestState<Map<number, ProductSummary>>;
    shareClasses: RequestState<ISelectValue[]>;
}

export const allProductStoreInitialState: IAllProductsStore = {
    productUniverseSummaries: NOT_REQUESTED,
    portfolioProductSummaries: NOT_REQUESTED,
    shareClasses: NOT_REQUESTED,
};

const allProductSummariesRequested = (state: IAllProductsStore): IAllProductsStore => {
    return {
        ...state,
        productUniverseSummaries: REQUESTED,
        portfolioProductSummaries: REQUESTED,
    };
};

export function getProductUniverseSummariesInner(state: IAllProductsStore) {
    return state.productUniverseSummaries!;
}

export function getPortfolioProductSummariesInner(state: IAllProductsStore) {
    return state.portfolioProductSummaries!;
}

export function shareClassesInner(state: IAllProductsStore): RequestState<ISelectValue[]> {
    return state.shareClasses;
}

export const selectProductSummariesBasedOnEditModeInner =
    (allProductsStore: IAllProductsStore,
     inPortfolioEditMode: boolean): RequestState<Map<number, ProductSummary>> => {
        return inPortfolioEditMode
        ? getProductUniverseSummariesInner(allProductsStore)
        : getPortfolioProductSummariesInner(allProductsStore);
};

const removeProductPreview = (state: IAllProductsStore, action: Action<Product>): IAllProductsStore => {
    const backstopId = action.payload!.backstopId;

    const localProductUniverseSummaries = getSuccessData(state.productUniverseSummaries!);
    const localPortfolioProductSummaries = getSuccessData(state.portfolioProductSummaries!)!;
    let newProductUniverseSummaries;
    let newPortfolioProductSummaries;

    if (localProductUniverseSummaries) {
        const product = localProductUniverseSummaries.get(backstopId)!;
        const removedProduct = {...product, inPortfolio: false};
        newProductUniverseSummaries =
            REQUEST_SUCCEEDED(localProductUniverseSummaries.remove(backstopId).set(backstopId, removedProduct));
    }

    if (localPortfolioProductSummaries) {
        newPortfolioProductSummaries = REQUEST_SUCCEEDED(localPortfolioProductSummaries.remove(backstopId));
    }

    newProductUniverseSummaries = newProductUniverseSummaries
        ? newProductUniverseSummaries : state.productUniverseSummaries;

    newPortfolioProductSummaries = newPortfolioProductSummaries
        ? newPortfolioProductSummaries : state.portfolioProductSummaries;

    return {
        ...state,
        portfolioProductSummaries: newPortfolioProductSummaries,
        productUniverseSummaries: newProductUniverseSummaries,
    };
};

const addProductToPlanPortfolio = (state: IAllProductsStore, action: Action<Product>): IAllProductsStore => {
    if (!action.payload) {
        return state;
    }

    const backstopId = action.payload.backstopId;

    let newPortfolioProductSummaries;
    let productSummaryInPortfolio;

    const localProductUniverseSummaries = getSuccessData(state.productUniverseSummaries!);

    const localPortfolioProductSummaries = getSuccessData(state.portfolioProductSummaries!)!;
    if (localProductUniverseSummaries === null || !localProductUniverseSummaries!.get(backstopId)!) {
        return state;
    } else {
        productSummaryInPortfolio = {...localProductUniverseSummaries!.get(backstopId)!, inPortfolio: true};

    }

    const newProductUniverseSummaries =
        REQUEST_SUCCEEDED(localProductUniverseSummaries.set(backstopId, productSummaryInPortfolio));

    if (localPortfolioProductSummaries) {
        newPortfolioProductSummaries =
            REQUEST_SUCCEEDED(localPortfolioProductSummaries.set(backstopId, productSummaryInPortfolio));
    }

    newPortfolioProductSummaries = newPortfolioProductSummaries
        ? newPortfolioProductSummaries : state.portfolioProductSummaries!;

    return {
        ...state,
        portfolioProductSummaries: newPortfolioProductSummaries,
        productUniverseSummaries: newProductUniverseSummaries,
    };
};

const productUniverseSummariesReceived = (
    state: IAllProductsStore,
    action: Action<Map<number, ProductSummary>>): IAllProductsStore => {
    return {
        ...state,
        productUniverseSummaries: REQUEST_SUCCEEDED(action.payload!),
        portfolioProductSummaries: REQUEST_SUCCEEDED(action.payload!.filter((it) => it!.inPortfolio).toMap()),
    };
};

export const getProductSummary =
    (props: any, backstopId: number):
        ProductSummary | undefined => {
        if (props.editingPlanPortfolio) {
        return props.productUniverseSummaries.get(backstopId);
    } else {
        return props.portfolioProductSummaries.get(backstopId);
    }
};

const reducerMap = {
    [AllProductsActions.ALL_PRODUCT_SUMMARIES_REQUESTED]: allProductSummariesRequested,
    [ProductActions.PRODUCT_REMOVED_FROM_PLAN_PORTFOLIO]: removeProductPreview,
    [ProductActions.PRODUCT_ADDED_TO_PLAN_PORTFOLIO]: addProductToPlanPortfolio,
    [AllProductsActions.PRODUCT_UNIVERSE_SUMMARIES_RECEIVED]: productUniverseSummariesReceived,
    [AllProductsActions.SHARE_CLASSES_REQUESTED]:
        (state: IAllProductsStore): IAllProductsStore => {
            return {...state, shareClasses: REQUESTED};
        },
    [AllProductsActions.REQUEST_SHARE_CLASSES_FAILED]:
        (state: IAllProductsStore, action: Action<any>): IAllProductsStore => {
            return {...state, shareClasses: REQUEST_FAILED(action.payload)};
        },
    [AllProductsActions.REQUEST_SHARE_CLASSES_SUCCEEDED]:
        (state: IAllProductsStore, action: Action<ISelectValue[]>): IAllProductsStore => {
            return {...state, shareClasses: REQUEST_SUCCEEDED(action.payload!)};
        },
};

type Payloads = Map<number, ProductSummary> | Product | ISelectValue[];
export default handleActions<IAllProductsStore, Payloads>(reducerMap, allProductStoreInitialState);
