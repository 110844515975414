import * as React from "react";
import {MeetingNoteSummary} from "../../../model/MeetingNoteSummary.model";
import {TrackedLink} from "../../base/TrackedLink.component";
import {formatShortDate} from "../../../utils/dateUtil";

export interface IMeetingNotesListingProps {
    meetingNote: MeetingNoteSummary;
    productBackstopId: number;
}

export const MeetingNotesListing: React.FunctionComponent<IMeetingNotesListingProps> = (props) => {
    return <div className="__meeting-note" key={`meeting-note-${props.meetingNote.backstopId}`}
                data-testid="meeting-note">
            <div className="icon meeting-note__icon fa-light fa-memo" />
            <div>
                <div>
                    <TrackedLink to={`/products/${props.productBackstopId}/meeting-notes/${props.meetingNote.backstopId}`}
                                 className="link-14"
                                 gaCategory=""
                                 gaAction="Link"
                                 gaLabel="">
                        {props.meetingNote.title}
                    </TrackedLink>
                </div>
                <div className="meeting-note__date date-12">{formatShortDate(props.meetingNote.date)}</div>
            </div>
        </div>;
};