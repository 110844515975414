import * as React from "react";
import {FormattedMessage, injectIntl, IntlShape} from "react-intl";
import {BUY_CLOSED} from "../../../utils/ratingConstants";
import {RatingTypeFilter} from "../research-filters/FilterAndSort.menuItems";

interface IRatingComponentProps {
    rating: string;
    intl: IntlShape;
}

export const RatingIconComponent: React.FunctionComponent<IRatingComponentProps> = (props: IRatingComponentProps) => {

    const productRatingToRatingType = (productRating: string) => {
        return productRating.toUpperCase() === BUY_CLOSED.toUpperCase()
            ? RatingTypeFilter.BUY.toLowerCase()
            : productRating as RatingTypeFilter;
    };

    const getRatingLabel = () => {
        if (props.rating.toUpperCase() === RatingTypeFilter.QUALIFIED.toUpperCase()){
            return "qual";
        } else if (props.rating === RatingTypeFilter.NR.toString()) {
            return "nr";
        } else if (props.rating === RatingTypeFilter.IR.toString()) {
            return "ir";
        } else if (props.rating === RatingTypeFilter.NOT_RATED.toString()) {
            return "n/r";
        }
        else {
            return props.rating.toLowerCase();
        }
    };

    const widthByLocale = props.intl.locale === "fr-FR" ? 65 : 50;
    const width = props.rating === RatingTypeFilter.NOT_RATED.toString() ? widthByLocale - 2 : widthByLocale;

    const rating = productRatingToRatingType(getRatingLabel());

    return props.rating === RatingTypeFilter.ALL.toString()
        ? null
        : <div className={`product__rating-${rating.replace("/", "-")}`} style={{width}}
               data-testid="product__rating">
            <FormattedMessage
                id={`research-rating-chip.${rating.replace("/", "-")}`}
                defaultMessage={rating}
            />
        </div>;
};

export default injectIntl(RatingIconComponent);
