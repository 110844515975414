import {Map, OrderedMap} from "immutable";
import * as React from "react";
import {FormattedMessage} from "react-intl";
import {connect} from "react-redux";
import {AnyAction, bindActionCreators, Dispatch} from "redux";
import {portfolioProductSummariesSelector} from "../../../../mainReducerMapSelectors";
import {ProductSummary} from "../../../model/product/ProductSummary";
import {IAttributeFilter, IFilterItem} from "../../../model/Research.model";
import {byName} from "../../../utils/listUtil";
import {getSuccessData} from "../../common/commonStates";
import {SelectComponentReact} from "../../common/ReactSelectComponent";
import {ISelectValue, SelectComponent} from "../../common/Select.component";
import {applyFilters} from "../researchPageUtil";
import {updateSelectedAttributes} from "./filterActions";
import {
    AdditionalFilterItem,
    additionalFilterItems,
    AssetClassFilter,
    RatingTypeFilter,
} from "./FilterAndSort.menuItems";
import {getTranslation} from "../../../utils/translationUtil";
import {IApplicationRootState} from "../../../../applicationState";

export interface IAttributeFiltersPropsFromActions {
    actions: {
        updateSelectedAttributes: (value?: IAttributeFilter) => any;
    };
}

export interface IAttributeFiltersPropsFromStore {
    selectedAssetClass: AssetClassFilter;
    selectedRating: RatingTypeFilter;
    selectedAttributes?: IAttributeFilter;
    portfolioProductSummaries: Map<number, ProductSummary>;
}

export type AttributeFiltersProps =
    IAttributeFiltersPropsFromActions &
    IAttributeFiltersPropsFromStore;

export class AttributeFiltersComponent extends React.Component<AttributeFiltersProps> {

    public render() {
        return <div className="advanced-filtering__section" data-testid="advanced-filtering__section">
            <div className="advanced-filtering__spacer-left"/>
            {this.renderFilterOrHintText()}
            <div className="advanced-filtering__spacer-right"/>
        </div>;
    }

    private renderFilterOrHintText = () => {
        const hintText = <div className="advanced-filtering__hint-text"
                              data-testid="advanced-filtering__hint-text">
            <FormattedMessage id={"research-attribute-filter.hintText"}
                defaultMessage="Select an asset class for more filtering options." />
        </div>;

        if (this.props.selectedAssetClass !== AssetClassFilter.ALL) {
            const selectedAttributes = this.props.selectedAttributes;

            if (selectedAttributes && selectedAttributes.attributeMap && selectedAttributes.attributeMap.size > 0) {
                if (selectedAttributes.attributeMap.last()!.length > 0) {
                    if (selectedAttributes.attributeMap.keySeq().size === 4) {
                        return this.renderAllAttributeFilters(selectedAttributes);
                    }
                    return <div className="advanced-filtering__filter-and-add-filter-button">
                        {this.renderAllAttributeFilters(selectedAttributes)}
                        {this.renderAddFilterLink()}
                    </div>;
                } else {
                    return this.renderAllAttributeFilters(selectedAttributes);
                }
            }
            return this.renderAddFilterLink();
        }
        return hintText;
    };

    private renderAddFilterLink = () => {
        const attributeMap = this.props.selectedAttributes
            ? this.props.selectedAttributes.attributeMap
            : OrderedMap<AdditionalFilterItem, IFilterItem<string>[]>();

        return <div className="advanced-filtering__add-filter" data-testid="advanced-filtering__add-filter">
            <span id="advanced-filtering__add-filter-link" data-testid="advanced-filtering__add-filter-link"
                  className="clickable" onClick={() => {
                this.props.actions.updateSelectedAttributes({
                    attributeMap: attributeMap.set(AdditionalFilterItem.DEFAULT, []),
                });
            }}>
                <FormattedMessage id={"research-attribute-filter.addFilterLink"}
                                  defaultMessage={"Add Filter"} />
            </span>
        </div>;
    };

    private renderAllAttributeFilters(selectedAttributes: IAttributeFilter) {
        const mappedKeys = selectedAttributes.attributeMap.map((value: any, attribute: AdditionalFilterItem) => {
            return this.renderAttributeFilterSet(attribute);
        });

        return <div>
            {(mappedKeys.valueSeq().toArray())}
        </div>;
    }

    private renderAttributeFilterSet(attribute: AdditionalFilterItem) {
        return <div className="advanced-filtering__additional-filter" key={attribute}>
            {this.renderAttributeSelector(attribute)}
            {
                attribute !== AdditionalFilterItem.DEFAULT
                    ? this.renderAttributeValuesSelector(attribute) : null
            }

            {this.props.selectedAttributes!.attributeMap.keySeq().last() === attribute
                ? this.renderRemove(attribute)
                : null}
        </div>;
    }

    private renderRemove(attribute: AdditionalFilterItem) {
        return <div className="advanced-filtering__filter-remove fal fa-times clickable"
                    data-testid="advanced-filtering__filter-remove"
                    onClick={() => {
                        const attributeMap = this.removeCurrentAndSubsequentAttributes(attribute);
                        this.props.actions.updateSelectedAttributes({attributeMap});
                    }}/>;
    }

    private renderAttributeValuesSelector(attribute: AdditionalFilterItem) {
        return <SelectComponentReact
            id="advanced-filtering__filter-value"
            className="advanced-filtering__filter-value"
            classNamePrefix="attribute-values"
            options={this.getAdditionalFilterValues(attribute)}
            value={this.props.selectedAttributes!.attributeMap.get(attribute)!}
            stylesObject={{
                width: "460px",
            }}
            getOptionLabel={(option) => option.name}
            getOptionValue={(option: IFilterItem<string>) => option.value}
            onChange={this.handleAttributeValuesChange(attribute)}
            submitClicked={false}
            renderRequired={false}
            isMulti={true}
            closeMenuOnSelect={false}
            placeholder={getTranslation("research-attribute.placeholder", "Select one or more...")}
        />;
    }

    private handleAttributeValuesChange = (attribute: AdditionalFilterItem) =>
        (values: IFilterItem<string>[], action: any) => {

        let attributeMap: OrderedMap<AdditionalFilterItem, IFilterItem<string>[]>;
        const attributeMapFromStore = this.props.selectedAttributes!.attributeMap;

        if (action.action === "remove-value" || action.action === "clear") {
            const nonNullValues = values ? values : [];
            attributeMap = this.removeCurrentAndSubsequentAttributes(attribute).set(attribute, nonNullValues);
        } else {
            attributeMap = attributeMapFromStore.set(attribute, values);
        }

        this.props.actions.updateSelectedAttributes({
            attributeMap,
        });
    };

    private removeCurrentAndSubsequentAttributes = (attribute: AdditionalFilterItem) => {
            const attributeMapFromStore = this.props.selectedAttributes!.attributeMap;
            const keyIndex = attributeMapFromStore.keySeq().findIndex((val) => val === attribute);

            return attributeMapFromStore.slice(0, keyIndex)
                .toOrderedMap();
    };

    private getAdditionalFilterValues(attribute: AdditionalFilterItem) {
        const index = this.props.selectedAttributes!.attributeMap.keySeq().findIndex((k) => k === attribute);

        const keysToRemove = this.props.selectedAttributes!.attributeMap.keySeq().slice(index).toArray();

        const reducedMap = keysToRemove.reduce(
            (prev: OrderedMap<AdditionalFilterItem, IFilterItem<string>[]>, curr: AdditionalFilterItem) => {
                return prev.remove(curr);

        }, this.props.selectedAttributes!.attributeMap);

        const selectedAttributes = {attributeMap: reducedMap};

        const productArray = applyFilters(
            this.props.portfolioProductSummaries.valueSeq().toArray(),
            this.props.selectedAssetClass,
            this.props.selectedRating,
            selectedAttributes,
        );

        const prop =
            <T extends string>(key: T) =>
                <U extends { [P in T]: U[T] }>(value: U) =>
                    (value[key] as any).toString();

        const getAttributeValue = prop(attribute.toLowerCase());

        const menuItems = productArray.map((it) => getAttributeValue(it as any));

        return Array.from(new Set(menuItems))
            .map((it) => ({
                    value: it,
                    name: it,
                }
            ))
            .filter((it) => it.name.trim().length > 0)
            .sort(byName);
    }

    private renderAttributeSelector(attribute: AdditionalFilterItem) {
        const filterItems: ISelectValue[] = additionalFilterItems
            .filter((item) => !this.props.selectedAttributes!.attributeMap.has(item.id as AdditionalFilterItem)
                || item.id === attribute);

        return <SelectComponent
                id={`advanced-filtering__filter-select-${attribute}`}
                values={filterItems}
                selected={attribute}
                width={230}
                handleChange={this.handleAttributeNameChange(attribute)}
                classNameSuffix={"advanced-filtering"}
                intlPrefix={"research-attribute-filter"}
            />;
    }

    private handleAttributeNameChange = (attribute: AdditionalFilterItem) => (e: any) => {
        if (attribute === e.target.value) {
            return;
        }
        this.props.actions.updateSelectedAttributes(
                { attributeMap:
                        this.removeCurrentAndSubsequentAttributes(attribute).set(e.target.value, []),
                });
    };

}

export const mapStateToProps = (state: IApplicationRootState): IAttributeFiltersPropsFromStore => {
    return {
        selectedAssetClass: state.filterAndSortState!.selectedAssetClass,
        selectedRating: state.filterAndSortState!.selectedRatingType,
        selectedAttributes: state.filterAndSortState!.selectedAttributes,
        portfolioProductSummaries: getSuccessData(portfolioProductSummariesSelector(state))!,
    };
};

export const mapDispatchToProps = (dispatch: Dispatch<AnyAction>): IAttributeFiltersPropsFromActions => {
    return {
        actions: bindActionCreators({
                updateSelectedAttributes,
            },
            dispatch),
    };
};

const connectedComponent = connect<IAttributeFiltersPropsFromStore, IAttributeFiltersPropsFromActions>
    (mapStateToProps, mapDispatchToProps);

export default connectedComponent(AttributeFiltersComponent);
