import * as React from "react";
import {useState} from "react";
import DatePicker from "react-datepicker";
import {Portal} from "react-overlays";
import {SelectComponentReact} from "../common/ReactSelectComponent";
import {hasConsultantLevelAccess} from "../../utils/sessionUtil";
import {formatDateAndTimeCustom} from "../../utils/dateUtil";
import {CalendarApi} from "../../api/CalendarApi";
import {setHeaderNotification} from "../base/header/HeaderActions";
import {NotificationTypes} from "../base/header/HeaderReducer";
import {CALENDAR_VIEW} from "./CalendarPage";
import {useDispatch} from "react-redux";
import {QuillEditor} from "../common/QuillEditor.component";
import {CalendarMeetingDetails} from "../../model/CalendarMeetingDetails.model";
import {RaisedButton} from "../common/RaisedButton";
import {prominentButtonStyle, secondaryButtonStyle} from "../common/buttonStyles";

export interface ICalendarMeetingInfoProps {
    meetingDetails: CalendarMeetingDetails
    onViewChange: (view: CALENDAR_VIEW) => void;
    onSave: () => void;
}
export enum TimeZone {
    EST = "EST",
    PST = "PST",
    CST = "CST",
    MST = "MST"
}

export const CalendarMeetingInfo: React.FunctionComponent<ICalendarMeetingInfoProps>= (props) => {

    const getInitMeetingDate = (): Date | undefined => {
        return props.meetingDetails.dateTimeString ? new Date(props.meetingDetails.dateTimeString) : undefined;
    };

    const [meetingDateTime, setMeetingDateTime] = useState(getInitMeetingDate);
    const [timeZone, setTimeZone] = useState<string | undefined>(props.meetingDetails.timeZone);
    const [meetingLocation, setMeetingLocation] = useState<string | undefined>(props.meetingDetails.location);
    const [submitClicked, setSubmitClicked] = useState<boolean>(false);

    const CalendarContainer = ({children}: any) => {
        const el = document.getElementById('calendar-portal');

        return <Portal container={el}>
            {children}
        </Portal>;
    };

    const dispatch = useDispatch();

    const renderDateInput = () => {
        return <div className="calendar-meeting-info__date-input">
                    <div className="calendar-meeting-info__label-date">Meeting Date and Time</div>
                    { hasConsultantLevelAccess()
                    ? <div className="calendar-meeting-info__date-picker-container">
                            <DatePicker
                                popperContainer={CalendarContainer}
                                className="calendar-meeting-info__date-picker"
                                placeholderText="d MMMM, yyyy h:mm aa"
                                dateFormat="d MMMM, yyyy h:mm aa"
                                dropdownMode="select"
                                selected={meetingDateTime}
                                showTimeSelect
                                onChange={(date: Date) => {
                                    setMeetingDateTime(date);
                                }}
                            />
                            {renderRequiredCaption(true, submitClicked, meetingDateTime)}
                      </div>
                    : <div className="calendar-meeting-info__date-time">
                            {props.meetingDetails.dateTimeString}
                      </div>
                    }
        </div>;

    };

    const renderRequiredCaption =
        (renderRequired?: boolean, submitClicked?: boolean, value?: any) => {
            const className = submitClicked && !validateField(value)
                ? "common__required-caption-red"
                : "common__required-caption";

            return renderRequired
                ? <span className={className}>required</span>
                : null;
        };

    const renderTimezones = () => {
        return <div className="calendar-meeting-info__timezone-container">
            <div className="calendar-meeting-info__label-timezone">Timezone</div>
            {hasConsultantLevelAccess()
                ? <div className="calendar-meeting-info__timezone-dropdown">
                        <SelectComponentReact
                            menuIsOpen={undefined}
                            id={"timezone-dropdown"}
                            options={Object.keys(TimeZone)}
                            value={timeZone}
                            onChange={setTimeZone }
                            submitClicked={false}
                            renderRequired={false}
                            getOptionLabel={(timeZone: TimeZone) => timeZone}
                            getOptionValue={(timeZone: TimeZone) => timeZone}
                            placeholder={timeZone ? timeZone : "Select Timezone"}
                            stylesObject={{width: "100%"}}
                        />
                        {renderRequiredCaption(true, submitClicked, timeZone)}
                   </div>
                :  <div className="calendar-meeting-info__timezone">
                    {props.meetingDetails.timeZone}
                   </div>
            }
            {/*</div>*/}
        </div>;
    };

    const renderQuill = () => {
        return hasConsultantLevelAccess()
                ? <QuillEditor
                        htmlContent={meetingLocation!}
                        placeHolder=""
                        toolbarEnabled={true}
                        readOnly={false}
                        onChange={(content) => {
                                         setMeetingLocation(content);
                                     }}
                  />
                : <QuillEditor
                            htmlContent={meetingLocation!}
                            placeHolder=""
                            toolbarEnabled={false}
                            readOnly={true}
                  />;
    };

    const renderMeetingLocation =  () => {
        return <div className="calendar-meeting-info__location">
            <div className="calendar-meeting-info__label-location-header">Meeting Location</div>
            {renderQuill()}
        </div>;
    };

    const validateField = (value: string | Date | undefined) : boolean => {
        return !!(value && value !== "");
    };

    const onSave = () => {
        window.scrollTo(0, 0);
        submitMeetingInfo();
        setSubmitClicked(true);
    };

    const renderButtons = () => {
        return hasConsultantLevelAccess()
            ? <div className="calendar-meeting-info__buttons">
                <RaisedButton className="calendar-meeting-info__cancel-button"
                              style={secondaryButtonStyle}
                              primary={false}
                              onClick={() => props.onViewChange(CALENDAR_VIEW.CALENDAR) }>
                    Cancel
                </RaisedButton>
                <RaisedButton className="calendar-meeting-info__save-button"
                              style={prominentButtonStyle}
                              primary={true}
                              onClick={onSave}>
                    Save
                </RaisedButton>
            </div>
            : null;
    };

    const submitMeetingInfo = () => {
        if(validateField(meetingDateTime) && validateField(timeZone)) {
            const updatedMeetingInfo = {...props.meetingDetails,
                dateTimeString: formatDateAndTimeCustom(meetingDateTime, "D MMMM, yyyy h:mm A"),
                timeZone: timeZone as TimeZone,
                location: meetingLocation
            };

            CalendarApi.updateCalendarMeetingDetails(updatedMeetingInfo)
                .then(() => {
                    dispatch(
                        setHeaderNotification(
                            {message: "Save Successful", notificationType: NotificationTypes.SUCCESS},
                            5000)
                    );
                    setSubmitClicked(false);
                    props.onSave();
                })
                .catch(() => {
                    dispatch(
                        setHeaderNotification(
                            {message: "Failed to save meeting details!", notificationType: NotificationTypes.FAILURE},
                            5000)
                    );
                });
        }
    };

    return <div className="calendar-meeting-info__container">
                <div className="calendar-meeting-info__date-time-zone">
                    {renderDateInput()}
                    {renderTimezones()}
                </div>
                {renderMeetingLocation()}
                {renderButtons()}
          </div>;
};