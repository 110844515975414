import * as React from 'react';
import {useState} from 'react';
import {useDispatch, useSelector} from "react-redux";
import {powerBiReportExportPeriodSelector, powerBiReportExportSelector} from "../../../mainReducerMapSelectors";
import {getSuccessData, REQUEST_STATES} from "../common/commonStates";
import {PowerBiExportPoller} from "./PowerBiExportPoller";
import {PowerBiReportingActions} from "./PowerBiReporting.actions";

export interface IPowerBiExportNotificationProps {
    delayDispatch: number,
}

export const PowerBiExportNotification: React.FunctionComponent<IPowerBiExportNotificationProps> = (props: IPowerBiExportNotificationProps) => {

    const dispatch = useDispatch();
    const exportInfoSelector = useSelector(state => powerBiReportExportSelector(state));
    const exportPeriodSelector = useSelector(state => powerBiReportExportPeriodSelector(state));
    const [style, setStyle] = useState('top-alert--running');

    const clearExportInfo = (success: boolean) => {
        if(success){
            setStyle('top-alert--success');
            setTimeout(() => {
                dispatch({type: PowerBiReportingActions.EXPORT_REPORT_CLEAR});
                setStyle('top-alert--running');
            }, props.delayDispatch);
        }else{
            setStyle('top-alert--failure');
            setTimeout(() => {
                dispatch({
                    type: PowerBiReportingActions.EXPORT_REPORT_REQUEST_FAILED,
                    payload: "Timeout"
                });
                setStyle('top-alert--running');
            }, props.delayDispatch);
        }
    };

    const renderExportPoller = () => {
        return exportInfoSelector.kind === REQUEST_STATES.REQUEST_SUCCEEDED
            ?
            <div className={`top-alert-notification ${style}`} data-testid="power-bi-alert-notification">
                <PowerBiExportPoller exportInfo={getSuccessData(exportInfoSelector)!}
                                     exportPeriod={exportPeriodSelector}
                                     clearExportInfo={clearExportInfo}
                                     interval={60000}
                                     numberOfTries={20}/>
            </div>
            : null;
    };

    return<> {renderExportPoller()} </>;

};


