import {AxiosResponse} from "axios";
import {Map} from "immutable";
import {ManagerResponse} from "../model/manager/ManagerResponse.model";
import {ApiBase} from "./ApiBase";
import {IApiResult} from "./AxiosWealthManagementApi";

class ManagerApiImpl {

    private static mapManagerResponse(managerResponseData: any) {
        return new ManagerResponse(
            managerResponseData.backstopId,
            managerResponseData.name,
            managerResponseData.website,
            managerResponseData.inPortfolio,
        );
    }

    public requestAllManagers(): Promise<IApiResult<Map<number, ManagerResponse>>> {
        return ApiBase.processGet(`/managers`,
            (response: AxiosResponse): any => {
                return (Object as any).entries(response.data)
                    .reduce(this.managerResponseEntryReducer, Map<number, ManagerResponse>());
            });
    }

    private managerResponseEntryReducer = (all: Map<number, ManagerResponse>, [key, value]: (any)[]) => {
        return all.set(parseInt(key, 10), ManagerApiImpl.mapManagerResponse(value));
    };
}

export const ManagerApi = new ManagerApiImpl();
