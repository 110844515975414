import * as React from "react";
import {IHolding} from "../../api/HoldingsApi";
import {getTrendData, IHoldingTrend} from "./InvestmentsTrendDataUtil";
import {formatCurrency, formatPercent, negativeToParenthesis} from "../../utils/numberUtil";
import {HoldingsGroupBy} from "./InvestmentsPage";

export interface IInvestmentsAllocationTrendsTableProps {
    holdings: IHolding[];
    year: number;
    month: number;
    groupName: string;
    groupBy: HoldingsGroupBy;
}

export const InvestmentsTrendsTable: React.FunctionComponent<IInvestmentsAllocationTrendsTableProps> = (props) => {
    const oneMonthTrends = getTrendData(props.holdings, props.year, props.month, 1, props.groupName, props.groupBy);
    const threeMonthTrends = getTrendData(props.holdings, props.year, props.month, 3, props.groupName, props.groupBy);
    const oneYearTrends = getTrendData(props.holdings, props.year, props.month, 12, props.groupName, props.groupBy);

    function renderTrendHeader(trend: IHoldingTrend | null, headerText: string) {
        return trend ? <th className="investments__trend-header small bold aon-gray-01">{headerText}</th> : null;
    }

    function renderTrendChange(trend: IHoldingTrend | null) {
        if (!trend) {
            return null;
        }

        const assetsChange = trend.endAssets - trend.beginningAssets;

        return <td className="investments__trend-change investments__trend-data">
            <div className="aon-peacock">{formatCurrency(assetsChange, 0)}</div>
        </td>;
    }

    function renderTrendChangePercent(trend: IHoldingTrend | null) {
        if (!trend) {
            return null;
        }

        const assetsChange = trend.endAssets - trend.beginningAssets;

        return <td className="investments__trend-percent investments__trend-data">{formatPercent(assetsChange / trend.beginningAssets, 2)}</td>;
    }

    function renderBeginningMarketValue(trend: IHoldingTrend | null) {
        if (!trend) {
            return null;
        }

        const negative = trend.beginningAssets < 0 ? "investments__trend-negative" : "";
        const formattedCurrency = formatCurrency(trend.beginningAssets, 0);
        const currencyWithParenthesis = negativeToParenthesis(formattedCurrency);

        return <td className={`investments__trend-beginning-assets investments__trend-data ${negative} ${negative}`}>{currencyWithParenthesis}</td>;
    }

    function renderInflow(trend: IHoldingTrend | null) {
        if (!trend) {
            return null;
        }

        const negative = trend.inflow < 0 ? "investments__trend-negative" : "";
        const formattedCurrency = formatCurrency(trend.inflow, 0);
        const currencyWithParenthesis = negativeToParenthesis(formattedCurrency);

        return <td className={`investments__trend-inflow investments__trend-data ${negative}`}>{currencyWithParenthesis}</td>;
    }

    function renderOutflow(trend: IHoldingTrend | null) {
        if (!trend) {
            return null;
        }

        const negative = trend.outflow < 0 ? "investments__trend-negative" : "";
        const formattedCurrency = formatCurrency(trend.outflow, 0);
        const currencyWithParenthesis = negativeToParenthesis(formattedCurrency);

        return <td className={`investments__trend-outflow investments__trend-data ${negative}`}>{currencyWithParenthesis}</td>;
    }

    function renderGains(trend: IHoldingTrend | null) {
        if (!trend) {
            return null;
        }

        const negative = trend.gains < 0 ? "investments__trend-negative" : "";
        const formattedCurrency = formatCurrency(trend.gains, 0);
        const currencyWithParenthesis = negativeToParenthesis(formattedCurrency);

        return <td className={`investments__trend-gains investments__trend-data ${negative}`}>{currencyWithParenthesis}</td>;
    }

    function renderEndAssets(trend: IHoldingTrend | null) {
        if (!trend) {
            return null;
        }

        const negative = trend.endAssets < 0 ? "investments__trend-negative" : "";
        const formattedCurrency = formatCurrency(trend.endAssets, 0);
        const currencyWithParenthesis = negativeToParenthesis(formattedCurrency);

        return <td
            className={`investments__trend-end-assets investments__trend-data ${negative}`}>{currencyWithParenthesis}</td>;
    }

    return <table className="investments__trend-table" data-testid="investments__trend-table">
        <thead>
        <tr className="investments__trends-header-row" data-testid="investments__trends-header-row">
            <th className={"bold aon-gray-01"}>Period</th>
            {renderTrendHeader(oneMonthTrends, "1 Month")}
            {renderTrendHeader(threeMonthTrends, "3 Month")}
            {renderTrendHeader(oneYearTrends, "12 Month")}
        </tr>
        </thead>
        <tbody>
        <tr className="investments__trends-data-row">
            <td className="investments__trend-data-header small bold">Change in Value</td>
            {renderTrendChange(oneMonthTrends)}
            {renderTrendChange(threeMonthTrends)}
            {renderTrendChange(oneYearTrends)}
        </tr>
        <tr className="investments__trends-data-row">
            <td className="investments__trend-data-header small bold">Beginning Market Value</td>
            {renderBeginningMarketValue(oneMonthTrends)}
            {renderBeginningMarketValue(threeMonthTrends)}
            {renderBeginningMarketValue(oneYearTrends)}
            </tr>
            <tr className="investments__trends-data-row">
                <td className="investments__trend-data-header small bold">Inflow</td>
                {renderInflow(oneMonthTrends)}
                {renderInflow(threeMonthTrends)}
                {renderInflow(oneYearTrends)}
            </tr>
            <tr className="investments__trends-data-row">
                <td className="investments__trend-data-header small bold">Outflow</td>
                {renderOutflow(oneMonthTrends)}
                {renderOutflow(threeMonthTrends)}
                {renderOutflow(oneYearTrends)}
            </tr>
            <tr className="investments__trends-data-row">
                <td className="investments__trend-data-header small bold">Gains</td>
                {renderGains(oneMonthTrends)}
                {renderGains(threeMonthTrends)}
                {renderGains(oneYearTrends)}
            </tr>
            <tr className="investments__trends-data-row">
                <td className="investments__trend-data-header small bold">Ending Market Value</td>
                {renderEndAssets(oneMonthTrends)}
                {renderEndAssets(threeMonthTrends)}
                {renderEndAssets(oneYearTrends)}
            </tr>
            <tr className="investments__trends-data-row">
                <td className="investments__trend-data-header small bold">Total Market Value Change</td>
                {renderTrendChangePercent(oneMonthTrends)}
                {renderTrendChangePercent(threeMonthTrends)}
                {renderTrendChangePercent(oneYearTrends)}
            </tr>
            </tbody>
        </table>;
};