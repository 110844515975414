import * as chroma from "chroma-js";
import * as d3 from "d3";
import {PieArcDatum} from "d3";
import * as React from "react";
import {HOLDINGS_CHART_COLOR_SCALE} from "../../../../../css/Colors";
import {IHolding} from "../../../api/HoldingsApi";
import {callCallback} from "../../../utils/callCallback";
import {numberWithCommas} from "../../../utils/numberUtil";
import {getDataSum, getHoldingsForAsset, getPercent, getSumsByAsset} from "../InvestmentsDataUtil";

export interface IHoldingsDonutChartProps {
    holdings: IHolding[];
}

export interface IHoldingsDonutChartState {
    data: IData[];
    level: number;
    maxDepth: number;
}

export interface IData {
    name: string;
    value: number;
}

const areHoldingsEqual = (a: IHolding, b: IHolding): boolean =>
    ((a.name === b.name)
        && (a.assets === b.assets)
        && (a.assetClass === b.assetClass)
        && (a.productId === b.productId)
        && (a.asOfDate.getTime() === b.asOfDate.getTime()));
const sameHoldings = (xs: IHolding[], ys: IHolding[]): boolean =>
    xs.length === ys.length
    && xs.reduce((res: boolean, x: IHolding) => res && ys.some((y) => areHoldingsEqual(x, y)), true);

export class HoldingsDonutChartGenerator extends React.Component<IHoldingsDonutChartProps, IHoldingsDonutChartState> {

    // define donut dimensions
    private innerRadius = 125;
    private donutThickness = 35;
    private outerRadius = this.innerRadius + this.donutThickness;
    private donutOnHoverThickness = 50;

    // define chart area

    private chartContainer = "pie-chart__chart-container";
    private outerChartContainer = "pie-chart__chart-outer-container";
    private innerChartContainer = "pie-chart__chart-inner-container";
    private innerContainerXTranslate = this.innerRadius + this.donutThickness;
    private innerContainerYTranslate = 220;

    // define inner donut
    private innerDonutThickness = 0;
    private innerDonutDelta = 125;

    // legend
    private markerDimension = 17;

    // generator functions
    private arc = d3.arc<any, PieArcDatum<IData>>()
        .outerRadius(this.outerRadius)
        .innerRadius(this.innerRadius);

    private arcOver = d3.arc<any, PieArcDatum<IData>>()
        .outerRadius(this.innerRadius + this.donutOnHoverThickness)
        .innerRadius(this.innerRadius);

    private pieGenerator = d3.pie<IData>()
        .sort(null)
        .value((d: IData) => d.value);

    constructor(props: IHoldingsDonutChartProps) {
        super(props);
        this.state = {
            data: getSumsByAsset(this.props.holdings),
            level: 0,
            maxDepth: 1,
        };
    }

    public componentDidMount(): void {
        this.generatePieChartSVGContainer();
        this.generatePieChartGElement(this.outerChartContainer);
        this.generatePieChartGElement(this.innerChartContainer);
        this.resetChart();
    }

    public componentDidUpdate(prevProps: IHoldingsDonutChartProps): void {
        if (!sameHoldings(prevProps.holdings, this.props.holdings)) {
            this.resetChart();
        }
    }

    public render() {
        return <div className="holdings-page__pie-chart">
            <div className="holding-page__chart-title">Asset Allocation</div>
            <div className="holdings-page__chart-container">
                {this.renderResetButton()}
                <div id="pie-chart__spacer-container"/>
                <div id="pie-chart__div-anchor"/>
                <div id="pie-chart__spacer-container"/>
                <div id="pie-chart__legend-container"/>
            </div>
        </div>;
    }

    private generatePieChartSVGContainer() {
        d3.select("#pie-chart__div-anchor")
            .append("svg")  // append svg element inside #chart
            .attr("width", this.innerContainerXTranslate * 2 )    // set width
            .attr("height", this.innerContainerXTranslate * 2 )  // set height
            .append("g")
            .attr("transform", "translate(" + this.innerContainerXTranslate + "," + this.innerContainerYTranslate + ")")
            .attr("class", this.chartContainer);
    }

    private generatePieChartGElement(className: string) {
        d3.select(`.${this.chartContainer}`)
            .append("g")
            .attr("class", className);
    }

    private generatePieChart(totalName: string, colorScale: string[]) {

        //////////
        // GENERATOR FUNCTIONS AND SETUP //
        //////////

        const data = this.state.data;

        const keys =
             data.map((assetAllocation) => assetAllocation.name); // create keys to access data

        const slice =
            this.pieGenerator(this.state.data); // generates data objects with beginning and ending angles

        //////////
        // COLOR SCALE //
        //////////

        const color = d3.scaleOrdinal()
            .domain(keys)
            .range(chroma.scale(colorScale).colors(keys.length));

        //////////
        // SETUP CONTAINER AND DONUT ELEMENTS //
        //////////

        const chart = d3
            .select(`.${this.outerChartContainer}`)
            .append("g")
            .attr("class", "pie-chart__outer-donut");

        const dataArcs = chart.selectAll("dataArcs") // create svg element for every asset class
            .data(slice)
            .enter()
            .append("path") // append slice with appropriate angle
            .attr("d", this.arc)
            .attr("fill", (d) => (color(d.data.name) as any).toString())
            .attr("class", (d, index) =>
                `pie-chart__slice${this.state.level === 0 ? " clickable" : ""} pie-chart__slice-` + index);

        //////////
        // DONUT ACTIONS //
        //////////

        dataArcs
            .on("mouseenter", function(d) {growSlice(d, dataArcs.nodes().indexOf(this));})
            .on("mouseleave", function(d) {shrinkSlice(d, dataArcs.nodes().indexOf(this));})
            .on("click", (d) => {
                drillDownSlice(d);
            });

        const drillDownSlice = (arcSlice: PieArcDatum<IData>) => {
            if (this.state.level === this.state.maxDepth) {return; }

            const innerArc = d3.select(`.${this.innerChartContainer}`)
                .append("g")
                .attr("class", "pie-chart__inner-donut")
                .append("path") // append slice with appropriate angle
                .attr("d", this.arcOver(arcSlice)!)
                .attr("fill", (color(arcSlice.data.name) as any).toString())
                .attr("class",  "pie-chart__selected-slice");

            innerArc
                .transition()
                .duration(700)
                .attrTween("d",  () => this.arcUnsweep(arcSlice))
                .on("end", () => {
                    d3.selectAll(".pie-chart__slice").remove();

                    d3.selectAll(".pie-chart__sum")
                        .transition()
                        .duration(700)
                        .style("opacity", 0);

                    d3.select(".pie-chart__selected-slice")
                        .transition()
                        .duration(800)
                        .style("opacity", 0)
                        .attrTween("d",  () =>
                            this.radialDonutTransform(
                                this.innerRadius,
                                this.innerRadius - this.innerDonutDelta,
                                this.donutOnHoverThickness,
                                this.innerDonutThickness))
                        .on("end", () => {
                            this.drillDownChart(arcSlice.data.name, (color(arcSlice.data.name) as any).toString());
                        });
                });

        };

        /* eslint-disable @typescript-eslint/no-unused-vars */
        // keep until functionality is confirmed
        // noinspection JSUnusedLocalSymbols
        const drillUpSlice = (arcSlice: PieArcDatum<IData>) => {
            d3.select(".pie-chart__selected-slice")
                .classed("clickable", false)
                .transition()
                .duration(700)
                .attrTween("d",  () =>
                    this.radialDonutTransform(
                        this.innerRadius - this.innerDonutDelta,
                        this.innerRadius,
                        this.innerDonutThickness,
                        this.donutThickness,
                    ))
            .on("end", () => {
                this.resetChart();
            })
            .transition()
            .duration(700)
            .attrTween("d",  () => this.arcSweepAround(arcSlice))
            .on("end", () => {
                d3.select(".pie-chart__inner-donut").remove();
            });
        };
        /* eslint-enable @typescript-eslint/no-unused-vars */

        const toggleSlice = (expand: boolean) => (d: PieArcDatum<IData>, index: number) => {
            const targetLegendDivSelector = ".pie-chart__legend-div-" + index;
            d3.selectAll(`.pie-chart__legend-div:not(${targetLegendDivSelector})`).classed("not-selected", expand);

            d3.select(targetLegendDivSelector)
                .classed("selected", expand);

            if (expand) {
                displayLabel(d);
            } else {
                chart.selectAll(".pie-chart__label").remove();
            }
            d3.select(".pie-chart__slice-" + index)
                .transition()
                .duration(100)
                .ease(d3.easeQuadOut)
                .attr("d", expand ? this.arcOver : this.arc);
        };

        const growSlice = toggleSlice(true);
        const shrinkSlice = toggleSlice(false);

        //////////
        // LEGEND //
        //////////

        const formatValue = (value: number) => {
            return "$" + numberWithCommas(value.toFixed(0));
        };

        d3.select("#pie-chart__legend-container")
            .append("div")
            .attr("class", "pie-chart__legend-title")
            .text(totalName);

        const legendText =  d3.select("#pie-chart__legend-container")
            .selectAll("mylabels")
            .data(slice)
            .enter()
            .append("div");

        legendText
            .attr("class", (d, index) =>
                `pie-chart__legend-div pie-chart__legend-div-${index}${this.state.level === 0 ? " clickable" : ""}`)
            .attr("text-anchor", "left")
            .style("alignment-baseline", "middle")
            .on("click", (d: PieArcDatum<IData>) => drillDownSlice(d))
            .on("mouseover",
                function (d: PieArcDatum<IData>) {growSlice(d, legendText.nodes().indexOf(this)); })
            .on("mouseleave",
                function (d: PieArcDatum<IData>) {shrinkSlice(d, legendText.nodes().indexOf(this)); });

        const legendRects = legendText
            .append("svg")
            .attr("width", this.markerDimension )    // set width
            .attr("height", this.markerDimension )  // set height
            .append("g")
            .append("rect");

        legendRects
            .attr("x", 0)
            .attr("y", 4)
            .attr("width", this.markerDimension)
            .attr("height", this.markerDimension)
            .attr("rx", 2)
            .style("fill", (d: PieArcDatum<IData>) => (color(d.data.name) as any).toString())
            .on("mouseover",
                function (d: PieArcDatum<IData>) {growSlice(d, legendRects.nodes().indexOf(this)); })
            .on("mouseleave",
                function (d: PieArcDatum<IData>) {shrinkSlice(d, legendRects.nodes().indexOf(this)); });

        legendRects
            .append("span")
            .attr("class", "pie-chart__legend-text")
            .text((d) => d.data.name + " | " )
        ;

        legendRects
            .append("span")
            .attr("class", "pie-chart__value-text")
            .text((d) => getPercent(d.data.value, data, 2));

        /////////////
        // LABELS //
        ////////////

        const displayLabel = (d: PieArcDatum<IData>) => {
            const pos = this.arc.centroid(d).map((it) => it * 1.5);

            chart.selectAll(".pie-chart__label").remove();
            appendDataLabel(pos, d);
        };

        const appendDataLabel = (pos: number[], d: PieArcDatum<IData>) => {
            const space = 22;

            const midpointAngle = d.startAngle + (d.endAngle - d.startAngle) / 2;
            let xOffset = 0;

            if (midpointAngle > Math.PI) {
                xOffset = -50;
            }
            appendLabel(
                "pie-chart__label percent-text", pos[0], pos[1], getPercent(d.data.value, data, 2), "", xOffset);
            appendLabel(
                "pie-chart__label value-text", pos[0], pos[1] + space, formatValue(d.data.value), "", xOffset,
            );
        };

        const appendLabel =
            (className: string, x: number, y: number, text: string, textAnchor?: string, xOffset?: number) => {
           chart.append("text")
                .attr("class", className)
                .attr("x", x)
                .attr("y", y)
                .attr("dx", xOffset || 0)
                .attr("text-anchor", textAnchor || "")
                .text(text);
        };

        //////////
        // INNER DONUT SUM //
        //////////

        appendLabel("bold-text pie-chart__sum", 0, -10, totalName, "middle");

        const sum = formatValue(getDataSum(data));

        appendLabel("pie-chart__sum total-sum", 0,  30, sum, "middle");

    }

    private arcUnsweep(arcSlice: PieArcDatum<IData>) {
        const interpolateFx = d3.interpolate(arcSlice.endAngle, arcSlice.startAngle + 2 * Math.PI);
        return (t: number) => {
            return this.arcOver({...arcSlice, endAngle: interpolateFx(t)})!;
        };
    }

    private arcSweepAround(arcSlice: PieArcDatum<IData>) {
        const interpolateFx = d3.interpolate(arcSlice.startAngle + 2 * Math.PI, arcSlice.endAngle);
        return (t: number) => {
            return this.arc({...arcSlice, endAngle: interpolateFx(t)})!;
        };
    }

    private radialDonutTransform(initialRadius: number, finalRadius: number, initialWidth: number, finalWidth: number) {
        const interpolateInner = d3.interpolate(initialRadius, finalRadius);
        const interpolateOuter = d3.interpolate(initialRadius + initialWidth, finalRadius + finalWidth);
        return (t: number) => {
            return d3.arc<any, PieArcDatum<IData>>()
                .innerRadius(interpolateInner(t))
                .outerRadius(interpolateOuter(t))
                ({
                    data: {name: "", value: 0},
                    value: 0,
                    index: 0,
                    padAngle: 0,
                    startAngle: 0,
                    endAngle: 2 * Math.PI,
                })!;
        };
    }

    private drillDownChart(name: string, color: string) {
        const individualClassHoldings = getHoldingsForAsset(this.props.holdings, name);
        let newColorScale;
        if (individualClassHoldings.length === 1) {
            newColorScale = [color];
        } else {
            const upperLimit = chroma(color).brighten(3);
            const domain = individualClassHoldings.map((_, idx) => idx / individualClassHoldings.length );
            newColorScale = chroma.scale([color, upperLimit]).domain(domain).colors(individualClassHoldings.length);
        }

        this.buildChart(individualClassHoldings, this.state.maxDepth, name, newColorScale);
    }

    private resetChart() {
        callCallback(() =>
            this.buildChart(getSumsByAsset(this.props.holdings), 0, "Total", HOLDINGS_CHART_COLOR_SCALE));
    }

    private buildChart(newData: IData[], newLevel: number, totalName: string, colorScale: string[]) {
        d3.select(".pie-chart__outer-donut").remove();
        d3.select(".pie-chart__inner-donut").remove();
        d3.select("#pie-chart__legend-container").selectAll("*").remove();
        this.setState({
            data: newData,
            level: newLevel,
        }, () => {
            this.generatePieChart(totalName, colorScale);
        });
    }

    private renderResetButton() {
        if (this.state.level === 0) {
            return <div className="pie-chart__reset-text" />;
        }

        return <div className="pie-chart__reset-text clickable">
            <span
                id="pie-chart__reset-button"
                onClick={() => this.resetChart()}
            >Reset
            </span>
        </div>;
    }
}
