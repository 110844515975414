import {IconButton, Tooltip} from "@mui/material";
import * as React from "react";
import {useEffect, useState} from "react";
import {get12MostRecentMonthsOfHoldings, ICashflowsProps,} from "./CashFlowsUtils";
import {HoldingsGroupBy} from "../InvestmentsPage";
import {CashFlowsFilters} from "./CashFlowsFilters";
import {CashFlowsOverTime} from "./CashFlowsOverTime";
import {CashFlowsByKey} from "./CashFlowsByKey";

export const allAssetClasses = "All Asset Classes";
export const allTiers = "All Tiers";
export const allProducts = "All Products";
export const orderByName = "Asset Class (A-Z)";
export const orderByTierName = "Tier (A-Z)";
export const orderByProductName = "Product";
export const orderByCashflow = "Net Cash Flow";
export const defaultOverTimeTitle = "Total Plan";
export const defaultAssetClassTitle = "Total Plan by Asset Class";
export const defaultTierTitle = "Total Plan by Tier";

export interface ICashflowsComponentProps extends ICashflowsProps {
    groupBy: HoldingsGroupBy;
}

export const CashFlowsComponent: React.FunctionComponent<ICashflowsComponentProps> = (props) => {
    const getSelectedGroupDefault = () => {
        return props.groupBy === HoldingsGroupBy.BY_ASSET_CLASS
            ? allAssetClasses
            : allTiers;
    };

    const [showChart, setShowChart] = useState<boolean>(true);
    const [selectedGroup, setSelectedGroup] = useState<string>(getSelectedGroupDefault());
    const [selectedProduct, setSelectedProduct] = useState<string>(allProducts);

    useEffect(() => {
        const group = getSelectedGroupDefault();
        setSelectedGroup(group);
        setSelectedProduct(allProducts);
    }, [props]);

    const mostRecentHoldingsOverTime = get12MostRecentMonthsOfHoldings(props.holdings);

    return <div className="cash-flows__container" data-testid="cash-flows__container">
        <h2 className="gray">Cash Flow</h2>
        <div className="cash-flows__options-container">
            {renderFilters()}
            <div className="cash-flows__chart-data-selection">
                {showChart ? renderTableButton() : renderChartButton()}
            </div>
        </div>
        <CashFlowsOverTime holdings={props.holdings}
                           groupBy={props.groupBy}
                           selectedGroup={selectedGroup}
                           selectedProduct={selectedProduct}
                           showChart={showChart}/>
        {renderByKeyComponent()}
    </div>;



    function handleGroupChange(event: { target: { value: string } }) {
        const value = event.target.value;
        setSelectedGroup(value);
        setSelectedProduct(allProducts);
    }

    function handleProductChange(event: { target: { value: string } }) {
        setSelectedProduct(event.target.value);
    }

    function renderFilters() {
        return <CashFlowsFilters holdings={mostRecentHoldingsOverTime}
                                 groupBy={props.groupBy}
                                 selectedGroup={selectedGroup}
                                 selectedProduct={selectedProduct}
                                 handleGroupChange={handleGroupChange}
                                 handleProductChange={handleProductChange}
        />;
    }

    function renderChartButton() {
        return <div className="cash-flows__chart-button" data-testid="cash-flows__chart-button">
            <Tooltip title="Chart">
                 <span>
                    <IconButton
                        onClick={() => {
                            setShowChart(true);
                        }}
                        disabled={showChart}>
                        <div className="far fa-chart-bar cash-flows__far-buttons"/>
                    </IconButton>
                 </span>
            </Tooltip>
        </div>;
    }

    function renderTableButton() {
        return <div className="cash-flows__table-button" data-testid="cash-flows__table-button">
            <Tooltip title="Data Table">
                <span>
                    <IconButton
                        onClick={() => {
                            setShowChart(false);
                        }}
                        disabled={!showChart}>
                        <div className="far fa-th cash-flows__far-buttons"/>
                    </IconButton>
                </span>
            </Tooltip>
        </div>;
    }

    function renderByKeyComponent() {
        if (selectedProduct !== allProducts) {
            return null;
        }

        return <CashFlowsByKey holdings={props.holdings}
                               groupBy={props.groupBy}
                               selectedGroup={selectedGroup}
                               selectedProduct={selectedProduct}
                               showChart={showChart}
        />;
    }
};
