import * as React from "react";
import "react-datepicker/dist/react-datepicker.css";
import {createRoot} from "react-dom/client";
import {store, history} from "./configureStore";
import {registerUnhandledRejectionHandler} from "./global-error-handler";
// import history from "./history";
import {RootComponent} from "./main/components/root/Root.component";

require("core-js");
require("./main/utils/polyfill.js");
require("es6-promise/auto");
require("es6-object-assign/auto");
require("../css/main.scss");

const renderComponent = () => {
    registerUnhandledRejectionHandler();

    const rootElement = document.getElementById("wealth-management");
    const root = createRoot(rootElement!);
    root.render(<RootComponent store={store} history={history}/>);
};

renderComponent();
