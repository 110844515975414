import * as React from "react";
import AdminClientInfoRequester from "./AdminClientInfoRequester";
import AdminUserEditComponent from "./AdminUserEdit.component";
import AdminUserInfoRequester from "./AdminUserInfoRequester";

export const AdminUserEditPage: React.FunctionComponent = () => {
    return <AdminUserInfoRequester>
            <AdminClientInfoRequester>
                <AdminUserEditComponent/>
            </AdminClientInfoRequester>
        </AdminUserInfoRequester>;
};
