import * as React from "react";
import {useHistory} from "react-router-dom";
import {oktaSignOut} from "../../utils/oktaUtil";
import {handleEnter} from "../../utils/commonUtil";

export interface ICorsErrorModalOpen{
    setCorsErrorModalOpen: (is: boolean) => void;
}
export const OktaLogOut: React.FunctionComponent<ICorsErrorModalOpen> = (props) => {
    const history = useHistory();

    const isCorsError = (err: any) =>
        (err.name === "AuthApiError" && !err.errorCode && err.xhr.message === "Failed to fetch");

    const logout = async () => {
        const basename = window.location.origin + history.createHref({ pathname: "/" });
        try {
            await oktaSignOut({
                postLogoutRedirectUri: basename,
                revokeAccessToken: true
            });
        } catch (err) {
            if (isCorsError(err)) {
                props.setCorsErrorModalOpen(true);
            } else {
                throw err;
            }
        }
    };

    return <div className="clickable-menu-item">
        {/* eslint-disable @typescript-eslint/no-misused-promises */}
        <a className="old-anchor" id="logout" data-testid="logout"
           tabIndex={-1}
           onKeyUp = {handleEnter(logout)}
           onClick={logout}>Sign Out</a>
    </div>;

};