import {Action, handleActions} from "redux-actions";
import {IAttributeFilter} from "../../../model/Research.model";
import {AdminActionTypes} from "../edit-portfolio/editPortfolioActions";
import {FilterActionTypes} from "./filterActions";
import {AssetClassFilter, ManagerSortOption, RatingTypeFilter} from "./FilterAndSort.menuItems";

export interface IFilterState {
    selectedAssetClass: AssetClassFilter;
    selectedRatingType: RatingTypeFilter;
    selectedSortOption: ManagerSortOption;
    selectedAttributes?: IAttributeFilter;
}

export const initialState: IFilterState = {
    selectedAssetClass: AssetClassFilter.ALL,
    selectedRatingType: RatingTypeFilter.ALL,
    selectedSortOption: ManagerSortOption.RECENT_RATING,
    selectedAttributes: undefined,
};

const updateSelectedRating = (state: IFilterState, {payload}: Action<RatingTypeFilter>): IFilterState => {
    return {...state, selectedRatingType: payload!, selectedAttributes: initialState.selectedAttributes};
};

const updateSelectedAssetClass = (state: IFilterState, {payload}: Action<AssetClassFilter>): IFilterState => {
    return {...state, selectedAssetClass: payload!, selectedAttributes: initialState.selectedAttributes};
};

const updateSelectedSortOption = (state: IFilterState, {payload}: Action<ManagerSortOption>): IFilterState => {
    return {...state, selectedSortOption: payload!};
};

const beginEditingPortfolio = (state: IFilterState): IFilterState => {
    return {...state, selectedSortOption: ManagerSortOption.ALPHA_ASC};
};

const clearFilters = (state: IFilterState, {}: Action<{}>): IFilterState => {
    return {...state,
        selectedAssetClass: initialState.selectedAssetClass,
        selectedRatingType: initialState.selectedRatingType,
        selectedAttributes: initialState.selectedAttributes};
};

const updateSelectedAttributes = (state: IFilterState, {payload}: Action<IAttributeFilter>) => {
    return {...state, selectedAttributes: payload!};
};

const reducerMap = {
    [FilterActionTypes.UPDATE_SELECTED_RATING]: updateSelectedRating,
    [FilterActionTypes.UPDATE_SELECTED_ASSET_CLASS]: updateSelectedAssetClass,
    [FilterActionTypes.UPDATE_SELECTED_SORT_OPTION]: updateSelectedSortOption,
    [FilterActionTypes.CLEAR_FILTERS]: clearFilters,
    [AdminActionTypes.BEGIN_EDIT_PORTFOLIO]: beginEditingPortfolio,
    [FilterActionTypes.UPDATE_SELECTED_ATTRIBUTES]: updateSelectedAttributes,
};

export default handleActions<IFilterState, any>(reducerMap, initialState);
