import {Action} from "redux-actions";

export enum UserSessionInitializerActions {
    INITIALIZE_USER_SESSION = "INITIALIZE_USER_SESSION",
}

export const createInitializeUserSessionAction = (): Action<string | null> => {
    return {
        type: UserSessionInitializerActions.INITIALIZE_USER_SESSION,
    };
};
