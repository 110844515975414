import {InsightsApi} from "../../api/InsightsApi";
import {DocumentType} from "../../utils/marketOutlookUtils";
import {IHomeContentBoxDownloadableDataItem, IHomeListItem} from "../IHomeListItem";

export interface ICapitalMarketAssumption {
    backstopId: number;
    title: string;
    outlook: CapitalMarketAssumptionOutlook;
    date: Date;
    fileName: string;
}

export class CapitalMarketAssumption implements IHomeListItem {
    public static builder(): CapitalMarketAssumptionBuilder {
        return new CapitalMarketAssumptionBuilder();
    }

    public constructor(public readonly backstopId: number,
                       public readonly title: string,
                       public readonly outlook: CapitalMarketAssumptionOutlook,
                       public readonly date: Date,
                       public readonly fileName: string) {}

    public transform(): IHomeContentBoxDownloadableDataItem {
        return {
            title: this.title,
            date: this.date,
            backstopId: this.backstopId,
            url: "",
            type: DocumentType.CMA,
            download: () => InsightsApi.getDocumentDownload(this.backstopId, this.fileName, true),
        };
    }
}

class CapitalMarketAssumptionBuilder {
    private mBackstopId: number;
    private mTitle: string;
    private mOutlook: CapitalMarketAssumptionOutlook;
    private mDate: Date;
    private mFileName: string;

    public backstopId(value: number) {
        this.mBackstopId = value;
        return this;
    }

    public title(value: string) {
        this.mTitle = value;
        return this;
    }

    public outlook(value: CapitalMarketAssumptionOutlook) {
        this.mOutlook = value;
        return this;
    }

    public date(value: Date) {
        this.mDate = value;
        return this;
    }

    public fileName(value: string) {
        this.mFileName = value;
        return this;
    }

    public build(): CapitalMarketAssumption {
        return new CapitalMarketAssumption(
            this.mBackstopId || 0,
            this.mTitle || "",
            this.mOutlook || CapitalMarketAssumptionOutlook.TEN_YEAR,
            this.mDate || new Date(),
            this.mFileName || "",
        );
    }
}

export enum CapitalMarketAssumptionOutlook {
    TEN_YEAR = "TEN_YEAR",
    THIRTY_YEAR = "THIRTY_YEAR",
}
