import * as React from "react";
import {IClientInfo, IPlanInfo} from "../../model/ClientsAndPlans.model";
import {UserInfo} from "../../model/UserInfo.model";
import {byName} from "../../utils/listUtil";
import {MyClientPlan} from "./MyClientPlan.component";

export interface IMyClientProps {
    client: IClientInfo;
    users: UserInfo[];
    allPlans: IPlanInfo[];
    expandedFromParent: boolean;
}

export const MyClient: React.FunctionComponent<IMyClientProps> = (props) => {
    return renderClient();

    function renderClient() {
        return <div className={`paper-style my-clients__client my-clients__client__${props.client.id}`}
                    key={props.client.id} data-testid={`my-clients__client__${props.client.id}`}>
            <div className="my-clients__client-name" data-testid="my-clients__client-name">
                {props.client.name}
            </div>
            {renderPlans(props.client.id, props.users)}
        </div>;
    }

    function renderPlans(clientId: number, users: UserInfo[]) {
        const plansSet = new Set(props.allPlans.map((plan) => plan.id));

        return props.client.plans.valueSeq().toArray()
            .filter((plan) => plansSet.has(plan.id) && plan.name.toLowerCase() !== "empower results")
            .sort(byName)
            .map((plan) => {
                return <MyClientPlan
                            plan={plan}
                            users={users}
                            expandedFromParent={props.expandedFromParent}
                            key={plan.id}/>;
            });
    }
};
