import * as React from "react";
import {useState} from "react";
import {connect} from "react-redux";
import {bindActionCreators, Dispatch} from "redux";
import {IPlanInfo} from "../../model/ClientsAndPlans.model";
import {UserInfo} from "../../model/UserInfo.model";
import {formatNumericDate} from "../../utils/dateUtil";
import adminActions from "../admin/adminActions";
import {DialogComponent} from "../common/Dialog.component";
import {capitalizeFirstLetter} from "../../utils/commonUtil";
import {scrollToElement} from "../../utils/browserUtil";

export interface IMyClientUserProps {
    user: UserInfo;
    index: number;
    plan: IPlanInfo;
}

interface IMyClientUserActions {
    actions: {
        removeUserFromPlan: typeof adminActions.removeUserFromPlan,
    };
}

export const MyClientUser: React.FunctionComponent<IMyClientUserProps & IMyClientUserActions> = (props) => {
    const [openDialog, setOpenDialog] = useState(false);
    return renderUser();

    function renderUser() {
        const lastLoginDate = props.user.lastLoginDate ? props.user.lastLoginDate.trim() : props.user.lastLoginDate;
        return <tr className="my-clients__user-table-row my-clients__user-info"
                   key={`my-clients__user-table-row-${props.index}`} data-testid="my-clients__user-table-row">
            <td className="my-clients__user-table-column my-clients__user-info__first-name">
                {props.user.firstName}
            </td>
            <td className="my-clients__user-table-column my-clients__user-info__last-name">
                {props.user.lastName}
            </td>
            <td className="my-clients__user-table-column my-clients__user-info__email"
                data-testid="my-clients__user-info__email">
                {props.user.email}
            </td>
            <td className="my-clients__user-table-column my-clients__user-info__user-type">
                {capitalizeFirstLetter(props.user.userType)}
            </td>
            <td className="my-clients__user-table-column my-clients__user-info__last-login-date"
                data-testid="my-clients__user-info__last-login-date">
                {(lastLoginDate && formatNumericDate(lastLoginDate)) || "Never"}
            </td>
            <td className="my-clients__user-table-column">
                <div className="remove-link-container">
                    <a className="remove-link clickable" data-testid="remove-link"
                       onClick={() => setOpenDialog(true)}>Remove</a>
                </div>
                {renderDeleteDialog()}
            </td>
        </tr>;
    }

    function renderDeleteDialog() {
        return  <DialogComponent noText="no, keep 'em"
                                 yesText="yes, remove 'em"
                                 title="Remove this user from the plan?"
                                 description="User will no longer have access to this plan."
                                 open={openDialog}
                                 onYes={() => {props.actions.removeUserFromPlan(
                                     props.user.arbtSecurityId,
                                     props.user.email,
                                     props.plan.id,
                                     props.plan.name);
                                     setOpenDialog(false);
                                     scrollToElement(".main__header", 0);
                                 }}
                                 onNo={() => setOpenDialog(false)}
        />;
    }
};

export const mapDispatchToProps = (dispatch: Dispatch): IMyClientUserActions => {
    return {
        actions: bindActionCreators({
            removeUserFromPlan: adminActions.removeUserFromPlan,
        }, dispatch),
    };
};

const connectedComponent = connect(null, mapDispatchToProps)(MyClientUser);
export default connectedComponent;
