export const FEEDBACK_SUBMIT_ACTION = "FEEDBACK_SUBMIT_ACTION";

export const feedbackPageAction = (feedbackText: string) => {
    return {type: FEEDBACK_SUBMIT_ACTION, payload: feedbackText};
};

const feedbackPageActions = {
    feedbackPageAction,
};

export default feedbackPageActions;
