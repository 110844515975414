import {AxiosError} from "axios";

export class ApiError {
    public static fromAxios(error: { response: { status: number, data: string } }): ApiError {
        if (!error.response) {
            return  new ApiError(500, "Internal server error");
        }
        const response = error.response;
        const message = response.data;

        return new ApiError(error.response.status, (message ? message : undefined));
    }

    public static fromAxiosWithBody(error: AxiosError): ApiError {
        if (!error.response) {
            return new ApiError(500, "Internal server error");
        }

        if (error.response!.data || (!error.response!.data as any).error) {
            return this.fromAxios(error as any);
        }

        return new ApiError(error.response!.status, (error.response!.data as any)?.error);
    }

    constructor(public errorNumber: number,
                public message?: string) {
    }
}
