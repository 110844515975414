import {MeetingNoteDetail} from "../../model/MeetingNoteDetail.model";
import {MeetingNoteSummary} from "../../model/MeetingNoteSummary.model";
import {parseDate} from "../../utils/dateUtil";

export function mapMeetingNoteDetail(meetingNoteData: any) {
    return new MeetingNoteDetail(
        meetingNoteData.backstopId,
        meetingNoteData.description,
        meetingNoteData.location,
    );
}

export function mapMeetingNoteSummary(meetingNoteSummaryData: any) {

    return MeetingNoteSummary.builder()
        .backstopId(meetingNoteSummaryData.backstopId)
        .title(meetingNoteSummaryData.title)
        .date(parseDate(meetingNoteSummaryData.occurredDate))
        .managerIds(meetingNoteSummaryData.managerIds)
        .productIds(meetingNoteSummaryData.productIds)
        .hasProductAttachment(meetingNoteSummaryData.hasProductAttachment)
        .fileName(meetingNoteSummaryData.fileName)
        .build();
}
