import OktaAuth from "@okta/okta-auth-js";
import {getConfig} from "./envUtil";
import {getLocationHostname} from "../api/BrowserWrapper";

let internalOktaAuth: OktaAuth | undefined;
const getOktaAuthInternal = (): OktaAuth => {
    // aon okta
    // const CLIENT_ID = "0oa5yl4y21XFTrAbz1d7";
    const CLIENT_ID = getConfig().FRONTEND_CONFIG_OKTA_CLIENT_ID;
    // const ISSUER = "https://iam-exttest.aon.com/oauth2/default";
    const ISSUER = getConfig().FRONTEND_CONFIG_OKTA_ISSUER;

    // dev okta
    // const CLIENT_ID = "0oaadhp8pmiYH8AfW5d7";
    // const ISSUER = "https://dev-52948602.okta.com/oauth2/default";

    // const BASENAME = "https://local:8080";
    const BASENAME = getConfig().FRONTEND_CONFIG_ENVIRONMENT !== "local" ?
    `https://${getConfig().FRONTEND_CONFIG_HOSTNAME}` :
    `https://${getLocationHostname()}:8080`;

    const REDIRECT_URI = `${BASENAME}/login/callback`;

    const oktaConfig = {
        oidc: {
            clientId: CLIENT_ID,
            issuer: ISSUER,
            redirectUri: REDIRECT_URI,
            scopes: ['openid', 'profile', 'email'],
            pkce: true,
            disableHttpsCheck: false,
            useInteractionCode: false
        },
        resourceServer: {
        },
        app: {
            basename: BASENAME,
        }
    };

    return new OktaAuth(oktaConfig.oidc);
};

export const getOktaAuth = (): OktaAuth => {
    if (internalOktaAuth) return internalOktaAuth;
    internalOktaAuth = getOktaAuthInternal();
    return internalOktaAuth;
};
