import * as d3 from "d3";
import {Selection} from "d3";
import {OrderedMap} from "immutable";
import * as React from "react";
import {useEffect, useRef} from "react";
import {useState} from "react";
import {LegacyColors} from "../../../../../css/Colors";
import {
    CashflowsChartingHelper,
    chartSvg,
    getChartWidth,
    getTextWidth,
    HORIZONTAL_BAND_WIDTH,
    HORIZONTAL_CHART_SPACER,
    HORIZONTAL_NET_BAR_WIDTH,
    horizontalChartDimensions,
    IChartVariables,
} from "./CashFlowsChartingUtils";
import {ICashflow} from "./CashFlowsUtils";
import {formatCurrencyTicks} from "../../../utils/d3Util";

export interface ICashFlowsByAssetClass {
    cashflows: OrderedMap<string, ICashflow>;
    showInflowOutflow: boolean;
}

const suffix = "-horizontal";
let chartingHelper: CashflowsChartingHelper;

export const CashFlowsByKeyChart: React.FunctionComponent<ICashFlowsByAssetClass> = (props) => {
    const [svgWidth, setSvgWidth] = useState(getChartWidth());

    const tooltipRef = useRef(null);

    function getChartHeight() {
        return HORIZONTAL_BAND_WIDTH * props.cashflows.size;
    }

    function drawChartOfWidth() {
        const xOffset = svgWidth / 3;
        chartingHelper = new CashflowsChartingHelper(
            false,
            svgWidth,
            suffix,
            xOffset,
            horizontalChartDimensions.topAndBottom,
            getChartHeight(),
        );
        drawGraph();
    }

    function drawGraph() {
        chartingHelper.cleanUpGraph();
        chartingHelper.setupGraph();

        chartingHelper.setCashFlowsByKey(props.cashflows);

        const bars = chartingHelper.getBarsVariables(props.showInflowOutflow);

        renderYAxis1(bars[0]);
        renderXAxis(bars[0]);
        addUsDollars();
        bars.map((bar, index) => renderBar(bar, props.showInflowOutflow && index === 0));
        chartingHelper.renderLegend(bars);
    }

    useEffect(() => {
        window.addEventListener("resize", () => {
                setSvgWidth(getChartWidth());
            },
        );
    }, []);

    useEffect(() => {
        drawChartOfWidth();
    }, [props, svgWidth]);

    return <div style={{position: "relative"}} data-testid="cash-flows-by-key-chart__container">
        <div className="hidden" ref={tooltipRef}>
            <span id="cash-flows__tool-tip-text"/>
        </div>
        <svg id={`${chartSvg}${suffix}`}
             width={svgWidth}
             height={getChartHeight() + horizontalChartDimensions.topAndBottom * 2 + 47 + 38}/>
    </div>;

    function renderYAxis1(chartVariables: IChartVariables) {

        d3.select(`#${chartingHelper.getChartSvg()}`)
            .append("g")
            .selectAll("line")
            .data(chartVariables.dataByKey.reverse())
            .join("line")
            .join("text")
            .text((d: any) => {
                return d.key!;
            })
            .attr("x1", 0)
            .attr("y1", (d: any) => {
                return chartVariables.xBandScale(d.key)!;
            })
            .attr("x2", chartingHelper.getChartWidth() - horizontalChartDimensions.leftAndRight * 2)
            .attr("y2", (d: any) => {
                return chartVariables.xBandScale(d.key)!;
            })
            .style("stroke-width", 1)
            .style("stroke", LegacyColors.LightGray)
            .attr("transform", () => `translate( ${horizontalChartDimensions.leftAndRight}, ${horizontalChartDimensions.topAndBottom})`);

        const yAxis = d3.axisLeft(chartVariables.xBandScale)
            .tickFormat(() => "")
            .tickSizeInner(0)
            .tickSizeOuter(0);

        chartVariables.chartRef.append("g")
            .call(yAxis)
            .attr("transform", `translate(${chartVariables.yLinearScale(0)}, 0)`);

        renderYaxisLabels(chartVariables);

    }

    function truncateText() {
        const self: Selection<any, any, null, undefined> = d3.select(this);
        const textLength = getTextWidth(self.node());
        const text = self.text();
        const maxWidth = svgWidth / 3 - HORIZONTAL_CHART_SPACER;
        if (textLength > maxWidth && text.length > 0) {
            const charWidth = textLength / text.length;
            const charNumber = maxWidth / charWidth - 3;
            const newText = text.slice(0, charNumber);
            const tooltipX = textLength / 2;
            self.text(newText + "...");
            self.classed("clickable", true)
                .on("mouseover", () => hoverLabel(self, text, tooltipX))
                .on("mouseout", () => unhoverLabel());
        }
    }

    function hoverLabel(self: Selection<any, any, null, undefined>, text: string, x: number) {
        const y: number = Number.parseInt(self.attr("y"), 10) + HORIZONTAL_BAND_WIDTH + 15;

        d3.select(tooltipRef.current)
            .attr("class", "cash-flows__tool-tip")
            .style("left", x + "px")
            .style("top",  y + "px")
            .select("span")
            .text(text);
    }

    function unhoverLabel() {
        d3.select(tooltipRef.current)
            .attr("class", "hidden");
    }

    function renderYaxisLabels(chartVariables: IChartVariables) {
        d3.select(`#${chartingHelper.getChartSvg()}`)
            .append("g")
            .selectAll("text")
            .data(chartVariables.dataByKey.reverse())
            .join("text")
            .text((d: any) => d.key!.toLowerCase())
            .call((g) => g.attr("class", "small bold cashflow-bar-chart__y-tick"))
            .attr("x", 0)
            .attr("y", (d: any) => chartVariables.xBandScale(d.key)!)
            .attr("transform", () => {
                    return `translate( ${horizontalChartDimensions.leftAndRight}, ${horizontalChartDimensions.topAndBottom + chartVariables.xBandScale.bandwidth() / 2 + 4} )`;
                },
            )
            .each(truncateText)
        ;
    }

    function renderXAxis(chartVariables: IChartVariables) {
        const [min, max] = chartVariables.yLinearScale.domain();

        const step = 9;
        const stepValue = (max - min) / (step - 1);
        const tickValues = d3.range(min, max + stepValue, stepValue);

        const xAxis = d3.axisBottom(chartVariables.yLinearScale)
            .tickValues(tickValues)
            .tickFormat((d: any) => formatCurrencyTicks(d, 2, true))
            .tickSizeOuter(0);

        chartVariables.chartRef.append("g")
            .call(xAxis)
            .attr("transform", `translate(0,${horizontalChartDimensions.height})`);
    }

    function addUsDollars() {
        const text = "US Dollars";
        d3.select(`#${chartingHelper.getChartSvg()}`)
            .append("g")
            .append("text")
            .text(text)
            .attr("font-weight", "bold")
            .attr(
                "transform",
                `translate(${svgWidth / 2 - 29}, ${getChartHeight() + horizontalChartDimensions.topAndBottom * 2 + 47 + 38 - 60})`,
            );
    }

    function addRect(chartVariables: IChartVariables, barWidth: number, yAlignment: number) {
        return chartVariables.chartRef
            .selectAll("rect")
            .data(chartVariables.dataByKey)
            .join("rect")
            .attr("y", (d) => chartVariables.xBandScale(d.key!)! + yAlignment)
            .attr("x", (d) => chartVariables.yLinearScale(Math.min(0, d.value!))!)
            .attr("height", barWidth)
            .attr("width", (d) => Math.abs(chartVariables.yLinearScale(d.value!)! - chartVariables.yLinearScale(0)!))
            .attr("fill", chartVariables.barColor ? chartVariables.barColor : "red")
            .attr("class", "cash-flow-horizontal-bar-chart__bar")
            .attr("key", (d) => d.key!);
    }

    function renderBar(chartVariables: IChartVariables, hideLabels: boolean) {
        const barWidth = chartVariables.barWidth ? chartVariables.barWidth : HORIZONTAL_NET_BAR_WIDTH;
        const yAlignment = chartVariables.barWidth
            ? chartVariables.xBandScale.bandwidth() / 2 + HORIZONTAL_NET_BAR_WIDTH / 2
            : chartVariables.xBandScale.bandwidth() / 2 - HORIZONTAL_NET_BAR_WIDTH / 2;

        const yLabelAlignment = chartVariables.barWidth
            ? chartVariables.xBandScale.bandwidth() / 2 + HORIZONTAL_NET_BAR_WIDTH / 2 + barWidth / 2 + 4
            : chartVariables.xBandScale.bandwidth() / 2 + 4;

        const bar = addRect(chartVariables, barWidth, yAlignment);

        if (chartVariables.patternUrl) {
            const patternBarChartRef = d3.select(`#${chartingHelper.getCashflowPatternBarG()}`);
            const barPattern = addRect({...chartVariables, chartRef: patternBarChartRef}, barWidth, yAlignment);
            barPattern.attr("fill", `url(#${chartVariables.patternUrl})`);
        }

        const label = bar.select("g")
            .data(chartVariables.dataByKey)
            .join("g")
            .attr("transform", (d) => `translate( ${chartVariables.yLinearScale(d.value!)}, ${chartVariables.xBandScale(d.key!)! + yLabelAlignment})`);

        if (!hideLabels) {
            label.append("text")
                .text((d) => formatCurrencyTicks(d.value, 3))
                .attr("text-anchor", "middle")
                .attr("dx", (d) => d.value > 0 ? "25px" : "-25px")
                .attr("class", "cash-flow-bar-chart__amount");
        }
    }
};
