import * as React from "react";
import {IInformSubComponentFactor} from "../../model/inform/InformSubComponentDetails";
import {IInformSubComponent, InformSubcomponentType} from "../../model/inform/Inform";
import {IInformESG} from "../../model/inform/InformESG";
import {InformESGResponses} from "./InformESGResponses";
import {getShortMonthAndYearString} from "../../utils/dateUtil";
import {formatCurrency} from "../../utils/numberUtil";

export interface IInformSubComponentRowProps {
    hasThreshold?: boolean,
    detail: IInformSubComponentFactor,
    component: IInformSubComponent | undefined,
    index: number,
    expandedRowIndex: number | undefined,
    setExpandedRowIndex: (index: number) => void,
    reportingCurrency: string
}

export const LAST_FACTOR = "Last Change of Ownership";
export const FIRM_FACTOR = "Firm Net Asset Flow";
export const PRODUCT_FACTOR = "Product Net Asset Flow";
export const STYLE_FACTOR = "Style Consistency - Market Capitalization";

export const InformSubComponentRow: React.FunctionComponent<IInformSubComponentRowProps> = (props) => {
    const renderValues = (details: IInformSubComponentFactor) => {
        return details.value.map((it) => <ul key={it}>{formatValueThreshold(it, details)}</ul>);
    };

    const isESG = () => {
        return props.component?.type === InformSubcomponentType.ESG;
    };

    const formatValueThreshold = (value: string | undefined, details?: IInformSubComponentFactor,
                                  isForThresholdColumn = false) => {
        if (details?.factor === LAST_FACTOR) {
            return toShortMonthDate(value);
        }

        const decimalFields = isForThresholdColumn
            ? [FIRM_FACTOR, PRODUCT_FACTOR]
            : [FIRM_FACTOR, PRODUCT_FACTOR, STYLE_FACTOR];

        if (details?.factor && decimalFields.includes(details.factor)) {
            return value ? formatCurrency(Math.round(+value), 0, props.reportingCurrency) : value;
        }
        return value;
    };

    const toShortMonthDate = (value: string | undefined) => {
        if (value) {
            const valueSplit = value.split("/");
            return valueSplit.length === 3 && (!isNaN(Date.parse(value)))
                ? getShortMonthAndYearString(new Date(value))
                : value;
        }
        return value;
    };

    const renderExpandRowIcon = () => {
        return <i data-testid="row-detail-icon"
                  className={props.expandedRowIndex === props.index ? "fa fa-regular fa-minus minus" : "fa fa-regular fa-plus plus"}/>;
    };

    const renderExpandedRow = () => {
        return <tr className={`inform-sub-component__details-table-expanded-row ${isESG() ? "collapsed" : "expanded"}`}
                   data-testid="inform-sub-component__details-table-expanded-row">
            <td colSpan={7} className="inform-sub-component__details-table-expanded-column">
                <div>
                    <div className="inform-sub-component__details-table-expanded-row-header">
                        <p>{props.detail.expandedData?.description}</p>
                    </div>
                    {isESG()
                        ? <InformESGResponses esg={props.component as IInformESG} moduleName={props.detail.factor}/>
                        : renderNonESGExpandedRow()}
                </div>
            </td>
        </tr>;
    };
    const renderNonESGExpandedRow = () => {
        return <div className="inform-sub-component__details-table-expanded-row-header expanded">
            {props.detail.expandedData?.responses?.map((it) => <p key={it}>{it}</p>)}
        </div>;
    };

    return (<>
        <tr key={props.detail.factor}
            data-testid="inform-sub-component__details-table-row"
            className={`inform-sub-component__details-table-row ${props.expandedRowIndex === props.index && !isESG() ? "expanded" : "collapsed"}`}
            onClick={() => props.setExpandedRowIndex(props.index)}>
            <td>{props.detail.factor}</td>
            <td>{props.detail.rating}</td>
            <td>{props.detail.change}</td>
            <td className="inform-sub-component__details-table-value-cell"
                data-testid="inform-sub-component__details-table-value-cell">{renderValues(props.detail)}</td>
            {props.hasThreshold &&
                <td className="inform-sub-component__details-table-value-cell"
                    data-testid="inform-sub-component__details-table-threshold-cell">
                    {formatValueThreshold(props.detail.threshold, props.detail, true)}
                </td>
            }
            <td>{renderExpandRowIcon()}</td>
        </tr>
        {props.expandedRowIndex === props.index && renderExpandedRow()}
    </>);
};