import * as React from "react";
import {StyledToggle} from "../common/material-ui-core/Toggle.component";
import {PleaseSelectElementNotification} from "../common/PleaseSelectElementNotification.component";
import UserSelectComponent from "./UserSelect.component";
import {UserTypeEnum} from "../../model/UserInfo.model";
import {FormattedMessage} from "react-intl";
import {getTranslation} from "../../utils/translationUtil";

export interface IEmailNotificationSection {
    emailEnabled: boolean;
    selectedPlans: number[];
    toggleEnabled: boolean;
    selectedUsers: number[];
    setSelectedUsers: (users: number[]) => void;
    setToggleEnabled: (isToggled: boolean) => void;
    setComment: (comment: string) => void;
    userType: UserTypeEnum;
    thirdPartyEnabled: boolean;
}

export const EmailNotificationSection: React.FunctionComponent<IEmailNotificationSection> = (props) => {
    if (!props.emailEnabled) {
        return null;
    }

    return <div className={`upload-page__email-notification${props.toggleEnabled ? "" : " collapsed"}`}>
        <RenderToggle {...props} />
        <RenderNotificationOptions  {...props}/>
        <RenderAttachedUsersComponentAndLabel  {...props}/>
        <RenderNoUsersSelectedError {...props}/>
    </div>;
};

EmailNotificationSection.displayName = "EmailNotificationSection";

export const RenderToggle = (props: IEmailNotificationSection) => {

    function setToggle(state: boolean) {
        props.setComment("");
        props.setToggleEnabled(state);
    }

    return <div className="upload-page__email-label-and-toggle">
        <div className="upload-page__email-notification-label">
            <FormattedMessage id="upload.notifications" defaultMessage="Notifications" />
        </div>
        <div className="upload-page__email-toggle">
            <StyledToggle
                toggleState={props.toggleEnabled}
                setToggle={(state) => setToggle(state)}
                id="upload-page__email-toggle"
                label={getTranslation("upload.enable-email","Enable Email Notifications")}
                disabled={props.userType === UserTypeEnum.CLIENT}
            />
        </div>
    </div>;
};

export const RenderNotificationOptions = (props: IEmailNotificationSection) => {
    if (!props.toggleEnabled) {
        return null;
    }

    return <div className="upload-page__email-notification-options" data-testid="email-notification-section">
        <div className="upload-page__email-notification-options-label">
            <FormattedMessage id="upload.comments-added"
                  defaultMessage="Comments added in the box below will be included in an email to selected users." />

        </div>
        <div className="upload-page__email-notification-comment-box">
            <div className="upload-page__email-notification-comment-box-label">
                <FormattedMessage id="upload.add-comment" defaultMessage="Add Comment" />
            </div>
            <textarea
                className="upload-page__email-notification-comment-box-textarea"
                cols={80}
                rows={8}
                onChange={(e) => { props.setComment!(e.target.value); }}
            />
        </div>
        <div className="upload-page__email-notification-comment-box-optional-text">
            <FormattedMessage id="upload.optional" defaultMessage="Optional" />
        </div>
    </div>;
};

export const RenderAttachedUsersComponentAndLabel = (props: IEmailNotificationSection) => {
    if (!props.toggleEnabled) {
        return null;
    }

    return <div className="upload-page__email-attached-users">
            <FormattedMessage id="upload.select-users" defaultMessage="Select users to notify:" />
        <div className="upload-page__email-attached-users_spacer"/>
        <UserSelectComponent
            selectedPlans={props.selectedPlans}
            onSelectUsersChange={props.setSelectedUsers!}
            selectedUsers={props.selectedUsers}
            userType={props.userType}
            thirdPartyEnabled = {props.thirdPartyEnabled}
        />
    </div>;
};

const RenderNoUsersSelectedError = (props: IEmailNotificationSection) => {
    if (props.selectedUsers.length !== 0 || !props.toggleEnabled) {
        return null;
    }

    return <PleaseSelectElementNotification message={getTranslation("upload.please-select","Please select at least one user.")}/>;
};
