import {ApiBase} from "./ApiBase";
import {AxiosResponse} from "axios";
import {IPlanSummary} from "../model/PlanSummary.model";

export interface IKeyPoint {
    title: string;
    content: string;
}

export interface IKeyPointsResponse {
    userEmail: string;
    createdTimestamp: string;
    keyPoints: IKeyPoint[];
}

export interface IFundingLevel {
    asOfDate: string;
    basis: string;
    value: number;
}

export interface IAssets {
    assetsAsOf: string;
    assetsValue: string;
}

export interface IPerformance {
    timePeriod: string;
    value: string;
    type:string;
}

export interface IManagerUpdate {
    name: string,
    currentRating: string,
    lastReportingRating: string,
}

export class CprPortfolioApi {
    public static fetchKeyPoints(): Promise<IKeyPointsResponse | undefined> {
        return ApiBase.processGetUnwrapped(
            "cpr-portfolio/key-points",
            (response: AxiosResponse): any => {
                return response.data;
            }
        );
    }

    public static saveKeyPoints(keyPoints: IKeyPoint[]) {
        return ApiBase.processPostWithResponseBody(
            "cpr-portfolio/key-points",
            keyPoints,
            (response) => {
                return response.data;
            }
        );
    }

    public static fetchFundingLevel(): Promise<IFundingLevel | undefined> {
        return ApiBase.processGetUnwrapped(
            "/cpr-portfolio/funding-level",
            (response) => {
                return response.data as IFundingLevel;
            });
    }

    public static saveAssets(assets: IAssets) {
        return ApiBase.processPost( "/cpr-portfolio/assets", assets);
    }

    public static fetchAssets(): Promise<IAssets> {
        return ApiBase.processGetUnwrapped(
            "/cpr-portfolio/assets",
            (response) => {
                return response.data as IAssets;
            });
    }

    public static fetchPerformance(): Promise<IPerformance> {
        return ApiBase.processGetUnwrapped(
            "/cpr-portfolio/performance",
            (response) => {
                return response.data as IPerformance;
            }
        );
    }

    public static savePerformance(performance: IPerformance){
        return ApiBase.processPost("/cpr-portfolio/performance", performance);
    }

    public static fetchPlanSummary(): Promise<IPlanSummary | undefined> {
        return ApiBase.processGetUnwrapped(
            "cpr-portfolio/plan-summary",
            (response) => {
                return response.data as IPlanSummary;
            }
        );
    }

    public static fetchManagerUpdates(): Promise<IManagerUpdate[]> {
        return ApiBase.processGetUnwrapped(
            "cpr-portfolio/manager-updates",
            (response) => {
                return response.data as IManagerUpdate[];
            }
        );

    }
}