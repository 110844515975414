import {Action, handleActions} from "redux-actions";
import {IPlanSettings} from "../../api/AuthApi";
import {throwErrorOnNullOrUndefined} from "../../utils/errorUtil";
import {
    NOT_REQUESTED,
    NotRequested,
    REQUEST_STATES,
    REQUEST_SUCCEEDED,
    RequestState,
    RequestSucceeded,
} from "../common/commonStates";
import {LANG} from "../common/intlUtils";
import {RootActionTypes} from "./rootActions";

export const initialState = NOT_REQUESTED;

const reducerMap = {
    [RootActionTypes.SET_PLAN_SETTINGS]: (state: RequestState<IPlanSettings>,
                                          action: Action<IPlanSettings>): RequestSucceeded<IPlanSettings> => {
        return REQUEST_SUCCEEDED({
            hasResearchAccess: throwErrorOnNullOrUndefined(action.payload!.hasResearchAccess),
            emailEnabled: throwErrorOnNullOrUndefined(action.payload!.emailEnabled),
            hasClientResearchEnabled: throwErrorOnNullOrUndefined(action.payload!.hasClientResearchEnabled),
            planCountryName: throwErrorOnNullOrUndefined(action.payload!.planCountryName),
            hasHoldings: throwErrorOnNullOrUndefined(action.payload!.hasHoldings),
            hasClientReports: throwErrorOnNullOrUndefined(action.payload!.hasClientReports),
            sharedDocumentsEnabled: throwErrorOnNullOrUndefined(action.payload!.sharedDocumentsEnabled),
            oddIqEnabled: throwErrorOnNullOrUndefined(action.payload!.oddIqEnabled),
            performanceReportingEnabled: throwErrorOnNullOrUndefined(action.payload!.performanceReportingEnabled),
            cprPortfolioOverviewEnabled: throwErrorOnNullOrUndefined(action.payload!.cprPortfolioOverviewEnabled),
            clientReportingEnabled: throwErrorOnNullOrUndefined(action.payload!.clientReportingEnabled),
            clientPerformanceReportingAccess:
                throwErrorOnNullOrUndefined(action.payload!.clientPerformanceReportingAccess),
            languageId: throwErrorOnNullOrUndefined(action.payload!.languageId),
            planTypeId: action.payload!.planTypeId,
            investmentsEnabled: throwErrorOnNullOrUndefined(action.payload!.investmentsEnabled),
            clientUploadsEnabled: throwErrorOnNullOrUndefined(action.payload!.clientUploadsEnabled),
            oddReportsEnabled: throwErrorOnNullOrUndefined(action.payload!.oddReportsEnabled),
            hasProducts: throwErrorOnNullOrUndefined(action.payload!.hasProducts),
            insightsEnabled: throwErrorOnNullOrUndefined(action.payload!.insightsEnabled),
            thirdPartyAccess: throwErrorOnNullOrUndefined(action.payload!.thirdPartyAccess),
            powerbiReportAdmin:  throwErrorOnNullOrUndefined(action.payload!.powerbiReportAdmin),
            oddPowerBiEnabled:  throwErrorOnNullOrUndefined(action.payload!.oddPowerBiEnabled),
            showFactSheets: throwErrorOnNullOrUndefined(action.payload!.showFactSheets),
            cprMediaAdmin: throwErrorOnNullOrUndefined(action.payload!.cprMediaAdmin),
            aonTrustCompany: throwErrorOnNullOrUndefined(action.payload!.aonTrustCompany),
            calendarEnabledForConsultants:
                throwErrorOnNullOrUndefined(action.payload!.calendarEnabledForConsultants),
            calendarEnabledForClients: throwErrorOnNullOrUndefined(action.payload!.calendarEnabledForClients),
            hasPortfolioAccess: throwErrorOnNullOrUndefined(action.payload!.hasPortfolioAccess),
            clientPortfolioEnabled: throwErrorOnNullOrUndefined(action.payload!.clientPortfolioEnabled),
        });
    },

    [RootActionTypes.RESET_PLAN_SETTINGS]: (): NotRequested => {
        return NOT_REQUESTED;
    },
};

function getData<T>(state: RequestState<IPlanSettings> | undefined,
                    callback: (planSettings: IPlanSettings) => T,
                    fallback: T) {
    const data = state && state.kind === REQUEST_STATES.REQUEST_SUCCEEDED && state.data;
    return data && callback(data) || fallback;
}

export function planSettingsLoadedInner(state?: RequestState<IPlanSettings>) {
    return getData(state, (x) => x && true, false);
}

export function hasResearchAccessInner(state?: RequestState<IPlanSettings>) {
    return getData(state, (x) => x.hasResearchAccess, false);
}

export function hasPortfolioAccessInner(state?: RequestState<IPlanSettings>) {
    return getData(state, (x) => x.hasPortfolioAccess ? x.hasPortfolioAccess : false, false);
}

export function hasClientPortfolioAccessInner(state?: RequestState<IPlanSettings>) {
    return getData(state, (x) => x.clientPortfolioEnabled, false);
}

export function hasThirdPartyAccessInner(state?: RequestState<IPlanSettings>) {
    return getData(state, (x) => x.thirdPartyAccess, false);
}

export function hasPowerbiReportAdminInner(state?: RequestState<IPlanSettings>) {
    return getData(state, (x) => x.powerbiReportAdmin, false);
}

export function hasCprSharedMediaAdminInner(state?: RequestState<IPlanSettings>) {
    return getData(state, (x) => x.cprMediaAdmin, false);
}
export function hasAonTrustCompanyInner(state?: RequestState<IPlanSettings>) {
    return getData(state, (x) => x.aonTrustCompany, false);
}
export function hasCalendarEnabledForConsultantsInner(state?: RequestState<IPlanSettings>) {
    return getData(state, (x) => x.calendarEnabledForConsultants, false);
}
export function hasCalendarEnabledForClientsInner(state?: RequestState<IPlanSettings>) {
    return getData(state, (x) => x.calendarEnabledForClients, false);
}
export function hasOddPowerBiEnabledInner(state?: RequestState<IPlanSettings>) {
    return getData(state, (x) => x.oddPowerBiEnabled, false);
}

export function emailEnabledInner(state?: RequestState<IPlanSettings>) {
    return getData(state, (x) => x.emailEnabled, false);
}

export function hasClientResearchEnabledInner(state?: RequestState<IPlanSettings>) {
    return getData(state, (x) => x.hasClientResearchEnabled, false);
}

export function hasProductsInner(state?: RequestState<IPlanSettings>) {
    return getData(state, (x) => x.hasProducts, false);
}

export function showFactSheetsInner(state?: RequestState<IPlanSettings>) {
    return getData(state, (x) => x.showFactSheets, false);
}

export function planCountryNameInner(state?: RequestState<IPlanSettings>) {
    return getData(state, (x) => x.planCountryName, "");
}

export function hasHoldingsInner(state?: RequestState<IPlanSettings>): boolean {
    return getData(state, (x) => x.hasHoldings, false);
}

export function hasClientReportsInner(state?: RequestState<IPlanSettings>): boolean {
    return getData(state, (x) => x.hasClientReports, false);
}

export function sharedDocumentsEnabledInner(state?: RequestState<IPlanSettings>): boolean {
    return getData(state, (x) => x.sharedDocumentsEnabled, false);
}

export function clientUploadsEnabledInner(state?: RequestState<IPlanSettings>): boolean {
    return getData(state, (x) => x.clientUploadsEnabled, false);
}

export function oddIqEnabledInner(state?: RequestState<IPlanSettings>): boolean {
    return getData(state, (x) => x.oddIqEnabled, false);
}

export function oddReportsEnabledInner(state?: RequestState<IPlanSettings>): boolean {
    return getData(state, (x) => x.oddReportsEnabled, false);
}

export function performanceReportingEnabledInner(state?: RequestState<IPlanSettings>): boolean {
    return getData(state, (x) => x.performanceReportingEnabled, false);
}

export function cprPortfolioOverviewEnabledInner(state?: RequestState<IPlanSettings>): boolean {
    return getData(state, (x) => x.cprPortfolioOverviewEnabled, false);
}

export function clientReportingEnabledInner(state?: RequestState<IPlanSettings>): boolean {
    return getData(state, (x) => x.clientReportingEnabled, false);
}

export function clientPerformanceReportingAccessInner(state?: RequestState<IPlanSettings>): boolean {
    return getData(state, (x) => x.clientPerformanceReportingAccess, false);
}

export function planLanguageIdInner(state?: RequestState<IPlanSettings>) {
    return getData(state, (x) => x.languageId, LANG.ENGLISH);
}

export function planTypeIdSelectorInner(state?: RequestState<IPlanSettings>) {
    return getData(state, (x) => x.planTypeId, null);
}

export function investmentsEnabledInner(state?: RequestState<IPlanSettings>): boolean {
    return getData(state, (x) => x.investmentsEnabled, false);
}

export function insightsEnabledInner(state?: RequestState<IPlanSettings>): boolean {
    return getData(state, (x) => x.insightsEnabled, false);
}

export default handleActions<RequestState<IPlanSettings>, IPlanSettings>(reducerMap, initialState);
