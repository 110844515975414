import {IHomeContentBoxDataItem, IHomeListItem} from "./IHomeListItem";
import {IStrategyUpdate, StrategyUpdateType} from "./IStrategyUpdate.model";

export class FlashReportSummary implements IHomeListItem, IStrategyUpdate {

    public static builder() {
        return new FlashReportSummaryBuilder();
    }

    constructor(public backstopId: number,
                public title: string,
                public date: Date,
                public managerIds: number[],
                public productIds: number[],
                public hasProductAttachment: boolean | undefined,
                public fileName: string,
                public urlPrefix: string = "flash-reports",
                public type: StrategyUpdateType = StrategyUpdateType.FLASH_REPORT) {
    }

    public transform(): IHomeContentBoxDataItem {
        return {
            title: this.title,
            date: this.date,
            backstopId: this.backstopId,
            url: `/${this.urlPrefix}/${this.backstopId}`,
            type: "Flash Report",
        };
    }
}

class FlashReportSummaryBuilder {
    private mTitle: string;
    private mDate: Date;
    private mBackstopId: number;
    private mManagerIds: number[];
    private mProductIds: number[];
    private mHasProductAttachment: boolean | undefined = undefined;
    private mFileName: string;

    public backstopId(value: number) {
        this.mBackstopId = value;
        return this;
    }

    public title(value: string) {
        this.mTitle = value;
        return this;
    }

    public date(value: Date) {
        this.mDate = value;
        return this;
    }

    public managerIds(value: number[]) {
        this.mManagerIds = value;
        return this;
    }

    public productIds(value: number[]) {
        this.mProductIds = value;
        return this;
    }

    public hasProductAttachment(value: boolean) {
        this.mHasProductAttachment = value;
        return this;
    }

    public fileName(value: string) {
        this.mFileName = value;
        return this;
    }

    public build() {
        return new FlashReportSummary(
            this.mBackstopId || 0,
            this.mTitle || "title",
            this.mDate || new Date(0),
            this.mManagerIds || [],
            this.mProductIds || [],
            this.mHasProductAttachment,
            this.mFileName,
        );
    }
}

export class FlashReportSummaryFactory {
    public static create(backstopId: number, managerIds?: number[]) {
        return FlashReportSummary.builder()
            .backstopId(backstopId)
            .managerIds(managerIds || [])
            .build();
    }
}
