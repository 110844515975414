import {Action, Dispatch} from "redux";
import {IAttributeFilter} from "../../../model/Research.model";

export enum FilterActionTypes {
    UPDATE_SELECTED_RATING = "UPDATE_SELECTED_RATING",
    UPDATE_SELECTED_ASSET_CLASS = "UPDATE_SELECTED_ASSET_CLASS",
    UPDATE_SELECTED_SORT_OPTION = "UPDATE_SELECTED_SORT_OPTION",
    CLEAR_FILTERS = "CLEAR_FILTERS",
    UPDATE_SELECTED_ATTRIBUTES = "UPDATE_SELECTED_ATTRIBUTES",
}

const updateRatingSelectionAction = (payload: string) => {
    return {type: FilterActionTypes.UPDATE_SELECTED_RATING, payload};
};

const updateAssetClassSelectionAction = (payload: string) => {
    return {type: FilterActionTypes.UPDATE_SELECTED_ASSET_CLASS, payload};
};

const updateSortOptionSelectionAction = (payload: string) => {
    return {type: FilterActionTypes.UPDATE_SELECTED_SORT_OPTION, payload};
};

const updateSelectedAttributesAction = (payload?: IAttributeFilter) => {
    return {type: FilterActionTypes.UPDATE_SELECTED_ATTRIBUTES, payload};
};

const clearFiltersAction = () => {
    return {type: FilterActionTypes.CLEAR_FILTERS, payload: {}};
};

export const updateSelectedRating = (value: string) => {
    return (dispatch: Dispatch<Action<FilterActionTypes>>) => {
        dispatch(updateRatingSelectionAction(value));
    };
};

export const updateSelectedAssetClass = (value: string) => {
    return (dispatch: Dispatch<Action<FilterActionTypes>>) => {
        dispatch(updateAssetClassSelectionAction(value));
    };
};

export const updateSelectedSortOption = (value: string) => {
    return (dispatch: Dispatch<Action<FilterActionTypes>>) => {
        dispatch(updateSortOptionSelectionAction(value));
    };
};

export const updateSelectedAttributes = (value?: IAttributeFilter) => {
    return (dispatch: Dispatch<Action<FilterActionTypes>>) => {
        dispatch(updateSelectedAttributesAction(value));
    };
};

export const clearFilters = () => {
    return (dispatch: Dispatch<Action<FilterActionTypes>>) => {
        dispatch(clearFiltersAction());
    };
};

export default {
    updateSelectedAssetClass,
    updateSelectedRating,
    updateSelectedSortOption,
    clearFilters,
    updateSelectedAttributes,
};
