import {Map as ImmutableMap} from "immutable";
import {ICalendarMeeting, ICalendarTopic} from "../../api/CalendarApi";
import {CalendarTopicDetails} from "../../model/CalendarTopicDetails.model";

export interface ICalendarTopicWithYearAndQuarter extends ICalendarTopic{
    year: number;
    quarter: number;
}

export interface INextAndLastReviewTopics {
    lastReview: ICalendarTopicWithYearAndQuarter | undefined
    nextReview: ICalendarTopicWithYearAndQuarter | undefined,
}

const getTopicsWithYearAndQuarterByTemplateId =
    (meetingsWithTopics: ImmutableMap<string, ICalendarMeeting>, currentTopicTemplateId: number)
        : ICalendarTopicWithYearAndQuarter[] => {
        const topicsWithDates: ICalendarTopicWithYearAndQuarter[] = [];

        meetingsWithTopics?.valueSeq()?.toArray()?.forEach(meeting => {
            meeting.topics.forEach(topic => {
                topicsWithDates.push({
                    ...topic,
                    year: meeting.year,
                    quarter: meeting.quarter
                });
            });
        });

        return topicsWithDates
            .filter(topic => topic.templateTopicId === currentTopicTemplateId)
            .sort((a, b) => a.year - b.year || a.quarter - b.quarter);
    };

export const getLastAndNextReviewDates =
    (meetingWithTopics: ImmutableMap<string, ICalendarMeeting>, currentTopicDetails: CalendarTopicDetails)
        : INextAndLastReviewTopics => {
        const topicsWithYearAndQuarter =
            getTopicsWithYearAndQuarterByTemplateId(meetingWithTopics!, currentTopicDetails!.templateTopicId!);

        const currentTopicIndex = topicsWithYearAndQuarter.findIndex(topic => topic.id === currentTopicDetails.id);

        const nextReview = topicsWithYearAndQuarter.length > currentTopicIndex + 1
            ? topicsWithYearAndQuarter[currentTopicIndex + 1] : undefined;

        const lastReview = currentTopicIndex === 0
            ? undefined : topicsWithYearAndQuarter[currentTopicIndex - 1];

        return {lastReview, nextReview};
    };