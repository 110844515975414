import {Set} from "immutable";
import {IHolding, tiersLookup} from "../../api/HoldingsApi";
import {byDate, formatDate, getEndOfMonthDate} from "../../utils/dateUtil";
import {ISelectValue} from "../common/Select.component";
import {HoldingsGroupBy} from "./InvestmentsPage";
import {byName, compareInsensitive, INamed} from "../../utils/listUtil";
import {capitalizeFirstLetter} from "../../utils/commonUtil";
import {SortBy} from "./InvestmentsStrategiesTable.component";
import moment = require("moment");

const lodash = require("lodash");

export interface IData {
    name: string;
    value: number;
}

export interface IDataInterface {
    date: Date;
    name: string;
    value: number;
}

export function createStackData(input: IDataInterface[], series: string[]): { date: Date }[] {

    const dataNew = multimap(input.map((d) => [d.date, d]));

    const newObjectArray: {date: Date}[] = [];

    dataNew.forEach((arr, key) => {
            const sum = arr.reduce((partialSum: number, a: IDataInterface) => partialSum + a.value, 0);
            const initObject: any = {};

            series.forEach((seriesName) => {
                initObject[seriesName] = {percent: 0.00, amount: 0};
            });
            initObject.date = new Date(key);
            
            newObjectArray.push(arr.reduce((result: any, item: IDataInterface) => {
                result[item.name].percent += Math.fround(item.value * 100 / sum);
                result[item.name].amount += item.value;
                return result;
            }, initObject));
            series.forEach((seriesName) => {
                initObject[seriesName] = {
                    percent: (initObject[seriesName].percent as number).toFixed(2),
                    amount: (initObject[seriesName].amount as number).toFixed(0),
                };
            });
        },
    );

    return newObjectArray.sort((a, b) => byDate(b, a));
}

export function getStackKeys(input: IDataInterface[], month: number, year: number): string[] {
    const keys: string[] = Set(input.map((d: IDataInterface) => d.name)).toArray().sort();

    const monthData = input.filter((row) => row.date.getFullYear() === year && row.date.getMonth() === month - 1);

    const monthDataByKeys: {name: string, value: number}[] = [];
    keys.forEach((key) => {
        const sum = monthData
            .filter((row) => row.name === key)
            .reduce((acc: number, r: IDataInterface) => acc + r.value, 0);

        monthDataByKeys.push({name: key, value: sum});
    });

    monthDataByKeys.sort((a, b) => b.value - a.value);

    return monthDataByKeys.map((m) => m.name);
}

export const getDataSum = (data: IData[]) => {
    return data.reduce((accum, d) => accum + d.value, 0 );
};

export function getSumsByAsset(holdings: IHolding[]): IData[] {
    return Set(holdings.map((it) => it.assetClass))
        .map((assetClass: string) => {
            return {
                name: toTitleCase(assetClass),
                value: getHoldingsGroupSum(holdings, assetClass),
            };
        })
        .sort((valueA, valueB) => valueB.value - valueA.value)
        .toArray();
}

export function getSumsByTier(holdings: IHolding[]): IData[] {
    return Set(holdings.map((it) => it.tier))
        .map((tier: string) => {
            return {
                name: toTitleCase(tier),
                value: getHoldingsGroupSum(holdings, tier, HoldingsGroupBy.BY_TIER),
            };
        })
        .sort(byTierId)
        .toArray();
}

export function getTiers(holdings: IHolding[]): string[] {
   return Set(holdings.map((holding) => capitalizeFirstLetter(holding.tier)))
        .sort((tier1, tier2) => {return getTierId(tier1) - getTierId(tier2); })
        .toArray();
}

export function getAssetClasses(holdings: IHolding[]): string[] {
   return  Set(holdings.map((holding) => capitalizeFirstLetter(holding.assetClass)))
        .toArray()
        .sort((item1, item2) => compareInsensitive(item1, item2));
}

function byTierId(tierData1: IData, tierData2: IData) {
   return getTierId(tierData1.name) - getTierId(tierData2.name);
}

function getTierId(tierName: string) {
    return tiersLookup.find((it) => it.name.toLowerCase() === tierName.toLowerCase())!.id;
}

export function toTitleCase(str: string) {
    return str.replace(
        /\w\S*/g,
        (txt) => {
            return txt.charAt(0).toUpperCase() + txt.substr(1).toLowerCase();
        },
    );
}

function getHoldingsGroupSum(
    holdings: IHolding[], name: string, groupBy: HoldingsGroupBy = HoldingsGroupBy.BY_ASSET_CLASS) {
    return Math.round(
        holdings
            .filter((holding) => filterBy(holding, groupBy, name))
            .map((holding) => holding.assets)
            .reduce((asset1, asset2) => asset1 + asset2, 0),
    );
}

export function filterBy(holding: IHolding, groupBy: HoldingsGroupBy, name: string): boolean {
    return groupBy === HoldingsGroupBy.BY_ASSET_CLASS
    ? holding.assetClass.toLowerCase() === name.toLowerCase()
        : holding.tier.toLowerCase() === name.toLowerCase();
}

export function getHoldingsForAsset(holdings: IHolding[], groupName: string) {
    return holdings
        .filter((h) => filterBy(h, HoldingsGroupBy.BY_ASSET_CLASS, groupName))
        .map((h) => ({name: h.name, value: h.assets}))
        .sort((valueA, valueB) => valueB.value - valueA.value);
}

export function getHoldingsForGroupOrdered(holdings: IHolding[],
                                           groupName: string,
                                           groupBy: HoldingsGroupBy,
                                           sortBy: SortBy,
                                           orderDescending: boolean) {
    return holdings
        .filter((h) => filterBy(h, groupBy, groupName))
        .sort(getSortingFunction(groupBy, sortBy,  orderDescending));
        // .sort(getSortingFunction(groupName, byAssets));
}

const getSortingFunction = (groupBy: HoldingsGroupBy, sortBy: SortBy, orderDescending: boolean) => {
    switch (sortBy) {
        case SortBy.STRATEGY:
            return byStrategyName(orderDescending);
        case SortBy.GROUP:
            return byGroupName(orderDescending, groupBy);
        default:
            return byAssets(orderDescending);
    }
};

export function needSpecialSorting(groupName: string) {
    return groupName.toUpperCase() === "LIFECYCLE" || groupName.toUpperCase() === "TARGET DATE FUNDS";
}

export function bySpecial(stringA: string, stringB: string) {
    if(stringA.includes("20") && !stringB.includes("20"))
        return 1;
    if(stringB.includes("20")  && !stringA.includes("20"))
        return -1;
    return compareInsensitive(stringA, stringB);
}

function byAssets(sortDescending: boolean) {
    const reverseSort = sortDescending ? 1 : -1;
    return (valueA: IHolding, valueB: IHolding) => reverseSort * (valueB.assets - valueA.assets);
}

function byGroupName(sortDescending: boolean, groupBy: HoldingsGroupBy) {
    const reverseSort = sortDescending ? 1 : -1;
    return groupBy === HoldingsGroupBy.BY_ASSET_CLASS
    ? (valueA: IHolding, valueB: IHolding) => reverseSort * compareInsensitive(valueA.tier, valueB.tier)
    : (valueA: IHolding, valueB: IHolding) => reverseSort * compareInsensitive(valueA.assetClass, valueB.assetClass);
}

function byStrategyName(sortDescending: boolean) {
    const reverseSort = sortDescending ? 1 : -1;
    return (valueA: IHolding, valueB: IHolding) => reverseSort * byName(valueA as INamed, valueB as INamed);
}

function multimap(entries: [Date, IDataInterface][]) {
    const map = new Map();
    for (const [key, value] of entries) {
        const keyTime = key.getTime();
        const arr = map.has(keyTime) ? map.get(keyTime) : [];
        map.set(keyTime, arr.concat(value));
    }
    return map;
}

export const getPercent = (value: number, allData: IData[], fractionDigits: number) => {
    return (value * 100 / getDataSum(allData)).toFixed(fractionDigits) + "%";
};

export const getYears = (holdings: IHolding[]): ISelectValue[] => {
    const years = Set(holdings.map((holding) => holding.asOfDate.getFullYear()));
    return years.map((y: number) => ({name: y.toString(), id: y}))
        .toArray().sort((a, b) => b.id - a.id);
};

export const getLatestYear = (holdings: IHolding[]): number  => {
    const years = getYears(holdings);

    return years[0].id as number;
};

export const getLatestMonth = (holdings: IHolding[], y: number): number => {
    const months = getMonths(holdings, y);
    return months[months.length - 1].id as number;
};

export const  getMonths = (holdings: IHolding[], y: number): ISelectValue[] => {
    return lodash.uniqWith(holdings
            .filter((h) => h.asOfDate.getFullYear() === y)
            .map((h) => ({name: moment(h.asOfDate).format("MMMM"), id: h.asOfDate.getMonth()}))
        , lodash.isEqual)
        .sort((a: ISelectValue, b: ISelectValue) => (a.id as number) - (b.id as number));
};

export const getAsOfDateFormatted = (year: number, month: number): string => {
    return formatDate(getEndOfMonthDate(year, month), "MMMM DD, YYYY");
};

export const getHoldingsForMonth = (holdings: IHolding[], y: number, m: number) => {
    return holdings
        .filter((holding) => {
            const date = holding.asOfDate;
            return date.getMonth() === m && date.getFullYear() === y;
        });
};

export function getAssetsForGroupAndMonth(holdings: IHolding[], year: number, month: number, groupName: string, viewBy: HoldingsGroupBy) {
    return getHoldingsGroupSum(getHoldingsForMonth(holdings, year, month), groupName, viewBy);
}
