import {Map} from "immutable";
import * as React from "react";
import {Link} from "react-router-dom";
import {ManagerResponse} from "../../model/manager/ManagerResponse.model";
import {byName} from "../../utils/listUtil";

export interface IManagerNameListProps {
    managerIds: number[];
    allManagers: Map<number, ManagerResponse>;
}

interface ILinkData {
    id: number;
    name: string;
    url: string;
}

export type ManagerNameListProps = IManagerNameListProps;

export const ManagerNameList = (props: ManagerNameListProps) => {
    return <div data-testid="manager-name-list__container">
        <ul>
            {getManagerLinks(props.managerIds, props.allManagers)}
        </ul>
    </div>;
};

const getManagerLinks = (managerIds: number[], allManagerResponses: Map<number, ManagerResponse>) => {
    return getManagerData(managerIds, allManagerResponses).map(getManagerLink);
};

const getManagerLink = (linkData: ILinkData) => {
    return <li className="attachment" key={linkData.id}>
        {renderLink(linkData.id, linkData.url, linkData.name)}
    </li>;
};

const getManagerData = (managerIds: number[], allManagerResponses: Map<number, ManagerResponse>) => {
    return managerIds
        .map((backstopId: number) => allManagerResponses.get(backstopId))
        .map(getLinkData)
        .sort(byName);
};

const getLinkData = (item: ManagerResponse) => {
    return {
        id: item.backstopId,
        name: item.name,
        url: `/managers/${item.backstopId}`,
    };
};

const renderLink = (id: number, url: string, name: string) => {
    return <Link to={url} className={"link-14"}>
        {name}
    </Link>;
};
