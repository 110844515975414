import * as React from "react";
import {HoldingsGroupBy} from "../InvestmentsPage";
import {NewSelectComponent} from "../../common/NewSelect.component";
import {getProductsForGroup} from "./CashFlowsUtils";
import {allAssetClasses, allProducts, allTiers} from "./CashFlows.component";
import {getAssetClasses, getTiers} from "../InvestmentsDataUtil";
import {IHolding} from "../../../api/HoldingsApi";
import {useEffect} from "react";

export interface ICashFlowsFiltersProps {
    holdings: IHolding[];
    groupBy: HoldingsGroupBy;
    selectedGroup: string;
    selectedProduct: string;
    handleGroupChange: (arg: any) => void;
    handleProductChange: (arg: any) => void;
}

export const CashFlowsFilters: React.FunctionComponent<ICashFlowsFiltersProps> = (props) => {
    function getGroupsNames() {
        return props.groupBy === HoldingsGroupBy.BY_ASSET_CLASS
            ? [allAssetClasses, ...getAssetClasses(props.holdings)]
            : [allTiers, ...getTiers(props.holdings)];
    }
    const [options, setOptions] = React.useState<string[]>(getGroupsNames());


    useEffect(() => {
        setOptions(getGroupsNames());
    }, [props.groupBy]);

    function renderAssetClassFilter() {
        return <div className="cash-flows__filter" data-testid="cash-flows__asset-class-filter">
            <div className="small bold">
                {props.groupBy === HoldingsGroupBy.BY_ASSET_CLASS ? "Asset Class" : "Tier"}
            </div>
            <NewSelectComponent id="cash-flows__filter-asset-classes"
                                classNameSuffix="cash-flows__filter-asset-classes"
                                selected={props.selectedGroup}
                                valuesAsStrings={options}
                                width={200}
                                handleChange={props.handleGroupChange}
            />
        </div>;
    }

    function renderProductFilter() {
        return props.selectedGroup === allAssetClasses || props.selectedGroup === allTiers
            ? null
            : <div className="cash-flows__filter" data-testid="cash-flows__product-filter">
                <div className="small bold">Product</div>
                <NewSelectComponent id="cash-flows__filter-products"
                                    classNameSuffix="cash-flows__filter-products"
                                    selected={props.selectedProduct}
                                    valuesAsStrings={[allProducts,
                                        ...getProductsForGroup(props.holdings, props.selectedGroup, props.groupBy)]}
                                    width={200}
                                    handleChange={props.handleProductChange}
                />
            </div>;
    }
    return  <div className="cash-flows__filters-container">
        {renderAssetClassFilter()}
        {renderProductFilter()}
    </div>;
};
