import {FormGroup} from "@mui/material";
import * as React from "react";
import {PleaseSelectElementNotification} from "./PleaseSelectElementNotification.component";
import {CheckBoxComponent} from "./CheckBox.component";

export interface IElement {
    name: string;
    id: number;
}

export interface ISelectCheckBoxesProps {
    elements: IElement[];
    selectedElements: number[];
    maxOneColumn: number;
    onSelectElementsChange: (arg: number[]) => void;
    noElementsSelectedMsg?: string;
    disabled?: boolean;
}

type CheckBoxesSelectProps = ISelectCheckBoxesProps;

const SelectCheckBoxesComponent:
    React.FunctionComponent<CheckBoxesSelectProps> = (props: CheckBoxesSelectProps) => {

    return <div className="select-check-box" data-testid="select-check-boxes-component">
            {renderSelectAllBox(props)}
            {renderFormGroup(props)}
            {renderNoElementsError(props)}
        </div>;
};

const renderSelectAllBox = (props: CheckBoxesSelectProps) => {
    return <CheckBoxComponent
        name="checkBoxesSelect-component_selectBoxes"
        checked={allSelected(props)}
        onChange={() => handleSelectAll(props)}
        label="Select All"
        labelId="upload.select-all"
        disabled={props.disabled ?? false}
        indeterminate={onlySomeSelected(props)}
    />;
};

const allSelected = (props: CheckBoxesSelectProps) => {
    return props.selectedElements.length === props.elements.length;
};

const onlySomeSelected = (props: CheckBoxesSelectProps) => {
    return props.selectedElements.length > 0 && props.selectedElements.length < props.elements.length;
};

const handleSelectAll = (props: CheckBoxesSelectProps) => {
    if (allSelected(props)) {
        props.onSelectElementsChange([]);
    } else {
        const planIds = props.elements.map((plan: any) => plan.id);
        props.onSelectElementsChange(planIds);
    }
};

const renderFormGroup = (props: CheckBoxesSelectProps) => {
    const singleColumn = props.elements.length <= props.maxOneColumn;
    const doubleColumnShort = props.elements.length <= props.maxOneColumn * 2;

    const renderElementsPassedIn = renderElements(props);

    if (singleColumn) {
        return (
            <FormGroup className={"checkBoxesSelect-component_single-column"}>
                {renderElementsPassedIn(props.elements)}
            </FormGroup>
        );
    } else {
        const endOfFirstCol = doubleColumnShort
            ? props.maxOneColumn
            : (props.elements.length / 2) + (props.elements.length % 2);
        const firstColElements = props.elements.slice(0, endOfFirstCol);
        const secondColElements = props.elements.slice(endOfFirstCol, props.elements.length);
        return (
            <div className={"checkBoxesSelect-component_selectBoxes"}>
                <FormGroup className={"checkBoxesSelect-component_first-column"}>
                    {renderElementsPassedIn(firstColElements)}
                </FormGroup>
                <FormGroup className={"checkBoxesSelect-component_second-column"}>
                    {renderElementsPassedIn(secondColElements)}
                </FormGroup>
            </div>
        );
    }
};

const renderElement = (props: CheckBoxesSelectProps, element: IElement) => {
    return <CheckBoxComponent
        name={`${element.id}`}
        checked={props.selectedElements.includes(element.id)}
        onChange={() => {
            handleChange(props, element.id);
        }}
        label={element.name}
        disabled={props.disabled ?? false}
        key={element.id}
    />;
};

const handleChange = (props: CheckBoxesSelectProps, elementId: number) => {
    const users = [...props.selectedElements];
    const index = users.indexOf(elementId);
    if (index === -1) {
        users.push(elementId);
        props.onSelectElementsChange(users);
    } else {
        users.splice(index, 1);
        props.onSelectElementsChange(users);
    }
};

const renderElements = (props: CheckBoxesSelectProps) => (elements: IElement[]) => {
    return elements.map((element) => renderElement(props, element));
};

const renderNoElementsError = (props: CheckBoxesSelectProps) => {
    return (props.noElementsSelectedMsg && props.selectedElements.length === 0)
        ? <PleaseSelectElementNotification message={props.noElementsSelectedMsg}/>
        : null;
};

export default SelectCheckBoxesComponent;
