import * as React from "react";
import {useEffect, useState} from "react";
import {RaisedButton} from "../common/RaisedButton";
import {prominentButtonStyle, secondaryButtonStyle} from "../common/buttonStyles";
import {ISelectValue, SelectComponent} from "../common/Select.component";
import {TextFieldComponent} from "../common/TextField.component";
import {IMediaGroup, IServiceType, MediaApi} from "../../api/MediaApi";
import {byName} from "../../utils/listUtil";
import {getErrorSpecificClientMessage, throwErrorOnNullOrUndefined} from "../../utils/errorUtil";
import {ApiError} from "../../model/ApiError.model";
import {setHeaderNotification} from "../base/header/HeaderActions";
import {NotificationTypes} from "../base/header/HeaderReducer";
import {useDispatch} from "react-redux";
import {scrollToElement} from "../../utils/browserUtil";
import {COUNTRY_SELECT_VALUES} from "../admin/AdminAllClients";
import {LANGUAGE_SELECT_VALUES} from "../admin/AdminCreatePlan.component";
import {getQuarters, Quarter} from "../../utils/dateUtil";

export interface IMediaFileUploadProps {
    hide: () => void;
    onUpload: () => void;
    isPlanSpecific: boolean
}

export const INVALID_FILE_ERROR = "Please select a valid file type of .png or .mp4";

export const getYears = (): ISelectValue[] => {
    const currentYear = new Date().getFullYear();
    return[
        {id: currentYear+1, name: `${currentYear+1}`},
        {id: currentYear, name: `${currentYear}`},
        {id: currentYear-1, name: `${currentYear-1}`},
        {id: currentYear-2, name: `${currentYear-2}`},
        {id: currentYear-3, name: `${currentYear-3}`}
    ];
};

export const MediaFileUpload
: React.FunctionComponent<IMediaFileUploadProps> = (props:IMediaFileUploadProps ) => {
    const allowedFileTypes = [".png", ".mp4"];
    const getMediaGroupsForSelect = (): ISelectValue[] => {
        return mediaGroups.map(item => {
            return {id: item.id!, name: item.name};
        });
    };

    const [selectedFile, setSelectedFile] = useState<File | undefined>(undefined);
    const [value, setValue] = useState("");
    const [selectedMediaGroup, setSelectedMediaGroup] = useState<IMediaGroup>();
    const [mediaGroups, setMediaGroups] = useState<IMediaGroup[]>([]);
    const [serviceTypes, setServiceTypes] = useState<IServiceType[]>([]);
    const [selectedServiceType, setSelectedServiceType]
        = useState<string | undefined>(undefined);
    const [selectedQuarter, setSelectedQuarter] = useState<Quarter>();
    const [selectedYear, setSelectedYear]
        = useState<string | undefined>(undefined);
    const [labelText, setLabelText] = useState<string>("");
    const [selectedRegion, setSelectedRegion]
        = useState<string | undefined>(undefined);
    const [selectedLanguage, setSelectedLanguage]
        = useState<string | undefined>(undefined);
    const [submitClicked, setSubmitClicked] = useState<boolean>(false);
    const dispatch = useDispatch();

    const fetchMediaGroups = () => {
        MediaApi.fetchMediaGroups().then((response) => {
            setMediaGroups(
                response
                    .filter(item => (props.isPlanSpecific && item.planSpecific)
                        || (!props.isPlanSpecific && !item.planSpecific))
                    .sort(byName)
            );
        });
    };

    const fetchServiceType = () => {
        MediaApi.fetchServiceType().then((response) => {
            setServiceTypes(
                response.sort(byName)
            );
        });
    };

    useEffect(() => {
        fetchMediaGroups();
        fetchServiceType();
    }, []);

    const handleFileSelection = (e: { target: { files: FileList | null, value: any } }) => {
        if (e.target.files === null) {
            return;
        }

        setSelectedFile(e.target.files[0]);
        setValue(e.target.value);
    };


    const renderMediaFileUploader = () => {
        return (
            <div className="media-file-add__file-selector" data-testid="media-file-add__file-selector">
                <input
                    id="media-file-add__file-input"
                    type="file"
                    onChange={handleFileSelection}
                    value={value}
                    style={ {width: "100%"} }
                    accept="image/png, video/mp4"
                    data-testid="media-file-add__file-input"
                />
                <label  htmlFor="media-file-add__file-input">Allowable files types: .png, .mp4 </label>
            </div>
        );
    };

    const renderMediaFileFilters = () => {
        return (<div className="media-file-filters">
            {renderMediaGroupDropdown()}
            {renderQuarterDropdown()}
            {renderYearDropdown()}
            {renderRegionDropdown()}
            {renderServiceTypeDropdown()}
            {renderLanguageDropdown()}
            {renderLabelText()}
        </div>);
    };

    const renderMediaFileActions = () => {
        return <div className="media-file-add__buttons submit_cancel_buttons">
                <RaisedButton
                    className="media-file-add__cancel-button"
                    style={secondaryButtonStyle}
                    primary={false}
                    onClick={() => props.hide()}
                >
                    Cancel
                </RaisedButton>
                <RaisedButton
                    className="media-file-add__save-button"
                    style={prominentButtonStyle}
                    primary={true}
                    onClick={() => handleFileUpload()}
                >
                    Submit
                </RaisedButton>
        </div>;
    };

    const frameFormData = (): FormData => {
        const formData = new FormData();
        formData.append("file", throwErrorOnNullOrUndefined(selectedFile));
        formData.append("mediaGroupId", selectedMediaGroup!.id!.toString());
        formData.append("mediaGroupName", selectedMediaGroup!.name);
        formData.append("quarter", selectedQuarter!.toString().substring(1));
        formData.append("year", selectedYear ? selectedYear : "");
        formData.append("label", labelText);
        if(!props.isPlanSpecific) {
            formData.append("regionId", selectedRegion ? selectedRegion : "");
            formData.append("serviceTypeId", selectedServiceType ? selectedServiceType : "");
            formData.append("languageId", selectedLanguage ? selectedLanguage : "");
        }
        return formData;
    };

    const handleFileUpload = () => {
        setSubmitClicked(true);
        const fileErrorMessage = validateFileSelection();
        if(fileErrorMessage){
            dispatch(setHeaderNotification(
                {message: fileErrorMessage, notificationType: NotificationTypes.FAILURE},
                5000));
            scrollToElement(".main__header", 0);
            return;
        }

        const processSuccess = (message: string) => {
            setSubmitClicked(false);
            dispatch(setHeaderNotification(
                {message: `The ${message} media file has been successfully uploaded.`,
                    notificationType: NotificationTypes.SUCCESS},
                5000));
            scrollToElement(".main__header", 0);
            props.hide();
            props.onUpload();
        };
        const processFailure = (errorNumber: number, errorMessage?: string) => {
            setSubmitClicked(false);
            dispatch(setHeaderNotification(
                {message: errorMessage ? errorMessage
                        : `${getErrorSpecificClientMessage(errorNumber)}`,
                    notificationType: NotificationTypes.FAILURE},
                5000));
            scrollToElement(".main__header", 0);
        };

        if(props.isPlanSpecific){
            if(validatePlanSpecificInputs()) {
                MediaApi.addPlanSpecificMediaFile(frameFormData())
                    .then((response) => response.data ? processSuccess("plan specific") : processFailure(-1))
                    .catch((error: ApiError) => processFailure(error.errorNumber, error.message));
            }
        } else {
            if(validateSharedInputs()) {
                MediaApi.addSharedMediaFile(frameFormData())
                    .then((response) => response.data ? processSuccess("shared") : processFailure(-1))
                    .catch((error: ApiError) => processFailure(error.errorNumber, error.message));
            }
        }
    };

    const renderMediaGroupDropdown = () => {
        return <SelectComponent
            id={"upload-file__media-group-name"}
            values={getMediaGroupsForSelect()}
            selected={selectedMediaGroup?.id}
            width={260}
            handleChange= {(e) => handleMediaGroupNameChange(e.target.value)}
            placeholder={"Media Group Name"}
            renderRequired={submitClicked}
            submitClicked={submitClicked}
        />;
    };

    const handleMediaGroupNameChange = (id: number) => {
        if (selectedMediaGroup) {
            setSelectedQuarter(undefined);
            setSelectedYear(undefined);
            setLabelText("");
            setSelectedRegion(undefined);
            setSelectedServiceType(undefined);
            setSelectedLanguage(undefined);
        }
        setSelectedMediaGroup(mediaGroups.find(p => p.id === id)!);
    };

    const renderQuarterDropdown = () => {
        return <SelectComponent
            id={"upload-file__quarter"}
            values={getQuarters()}
            selected={selectedQuarter}
            width={80}
            handleChange={(e) => setSelectedQuarter(e.target.value)}
            placeholder={"Quarter"}
            renderRequired={submitClicked}
            submitClicked={submitClicked}
        />;
    };

    const renderYearDropdown = () => {
        return <SelectComponent
            id={"upload-file__year"}
            values={getYears()}
            selected={selectedYear}
            width={75}
            handleChange= {(e) => setSelectedYear(e.target.value)}
            placeholder={"Year"}
            renderRequired={submitClicked}
            submitClicked={submitClicked}
        />;
    };

    const renderRegionDropdown = () => {
        return selectedMediaGroup?.regionSpecific
            ? <SelectComponent
                id={"upload-file__region"}
                values={COUNTRY_SELECT_VALUES}
                selected={selectedRegion}
                width={80}
                handleChange= {(e) => setSelectedRegion(e.target.value)}
                placeholder={"Region"}
                renderRequired={submitClicked}
                submitClicked={submitClicked}
            />
            : null;
    };

    const renderServiceTypeDropdown = () => {
        return selectedMediaGroup?.serviceTypeSpecific
            ? <SelectComponent
                id={"upload-file__service_type"}
                values={serviceTypes.map(item =>  { return {id: item.id!, name: item.name}; })}
                selected={selectedServiceType}
                width={180}
                handleChange= {(e) => setSelectedServiceType(e.target.value)}
                placeholder={"Service Type"}
                renderRequired={submitClicked}
                submitClicked={submitClicked}
            />
            : null;
    };

    const renderLanguageDropdown = () => {
        return selectedMediaGroup?.languageSpecific
            ? <SelectComponent
                id={"upload-file__language"}
                values={LANGUAGE_SELECT_VALUES}
                selected={selectedLanguage}
                width={95}
                handleChange= {(e) => setSelectedLanguage(e.target.value)}
                placeholder={"Language"}
                renderRequired={submitClicked}
                submitClicked={submitClicked}
            />
            : null;
    };

    const onChangeLabelTextValue = (labelText: string) => {
        if (labelText.length > 25)
            return;
        setLabelText(labelText);
    };

    const placeholderStyle = {
        fontSize: "14px !important",
        fontStyle: "italic !important"
    };

    const renderLabelText = () => {
        return <div className="media-file-add__label-text-container">
            <div className="media-file-add__label-text">
                <div className="media-file-add__filter-label-text">
                    <label htmlFor="labelText" className="media-file-add__display-label-text">
                        Label Text:
                    </label>
                </div>
                <TextFieldComponent
                    id="upload-file__labelText"
                    name="labelText"
                    placeholder="25 Character Limit"
                    className="title-input"
                    type="text"
                    fullWidth={true}
                    value={labelText}
                    handleChange={(e) => onChangeLabelTextValue(e.target.value)}
                    renderRequired={submitClicked}
                    submitClicked={submitClicked}
                    placeholderStyle={placeholderStyle}
                        />
                    </div>
                </div>;
    };

    const validateFileSelection = (): string | undefined => {
        if(selectedFile === undefined ||
            !allowedFileTypes.includes(
                selectedFile.name.substring(selectedFile.name.lastIndexOf("."),
                    selectedFile.name.length).toLowerCase())) {
            return INVALID_FILE_ERROR;
        }
        return;
    };

    const validatePlanSpecificInputs = (): boolean => {
        return !(!selectedMediaGroup || !selectedQuarter || selectedYear === "" || !selectedYear || labelText === "");
    };

    const validateSharedInputs = (): boolean => {
        if ((!selectedMediaGroup || !selectedQuarter || selectedYear === "" || !selectedYear || labelText === "")) {
            return false;
        }
        if (selectedMediaGroup?.regionSpecific && !selectedRegion) {
            return false;
        }
        if (selectedMediaGroup.serviceTypeSpecific && !selectedServiceType) {
            return false;
        }
        return !(selectedMediaGroup?.languageSpecific && !selectedLanguage);

    };

    return <div className="media-file-add__container" data-testid="media-file-add__container" >
            {renderMediaFileUploader()}
            {renderMediaFileFilters()}
            {renderMediaFileActions()}
        </div>;
};

