import {OrderedMap} from "immutable";
import * as React from "react";
import {capitalizeFirstLetter} from "../../../utils/commonUtil";
import {formatCurrency} from "../../../utils/numberUtil";
import {ICashflow} from "./CashFlowsUtils";

export interface ICashFlowsByAssetClassDataTableProps {
    cashflows: OrderedMap<string, ICashflow>;
    isProduct: boolean;
}

export const CashFlowsByKeyDataTable: React.FunctionComponent<ICashFlowsByAssetClassDataTableProps>
    = (props) => {
    const renderCashflow = (cashflow: ICashflow, key: string) => {
        return <tr key={key} className="small">
            <td>{capitalizeFirstLetter(key)}</td>
            <td>{formatCurrency(cashflow.cashflow)}</td>
            <td>{formatCurrency(cashflow.inflow)}</td>
            <td>{formatCurrency(cashflow.outflow)}</td>
        </tr>;
    };

    const renderAssetClassRows = () => {
        return props.cashflows.map((cashflow: ICashflow, key: string) =>
            renderCashflow(cashflow, key)).valueSeq().toArray();
    };

    return <div className="cash-flows__data-table-asset-class-container"
                data-testid="cash-flows__data-table-asset-class-container">
        <table>
            <thead>
            <tr className="small bold">
                <th>{props.isProduct ? "Product Name" : "Asset Class"}</th>
                <th>Net Cashflow</th>
                <th>Inflow</th>
                <th>Outflow</th>
            </tr>
            </thead>
            <tbody>{renderAssetClassRows()}</tbody>
        </table>
    </div>;
};
