import * as React from "react";
import {FactSheet, FsAssetClass, FsCurrency, FsFrequency} from "../../model/FactSheet.model";
import {ISelectValue} from "../common/Select.component";
import {FiltersComponent} from "../common/Filters.component";
import {getQuartersSelectValues} from "../../utils/dateUtil";
import {ALL_YEARS_ID, IPerformanceDocsFiltersProps} from "./PerformanceReportingTab";

export interface IFactSheetsFiltersFromParent {
    factSheets: FactSheet[];
    selectedAssetClass: FsAssetClassKey;
    selectedCurrency: FsCurrencyKey;
    selectedFrequency: FsFrequencyKey;
}

export type FactSheetsFiltersProps = IFactSheetsFiltersFromParent & IPerformanceDocsFiltersProps;
export type FsAssetClassKey = keyof typeof FsAssetClass;
export type FsCurrencyKey = keyof typeof FsCurrency;
export type FsFrequencyKey = keyof typeof FsFrequency;
export const ALL_ASSET_CLASSES_KEY: FsAssetClassKey = "ALL";
export const ALL_CURRENCIES_KEY: FsCurrencyKey = "ALL";
export const ALL_FREQUENCIES_KEY: FsFrequencyKey = "ALL";

export const FactSheetsFilters: React.FunctionComponent<FactSheetsFiltersProps> = (props) => {
    const getAssetClasses = (): ISelectValue[] => {
        const assetClasses = props.factSheets
            .map((it) => it.assetClass.toString());

        const allOptions = Object.keys(FsAssetClass)
            .map((key: keyof typeof FsAssetClass) => ({
                id: key,
                name: FsAssetClass[key],
            }));

        return allOptions
            .filter((it) => assetClasses.includes(it.name) || it.name === FsAssetClass.ALL);
    };

    const getCurrencies = (): ISelectValue[] => {
        const currencies = props.factSheets
            .map((it) => it.currency.toString());

        const allOptions = Object.keys(FsCurrency)
            .map((key: keyof typeof FsCurrency) => ({
                id: key,
                name: FsCurrency[key],
            }));

        return allOptions
            .filter((it) => currencies.includes(it.name) || it.name === FsCurrency.ALL);
    };

    const getFrequencies = (): ISelectValue[] => {
        const frequencies = props.factSheets
            .map((it) => it.frequency.toString());

        const allOptions = Object.keys(FsFrequency)
            .map((key: keyof typeof FsFrequency) => ({
                id: key,
                name: FsFrequency[key],
            }));

        return allOptions
            .filter((it) => frequencies.includes(it.name) || it.name === FsFrequency.ALL);
    };

    const getYears = (): ISelectValue[] => {
        const years = props.factSheets
            .map((it) => it.quarterAndYear.year)
            .filter((elem, index, self) => index === self.indexOf(elem))
            .sort((item1, item2) => item2 - item1)
            .map((year) => ({id: year, name: year.toString()}));

        return [{id: ALL_YEARS_ID, name: "All Years"}, ...years];
    };

    return <div>
        <FiltersComponent
            filters={[
                    {
                        values: getYears(),
                        selectedValue: props.selectedYear,
                        code: "by_year",
                        width: 125,
                    },
                    {
                        values: getQuartersSelectValues(),
                        selectedValue: props.selectedQuarter,
                        code: "by_quarter",
                        width: 150,
                    },
                    {
                        values: getFrequencies(),
                        selectedValue: props.selectedFrequency,
                        code: "by_frequency",
                        width: 200,
                    },
                    {
                        values: getCurrencies(),
                        selectedValue: props.selectedCurrency,
                        code: "by_currency",
                        width: 200,
                    },
                    {
                        values: getAssetClasses(),
                        selectedValue: props.selectedAssetClass,
                        code: "by_asset_class",
                        width: 200,
                    },
                ]}
            onFilterChangeHandler={props.handleFilterChange}
            onClearFilters={props.resetFilters}
        />
    </div>;
};