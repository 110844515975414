import * as React from "react";
import {useEffect, useRef, useState} from "react";
import {Map as ImmutableMap} from "immutable";
import {
    IFieldData,
    IHorizontalBarChartVariables,
    IKeyedResponseSummary,
    IMargins,
    IStack,
    IStackData,
    RESPONSE_SUMMARY_KEYS
} from "../../OddIqChartingUtils";
import * as d3 from "d3";
import {Axis, ScaleBand, ScaleLinear, ScaleOrdinal, Series, Stack} from "d3";
import {generateColorScale} from "../../../../utils/d3Util";
import {ODD_CHART_SUMMARY_COLOR_SCALE} from "../../../../../../css/Colors";
import {IOddQuestionSummary, IOddResponse, OddResponseName} from "../../../../api/OddIqApi";
import {scrollToElement} from "../../../../utils/browserUtil";
import {BackstopStrategiesWithExceptions} from "./BackstopStrategiesWithExceptions";
import {ProductSummary} from "../../../../model/product/ProductSummary";
import {OperationalDueDiligenceDocument} from "../../../../model/OperationalDueDiligenceDocument.model";
import OddStrategiesWithExceptions from "../../odd-page-no-backstop/OddStrategiesWithExceptions";

export interface IOddHorizontalBarChartProps {
    sortedMap: ImmutableMap<number, IStackData>;
    portfolioProductSummaries: ImmutableMap<number, ProductSummary>;
    oddExceptionResponses: IOddResponse[];
    currentSectionId: number | undefined;
    setCurrentSectionId: (sectionId: number | undefined) => void;
    currentQuestionId: number | undefined;
    setCurrentQuestionId: (questionId: number | undefined) => void;
    oddDocuments: OperationalDueDiligenceDocument[];
    byBackstop: boolean;
    oddQuestionSummaries?: IOddQuestionSummary[];
}

export interface IStackedBarProps {
    hbr: IHorizontalBarChartVariables;
    fieldData: IFieldData;
    chartProps: IOddHorizontalBarChartProps;
    index: number;
}

const margin: IMargins = {top: 50, right: 40, bottom: 60, left: 0};

export const OddHorizontalBarChart: React.FunctionComponent<IOddHorizontalBarChartProps> = (props) => {
    const fieldData: IFieldData[] = props.sortedMap.map((value, key) => {
        const field: IFieldData = {name: value!.name!, id: key!};
        return field;
    }).valueSeq().toArray();

    const [screenWidth, setScreenWidth] = useState(window.innerWidth);

    const chartWidth = stackedBarWidth(screenWidth);

    const chartHeight = fieldData.length * 100 - margin.top - margin.bottom;

    const colorFn: ScaleOrdinal<string, any> = generateColorScale(RESPONSE_SUMMARY_KEYS, ODD_CHART_SUMMARY_COLOR_SCALE);
    const stackFn: Stack<any, IStack, string> = generateStack(RESPONSE_SUMMARY_KEYS);

    const yScale0Fn: ScaleBand<string> = generateYScale0(fieldData, chartHeight)
        .paddingOuter(0);

    const yAxis0Fn: Axis<string> = generateYAxis0(yScale0Fn);

    const xScaleFn = generateXScale(chartWidth);

    useEffect(() => {
        window.addEventListener("resize", (event: Event) => {
                const window = event.target! as Window;
                setScreenWidth(window.innerWidth);
                return null;
            },
        );
    }, []);

    const chartVariables: IHorizontalBarChartVariables = {
        colorFn,
        stackFn,
        xScaleFn,
        yScale0Fn,
        yAxis0Fn,
        chartHeight,
        chartWidth,
    };

    function renderBars() {
        return fieldData.reverse().map((field, index) =>
            <HorizontalStackedBar
                hbr={chartVariables}
                chartProps={props}
                fieldData={field}
                key={field.name}
                index={index}
            />,
        );
    }

    return <div id="__chart" data-testid="odd-horizontal-bar-chart">
        <div id="__chart-container">
            {renderBars()}
        </div>
    </div>;
};

export const HorizontalStackedBar: React.FunctionComponent<IStackedBarProps> = (props) => {
    const barRef = useRef(null);
    const yOffset = 25;

    useEffect(() => {
        d3.select(barRef.current)
            .attr("transform", () => `translate(${-props.hbr.chartWidth!}, ${yOffset})`)
            .transition()
            .duration(1400)
            .attr("transform", () => `translate(0, ${yOffset})`)
            .delay(() => props.index * 80);
    }, [props]);

    const xScaleFn = generateXScale(props.hbr.chartWidth!);

    const keyedPercents = props.chartProps.sortedMap.get(props.fieldData.id)!.percents;
    const stackedData = props.hbr.stackFn([keyedPercents]);

    const handleDrillDown = () => {
        if (!props.chartProps.currentSectionId) {
            props.chartProps.setCurrentSectionId(props.fieldData.id);

            scrollToElement("#__chart", 80);
        } else if (!props.chartProps.currentQuestionId || props.fieldData.id !== props.chartProps.currentQuestionId) {
            props.chartProps.setCurrentQuestionId(props.fieldData.id);
        } else {
            props.chartProps.setCurrentQuestionId(undefined);
        }
    };

    const renderStrategiesWithExceptionsTable = () => {
        if (!props.chartProps.currentSectionId
            || !props.chartProps.currentQuestionId
            || props.fieldData.id !== props.chartProps.currentQuestionId) {
                return <div id="__strategies-outer-container" className="hide"/>;
        }

        return props.chartProps.byBackstop
            ? <div id="__strategies-outer-container" className="show">
                <BackstopStrategiesWithExceptions
                        portfolioProductSummaries={props.chartProps.portfolioProductSummaries}
                        oddExceptionResponses={props.chartProps.oddExceptionResponses}
                        currentQuestionId={props.chartProps.currentQuestionId}
                        oddDocuments={props.chartProps.oddDocuments}
                />
            </div>
            : <div id="__strategies-outer-container" className="show">
                <OddStrategiesWithExceptions
                    oddExceptionResponses={props.chartProps.oddExceptionResponses}
                    currentQuestionId={props.chartProps.currentQuestionId}
                    oddQuestionSummaries={props.chartProps.oddQuestionSummaries}
                />
            </div>;
    };

    const renderBarsWithPercents = (seriesData: Series<IStack, string>, idx: number) => {
        const data = seriesData[0];

        const x = xScaleFn(data[0])!;
        const width = xScaleFn(data[1])! - x!;
        const offset = [0, width / 2, width];

        const keyedPercent = keyedPercents[seriesData.key];
        const percent = keyedPercent > 0 ? keyedPercent + "%" : null;
        const anchor = idx === 0 ? "start" : "end";
        const color = props.hbr.colorFn(seriesData.key).toString();

        const renderPercents = () => {
            if (!percent) {
                return null;
            }

            return <text y={38 + yOffset}
                         x={x + offset[idx]}
                         textAnchor={anchor}
                         className="__summary-percent extra-small bold"
                         fill={color}>
                {percent}
            </text>;
        };

        const renderRects = () => {
            return <rect
                x={x}
                y={yOffset}
                height={16}
                width={width}
                fill={color}
                className="__bar-rect clickable"
                onClick={handleDrillDown}
            />;
        };

        return <g key={idx}>
            {renderRects()}
            {renderPercents()}
        </g>;
    };

    const sum = sumResponses(props.chartProps.sortedMap.get(props.fieldData.id)!.counts);

    return sum > 0
        ? <div className="__field-container">
            <svg className="__field" ref={barRef} width={props.hbr.chartWidth} height={70}>
                <text y={yOffset - 10} className="__bar-section-title body-16 clickable hoverable" onClick={handleDrillDown}>
                    {props.fieldData.name}
                </text>
                <text x={xScaleFn(100)} y={yOffset - 10} textAnchor="end" className="body-14">{sum} questions</text>
                {stackedData.map(renderBarsWithPercents)}
            </svg>
            {renderStrategiesWithExceptionsTable()}
        </div>
        : null;
};

function sumResponses(stackData: IKeyedResponseSummary) {
    return stackData[OddResponseName.EXCEPTIONS]
        + stackData[OddResponseName.BEST_PRACTICE]
        + stackData[OddResponseName.NO_DATA];
}

function generateXScale(width: number): ScaleLinear<number, number> {
    return d3.scaleLinear().rangeRound([0, width]).domain([0, 100]).nice();
}

function generateYScale0(data: IFieldData[], chartHeight: number) {
    return d3.scaleBand().rangeRound([chartHeight, 0]).padding(0.6)
        .domain(
            data.map((d: IFieldData) => {
                return d.name;
            }),
        );
}

function generateStack(keyList: string[]) {
    return d3.stack()
        .keys(keyList)
        .offset(d3.stackOffsetNone);
}

function generateYAxis0(yScale0: ScaleBand<string>) {
    return d3.axisRight(yScale0)
        .tickSizeOuter(0)
        .tickSizeInner(0);
}

function stackedBarWidth(screenWidth: number) {
    const padding = 63 * 2;
    const offset = 24;
    const allowedWidth = screenWidth > 1600
        ? 1600
        : screenWidth < 1200 ? 1200 - (offset * 2) : screenWidth - offset;
    return allowedWidth - padding;
}