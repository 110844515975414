import {AxiosError, AxiosResponse} from "axios";
import {ApiError} from "../model/ApiError.model";
import {logApiError} from "./apiLog";
import {AxiosConnectionFactory} from "./AxiosConnectionFactory";
import {IApiResult} from "./AxiosWealthManagementApi";

export class ApiBase {
    public static processGet<TResult>(
        url: string,
        reader: (axiosResponse: AxiosResponse) => TResult,
        config: any = {},
    ): Promise<IApiResult<TResult>> {
        return AxiosConnectionFactory.getAxiosInstance()
            .get(url, config)
            .then<IApiResult<TResult>>((result: AxiosResponse) => ({data: reader(result)}))
            .catch((error) => ApiBase.processRejected(error, "GET", url));
    }

    public static processGetUnwrapped<TResult>(
        url: string,
        reader: (axiosResponse: AxiosResponse) => TResult,
        config: any = {},
    ): Promise<TResult> {
        return AxiosConnectionFactory.getAxiosInstance()
            .get(url, config)
            .then<TResult>((result: AxiosResponse) => (reader(result)))
            .catch((error) => ApiBase.processRejected(error, "GET", url));
    }

    public static processGetWithoutResponseBody(
        url: string,
        config: any = {},
    ): Promise<void> {
        return AxiosConnectionFactory.getAxiosInstance()
            .get(url, config)
            .then(() => (Promise.resolve()))
            .catch((error) => ApiBase.processRejected(error, "GET", url));
    }

    public static processPost<TData>(url: string, data: TData): Promise<IApiResult<boolean>> {
        return AxiosConnectionFactory.getAxiosInstance()
            .post(url, data)
            .then<IApiResult<boolean>>(
                () => ({data: true}),
            )
            .catch((error) => {
                return ApiBase.processRejected(error, "POST", url);
            });
    }

    public static processPostWithResponseBody<TData, TResult>(
        url: string,
        data: TData,
        reader: (axiosResponse: AxiosResponse) => TResult,
    ): Promise<IApiResult<TResult>> {
        return AxiosConnectionFactory.getAxiosInstance()
            .post(url, data)
            .then<IApiResult<TResult>>((result: AxiosResponse) => ({data: reader(result)}))
            .catch((error) => ApiBase.processRejectedWithResponseBody(error, "POST", url));
    }

    public static processPostWithResponseBodyUnwrapped<TData, TResult>(
        url: string,
        data: TData,
        reader: (axiosResponse: AxiosResponse) => TResult,
    ): Promise<TResult> {
        return AxiosConnectionFactory.getAxiosInstance()
            .post(url, data)
            .then<TResult>((result: AxiosResponse) => (reader(result)))
            .catch((error) => ApiBase.processRejectedWithResponseBody(error, "POST", url));
    }

    public static processPostWithResponseBodyAndConfig<TData, TResult>(
        url: string,
        data: TData,
        reader: (axiosResponse: AxiosResponse) => TResult,
        config: any,
    ): Promise<IApiResult<TResult>> {
        return AxiosConnectionFactory.getAxiosInstance()
            .post(url, data, config)
            .then<IApiResult<TResult>>((result: AxiosResponse) => ({data: reader(result)}))
            .catch((error) => ApiBase.processRejectedWithResponseBody(error, "POST", url));
    }

    public static processDelete<TData>(url: string): Promise<IApiResult<boolean>> {
        return AxiosConnectionFactory.getAxiosInstance()
            .delete(url)
            .then<IApiResult<boolean>>(() => ({data: true}))
            .catch((error) => ApiBase.processRejected(error, "DELETE", url));
    }

    public static processPut<TData>(url: string, data?: TData): Promise<IApiResult<boolean>> {
        return AxiosConnectionFactory.getAxiosInstance()
            .put(url, data)
            .then<IApiResult<boolean>>(() => ({data: true}))
            .catch((error) => ApiBase.processRejected(error, "PUT", url));
    }

    public static processPatch<TData>(url: string, data: TData): Promise<IApiResult<boolean>> {
        return AxiosConnectionFactory.getAxiosInstance()
            .patch(url, data)
            .then<IApiResult<boolean>>(() => ({data: true}))
            .catch((error) => ApiBase.processRejected(error, "PATCH", url));
    }

    public static processPatchWithResponseBodyUnwrapped<TData, TResult>(
        url: string,
        data: TData,
        reader: (axiosResponse: AxiosResponse) => TResult,
    ): Promise<TResult> {
        return AxiosConnectionFactory.getAxiosInstance()
            .patch(url, data)
            .then<TResult>((result: AxiosResponse) => (reader(result)))
            .catch((error) => ApiBase.processRejected(error, "PATCH", url));
    }


    public static processGetWithToken<TResult>(
        url: string,
        reader: (axiosResponse: AxiosResponse<TResult>) => TResult,
        token: string,
    ): Promise<IApiResult<TResult>> {
        return AxiosConnectionFactory.getAxiosInstanceWithoutSessionData(token)
            .get(url)
            .then<IApiResult<TResult>>((axiosResponse: AxiosResponse<TResult>) => {
                return {data: reader(axiosResponse)};
            })
            .catch((error) => {
                return ApiBase.processRejected(error, "GET", url);
            });
    }

    private static processRejected(error: Error | AxiosError, method: string,  url: string) {
        logApiError(method, url, error);
        if ("response" in error) {
            return Promise.reject(ApiError.fromAxios(error as any));
        } else {
            return Promise.reject(error.message);
        }
    }

    private static processRejectedWithResponseBody(error: Error | AxiosError, method: string, url: string) {
        logApiError(method, url, error);

        if ("response" in error) {
            return Promise.reject(ApiError.fromAxiosWithBody(error));
        } else {
            return Promise.reject(error.message);
        }
    }
}
