import {FormControlLabel, Switch} from "@mui/material";
import {SwitchProps} from "@mui/material/Switch";
import * as React from "react";
import {AonColors} from "../../../../../css/Colors";

export interface IStyledToggle {
    toggleState: boolean;
    setToggle: (a: boolean) => void;
    enabledColor?: string;
    label: string;
    labelPlacement?: "end" | "start" | "top" | "bottom";
}

type styledProps = IStyledToggle & Partial<SwitchProps>;

export const StyledToggle = (props: styledProps) => {
    /* eslint-disable @typescript-eslint/no-unused-vars */
    const {
        ["toggleState"]: notNeeded,
        ["setToggle"]: notNeeded1,
        ["label"]: notNeeded2,
        ["enabledColor"]: notNeeded3,
        ["labelPlacement"]: notNeeded4,
        ...switchProps
    } = props;
    /* eslint-enable @typescript-eslint/no-unused-vars */

    const switchColor = props.enabledColor ? props.enabledColor : AonColors.AonTealDark;

    const switchStyle = [{
        "& .MuiSwitch-switchBase.Mui-checked": {
            color: switchColor,
        },
        "& .MuiSwitch-switchBase.Mui-checked+.MuiSwitch-track": {
            backgroundColor: switchColor,
        },
    }];

    return <FormControlLabel
        control={
            <Switch
                checked={props.toggleState}
                onChange={(e) => props.setToggle(e.target.checked)}
                {...switchProps}
                sx={switchStyle}
                data-testid={props.label}
            />
        }
        label={props.label}
        labelPlacement={props.labelPlacement ? props.labelPlacement : "end"}
    />;
};
