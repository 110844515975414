import * as React from "react";
import {CalendarApi, ICalendarMeeting, ICalendarQuarter, ICalendarTopic} from "../../api/CalendarApi";
import {Draggable, DraggableProvided, Droppable} from "react-beautiful-dnd";
import {CALENDAR_VIEW} from "./CalendarPage";
import {DialogComponent} from "../common/Dialog.component";
import {getTranslation} from "../../utils/translationUtil";
import {useEffect, useState} from "react";
import {showCalendarMeetingEnabled} from "../../utils/envUtil";
import {formatShortDate} from "../../utils/dateUtil";
import {isClientUser} from "../../utils/sessionUtil";

export interface ICalendarQuarterProps {
    calendarMeeting?: ICalendarMeeting;
    quarter: ICalendarQuarter;
    handleDelete?: (quarter: ICalendarQuarter, topic: ICalendarTopic) => void;
    onViewChange: (meetingOrTopicId: number | undefined, view: CALENDAR_VIEW) => void;
}

export const CalendarQuarter: React.FunctionComponent<ICalendarQuarterProps> = (props) => {
    const [topicToDelete, setTopicToDelete] = useState<ICalendarTopic | undefined>(undefined);
    const [meetingDate, setMeetingDate] = useState<string | undefined>("");
    const deleteTopic = () => {
        props.handleDelete!(props.quarter, topicToDelete!);
        setTopicToDelete(undefined);
    };

    useEffect(() => {
        if (showCalendarMeetingEnabled() && props.calendarMeeting) {
            CalendarApi.requestCalendarMeetingDetails(props.calendarMeeting.id)
                .then((response) => {
                    setMeetingDate(response.dateTimeString ? formatShortDate(new Date(response.dateTimeString)) : "");
                });
        }
    }, []);

    const renderDeleteTopicDialog = () => {
        return <DialogComponent
            title="Delete this topic from the client's calendar?"
            open={topicToDelete !== undefined}
            description="All content attached to the topic will be permanently deleted."
            noText={getTranslation("shared-docs.no-cancel", "No, cancel")}
            yesText={getTranslation("shared-docs.yes-delete", "Yes, delete")}
            onNo={() => setTopicToDelete(undefined)}
            onYes={deleteTopic}
        />;
    };

    const renderTopic = (calendarTopic: ICalendarTopic, index: number) => {
        return isClientUser()
            ? <div className="client-calendar__quarter-topic clickable" key={calendarTopic.id}>
                <span
                    className="client-calendar__quarter-topic-name clickable" key={calendarTopic.id}
                    onClick={() => props.onViewChange(calendarTopic.id, CALENDAR_VIEW.TOPIC)}>
                    {calendarTopic.name}
                </span>
              </div>
            : <Draggable draggableId={`${props.quarter.key}-${index}`} index={index} key={index}>
            {(dProv: DraggableProvided) => (
                <div className="client-calendar__quarter-topic" key={calendarTopic.id}
                     ref={dProv.innerRef}
                     {...dProv.draggableProps}
                     {...dProv.dragHandleProps}>
                    <span className="client-calendar__quarter-topic-name clickable"
                          onClick={() => props.onViewChange(calendarTopic.id, CALENDAR_VIEW.TOPIC)}
                    >{calendarTopic.name}</span>
                    <span className="client-calendar__quarter-delete-topic"
                          onClick={() => setTopicToDelete(calendarTopic)}>
                        x
                    </span>
                </div>
            )}
        </Draggable>;
    };

    const getSortedTopics = (): ICalendarTopic[] => {
        return props.calendarMeeting
            ? props.calendarMeeting.topics
                .sort((a, b) => a.order - b.order)
            : [];
    };

    const renderQuarterHeader = () => {
        return showCalendarMeetingEnabled() && props.calendarMeeting
            ? <span className="client-calendar__quarter-header clickable"
                    onClick={() => props.onViewChange(props.calendarMeeting!.id, CALENDAR_VIEW.MEETING)}>
                {`${props.quarter.name} Meeting  ${meetingDate}`}
              </span>
            : <span className="client-calendar__quarter-header">{props.quarter.name}</span>;
    };

    return isClientUser()
        ? <div key={`${props.quarter.key}-quarter`} className="client-calendar__quarter">
            {renderQuarterHeader()}
            <div className="client-calendar__quarter-topics">
                {getSortedTopics().map(renderTopic)}
            </div>
          </div>
        : <div key={`${props.quarter.key}-quarter`} className="client-calendar__quarter">
            {renderDeleteTopicDialog()}
            {renderQuarterHeader()}
            <Droppable key={props.quarter.key} droppableId={props.quarter.key}>
                {(provided) => (
                    <div
                        className="client-calendar__quarter-topics"
                        key={`${props.quarter.key}-droppable`}
                        ref={provided.innerRef}
                        {...provided.droppableProps}
                    >
                        {getSortedTopics().map(renderTopic)}
                        {provided.placeholder}
                    </div>
                )}
            </Droppable>
          </div>;
};
