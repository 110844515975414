import {AxiosResponse} from "axios";
import {OperationalDueDiligenceDocument} from "../model/OperationalDueDiligenceDocument.model";
import {parseDate} from "../utils/dateUtil";
import {getConfig} from "../utils/envUtil";
import {getFileExtension, renameWordWithPdf} from "../utils/fileUtil";
import {ApiBase} from "./ApiBase";
import {IApiResult} from "./AxiosWealthManagementApi";
import {OddReport} from "../model/OddReport.model";

class OperationalDueDiligenceApiImpl {
    public requestAllOddReports(): Promise<OddReport[]> {
        return ApiBase.processGetUnwrapped(
            "/odd-reports/all",
            (response: AxiosResponse): any => {
                    return (response.data).map((report: any) =>
                    new OddReport(
                            report.backstopId,
                            report.title,
                            report.managers,
                            report.products,
                            report.fileName,
                            parseDate(report.effectiveDate),
                        ));
            });
    }

    public requestAllOddDocumentsAndReportsForProduct(productBackstopId: number):
        Promise<IApiResult<OperationalDueDiligenceDocument[]>> {
        return ApiBase.processGet(`/products/${productBackstopId}/odd-documents-and-reports`,
            (response: AxiosResponse) => response.data.map(this.createDocument),
        );
    }

    public requestAllOddDocuments(): Promise<OperationalDueDiligenceDocument[]> {
        return ApiBase.processGetUnwrapped(
            "/odd-documents/all",
            (response: AxiosResponse): any => {
                return (response.data).map((report: any) =>
                    this.createDocument(report)
                    );
            });
    }

    public getOddDocumentDownload(productId: number, documentId: number, filename: string, isNewTab: boolean) {
        const extension = getFileExtension(filename);
        const newFileName = renameWordWithPdf(filename);
        const baseURL = getConfig().FRONTEND_CONFIG_BACKEND_URL;

        const url = `${baseURL}/download/${productId}/odd-documents/${documentId}/${extension}/${escape(newFileName)}`;

        if (isNewTab) {
            window.open(url, "_blank");
        } else {
            window.location.assign(url);
        }
    }

    private createDocument = (item: any): OperationalDueDiligenceDocument => {
        return new OperationalDueDiligenceDocument(
            item.backstopId,
            item.title,
            item.filename,
            parseDate(item.effectiveDate),
            item.products,
        );
    };

}

export const OperationalDueDiligenceApi = new OperationalDueDiligenceApiImpl();
