import * as React from "react";
import { RaisedButton } from "../common/RaisedButton";
import { prominentButtonStyle, secondaryButtonStyle} from "../common/buttonStyles";
import { RadioButtonsComponent } from "../common/RadioButtons.component";
import { TextFieldComponent } from "../common/TextField.component";
import { LoadingSpinner } from "../icons/LoadingSpinner.component";
import {IMediaGroup, MediaApi} from "../../api/MediaApi";
import {useEffect, useState} from "react";
import {setHeaderNotification} from "../base/header/HeaderActions";
import {NotificationTypes} from "../base/header/HeaderReducer";
import {useDispatch} from "react-redux";
import {ApiError} from "../../model/ApiError.model";
import {camelCase} from "lodash";
import {scrollToElement} from "../../utils/browserUtil";

export interface IMediaGroupAddComponentProps {
    onSave: () => void;
    mediaGroup?: IMediaGroup;
    hide: () => void;
}

export enum MediaGroupErrorMessages {
    INVALID_NAME = "Please enter unique media name",
    INVALID_PLAN_SPECIFIC = "Please fill plan specific",
    INVALID_SERVICE_TYPE_SPECIFIC = "Please fill service type specific",
    INVALID_REGION_SPECIFIC = "Please fill region specific",
    INVALID_LANGUAGE_SPECIFIC = "Please fill language specific"
}

export const FILE_TYPE_LABEL = "Allowable files types: .pdf";
export const INVALID_FILE_SELECTION_ERROR = "Please select .pdf file only";

export const MediaGroupAddComponent: React.FunctionComponent<IMediaGroupAddComponentProps> = (props: IMediaGroupAddComponentProps) => {

    const [mediaGroupName, setMediaGroupName] = useState<string>(props.mediaGroup ? props.mediaGroup.name : "");
    const [mediaGroupDescription, setMediaGroupDescription] =
        useState<string>(props.mediaGroup?.description ? props.mediaGroup.description : "");
    const [planSpecific, setPlanSpecific] = useState<boolean | undefined>(undefined);
    const [serviceTypeSpecific, setServiceTypeSpecific] = useState<boolean | undefined>(undefined);
    const [regionSpecific, setRegionSpecific] = useState<boolean | undefined>(undefined);
    const [languageSpecific, setLanguageSpecific] = useState<boolean | undefined>(undefined);
    const [saveInProgress, setSaveInProgress] = useState<boolean>(false);
    const [submitClicked, setSubmitClicked] = useState<boolean>(false);
    const [mediaGroupTemplateFile, setMediaGroupTemplateFile] = useState<File | undefined>(undefined);
    const [value, setValue] = useState("");
    const dispatch = useDispatch();

    useEffect(() => {
        setMediaGroupName(props.mediaGroup ? props.mediaGroup.name : "");
        setMediaGroupDescription(props.mediaGroup?.description ? props.mediaGroup.description : "");
    }, [props]);

    const renderNonPlanSpecificOptions = () => {
        return planSpecific === false
            ? <div className="media-group-add__plan-specific-dependent"
                   data-testid="media-group-add__plan-specific-dependent">
                <div className="media-group-add__service-type-specific"
                     data-testid="media-group-add__service-type-specific">
                    <div>Service Type Specific:</div>
                    <div>
                        {renderRadioButton(serviceTypeSpecific,
                            "service-type-specific",
                            setServiceTypeSpecific)
                        }
                    </div>
                </div>
                <div className="media-group-add__region-specific" data-testid="media-group-add__region-specific">
                    <div>Region Specific:</div>
                    <div>
                        {renderRadioButton(regionSpecific, "region-specific", setRegionSpecific)}
                    </div>
                </div>
                <div className="media-group-add__language-specific" data-testid="media-group-add__language-specific">
                    <div>Language Specific:</div>
                    <div>
                        {renderRadioButton(languageSpecific, "language-specific", setLanguageSpecific)}
                    </div>
                </div>
            </div>
            : null;
    };

    const handleFileSelection = (e: { target: { files: FileList | null, value: any } }) => {
        if (e.target.files === null) {
            return;
        }

        setMediaGroupTemplateFile(e.target.files[0]);
        setValue(e.target.value);
    };

    const renderTemplateUploader = () => {
        return (
            <div className="media-group-add__file-selector-container">
                <div className="media-group-add__file-selector">
                    <label className="media-group-add__file-input-left-label">Template for Export: </label>
                    <input
                        id="media-group-add__file-input"
                        type="file"
                        onChange={handleFileSelection}
                        value={value}
                        style={{width: "100%"}}
                        accept="application/pdf"
                        data-testid="media-group-add__file-input"
                    />
                </div>
                <label htmlFor="media-group-add__file-input">Optional</label>
                <label htmlFor="media-group-add__file-input"
                       className="media-group-add__file-input-label">
                    {FILE_TYPE_LABEL}
                </label>
            </div>
        );
    };

    const renderMediaGroupOptions = () => {
        return props.mediaGroup
            ? null
            : <div>
                <div className="media-group-add__plan-specific" data-testid="media-group-add__plan-specific">
                    <div>Plan Specific:</div>
                    <div>
                        {renderRadioButton(planSpecific, "plan-specific", onPlanSpecificChange)}
                    </div>
                </div>
                {renderNonPlanSpecificOptions()}
            </div>;
    };
    const renderContent = () => {
        return (
            <div className="media-group-add__container">
                <h2 className="media-group-add__header">
                    {props.mediaGroup ? "Edit Media Group" : "Add Media Group"}
                </h2>
                <div className="media-group-add__input-fields">
                    <div className="media-group-add__media-group-name" data-testid="media-group-add__media-group-name">
                        {renderMediaGroupTextField("name", mediaGroupName, setMediaGroupName, true)}
                    </div>
                    <div className="media-group-add__media-group-description"
                         data-testid="media-group-add__media-group-description">
                        {renderMediaGroupTextField(
                            "description",
                            mediaGroupDescription,
                            setMediaGroupDescription,
                            false)}
                    </div>
                    {renderTemplateUploader()}
                    {renderMediaGroupOptions()}
                </div>
                {renderButtons()}
            </div>
        );
    };
    
    const renderMediaGroupTextField = (name: string, value: string, setState: any, mandatory: boolean) => {
        const cameCasedName = camelCase(name);
        return <TextFieldComponent
            name={`media-group-${name}`}
            className={`media-group-add__media-group-${name}-field`}
            type="text"
            fullWidth={true}
            value={value}
            handleChange={(e: any) => setState(e.target.value)}
            placeholder={`Media Group ${cameCasedName}`}
            renderRequired={mandatory}
            submitClicked={submitClicked}
        />;
    };

    const onPlanSpecificChange = (value: boolean) => {
        setPlanSpecific(value);
        if(value){
            setServiceTypeSpecific(undefined);
            setRegionSpecific(undefined);
            setLanguageSpecific(undefined);
        }
    };

    const renderRadioButton = (currentValue: boolean | undefined, name: string, setState: any) => {
        return <RadioButtonsComponent
            id={`media-group-add__${name}-toggle`}
            currentValue={currentValue}
            onChange={(value) => setState(value)}
            classNamePrefix={`media-group-add__${name}`}
            name={name}
        />;
    };

    const renderButtons = () => {
        return <div>
            <div className="buttons-spacer-top"/>
            <div className="save-and-cancel-buttons">
                <div className="buttons-spacer-left"/>
                <RaisedButton className="media-group-add__cancel-button"
                              style={secondaryButtonStyle}
                              primary={false}
                              onClick={() => props.hide()}
                              >
                    Cancel
                </RaisedButton>
                <div className="buttons-spacer-between"/>
                <RaisedButton className="media-group-add__save-button"
                              style={prominentButtonStyle}
                              primary={true}
                              onClick={() => handleSave()}
                              >
                    Save
                </RaisedButton>
            </div>
            <div className="buttons-spacer-bottom"/>
        </div>;
    };

    const handleModify = () => {

        const formData = new FormData();
        formData.append("id", props.mediaGroup!.id!.toString());
        formData.append("name", mediaGroupName);
        formData.append("description", mediaGroupDescription);
        if (mediaGroupTemplateFile)
            formData.append("mediaGroupTemplateFile", mediaGroupTemplateFile);

        MediaApi.modifyMediaGroup(formData)
            .then(() => {
                dispatch(setHeaderNotification(
                    {
                        message: "Media Group is modified successfully",
                        notificationType: NotificationTypes.SUCCESS
                    },
                    5000));
                props.hide();
                props.onSave();
            })
            .catch((reason: ApiError) => {
                    dispatch(setHeaderNotification(
                        {
                            message: `Error: Failed to update media group ${mediaGroupName}. Reason: ${reason.message}`,
                            notificationType: NotificationTypes.FAILURE
                        },
                        5000));
                },
            );
    };

    const validateCreateOptions = (): string | undefined => {
        if (planSpecific === undefined) {
            return MediaGroupErrorMessages.INVALID_PLAN_SPECIFIC;

        }
        if (!planSpecific ) {
            if(serviceTypeSpecific === undefined) {
                return MediaGroupErrorMessages.INVALID_SERVICE_TYPE_SPECIFIC;
            }
            if(regionSpecific === undefined) {
                return MediaGroupErrorMessages.INVALID_REGION_SPECIFIC;
            }
            if(languageSpecific === undefined) {
                return MediaGroupErrorMessages.INVALID_LANGUAGE_SPECIFIC;
            }
        }
        return;
    };

    const handleCreate = () => {
        const errorMessage = validateCreateOptions();
        if (errorMessage) {
            setSubmitClicked(true);
            dispatch(setHeaderNotification(
                {message: errorMessage, notificationType: NotificationTypes.FAILURE},
                5000));
            return;
        }
        setSubmitClicked(false);

        const formData = new FormData();
        formData.append("name", mediaGroupName);
        formData.append("description", mediaGroupDescription);
        formData.append("planSpecific", `${planSpecific!}`);
        formData.append("serviceTypeSpecific", `${planSpecific ? false : serviceTypeSpecific!}`);
        formData.append("regionSpecific", `${planSpecific ? false : regionSpecific!}`);
        formData.append("languageSpecific", `${planSpecific ? false : languageSpecific!}`);
        if (mediaGroupTemplateFile) {
            formData.append("mediaGroupTemplateFile", mediaGroupTemplateFile);
        }

        MediaApi.addMediaGroup(formData)
            .then(() => {
                resetState();
                dispatch(setHeaderNotification(
                    {message: "New media group added successfully", notificationType: NotificationTypes.SUCCESS},
                    5000));
                props.hide();
                props.onSave();
            })
            .catch((reason: ApiError) => {
                dispatch(setHeaderNotification(
                    {
                        message: `Error: Failed to update media group ${mediaGroupName}. Reason: ${reason.message}`,
                        notificationType: NotificationTypes.FAILURE
                    },
                    5000));
                setSaveInProgress(false);
            });
    };

    const validateFileSelection = (): string | undefined => {
        if (mediaGroupTemplateFile) {
            const fileType = mediaGroupTemplateFile.name.substring(mediaGroupTemplateFile.name.lastIndexOf("."), mediaGroupTemplateFile.name.length).toLowerCase();

            if (fileType !== ".pdf") {
                return INVALID_FILE_SELECTION_ERROR;
            }
        }
        return;
    };

    const handleSave = () => {
        if (mediaGroupName.length === 0) {
            setSubmitClicked(true);
            dispatch(setHeaderNotification(
                {message: MediaGroupErrorMessages.INVALID_NAME, notificationType: NotificationTypes.FAILURE},
                5000));
            scrollToElement(".main__header", 0);
            return;
        }

        const fileErrorMessage = validateFileSelection();
        if (fileErrorMessage) {
            dispatch(setHeaderNotification(
                {message: fileErrorMessage, notificationType: NotificationTypes.FAILURE},
                5000));
            scrollToElement(".main__header", 0);
            return;
        }

        props.mediaGroup ? handleModify() : handleCreate();
        scrollToElement(".main__header", 0);
    };

    const resetState = () => {
        setMediaGroupName("");
        setMediaGroupDescription("");
        setMediaGroupTemplateFile(undefined);
        setValue("");
        setPlanSpecific(false);
        setServiceTypeSpecific(false);
        setRegionSpecific(false);
        setLanguageSpecific(false);
    };

    return  saveInProgress ? <LoadingSpinner/> : renderContent();
};