import {isNullOrUndefined} from "./commonUtil";
import {getTranslation} from "./translationUtil";

export const ERROR_NOT_ACCEPTABLE = 406;
export const ERROR_NOT_ALLOWED = 405;
export const ERROR_DUPLICATE_DATA= 503;
export const throwErrorOnNullOrUndefined = <T>(variable: T | undefined | null, name?: string): T => {
    if (isNullOrUndefined(variable)) {
        const message = `${name || "Variable"} is ${variable}`;

        // to avoid react swallowing errors, see https://github.com/facebook/react/issues/6895
        // eslint-disable-next-line
        console.error(message);

        const x = new Error(message);
        // eslint-disable-next-line
        console.error("=============> x.stack: ", x.stack);

        throw new Error(message);
    }
    return variable;
};

export function assertNever(x: never): never {
    throw new Error("Unexpected object: " + x);
}

export const getErrorSpecificClientMessage = (errorNumber: number): string => {
    let errorSpecificMessage = "";

    if (errorNumber === ERROR_NOT_ACCEPTABLE) {
        const uploadFailedMessage = ": File failed to upload in a timely manner, please try again";
        errorSpecificMessage = getTranslation("upload.upload-timeout", uploadFailedMessage);
    }
    if (errorNumber === ERROR_NOT_ALLOWED) {
        const fileTypeErrorMessage = ": This type of file is not permitted";
        errorSpecificMessage = getTranslation("upload.file-type-error", fileTypeErrorMessage);
    }
    if (errorNumber === ERROR_DUPLICATE_DATA) {
        const fileTypeErrorMessage = ": A file already exists with the combination of Media Group, Quarter, and Year";
        errorSpecificMessage = getTranslation("upload.file-type-error", fileTypeErrorMessage);
    }
    return errorSpecificMessage;
};
