import * as React from "react";
import {ReactNode} from "react";
import SelectCheckBoxesComponent from "../common/SelectCheckBoxesComponent";
import {ConsultantLevelList, doesUserHaveAccess} from "../../model/UserDetails.model";
import {ClientsAndPlans, IClientInfo, IPlanInfo} from "../../model/ClientsAndPlans.model";
import {getSuccessData, REQUEST_STATES, RequestState} from "../common/commonStates";
import {useDispatch, useSelector} from "react-redux";
import getRelatedPlansActions from "../client/Client.actions";
import {LoadingSpinner} from "../icons/LoadingSpinner.component";
import {getAllClientsAndPlansForAdmin} from "../admin/adminActions";
import {adminClientsInfoStateSelector, relatedPlans, sessionPlans} from "../../../mainReducerMapSelectors";
import {UserTypeEnum} from "../../model/UserInfo.model";
import {IApplicationRootState} from "../../../applicationState";

export interface IPlanSelectPropsFromParent {
    onSelectElementsCallBack: (arg: number[]) => void;
    selectedElements: number[];
    currentPlan: IPlanInfo;
    userType: UserTypeEnum;
    children?: ReactNode;
}

export interface IPlanSelectPropsFromStore {
    sessionPlans: IPlanInfo[];
    relatedPlans: RequestState<IPlanInfo[]>;
    adminClientsInfoState: RequestState<ClientsAndPlans>;
}

export type IPlanSelectProps = IPlanSelectPropsFromParent;

export const PlanSelect: React.FunctionComponent<IPlanSelectProps> = (props: IPlanSelectProps) => {
    const values: IPlanSelectPropsFromStore = useSelector((state: IApplicationRootState) => ({
        sessionPlans: sessionPlans(state),
        relatedPlans: relatedPlans(state),
        adminClientsInfoState: adminClientsInfoStateSelector(state),
    }));

    const plansToDisplay = getPlansToDisplay();

    const dispatch = useDispatch();

    if (doesUserHaveAccess(props.userType, ConsultantLevelList)
        && values.relatedPlans.kind === REQUEST_STATES.NOT_REQUESTED) {
        dispatch(getRelatedPlansActions.getRelatedPlans(props.currentPlan.id));
    }

    if (!doesUserHaveAccess(props.userType, ConsultantLevelList)
        && values.adminClientsInfoState.kind === REQUEST_STATES.NOT_REQUESTED) {
        dispatch(getAllClientsAndPlansForAdmin());
    }

    if (doesUserHaveAccess(props.userType, ConsultantLevelList)
        &&  values.relatedPlans.kind === REQUEST_STATES.REQUESTED) {
        return <LoadingSpinner/>;
    }

    if (!doesUserHaveAccess(props.userType, ConsultantLevelList)
        &&  values.adminClientsInfoState.kind === REQUEST_STATES.REQUESTED) {
        return <LoadingSpinner/>;
    }

    if (!plansToDisplay || plansToDisplay.length <= 1) {
        return null;
    }

    return <div data-testid="plan-select">
        {props.children}
        <SelectCheckBoxesComponent
            elements={plansToDisplay}
            onSelectElementsChange={props.onSelectElementsCallBack}
            selectedElements={props.selectedElements}
            noElementsSelectedMsg={"Please select at least one plan."}
            maxOneColumn={5}
        />
    </div>;

    function getPlansToDisplay(): IPlanInfo[] {
        return doesUserHaveAccess(props.userType, ConsultantLevelList)
            ? getSuccessData(values.relatedPlans)!
            : values.sessionPlans.filter((plan) =>
                getCurrentClient()?.plans.some((clientPlan: IPlanInfo) => plan.id === clientPlan.id));
    }

    function getCurrentClient(): IClientInfo | undefined {
        return getSuccessData(values.adminClientsInfoState)?.find((clientInfo: IClientInfo) => {
            return clientInfo.plans.some((planInfo: IPlanInfo) => {
                return planInfo.id === props.currentPlan.id;
            });
        });
    }
};

