import {Action, Dispatch} from "redux";

export enum AdminActionTypes {
    BEGIN_EDIT_PORTFOLIO = "BEGIN_EDIT_PORTFOLIO",
    END_EDIT_PORTFOLIO = "END_EDIT_PORTFOLIO",
}

const beginEditPortfolioAction = () => {
    return {type: AdminActionTypes.BEGIN_EDIT_PORTFOLIO, payload: {}};
};

const endEditPortfolioAction = () => {
    return {type: AdminActionTypes.END_EDIT_PORTFOLIO, payload: {}};
};

export const beginEditPortfolio = () => {
    return (dispatch: Dispatch<Action<AdminActionTypes>>) => {
        dispatch(beginEditPortfolioAction());
    };
};

export const endEditPortfolio = () => {
    return (dispatch: Dispatch<Action<AdminActionTypes>>) => {
        dispatch(endEditPortfolioAction());
    };
};

export default {
    beginEditPortfolio,
    endEditPortfolio,
};
