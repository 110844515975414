import {Action, handleActions} from "redux-actions";
import {IPlanInfo} from "../../model/ClientsAndPlans.model";
import {PlanSelectActionTypes} from "./Client.actions";
import {NOT_REQUESTED, REQUEST_FAILED, REQUEST_SUCCEEDED, REQUESTED, RequestState} from "../common/commonStates";

export interface ICurrentClientState {
    relatedPlans: RequestState<IPlanInfo[]>;
}

export const currentClientInitialState: ICurrentClientState = {
    relatedPlans: NOT_REQUESTED,
};

const reducerMap = {
    [PlanSelectActionTypes.SET_RELATED_PLANS]:
        (state: ICurrentClientState, action: Action<IPlanInfo[]>): ICurrentClientState => {
            return {...state, relatedPlans: REQUEST_SUCCEEDED(action.payload!)};
        },
    [PlanSelectActionTypes.REQUEST_RELATED_PLANS_FAILED]:
        (state: ICurrentClientState, action: Action<any>): ICurrentClientState => {
            return {...state, relatedPlans: REQUEST_FAILED(action.payload!)};
        },
    [PlanSelectActionTypes.REQUEST_RELATED_PLANS]:
        (state: ICurrentClientState): ICurrentClientState => {
            return {...state, relatedPlans: REQUESTED};
        },
};

export function relatedPlansInner(state: ICurrentClientState): RequestState<IPlanInfo[]> {
    return state.relatedPlans;
}

export default handleActions<ICurrentClientState, IPlanInfo[]>(reducerMap, currentClientInitialState);
