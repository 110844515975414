/* tslint:disable:interface-name */

export const enum REQUEST_STATES {
    NOT_REQUESTED = "NOT_REQUESTED",
    REQUESTED = "REQUESTED",
    REQUEST_FAILED = "REQUEST_FAILED",
    REQUEST_SUCCEEDED = "REQUEST_SUCCEEDED",
}

export interface RequestFailed {
    kind: REQUEST_STATES.REQUEST_FAILED;
    failureInfo: any;
}

export interface NotRequested {
    kind: REQUEST_STATES.NOT_REQUESTED;
}

export interface Requested {
    kind: REQUEST_STATES.REQUESTED;
}

export interface RequestSucceeded<A> {
    kind: REQUEST_STATES.REQUEST_SUCCEEDED;
    data: A;
}

export type RequestIncompleteStates = NotRequested | Requested | RequestFailed;

export type RequestState<T> = RequestSucceeded<T> | RequestIncompleteStates;

export const REQUESTED: Requested = {
    kind: REQUEST_STATES.REQUESTED,
};

export const NOT_REQUESTED: NotRequested = {
    kind: REQUEST_STATES.NOT_REQUESTED,
};

export const REQUEST_FAILED = (failureInfo: any): RequestFailed => ({kind: REQUEST_STATES.REQUEST_FAILED, failureInfo});

export const REQUEST_SUCCEEDED = <A> (data: A): RequestSucceeded<A> => ({kind: REQUEST_STATES.REQUEST_SUCCEEDED, data});

export const getSuccessData = <A> (state: RequestIncompleteStates | RequestSucceeded<A>): A | null => {
    if (state.kind === REQUEST_STATES.REQUEST_SUCCEEDED) {
        return state.data;
    }
    return null;
};
