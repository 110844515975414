import * as React from "react";
import {useState} from "react";
import {ICalendarTemplateLink} from "../../model/CalendarTopicDetails.model";
import {hasConsultantLevelAccess} from "../../utils/sessionUtil";

export interface ICalendarTopicLinksProps {
    topicLinks: ICalendarTemplateLink[];
}

export enum TemplateLinkType {
    TEMPLATE = 1,
    WHITE_PAPER = 2,
}

export const CalendarTopicLinks: React.FunctionComponent<ICalendarTopicLinksProps> = (props) => {
    const [templateLinkView, setTemplateLinkView] = useState<TemplateLinkType>
    (hasConsultantLevelAccess() ? TemplateLinkType.TEMPLATE : TemplateLinkType.WHITE_PAPER);


    const getLinksByType = (linkType: TemplateLinkType) => {
        return props.topicLinks
            .filter(link => link.calendarLinkTypeId === linkType.valueOf());
    };

     const renderLinksTab = (linkType: TemplateLinkType) => {
         const links = getLinksByType(linkType);
         return linkType === TemplateLinkType.TEMPLATE
             ? hasConsultantLevelAccess() && links.length > 0
             : links.length > 0;
     };

    const getSelected = (linkType: TemplateLinkType) => linkType === templateLinkView ? "selected" : "";

    const renderLinksHeader = (linkType: TemplateLinkType) => {
        const linksHeader = linkType === TemplateLinkType.TEMPLATE ? "Template" : "White Paper";
        return renderLinksTab(linkType) && <div>
            <p className={`topic__links-tab clickable ${getSelected(linkType)}`}
               onClick={() => setTemplateLinkView(linkType)}>{linksHeader}</p>
        </div>;
    };

    const renderTopicLinks = (linkType: TemplateLinkType) => {
        const links = getLinksByType(linkType);

        const isCurrentTab = linkType === templateLinkView && links.length > 0;

        return isCurrentTab && <div className="topic__links">
            {links.map((link, index) => {
                return <li onClick={() => {
                    window.open(link.link, "_blank");
                }
                } className="topic__link-url clickable" key={index}>
                    {link.title}
                </li>;
            })}
        </div>;
    };

    const renderLinksSection = () => {
        return  hasConsultantLevelAccess()
            ? props.topicLinks.length > 0
            : props.topicLinks.filter(link =>
            link.calendarLinkTypeId === TemplateLinkType.WHITE_PAPER.valueOf()).length > 0;
    };

    return renderLinksSection()
        ? <div className="topic-links__section">
            <div className="topic__links-tabs">
                {renderLinksHeader(TemplateLinkType.TEMPLATE)}
                {renderLinksHeader(TemplateLinkType.WHITE_PAPER)}
            </div>
            <div className="topic__links-body">
                {renderTopicLinks(TemplateLinkType.TEMPLATE)}
                {renderTopicLinks(TemplateLinkType.WHITE_PAPER)}
            </div>
        </div>
        : null;
};