import * as React from "react";
import {Cell, Label, Pie, PieChart} from "recharts";
import {getDataCompletionChartData} from "./informUtil";

export interface IDataCompletionDonutChartProps {
    completion: string | undefined
}

export const DataCompletionDonutChart: React.FunctionComponent<IDataCompletionDonutChartProps> = (props) => {
    const renderChart = (value: string | undefined) => {
        const data = getDataCompletionChartData(value);
        return <div className="data-completion__chart">
            <PieChart width={180} height={180}>
                <Pie data={data} dataKey="value" nameKey="name" startAngle={90} endAngle={-270}
                     innerRadius={75} outerRadius={90}>
                    {data.map((entry, index) => (
                        <Cell key={`cell-${index}`} fill={entry.color}/>
                    ))}
                    <Label width={30} position="center" className='data-completion__chart-label'>
                        {`${data[0].value.toString()}%`}
                    </Label>
                </Pie>
            </PieChart>
        </div>;
    };

    return props.completion
        ? <div className="data-completion__container" data-testid="data-completion__container">
            {renderChart(props.completion)}
            <div className="data-completion__label">Data Completion</div>
        </div>
        : null;
};