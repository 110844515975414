export class OddReport {
    public static builder() {
        return new OddReportBuilder();
    }

    constructor(public backstopId: number,
                public title: string,
                public managers: string[],
                public products: string[],
                public fileName: string,
                public effectiveDate: Date) {
    }

}

class OddReportBuilder {
    private mBackstopId: number;
    private mTitle: string;
    private mManagers: string[];
    private mProducts: string[];
    private mFileName: string;
    private mEffectiveDate: Date;

    public backstopId(value: number) {
        this.mBackstopId = value;
        return this;
    }

    public title(value: string) {
        this.mTitle = value;
        return this;
    }

    public managers(value: string[]) {
        this.mManagers = value;
        return this;
    }

    public products(value: string[]) {
        this.mProducts = value;
        return this;
    }

    public fileName(value: string) {
        this.mFileName = value;
        return this;
    }

    public effectiveDate(value: Date) {
        this.mEffectiveDate = value;
        return this;
    }

    public build() {
        return new OddReport(
            this.mBackstopId || 0,
            this.mTitle || "",
            this.mManagers || [],
            this.mProducts || [],
            this.mFileName || "",
            this.mEffectiveDate || Date(),
        );
    }
}
