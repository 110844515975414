import * as React from "react";
import {useEffect, useState} from "react";
import {connect, useDispatch} from "react-redux";
import {clientUsers, hasAonTrustCompany, usersInSessionPlansSelector} from "../../../mainReducerMapSelectors";
import {ClientUser} from "../../model/ClientUser.model";
import {ClientLevelList, ConsultantLevelList, doesUserHaveAccess} from "../../model/UserDetails.model";
import {UserTypeEnum} from "../../model/UserInfo.model";
import {getSuccessData, REQUEST_STATES, RequestState} from "../common/commonStates";
import SelectCheckBoxesComponent from "../common/SelectCheckBoxesComponent";
import {requestClientUsers, requestUsersInSessionPlans} from "../shared-documents/SharedDocuments.actions";
import {ErrorComponent} from "../base/Error.component";
import {LoadingSpinner} from "../icons/LoadingSpinner.component";
import {FormattedMessage} from "react-intl";
import {byName} from "../../utils/listUtil";
import {IApplicationRootState} from "../../../applicationState";

export interface IUserSelectPropsFromParent {
    selectedPlans: number[];
    onSelectUsersChange: (arg: number[]) => void;
    selectedUsers: number[];
    userType: UserTypeEnum;
    thirdPartyEnabled: boolean;
}

export interface IUserSelectFromStore {
    usersWithSameClient: RequestState<ClientUser[]>;
    usersInSessionPlans: RequestState<ClientUser[]>;
    isAtcPlan: boolean;
}

export type UserSelectProps = IUserSelectPropsFromParent & IUserSelectFromStore;

export const UserSelectComponent: React.FunctionComponent<UserSelectProps> = (props) => {
    const [clientUsersSelected, setClientUsersSelected] = useState<number[]>([]);
    const [consultantUsersSelected, setConsultantUsersSelected] = useState<number[]>([]);
    const [showClientUserSelect, setShowClientUserSelect] = useState(true);
    const [showConsultantUserSelect, setShowConsultantUserSelect] = useState(true);

    const dispatch = useDispatch();

    useEffect(() => {
        if (props.usersWithSameClient.kind === REQUEST_STATES.NOT_REQUESTED
            && doesUserHaveAccess(props.userType, ConsultantLevelList)) {
            dispatch(requestClientUsers());
        }

        if (props.usersInSessionPlans.kind === REQUEST_STATES.NOT_REQUESTED
            && doesUserHaveAccess(props.userType, ClientLevelList)) {
            dispatch(requestUsersInSessionPlans());
        }
    }, []);

    const consultantUserTypes = [UserTypeEnum.CONSULTANT, UserTypeEnum.ADMIN, UserTypeEnum.ATC_ADMIN];
    const consultantElements = getElements(getPlansUsers(), consultantUserTypes).sort(byName);

    const selectedConsultants = props.userType === UserTypeEnum.CLIENT
        ? consultantElements.map((element) => element.id)
        : consultantUsersSelected;

    useEffect(() => {
        if (props.userType === UserTypeEnum.CLIENT) {
            props.onSelectUsersChange(selectedConsultants);
        }
    }, [props.usersInSessionPlans, props.selectedPlans, props.userType]);

    if (props.usersWithSameClient.kind === REQUEST_STATES.REQUESTED
        || props.usersInSessionPlans.kind === REQUEST_STATES.REQUESTED) {
        return <LoadingSpinner/>;
    }

    if (props.usersWithSameClient.kind === REQUEST_STATES.REQUEST_FAILED
        || props.usersInSessionPlans.kind === REQUEST_STATES.REQUEST_FAILED) {
        return <ErrorComponent/>;
    }

    return <div className="users" data-testid="user-select-component">
        {renderClientUsers()}
        <div className="user-sections-separator">
            <div className="user-sections-line"/>
            <div className="user-sections-line-spacer"/>
        </div>
        {renderConsultantUsers()}
    </div>;

    function renderClientUsers() {
        const userType =
            props.thirdPartyEnabled ? [UserTypeEnum.CLIENT, UserTypeEnum.THIRD_PARTY] : [UserTypeEnum.CLIENT];
        const users = getElements(getPlansUsers(), userType).sort(byName);

        const clientSelectClassName = `client-user-select${showClientUserSelect ? "" : " hidden"}`;
        const icon = showClientUserSelect ? "fas fa-chevron-up" : "fas fa-chevron-down";
        const iconClassName = `users-chevron user-hide-button client-user-hide-button clickable ${icon}`;

        return users.length === 0
            ? <div className="users-section_label">
                <FormattedMessage id="upload.no-clients"
                                  defaultMessage="No Client users have been added to this plan."/>
            </div>
            : <>
                <div className="user-section-header">
                    <div className="users-section_label">
                        <FormattedMessage id="upload.clients" defaultMessage="Clients"/>
                    </div>
                    <div className="users-section_spacer"/>
                    <div className={iconClassName} onClick={toggleShowClientUserSelect}/>
                    <div className="users-section_spacer_after"/>
                </div>
                <div className={clientSelectClassName}>
                    <SelectCheckBoxesComponent
                        maxOneColumn={10}
                        elements={users}
                        selectedElements={clientUsersSelected}
                        onSelectElementsChange={handleChangeForClient}
                    />
                </div>
            </>;
    }

    function renderConsultantUsers() {
        const hiddenClassName = showConsultantUserSelect ? "" : " hidden";
        const consultantSelectClassName = `consultant-user-select${hiddenClassName}`;
        const icon = showConsultantUserSelect ? "fas fa-chevron-up" : "fas fa-chevron-down";
        const iconClassName = `users-chevron user-hide-button consultant-user-hide-button clickable ${icon}`;

        return <>
            <div className="user-section-header">
                <div className="users-section_label">
                    <FormattedMessage id="upload.consultants" defaultMessage="Consultants" />
                </div>
                <div className="users-section_spacer"/>
                <div className={iconClassName} onClick={toggleShowConsultantUserSelect}/>
                <div className="users-section_spacer_after"/>
            </div>
            <div className={consultantSelectClassName}>
                <SelectCheckBoxesComponent
                    maxOneColumn={10}
                    elements={consultantElements}
                    selectedElements={selectedConsultants}
                    onSelectElementsChange={handleChangeForConsultant}
                    disabled={props.userType === UserTypeEnum.CLIENT}
                />
            </div>
        </>;
    }

    function toggleShowClientUserSelect() {
        setShowClientUserSelect(!showClientUserSelect);
    }

    function toggleShowConsultantUserSelect() {
        setShowConsultantUserSelect(!showConsultantUserSelect);
    }

    function getPlansUsers(): ClientUser[] {
        const userList = doesUserHaveAccess(props.userType, ConsultantLevelList)
            ? getSuccessData(props.usersWithSameClient)  || []
            : getSuccessData(props.usersInSessionPlans)  || [];

        const inSelectedPlans = (plan: number) => props.selectedPlans.includes(plan);

        return userList.filter((user) => {
            return user.planIds.some(inSelectedPlans);
        });
    }

    function getUserElementName(user: ClientUser): string {
        let name = `${user.firstName} ${user.lastName}`;
        if (props.isAtcPlan) {
            name = `${name} (${user.email})`;
        }
        return name;
    }

    function getElements(users: ClientUser[], userTypes: UserTypeEnum[]) {
        return users
            .filter((user) => userTypes
                .map((it) => it.toUpperCase())
                .includes(user.userType.toUpperCase()))
            .map((user) => ({name: getUserElementName(user), id: user.id}));
    }

    function handleChangeForClient(selectedClientIds: number[]) {
        setClientUsersSelected(selectedClientIds);

        const deselectedUserIds = props.selectedUsers
            .filter((userId) =>
                !selectedClientIds.includes(userId) && !selectedConsultants.includes(userId));

        const idsToAdd = props.selectedUsers.filter((userId) =>
            !selectedClientIds.includes(userId) && !deselectedUserIds.includes(userId));

        props.onSelectUsersChange([...idsToAdd, ...selectedClientIds]);
    }

    function handleChangeForConsultant(selectedConsultantIds: number[]){
        setConsultantUsersSelected(selectedConsultantIds);

        const deselectedUserIds = props.selectedUsers
            .filter((userId) =>
                !selectedConsultantIds.includes(userId) && !clientUsersSelected.includes(userId));

        const idsToAdd = props.selectedUsers.filter((userId) =>
            !selectedConsultantIds.includes(userId) && !deselectedUserIds.includes(userId));

        props.onSelectUsersChange([...idsToAdd, ...selectedConsultantIds]);
    }
};

export const mapStateToProps = (state: IApplicationRootState): IUserSelectFromStore => {
    return {
        usersWithSameClient: clientUsers(state),
        usersInSessionPlans: usersInSessionPlansSelector(state),
        isAtcPlan: hasAonTrustCompany(state)
    };
};

export default connect<IUserSelectFromStore, {}, IUserSelectPropsFromParent>(
    mapStateToProps,
)(UserSelectComponent);
