import {INewReleaseNote, ReleaseNote} from "../model/ReleaseNote.model";
import {parseDate} from "../utils/dateUtil";
import {ApiBase} from "./ApiBase";
import {IApiResult} from "./AxiosWealthManagementApi";

class ReleaseNotesApi {
    public addReleaseNote(releaseNote: INewReleaseNote): Promise<IApiResult<ReleaseNote>> {
        return ApiBase.processPostWithResponseBody(
            "/release-notes",
            releaseNote,
            (axiosResponse) => ({...axiosResponse.data, date: parseDate(axiosResponse.data.date)}),
        ).then((result) => result);
    }

    public getReleaseNotes(): Promise<IApiResult<ReleaseNote[]>> {
        return ApiBase.processGet(
            "/release-notes",
            (axiosResponse) => (axiosResponse.data.map(
                (data: any) => {
                    return ReleaseNote.builder()
                        .note(data.note)
                        .date(parseDate(data.date))
                        .id(data.id)
                        .build();
                },
            )));
    }

    public updateReleaseNote(releaseNote: ReleaseNote): Promise<void> {
        return ApiBase.processPut(`/release-notes/${releaseNote.id}`, releaseNote)
                .then(() => Promise.resolve());
    }

    public deleteReleaseNote(releaseNoteId: number): Promise<void> {
        return ApiBase.processDelete(`/release-notes/${releaseNoteId}`)
            .then(() => Promise.resolve());
    }
}

export default new ReleaseNotesApi();
