import * as React from "react";
import {Tooltip} from "@mui/material";
import {hasConsultantLevelAccess} from "../../utils/sessionUtil";
import {CheckBoxComponent} from "../common/CheckBox.component";
import {useEffect, useState} from "react";
import {RaisedButton} from "../common/RaisedButton";
import {prominentButtonStyle, secondaryButtonStyle} from "../common/buttonStyles";
import {CprKeyPoint} from "./CprKeyPoint";
import {setHeaderNotification} from "../base/header/HeaderActions";
import {NotificationTypes} from "../base/header/HeaderReducer";
import {useDispatch} from "react-redux";
import {CprPortfolioApi, IKeyPoint, IKeyPointsResponse} from "../../api/CprPortfolioApi";
import {ApiError} from "../../model/ApiError.model";
import {scrollToElement} from "../../utils/browserUtil";
import {formatDateAndTimeCustom} from "../../utils/dateUtil";
import {FormattedMessage} from "react-intl";

export const LABEL_PLACEHOLDER = "I confirm this content has been reviewed " +
    "according to the appropriate local procedures for client advice";
export const TOPIC_PLACEHOLDER = "Key Points Topic Title";
export const CONTENT_PLACEHOLDER = "Key Points Topic Content";
export const KEY_POINTS = "Key Points";

export const KEY_POINT_TITLE_MISSING = "A title needs to be filled in for all Key Points before you will be able to save. " +
    "Either fill in the blank titles or delete the Key Points that are no longer needed";

export const CprKeyPoints: React.FunctionComponent = () => {
    const [consent, setConsent] = useState<boolean>(true);
    const [editMode, setEditMode] = useState<boolean>(false);
    const [keyPointsResponse, setKeyPointsResponse] = useState<IKeyPointsResponse | undefined>();
    const [keyPoints, setKeyPoints] = useState<IKeyPoint[]>([]);

    const dispatch = useDispatch();

    const fetchKeyPoints = () => {
        CprPortfolioApi.fetchKeyPoints().then((response) => {
            setKeyPointsResponse(response);
            setKeyPoints(response ? response.keyPoints : []);
        });
    };
    useEffect(() => {
        fetchKeyPoints();
    },[]);

    const handleKeyPointChange = (index: number, updatedKeyPoint: IKeyPoint) => {
        const updatedKeyPoints: IKeyPoint[] = [...keyPoints];
        updatedKeyPoints[index] = updatedKeyPoint;
        setKeyPoints(updatedKeyPoints);
    };

    const handleDelete = (index: number) => {
        const removedKeyPoints: IKeyPoint[] = keyPoints.filter((keypoint, keyPointIndex) => {
            return keyPointIndex !== index;
        });
        setKeyPoints(removedKeyPoints);
    };

    const renderLastUpdated = () => {
        return hasConsultantLevelAccess() && keyPointsResponse
            ? <div className="key-points__header-last-updated">
                {getLastUpdated()}
            </div>
            : null;
    };

    const getLastUpdated = () => {
        const  timeStamp = formatDateAndTimeCustom(keyPointsResponse?.createdTimestamp, "DD MMM YYYY");
        return `Last updated: ${keyPointsResponse!.userEmail} ${timeStamp}`;
    };

    const renderContent = () => {
        return (
            <div className="key-points__section" data-testid="key-points__section">
                <div className="key-points-header__container">
                    <div className="key-points__header">
                        <div className="key-points__header-text">
                            <h2><FormattedMessage id="home.key-points-header" defaultMessage={KEY_POINTS}/></h2></div>
                    </div>
                    <div>
                        {renderLastUpdated()}
                    </div>
                </div>
                <div className={editMode ? "key-points__container edit" : "key-points__container"}>
                    <div className="key-points__consent" data-testid="key-points__consent">
                        {renderCheckbox()}
                    </div>
                    <div className="key-points__pencil">
                        {renderToolTip()}
                        {renderButtons()}
                    </div>
                    <div className="key-points__list-container">
                        {renderKeyPoints()}
                    </div>
                    <div className="key-points__add-link" data-testid="key-points__add-link">
                        {renderAddLink()}
                    </div>
                </div>
            </div>
        );
    };

    const renderToolTip = () => {
        if(!hasConsultantLevelAccess()) {
            return null;
        }
        return (
            editMode
                ? null
                : <Tooltip title="">
                    <p className="key_points__edit-pencil fas fa-pencil edit-pencil clickable"
                        onClick={() =>{
                            setEditMode(true);
                            setConsent(false);
                        }}/>
                </Tooltip>
        );
    };

    const renderCheckbox = () => {
        return editMode
            ? <CheckBoxComponent
                name="user-consent"
                checked={consent}
                onChange={() => {
                    setConsent(!consent);
                }}
                label={LABEL_PLACEHOLDER}
             />
            : null;
    };

    const handleSave = () => {
        if (keyPoints.filter((keypoint) => keypoint.title.trim().length === 0).length > 0) {
            dispatch(setHeaderNotification(
                {message: KEY_POINT_TITLE_MISSING,
                    notificationType: NotificationTypes.FAILURE},
                5000));
            scrollToElement(".main__header", 0);
            return;
        }

        const processSuccess = () => {
            setEditMode(false);
            fetchKeyPoints();
            dispatch(setHeaderNotification(
                {message: `Key points have been successfully uploaded.`,
                    notificationType: NotificationTypes.SUCCESS},
                5000));
            scrollToElement(".main__header", 0);
        };

        const processFailure = (errorNumber: number, errorMessage?: string) => {
            fetchKeyPoints();
            dispatch(setHeaderNotification(
                {message: errorMessage ? errorMessage
                        : 'Failed to persist key points',
                    notificationType: NotificationTypes.FAILURE},
                5000));
            scrollToElement(".main__header", 0);
        };

        CprPortfolioApi.saveKeyPoints(keyPoints)
            .then((response) => response.data ? processSuccess() : processFailure(-1))
            .catch((error: ApiError) => processFailure(error.errorNumber, error.message));

    };

    const renderButtons = () => {
        return (
            editMode
                ? <div className="key-point-buttons">
                    <RaisedButton className="key-points-edit__cancel-button"
                                  style={secondaryButtonStyle}
                                  primary={false}
                                  onClick={handleCancel} >
                        Cancel
                    </RaisedButton>
                    <RaisedButton className="key-points-edit__save-button"
                                  style={prominentButtonStyle}
                                  primary={true}
                                  disabled={!consent}
                                  onClick={handleSave} >
                        Save
                    </RaisedButton>
                </div>
                : null
        );
    };

    const handleCancel = () => {
        setKeyPoints(keyPointsResponse?.keyPoints ? keyPointsResponse?.keyPoints : []);
        setEditMode(false);
    };

    const renderKeyPoints = () => {
        if(keyPoints.length === 0) {
            keyPoints.push({title:"", content: ""});
        }
        return  keyPoints.map(renderKeyPoint);
    };

    const renderKeyPoint = (keyPoint: IKeyPoint, index: number) => {
        return <CprKeyPoint
            index={index}
            editMode={editMode}
            keyPoint={keyPoint}
            onChange={handleKeyPointChange}
            onDelete={handleDelete}
            key={`keypoint_${index}`}
        />;
    };

    const renderAddLink = () => {
        return editMode && keyPoints.length < 4
            ? <a className="key-points__add-key-point old-anchor" onClick={() => {
                setKeyPoints([...keyPoints,{title:"", content: ""}]);
            }}>+ Add Another Topic</a>
            : null;
    };

    return renderContent();
};