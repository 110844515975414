import {findElementById, getPageYOffset, getScrollTop, getScrollY} from "../api/BrowserWrapper";
import {useLocation} from "react-router-dom";
import "url-search-params-polyfill";

export const getElementById = (id: string): HTMLElement => {
    const elem = findElementById(id);
    if (!elem) {
        throw new Error(`Can't find position for element ${id}`);
    }
    return elem;
};

export const getScrollOffset = (): number => {
    const getSafariAndChromeScrollOffset = getScrollY();
    const getInternetExplorerScrollOffset = getPageYOffset();
    const getLastResortScrollOffset = getScrollTop();
    return getSafariAndChromeScrollOffset || getInternetExplorerScrollOffset || getLastResortScrollOffset || 0;
};

export function isIE() {
    // noinspection PointlessBooleanExpressionJS
    return (/* @cc_on!@ */false || !!(document as any).documentMode);
}

export function copyToClipboard(stringToCopy: string) {
    if (isIE()) {
        (window as any).clipboardData.setData("text", stringToCopy);
    } else {
        document.addEventListener("copy", (e: any) => {
            e.clipboardData.setData("text/plain", stringToCopy);
            e.preventDefault();
            document.removeEventListener("copy", e);
        });
        document.execCommand("copy");
    }
}

export function getElementWidth(querySelector: string) {
    return document.querySelector(querySelector)!.clientWidth;
}

export function getQueryStringParam(paramName: string) {
    return new URLSearchParams(useLocation().search).get(paramName);
}

export function scrollToElement (querySelector: string, offset: number, asIE: boolean = false) {
    const htmlElement = document.querySelector(querySelector) as HTMLElement;

    if (htmlElement) {
        if (isIE() || asIE) {
                htmlElement.scrollIntoView(true);
                window.scrollBy(0, -offset);
        } else {
            window.scrollTo({top: htmlElement.offsetTop - offset, behavior: "smooth"});
        }
    }
}

export function scrollElementToTop (querySelector: string) {
    const htmlElement = document.querySelector(querySelector) as HTMLElement;

    if (htmlElement) {
        htmlElement.scrollTop = 0;
    }
}
