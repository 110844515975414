import * as React from "react";
import {useState} from "react";
import {IPercentAndProductSummary} from "../OddIqUtils";
import {NR_RATING, OddDisplayedRatings, OddRatingList} from "../../../utils/ratingConstants";
import {byDate, compareDates, getShortMonthAndYearString, IDated, parseDate} from "../../../utils/dateUtil";
import {Link} from "react-router-dom";
import {Map as ImmutableMap} from "immutable";
import {ProductSummary} from "../../../model/product/ProductSummary";
import {IOddManager} from "../../../api/OddIqApi";
import {byName, compareInsensitive, INamed} from "../../../utils/listUtil";
import {QuestionCategory, QuestionSummaryCategory, SortCategory} from "../odd-page-no-backstop/SummaryByOddProduct";

export interface IOddSummaryByProductTableProps {
    productIdToSummaryMap: ImmutableMap<number, IPercentAndProductSummary>;
    hasClientResearchEnabled: boolean;
    oddManagers: IOddManager[];
}

export type SummaryComparator = (summary1: IPercentAndProductSummary, summary2: IPercentAndProductSummary) => number;

export const OddSummaryByProductTable: React.FunctionComponent<IOddSummaryByProductTableProps> = (props) => {
    const [sortCategory, setSortCategory] = useState({category: SortCategory.EXCEPTIONS, descending: true});

    function renderProductName(productSummary: ProductSummary, key: number, isLink: boolean) {
        return isLink
            ? <Link className="clickable odd-summary__product-link" to={`/products/${key}?tab=ODD`}>
                <span className="odd-summary__product-name">{productSummary.name}</span>
            </Link>
            : <span className="odd-summary__product-name small">{productSummary.name}</span>;
    }

    function renderTitleAndArrow(category: SortCategory, displayName: string) {
        let name = <div className="clickable">{displayName}</div>;
        let arrow = <div/>;

        if (category === sortCategory.category) {
            name = <div className="header-text color-teal clickable">{displayName}</div>;
            if (sortCategory.descending) {
                arrow = <div className="fal fa-arrow-down"/>;
            } else {
                arrow = <div className="fal fa-arrow-up"/>;
            }
        }

        return <div className="odd-product-summary__header">
            {name}
            {arrow}
        </div>;
    }

    function getManagerResponseDateString(productBackstopId: number): string {
        const managerWithResponse = props.oddManagers.find((manager) =>
            manager.productBackstopIds.includes(productBackstopId));

        return managerWithResponse && managerWithResponse.dateResponded
            ? getShortMonthAndYearString(parseDate(managerWithResponse.dateResponded))
            : "N/A";
    }

    function getComparator(): SummaryComparator {
        if (QuestionSummaryCategory.includes(sortCategory.category as QuestionCategory)) {
            return getComparatorForQuestionPercentage(
                sortCategory.category as QuestionCategory,
                sortCategory.descending,
            );
        } else if (sortCategory.category === SortCategory.STRATEGY) {
            return getComparatorForStrategy(sortCategory.descending);
        } else if (sortCategory.category === SortCategory.RATING) {
            return getComparatorForRating(sortCategory.descending);
        } else if (sortCategory.category === SortCategory.ASSET_CLASS) {
            return getComparatorForAssetClass(sortCategory.descending);
        } else if (sortCategory.category === SortCategory.RATING_DATE) {
            return getComparatorForRatingDate(sortCategory.descending);
        } else {
            return getComparatorForResponseDate(props.oddManagers, sortCategory.descending);
        }
    }

    function sortOnCategory(category: SortCategory) {
        let descending = true;

        if (sortCategory.category === category) {
            descending = !sortCategory.descending;
        }

        setSortCategory({category, descending});
    }

    function hasOdd (product: IPercentAndProductSummary) {
        const responsesSum= product.questionPercentSummary.exceptions +
                                product.questionPercentSummary.bestPractice +
                                product.questionPercentSummary.noData;

        return product.productSummary.oddRating.rating !== NR_RATING
                || responsesSum > 0;
    }

    function renderProductDataTable() {
        return props.productIdToSummaryMap
            .sort(getComparator())
            .map((product: IPercentAndProductSummary, key: number) => {
                const {productSummary, questionPercentSummary} = product!;
                const displayedRating = productSummary.oddRating.rating;

                return <tr key={key} className="odd-summary__product-summary-row" data-testid="product-summary-row">
                    <td>
                        {renderProductName(productSummary, key, hasOdd(product))}
                    </td>
                    <td>{productSummary.assetType}</td>
                    <td>
                        <div className="odd-summary__product-rating">
                            <span className={OddDisplayedRatings[displayedRating].class}
                                  data-testid="product-rating-dot"/>
                            <span>{OddDisplayedRatings[displayedRating].text}</span>
                        </div>
                    </td>
                    <td>{getShortMonthAndYearString(productSummary.oddRating.date)}</td>
                    <td>{questionPercentSummary.exceptions}%</td>
                    <td>{questionPercentSummary.bestPractice}%</td>
                    <td>{questionPercentSummary.noData}%</td>
                    <td>{getManagerResponseDateString(productSummary.backstopId)}</td>
                </tr>;

            })
            .toList();
    }

    return <div id="odd-product-summary__table-container" data-testid="odd-product-summary__table-container">
        <table>
            <thead>
            <tr className="odd-product-summary__column-headers">
                <th className="small bold strategy-header header-start"
                    onClick={() => sortOnCategory(SortCategory.STRATEGY)}>
                    {renderTitleAndArrow(SortCategory.STRATEGY, "Strategy")}
                </th>
                <th className="small bold asset-class-header"
                    onClick={() => sortOnCategory(SortCategory.ASSET_CLASS)}>
                    {renderTitleAndArrow(SortCategory.ASSET_CLASS, "Asset Class")}
                </th>
                <th className="small bold rating-header"
                    onClick={() => sortOnCategory(SortCategory.RATING)}>
                    {renderTitleAndArrow(SortCategory.RATING, "Rating")}
                </th>
                <th className="small bold rating-date-header"
                    onClick={() => sortOnCategory(SortCategory.RATING_DATE)}>
                    {renderTitleAndArrow(SortCategory.RATING_DATE, "Rating Date")}
                </th>
                <th className="small bold exceptions-header"
                    onClick={() => sortOnCategory(SortCategory.EXCEPTIONS)}>
                    {renderTitleAndArrow(SortCategory.EXCEPTIONS, "Exceptions")}
                </th>
                <th className="small bold best-practice-header"
                    onClick={() => sortOnCategory(SortCategory.BEST_PRACTICE)}>
                    {renderTitleAndArrow(SortCategory.BEST_PRACTICE, "Best Practice")}
                </th>
                <th className="small bold no-data-header"
                    onClick={() => sortOnCategory(SortCategory.NO_DATA)}>
                    {renderTitleAndArrow(SortCategory.NO_DATA, "No Data")}
                </th>
                <th className="small bold manager-response-header"
                    onClick={() => sortOnCategory(SortCategory.MANAGER_RESPONSE)}>
                    {renderTitleAndArrow(SortCategory.MANAGER_RESPONSE, "Manager Response")}
                </th>
            </tr>
            </thead>
            <tbody>
                {renderProductDataTable()}
            </tbody>
        </table>
    </div>;
};

function getComparatorForQuestionPercentage(category: QuestionCategory, sortDescending: boolean): SummaryComparator {
    const reverseSort = sortDescending ? 1 : -1;

    return (summary1: IPercentAndProductSummary, summary2: IPercentAndProductSummary) =>
        reverseSort * (summary2.questionPercentSummary[category] - summary1.questionPercentSummary[category]);
}

function getComparatorForStrategy(sortDescending: boolean): SummaryComparator {
    const reverseSort = sortDescending ? 1 : -1;

    return (summary1: IPercentAndProductSummary, summary2: IPercentAndProductSummary) =>
        reverseSort * (byName(summary1.productSummary as INamed, summary2.productSummary as INamed));
}

function getComparatorForAssetClass(sortDescending: boolean): SummaryComparator {
    const reverseSort = sortDescending ? 1 : -1;

    return (summary1: IPercentAndProductSummary, summary2: IPercentAndProductSummary) =>
        reverseSort * (compareInsensitive(summary1.productSummary.assetType, summary2.productSummary.assetType));
}

function getComparatorForRating(sortDescending: boolean): SummaryComparator {
    const reverseSort = sortDescending ? 1 : -1;

    return (summary1: IPercentAndProductSummary, summary2: IPercentAndProductSummary) => {
        const ratingIndex1 = OddRatingList.indexOf(summary1.productSummary.oddRating.rating);
        const ratingIndex2 = OddRatingList.indexOf(summary2.productSummary.oddRating.rating);
        const ratingDiff = reverseSort * (ratingIndex1 - ratingIndex2);

        if (ratingDiff === 0) {
            return (byName(summary1.productSummary as INamed, summary2.productSummary as INamed));
        }

        return ratingDiff;
    };
}

function getComparatorForRatingDate(sortDescending: boolean): SummaryComparator {
    const reverseSort = sortDescending ? 1 : -1;

    return (summary1: IPercentAndProductSummary, summary2: IPercentAndProductSummary) =>
        reverseSort * (byDate(
        summary1.productSummary.oddRating as IDated,
        summary2.productSummary.oddRating as IDated,
        ));
}

function getComparatorForResponseDate(oddManagers: IOddManager[], sortDescending: boolean): SummaryComparator {
    const reverseSort = sortDescending ? 1 : -1;

    return (summary1: IPercentAndProductSummary, summary2: IPercentAndProductSummary) => {
        const manager1 = oddManagers.find((manager) =>
            manager.productBackstopIds.includes(summary1.productSummary.backstopId));
        const responseDate1 = manager1 && manager1.dateResponded ? parseDate(manager1.dateResponded) : null;

        const manager2 = oddManagers.find((manager) =>
            manager.productBackstopIds.includes(summary2.productSummary.backstopId));
        const responseDate2 = manager2 && manager2.dateResponded ? parseDate(manager2.dateResponded) : null;

        return reverseSort * compareDates(responseDate1, responseDate2);
    };
}