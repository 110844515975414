import {IFundingLevel} from "../../api/CprPortfolioApi";
import {NOT_REQUESTED, REQUEST_SUCCEEDED, REQUESTED, RequestState} from "../common/commonStates";
import {CprPortfolioActions} from "./CprPortfolioActions";
import {Action, handleActions} from "redux-actions";

export interface ICprPortfolioStore {
    fundingLevel: RequestState<IFundingLevel | undefined>
}

export const cprPortfolioInitialState: ICprPortfolioStore = {
    fundingLevel: NOT_REQUESTED
};

const reducerMap = {
    [CprPortfolioActions.FUNDING_LEVEL_REQUESTED]:
        (state: ICprPortfolioStore) => {
            return {...state, fundingLevel: REQUESTED};
        },
    [CprPortfolioActions.FUNDING_LEVEL_REQUEST_SUCCEEDED]:
        (state: ICprPortfolioStore, action: Action<any>) => {
            return {...state, fundingLevel: REQUEST_SUCCEEDED(action.payload)};
        },
    [CprPortfolioActions.CLEAR_FUNDING_LEVEL]:
        (state: ICprPortfolioStore) => {
            return {...state, fundingLevel: NOT_REQUESTED};
        },
};

export function fundingLevelSelectorInner(state?: ICprPortfolioStore): RequestState<IFundingLevel | undefined> {
    return state ? state.fundingLevel : NOT_REQUESTED;
}

export default handleActions<ICprPortfolioStore>(reducerMap, cprPortfolioInitialState);