import {
    A1_PASS,
    A2_PASS,
    BUY,
    BUY_CLOSED,
    CONDITIONAL_PASS,
    FAIL,
    IN_REVIEW,
    NOT_RECOMMENDED,
    NR_RATING,
    PASS,
    QUALIFIED,
    SELL,
} from "./ratingConstants";

export function getRatingImageClass(currentRating: string): string {
    switch (currentRating.toUpperCase()) {
        case BUY.toUpperCase():
            return "current-rating--icon rating-buy";
        case BUY_CLOSED.toUpperCase():
            return "current-rating--icon rating-buy-closed";
        case QUALIFIED.toUpperCase():
            return "current-rating--icon rating-qualified";
        case SELL.toUpperCase():
            return "current-rating--icon rating-sell";
        case IN_REVIEW.toUpperCase():
            return "current-rating--icon rating-in-review";
        default:
            return "current-rating--icon rating-default";
    }
}

export function getRatingTextClass(currentRating: string, size: string): string {
    switch (currentRating) {
        case BUY:
        case A1_PASS:
        case A2_PASS:
        case PASS:
        case CONDITIONAL_PASS:
        case BUY_CLOSED:
            return `rating-green-color rating-${size}`;
        case SELL:
        case FAIL:
            return `rating-red-color rating-${size}`;
        case NOT_RECOMMENDED:
            return `rating-nr-yellow-color rating-${size}`;
        case IN_REVIEW:
            return `rating-ir-blue-color rating-${size}`;
        case QUALIFIED:
        case NR_RATING:
        default:
            return `rating-grey-color rating-${size}`;
    }
}
