import * as React from "react";
import {AccessLevels} from "../../model/UserDetails.model";
import {Dispatch} from "redux";
import {store} from "../../../configureStore";
import {Action} from "redux-actions";
import {REQUEST_STATES} from "./commonStates";
import {IRequesterApiPair, MultiApiRequester} from "./MultiDataGenericRequester";

export const multipleApiRequesterWrapper = (
    Component: (
        React.ComponentClass<any> |
        React.FunctionComponent<any>
        ),
    requesterApiPairArray: IRequesterApiPair[],
    userAccessLevel?: AccessLevels,
    loadingComponent?: React.Component | React.ReactNode,
) => (props: any) => {

    function createUnifiedApiRequest(): (dispatch: Dispatch) => any {
        const state = store.getState();
        return (dispatch: Dispatch<Action<any>>) => {
            const apiPromiseArray = requesterApiPairArray.map(({selector, apiRequest}) => {
                if (selector(state).kind === REQUEST_STATES.NOT_REQUESTED) {
                    return apiRequest(dispatch);
                }
                return new Promise((resolve) => resolve(undefined));
            });

            return Promise.all(apiPromiseArray);
        };
    }

    return <MultiApiRequester
        apiActions={createUnifiedApiRequest}
        requestStateSelectors={requesterApiPairArray.map((pair) => pair.selector)}
        loadingComponent={loadingComponent}>
        <Component {...props} className="multi-api-requester__child"/>
    </MultiApiRequester>;

};