import * as React from "react";
import {PerformanceSubNav} from "./PerformanceReportingTab";
import {REQUEST_STATES, RequestState} from "../common/commonStates";
import {IReportConfig} from "../../api/PowerBiReportingApi";
import {planName} from "../../utils/planUtil";
import {ErrorComponent} from "../base/Error.component";
import {PowerBiReportComponent} from "./PowerBiReport.component";
import {handleEnter} from "../../utils/commonUtil";
import {FormattedMessage} from "react-intl";
import {hasConsultantLevelAccess} from "../../utils/sessionUtil";
import {navigateTo} from "../../../navigateTo";

export interface IPerformanceReportProps {
    reportConfig: RequestState<IReportConfig>;
    setSubNav: (subNav: PerformanceSubNav) => void;
    hide: boolean;
    showFactSheets: boolean;
}
export const PerformanceReport: React.FunctionComponent<IPerformanceReportProps> = (props) => {
    function renderPowerBiReport() {
        return props.reportConfig.kind === REQUEST_STATES.REQUEST_SUCCEEDED
            ?  <PowerBiReportComponent reportConfig={props.reportConfig}
                                       displayPrintLink={false}
            />
            : null;
    }

    function renderFactSheetsLink() {
        return  props.showFactSheets
            ? <a className="powerbi-reporting__fact-sheets-link old-anchor"
                 tabIndex={0}
                 onKeyUp={handleEnter(() => props.setSubNav(PerformanceSubNav.FACT_SHEETS))}
                 onClick={() => props.setSubNav(PerformanceSubNav.FACT_SHEETS)}>

                <FormattedMessage id="client-reporting.fact-sheets" defaultMessage="Fact Sheets"/>
            </a>
            : null;
    }

    function renderSupplementalLink() {
        return <a className="powerbi-reporting__supplemental-link old-anchor"
                  tabIndex={0}
                  onKeyUp={handleEnter(() => props.setSubNav(PerformanceSubNav.SUPPLEMENTAL))}
                  onClick={() => props.setSubNav(PerformanceSubNav.SUPPLEMENTAL)}>
            <FormattedMessage id="filters.supplemental-documents" defaultMessage="Supplemental Reports"/>
        </a>;
    }

    function getClassName() {
        return props.hide ? "hidden" : "";
    }

    function renderMediaFileAdminLink() {
        return hasConsultantLevelAccess()
            ? <div className="powerbi-reporting__media-file-admin-container">
                <a id="powerbi-reporting__media-file-admin-link" className="old-anchor"
                   onClick={() => navigateTo("/plan-media-files")}>Media File Administration</a>
            </div>
            : null;
    }

    return <div id="powerbi-reporting__performance-report-container" className={getClassName()}>
        <div className="powerbi-reporting__performance-header">
            <h2 className="powerbi-reporting__performance-title">{planName()}</h2>
            <div className="powerbi-reporting__sub-nav-links">
                {renderFactSheetsLink()}
                {renderSupplementalLink()}
            </div>
        </div>
        {renderMediaFileAdminLink()}
        {props.reportConfig.kind === REQUEST_STATES.REQUEST_FAILED ? <ErrorComponent/> : renderPowerBiReport()}
    </div>;
};