import {Fade, IconButton, Tooltip} from "@mui/material";
import {TooltipProps} from "@mui/material/Tooltip";
import * as React from "react";
import {ReactElement} from "react";

export interface ITransitionText {
    hoverText: string;
    onClickedText: string;
    timer: number;
    onClickFunction: () => void;
}

export interface ITooltipComponentProps {
    transitionText: ITransitionText;
    muiTooltipProps?: Partial<TooltipProps>;
}

export interface ITooltipComponentChildren {
    children: ReactElement;
}

export type TooltipComponentProps = ITooltipComponentProps & ITooltipComponentChildren;

export interface ITooltipComponentState {
    title: string;
    button?: { onClick: () => void };
    position: TooltipProps["placement"];
}

export class TooltipComponent extends React.Component<TooltipComponentProps, ITooltipComponentState> {
    public constructor(props: TooltipComponentProps) {
        super(props);
        this.state = {
            title: "Copy URL",
            position: "bottom",
        };
    }

    public componentDidMount() {
        const transitionText = this.props.transitionText;
        this.setState({
                title: transitionText.hoverText,
                button: { onClick: () => {
                    this.props.transitionText.onClickFunction();
                    this.setState({
                            title: transitionText.onClickedText,
                            position: "bottom-start",
                        },
                        () => setTimeout(
                            () => { this.setState({title: ""} ); }, transitionText.timer),
                    );
                } },
            });
        }

    public render() {

        return <Tooltip
            title={this.state.title}
            placement={this.state.position}
            onClose={() => this.setState({title: this.props.transitionText.hoverText})}
            TransitionComponent={Fade}
            TransitionProps={{ timeout: 0 }}
            PopperProps={{
                disablePortal: true,
            }}
            >
                <IconButton
                    {...this.state.button}
                >
                {this.props.children}
                </IconButton>
        </Tooltip>;
    }
}
