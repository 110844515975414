import {Action, Dispatch} from "redux";
import {ThunkAction} from "redux-thunk";
import PlanApi from "../../api/PlanApi";

export enum PlanSelectActionTypes {
    SET_RELATED_PLANS = "SET_RELATED_PLANS",
    REQUEST_RELATED_PLANS = "REQUEST_RELATED_PLANS",
    REQUEST_RELATED_PLANS_FAILED = "REQUEST_RELATED_PLANS_FAILED",
    CLEAR_RELATED_PLANS = "CLEAR_RELATED_PLANS",
}

const getRelatedPlans = (currentPlanId: number): (ThunkAction<void, void, null, Action<number>>) => {
    // eslint-disable-next-line @typescript-eslint/no-misused-promises
    return (dispatch: Dispatch<Action<{}>>) => {
        dispatch({type: PlanSelectActionTypes.REQUEST_RELATED_PLANS});

        return PlanApi.getListOfCurrentClientsPlans(currentPlanId)
            .then((response) => {
                dispatch({
                    type: PlanSelectActionTypes.SET_RELATED_PLANS,
                    payload: response.data,
                });
            }).catch((error) => {
                dispatch({
                    type: PlanSelectActionTypes.REQUEST_RELATED_PLANS_FAILED,
                    payload: error,
                });
            });
    };
};

const getRelatedPlansActions = {
    getRelatedPlans,
};

export default getRelatedPlansActions;
