import {Action, handleActions} from "redux-actions";
import {PortfolioActions} from "./PortfolioActions";
import {ISelectValue} from "../common/Select.component";

export interface IPortfolioPlanTreeState {
    expandedRows: number[];
    selectedDate?: ISelectValue;
    selectedProduct?: number;
}

export const portfolioPlanTreeInitialState: IPortfolioPlanTreeState = {
    expandedRows: [],
    selectedDate: undefined,
    selectedProduct: undefined
};

const updatePlanTreeExpandedRows =
    (state: IPortfolioPlanTreeState, action: Action<number[]>): IPortfolioPlanTreeState => {
        return {
            ...state,
            expandedRows: action.payload ? action.payload : portfolioPlanTreeInitialState.expandedRows
        };
    };

const setPlanTreeDate =
    (state: IPortfolioPlanTreeState, action: Action<ISelectValue>): IPortfolioPlanTreeState => {
        return {
            ...state,
            selectedDate: action.payload ? action.payload : portfolioPlanTreeInitialState.selectedDate
        };
    };

const setSelectedProduct =
    (state: IPortfolioPlanTreeState, action: Action<number>): IPortfolioPlanTreeState => {
        return {
            ...state,
            selectedProduct: action.payload ? action.payload : portfolioPlanTreeInitialState.selectedProduct
        };
    };

const reducerMap = {
    [PortfolioActions.SET_TREE_SELECTED_DATE]: setPlanTreeDate,
    [PortfolioActions.UPDATE_TREE_EXPANDED_ROWS]: updatePlanTreeExpandedRows,
    [PortfolioActions.SET_SELECTED_PRODUCT]: setSelectedProduct,
};

export function portfolioPlanTreeExpandedRowsInner(state?: IPortfolioPlanTreeState): number[] {
    return state ? state.expandedRows : [];
}

export function portfolioPlanTreeSelectedDateInner(state?: IPortfolioPlanTreeState): ISelectValue | undefined {
    return state ? state.selectedDate : undefined;
}

export function portfolioPlanTreeSelectedProductInner(state?: IPortfolioPlanTreeState): number | undefined {
    return state ? state.selectedProduct : undefined;
}

type portfolioActionTypes = number[] | ISelectValue | number | undefined;

export default handleActions<IPortfolioPlanTreeState, portfolioActionTypes>(reducerMap, portfolioPlanTreeInitialState);