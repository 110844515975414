export class FlashReport {
    public static builder() {
        return new FlashReportBuilder();
    }

    constructor(public backstopId: number,
                public title: string,
                public descriptionHtml: string | null,
                public date?: Date) {
    }

}

class FlashReportBuilder {
    private mBackstopId: number;
    private mTitle: string;
    private mDescription: string;
    private mDate: Date;

    public backstopId(value: number) {
        this.mBackstopId = value;
        return this;
    }

    public title(value: string) {
        this.mTitle = value;
        return this;
    }

    public description(value: string) {
        this.mDescription = value;
        return this;
    }

    public date(value: Date) {
        this.mDate = value;
        return this;
    }

    public build() {
        return new FlashReport(this.mBackstopId || 0, this.mTitle || "title", this.mDescription, this.mDate);
    }
}
