import {ApiError} from "../model/ApiError.model";
import {ApiBase} from "./ApiBase";

export interface IApiResult<T> {
    data?: T;
    error?: ApiError;
}

export class AxiosWealthManagementApiImpl {
    public static sendFeedback(text: string): Promise<boolean> {
        return ApiBase.processPost(`/feedback-items`, {feedbackText: text})
            .then(() => true)
            .catch(() => false);
    }
}
