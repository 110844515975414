import * as React from "react";
import {IOddResponse, IOddSectionsQuestions} from "../../../../api/OddIqApi";
import {OddTotalResultsByRiskCategory} from "./OddTotalResultsByRiskCategory";
import {OddResultsByRiskCategoryBreakdown} from "./OddResultsByRiskCategoryBreakdown";
import {Map as ImmutableMap} from "immutable";
import {ProductSummary} from "../../../../model/product/ProductSummary";
import {OperationalDueDiligenceDocument} from "../../../../model/OperationalDueDiligenceDocument.model";

export interface OddResultsByRiskCategoryContainerProps {
    oddSectionsQuestions: IOddSectionsQuestions;
    oddResponses: IOddResponse[];
    portfolioProductSummaries: ImmutableMap<number, ProductSummary>;
    descriptionComponent: JSX.Element;
    currentQuestionId?: number;
    oddDocuments: OperationalDueDiligenceDocument[];
    byBackstop: boolean;
}

export const OddResultsByRiskCategoryContainer: React.FunctionComponent<OddResultsByRiskCategoryContainerProps> = (props) => {
    return <div>
        <div className="odd-page__results-by-risk-category">
            <div className="odd-page__level-description">{props.descriptionComponent}</div>
            <OddTotalResultsByRiskCategory oddResponses={props.oddResponses}
                                           oddSectionsQuestions={props.oddSectionsQuestions} />
        </div>
        <div className="odd-page__breakdown-chart-container">
            <OddResultsByRiskCategoryBreakdown oddResponses={props.oddResponses}
                                               oddSectionsQuestions={props.oddSectionsQuestions}
                                               portfolioProductSummaries={props.portfolioProductSummaries}
                                               currentQuestionId={props.currentQuestionId}
                                               oddDocuments={props.oddDocuments}
                                               byBackstop={props.byBackstop}
            />
        </div>
    </div>;
};