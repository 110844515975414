import {Dispatch} from "redux";
import {Action} from "redux-actions";
import {ThunkAction} from "redux-thunk";
import {AuthApi} from "../../api/AuthApi";
import {IPlanInfo} from "../../model/ClientsAndPlans.model";
import {UserDetails} from "../../model/UserDetails.model";

export enum SessionActions {
    SET_USER_DETAILS = "SET_USER_DETAILS",
    SET_CURRENT_PLAN_SESSION = "SET_CURRENT_PLAN_SESSION",
    SET_PLAN_LIST = "SET_PLAN_LIST",
}

export const createSetUserDetailsAction = (userDetails: UserDetails) => {
    return {
        type: SessionActions.SET_USER_DETAILS,
        payload: userDetails,
    };
};

export const createSetCurrentPlanSessionAction = (plan: IPlanInfo | undefined): Action<IPlanInfo | undefined> => {
    return {
        type: SessionActions.SET_CURRENT_PLAN_SESSION,
        payload: plan,
    };
};

const allPlans = (dispatch: Dispatch<Action<IPlanInfo[]>>) => {
    const promise = AuthApi.plans();

    promise.then((plans) => {
            dispatch(setPlans(plans.data!));
        },
    );
};

export const requestPlans = (): ThunkAction<void, void, null, Action<void>> => {
    return allPlans;
};

export const setPlans = (plans: IPlanInfo[]) => {
    return {
        type: SessionActions.SET_PLAN_LIST,
        payload: plans,
    };
};
