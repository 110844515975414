import {Action} from "redux-actions";
import {UserDetails} from "../../../model/UserDetails.model";

export enum UserSessionPersistenceActions {
    PERSIST_USER_DETAILS = "PERSIST_USER_DETAILS",
    PERSIST_TOKEN = "PERSIST_TOKEN",
    UNPERSIST_CURRENT_PLAN = "UNPERSIST_CURRENT_PLAN",
}

export const createPersistUserSessionAction = (userDetails: UserDetails): Action<UserDetails> => {
    return {
        type: UserSessionPersistenceActions.PERSIST_USER_DETAILS,
        payload: userDetails,
    };
};

export const createPersistTokenAction = (token: string): Action<string> => {
    return {
        type: UserSessionPersistenceActions.PERSIST_TOKEN,
        payload: token,
    };
};

export const createUnpersistCurrentPlanAction = () => {
    return {
        type: UserSessionPersistenceActions.UNPERSIST_CURRENT_PLAN,
    };
};
