import * as React from "react";
import {ComponentRating} from "../../../model/ComponentRating";
import {ProductRatingType} from "../../../model/product/ProductRatingType";
import {
    getInformRatingTranslatedComments, getMovementIconClass,
    getRatingIconClass,
    getRatingText
} from "../../../utils/ratingFormatUtil";
import {ProductCard} from "../ProductCard";

export interface IComponentRatingDetailProps {
    title: string;
    componentRating: ComponentRating;
    ratingType: ProductRatingType;
}

export const ComponentRatingDetail: React.FunctionComponent<IComponentRatingDetailProps> = (props) => {
    const renderCardContent = () => {
        return <div className="component-rating__comments-container">
            <div className="component-rating__comments" data-testid="component-rating__comments">
                {props.ratingType === ProductRatingType.INFORM
                    ? getInformRatingTranslatedComments(props.componentRating.rating, props.componentRating.comments)
                    : props.componentRating.comments}
            </div>
        </div>;
    };

    return <ProductCard
        name={props.title}
        rating={getRatingText(props.componentRating.rating)}
        ratingClass={`${getRatingIconClass(props.componentRating.rating)} 
                      ${getMovementIconClass(props.componentRating.movement)}`}>
        {renderCardContent()}
    </ProductCard>;
};

export default ComponentRatingDetail;