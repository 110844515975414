import {InsightsApi} from "../api/InsightsApi";
import {CapitalMarketAssumption, ICapitalMarketAssumption} from "../model/thought-leadership/CapitalMarketAssumption";
import {IMediumTermView, MediumTermView} from "../model/thought-leadership/MediumTermView.model";
import {
    IMarketOutlookDocument, MarketOutlookDocument,
} from "../model/thought-leadership/MarketOutlookDocument.model";

export type MarketOutlook = ICapitalMarketAssumption | IMediumTermView | IMarketOutlookDocument;

export enum DocumentType {
    CMA = "Capital Market Assumptions",
    MTV = "Medium Term Views",
    QIO = "Quarterly Investment Outlook",
    MB = "Market Background"
}

export function getDocumentType(document: MarketOutlook) {
    if (document instanceof CapitalMarketAssumption) {
        return (document as CapitalMarketAssumption).transform().type;
    } else if (document instanceof MediumTermView) {
        return (document as MediumTermView).transform().type;
    } else {
        return (document as MarketOutlookDocument).transform().type;
    }
}

export function download(document: MarketOutlook) {
    InsightsApi.getDocumentDownload(document.backstopId, document.fileName, true);
}
