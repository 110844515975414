import * as React from "react";
import {formatQuarterAndYear, getQuarterAndYearByDate} from "../../utils/dateUtil";
import {
    hasConsultantLevelAccess,
    isAdminUser,
    isAtcAdminUser,
    isThirdPartyUser
} from "../../utils/sessionUtil";
import {SharedDocument} from "../../model/SharedDocument.model";
import {copyToClipboard} from "../../utils/browserUtil";
import {TooltipComponent} from "../common/material-ui-core/TooltipComponent";
import {SharedDocumentApi} from "../../api/SharedDocumentApi";
import {IconButton, Tooltip} from "@mui/material";
import {navigateTo} from "../../../navigateTo";
import {getTranslation} from "../../utils/translationUtil";

export interface ISharedDocumentsFileProps {
    document: SharedDocument;
    currentUserEmail: string;
    index: number;
    handleOpenDialog: (documentId: number) => void;
    showThirdPartyColumn: boolean;
    aonTrustCompany: boolean;
}

export const SharedDocumentsFile: React.FunctionComponent<ISharedDocumentsFileProps> = (props) => {
    function getDownloadUrl() {
        const onClickFunction = () => {
            copyToClipboard(`${window.location.origin}/shared-documents/download/${props.document.id}`);
        };

        const hoverText = getTranslation( "shared-docs.copy-url", "Copy URL");

        return <td>
            <div className="advanced-file-management__icon-cell advanced-file-management__copy-url">
                <TooltipComponent transitionText={{
                    hoverText,
                    onClickedText: getTranslation( "shared-docs.copied", "Copied"),
                    timer: 2000,
                    onClickFunction,
                }}>
                    <div className="fas fa-link advanced-file-management__download-url"
                         id={`copy-shared-document-${props.index}`}/>
                </TooltipComponent>
            </div>
        </td>;
    }

    function getDocument(documentId: number, filename: string) {
        SharedDocumentApi.getDocumentDownload(documentId, filename, true);
    }

    function hasEditAccess(): boolean {
        return props.aonTrustCompany
            ? (isAtcAdminUser() || isAdminUser())
            : hasConsultantLevelAccess()
                ? true
                : !isAtcAdminUser() && !isThirdPartyUser() && props.currentUserEmail === props.document.email;
    }

    function getTealEditIcon() {
        return hasEditAccess()
            ? <td>
                <div className="advanced-file-management__icon-cell" id={`edit-shared-document-${props.index}`}>
                    <Tooltip title={getTranslation(
                        "shared-docs.edit-properties",
                        "Edit Properties")}
                             PopperProps={{
                                 disablePortal: true,
                             }}>
                        <IconButton
                            onClick={() => {
                                navigateTo(`/shared-documents/edit/${props.document.id}`);
                            }}>
                            <div className="fas fa-pencil edit-pencil"/>
                        </IconButton>
                    </Tooltip>
                </div>
            </td>
            : null;
    }

    function getTealTrashCan() {
        return hasEditAccess()
            ? <td>
                <div className="advanced-file-management__icon-cell trash-can-cell">
                    <Tooltip title={getTranslation(
                        "shared-docs.delete-document",
                        "Delete Document")}
                             PopperProps={{
                                 disablePortal: true,
                             }}>
                        <IconButton onClick={() => {
                            props.handleOpenDialog(props.document.id);
                        }}>
                            <div className="fas fa-trash trash-can"/>
                        </IconButton>
                    </Tooltip>
                </div>
            </td>
            : null;
    }

    function showThirdPartyAccessIndicator() {
        return hasConsultantLevelAccess() && props.showThirdPartyColumn ?
            <td className="shared-documents__row__thirdPartyAccess">
                <div className="advanced-file-management__thirdPartyAccess-cell">
                    {props.document.thirdPartyAccess ? "Y" : "N"}
                </div>
            </td>
            :null;
    }

    return <tr key={props.document.id} id={`shared-documents__row-${props.document.id}`} className="eachDoc">
        <td className="shared-documents__row__date">
            <div className="advanced-file-management__date-cell">
                {formatQuarterAndYear(getQuarterAndYearByDate(props.document.date))}
            </div>
        </td>
        <td>
            <div className="advanced-file-management__title-cell">
                <a className="shared-documents__row__title"
                   onClick={
                       () => getDocument(props.document.id, props.document.fileName)
                   }>
                    {props.document.title}
                </a>
            </div>
        </td>
        {showThirdPartyAccessIndicator()}
        {!hasEditAccess() && <td/>}
        {!hasEditAccess() && <td/>}
        {getDownloadUrl()}
        {getTealEditIcon()}
        {getTealTrashCan()}
    </tr>;
};

export default SharedDocumentsFile;