import * as React from "react";
import {connect} from "react-redux";
import {Link} from "react-router-dom";
import {bindActionCreators, Dispatch} from "redux";
import {navigateTo} from "../../../navigateTo";
import {IUserInfo, UserTypeEnum} from "../../model/UserInfo.model";
import {prominentButtonStyle} from "../common/buttonStyles";
import {RaisedButton} from "../common/RaisedButton";
import {compareInsensitive} from "../../utils/listUtil";
import {REQUEST_STATES} from "../common/commonStates";
import {DialogComponent} from "../common/Dialog.component";
import {adminUserDetailsPair} from "../common/RequesterPairs";
import adminActions from "./adminActions";
import {IAdminState} from "./AdminReducer";
import {withVerifyAccess} from "../common/withVerifyAccess";
import {multipleApiRequesterWrapper} from "../common/MultipleApiRequesterWrapper";
import {capitalizeFirstLetter} from "../../utils/commonUtil";
import {IApplicationRootState} from "../../../applicationState";

type IMainAdminComponentPropsFromReduxStore = IAdminState;

export type MainAdminComponentProps = IMainAdminComponentPropsFromReduxStore & IMainAdminComponentActions;

interface IMainAdminComponentState {
    dialogOpen: boolean;
    userIdToDelete: string;
    userEmailToDelete: string;
}

interface IMainAdminComponentActions {
    actions: { removeUser: typeof adminActions.removeUser };
}

export class MainAdminComponent extends React.Component<MainAdminComponentProps, IMainAdminComponentState> {

    private static renderHeaders() {
        return <tr className="admin-page__user-table-row admin-page__user-table-headers">
            <th className="admin-page__user-table-column admin-page__user-info__edit-link-header"/>
            <th className="admin-page__user-table-column admin-page__user-info__first-name-header">
                FIRST NAME
            </th>
            <th className="admin-page__user-table-column admin-page__user-info__last-name-header">
                LAST NAME
            </th>
            <th className="admin-page__user-table-column admin-page__user-info__email-header">
                EMAIL
            </th>
            <th className="admin-page__user-table-column admin-page__user-info__user-type-header">
                ROLE
            </th>
            <th className="admin-page__user-table-column admin-page__user-info__last-login-date-header">
                LAST LOGIN
            </th>
        </tr>;
    }

    public constructor(props: any) {
        super(props);
        this.state = {
            dialogOpen: false,
            userIdToDelete: "",
            userEmailToDelete: "",
        };
    }

    public render() {
        return this.props.adminUsersInfoState.kind === REQUEST_STATES.REQUEST_SUCCEEDED
            ? this.renderPage(this.props.adminUsersInfoState.data)
            : null;
    }

    private renderUser(user: IUserInfo, index: number) {
        return <tr className="admin-page__user-table-row admin-page__user-info"
                   key={`admin-page__user-table-row-${index}`}>
            <td className="admin-page__user-table-column admin-page__user-info__edit-link">
                <Link to={`/admin/user/${user.arbtSecurityId}`} id={`edit-user-link-${user.arbtSecurityId}`}>Edit</Link>
            </td>
            <td className="admin-page__user-table-column admin-page__user-info__first-name">
                {user.firstName}
            </td>
            <td className="admin-page__user-table-column admin-page__user-info__last-name">
                {user.lastName}
            </td>
            <td className="admin-page__user-table-column admin-page__user-info__email">
                {user.email}
            </td>
            <td className="admin-page__user-table-column admin-page__user-info__user-type">
                {capitalizeFirstLetter(user.userType)}
            </td>
            <td className="admin-page__user-table-column admin-page__user-info__last-login-date">
                {user.lastLoginDate || "Never"}
            </td>
            <td className="admin-page__user-table-column admin-page__user-info__delete">
                <div className="fas fa-trash trash-can clickable" onClick={this.deleteUser(user)}/>

            </td>
        </tr>;
    }

    private deleteUser = (user: IUserInfo) => () => {
        this.setState({dialogOpen: true});
        this.setState({userIdToDelete: user.arbtSecurityId});
        this.setState({userEmailToDelete: user.email});
    };

    private renderPage(adminUsersInfo: IUserInfo[]) {
        return (<div id="admin-page" className="new-common-styles" data-testid="admin-page">
                <div className="admin-page__headers">
                    <h1 id="admin-page-title" className="blue">User Administration</h1>
                    <a className="flex-right-align link-16 clickable" onClick={() => navigateTo("/admin/mass-assign-users")}>
                        Mass Assign Users to Plans
                    </a>
                    <RaisedButton className="admin-page__add-user-button"
                                  style={prominentButtonStyle}
                                  primary={true}
                                  onClick={() => navigateTo("/admin/add-user")}>
                        Add User
                    </RaisedButton>
                </div>
                <div className="admin-page__spacer"/>
                <table className="admin-page__user-table">
                    <thead>{MainAdminComponent.renderHeaders()}</thead>
                    <tbody>{this.renderUsers(adminUsersInfo)}</tbody>
                </table>
                <DialogComponent
                    title="Remove this user?"
                    open={this.state.dialogOpen}
                    noText="no, keep 'em"
                    yesText="yes, remove 'em"
                    onNo={this.handleClose}
                    onYes={this.handleDelete}
                    description="User will no longer have access to the website."
                />
            </div>
        );
    }

    private renderUsers(adminUsersInfo: IUserInfo[]) {
        return adminUsersInfo
            .sort((a, b) => compareInsensitive(a.email, b.email))
            .map((user, index) => this.renderUser(user, index));
    }

    private handleClose = () => {
        this.setState({dialogOpen: false});
    };

    private handleDelete = () => {
        this.props.actions.removeUser(this.state.userIdToDelete, this.state.userEmailToDelete);
        this.setState({dialogOpen: false});
    };
}

export const mapDispatchToProps = (dispatch: Dispatch): IMainAdminComponentActions => {
    return {
        actions: bindActionCreators({
            removeUser: adminActions.removeUser,
        }, dispatch),
    };
};

export const mapStateToProps = (state: IApplicationRootState): IMainAdminComponentPropsFromReduxStore => {
    return {
        ...state.adminState!,
    };
};

const connectedComponent = connect<IMainAdminComponentPropsFromReduxStore, IMainAdminComponentActions>
(mapStateToProps, mapDispatchToProps)(MainAdminComponent);
const withRequester = multipleApiRequesterWrapper(
    connectedComponent,
    [
        adminUserDetailsPair,
    ],
);

export default withVerifyAccess(withRequester, UserTypeEnum.ADMIN);