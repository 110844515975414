import * as React from "react";
import {useEffect, useState} from "react";
import {HoldingsGroupBy} from "../InvestmentsPage";
import {
    filterByGroupAndProduct,
    get12MostRecentMonthsOfHoldings,
    get4MostRecentQuartersOfHoldings,
    getTitle
} from "./CashFlowsUtils";
import {Switch} from "@mui/material";
import {CashFlowsOverTimeChart} from "./CashFlowsOverTimeChart";
import {CashFlowsOverTimeDataTable} from "./CashFlowsOverTimeDataTable";
import {CashFlowsExport} from "./CashFlowsExport";
import {IHolding} from "../../../api/HoldingsApi";

export interface ICashFlowOverTimeProps {
    holdings: IHolding[]
    groupBy: HoldingsGroupBy;
    selectedGroup: string;
    selectedProduct: string;
    showChart: boolean;
}

export const CashFlowsOverTime: React.FunctionComponent<ICashFlowOverTimeProps> = (props) => {
    const [quarterlySelected, setQuarterlySelected] = useState<boolean>(false);
    const [showInflowOutflow, setShowInflowOutflow] = useState<boolean>(false);

    useEffect(() => {
        if(showInflowOutflow && !props.showChart) {
            setShowInflowOutflow(false);
        }
    }, [props]);
    const mostRecentHoldingsOverTime = quarterlySelected
        ? get4MostRecentQuartersOfHoldings(props.holdings)
        : get12MostRecentMonthsOfHoldings(props.holdings);

    const filteredHoldingsOverTime: IHolding[] =filterByGroupAndProduct(
        mostRecentHoldingsOverTime,
        props.selectedGroup,
        props.selectedProduct,
        props.groupBy);

    return  <div className="cash-flows__chart-border">
        <div className="cash-flows__over-time-header-row">
            <h3 className="cash-flows__chart-title">
                {`${getTitle(true, props.selectedGroup, props.selectedProduct)} over Time`}
            </h3>
            {renderToggleVertical()}
            <span className="cash-flows__alignment-span">
                    {renderFrequencyButton()}
                {renderCsvExportButton(true)}
                </span>
        </div>
        {renderChartOrTableOverTime()}
    </div>;

    function renderFrequencyButton() {
        return <span>
            <span>Frequency:</span>
            <div className="cash-flow__frequency-toggle" data-testid="cash-flow__frequency-toggle">
                <div
                    className={`cash-flow__monthly-frequency${!quarterlySelected ? " activated" : " clickable"}`}
                    onClick={() => setQuarterlySelected(false)}
                    data-testid="cash-flow__monthly-frequency"
                >
                    Monthly
                </div>
                <div
                    className={`cash-flow__quarterly-frequency${quarterlySelected ? " activated" : " clickable"}`}
                    onClick={() => setQuarterlySelected(true)}
                    data-testid="cash-flow__quarterly-frequency"
                >
                    Quarterly
                </div>
            </div>
        </span>;
    }

    function renderCsvExportButton(isOvertime: boolean) {
        return props.showChart
            ? null
            : <CashFlowsExport holdings={filteredHoldingsOverTime}

                               isOvertime={isOvertime}
                               quarterlySelected={quarterlySelected}
                               selectedAssetClass={props.selectedGroup}
                               selectedProduct={props.selectedProduct}
                               selectedAssetClassSort={""}
                               id={ "export-overtime"}
            />;
    }

    function renderChartOrTableOverTime() {
        return props.showChart ? renderChartOverTime() : renderDataTable();
    }

    function renderChartOverTime() {
        return <div>
            <CashFlowsOverTimeChart holdings={filteredHoldingsOverTime}
                                    showInflowOutflow={showInflowOutflow}
                                    byQuarter={quarterlySelected}/>
        </div>;
    }

    function renderDataTable() {
        return <CashFlowsOverTimeDataTable holdings={filteredHoldingsOverTime} byQuarter={quarterlySelected}/>;
    }

    function renderToggleVertical() {
        return props.showChart ?
            <div className="cash-flows__toggle" data-testid="cash-flows-over-time__toggle">
                <span data-testid="cash-flows__toggle-label">
                    {showInflowOutflow ? "Hide Inflow/Outflow" : "Show Inflow/Outflow"}
                </span>
                <Switch
                    id="cashflow__switch-vertical"
                    onChange={() => setShowInflowOutflow(!showInflowOutflow)}
                    color="primary"
                    checked={showInflowOutflow}
                    data-testid="cashflow__switch-vertical"
                />
            </div>
            : null;
    }
};
