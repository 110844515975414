import * as d3 from "d3";
import {ScaleLinear} from "d3";
import * as React from "react";
import {useEffect, useState} from "react";
import {AonColors, LegacyColors} from "../../../../css/Colors";
import {IHolding} from "../../api/HoldingsApi";
import {formatCurrency} from "../../utils/numberUtil";
import {getDataSum, getPercent, IData} from "./InvestmentsDataUtil";
import {InvestmentsStrategiesTable} from "./InvestmentsStrategiesTable.component";
import {HoldingsGroupBy} from "./InvestmentsPage";
import {InvestmentsTrendsTable} from "./InvestmentsTrendsTable.component";

export interface IInvestmentsAssetAllocationRowProps {
    holdingsForPlan: IHolding[];
    year: number;
    month: number;
    currentGroupByPlanData: IData;
    currentGroupUniverseData: IData;
    allGroupsByPlanData: IData[];
    allGroupsUniverseData: IData[];
    monthlyHoldings: IHolding[];
    isExpanded: boolean;
    groupBy: HoldingsGroupBy;
    showUniverse: boolean;
}

export const InvestmentsByGroupRow: React.FunctionComponent<IInvestmentsAssetAllocationRowProps> = (props) => {
    const [maxBarWidth, setMaxBarWidth] = useState<number>(0);
    const [isExpanded, setIsExpanded] = useState<boolean>(false);

    const byPlanAssetSum = getDataSum(props.allGroupsByPlanData);
    const universeAssetSum = getDataSum(props.allGroupsUniverseData);

    const getLargestAssetValue = (groupData: IData[]) => {
        return groupData.reduce((accum, d) => accum > d.value ? accum : d.value, 0);
    };

    const maxByPlanAssetsPct = getLargestAssetValue(props.allGroupsByPlanData) * 100 / byPlanAssetSum;
    const maxUniverseAssetsPct = getLargestAssetValue(props.allGroupsUniverseData) * 100 / universeAssetSum;

    const currentGroupByPlanPct = getPercent(props.currentGroupByPlanData.value, props.allGroupsByPlanData, 2);
    const currentGroupUniversePct = getPercent(props.currentGroupUniverseData.value, props.allGroupsUniverseData, 2);

    useEffect(() => {
        setIsExpanded(props.isExpanded);
    }, [props]);

    useEffect(() => {
        setMaxBarWidth(getMaxBarWidth());
    });

    useEffect(() => {
        window.addEventListener("resize", () => {
                setMaxBarWidth(getMaxBarWidth());
            },
        );
    }, []);

    const getMaxBarWidth = () => {
        const innerWidth = window.innerWidth;
        const maxWidth = 1600;
        const minWidth = 1024;
        const offset = 75;

        if (innerWidth > maxWidth) {
            return maxWidth / 4 - offset;
        } else if (innerWidth < minWidth) {
            return minWidth / 4 - offset;
        } else {
            return innerWidth / 4 - offset;
        }
    };

    const getRectWidth = (value: number, assetsSum: number): number => {
        if (maxBarWidth > 0) {
            const maxPct = props.showUniverse ? maxUniverseAssetsPct : maxByPlanAssetsPct;
            const scaleFn = getScaleFn(maxBarWidth, maxPct);
            const pct = value * 100 / assetsSum;
            return scaleFn(pct)!;
        } else {
            return 0;
        }
    };

    const getScaleFn = (maxWidth: number, maxValue: number): ScaleLinear<number, number> => {
        return d3.scaleLinear().rangeRound([0, maxWidth]).domain([0, maxValue]).nice();
    };

    const renderTrendsAndStrategies = () => {
        return isExpanded
            ? <tr>
                <td colSpan={4} className="investments__strategy-table-container"
                    data-testid="investments__strategy-table-container">
                    <div className="investments__strategy-table-titles" data-testid="investments__trends-table-titles">
                        {props.groupBy === HoldingsGroupBy.BY_ASSET_CLASS ? "Asset Value Trends" : "Tier Value Trends"}
                    </div>
                    <div className="investments__trends-container">
                        <InvestmentsTrendsTable holdings={props.holdingsForPlan}
                                                groupName={props.currentGroupByPlanData.name}
                                                month={props.month}
                                                year={props.year}
                                                groupBy={props.groupBy}/>
                    </div>
                    <div className="investments__strategy-table-titles"
                         data-testid="investments__strategy-table-titles">
                        {props.groupBy === HoldingsGroupBy.BY_ASSET_CLASS ? "Asset Class Breakdown" : "Tier Breakdown"}
                    </div>
                    <InvestmentsStrategiesTable currentGroupData={props.currentGroupByPlanData}
                                                allGroupsData={props.allGroupsByPlanData}
                                                monthlyHoldings={props.monthlyHoldings}
                                                groupBy={props.groupBy}
                    />
                </td>
            </tr>
            : null;
    };

    const renderHoldingsUniversePercent = () => {
        return props.showUniverse
            ? <div className="investments__holding-universe-pct-container"
                   data-testid="investments__holding-universe-pct-container">
                <span className="investments__holding-universe-pct">
                    {getPercent(props.currentGroupUniverseData.value, props.allGroupsUniverseData, 2)}
                </span>
                <svg className="investments__universe-pct-bar" width={maxBarWidth} height="20">
                    <rect height="20"
                          width={`${getRectWidth(props.currentGroupUniverseData.value, universeAssetSum)}`}
                          fill={AonColors.AonDataLime}
                          data-testid="investments__universe-pct-bar"/>
                </svg>
            </div>
            : null;
    };

    const renderWeight = () => {
        const byPlan = parseFloat(currentGroupByPlanPct);
        const universe = parseFloat(currentGroupUniversePct);
        const weight = byPlan - universe;

        const negativeClass = weight < 0 ? "negative" : "";

        return props.showUniverse
            ? <td className={`data large investments__weight ${negativeClass}`}>{weight.toFixed(2)}%</td>
            : null;
    };

    const getBackgroundColor = () => {
        return isExpanded ? LegacyColors.GRAY_G : AonColors.ActualWhite;
    };

    const getChevronSvg = () => {
        return isExpanded ? "far fa-chevron-up" : "far fa-chevron-down";
    };

    const getFontClass = () => {
        return props.showUniverse ? "investments__universe-shown" : "";
    };

    const getRectPadding = () => {
        return props.showUniverse ? "": "investments__holding-pct-bar";
    };

    return <>
        <tr key={props.currentGroupByPlanData.name}
            className="investments__asset-allocation-table-row clickable"
            style={{backgroundColor: getBackgroundColor()}}
            onClick={() => setIsExpanded(!isExpanded)}
            data-testid="investments__asset-allocation-table-row"
        >
            <td className="row-header large">{props.currentGroupByPlanData.name}</td>
            <td className="data large">
                <div className="investments__holding-pct-container">
                    <span className={`investments__holding-pct-label ${getFontClass()}`}>
                        {currentGroupByPlanPct}
                    </span>
                    <svg className={getRectPadding()} width={maxBarWidth} height="20">
                        <rect height="20"
                              width={`${getRectWidth(props.currentGroupByPlanData.value, byPlanAssetSum)}`}
                              fill={AonColors.AonDataCobalt}
                              data-testid="investments__holding-pct-bar"/>
                    </svg>
                </div>
                {renderHoldingsUniversePercent()}
            </td>
            {renderWeight()}
            <td className="data large investments__amount">
                {formatCurrency(props.currentGroupByPlanData.value, 0)}
            </td>
            <td className="investments__expand">
                <div className={`investments__chevron ${getChevronSvg()}`} data-testid="investments__chevron"/>
            </td>
        </tr>
        {renderTrendsAndStrategies()}
    </>;
};
