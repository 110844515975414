import * as React from "react";
import {IInformSummary} from "../../../model/inform/InformSummary";
import {ITooltipProps, Tooltip} from "../../common/Tooltip";
import {formatShortDate} from "../../../utils/dateUtil";

export interface IDisclosuresProps {
    summary: IInformSummary;
}

export const Disclosures: React.FunctionComponent<IDisclosuresProps> = (props) => {
    const convertDateToShortFormat = (dateString: string): string => {
        if (dateString && dateString.includes("/")) {
            const parts = dateString.split('/');
            return formatShortDate(new Date(`${parts[1]}/${parts[0]}/${parts[2]}`));
        }
        return dateString;
    };

    const DISCLOSURES_TEXT = `${props.summary.performanceType} (${props.summary.reportingCurrency}) is 
        ${props.summary.reportingMethod} relative to ${props.summary.inFormBenchmark}. CY = calendar year. Source: 
        eVestment. Performance is representative performance as reported in eVestment. The performance for a specific 
        vehicle may differ from the representative strategy. Note: The Aon InForm Assessment section is based on data 
        as of ${convertDateToShortFormat(props.summary.ifaRatingEffectiveDate)} (obtained on 
        ${convertDateToShortFormat(props.summary.informAssessmentDate)} from eVestment) unless stated 
        differently. Product data completion is ${props.summary.completion} and peer group average data completion is 
        ${props.summary.averageDataCompletion}. Changes in manager or peer group data completion may impact the Aon 
        InForm Assessment. Past performance is no guarantee of future results.`;

    const renderTooltip = () => {
        const tooltipProps: ITooltipProps = {
            label: "Disclosures",
            hoverText: [DISCLOSURES_TEXT],
            hoverTitle: "Disclosures",
        };
        return <Tooltip {...tooltipProps}/>;
    };

    return <span className="disclosures-link" data-testid="disclosures-link">
                {renderTooltip()}
            </span>;
};