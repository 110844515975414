import {ApiBase} from "./ApiBase";
import {AxiosResponse} from "axios";
import {Map} from "immutable";
import {CalendarTopicDetails, ICalendarTemplateLink} from "../model/CalendarTopicDetails.model";
import {IApiResult} from "./AxiosWealthManagementApi";
import {CalendarMeetingDetails} from "../model/CalendarMeetingDetails.model";
import {CalendarMeetingAttendee} from "../model/CalendarMeetingAttendee.model";

export interface ICalendarTemplateTopic {
    id?: number,
    name: string,
    description?: string,
    frequencyId: number,
    allowDupes: boolean,
    frequency: string,
    planTypeName: string,
    planTypeId: number,
    links: ICalendarTemplateLink[]
}

export interface ICalendarTopic {
    id: number,
    templateTopicId: number,
    name: string,
    order: number,
    frequencyId: number
}

export interface ICalendarTopicToSave extends ICalendarTopic {
    year: number,
    quarter: number
}

export interface ICalendarMeeting {
    id: number,
    year: number,
    quarter: number,
    topics: ICalendarTopic[]
}

export interface ICalendarQuarter {
    key: string,
    year: number,
    name: string,
    quarterNumber: number,
}

export interface ICommentRequest {
    bodyHtml: string
}

export class CalendarApi {
    public static requestTemplateTopicsBasedOnPlanType(): Promise<ICalendarTemplateTopic[]> {
        return ApiBase.processGetUnwrapped("/plan/current/calendar-template/topics",
            (response: AxiosResponse): ICalendarTemplateTopic[] => {
                return response.data as ICalendarTemplateTopic[];
            });
    }

    public static requestAllTemplateTopics(): Promise<ICalendarTemplateTopic[]> {
        return ApiBase.processGetUnwrapped("/calendar-template/topics/all",
            (response: AxiosResponse): ICalendarTemplateTopic[] => {
                return response.data as ICalendarTemplateTopic[];
            });
    }

    public static modifyTemplateTopic(modifiedTopic: ICalendarTemplateTopic): Promise<void> {
        return ApiBase.processPatch(
            `/calendar-template/topics/${modifiedTopic.id}`,
            modifiedTopic,
        ).then(() => Promise.resolve());
    }

    public static addTemplateTopic(modifiedTopic: ICalendarTemplateTopic): Promise<void> {
        return ApiBase.processPost(
            `/calendar-template/topic`,
            modifiedTopic,
        ).then(() => Promise.resolve());
    }

    public static createTopics(calendarTopics: ICalendarTopicToSave[]): Promise<Map<string, ICalendarMeeting>> {
        return ApiBase.processPostWithResponseBodyUnwrapped(
            "/calendar/meetings/topics", calendarTopics,
            (response: AxiosResponse): any => {
                return (Object as any).entries(response.data)
                    .reduce(CalendarApi.calendarReducer, Map<string, ICalendarMeeting>());
            }
        );
    }

    public static uploadCalendarTopicDocument(topicId: number, formData: FormData): Promise<IApiResult<boolean>> {
        return ApiBase.processPost(`/calendar/topic/${topicId}/document`, formData);
    }

    public static addCalendarTopicComment(topicId: number, commentRequest: ICommentRequest):
        Promise<IApiResult<boolean>> {
        return ApiBase.processPost(`/calendar/topic/${topicId}/comment`, commentRequest);
    }

    public static deleteCalendarTopicDocument(documentId: number) {
        return ApiBase.processDelete(`/calendar/topic/documents/${documentId}`);
    }

    public static deleteCalendarTopicComment(commentId: number) {
        return ApiBase.processDelete(`/calendar/topic/comments/${commentId}`);
    }

    public static requestCalendarMeetingsWithTopics(): Promise<Map<string, ICalendarMeeting>> {
        return ApiBase.processGetUnwrapped("/calendar/meetings/topics",
            (response: AxiosResponse): any => {
                return (Object as any).entries(response.data)
                    .reduce(CalendarApi.calendarReducer, Map<string, ICalendarMeeting>());
            }
        );
    }

    public static deleteCalendarTopic(topicId: number) {
        return ApiBase.processDelete(`/calendar/topics/${topicId}`);
    }

    public static requestCalendarMeeting(meetingId: number): Promise<ICalendarMeeting> {
        return ApiBase.processGetUnwrapped(`/calendar/meetings/${meetingId}`,
            (response: AxiosResponse): any => {
                return response.data as ICalendarMeeting;
            });
    }

    public static requestCalendarMeetingDetails(meetingId: number): Promise<CalendarMeetingDetails> {
        return ApiBase.processGetUnwrapped(`/calendar/meeting-details/${meetingId}`,
            (response: AxiosResponse): any => {
                return response.data as CalendarMeetingDetails;
            });
    }

    public static requestCalendarTopicDetails(topicId: number): Promise<CalendarTopicDetails> {
        return ApiBase.processGetUnwrapped(`/calendar/topic-details/${topicId}`,
            (response: AxiosResponse): any => {
                return response.data as CalendarTopicDetails;
            });
    }

    public static requestCalendarMeetingByQuarter(year: number, quarter: number): Promise<ICalendarMeeting> {
        return ApiBase.processGetUnwrapped(`/calendar/meetings/years/${year}/quarters/${quarter}`,
            (response: AxiosResponse): any => {
                return response.data as ICalendarMeeting;
            });
    }

    public static moveTopic(calendarTopic: ICalendarTopicToSave): Promise<Map<string, ICalendarMeeting>> {
        return ApiBase.processPatchWithResponseBodyUnwrapped(
            `/calendar/meetings/topics/${calendarTopic.id}`,
            calendarTopic,
            (response: AxiosResponse): any => {
                return (Object as any).entries(response.data)
                    .reduce(CalendarApi.calendarReducer, Map<string, ICalendarMeeting>());
            }
        );
    }

    public static reorderTopics(meetingId: number, orderedTopicIds: number[]): Promise<ICalendarMeeting> {
        return ApiBase.processPatchWithResponseBodyUnwrapped(
            `/calendar/meetings/${meetingId}/topics`,
            orderedTopicIds,
            (response: AxiosResponse): any => {
                return response.data as ICalendarMeeting;
            }
        );
    }

    public static updateTopicName(topicId: number, name: string | undefined): Promise<void> {
        return ApiBase.processPatch(`/calendar/topics/${topicId}/name`, { name })
            .then(() => Promise.resolve());
    }

    public static updateTopicDescription(topicId: number, description: string | undefined): Promise<void> {
        return ApiBase.processPatch(`/calendar/topics/${topicId}/description`, { description })
            .then(() => Promise.resolve());
    }

    public static updateCalendarMeetingDetails(calendarMeetingDetails: CalendarMeetingDetails)
        : Promise<void> {

        return ApiBase.processPost(
            `/calendar/meetings/${calendarMeetingDetails.id}/info`,
            calendarMeetingDetails)
            .then(() => Promise.resolve());
    }

    public static requestMeetingAttendees(meetingId: number): Promise<CalendarMeetingAttendee[]> {
        return ApiBase.processGetUnwrapped(`/calendar/meetings/${meetingId}/attendees`,
            (response: AxiosResponse): any => {
                return response.data.map((attendee: CalendarMeetingAttendee) => {
                    return new CalendarMeetingAttendee(
                        attendee.firstName,
                        attendee.lastName,
                        attendee.userType,
                        attendee.email,
                        attendee.id,
                    );
                });
            });
    }

    public static addMeetingAttendee(meetingId: number, attendee: CalendarMeetingAttendee) :
        Promise<IApiResult<boolean>> {
        return ApiBase.processPost(`/calendar/meetings/${meetingId}/attendee`, attendee);
    }

    public static deleteMeetingAttendee(attendeeId: number) {
        return ApiBase.processDelete(`/calendar/meeting-attendees/${attendeeId}`);
    }

    private static calendarReducer = (all: Map<string, ICalendarMeeting>,
                                      [key, value]: (any)[]) => {
        return all.set(key, value);
    };
}