import {ClientReport} from "../../model/ClientReport.model";
import {NOT_REQUESTED, REQUEST_FAILED, REQUEST_SUCCEEDED, REQUESTED, RequestState} from "../common/commonStates";
import {Action, handleActions} from "redux-actions";
import {PowerBiReportingActions} from "./PowerBiReporting.actions";
import {IReportExportInfo} from "../../api/PowerBiReportingApi";

export interface IPowerBiReportingStore {
    clientReports: RequestState<ClientReport[]>;
    currentCategory?: string;
    reportExportInfo: RequestState<IReportExportInfo>;
    reportExportPeriod?: number;
}

export const powerBiReportingInitialState: IPowerBiReportingStore = {
    clientReports: NOT_REQUESTED,
    currentCategory: undefined,
    reportExportInfo: NOT_REQUESTED,
    reportExportPeriod: undefined
};

const reducerMap = {
    [PowerBiReportingActions.CLIENT_REPORTS_REQUESTED]:
        (state: IPowerBiReportingStore) => {
            return {...state, clientReports: REQUESTED};
        },
    [PowerBiReportingActions.CLIENT_REPORTS_REQUEST_SUCCEEDED]:
        (state: IPowerBiReportingStore, action: Action<any>) => {
            return {...state, clientReports: REQUEST_SUCCEEDED(action.payload)};
        },
    [PowerBiReportingActions.CLIENT_REPORTS_REQUEST_FAILED]:
        (state: IPowerBiReportingStore, action: Action<any>) => {
            return {...state, clientReports: REQUEST_FAILED(action.payload)};
        },
    [PowerBiReportingActions.CLEAR_CLIENT_REPORTS]:
        (state: IPowerBiReportingStore) => {
            return {...state, clientReports: NOT_REQUESTED};
        },
    [PowerBiReportingActions.SET_CURRENT_CATEGORY]:
        (state: IPowerBiReportingStore, action: Action<any>) => {
            return {...state, currentCategory: action.payload};
        },
    [PowerBiReportingActions.EXPORT_REPORT_REQUESTED]:
        (state: IPowerBiReportingStore) => {
            return {...state, reportExportInfo: REQUESTED};
        },
    [PowerBiReportingActions.EXPORT_REPORT_REQUEST_SUCCEEDED]:
        (state: IPowerBiReportingStore, action: Action<any>) => {
            return {...state, reportExportInfo: REQUEST_SUCCEEDED(action.payload)};
        },
    [PowerBiReportingActions.EXPORT_REPORT_REQUEST_FAILED]:
        (state: IPowerBiReportingStore, action: Action<any>) => {
            return {...state, reportExportInfo: REQUEST_FAILED(action.payload)};
        },
    [PowerBiReportingActions.EXPORT_REPORT_CLEAR]:
        (state: IPowerBiReportingStore) => {
            return {...state, reportExportInfo: NOT_REQUESTED};
        },
    [PowerBiReportingActions.SET_EXPORT_REPORT_PERIOD]:
        (state: IPowerBiReportingStore, action: Action<any>) => {
            return {...state, reportExportPeriod: action.payload};
        },
};

export function powerBiClientReportsSelectorInner(state?: IPowerBiReportingStore): RequestState<ClientReport[]> {
    return state ? state.clientReports : NOT_REQUESTED;
}

export function powerBiReportCategorySelectorInner(state?: IPowerBiReportingStore): string | undefined {
    return state ? state.currentCategory : undefined;
}

export function powerBiReportExportSelectorInner(state?: IPowerBiReportingStore): RequestState<IReportExportInfo> {
    return state ? state.reportExportInfo : NOT_REQUESTED;
}

export function powerBiReportExportPeriodSelectorInner(state?: IPowerBiReportingStore): number | undefined {
    return state ? state.reportExportPeriod : undefined;
}

export default handleActions<IPowerBiReportingStore>(reducerMap, powerBiReportingInitialState);