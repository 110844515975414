import * as React from "react";
import {useState} from "react";
import {TextFieldComponent} from "../common/TextField.component";
import {RaisedButton} from "../common/RaisedButton";
import {prominentButtonStyle, secondaryButtonStyle} from "../common/buttonStyles";
import {getElementById} from "../../utils/browserUtil";
import {HiddenFileInput} from "../shared-documents/HiddenFileInput.component";
import {UploadFile} from "../../model/UploadFile.model";
import {ERROR_NOT_ALLOWED, getErrorSpecificClientMessage, throwErrorOnNullOrUndefined} from "../../utils/errorUtil";
import {formatNumericDate, reactDatePickerShortDateFormat} from "../../utils/dateUtil";
import {setHeaderNotification} from "../base/header/HeaderActions";
import {NotificationTypes} from "../base/header/HeaderReducer";
import {useDispatch} from "react-redux";
import {IReportWorkspace, PowerBiReportingApi} from "../../api/PowerBiReportingApi";
import {clearClientReports} from "../power-bi-reporting/PowerBiReporting.actions";
import DatePicker from "react-datepicker";
import {LoadingSpinner} from "../icons/LoadingSpinner.component";
import {Portal} from "react-overlays";
import {ApiError} from "../../model/ApiError.model";
import {getTranslation} from "../../utils/translationUtil";

export interface IAddClientReportProps {
    setShowAddReport: (show: boolean) => void;
    categoryId: number;
    planId: number;
    workspace: IReportWorkspace | undefined;
}

export const AddClientReport:React.FunctionComponent<IAddClientReportProps> = (props) => {
    const [submitClicked, setSubmitClicked] = useState(false);
    const [title, setTitle] = useState("");
    const [reportId, setReportId] = useState("");
    const [reportName, setReportName] = useState("");
    const [requestId, setRequestId] = useState("");
    const [reportDate, setReportDate] = useState<Date | undefined>(undefined);
    const [selectedFile, setSelectedFile] = useState<UploadFile | undefined>(undefined);

    const dispatch = useDispatch();

    const renderInputField = (name: string,
                              placeHolder: string,
                              currentValue: string,
                              setFunction: (value: string) => void,
                              containerClass: string = "add-client-report__input-container") => {
        const handleChange = (e: React.ChangeEvent<HTMLSelectElement>) => {
            return setFunction(e.target.value);
        };

        return <div className={containerClass}>
                <div className="add-client-report__label">{placeHolder}</div>
                <TextFieldComponent
                    name={`${name}`}
                    className={`add-client-report__${name}-input`}
                    type="text"
                    fullWidth={true}
                    value={currentValue}
                    handleChange={handleChange}
                    placeholder={placeHolder}
                    renderRequired={false}
                    submitClicked={submitClicked}
                />
            </div>;
    };

    const CalendarContainer = ({children}: any) => {
        const el = document.getElementById('calendar-portal');

        return <Portal container={el}>
                {children}
            </Portal>;
    };

    const renderDateInput = () => {
        return <div className="add-client-report__date-input">
            <div className="add-client-report__label-date">Report Date</div>
            <DatePicker
                        popperContainer={CalendarContainer}
                        className="add-client-report__date-picker"
                        placeholderText="dd MMM yyyy"
                        dateFormat={reactDatePickerShortDateFormat}
                        dropdownMode="select"
                        selected={reportDate}
                        onChange={(date: Date) => {
                            setReportDate(date);
                        }}/>
        </div>;
    };

    const showSelectDialog = () => {
        getElementById("the-hidden-file-input").click();
    };

    const getFileTitle = (fileName: string) => {
        const lastIndex = fileName.lastIndexOf(".");
        return (lastIndex < 0) ? fileName : fileName.substring(0, fileName.lastIndexOf("."));
    };

    const handleFiles = (files: FileList | null) => {
        if (!files || files.length === 0) {
            return;
        }
        const uploadFile = new UploadFile(
            files[0],
            0,
            getFileTitle(files[0].name),
            false,
            undefined,
            undefined
        );
        setSelectedFile(uploadFile);
        setTitle(uploadFile.title);
    };

    const handleFilesSelection = (e: { target: { files: FileList | null } }) => {
        handleFiles(e.target.files);
        setSubmitClicked(false);
    };

    const removeFile = () => {
        setTitle("");
        setSelectedFile(undefined);
    };
    
    const renderFile = () => {
        return <div className="add-client-report__file-container">
            <div className="add-client-report__file-name">{selectedFile!.file.name}</div>
            <a className="add-client-report__remove-file clickable far fa-x"
                  onClick={removeFile}/>
        </div>;
    };
    
    const renderSelectFileLink = () => {
        return <a role="link" className="add-client-report__select-file clickable" onClick={showSelectDialog}>
            Select File
        </a>;
    };

    function hasInvalidDocumentFields() {
        const hasTitle = title.trim().length > 0;

        return (selectedFile && !hasTitle) || (hasTitle && !selectedFile);
    }

    const isInvalidInput = () => {
        const isMissingRequiredFields = !reportDate
            || reportId.trim().length === 0
            || reportName.trim().length === 0
            || requestId.trim().length === 0
            || !props.workspace
            || props.workspace!.id.trim().length === 0;

        return isMissingRequiredFields || hasInvalidDocumentFields();
    };

    const renderSpinner = () => {
        return submitClicked ? <LoadingSpinner/> : null;
    };

    const submit = () => {
        setSubmitClicked(true);
        if (isInvalidInput()) {
            return;
        }

        const formData = new FormData();
        if (selectedFile)
            formData.append("file", throwErrorOnNullOrUndefined(selectedFile!.file));
        if (title.trim().length > 0)
            formData.append("title", title);

        formData.append("reportDate", formatNumericDate(reportDate!));
        formData.append("reportId", reportId);
        formData.append("reportName", reportName);
        formData.append("requestId", requestId);
        formData.append("categoryId", `${props.categoryId}`);
        formData.append("workspaceId", props.workspace!.id);

        const processSuccess = () => {
            setSubmitClicked(false);
            dispatch(setHeaderNotification(
            {message: "Added Client Report", notificationType: NotificationTypes.SUCCESS},
            5000));
            dispatch(clearClientReports());
            props.setShowAddReport(false);
        };

        const processFailure  = (errorNumber: number) => {
            setSubmitClicked(false);
            dispatch(
                setHeaderNotification({
                    message: `${getTranslation(
                        "client-reporting.failed-add-report", "Failed to Add Client Report"
                    )}${getErrorSpecificClientMessage(errorNumber)}`,
                    notificationType: NotificationTypes.FAILURE
                }, 5000)
            );
            window.scrollTo(0, 0);
            if (errorNumber === ERROR_NOT_ALLOWED) removeFile();
        };

        PowerBiReportingApi.addClientReportToPlan(props.planId, formData)
            .then((response) =>
            response.data
                ? processSuccess()
                : processFailure(response.error ? response.error.errorNumber : -1))
            .catch((error: ApiError) => processFailure(error.errorNumber));
    };

    return <div className="add-client-report__container" data-testid="add-client-report__container">
            {renderSpinner()}
            <div className="add-client-report__inputs">
                {renderInputField("report-id", "PowerBI Report ID", reportId, setReportId)}
                {renderInputField("report-name", "PowerBI Report Name", reportName, setReportName)}
                {renderDateInput()}
                {renderInputField("request-id", "Request ID", requestId, setRequestId,
                    "add-client-report__request-id-container")}
            </div>
            <h3 className="powerbi-admin__optional-fields-header">Optional File Upload</h3>
            <div className="add-client-report__inputs">
                <HiddenFileInput action={handleFilesSelection} multiple={false}/>
                <div className="add-client-report__upload-file">
                    <div className="add-client-report__label-file">Upload File</div>
                    {selectedFile ? renderFile() : renderSelectFileLink()}
                </div>
                {renderInputField("title", "Document Title", title, setTitle)}
            </div>
            <div className="add-client-report__buttons">
                <RaisedButton className="add-client-report__cancel-button"
                              style={secondaryButtonStyle}
                              primary={false}
                              onClick={() => props.setShowAddReport(false)}>
                    Cancel
                </RaisedButton>
                <div className="buttons-spacer-between"/>
                <RaisedButton className="add-client-report__save-button"
                              style={prominentButtonStyle}
                              primary={true}
                              onClick={submit}
                              disabled={isInvalidInput()}
                              >
                    Add Report
                </RaisedButton>
            </div>
        </div>;
};

export default AddClientReport;