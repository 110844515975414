import * as React from "react";
import {IAssets, IFundingLevel, IPerformance, CprPortfolioApi} from "../../api/CprPortfolioApi";
import {connect} from "react-redux";
import {CprAssets} from "./CprAssets";
import {CprPerformance} from "./CprPerformance";
import {currentPlan} from "../../../mainReducerMapSelectors";
import {IPlanInfo} from "../../model/ClientsAndPlans.model";
import {Country} from "../admin/AdminAllClients";
import {hasConsultantLevelAccess} from "../../utils/sessionUtil";
import {useEffect, useState} from "react";
import {CprFundingLevel} from "./CprFundingLevel";
import {FormattedMessage} from "react-intl";
import {IApplicationRootState} from "../../../applicationState";

export const CONSULTANT_USERS_DISCLAIMER = "The Assets and Performance boxes will display to Client users only after " +
    "information is filled";

export interface IPostQuarterUpdateProps {
    currentPlan: IPlanInfo
}

export const CprPostQuarterUpdate: React.FunctionComponent<IPostQuarterUpdateProps> = (props: IPostQuarterUpdateProps) => {
    const [assets, setAssets] = useState<IAssets>();
    const [performance, setPerformance] = useState<IPerformance | undefined>(undefined);
    const [fundingLevel, setFundingLevel] = useState<IFundingLevel | undefined>(undefined);

    const fetchAssets = () => {
        CprPortfolioApi.fetchAssets().then((data) => {
            setAssets(data);
        });
    };

    const fetchPerformance = () => {
        CprPortfolioApi.fetchPerformance().then((response) => {
            setPerformance(response);
        });
    };

    const fetchFundingLevel = () => {
        CprPortfolioApi.fetchFundingLevel().then((response) => {
            setFundingLevel(response);
        });
    };

    useEffect(() => {
        fetchFundingLevel();
        if(props.currentPlan.countryId !== Country.Canada) {
            fetchAssets();
            fetchPerformance();
        }
    },[]);

    const displayPostQuarterUpdate = () => {
        if(fundingLevel) {
            return true;
        }
        if (props.currentPlan.countryId === Country.Canada){
            return false;
        }
        if (hasConsultantLevelAccess()) {
            return true;
        }
        return !!(assets || performance);
    };

    const renderFundingLevel = () => {
        return fundingLevel ? <CprFundingLevel fundingLevel={fundingLevel!} isPostQuarterUpdate={true}/> : null;
    };

    const displayAssets = () => {
        return (hasConsultantLevelAccess() || assets);
    };

    const displayPerformance = () => {
        return (hasConsultantLevelAccess() || performance);
    };

    const renderAssetsAndPerformance = () => {
        return props.currentPlan.countryId === Country.Canada
               ? null
               : <div className="post-quarter-update__assets-performance-content">
                {displayAssets() && <CprAssets assets={assets} fetchAssets={fetchAssets} isPostQuarterUpdate={true}/>}
                {displayPerformance() &&
                    <CprPerformance
                        performance={performance} fetchPerformance={fetchPerformance} isPostQuarterUpdate={true}/>}
                </div>;
    };

    const renderDisclaimer = () => {
        return hasConsultantLevelAccess() && props.currentPlan.countryId !== Country.Canada
            ? <div className="post-quarter-update__disclaimer" data-testid="post-quarter-update__disclaimer">
                {CONSULTANT_USERS_DISCLAIMER}
            </div>
            : null;
    };

    return displayPostQuarterUpdate()
        ? <div className="post-quarter-update__container" data-testid="post-quarter-update__container">
            <div className="post-quarter-update__title">
                <h2><FormattedMessage id="home.post-quarter-update-title" defaultMessage="Post Quarter Updates"/>
                </h2>
                {renderDisclaimer()}
            </div>
            <div className="post-quarter-update__content">
                {renderFundingLevel()}
                {renderAssetsAndPerformance()}
            </div>
        </div>
        : null;
};

export const mapStateToProps = (state: IApplicationRootState): IPostQuarterUpdateProps => {
    return {
        currentPlan: currentPlan(state)
    };
};

const connected = connect<IPostQuarterUpdateProps>(mapStateToProps)(CprPostQuarterUpdate);

export default connected;