import * as React from "react";
import {RaisedButton} from "./RaisedButton";
import {defaultButtonStyle} from "./buttonStyles";
import {useState} from "react";
import {throwErrorOnNullOrUndefined} from "../../utils/errorUtil";

export interface ISimpleFileUploadProps {
    title: string,
    id: string;
    uploadCallback: (id: string, file: File, value: any) => Promise<string>
}

export const SimpleFileUpload: React.FunctionComponent<ISimpleFileUploadProps> = (props) => {
    const [selectedFile, setSelectedFile] = useState<File | undefined>(undefined);
    const [value, setValue] = useState("");
    const [uploading, setUploading] = useState(false);
    const [message, setMessage] = useState("");

    const handleFileSelection = (e: { target: { files: FileList | null, value: any } }) => {
        if (e.target.files === null) {
            return;
        }

        setSelectedFile(e.target.files[0]);
        setValue(e.target.value);
    };

    const resetFileSelection = () => {
        setUploading(false);
        setSelectedFile(undefined);
        setValue("");
    };

    const handleUploadFile = () => {
        if (! selectedFile ) {
            return;
        }

        const formData = new FormData();
        formData.append("file", throwErrorOnNullOrUndefined(selectedFile), selectedFile!.name);

        setUploading(true);
        setMessage(`Uploading file`);
        props.uploadCallback(props.id, selectedFile, value)
            .then( (text) => {
                setMessage(text);
                resetFileSelection();
            }, (error) => {
                setMessage(error);
                resetFileSelection();
            });
    };

    return <div className="simple-file-upload__container">
        <h2 className={`${props.id}__title`}>{props.title}</h2>
        <div className="flex-column">
            <input
                id={props.id + "__file-input"}
                type="file"
                onChange={handleFileSelection}
                value={value}
                disabled={uploading}
                style={{width: "100%"}}
                data-testid={props.id + "__file-input"}
            />
            <RaisedButton style={defaultButtonStyle}
                          primary={false}
                          disabled={!selectedFile || uploading}
                          className={`${props.id}__upload-button`}
                          onClick={handleUploadFile}
            >
                Upload
            </RaisedButton>
            <div id={props.id + "__message"}
                 data-testid={props.id + "__message"}>
                {message}
            </div>
        </div>
    </div>;
};
