import {AxiosResponse} from "axios";
import {INewUser} from "../model/NewUser.model";
import {IUserInfo, UserInfo, UserTypeEnum} from "../model/UserInfo.model";
import {ApiBase} from "./ApiBase";
import {IApiResult} from "./AxiosWealthManagementApi";
import {ClientUser} from "../model/ClientUser.model";
import {getConfig} from "../utils/envUtil";

export interface INewUserResponse {
    firstName: string;
    lastName: string;
}

export interface IUploadUsersAndPlansResponse {
    isSuccess: boolean;
    messages: string[];
}

export interface IUserOktaInfo {
    status: string,
    groups: string,
    isMfa: boolean
}

export class UserApi {
    public static requestUsers(): Promise<IApiResult<IUserInfo[]>> {
        return ApiBase.processGet("/users", (response: AxiosResponse) => {
            return response.data.map((t: any) => {
                return new UserInfo(t.firstName,
                    t.lastName,
                    t.email,
                    t.lastLoginDate,
                    t.userType,
                    t.arbtSecurityId,
                    t.planIds);
            });
        });
    }

    public static updateUser(arbtId: string, userType: UserTypeEnum, planIds: number[], isMfa: boolean): Promise<void> {
       return ApiBase.processPatch(`/users/${arbtId}`, {userType, planIds, isMfa})
           .then(() => Promise.resolve());
    }

    public static getUserOktaInfo(securityId: string): Promise<IUserOktaInfo> {
        return ApiBase.processGetUnwrapped(`/okta/users/${securityId}`,
            (response: AxiosResponse): any => {
                return response.data as IUserOktaInfo;
            });
    }

    public static addUser(user: INewUser): Promise<IApiResult<INewUserResponse>> {
        return ApiBase.processPostWithResponseBody("/users", user,
            (axiosResponse) => ({
                    firstName: axiosResponse.data.firstName,
                    lastName: axiosResponse.data.lastName,
                }),
        );
    }

    public static getUsersForMyPlansForAdmin(): Promise<IApiResult<IUserInfo[]>> {
        return ApiBase.processGet("/users?in_loggedin_user_plans=true", (response: AxiosResponse) => {
            return response.data.map((t: any) => {
                return new UserInfo(t.firstName,
                    t.lastName,
                    t.email,
                    t.lastLoginDate,
                    t.userType,
                    t.arbtSecurityId,
                    t.planIds);
            });
        });
    }

    public static requestUsersInSessionPlansForClient(): Promise<ClientUser[]> {
        return ApiBase.processGetUnwrapped("/users?in_loggedin_user_plans=true",
            (response: AxiosResponse) => response.data);
    }

    public static sendRegistrationEmail(arbtSecurityId: string): Promise<void> {
        return ApiBase.processGetWithoutResponseBody(`/users/${arbtSecurityId}/resend-activation-email`);
    }

    public static sendResetPasswordEmail(arbtSecurityId: string): Promise<IApiResult<boolean>> {
        return ApiBase.processPost(`/users/${arbtSecurityId}/reset-password`, null);
    }

    public static removeUser(arbtSecurityId: string): Promise<IApiResult<boolean>> {
        return ApiBase.processDelete(`/users/${arbtSecurityId}`);
    }

    public static removeUserFromPlan(arbtSecurityId: string, planId: number): Promise<IApiResult<boolean>> {
        return ApiBase.processDelete(`/users/${arbtSecurityId}/plans/${planId}`);
    }

    public static resetMfaFactors(arbtSecurityId: string): Promise<IApiResult<boolean>> {
        return ApiBase.processPost(`/users/${arbtSecurityId}/reset-mfa-factors`, null);
    }

    public static downloadUsersAndPlans() {
        const baseURL = getConfig().FRONTEND_CONFIG_BACKEND_URL;

        const url = `${baseURL}/download/admin-exports/users-and-plans.xlsx`;

        window.open(url, "_blank");
    }

    static downloadMassAssignTemplate() {
        const baseURL = getConfig().FRONTEND_CONFIG_BACKEND_URL;

        const url = `${baseURL}/download/admin-templates/user-plan-mass-assignment-template.xlsx`;

        window.open(url, "_blank");
    }

    static async uploadUsersAndPlans(file: File): Promise<IApiResult<IUploadUsersAndPlansResponse>> {
        const formData = new FormData();
        formData.set("file", file);

        return ApiBase.processPostWithResponseBody("/users/mass-assign-users-to-plans", formData, (response: AxiosResponse) => {
            return {isSuccess: response.data.isSuccess, messages: response.data.messages};
        });
    }
}
