import * as React from "react";
import {mapQuestionIdToResponseSummary} from "../OddIqUtils";
import {
    IOddResponse,
    IOddSectionsQuestions,
    IOddResponsePercentSummary,
    IOddResponseSummary
} from "../../../api/OddIqApi";
import {compareInsensitive} from "../../../utils/listUtil";
import {OrderedMap} from "immutable";
import {useDispatch} from "react-redux";
import {setCurrentOddQuestionId} from "../OddIqActions";

export interface IOddTopRisksProps {
    oddProductQuestionResponse: IOddResponse[];
    oddSectionsQuestions: IOddSectionsQuestions;
}

export const OddTopRisks: React.FunctionComponent<IOddTopRisksProps> = (props) => {
    const summariesWithExceptions = mapQuestionIdToResponseSummary(
        props.oddSectionsQuestions.questions,
        props.oddProductQuestionResponse)
        .filter((summary) => summary!.exceptions > 0);

    const byTopRisk = (item1: IOddResponsePercentSummary, item2: IOddResponsePercentSummary) => {
        const exceptionCompare = (item2.exceptions - item1.exceptions);
        return exceptionCompare === 0 ? compareInsensitive(item1.name!, item2.name!) : exceptionCompare;
    };

    const byLowRisk = (item1: IOddResponsePercentSummary, item2: IOddResponsePercentSummary) => {
        const exceptionCompare = (item1.exceptions - item2.exceptions);
        return exceptionCompare === 0 ? compareInsensitive(item1.name!, item2.name!) : exceptionCompare;
    };


    const getTopRiskQuestions = (): OrderedMap<number, IOddResponseSummary> => {
        return  summariesWithExceptions
            .sort(byTopRisk)
            .take(5)
            .toOrderedMap();
    };

    const getTopUniqueQuestions = (): OrderedMap<number, IOddResponseSummary> => {
        return summariesWithExceptions
            .sort(byLowRisk)
            .take(5)
            .toOrderedMap();
    };

    const dispatch = useDispatch();

    const setCurrentQuestion = (questionId: number) => () => {
        dispatch(setCurrentOddQuestionId(questionId));
    };
    const renderRiskQuestion = (className: string) => (value: [number, IOddResponseSummary], index: number) => {
        return <tr key={`${className}-${index}`} className={className}>
            <td className={`clickable ${className}-name`} onClick={setCurrentQuestion(value[0])}>{value[1].name}</td>
            <td className={`${className}-count`}>{value[1].exceptions}</td>
        </tr>;
    };

    return <div className="odd-page__odd-top-risks">
        <div className="odd-top-risks__header-container">
            <h3>Top Portfolio Risks</h3>
        </div>
        <div className="odd-top-risks__container">
            <div className="odd-top-risks__questions-container">
                <h5>Top 5 Most Common Risks</h5>
                <div className="odd-top-risks__questions">
                    <table>
                        <thead>
                            <tr>
                                <th className="column-header">Risk</th>
                                <th className="column-header">Strategies</th>
                            </tr>
                        </thead>
                        <tbody>
                            {getTopRiskQuestions().entrySeq().map(renderRiskQuestion("odd-top-risks__question"))}
                        </tbody>
                    </table>
                </div>
            </div>
            <div className="odd-top-risks__separator" />
            <div className="odd-top-risks__unique-risks-container">
                <h5>Top 5 Unique Risks</h5>
                <div className="odd-unique-risks__questions">
                    <table>
                        <thead>
                            <tr>
                                <th className="column-header">Risk</th>
                                <th className="column-header">Strategies</th>
                            </tr>
                        </thead>
                        <tbody>
                            {getTopUniqueQuestions().entrySeq().map(renderRiskQuestion("odd-unique-risks__question"))}
                        </tbody>
                    </table>
                </div>
            </div>
        </div>
    </div>;
};