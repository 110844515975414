import * as React from "react";
import {IStrategyUpdate} from "../../../model/IStrategyUpdate.model";
import {getScrollOffset} from "../../../utils/browserUtil";
import StrategyUpdateRow from "./StrategyUpdateRow.component";
import {getConfigPageSize} from "../../../utils/commonUtil";

interface IStrategyUpdatesPagePropsFromParent {
    updates: IStrategyUpdate[];
}

interface IStrategyUpdatesPaginatorState {
    pageNumber: number;
}

export type StrategyUpdatesPaginatorProps = IStrategyUpdatesPagePropsFromParent;

export class StrategyUpdatesPaginator
    extends React.Component<StrategyUpdatesPaginatorProps, IStrategyUpdatesPaginatorState> {

    constructor(props: any) {
        super(props);

        this.state = {
            pageNumber: 1,
        };
    }

    public componentDidMount() {
        window.addEventListener("scroll", this.onScroll, false);
    }

    public componentWillUnmount() {
        window.removeEventListener("scroll", this.onScroll, false);
    }

    public componentDidUpdate(prevProps: any) {
        if (prevProps !== this.props) {
            this.setState({
                pageNumber: 1,
            });
        }
    }

    public render() {
        return <div>
            {this.renderUpdateRows()}
        </div>;
    }

    private onScroll = () => {
        if (this.shouldLoadMore() && this.isScrollingDown()) {
            this.addPage();
        }
    };

    // noinspection JSMethodCanBeStatic
    private isScrollingDown() {
        return (window.innerHeight + getScrollOffset()) >= (document.body.offsetHeight - 500);
    }

    private shouldLoadMore() {
        return !this.isShowingAllPages();
    }

    private isShowingAllPages() {
        return this.state.pageNumber >= this.pageCount();
    }

    private addPage = () => {
        const pageNumber = this.state.pageNumber + 1;
        this.setState({pageNumber});
    };

    private pageCount = () => {
        return Math.floor((this.props.updates.length - 1) / getConfigPageSize()) + 1;
    };

    private renderUpdateRows() {
        const visibleCount = Math.min(this.state.pageNumber * getConfigPageSize(), this.props.updates.length);

        return this.props.updates.slice(0, visibleCount)
            .map((update, index) => {
                return <StrategyUpdateRow
                    key={update.backstopId}
                    update={update}
                    isLast={index === visibleCount - 1}/>;
            });
    }
}
