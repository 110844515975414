import {Action, Dispatch} from "redux";
import {ThunkAction} from "redux-thunk";
import {IApiResult} from "../../api/AxiosWealthManagementApi";
import PlanApi from "../../api/PlanApi";
import {ClientUser} from "../../model/ClientUser.model";
import {SharedDocument, SharedDocumentType} from "../../model/SharedDocument.model";
import {UserApi} from "../../api/UserApi";
import {SharedDocumentApi} from "../../api/SharedDocumentApi";
import {dispatchHeaderNotificationActions} from "../base/header/HeaderActions";
import {INotificationObject, NotificationTypes} from "../base/header/HeaderReducer";

export class SharedDocumentsActionTypes {
    public static RESOLVE_DOCUMENT_TYPES = "RESOLVE_DOCUMENT_TYPES";
    public static CLEAR_FILE_UPLOAD_FAILURE = "CLEAR_FILE_UPLOAD_FAILURE";
    public static FILE_UPDATE_SUCCESS = "FILE_UPDATE_SUCCESS";
    public static CLIENT_USERS_SUCCEEDED = "CLIENT_USERS_SUCCEEDED";
    public static CLIENT_USERS_FAILED = "CLIENT_USERS_FAILED";
    public static CLIENT_USERS_REQUESTED = "CLIENT_USERS_REQUESTED";
    public static USERS_FOR_SESSION_PLANS_REQUESTED = "USERS_FOR_SESSION_PLANS_REQUESTED";
    public static USERS_FOR_SESSION_PLANS_SUCCEEDED = "USERS_FOR_SESSION_PLANS_SUCCEEDED";
    public static USERS_FOR_SESSION_PLANS_FAILED = "USERS_FOR_SESSION_PLANS_FAILED";
    public static SHARED_DOCUMENTS_REQUESTED = "SHARED_DOCUMENTS_REQUESTED";
    public static SHARED_DOCUMENTS_SUCCEEDED = "SHARED_DOCUMENTS_SUCCEEDED";
    public static SHARED_DOCUMENTS_FAILED = "SHARED_DOCUMENTS_FAILED";
    public static CLEAR_SHARED_DOCUMENTS = "CLEAR_SHARED_DOCUMENTS";
}

const createSetDocumentTypesAction = (payload: IApiResult<SharedDocumentType[]>) => {
    return {
        type: SharedDocumentsActionTypes.RESOLVE_DOCUMENT_TYPES,
        payload,
    };
};

const requestDocumentTypes = (): ThunkAction<Promise<void>, void, null, Action<string>> => {
    return (dispatch: Dispatch<Action<{}>>) => {
        return SharedDocumentApi.requestSharedDocumentTypes()
            .then((response) => {
                dispatch(createSetDocumentTypesAction(response));
            });
    };
};

const notifyFileUploadComplete = (success: boolean, message: string):
    ThunkAction<void, {}, null, Action<string>> => {
    return (dispatch) => {
        const notification: INotificationObject = {
            notificationType: success ? NotificationTypes.SUCCESS : NotificationTypes.FAILURE,
            message,
        };

        dispatchHeaderNotificationActions(notification, 5000)(dispatch);

        clearSharedDocuments(dispatch);
    };
};

const clearFileUploadFailure = (): ThunkAction<void, void, null, Action<string>> => {
    return (dispatch) => {
        dispatch({type: SharedDocumentsActionTypes.CLEAR_FILE_UPLOAD_FAILURE});
    };
};

const notifyFileUpdateSuccess = (): ThunkAction<void, {}, null, Action<string>> => {
    return (dispatch) => {
        const notification: INotificationObject = {
            notificationType: NotificationTypes.SUCCESS,
            message: "File successfully saved",
        };

        dispatchHeaderNotificationActions(notification, 5000)(dispatch);

        clearSharedDocuments(dispatch);
    };
};

export const requestClientUsers = () =>
    (dispatch: Dispatch<Action<string>>) => {
        dispatch({type: SharedDocumentsActionTypes.CLIENT_USERS_REQUESTED});

        PlanApi.requestClientUsers().then((clientUsers: ClientUser[]) => {
            dispatch({type: SharedDocumentsActionTypes.CLIENT_USERS_SUCCEEDED, payload: clientUsers});
        }).catch((failure: any) => {
            dispatch({type: SharedDocumentsActionTypes.CLIENT_USERS_FAILED, payload: failure});
        });
};

export const requestUsersInSessionPlans = () => (dispatch: Dispatch<Action<string>>) => {
    dispatch({type: SharedDocumentsActionTypes.USERS_FOR_SESSION_PLANS_REQUESTED});

    UserApi.requestUsersInSessionPlansForClient().then((usersInSessionPlans: ClientUser[]) => {
        dispatch({type: SharedDocumentsActionTypes.USERS_FOR_SESSION_PLANS_SUCCEEDED, payload: usersInSessionPlans});
    }).catch((failure: any) => {
        dispatch({type: SharedDocumentsActionTypes.USERS_FOR_SESSION_PLANS_FAILED, payload: failure});
    });
};

export const requestAllSharedDocuments = (dispatch: Dispatch<Action<string>>) => {
    dispatch({type: SharedDocumentsActionTypes.SHARED_DOCUMENTS_REQUESTED});

    SharedDocumentApi.requestSharedDocuments().then((documents: IApiResult<SharedDocument[]>) => {
        dispatch({
            type: SharedDocumentsActionTypes.SHARED_DOCUMENTS_SUCCEEDED,
            payload: documents.data,
        });
    }).catch((error) => {
        dispatch({
            type: SharedDocumentsActionTypes.SHARED_DOCUMENTS_FAILED,
            payload: error,
        });
    });
};

export const deleteSharedDocumentFile = (documentId: number) =>
    (dispatch: Dispatch<Action>) => {
        SharedDocumentApi.deleteSharedDocument(documentId).then(() => {
            clearSharedDocuments(dispatch);
        });
};


export const clearSharedDocuments = (dispatch: Dispatch<Action>) => {
    dispatch({type: SharedDocumentsActionTypes.CLEAR_SHARED_DOCUMENTS});
};

export default {
    requestDocumentTypes,
    notifyFileUploadComplete,
    clearFileUploadFailure,
    notifyFileUpdateSuccess,
    requestClientUsers,
};
