import {
    IInformSubComponent,
    IInformSubComponentSummary,
    InformSubComponentSummary,
    InformSubcomponentType
} from "./Inform";
import {IInformSubComponentDetails, InformSubComponentDetails} from "./InformSubComponentDetails";

export interface IInformESG extends IInformSubComponent {
    backstopId: number,
    strategyName: string,
    ifaRating: string,
    ifaRatingEffectiveDate: string,
    modules: IInformESGModule[]
}

export interface IInformESGSummary {
    ifaRating: string,
    ifaRatingValue: string,
    ifaRatingChange: string,
    ifaRatingEffectiveDate: string,
    acceptables: string,
    flagged: string,
    overAllDataCompletion: string
}

export interface IInformESGModule {
    name: string,
    module: IInformESGModuleValue
}

export interface IInformESGModuleValue {
    rating: IInformESGRating,
    questions: IInformESGQuestion[]
}

export interface IInformESGRating {
    acceptable: string,
    flagged: string,
    noData: string,
}

export enum ESGQuestionOutcome {
    PASS = "Pass",
    ALERT = "Alert",
    NOT_ASSESSED = "Not Assessed"
}

export interface IInformESGQuestion {
    level: string,
    questionName: string,
    response: string,
    outcome: ESGQuestionOutcome
}

export class InformESG implements IInformESG {
    public static builder(): InformESGBuilder {
        return new InformESGBuilder();
    }

    constructor(
        public backstopId: number,
        public strategyName: string,
        public ifaRating: string,
        public ifaRatingEffectiveDate: string,
        public summary: IInformSubComponentSummary,
        public details: IInformSubComponentDetails,
        public modules: IInformESGModule[],
        public type: InformSubcomponentType
    ){}
}

class InformESGBuilder {
    private mBackstopId: number;
    private mStrategyName: string;
    private mIfaRating: string;
    private mIfaRatingEffectiveDate: string;
    private mSummary: IInformSubComponentSummary;
    private mDetails: IInformSubComponentDetails;
    private mModules: IInformESGModule[];

    public backstopId(value: number) {
        this.mBackstopId = value;
        return this;
    }

    public strategyName(value: string) {
        this.mStrategyName = value;
        return this;
    }

    public ifaRating(value: string) {
        this.mIfaRating = value;
        return this;
    }

    public ifaRatingEffectiveDate(value: string) {
        this.mIfaRatingEffectiveDate = value;
        return this;
    }

    public summary(summary: IInformSubComponentSummary) {
        this.mSummary = summary;
        return this;
    }

    public details(details: IInformSubComponentDetails) {
        this.mDetails = details;
        return this;
    }

    public modules(modules: IInformESGModule[]) {
        this.mModules = modules;
        return this;
    }

    public build(): InformESG {
        return new InformESG(
            this.mBackstopId || 1,
            this.mStrategyName || "fund-name",
            this.mIfaRating || "Poor",
            this.mIfaRatingEffectiveDate || "20/12/2023",
            this.mSummary || InformSubComponentSummary.builder().build(),
            this.mDetails || InformSubComponentDetails.builder().build(),
            this.mModules || [InformESGModule.builder().build()],
            InformSubcomponentType.ESG
        );
    }
}

export class InformESGSummary implements IInformESGSummary{
    public static builder(): InformESGSummaryBuilder {
        return new InformESGSummaryBuilder();
    }

    constructor(
        public ifaRating: string,
        public ifaRatingValue: string,
        public ifaRatingChange: string,
        public ifaRatingEffectiveDate: string,
        public acceptables: string,
        public flagged: string,
        public overAllDataCompletion: string
    ) {
    }
}

class InformESGSummaryBuilder {
    private mIfaRating: string;
    private mIfaRatingValue: string;
    private mIfaRatingChange: string;
    private mIfaRatingEffectiveDate: string;
    private mAcceptables: string;
    private mFlagged: string;
    private mOverAllDataCompletion: string;

    public ifaRating(value: string) {
        this.mIfaRating = value;
        return this;
    }
    public ifaRatingValue(value: string) {
        this.mIfaRatingValue = value;
        return this;
    }
    public ifaRatingChange(value: string) {
        this.mIfaRatingChange = value;
        return this;
    }
    public ifaRatingEffectiveDate(value: string) {
        this.mIfaRatingEffectiveDate = value;
        return this;
    }
    public acceptables(value: string) {
        this.mAcceptables = value;
        return this;
    }
    public flagged(value: string) {
        this.mFlagged = value;
        return this;
    }
    public overAllDataCompletion(value: string) {
        this.mOverAllDataCompletion = value;
        return this;
    }

    public build(): InformESGSummary {
        return new InformESGSummary(
            this.mIfaRating || "OK",
        this.mIfaRatingValue || "3",
            this.mIfaRatingChange || "No Change",
            this.mIfaRatingEffectiveDate || "30/09/2023",
            this.mAcceptables || "15",
            this.mFlagged || "0",
            this.mOverAllDataCompletion || "81.395%"
        );
    }
}

export class InformESGModule implements IInformESGModule {
    public static builder(): InformESGModuleBuilder {
        return new InformESGModuleBuilder();
    }

    constructor(
        public name: string,
        public module: InformESGModuleValue
    ) {}
}

class InformESGModuleBuilder {
    private mName: string;
    private mModule: InformESGModuleValue;

    public name(value: string) {
        this.mName = value;
        return this;
    }

    public module(value: InformESGModuleValue) {
        this.mModule = value;
        return this;
    }

    public build(): InformESGModule {
        return  new InformESGModule(
            this.mName || "policies",
            this.mModule
        );
    }
}

export class InformESGModuleValue implements IInformESGModuleValue {
    public static builder(): InformESGModuleValueBuilder {
        return new InformESGModuleValueBuilder();
    }

    constructor(
        public rating: IInformESGRating,
        public questions: IInformESGQuestion[]
    ) {}
}

class InformESGModuleValueBuilder {
    private mRating: IInformESGRating;
    private mQuestions: IInformESGQuestion[];

    public rating(value: IInformESGRating) {
        this.mRating = value;
        return this;
    }

    public questions(value: IInformESGQuestion[]) {
        this.mQuestions = value;
        return this;
    }

    public build(): InformESGModuleValue {
        return  new InformESGModuleValue(
            this.mRating,
            this.mQuestions
        );
    }
}

export class InformESGRating implements IInformESGRating {
    public static builder(): InformESGRatingBuilder {
        return new InformESGRatingBuilder();
    }

    constructor(
        public acceptable: string,
        public flagged: string,
        public noData: string,
    ) {}
}

class InformESGRatingBuilder {
    private mAcceptable: string;
    private mFlagged: string;
    private mNoData: string;

    public acceptable(value: string) {
        this.mAcceptable = value;
        return this;
    }

    public flagged(value: string) {
        this.mFlagged = value;
        return this;
    }

    public noData(value: string) {
        this.mNoData = value;
        return this;
    }

    public build(): InformESGRating {
        return new InformESGRating(
            this.mAcceptable || "3",
            this.mFlagged || "0",
            this.mNoData || "0"
        );
    }
}

export class InformESGQuestion implements IInformESGQuestion {
    public static builder(): InformESGQuestionBuilder {
        return new InformESGQuestionBuilder();
    }

    constructor(
        public level: string,
        public questionName: string,
        public response: string,
        public outcome: ESGQuestionOutcome
    ) {}
}

class InformESGQuestionBuilder {
    private mLevel: string;
    private mQuestionName: string;
    private mResponse: string;
    private mOutcome: ESGQuestionOutcome;

    public level(value: string) {
        this.mLevel = value;
        return this;
    }

    public questionName(value: string) {
        this.mQuestionName = value;
        return this;
    }

    public response(value: string) {
        this.mResponse = value;
        return this;
    }

    public outcome(value: ESGQuestionOutcome) {
        this.mOutcome = value;
        return this;
    }

    public build(): InformESGQuestion {
        return new InformESGQuestion(
            this.mLevel || "Firm",
            this.mQuestionName || "question name",
            this.mResponse || "Yes",
            this.mOutcome || ESGQuestionOutcome.PASS
        );
    }
}