import {LOCATION_CHANGE} from "redux-first-history";
import {Action, handleActions} from "redux-actions";
import {IPlanInfo} from "../../../model/ClientsAndPlans.model";
import {throwErrorOnNullOrUndefined} from "../../../utils/errorUtil";
import {
    NavigationTab,
    OddPortfolioMenu,
    ResearchMenu,
    SharedDocumentsMenu,
    ThoughtLeadershipMenu,
} from "../../../utils/navigationUtils";
import {updateCurrentPlan} from "../../../utils/sessionUtil";
import {HeaderActions} from "./HeaderActions";

export enum NotificationTypes {
    SUCCESS = "SUCCESS",
    FAILURE = "FAILURE",
}

export interface INotificationObject {
    message: string;
    notificationType: NotificationTypes;
}

export interface IHeaderReducerState {
    hasResearchAccess?: boolean | undefined;
    showUsernameMenu: boolean;
    currentNavigationTab?: NavigationTab;
    notificationObject?: INotificationObject;
}

export const initialState: IHeaderReducerState = {
    showUsernameMenu: false,
    notificationObject: undefined,
};

const reducerMap = {
    [HeaderActions.SET_MENU_VISIBILITY]: (state: IHeaderReducerState,
                                          action: Action<boolean>): IHeaderReducerState => {
        return {...state, showUsernameMenu: throwErrorOnNullOrUndefined(action.payload)};
    },
    [HeaderActions.SET_CURRENT_PLAN]: (state: IHeaderReducerState,
                                       action: Action<IPlanInfo>): IHeaderReducerState => {
        updateCurrentPlan(throwErrorOnNullOrUndefined(action.payload));
        window.location.reload();
        return {...state};
    },
    [HeaderActions.SET_CURRENT_NAVIGATION_TAB]: (state: IHeaderReducerState,
                                                 action: Action<NavigationTab>): IHeaderReducerState => {
        return {...state, currentNavigationTab: action.payload!};
    },
    [LOCATION_CHANGE]: (state: IHeaderReducerState,
                        action: Action<{location: { pathname: string }}>): IHeaderReducerState => {
        return {
            ...state,
            currentNavigationTab: getNavigationTabFromPathName(action.payload!.location.pathname),
            showUsernameMenu: false,
        };
    },
    [HeaderActions.SET_HEADER_NOTIFICATION]: (state: IHeaderReducerState,
                                              action: Action<INotificationObject>): IHeaderReducerState => {
        return {...state, notificationObject: action.payload};
    },
    [HeaderActions.CLEAR_HEADER_NOTIFICATION]: (state: IHeaderReducerState): IHeaderReducerState => {
        return {...state, notificationObject: undefined};
    },
};

function routeIsInPathList(route: string, pathList: string[]): boolean {
    return pathList.some((path) => route.startsWith(path));
}

function getNavigationTabFromPathName(pathName: string): NavigationTab | undefined {
    const route = removeFirstSlash(pathName);

    if (routeIsInPathList(route, ResearchMenu.paths)) {
        return NavigationTab.MANAGER_RESEARCH;
    }

    if (routeIsInPathList(route, OddPortfolioMenu.paths)) {
        return NavigationTab.ODD_IQ;
    }

    if (routeIsInPathList(route, SharedDocumentsMenu.paths)) {
        return NavigationTab.SHARED_DOCUMENTS;
    }

    if (routeIsInPathList(route, ThoughtLeadershipMenu.paths)) {
        return NavigationTab.INSIGHTS;
    }

    return undefined;
}

function removeFirstSlash(pathName: string) {
    return pathName.replace(/\//, "");
}

type HeaderTypes = string | boolean | {location: { pathname: string }} | INotificationObject | IPlanInfo;

export default handleActions<IHeaderReducerState, HeaderTypes>(reducerMap, initialState);
