import * as React from "react";
import {
    IOddResponse,
    IOddSectionsQuestions,
    IOddResponsePercentSummary,
    IOddResponseSummary,
    OddLevelId
} from "../../../api/OddIqApi";
import {
    generatePercentagesFromResponseSummary,
    getLevelPercentSummary,
    getSummaryFromResponseList,
    mapQuestionIdToResponseSummary
} from "../OddIqUtils";
import {PotentialRiskAreasCard} from "./PotentialRiskAreasCard";
import {Map as ImmutableMap} from "immutable";

export interface IPotentialRiskAreasDataProps {
    responsesForProduct: IOddResponse[]
    oddSectionsQuestions: IOddSectionsQuestions;
}

export const PotentialRiskAreas: React.FunctionComponent<IPotentialRiskAreasDataProps> = (props) => {
    const totalCounts: IOddResponseSummary = getSummaryFromResponseList(props.responsesForProduct);
    const totalPercents: IOddResponsePercentSummary = generatePercentagesFromResponseSummary(totalCounts);

    const sectionIdToSummaryMap: ImmutableMap<number, IOddResponseSummary> =
        mapQuestionIdToResponseSummary(props.oddSectionsQuestions.questions, props.responsesForProduct);

    const pctOfTotalStrategy: IOddResponsePercentSummary =
        getLevelPercentSummary(sectionIdToSummaryMap, props.oddSectionsQuestions, OddLevelId.Strategy);

    const pctOfTotalManager: IOddResponsePercentSummary = {
        exceptions: totalPercents.exceptions - pctOfTotalStrategy.exceptions,
        bestPractice: totalPercents.bestPractice - pctOfTotalStrategy.bestPractice,
        noData: totalPercents.noData - pctOfTotalStrategy.noData,
    };

    return <div className="potential-risk-areas-card-container" data-testid="potential-risk-areas">
        <PotentialRiskAreasCard title="Exceptions"
                                total={totalCounts.exceptions}
                                totalPercent={totalPercents.exceptions.toFixed(0)}
                                pctOfTotalManager={pctOfTotalManager.exceptions.toFixed(0)}
                                pctOfTotalStrategy={pctOfTotalStrategy.exceptions.toFixed(0)} />
        <PotentialRiskAreasCard title="Best Practice"
                                total={totalCounts.bestPractice}
                                totalPercent={totalPercents.bestPractice.toFixed(0)}
                                pctOfTotalManager={pctOfTotalManager.bestPractice.toFixed(0)}
                                pctOfTotalStrategy={pctOfTotalStrategy.bestPractice.toFixed(0)} />
        <PotentialRiskAreasCard title="No Data"
                                total={totalCounts.noData}
                                totalPercent={totalPercents.noData.toFixed(0)}
                                pctOfTotalManager={pctOfTotalManager.noData.toFixed(0)}
                                pctOfTotalStrategy={pctOfTotalStrategy.noData.toFixed(0)} />
    </div>;
};
