import * as React from "react";

export interface IHomePageMessageNotificationProps {
    message: string;
}

export const HomePageMessageNotification = (props: IHomePageMessageNotificationProps) => {
    return <div className="home-page-notification-container" data-testid="home-page-notification-container">
        <div className="home-page-notification">
            {props.message}
        </div>
    </div>;
};
