import * as React from "react";
import {useEffect, useState} from "react";
import {IMarketOutlookDocument} from "../../model/thought-leadership/MarketOutlookDocument.model";
import {getSuccessData, REQUEST_STATES, RequestState} from "../common/commonStates";
import {
    capitalMarketAssumptionsSelector,
    marketBackgroundsSelector,
    mediumTermViewsSelector,
    planLanguageId,
    quarterlyInvestmentOutlookSelector
} from "../../../mainReducerMapSelectors";
import {multipleApiRequesterWrapper} from "../common/MultipleApiRequesterWrapper";
import {
    capitalMarketAssumptionsRequestPair,
    marketBackgroundsRequestPair,
    mediumTermViewsRequestPair,
    quarterlyInvestmentOutlookRequestPair
} from "../common/RequesterPairs";
import {connect} from "react-redux";
import {LoadingSpinner} from "../icons/LoadingSpinner.component";
import {byDate} from "../../utils/dateUtil";
import {InViewReportsApi} from "../../api/InViewReportsApi";
import {ErrorComponent} from "../base/Error.component";
import {CapitalMarketAssumption} from "../../model/thought-leadership/CapitalMarketAssumption";
import {IMediumTermView} from "../../model/thought-leadership/MediumTermView.model";
import {Map} from "immutable";
import {DocumentType, MarketOutlook} from "../../utils/marketOutlookUtils";
import {LANG, LANG_NAME} from "../common/intlUtils";
import {IApplicationRootState} from "../../../applicationState";

export interface ILatestMarketOutlookFromLocation {
    match: {
        params: {
            documentType: string;
        };
    };
}
export interface ILatestMarketOutlookFromStore {
    capitalMarketAssumptionsState: RequestState<Map<number, CapitalMarketAssumption>>;
    mediumTermViewsState: RequestState<IMediumTermView[]>;
    quarterlyInvestmentOutlookState: RequestState<IMarketOutlookDocument[]>
    marketBackgroundsState: RequestState<IMarketOutlookDocument[]>;
    planLanguageId: number;
}

export type ILatestMarketOutlookProps = ILatestMarketOutlookFromLocation &
    ILatestMarketOutlookFromStore;

export const LatestMarketOutlookPage: React.FunctionComponent<ILatestMarketOutlookProps> = (props) => {
    const [documentsState, setDocumentsState] = useState(REQUEST_STATES.REQUESTED);
    const [downloadRequested, setDownloadRequested] = useState(false);
    const [noDocuments, setNoDocuments] = useState(false);
    
    const getDocumentsSuccessData = (type: string): MarketOutlook[] => {
        switch (type) {
            case ("MB"):
                setDocumentsState(props.marketBackgroundsState.kind);
                return props.marketBackgroundsState.kind === REQUEST_STATES.REQUEST_SUCCEEDED
                    ? getSuccessData(props.marketBackgroundsState)!
                    : [];
            case("CMA"):
                setDocumentsState(props.capitalMarketAssumptionsState.kind);
                return props.capitalMarketAssumptionsState.kind === REQUEST_STATES.REQUEST_SUCCEEDED
                    ? getSuccessData(props.capitalMarketAssumptionsState)!.valueSeq().toArray()
                    : [];
            case ("MTV"):
                setDocumentsState(props.mediumTermViewsState.kind);
                return props.mediumTermViewsState.kind === REQUEST_STATES.REQUEST_SUCCEEDED
                    ? getSuccessData(props.mediumTermViewsState)!
                    : [];
            case ("QIO"):
                setDocumentsState(props.quarterlyInvestmentOutlookState.kind);
                return props.quarterlyInvestmentOutlookState.kind === REQUEST_STATES.REQUEST_SUCCEEDED
                    ? getSuccessData(props.quarterlyInvestmentOutlookState)!
                    : [];
            default:
                setDocumentsState(REQUEST_STATES.REQUEST_FAILED);
                return [];
        }
    };

    const typeStringToEnum = (type: string): DocumentType => {
       return DocumentType[type as keyof typeof DocumentType];
    };
    
    useEffect(() => {
        let reports = getDocumentsSuccessData(props.match.params.documentType);
        if(reports.length === 0) {
            setNoDocuments(true);
            return;
        }
        if (!downloadRequested) {
            if(props.match.params.documentType === "MB") {
                reports = reports.filter((report: IMarketOutlookDocument) =>
                    report.activityTag.indexOf(props.planLanguageId === LANG.FRENCH.valueOf()
                        ? LANG_NAME.FRENCH : LANG_NAME.ENGLISH) > -1);
            }
            const doc = reports.sort(byDate)[0];
            setDownloadRequested(true);
            InViewReportsApi.downloadReport(doc.backstopId, doc.fileName, false);
        }

    }, []);

    return documentsState === REQUEST_STATES.REQUEST_FAILED
        ? <ErrorComponent/>
        : (documentsState === REQUEST_STATES.REQUEST_SUCCEEDED && noDocuments
            ? <div className="market-background__no-reports" data-testid="market-background__no-reports">
                {`There are no ${typeStringToEnum(props.match.params.documentType)} documents available.
                    Please see the Insights tab for a list of all of Aon's White Papers and Market Outlooks`}
            </div>
            : <LoadingSpinner/>);
};

export const mapStateToProps = (state: IApplicationRootState): ILatestMarketOutlookFromStore => {
    return {
        marketBackgroundsState: marketBackgroundsSelector(state),
        capitalMarketAssumptionsState: capitalMarketAssumptionsSelector(state),
        mediumTermViewsState: mediumTermViewsSelector(state),
        quarterlyInvestmentOutlookState: quarterlyInvestmentOutlookSelector(state),
        planLanguageId: planLanguageId(state),
    };
};

const connected = connect(mapStateToProps)(LatestMarketOutlookPage);

export default multipleApiRequesterWrapper(
    connected,
    [
        marketBackgroundsRequestPair,
        capitalMarketAssumptionsRequestPair,
        mediumTermViewsRequestPair,
        quarterlyInvestmentOutlookRequestPair,
    ]
);
