import {Map} from "immutable";
import * as React from "react";
import {connect} from "react-redux";
import {Link} from "react-router-dom";
import {AnyAction, bindActionCreators, Dispatch} from "redux";
import {
    allManagersSelector,
    portfolioProductSummariesSelector,
    productUniverseSummariesSelector,
} from "../../../../mainReducerMapSelectors";
import {IStrategyUpdate, StrategyUpdateType} from "../../../model/IStrategyUpdate.model";
import {ManagerResponse} from "../../../model/manager/ManagerResponse.model";
import {ProductSummary} from "../../../model/product/ProductSummary";
import {formatShortDate} from "../../../utils/dateUtil";
import {sortCaseInsensitive} from "../../../utils/listUtil";
import {getSuccessData} from "../../common/commonStates";
import {allManagersRequestPair, allProductsRequestPair} from "../../common/RequesterPairs";
import {flashReportActions} from "../../flash-report/flashReportActions";
import meetingNoteActions from "../../meeting-note/meetingNoteActions";
import {multipleApiRequesterWrapper} from "../../common/MultipleApiRequesterWrapper";
import {IApplicationRootState} from "../../../../applicationState";

export interface IStrategyUpdateRowPropsFromActions {
    actions: {
        getFlashReportPDF: typeof flashReportActions.getFlashReportPDF;
        getMeetingNotePdf: typeof meetingNoteActions.getMeetingNotePdf;
    };
}

interface IStrategyUpdateRowPropsFromParent {
    update: IStrategyUpdate;
    isLast: boolean;
}

interface IStrategyUpdateRowPropsFromStore {
    allManagers: Map<number, ManagerResponse>;
    productUniverseSummaries: Map<number, ProductSummary>;
    portfolioProductSummaries: Map<number, ProductSummary>;
}

export type StrategyUpdateRowProps = IStrategyUpdateRowPropsFromParent
    & IStrategyUpdateRowPropsFromActions
    & IStrategyUpdateRowPropsFromStore;

export class StrategyUpdateRowComponent extends React.Component<StrategyUpdateRowProps> {
    private static renderSeparator() {
        return <div className="strategy-update__separator" data-testid="strategy-update__separator">
            <div className="spacer-dash--small"/>
            <div className="spacer-dash--small"/>
        </div>;
    }

    private static renderUpdateTitle(update: IStrategyUpdate) {
        return <div className="strategy-update--top-align">
            <Link className="clickable link-16" to={`/${update.urlPrefix}/${update.backstopId}`}
                  data-testid="strategy-title-link">
                <span className="strategy-update__title">{update.title}</span>
            </Link>
        </div>;
    }

    private static formatAttachments(items: { name: string }[]) {
        const names = items.map((item) => item.name);
        return sortCaseInsensitive(names).join(", ");
    }

    public render() {
        const {update} = this.props;
        return <div key={update.backstopId} data-testid="strategy-update__row-container">
            <div className={"strategy-update__row"}>
                <div className={"strategy-update__item"}>
                    <div className={"strategy-update__date date-12 strategy-update--top-align"}>
                        {formatShortDate(update.date)}
                    </div>
                    {this.renderIcon(update.type)}
                    <div className={"strategy-update__contents"}>
                        {StrategyUpdateRowComponent.renderUpdateTitle(update)}
                        {this.renderUpdateAttachments(update)}
                    </div>
                </div>
                {this.renderDownloadIcon(update)}
            </div>
            {this.props.isLast ? <div/> : StrategyUpdateRowComponent.renderSeparator()}
        </div>;
    }

    private renderIcon = (type: StrategyUpdateType) => {
        const className = type === StrategyUpdateType.MEETING_NOTE
            ? "meeting-note__icon fa-light fa-memo"
            : "flash-report__icon fa-light fa-bell";
        return <div {...{className}}/>;
    };

    private renderUpdateAttachments(update: IStrategyUpdate) {
        return <div className={"strategy-update__attachments body-14"} data-testid="strategy-update__attachments">
            {this.getAttachments(update)}
        </div>;
    }

    private renderDownloadIcon(update: IStrategyUpdate) {
        return <div className="strategy-update__download strategy-update--top-align"
                    data-testid="strategy-update__download"
                    onClick={() => this.download(update)}
        >
            <div className={"download-icon fal fa-download"}/>
        </div>;
    }

    private getAttachments = (update: IStrategyUpdate) => {
        const attachments = update.hasProductAttachment ?
            this.getProductAttachments(update) :
            this.getManagerAttachments(update);

        return StrategyUpdateRowComponent.formatAttachments(attachments);
    };

    private getProductAttachments = (update: IStrategyUpdate) => {
        return update.productIds!
            .map((it) => this.props.portfolioProductSummaries.get(it)!)
            .filter((it) => it);
    };

    private getManagerAttachments = (update: IStrategyUpdate) => {
        return update.managerIds
            .map((it) => this.props.allManagers.get(it)!)
            .filter((it) => it);
    };

    private download = (update: IStrategyUpdate) => {
        if (update.type === StrategyUpdateType.FLASH_REPORT) {
            this.props.actions.getFlashReportPDF(update.backstopId, update.fileName);
        } else {
            this.props.actions.getMeetingNotePdf(update.backstopId, update.fileName);
        }
    };
}

export const mapDispatchToProps = (dispatch: Dispatch<AnyAction>): IStrategyUpdateRowPropsFromActions => {
    return {
        actions: bindActionCreators({
            getFlashReportPDF: flashReportActions.getFlashReportPDF,
            getMeetingNotePdf: meetingNoteActions.getMeetingNotePdf,
        }, dispatch),
    };
};

export const mapStateToProps = (state: IApplicationRootState) => {
    return {
        allManagers: getSuccessData(allManagersSelector(state))!,
        productUniverseSummaries: getSuccessData(productUniverseSummariesSelector(state))!,
        portfolioProductSummaries: getSuccessData(portfolioProductSummariesSelector(state))!,
    };
};

const connectedComponent =
    connect<IStrategyUpdateRowPropsFromStore, IStrategyUpdateRowPropsFromActions, IStrategyUpdateRowPropsFromParent>
    (mapStateToProps, mapDispatchToProps)(StrategyUpdateRowComponent);

export default multipleApiRequesterWrapper(
    connectedComponent,
    [
        allManagersRequestPair,
        allProductsRequestPair(),
    ],
    undefined,
    null,
);
