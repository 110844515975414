import {AxiosResponse} from "axios";
import {FactSheet, FsCurrency, FsFrequency} from "../model/FactSheet.model";
import {ApiBase} from "./ApiBase";
import {getQuarterAndYearByDate, parseDate} from "../utils/dateUtil";
import {getFileExtension, renameWordWithPdf} from "../utils/fileUtil";
import {getConfig} from "../utils/envUtil";

class InViewReportsApiImpl {
    public requestAllFactSheets(): Promise<FactSheet[]> {
        return ApiBase.processGetUnwrapped(
            "/fact-sheets/all",
            (response: AxiosResponse): any => {
                return (response.data).map((report: any) =>
                    new FactSheet(
                        report.backstopId,
                        report.title,
                        parseDate(report.effectiveDate),
                        getQuarterAndYearByDate(parseDate(report.effectiveDate)),
                        report.fileName,
                        report.assetClass,
                        report.currency || FsCurrency.OTHER,
                        report.frequency || FsFrequency.OTHER,
                    ));
            });
    }

    public downloadReport(reportId: number, fileName: string, isNewTab: boolean = true) {
        const extension = getFileExtension(fileName);
        const newFileName = renameWordWithPdf(fileName);
        const baseURL = getConfig().FRONTEND_CONFIG_BACKEND_URL;

        const url = `${baseURL}/download/research-reports/${reportId}/${extension}/${encodeURIComponent(newFileName)}`;

        if (isNewTab) {
            window.open(url, "_blank");
        } else {
            window.location.assign(url);
        }
    }
}

export const InViewReportsApi = new InViewReportsApiImpl();