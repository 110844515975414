import {createTheme, FormControlLabel} from "@mui/material";
import Checkbox from "@mui/material/Checkbox";
import * as React from "react";
import {AonColors} from "../../../../css/Colors";
import {ThemeProvider} from "@mui/material/styles";
import {FormattedMessage} from "react-intl";

export interface ICheckBoxProps {
    name: string;
    checked: boolean;
    onChange: () => void;
    label: string;
    labelId?: string;
    disabled?: boolean;
    indeterminate?: boolean;
}

export const CheckBoxComponent: React.FunctionComponent<ICheckBoxProps> = (props) => {
    const theme = createTheme({
        palette: {
            primary: {
                main: `${AonColors.AonTealDark}`
            }
        }
    });
    const CustomCheckBox = ((Checkbox));
    return <ThemeProvider theme={theme}>
        <FormControlLabel
            key={`${props.name}`}
            control={
                <CustomCheckBox
                    color="primary"
                    className={`shared-components__${props.name}-checkbox`}
                    checked={props.checked}
                    key={`${props.name}`}
                    onChange={() => props.onChange()}
                    disabled={props.disabled ?? false}
                    indeterminate={props.indeterminate ?? false}
                />
            }
            label={<div className="shared-components_checkbox-label" key={`${props.name}`}>
                {props.labelId
                    ? <FormattedMessage id="upload.select-all" defaultMessage={`${props.label}`}/>
                    : props.label}
            </div>}
        />
    </ThemeProvider>;
};
