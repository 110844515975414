import * as React from "react";
import {navigateTo} from "../../../navigateTo";

export class RootPage extends React.Component<any, any> {

    public componentDidMount() {
        navigateTo("/home");
    }

    public render() {
        return <div data-testid="root-page"/>;
    }
}

export default RootPage;
