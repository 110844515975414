import {Dispatch} from "react";
import {Action} from "redux-actions";
import {ThunkAction, ThunkDispatch} from "redux-thunk";
import {IApiResult} from "../../api/AxiosWealthManagementApi";
import {ClientApi, IClient} from "../../api/ClientApi";
import PlanApi from "../../api/PlanApi";
import {UserApi} from "../../api/UserApi";
import {ApiError} from "../../model/ApiError.model";
import {ClientsAndPlans} from "../../model/ClientsAndPlans.model";
import {IUserInfo, UserTypeEnum} from "../../model/UserInfo.model";
import {dispatchHeaderNotificationActions} from "../base/header/HeaderActions";
import {INotificationObject, NotificationTypes} from "../base/header/HeaderReducer";
import {ISelectValue} from "../common/Select.component";
import {IPlanAuthorization} from "../../model/PlanAuthorization.model";

export enum AdminActions {
    FAILED_GET_ALL_USER_EDIT_DETAILS = "FAILED_GET_ALL_USER_EDIT_DETAILS",
    SET_ALL_USER_EDIT_DETAILS = "SET_ALL_USER_EDIT_DETAILS",
    GET_ALL_USER_EDIT_DETAILS = "GET_ALL_USER_EDIT_DETAILS",
    CLEAR_USER_LIST = "CLEAR_USER_LIST",
    UPDATE_USER_SUCCESS = "UPDATE_USER_SUCCESS",
    UPDATE_USER_FAILED = "UPDATE_USER_FAILED",
    FAILED_GET_ALL_CLIENTS_AND_PLANS = "FAILED_GET_ALL_CLIENTS_AND_PLANS",
    SET_ALL_CLIENTS_AND_PLANS = "SET_ALL_CLIENTS_AND_PLANS",
    GET_ALL_CLIENTS_AND_PLANS = "GET_ALL_CLIENTS_AND_PLANS",
    CLEAR_CLIENTS_AND_PLANS = "CLEAR_CLIENTS_AND_PLANS",
    SET_CURRENT_CLIENT = "SET_CURRENT_CLIENT",
}

const setUserDetailsForEdit = (usersInfo: IUserInfo[]): ThunkAction<void, {}, null, Action<IUserInfo[]>> => {
    return (dispatch) => {
        dispatch(
            {
                type: AdminActions.SET_ALL_USER_EDIT_DETAILS,
                payload: usersInfo,
            },
        );
    };
};

export const getAllUserDetails = (dispatch: Dispatch<Action<void>>) => {
    return dispatch(
        {
            type: AdminActions.GET_ALL_USER_EDIT_DETAILS,
        },
    );
};

const getUserDetailsForEdit = (): ThunkAction<void, {}, null, Action<void>> => {
    return getAllUserDetails;
};

const updateUserDetails = (arbtSecurityId: string, userType: UserTypeEnum, planIds: number[], isMfa: boolean):
    ThunkAction<void, {}, null, Action<void>> => {
    return (dispatch) => {
        UserApi.updateUser(arbtSecurityId, userType, planIds, isMfa)
            .then(() => {
                    dispatch({type: AdminActions.UPDATE_USER_SUCCESS});
                },
                () => {
                    dispatch({type: AdminActions.UPDATE_USER_FAILED});
                },
            );
    };
};

const dispatchSuccessNotification = (
    dispatch: ThunkDispatch<{}, null, Action<void | INotificationObject>>,
    successMsg: string,
    action: AdminActions,
) => () => {
    dispatchHeaderNotificationActions(
        {
            message: successMsg,
            notificationType: NotificationTypes.SUCCESS,
        },
        5000,
    )(dispatch);
    return dispatch({type: action});
};

const dispatchFailureNotification = (
    dispatch: ThunkDispatch<{}, null, Action<void | INotificationObject>>,
    errorMsg: string) => () => {
    return dispatchHeaderNotificationActions(
        {
            message: errorMsg,
            notificationType: NotificationTypes.FAILURE,
        },
        5000,
    )(dispatch);
};

const dispatchSuccessNotificationWithClearClientsAndPlans = (
    dispatch: ThunkDispatch<{}, null, Action<void | INotificationObject>>,
    successMsg: string,
) => dispatchSuccessNotification(dispatch, successMsg, AdminActions.CLEAR_CLIENTS_AND_PLANS);

const setAllClientsAndPlansForAdmin = (clientsAndPlans: ClientsAndPlans):
    ThunkAction<void, {}, null, Action<ClientsAndPlans>> => {
    return (dispatch) => {
        dispatch(
            {
                type: AdminActions.SET_ALL_CLIENTS_AND_PLANS,
                payload: clientsAndPlans,
            },
        );
    };
};

export const getClientsAndPlansForAdmin = (dispatch: Dispatch<Action<void>>) => {
    dispatch(
        {
            type: AdminActions.GET_ALL_CLIENTS_AND_PLANS,
        },
    );
};

export const getAllClientsAndPlansForAdmin = (): ThunkAction<void, {}, null, Action<void>> => {
    return getClientsAndPlansForAdmin;
};

const removeUser = (arbtSecurityId: string, email: string):
    ThunkAction<void, {}, null, Action<void | INotificationObject>> => {
    return (dispatch) => {
        UserApi.removeUser(arbtSecurityId).then(
            dispatchSuccessNotification(dispatch,
                `User with email ${email} was successfully removed!`,
                AdminActions.CLEAR_USER_LIST),
        ).catch(
            dispatchFailureNotification(dispatch, `Error: Failed to remove user with email ${email}`),
        );

    };
};

const removeUserFromPlan = (arbtSecurityId: string, email: string, planId: number, planName: string):
    ThunkAction<void, {}, null, Action<void | INotificationObject>> => {
    return (dispatch) => {
        UserApi.removeUserFromPlan(arbtSecurityId, planId).then(
            dispatchSuccessNotificationWithClearClientsAndPlans(dispatch,
                `User with email ${email} was successfully removed from plan ${planName}`,
            ),
        ).catch(
            dispatchFailureNotification(dispatch, `Error: Failed to remove user with email ${email} from plan ${planName}`),
        );

    };
};

const saveClient = (client: IClient):
    ThunkAction<void, {}, null, Action<void | INotificationObject | ISelectValue>> => {
    return (dispatch) => {
        ClientApi.saveClient(client).then((result: IApiResult<ISelectValue>) => {
                dispatchSuccessNotificationWithClearClientsAndPlans(dispatch,
                    `Client ${client.name} was successfully added`)();
                dispatch({type: AdminActions.SET_CURRENT_CLIENT, payload: result.data});
            },
        ).catch((reason: ApiError) => {
            dispatchFailureNotification(dispatch, `Error: Failed to add client ${client.name}. Reason: ${reason.message}`)();
            },
        );
    };
};

const updateClient = (client: IClient):
    ThunkAction<void, {}, null, Action<void | INotificationObject | ISelectValue>> => {
    return (dispatch) => {
        ClientApi.updateClient(client).then(() => {
            dispatchSuccessNotificationWithClearClientsAndPlans(dispatch,
                    `Client ${client.name} was successfully updated`)();
            },
        ).catch((reason: ApiError) => {
                dispatchFailureNotification(dispatch, `Error: Failed to update client ${client.name}. Reason: ${reason.message}`)();
            },
        );
    };
};

export const savePlan = (plan: IPlanAuthorization):
    ThunkAction<void, {}, null, Action<void | INotificationObject>> => {
    return (dispatch) => {
        PlanApi.savePlan(plan).then(
            dispatchSuccessNotificationWithClearClientsAndPlans(dispatch,
                `Plan ${plan.name} was successfully added`),
        ).catch((reason: ApiError) => {
                dispatchFailureNotification(dispatch, `Error: Failed to add plan ${plan.name}. Reason: ${reason.message}`)();
            },
        );
    };
};

export const updatePlan = (planId: number, plan: IPlanAuthorization):
    ThunkAction<void, {}, null, Action<void | INotificationObject>> => {
    return (dispatch) => {
        PlanApi.updatePlan(planId, plan).then(
            dispatchSuccessNotificationWithClearClientsAndPlans(dispatch,
                `Plan ${plan.name} was successfully updated`),
        ).catch((reason: ApiError) => {
            dispatchFailureNotification(dispatch, `Error: Failed to update plan ${plan.name}. Reason: ${reason.message}`)();
            },
        );
    };
};

export const resetCurrentClient = ():
    ThunkAction<void, {}, null, Action<void | undefined>> => (dispatch: Dispatch<Action<void>>) => {
    dispatch(
        {
            type: AdminActions.SET_CURRENT_CLIENT,
            payload: undefined,
        },
    );
};

export const setCurrentClient = (client: ISelectValue):
    ThunkAction<void, {}, null, Action<ISelectValue>> => (dispatch: Dispatch<Action<ISelectValue>>) => {
    dispatch(
        {
            type: AdminActions.SET_CURRENT_CLIENT,
            payload: client,
        },
    );
};

export default {
    setUserDetailsForEdit,
    getUserDetailsForEdit,
    setAllClientsAndPlansForAdmin,
    getAllClientsAndPlansForAdmin,
    updateUserDetails,
    removeUser,
    removeUserFromPlan,
    saveClient,
    updateClient,
    savePlan,
    updatePlan,
    resetCurrentClient,
    setCurrentClient,
};
