import {Action, handleActions} from "redux-actions";
import {IApiResult} from "../../api/AxiosWealthManagementApi";
import {ClientUser} from "../../model/ClientUser.model";
import {SharedDocument, SharedDocumentType} from "../../model/SharedDocument.model";
import {throwErrorOnNullOrUndefined} from "../../utils/errorUtil";
import {NOT_REQUESTED, REQUEST_FAILED, REQUEST_SUCCEEDED, REQUESTED, RequestState} from "../common/commonStates";
import SharedDocumentActions, {SharedDocumentsActionTypes} from "./SharedDocuments.actions";
import {LOCATION_CHANGE} from "redux-first-history";

export const sharedDocumentsInitialState: ISharedDocumentsPropsFromState = {
    documentTypes: {data: []},
    fileUpload: {success: undefined, message: ""},
    clientUsers: NOT_REQUESTED,
    usersInSessionPlans: NOT_REQUESTED,
    clientUploadsEnabled: false,
    currentUserEmail: "",
    documents: NOT_REQUESTED,
};

export interface ISharedDocumentsFileUploadProps {
    success: boolean | undefined;
    message: string;
}

export interface ISharedDocumentsPropsFromState {
    documentTypes: IApiResult<SharedDocumentType[]>;
    fileUpload: ISharedDocumentsFileUploadProps;
    clientUsers: RequestState<ClientUser[]>;
    usersInSessionPlans: RequestState<ClientUser[]>;
    documents: RequestState<SharedDocument[]>;
    clientUploadsEnabled: boolean;
    currentUserEmail: string;
}

export interface ISharedDocumentsPropsFromActions {
    actions: typeof SharedDocumentActions;
}

export interface IFileUploadCompletePayload { success: boolean; message: string; }

const handleClearUploadFileFailure = (state: ISharedDocumentsPropsFromState): ISharedDocumentsPropsFromState => {
    return {...state, fileUpload: {success: undefined, message: ""}};
};

// tslint:disable
type LocationChangePayload = { location: { pathname: string } };
// tslint:enable

const handleLocationChange = (state: ISharedDocumentsPropsFromState,
                              locationChangeAction: Action<LocationChangePayload>):
    ISharedDocumentsPropsFromState => {
    return (locationChangeAction.payload!.location.pathname === "/shared-documents")
        ? state
        : handleClearUploadFileFailure(state)
    ;
};

const reducerMap = {
    [SharedDocumentsActionTypes.RESOLVE_DOCUMENT_TYPES]:
        (state: ISharedDocumentsPropsFromState,
         action: Action<IApiResult<SharedDocumentType[]>>): ISharedDocumentsPropsFromState => {
            return {...state, documentTypes: throwErrorOnNullOrUndefined(action.payload, "action.payload")};
        },
    [SharedDocumentsActionTypes.FILE_UPDATE_SUCCESS]:
        (state: ISharedDocumentsPropsFromState): ISharedDocumentsPropsFromState => {
            return {...state, fileUpload: {success: true, message: "File successfully saved"}};
        },
    [SharedDocumentsActionTypes.CLEAR_FILE_UPLOAD_FAILURE]: handleClearUploadFileFailure,
    [LOCATION_CHANGE]: handleLocationChange,
    [SharedDocumentsActionTypes.CLIENT_USERS_REQUESTED]:
        (state: ISharedDocumentsPropsFromState): ISharedDocumentsPropsFromState => {
            return {...state, clientUsers: REQUESTED};
        },
    [SharedDocumentsActionTypes.CLIENT_USERS_SUCCEEDED]:
        (state: ISharedDocumentsPropsFromState,
         action: Action<any>): ISharedDocumentsPropsFromState => {
            return {...state, clientUsers: REQUEST_SUCCEEDED(action.payload)};
        },
    [SharedDocumentsActionTypes.CLIENT_USERS_FAILED]:
        (state: ISharedDocumentsPropsFromState,
         action: Action<any>): ISharedDocumentsPropsFromState => {
            return {...state, clientUsers: REQUEST_FAILED(action.payload)};
        },
    [SharedDocumentsActionTypes.USERS_FOR_SESSION_PLANS_REQUESTED]:
        (state: ISharedDocumentsPropsFromState): ISharedDocumentsPropsFromState => {
            return {...state, usersInSessionPlans: REQUESTED};
        },
    [SharedDocumentsActionTypes.USERS_FOR_SESSION_PLANS_SUCCEEDED]:
        (state: ISharedDocumentsPropsFromState,
         action: Action<any>): ISharedDocumentsPropsFromState => {
            return {...state, usersInSessionPlans: REQUEST_SUCCEEDED(action.payload)};
        },
    [SharedDocumentsActionTypes.USERS_FOR_SESSION_PLANS_FAILED]:
        (state: ISharedDocumentsPropsFromState,
         action: Action<any>): ISharedDocumentsPropsFromState => {
            return {...state, usersInSessionPlans: REQUEST_FAILED(action.payload)};
        },
    [SharedDocumentsActionTypes.SHARED_DOCUMENTS_REQUESTED]:
        (state: ISharedDocumentsPropsFromState) => {
            return {...state, documents: REQUESTED};
        },
    [SharedDocumentsActionTypes.SHARED_DOCUMENTS_SUCCEEDED]:
        (state: ISharedDocumentsPropsFromState,
         action: Action<any>) => {
            return {...state, documents: REQUEST_SUCCEEDED(action.payload)};
        },
    [SharedDocumentsActionTypes.SHARED_DOCUMENTS_FAILED]:
        (state: ISharedDocumentsPropsFromState,
         action: Action<any>) => {
            return {...state, documents: REQUEST_FAILED(action.payload)};
        },
    [SharedDocumentsActionTypes.CLEAR_SHARED_DOCUMENTS]:
        (state: ISharedDocumentsPropsFromState) => {
            return {...state, documents: NOT_REQUESTED};
        },
};

export function clientUsersInner(state: ISharedDocumentsPropsFromState | undefined): RequestState<ClientUser[]> {
    if (state) {
        return state.clientUsers;
    } else {
        return NOT_REQUESTED;
    }
}

export function usersInSessionPlansSelectorInner(state: ISharedDocumentsPropsFromState | undefined): RequestState<ClientUser[]> {
    return state ? state.usersInSessionPlans : NOT_REQUESTED;
}

export function sharedDocumentsSelectorInner(state: ISharedDocumentsPropsFromState | undefined): RequestState<SharedDocument[]> {
    return state ? state.documents : NOT_REQUESTED;
}

export default handleActions<ISharedDocumentsPropsFromState,
    IApiResult<SharedDocumentType[]>
    | { successCount: number, fileCount: number }
    | IFileUploadCompletePayload
    | LocationChangePayload>(reducerMap, sharedDocumentsInitialState);
