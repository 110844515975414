import {MRT_Column} from "material-react-table";
import {ProductSummary} from "../../model/product/ProductSummary";
import {AonColors} from "../../../../css/Colors";
import {TableCellProps} from "@mui/material";

export const textStyle = (fontWeight: string, fontSize: string, lineHeight: string, color: string, marginBottom: string)=>
{
    return {
        fontFamily: `"Helvetica Now Text", "sans-serif"`,
        fontWeight,
        fontSize,
        lineHeight,
        color,
        marginBottom,
    };
};

export const headersStyle = (column: MRT_Column<ProductSummary>) => {
    const borderRadius = column.getIsFirstColumn() ? "12px 0 0 0" : column.getIsLastColumn() ? "0 12px 0 0" : "0";
    const borderRight = column.getIsLastColumn() ? "0" : `1px solid ${AonColors.AonGray05}`;
    const isColumnFiltered = column.getFilterValue() && (column.getFilterValue() as []).length > 0;
    return {
        ...textStyle("700", "14px", "18px", AonColors.AonNavy, "12px"),
        borderTop: "0",
        borderRight,
        borderRadius,
        "& .Mui-TableHeadCell-Content-Labels svg": {
            color: isColumnFiltered ? AonColors.AonTealDark : AonColors.AonGray01,
            fontWeight: isColumnFiltered ? "700" : "400",
        },
    };
};

export const tableStyle: TableCellProps['sx'] = {
    borderWidth: "1px 1px 0 1px",
    borderColor: AonColors.AonGray05,
    borderStyle: "solid",
    borderRadius: "12px 12px 0 0",
    borderSpacing: "0",
};

export const bottomToolbarStyle: TableCellProps['sx'] = {
    borderWidth: "0 1px 1px 1px",
    borderColor: AonColors.AonGray05,
    borderStyle: "solid",
    boxShadow: "none",
    borderRadius: "0 0 12px 12px",
    "& .MuiTablePagination-root":{marginBottom: "16px"},
    "& .MuiTablePagination-root svg": textStyle("400", "16px", "20px", AonColors.AonGray02, "0"),
    "& .MuiButtonBase-root": {'&:hover': { backgroundColor: AonColors.AonGray07}},
    "& .MuiInputBase-input": {paddingBottom: 0},
    "& .MuiTypography-root": textStyle("400", "16px", "20px", AonColors.AonGray02, "0"),
    "& .MuiInputBase-root": textStyle("400", "16px", "20px", AonColors.AonGray02, "0"),
    "& .MuiInputLabel-root": textStyle("400", "16px", "20px", AonColors.AonGray02, "0"),
};

export const topToolbarStyle: TableCellProps['sx'] = {
    "& .MuiInputBase-input": {
        color: `${AonColors.AonGray02}`,
        paddingLeft: "8px",
    },
    "& .MuiOutlinedInput-notchedOutline": {
        border: `1px solid ${AonColors.AonGray01}`,
        borderRadius: "4px",
        backgroundColor: "none"
    },
    "& .Mui-focused": {
        "& .MuiOutlinedInput-notchedOutline": {
            border: `2px solid ${AonColors.AonSearchTealDarkHover} !important`,
            borderRadius: "4px",
            backgroundColor: "none"
        },
    },
};

export const filterTextFieldStyle: TableCellProps['sx'] = {
    // "& .MuiInput-root::before": {
    //     borderBottom: `1px solid ${AonColors.AonErrorRed}`,
    // },
    "& .MuiInput-root::after": {
        borderBottom: `2px solid ${AonColors.AonSearchTealDarkHover}`,
    },
    "& .MuiInputBase-input": {
        color: `${AonColors.AonGray02}`,
        fontFamily: `"Helvetica Now Text", "sans-serif"`,
    },
};
