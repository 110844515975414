import {Action, handleActions} from "redux-actions";
import {ALL_MANAGERS, StrategyUpdatePageTypes} from "./strategyUpdatesActions";

export interface IStrategyUpdatesState {
    selectedManager: number;
}

export const initialState = {
    selectedManager: ALL_MANAGERS,
};

const updateSelectedManager = (state: IStrategyUpdatesState, {payload}: Action<number>): IStrategyUpdatesState => {
    return {...state, selectedManager: payload!};
};

const reducerMap = {
    [StrategyUpdatePageTypes.UPDATE_SELECTED_MANAGER]: updateSelectedManager,
};

export default handleActions<IStrategyUpdatesState, any>(reducerMap, initialState);
