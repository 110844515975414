import * as d3 from "d3";
import {ScaleBand, ScaleLinear} from "d3-scale";
import {BaseType, Selection} from "d3-selection";
import {OrderedMap} from "immutable";
import {AonColors, LegacyColors} from "../../../../../css/Colors";
import {CashflowKeys, ICashflow} from "./CashFlowsUtils";

export function getChartWidth() {
    const widthWithoutMargin = window.innerWidth > 1024
        ? window.innerWidth > 1600 ? 1600 : window.innerWidth
        : verticalChartDimensions.width;
    return widthWithoutMargin - horizontalChartDimensions.leftAndRight * 2;
}

export const verticalChartDimensions = {
    width: 1024,
    height: 400,
    topAndBottom: 32,
    leftAndRight: 60,
};

export const horizontalChartDimensions = {
    width: 1024,
    height: 0,
    topAndBottom: 32,
    leftAndRight: 45,
};

export interface IChartVariables {
    dataByKey: IKeyValuePair[];
    chartRef: Selection<BaseType, any, HTMLElement, any>;
    legendRef?: Selection<BaseType, any, HTMLElement, any>;
    yLinearScale: ScaleLinear<number, number>;
    xBandScale: ScaleBand<string>;
    barColor?: string;
    barWidth?: number;
    barLabel?: string;
    patternUrl?: string;
}

export interface IKeyValuePair {
    key: string;
    value: number;
}

export function getTextWidth(node: SVGTextContentElement) {
    return node!.getComputedTextLength();
}

export const chartSvg = "cashflow-chart__chart-svg";
export const cashflowBarG = "cashflow-chart__chart-g__cashflow";
export const inflowBarG = "cashflow-chart__chart-g__inflow";
export const outflowBarG = "cashflow-chart__chart-g__outflow";
export const legendG = "cashflow-chart__legend-g";
export const cashflowPatternBarG = "cashflow-chart__chart-g__cashflow-pattern";
export const patternId = "diagonalHatch";

export const VERTICAL_NET_BAR_WIDTH = 32;
export const HORIZONTAL_NET_BAR_WIDTH = 24;

export const HORIZONTAL_BAND_WIDTH = 64;

export const HORIZONTAL_CHART_SPACER = 100;

const CHART_LEGEND_SPACER = 47;

export class CashflowsChartingHelper {
    private static appendRectToLegendItem(legendItem: any, fillValue: string) {
        legendItem
            .append("rect")
            .attr("width", "16")
            .attr("height", "16")
            .attr("rx", 2)
            .style("fill", fillValue);
    }

    private cashFlowsByKey: OrderedMap<string, ICashflow>;

    constructor(
        private readonly isVerticalChart: boolean,
        private readonly chartWidth: number,
        private readonly suffix: string,
        private readonly dx: number,
        private readonly dy: number,
        private readonly horizontalChartHeight: number = 0) {
        this.cashFlowsByKey = OrderedMap<string, ICashflow>();
        if (!isVerticalChart) {
            horizontalChartDimensions.height = horizontalChartHeight;
        }
    }

    public getChartWidth(): number {
        return this.chartWidth;
    }

    public getChartSvg(): string {
        return `${chartSvg}${this.suffix}`;
    }

    public getCashflowBarG(): string {
        return `${cashflowBarG}${this.suffix}`;
    }

    public getInflowBarG(): string {
        return `${inflowBarG}${this.suffix}`;
    }

    public getOutflowBarG(): string {
        return `${outflowBarG}${this.suffix}`;
    }

    public getLegendG(): string {
        return `${legendG}${this.suffix}`;
    }

    public getCashflowPatternBarG(): string {
        return `${cashflowPatternBarG}${this.suffix}`;
    }

    public getPatternId(): string {
        return `${patternId}${this.suffix}`;
    }

    public setCashFlowsByKey(cashFlowsByKey: OrderedMap<string, ICashflow>) {
        this.cashFlowsByKey = cashFlowsByKey;
    }

    public cleanUpGraph() {
        d3.select(`#${this.getChartSvg()}`).selectAll("*").remove();
    }

    public setupGraph() {
        const listOfGs = [
            this.getCashflowBarG(),
            this.getInflowBarG(),
            this.getOutflowBarG(),
            this.getCashflowPatternBarG(),
            this.getLegendG(),
        ];

        listOfGs.map((g) => this.appendG(g));

        d3.select(`#${this.getChartSvg()}`)
            .append("defs")
            .append("pattern")
            .attr("id", this.getPatternId())
            .attr("patternUnits", "userSpaceOnUse")
            .attr("width", 20)
            .attr("height", 20)
            .append("path")
            .attr("d", "M 0 0 L 20 20 M 10 -10 L 30 10 M -10 10 L 10 30")
            .attr("stroke", LegacyColors.Gray)
            .attr("stroke-width", 3);
    }

    public getBarsVariables(showInflowOutflow: boolean) {
        const netCashflowData: IKeyValuePair[] = this.getKeyValuePairsByAttribute(CashflowKeys.CASHFLOW);
        const inflowData: IKeyValuePair[] = this.getKeyValuePairsByAttribute(CashflowKeys.INFLOW);
        const outflowData: IKeyValuePair[] = this.getKeyValuePairsByAttribute(CashflowKeys.OUTFLOW);

        const chartRefCashflow = d3.select(`#${this.getCashflowBarG()}`);
        const chartRefInflow = d3.select(`#${this.getInflowBarG()}`);
        const chartRefOutflow = d3.select(`#${this.getOutflowBarG()}`);

        const legendRef = d3.select(`#${this.getLegendG()}`);

        const netCashflowValues: number[] = netCashflowData.map((pair) => pair.value);
        const inflowValues: number[] = inflowData.map((pair) => pair.value);
        const outflowValues: number[] = outflowData.map((pair) => pair.value);

        const maxY = Math.round(showInflowOutflow ? Math.max(...inflowValues) : Math.max(...netCashflowValues));
        const minY = Math.round(showInflowOutflow ? Math.min(...outflowValues) : Math.min(...netCashflowValues));

        const maxMagnitude = Math.max(Math.abs(minY), Math.abs(maxY)) + Math.max(Math.abs(minY), Math.abs(maxY)) / 7.5;

        const innerWidthHorizontal = this.chartWidth * 2 / 3 - horizontalChartDimensions.leftAndRight;

        const linearScale = this.isVerticalChart
            ? d3.scaleLinear()
                .domain([maxMagnitude, -maxMagnitude])
                .range([0, verticalChartDimensions.height - verticalChartDimensions.topAndBottom * 2])
            : d3.scaleLinear()
                .domain([-maxMagnitude, maxMagnitude])
                .range([0, innerWidthHorizontal]);

        const getBandScale: (barData: IKeyValuePair[]) => ScaleBand<string> = this.isVerticalChart
            ? this.getScaleForVerticalChart
            : this.getScaleForHorizontalChart;

        const cashflowChartVariables: IChartVariables = {
            dataByKey: netCashflowData,
            chartRef: chartRefCashflow,
            legendRef,
            yLinearScale: linearScale,
            xBandScale: getBandScale(netCashflowData),
            barColor: showInflowOutflow ? AonColors.AonGray05 : AonColors.AonDataCobalt,
            barLabel: "Net Cashflow",
            patternUrl: showInflowOutflow ? this.getPatternId() : undefined,
        };

        const inflowChartVariables: IChartVariables = {
            ...cashflowChartVariables,
            dataByKey: inflowData,
            chartRef: chartRefInflow,
            barColor: AonColors.AonTealLight,
            xBandScale: getBandScale(inflowData),
            barWidth: 6,
            barLabel: "Cash Inflow",
            patternUrl: undefined,
        };

        const outflowChartVariables: IChartVariables = {
            ...cashflowChartVariables,
            dataByKey: outflowData,
            chartRef: chartRefOutflow,
            barColor: AonColors.AonDataCobalt,
            xBandScale: getBandScale(outflowData),
            barWidth: 6,
            barLabel: "Cash Outflow",
            patternUrl: undefined,
        };

        return showInflowOutflow
            ? [cashflowChartVariables, inflowChartVariables, outflowChartVariables]
            : [cashflowChartVariables];
    }

    public renderLegend(bars: IChartVariables[]) {
        const verticalOffset = this.isVerticalChart
            ? verticalChartDimensions.height - verticalChartDimensions.topAndBottom / 2 + CHART_LEGEND_SPACER
            : horizontalChartDimensions.height + horizontalChartDimensions.topAndBottom + CHART_LEGEND_SPACER + 28 ;

        bars.map((bar, index) => this.renderLegendItem(bar, index * 125, bars.length > 1 && index === 0));
        const offset = bars.length > 1 ? 175 : 50;
        bars[0].legendRef!.attr("transform", `translate(${this.chartWidth / 2 - offset}, ${verticalOffset})`);
    }

    private renderLegendItem(chartVariables: IChartVariables, offset: number, showPattern: boolean) {
        const legendItem = chartVariables.legendRef!
            .append("g")
            .attr("class", "legend-item")
            .attr("transform", `translate(${offset}, 0)`);

        CashflowsChartingHelper.appendRectToLegendItem(legendItem, chartVariables.barColor!);
        if (showPattern) {
            CashflowsChartingHelper.appendRectToLegendItem(legendItem, `url(#${this.getPatternId()})`);
        }

        legendItem
            .append("text")
            .attr("x", 22)
            .attr("y", 8)
            .attr("dy", "0.35em")
            .text(chartVariables.barLabel!.toLowerCase());
    }

    private getScaleForVerticalChart = (barData: IKeyValuePair[]): ScaleBand<string> => {
        return d3.scaleBand()
            .domain(barData.map((pair) => pair.key))
            .range([0, this.chartWidth - verticalChartDimensions.leftAndRight * 2])
            .padding(0.5);
    };

    // noinspection JSMethodCanBeStatic
    private getScaleForHorizontalChart(barData: IKeyValuePair[]): ScaleBand<string> {
        return d3.scaleBand()
            .domain(barData.map((pair) => pair.key))
            .range([0, horizontalChartDimensions.height]);
    }

    private appendG(id: string) {
        d3.select(`#${this.getChartSvg()}`)
            .append("g")
            .attr("id", `${id}`)
            .attr("transform", `translate(${this.dx}, ${this.dy})`);
    }

    private getKeyValuePairsByAttribute(attr: CashflowKeys): IKeyValuePair[] {
        return this.cashFlowsByKey.map((value: ICashflow, key: string) => {
            return {
                key,
                value: parseFloat(value[attr].toFixed(2)),
            } as IKeyValuePair;
        }).valueSeq().toArray();
    }
}
