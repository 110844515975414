import * as React from "react";
import {withVerifyAccess} from "../common/withVerifyAccess";
import AdminClientInfoRequester from "./AdminClientInfoRequester";
import AdminUserAddComponent from "./AdminUserAddComponent";
import {UserTypeEnum} from "../../model/UserInfo.model";

export const AdminUserAddPage: React.FunctionComponent = () => {
    return (
        <AdminClientInfoRequester>
            <AdminUserAddComponent/>
        </AdminClientInfoRequester>
    );
};

export default withVerifyAccess(AdminUserAddPage, UserTypeEnum.ADMIN);
