import {IFundingLevel, CprPortfolioApi} from "../../api/CprPortfolioApi";
import {Dispatch} from "redux";
import {Action} from "redux-actions";
import {ThunkAction} from "redux-thunk";

export enum CprPortfolioActions {
    FUNDING_LEVEL_REQUESTED = "FUNDING_LEVEL_REQUESTED",
    FUNDING_LEVEL_REQUEST_SUCCEEDED = "FUNDING_LEVEL_REQUEST_SUCCEEDED",
    CLEAR_FUNDING_LEVEL = "FUNDING_LEVEL_REQUEST_FAILED",
}

export function requestFundingLevel(dispatch: Dispatch<Action<IFundingLevel | undefined>>) {
    dispatch({type: CprPortfolioActions.FUNDING_LEVEL_REQUESTED});

    return CprPortfolioApi.fetchFundingLevel()
        .then((response: IFundingLevel) => {
            dispatch({
                type: CprPortfolioActions.FUNDING_LEVEL_REQUEST_SUCCEEDED,
                payload: response,
            });
        }).catch(() => {
            dispatch({
                type: CprPortfolioActions.FUNDING_LEVEL_REQUEST_SUCCEEDED,
                payload: undefined,
            });
        });
}

export const clearFundingLevel = (): ThunkAction<void, void, null, Action<string>> => {
    return (dispatch) => {
        dispatch({type: CprPortfolioActions.CLEAR_FUNDING_LEVEL});
    };
};

export default {
    requestFundingLevel,
    clearFundingLevel
};