import * as React from "react";
import {useEffect, useState} from "react";
import {ClientUser} from "../../model/ClientUser.model";
import {SelectComponentReact} from "../common/ReactSelectComponent";
import {ISelectValue} from "../common/Select.component";
import {CalendarMeetingAttendee} from "../../model/CalendarMeetingAttendee.model";
import {compareInsensitive} from "../../utils/listUtil";

export interface IMeetingAttendeesSectionProps {
    sectionName: string,
    users: ClientUser[],
    renderSearch: boolean,
    attendees: CalendarMeetingAttendee[],
    onAddAttendee: (user: ISelectValue) => void;
    onDeleteAttendee: (attendeeId: number) => void;
}

export const ALL_USERS_SELECT_VALUE: ISelectValue = {name: "Search Users", id: -1};

export const MeetingAttendeesSection: React.FunctionComponent<IMeetingAttendeesSectionProps>=(props: IMeetingAttendeesSectionProps) => {
    const [searchUsers, setSearchUsers] = useState<ISelectValue[]>([]);
    const [selectedUser, setSelectedUser] = useState<ISelectValue>(ALL_USERS_SELECT_VALUE);

    useEffect(() => {
        if (props.users) {
            setSearchUsers(
                [ALL_USERS_SELECT_VALUE].concat(
                props.users.map((user) => {
                    return {name: `${user.firstName} ${user.lastName} - ${user.email}`, id: user.id};
                })
            ));
        }
    }, [props.users]);

    const handleChange = (user: ISelectValue) => {
        setSelectedUser(user);
    };

    const renderUsersFilter = () => {
        return props.renderSearch
            ? <div className="meeting-attendees__filter-container">
                <div className="meeting-attendees__filter">
                        <SelectComponentReact
                            className="meeting-attendees__users-dropdown"
                            isMulti={false}
                            menuIsOpen={undefined}
                            options={searchUsers}
                            value={selectedUser}
                            onChange={handleChange}
                            submitClicked={false}
                            getOptionLabel={(option: ISelectValue) => option.name}
                            getOptionValue={(option: ISelectValue) => option!.id!.toString(10)}
                            renderRequired={false}
                            stylesObject={{width: "500px"}}
                        />
                    </div>
                    <a className="meeting-attendees__add-user link-14 clickable"
                       onClick={() => props.onAddAttendee(selectedUser)}>
                        +Add
                    </a>
                </div>
            : null;
    };

    const renderRemoveTd = (attendee: CalendarMeetingAttendee) => {
        return props.renderSearch
        ?  <td>
                <a className="meeting-attendee__remove link-14 clickable" onClick={() => props.onDeleteAttendee(attendee.id!)}>
                    Remove
                </a>
            </td>
        :<td/>;
    };

    const renderAttendees = () => {
        return props.attendees.length > 0
            ? <table className="meeting-attendees__attendees-table">
                    <tbody>
                    <tr>
                        <th>Name</th>
                        <th>Email</th>
                    </tr>
                    {props.attendees
                        .sort((a1, a2) =>
                            compareInsensitive(a1.firstName, a2.firstName))
                        .map((attendee, idx) => {
                       return <tr key={idx}>
                           <td className="meeting-attendee__name">{`${attendee.firstName} ${attendee.lastName}`}</td>
                           <td className="meeting-attendee__email">{attendee.email}</td>
                           {renderRemoveTd(attendee)}
                       </tr>;
                    })}
                    </tbody>
                </table>
            : null;
    };

    return <div className="meeting-attendees__section">
        <p className="meeting-attendees__section-header">{props.sectionName}</p>
        {renderUsersFilter()}
        {renderAttendees()}
    </div> ;
};
