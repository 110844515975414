import * as React from "react";
import {withVerifyAccess} from "../common/withVerifyAccess";
import {UserTypeEnum} from "../../model/UserInfo.model";
import {UserApi} from "../../api/UserApi";
import {SimpleFileUpload} from "../common/SimpleFileUpload.component";
import {useState} from "react";

export const AdminMassAssignUsersPage: React.FunctionComponent = () => {
    const [messages, setMessages ] = useState<string[]>([]);

    const uploadFile = async (id: string, file: File, value: string): Promise<string> => {
        setMessages([]);
        return UserApi.uploadUsersAndPlans(file).then(
            (result) => {
                if (result.data?.isSuccess) {
                    setMessages(result.data?.messages ?? []);
                    return `Import succeeded`;
                } else {
                    setMessages(result.data?.messages ?? []);
                    return `Import failed`;
                }
            },
            (error) => {
                return Promise.reject(`Failed to upload file ${value} error ${error.errorNumber}: ${error.message}`);
            }
        );
    };

    return (
        <div className="main-content" data-testid="admin-mass-assign-users-page">
            <h1>Mass Assign Users to Plans</h1>
            <h2> Instructions: </h2>
            <p>Export all the user and plan info using the link below so
            that you can filter these lists to the appropriate populations.</p>
            <p>Once you have your list of users and plans, download
            the excel template and fill in all the emails id in column A
            and all the plan Ids and plan names in columns C and D.</p>
            <p>When the template is filled out, choose the file from your computer and upload it</p>
            <p>Note: All users in the excel will be assigned to all plans in the excel.
            If any users or plans are not found, the whole file will be rejected
            and the errors will be written out below</p>
            <p>
                <span className="clickable link-16" onClick={() => downloadExport()}>
                    Download Users and Plans
                </span>
            </p>
            <p>
                <span className="clickable link-16" onClick={() => downloadTemplate()}>
                    Download Template
                </span>
            </p>

            <SimpleFileUpload
                title="Upload File"
                id="mass-assign-users-to-plans"
                uploadCallback={uploadFile}
            />
            <ul>
            { messages.map((it, index) => <li key={index}>{it}</li>)}
            </ul>
        </div>
    );
};


const downloadExport = () => {
    UserApi.downloadUsersAndPlans();
};

const downloadTemplate = () => {
    UserApi.downloadMassAssignTemplate();
};
export default withVerifyAccess(AdminMassAssignUsersPage, UserTypeEnum.ADMIN);
