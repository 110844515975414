import {Set} from "immutable";
import * as React from "react";
import {connect} from "react-redux";
import {holdingsSelector} from "../../../../mainReducerMapSelectors";
import {IHolding} from "../../../api/HoldingsApi";
import {getSuccessData} from "../../common/commonStates";
import {holdingsRequestPair} from "../../common/RequesterPairs";
import {ISelectValue, SelectComponent} from "../../common/Select.component";
import {HoldingsComponent} from "./Holdings.component";
import {HoldingsAreaChartGenerator} from "./HoldingsAreaChartGenerator";
import {HoldingsDonutChartGenerator} from "./HoldingsDonutChartGenerator";
import {multipleApiRequesterWrapper} from "../../common/MultipleApiRequesterWrapper";
import moment = require("moment");
import {IApplicationRootState} from "../../../../applicationState";

const lodash = require("lodash");

export interface IHoldingsPropsFromState {
    holdings: IHolding[];
}

export interface IHoldingsState {
    year?: number;
    month?: number;
}

export class HoldingsPage extends React.Component<IHoldingsPropsFromState, IHoldingsState> {

    private static getHeader() {
        return <div className="holdings-page__header">
            <div className="holdings-page_header-text">Holdings </div>
        </div>;
    }

    public constructor(props: any) {
        super(props);

        const year = this.getDefaultYear();
        this.state = {
            year,
            month: this.getDefaultMonth(year),
        };
    }

    public render() {
        return <div className="holdings-page">
            {this.renderContent()}
        </div>;
    }

    public renderContent() {
        return this.renderWithData(
            this.props.holdings
                .filter((holding) => {
                   const date = holding.asOfDate;
                   return date.getMonth() + 1 === this.state.month && date.getFullYear() === this.state.year;
                }),
        );
    }

    private renderWithData(holdings: IHolding[]) {

        return <div>
            <div className="holdings-page__header-container">
                {HoldingsPage.getHeader()}
                <span>View as of: </span>
                <div className="holdings-page__header-dropdowns">
                    {this.renderYearsSelect()}
                    {this.renderMonthSelect()}
                </div>
            </div>
            {this.renderDonutChart(holdings)}
            {this.renderAreaChart()}
            <HoldingsComponent holdings={holdings}/>
        </div>;
    }

    private renderDonutChart = (holdings: IHolding[]) => {
        return <HoldingsDonutChartGenerator
            holdings={holdings}
        />;
    };

    private renderAreaChart = () => {
        const data = this.props.holdings.map((holding) => ({
                name: holding.assetClass,
                date: holding.asOfDate,
                value: holding.assets,
            }),
        );

        const props = {inputData: data, month: this.state.month!, year: this.state.year!};

        return <HoldingsAreaChartGenerator {...props}/>;
    };

    private renderYearsSelect() {
        return <SelectComponent
            id={"holdings-page__years"}
            values={this.getYears()}
            selected={this.state.year}
            width={144}
            handleChange={this.handleYearChange}
            classNameSuffix={"filter-menu__asset-class"}
        />;
    }

    private renderMonthSelect() {
        return <SelectComponent
            id={"holdings-page__months"}
            values={this.getMonths(this.state.year)}
            selected={this.state.month}
            width={144}
            handleChange={this.handleMonthChange}
            classNameSuffix={"filter-menu__asset-class"}
        />;
    }

    private getYears(): ISelectValue[] {
        const years = Set(this.props.holdings.map((holding) => holding.asOfDate.getFullYear()));
        return years.map((year: number) => ({name: year.toString(), id: year}))
            .toArray().sort((a, b) => b.id - a.id);
    }

    private getDefaultYear(): number | undefined {
        const years = this.getYears();

        return years.length > 0 ? years[0].id as number : undefined;
    }

    private getDefaultMonth(year: number | undefined): number | undefined {
        if (!year) {
            return undefined;
        }
        const months = this.getMonths(year);
        return months.length > 0 ? months[months.length - 1].id as number : undefined;
    }

    private  getMonths(year: number | undefined): ISelectValue[] {
        if (!year) {
            return [];
        }

        return lodash.uniqWith(this.props.holdings
            .filter((h) => h.asOfDate.getFullYear() === year)
            .map((h) => ({name: moment(h.asOfDate).format("MMMM"), id: h.asOfDate.getMonth() + 1}))
            , lodash.isEqual)
            .sort((a: ISelectValue, b: ISelectValue) => (a.id as number) - (b.id as number));
    }

    private handleYearChange = (e: any) => {
        const year = e.target.value;
        this.setState({...this.state, year, month: this.getDefaultMonth(year)});
    };

    private handleMonthChange = (e: any) => {
        this.setState({...this.state, month: e.target.value});
    };
}

export const mapStateToProps = (state: IApplicationRootState) => {
    return {
        holdings: getSuccessData(holdingsSelector(state))!,
    };
};

const connectedComponent = connect<IHoldingsPropsFromState>(mapStateToProps)(HoldingsPage);

export default multipleApiRequesterWrapper(
    connectedComponent,
    [holdingsRequestPair],
);
