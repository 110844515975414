import * as React from "react";
import {useEffect, useState} from "react";
import {PowerBiReportingApi} from "../../api/PowerBiReportingApi";

export const LatestPowerBiFaq: React.FunctionComponent = () => {
    const [downloadRequested, setDownloadRequested] = useState(false);

    useEffect(() => {
        if (!downloadRequested) {
            PowerBiReportingApi.downloadLatestPowerBiFaq();
            setDownloadRequested(true);
        }
    }, []);

    return null;
};