import {AxiosResponse} from "axios";
import {MediumTermView} from "../model/thought-leadership/MediumTermView.model";
import {MarketOutlookDocument} from "../model/thought-leadership/MarketOutlookDocument.model";
import {WhitePaper} from "../model/thought-leadership/WhitePaper.model";
import {ApiBase} from "./ApiBase";
import {IApiResult} from "./AxiosWealthManagementApi";
import {DocumentType} from "../utils/marketOutlookUtils";
import {InViewReportsApi} from "./InViewReportsApi";
import moment = require("moment");

class InsightsImpl {
    private static validateDate(maybeDate: string, backstopId: number) {
        const date = moment(maybeDate);
        if (!date.isValid()) {
            throw new Error(`Unparsable date ${maybeDate} for mtv with id ${backstopId}`);
        }
        return date;
    }

    private static createMediumTermView(mtv: any) {
        const date = InsightsImpl.validateDate(mtv.effectiveDate, mtv.backstopId);
        return new MediumTermView(date.toDate(), mtv.backstopId, mtv.frequency, mtv.title, mtv.fileName);
    }

    public requestMediumTermViews(): Promise<IApiResult<MediumTermView[]>> {
        return ApiBase
            .processGet("/mtv",
                (response: AxiosResponse) => {
                    return response.data.map(InsightsImpl.createMediumTermView);
                });
    }

    public requestWhitePapers(): Promise<IApiResult<WhitePaper[]>> {
        return ApiBase.processGet("/white-papers",
            (response: AxiosResponse) => {
                return response.data.map((whitePaper: any) => {
                    const date = InsightsImpl.validateDate(whitePaper.effectiveDate, whitePaper.backstopId);
                    return new WhitePaper(
                        date.toDate(),
                        whitePaper.backstopId,
                        whitePaper.title,
                        whitePaper.fileName,
                        whitePaper.isUri,
                        whitePaper.fileUri
                    );
                });
            });
    }

    public requestQuarterlyInvestmentOutlook(): Promise<IApiResult<MarketOutlookDocument[]>> {
        return ApiBase.processGet("/quarterly-investment-outlook",
            (response: AxiosResponse) => {
                return response.data.map((doc: any) => InsightsImpl.mapMarketOutlookDocument(DocumentType.QIO, doc));
            });
    }

    public requestMarketBackgrounds(): Promise<IApiResult<MarketOutlookDocument[]>> {
        return ApiBase.processGet("/market-backgrounds",
            (response: AxiosResponse) => {
                return response.data.map((doc: any) => InsightsImpl.mapMarketOutlookDocument(DocumentType.MB, doc));
            });
    }

    private static mapMarketOutlookDocument (documentType: DocumentType, document: any) {
        const date = InsightsImpl.validateDate(document.effectiveDate, document.backstopId);

        return new MarketOutlookDocument(
            document.title,
            document.backstopId,
            date.toDate(),
            document.fileName,
            document.activityTag,
            documentType
        );
    }

    public getDocumentDownload(backstopId: number, filename: string, isNewTab: boolean) {
        InViewReportsApi.downloadReport(backstopId, filename, isNewTab);
    }
}

export const InsightsApi = new InsightsImpl();
