import * as React from "react";
import {IFundingLevel} from "../../api/CprPortfolioApi";
import {formatShortDate} from "../../utils/dateUtil";
import {formatPercentBasedOnLocale} from "../../utils/numberUtil";
import {FormattedMessage} from "react-intl";

export interface IFundingLevelProps {
    fundingLevel: IFundingLevel;
    isPostQuarterUpdate: boolean;
}

export const CprFundingLevel: React.FunctionComponent<IFundingLevelProps> = (props: IFundingLevelProps) => {

    const value = props.fundingLevel!.value ? props.fundingLevel!.value : 0;
    const valuePercentage = formatPercentBasedOnLocale(Number(value) * 100);

    return <div className="funding-level-container" data-testid="funding-level-container">
        <div className="funding-level__title">
            <h3><FormattedMessage id="home.funding-level-title" defaultMessage="Funding Level"/></h3>
        </div>
        <div className="funding-level__basis">Using {props.fundingLevel!.basis}</div>
        <div className="funding-level__value">{valuePercentage}</div>
        {props.isPostQuarterUpdate &&
            <div className="funding-level__date" data-testid="funding-level__date">
                <FormattedMessage id="home.post-quarter-update-as-of-date"
                                  defaultMessage="As of"/> {formatShortDate(props.fundingLevel!.asOfDate)}
            </div>}
    </div>;

};