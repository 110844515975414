import * as React from "react";

export interface IChipsListProps {
    className?: string;
    values: string[];
    onRemove?: (value: string) => void;
}

export const ChipsList: React.FunctionComponent<IChipsListProps> = (props) => {
    function renderChip(value: string) {
        return <div className="chip-list__chip" key={value}>
            {value}
            {
                props.onRemove
                    ? <i className="fas fa-times remove-chip__icon clickable" onClick={() => props.onRemove!(value)}/>
                    : null
            }
        </div>;
    }

    return <div className={`shared-components__chip-list ${props.className ? props.className : ""}`}>
        {props.values.map((value) => renderChip(value))}
    </div>;
};
