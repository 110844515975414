import * as React from "react";
import {withVerifyAccess} from "../common/withVerifyAccess";
import {UserTypeEnum} from "../../model/UserInfo.model";
import {useEffect, useState} from "react";
import {CalendarApi, ICalendarTemplateTopic} from "../../api/CalendarApi";
import {byName} from "../../utils/listUtil";
import {ALL_PLAN_TYPES, byPlanType} from "../calendar/CalendarUtils";
import {AdminCalendarTopicFilter} from "./AdminCalendarTopicFilter";
import {Link} from "react-router-dom";
import {prominentButtonStyle} from "../common/buttonStyles";
import {RaisedButton} from "../common/RaisedButton";
import {navigateTo} from "../../../navigateTo";


export const CALENDAR_TOPIC_ADMINISTRATION = "Calendar Topic Administration";
export const TOPIC_NAME = "Topic Name";
export const PLAN_TYPE = "Plan Type";
export const FREQUENCY = "Frequency";

export const AdminCalendarTopicsPage: React.FunctionComponent = () => {
    const [allTopics, setAllTopics] = useState<ICalendarTemplateTopic[]>([]);
    const [filteredTopics, setFilteredTopics] = useState<ICalendarTemplateTopic[]>([]);

    const fetchCalendarTemplateTopics = () => {
        CalendarApi.requestAllTemplateTopics()
            .then((response) => {
                const sortedResponse = response
                    .sort(byName)
                    .sort(byPlanType);
                setFilteredTopics(sortedResponse);
                setAllTopics(sortedResponse);
            });
    };

    useEffect(() => {
        fetchCalendarTemplateTopics();
    }, []);

    const renderCalendarTopic = (calendarTemplateTopic: ICalendarTemplateTopic) => {
        return <tr
            className="admin-calendar-topic__row"
            data-testid="admin-calendar-topic__row"
            key={calendarTemplateTopic.id}>
            <td className="admin-calendar-topic__topic-name">{calendarTemplateTopic.name}</td>
            <td className="admin-calendar-topic__plan-type">{calendarTemplateTopic.planTypeName}</td>
            <td className="admin-calendar-topic__frequency">{calendarTemplateTopic.frequency}</td>
            <td className="admin-calendar-topic__edit">
                <Link to={{
                    pathname: "/admin-calendar-topic/edit",
                    state: {calendarTopic: calendarTemplateTopic}
                }} data-testid="admin-calendar-topic__edit-link">Edit</Link>
            </td>
            <td className="admin-calendar-topic__delete">Delete</td>
        </tr>;
    };

    const renderCalendarTopics = () => {
        return filteredTopics.map(renderCalendarTopic);
    };

    const renderCalendarTopicsTable = () => {
        return <div className="admin-calendar-topic__table">
            <table>
                <thead>
                <tr>
                    <th className="admin-calendar-topic__table-topic-name">{TOPIC_NAME}</th>
                    <th className="admin-calendar-topic__table-plan-type">{PLAN_TYPE}</th>
                    <th className="admin-calendar-topic__table-frequency">{FREQUENCY}</th>
                    <th className="admin-calendar-topic__table-empty"/>
                    <th className="admin-calendar-topic__table-empty"/>
                </tr>
                </thead>
                <tbody>{renderCalendarTopics()}</tbody>
            </table>
        </div>;
    };

    const filterTopicsByPlanType = (planTypeId: number) => {
        if (planTypeId === ALL_PLAN_TYPES) {
            setFilteredTopics(allTopics);
            return;
        }
        setFilteredTopics(allTopics.filter(it => it.planTypeId === planTypeId));
    };

    return <div className="new-common-styles admin-calendar-topic__container"
                data-testid="admin-calendar-topic__container">
        <h1 className="admin-calendar-topic__header">{CALENDAR_TOPIC_ADMINISTRATION}</h1>
        <div className="admin-calendar-topic__filter-add-container">
            <div className="admin-calendar-topic__filter-container">
                <AdminCalendarTopicFilter onFilterChange={filterTopicsByPlanType}/>
            </div>
            <div className="admin-calendar-topic__add-new-topic">
                <RaisedButton className="admin-calendar-topic__add-new-topic-button"
                              style={prominentButtonStyle} primary={true}
                              onClick={() => navigateTo("/admin-calendar-topic/new")}>
                    + Add Topic
                </RaisedButton>
            </div>
        </div>
        <div className="admin-calendar-topic__table-container">
            {renderCalendarTopicsTable()}
        </div>
    </div>;
};

export default withVerifyAccess(AdminCalendarTopicsPage, UserTypeEnum.ADMIN);