import * as React from "react";
import {useEffect, useState} from "react";
import {prominentButtonStyle, secondaryButtonStyle} from "./buttonStyles";
import {RaisedButton} from "./RaisedButton";

export interface IDialogProps {
    noText: string;
    yesText: string;
    title: string;
    description: string;
    open: boolean;
    onYes: () => void;
    onNo: () => void;
    hideNoButton?: boolean;
}

export const DialogComponent: React.FunctionComponent<IDialogProps> = (props: IDialogProps) => {
    if (props.open) {
        document.body.style.overflow = "hidden";
    } else {
        document.body.style.overflow = "";
    }

    const [animateClassOverlay, setAnimateClassOverlay] = useState<string>("");
    const [animateClassBox, setAnimateClassBox] = useState<string>("");

    useEffect(() => {
        if (props.open) {
            setAnimateClassOverlay(" dialog__overlay__animate");
            setAnimateClassBox(" dialog__box__animate");
        } else {
            setAnimateClassOverlay("");
            setAnimateClassBox("");
        }
    }, [props]);

    function renderCancelButton() {
        return props.hideNoButton
        ? null
            :  <RaisedButton key="cancel"
                             style={secondaryButtonStyle}
                             onClick={props.onNo}
                             primary={false}
                             className="dialog__box__no__button"
            >
                {props.noText}
            </RaisedButton>;
    }
    function renderDialogOverlay() {
        return <div
                className={`dialog__overlay odd-page__container${animateClassOverlay}`}
                data-testid="dialog__overlay"
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
        >
            <div className={`dialog__box${animateClassBox}`}>
                <h3 className="dialog__box__title">
                    {props.title}
                </h3>
                <div className="dialog__box__description small">
                    {props.description}
                </div>
                <div className="dialog__box__actions">
                    {renderCancelButton()}
                    <RaisedButton key="delete"
                                  primary={true}
                                  style={{...prominentButtonStyle, marginBottom: 15, marginLeft: 15, marginRight: 15}}
                                  onClick={props.onYes}
                                  className="dialog__box__yes__button"
                    >
                        {props.yesText}
                    </RaisedButton>
                </div>
            </div>
        </div>;
    }

    return props.open ? renderDialogOverlay() : null;
};
