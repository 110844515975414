import * as React from "react";
import {useEffect, useState} from "react";
import {IPlanInfo} from "../../model/ClientsAndPlans.model";
import AdminClientInfoRequester from "../admin/AdminClientInfoRequester";
import AdminAddPlanComponent from "../admin/AdminAddPlan.component";
import {IClientReportCategory, IReportWorkspace, PowerBiReportingApi} from "../../api/PowerBiReportingApi";
import {SelectComponentReact} from "../common/ReactSelectComponent";
import AddClientReport from "./AddClientReport";

export const PowerBiAdminAddReport: React.FunctionComponent = () => {
    const [selectedPlan, setSelectedPlan] = useState<IPlanInfo | undefined>(undefined);
    const [categories, setCategories] = useState<IClientReportCategory[]>([]);
    const [selectedCategory, setSelectedCategory] = useState<IClientReportCategory | undefined>(undefined);
    const [selectPlanComponentKey, setSelectPlanComponentKey] = useState<number>(Math.random());
    const [selectedWorkspace, setSelectedWorkspace] = useState<IReportWorkspace | undefined>(undefined);

    const handleSelectPlan = (planInfo?: IPlanInfo) => {
        setSelectedCategory(undefined);
        setSelectedPlan(planInfo);
    };

    const handleSelectType = (option: any) => {
        setSelectedWorkspace(undefined);
        const newCategory = categories.find((category) => category.id === option.id);
        setSelectedCategory(newCategory);
        if(newCategory!.workspaces.length === 1) {
            setSelectedWorkspace(newCategory!.workspaces[0]);
        }
    };
    const handleWorkspaceSelection = (option: any) => {
        const workspace = selectedCategory!.workspaces.find((ws) => ws.id === option.id);
        setSelectedWorkspace(workspace!);
    };

    const renderWorkspaces = () => {
        if (selectedCategory) {
            if (selectedCategory.workspaces.length > 1) {
                return <div className="powerbi-admin__workspace-dropdown">
                    <SelectComponentReact
                        menuIsOpen={undefined}
                        id={"workspace-dropdown"}
                        options={selectedCategory.workspaces}
                        value={selectedWorkspace ? selectedWorkspace : null}
                        onChange={handleWorkspaceSelection}
                        submitClicked={false}
                        getOptionLabel={(option: IReportWorkspace) => option.name}
                        getOptionValue={(option: IReportWorkspace) => option.id}
                        renderRequired={false}
                        placeholder={"Select Workspace"}
                        stylesObject={{width: "100%"}}
                    />
                </div>;
            } else {
                return <div className="powerbi-admin__workspace-label">{selectedWorkspace?.name}</div>;
            }
        }
    };

    const renderReportTypeSelection = () => {
        return selectedPlan
            ? <div className="powerbi-admin__report-type-selection">
                <div className="powerbi-admin__report-type-container">
                    <div className="powerbi-admin__label">Report Type</div>
                    <div className="powerbi-admin__report-type-dropdown">
                        <SelectComponentReact
                            menuIsOpen={undefined}
                            id={"categories-dropdown"}
                            options={categories}
                            value={selectedCategory ? selectedCategory : null}
                            onChange={handleSelectType}
                            submitClicked={false}
                            getOptionLabel={(option: IClientReportCategory) => option.name}
                            getOptionValue={(option: IClientReportCategory) => option.id.toString(10)}
                            renderRequired={false}
                            placeholder="Select Report Type..."
                            stylesObject={{width: "100%"}}
                        />
                    </div>
                </div>
                <div className="powerbi-admin__report-type-workspace">
                    <div className="powerbi-admin__label">PowerBi Workspace</div>
                    {renderWorkspaces()}
                </div>
            </div>
            : null;
    };

    const setShowAddReport = (show: boolean) => {
        if (!show) {
            setSelectedCategory(undefined);
            setSelectedPlan(undefined);
            setSelectPlanComponentKey(Math.random);
        }
    };

    const renderAddClientReport = () => {
        return selectedCategory
            ? <AddClientReport setShowAddReport={setShowAddReport}
                               categoryId={selectedCategory.id}
                               planId={selectedPlan!.id}
                               workspace={selectedWorkspace}
            />
            : null;
    };

    const renderUploadReport = () => {
        return selectedPlan
            ? <div data-testid="powerbi-admin__upload-report-container">
                <h2 className="powerbi-admin__upload-report-header">{`Upload Report for ${selectedPlan.name}`}</h2>
                {renderReportTypeSelection()}
                {renderAddClientReport()}
            </div>
            : null;
    };

    useEffect(() => {
        PowerBiReportingApi.requestClientReportCategories()
            .then((response) => setCategories(response));
    }, []);

    return <div className="powerbi-admin__add-report" data-testid="powerbi-admin__add-report">
            <h2 className="powerbi-report-admin__sub-header">Add a new PowerBI Report</h2>
            <AdminClientInfoRequester>
                <AdminAddPlanComponent
                    key={selectPlanComponentKey}
                    onAddPlan={handleSelectPlan}
                    renderRequired={true}
                    submitClicked={true}
                    plansSelected={!!selectedPlan}
                    isSinglePlanSelection={true}
                />
            </AdminClientInfoRequester>
            {renderUploadReport()}
        </div>;
};

