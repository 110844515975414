import {AxiosResponse} from "axios";
import {Map} from "immutable";
import {ApiBase} from "./ApiBase";
import {IApiResult} from "./AxiosWealthManagementApi";

export interface IOddResponseSummary {
    exceptions: number;
    bestPractice: number;
    noData: number;
    name?: string;
}

export interface IOddResponsePercentSummary {
    exceptions: number;
    bestPractice: number;
    noData: number;
    name?: string;
}

export interface IOddManager {
    name: string;
    firmId: string;
    productBackstopIds: number[],
    city: string;
    country: string;
    dateResponded?: string;
}

export interface IOddResponse {
    productBackstopId: number;
    questionId: number;
    responseCodeId: OddResponseCode;
    managerResponse?: string;
    bestPractice?: string;
    description?: string;
    productId: number;
}

export type IOddResponseEntity = IOddResponse & { id: number };

export enum OddResponseCode {
    NO_DATA = 1,
    BEST_PRACTICE = 2,
    EXCEPTION = 3,
}

export enum OddResponseName {
    EXCEPTIONS = "Exceptions",
    BEST_PRACTICE = "Best Practice",
    NO_DATA = "No Information",
}

export enum OddLevelId {
    Manager = 1,
    Strategy = 2,
}

export interface IOddSectionEntity {
    id: number;
    name: string;
}

export interface IOddQuestionEntity {
    id: number;
    questionTitle: string;
    sectionId: number;
    levelId: OddLevelId;
}

export interface IOddSectionsQuestions {
    sections: IOddSectionEntity[];
    questions: IOddQuestionEntity[];
}

export class OddIqApi {
    public static requestOddManagers(): Promise<IOddManager[]> {
        return ApiBase.processGetUnwrapped("/plans/current/odd-managers",
            (response: AxiosResponse): any => {
                return response.data;
            });
    }

    public static requestOddResponsesByBackstopForCurrentPlan() {
        const uri = "/plans/current/odd-responses-by-backstop";
        return ApiBase.processGetUnwrapped(uri,
            (response: AxiosResponse): any => {
                return (response.data as IOddResponseEntity[]).map(this.mapOddResponse);
            });
    }

    public static requestOddResponsesForProduct(productId: number): Promise<IOddResponse[]> {
        const uri = `/odd-responses/products/${productId}`;
        return ApiBase.processGetUnwrapped(uri,
            (response: AxiosResponse): any => {
                return (response.data as IOddResponseEntity[]).map(this.mapOddResponse);
            });
    }

    private static mapOddResponse = (entity: IOddResponseEntity):
        IOddResponse => {
        return {
            responseCodeId: entity.responseCodeId,
            questionId: entity.questionId,
            productBackstopId: entity.productBackstopId ? entity.productBackstopId : -1,
            managerResponse: entity.managerResponse,
            bestPractice: entity.bestPractice,
            description: entity.description,
            productId: entity.productId,
        };
    };

    public static requestOddSectionsAndQuestions() {
        const uri = "/odd/sections-questions";
        return ApiBase.processGetUnwrapped(uri,
            (response: AxiosResponse): any => {
                const entity: IOddSectionsQuestions = response.data;
                return {
                    sections: entity.sections,
                    questions: entity.questions,
                };
            });
    }

    public static requestOddProductDateRespondedForCurrentPlan(): Promise<Map<number, string>> {
        return ApiBase.processGetUnwrapped("/plans/current/odd-response-dates",
            (response: AxiosResponse): any => {
                let productIdToResponseDates = Map<number, string>();

                (Object as any).entries(response.data).map(([productBackstopId, date]: (any)[]) => {
                    productIdToResponseDates =
                        productIdToResponseDates.set(parseInt(productBackstopId, 10), date);
                });

                return productIdToResponseDates;
            });
    }

    public static uploadOdd(formData: FormData): Promise<IApiResult<IOddUploadResult>> {
        return ApiBase.processPostWithResponseBody("/odd/load", formData, (response: AxiosResponse) => {
            return {isSuccess: response.data.first, errors: response.data.second};
        });
    }

    public static uploadOddSec(formData: FormData): Promise<IApiResult<IOddUploadResult>> {
        return ApiBase.processPostWithResponseBody("/odd/sec/upload", formData, (response: AxiosResponse) => {
            return {isSuccess: response.data.first, errors: response.data.second};
        });
    }

    public static requestOddProductsAccess(): Promise<IOddProduct[]> {
        return ApiBase.processGetUnwrapped("/plans/current/odd-products-access",
            (response: AxiosResponse): any => {
                return response.data as IOddProductsAccess;
            });
    }

    public static requestOddQuestionSummaries(): Promise<IOddQuestionSummary[]> {
        return ApiBase.processGetUnwrapped("/plans/current/odd-question-summaries",
            (response: AxiosResponse): any => {
                return response.data as IOddQuestionSummary[];
            });
    }

    public static requestOddQuestionSummariesForPortfolio(): Promise<IOddQuestionSummary[]> {
        return ApiBase.processGetUnwrapped("/plans/current/odd-question-summaries/portfolio",
            (response: AxiosResponse): any => {
                return response.data as IOddQuestionSummary[];
            });
    }

    public static requestOddSummariesByProduct(): Promise<Map<number, IOddResponseSummary>> {
        return ApiBase.processGetUnwrapped("/plans/current/odd-product-summaries",
            (response: AxiosResponse): any => {
                return (Object as any).entries(response.data)
                    .reduce(OddIqApi.oddSummaryByProductReducer, Map());
            });
    }

    static oddSummaryByProductReducer = (all: Map<number, IOddResponseSummary>, [key, value]: (any)[]) => {
       return all.set(parseInt(key, 10), {
           exceptions: value.exceptions,
           bestPractice: value.bestPractice,
           noData: value.noData,
       } );
    };
}

export interface IOddUploadResult {
    isSuccess: boolean;
    errors: string [];
}

export interface IOddProduct {
    id: number,
    name: string,
    vehicleId: string,
    managerName?: string,
    dateResponded?: string,
}

export interface IOddProductsAccess {
    allAndPortfolioAccess: boolean,
    accessibleProducts: IOddProduct[],
    portfolioProducts: string[]
}

export interface IOddQuestionSummary {
    questionId: number,
    exceptionProducts: number[];
    summary: IOddResponseSummary;
}
