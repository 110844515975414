import {Map} from "immutable";
import {Action, handleActions} from "redux-actions";
import {
    IOddManager,
    IOddProductsAccess,
    IOddResponse,
    IOddSectionsQuestions,
    IOddResponseSummary,
} from "../../api/OddIqApi";
import {OperationalDueDiligenceDocument} from "../../model/OperationalDueDiligenceDocument.model";
import {NOT_REQUESTED, REQUEST_FAILED, REQUEST_SUCCEEDED, REQUESTED, RequestState} from "../common/commonStates";
import {OddIqActions} from "./OddIqActions";

export interface IOddIQStore {
    questionsSummary: RequestState<IOddResponseSummary>;
    oddSectionsQuestions: RequestState<IOddSectionsQuestions>;
    oddResponsesByBackstop: RequestState<IOddResponse[]>;
    oddProductDateResponded: RequestState<Map<number, string>>;
    oddCurrentQuestionId?: number;
    oddDocuments: RequestState<OperationalDueDiligenceDocument[]>;
    oddManagers: RequestState<IOddManager[]>;
    oddProductsAccess: RequestState<IOddProductsAccess>;
    oddPortfolioView: boolean;
}

export const oddIQInitialState: IOddIQStore = {
    questionsSummary: NOT_REQUESTED,
    oddSectionsQuestions: NOT_REQUESTED,
    oddResponsesByBackstop: NOT_REQUESTED,
    oddProductDateResponded: NOT_REQUESTED,
    oddCurrentQuestionId: undefined,
    oddDocuments: NOT_REQUESTED,
    oddManagers: NOT_REQUESTED,
    oddProductsAccess: NOT_REQUESTED,
    oddPortfolioView: true,
};

const reducerMap = {
    [OddIqActions.OPERATIONAL_DUE_DILIGENCE_REVIEW_REQUESTED]:
        (state: IOddIQStore): IOddIQStore => {
            return {...state, questionsSummary: REQUESTED};
        },
    [OddIqActions.OPERATIONAL_DUE_DILIGENCE_REVIEW_FAILED]:
        (state: IOddIQStore, action: Action<any>): IOddIQStore => {
            return {...state, questionsSummary: REQUEST_FAILED(action.payload!)};
        },
    [OddIqActions.OPERATIONAL_DUE_DILIGENCE_REVIEW_SUCCEEDED]:
        (state: IOddIQStore, action: Action<IOddResponseSummary>)
            : IOddIQStore => {
            return {...state, questionsSummary: REQUEST_SUCCEEDED(action.payload!)};
        },
    [OddIqActions.CLEAR_OPERATIONAL_DUE_DILIGENCE_REVIEW]:
        (state: IOddIQStore)
            : IOddIQStore => {
            return {...state, questionsSummary: NOT_REQUESTED};
        },
    [OddIqActions.ODD_RESPONSES_BY_BACKSTOP_REQUESTED]:
        (state: IOddIQStore)
            : IOddIQStore => {
            return {...state, oddResponsesByBackstop: REQUESTED};
        },
    [OddIqActions.ODD_RESPONSES_BY_BACKSTOP_SUCCEEDED]:
        (state: IOddIQStore, action: Action<IOddResponse[]>)
            : IOddIQStore => {
            return {...state, oddResponsesByBackstop: REQUEST_SUCCEEDED(action.payload!)};
        },
    [OddIqActions.ODD_RESPONSES_BY_BACKSTOP_FAILED]:
        (state: IOddIQStore, action: Action<any>)
            : IOddIQStore => {
            return {...state, oddResponsesByBackstop: REQUEST_FAILED(action.payload)};
        },
    [OddIqActions.ODD_SECTIONS_AND_QUESTIONS_REQUESTED]:
        (state: IOddIQStore)
            : IOddIQStore => {
            return {...state, oddSectionsQuestions: REQUESTED};
        },
    [OddIqActions.ODD_SECTIONS_AND_QUESTIONS_SUCCEEDED]:
        (state: IOddIQStore, action: Action<IOddSectionsQuestions>)
            : IOddIQStore => {
            return {...state, oddSectionsQuestions: REQUEST_SUCCEEDED(action.payload!)};
        },
    [OddIqActions.ODD_SECTIONS_AND_QUESTIONS_FAILED]:
        (state: IOddIQStore, action: Action<any>)
            : IOddIQStore => {
            return {...state, oddSectionsQuestions: REQUEST_FAILED(action.payload)};
        },
    [OddIqActions.ODD_PRODUCT_DATE_RESPONDED_REQUESTED]:
        (state: IOddIQStore)
            : IOddIQStore => {
            return {...state, oddProductDateResponded: REQUESTED};
        },
    [OddIqActions.ODD_PRODUCT_DATE_RESPONDED_SUCCEEDED]:
        (state: IOddIQStore, action: Action<Map<number, string>>)
            : IOddIQStore => {
            return {...state, oddProductDateResponded: REQUEST_SUCCEEDED(action.payload!)};
        },
    [OddIqActions.ODD_PRODUCT_DATE_RESPONDED_FAILED]:
        (state: IOddIQStore, action: Action<any>)
            : IOddIQStore => {
            return {...state, oddProductDateResponded: REQUEST_FAILED(action.payload)};
        },
    [OddIqActions.ODD_SET_CURRENT_QUESTION_ID]:
        (state: IOddIQStore, action: Action<any>)
            : IOddIQStore => {
            return {...state, oddCurrentQuestionId: action.payload};
        },
    [OddIqActions.ODD_DOCUMENTS_REQUESTED]:
        (state: IOddIQStore)
            : IOddIQStore => {
            return {...state, oddDocuments: REQUESTED};
        },
    [OddIqActions.ODD_DOCUMENTS_SUCCEEDED]:
        (state: IOddIQStore, action: Action<OperationalDueDiligenceDocument[]>)
            : IOddIQStore => {
            return {...state, oddDocuments: REQUEST_SUCCEEDED(action.payload!)};
        },
    [OddIqActions.ODD_DOCUMENTS_FAILED]:
        (state: IOddIQStore, action: Action<any>)
            : IOddIQStore => {
            return {...state, oddDocuments: REQUEST_FAILED(action.payload)};
        },
    [OddIqActions.ODD_MANAGERS_REQUESTED]:
        (state: IOddIQStore)
            : IOddIQStore => {
            return {...state, oddManagers: REQUESTED};
        },
    [OddIqActions.ODD_MANAGERS_SUCCEEDED]:
        (state: IOddIQStore, action: Action<IOddManager[]>)
            : IOddIQStore => {
            return {...state, oddManagers: REQUEST_SUCCEEDED(action.payload!)};
        },
    [OddIqActions.ODD_MANAGERS_FAILED]:
        (state: IOddIQStore, action: Action<any>)
            : IOddIQStore => {
            return {...state, oddManagers: REQUEST_FAILED(action.payload)};
        },
    [OddIqActions.ODD_PRODUCTS_ACCESS_REQUESTED]:
        (state: IOddIQStore)
            : IOddIQStore => {
            return {...state, oddProductsAccess: REQUESTED};
        },
    [OddIqActions.ODD_PRODUCTS_ACCESS_SUCCEEDED]:
        (state: IOddIQStore, action: Action<IOddProductsAccess>)
            : IOddIQStore => {
            return {...state, oddProductsAccess: REQUEST_SUCCEEDED(action.payload!)};
        },
    [OddIqActions.ODD_PRODUCTS_ACCESS_FAILED]:
        (state: IOddIQStore, action: Action<any>)
            : IOddIQStore => {
            return {...state, oddProductsAccess: REQUEST_FAILED(action.payload)};
        },
    [OddIqActions.ODD_SET_PORTFOLIO_VIEW]:
        (state: IOddIQStore, action: Action<any>)
            : IOddIQStore => {
            return {...state, oddPortfolioView: action.payload};
        },
};

export const operationalDueDiligenceQuestionSummaryInner = (state?: IOddIQStore)
    : RequestState<IOddResponseSummary> => {
    return state ? state.questionsSummary : NOT_REQUESTED;
};

export const operationalDueDiligenceSectionsQuestionsInner = (state?: IOddIQStore)
    : RequestState<IOddSectionsQuestions> => {
    return state ? state.oddSectionsQuestions : NOT_REQUESTED;
};

export const oddResponsesByBackstopInner = (state?: IOddIQStore)
    : RequestState<IOddResponse[]> => {
    return state ? state.oddResponsesByBackstop : NOT_REQUESTED;
};

export const oddProductDateRespondedSelectorInner = (state?: IOddIQStore): RequestState<Map<number, string>> => {
    return state ? state.oddProductDateResponded : NOT_REQUESTED;
};

export const oddCurrentQuestionIdInner  = (state?: IOddIQStore): number | undefined => {
    return state ? state.oddCurrentQuestionId : undefined;
};

export const oddDocumentsSelectorInner = (state?: IOddIQStore):
    RequestState<OperationalDueDiligenceDocument[]> => {
    return state ? state.oddDocuments : NOT_REQUESTED;
};

export const oddManagersSelectorInner = (state?: IOddIQStore):
    RequestState<IOddManager[]> => {
    return state ? state.oddManagers : NOT_REQUESTED;
};

export const oddProductsAccessSelectorInner = (state?: IOddIQStore):
    RequestState<IOddProductsAccess> => {
    return state ? state.oddProductsAccess : NOT_REQUESTED;
};

export const oddPortfolioViewInner = (state?: IOddIQStore): boolean => {
    return  state ? state.oddPortfolioView : true;
};


type oddStoreTypes =
    IOddResponseSummary
    & IOddSectionsQuestions
    & IOddResponse[]
    & Map<number, string>
    & number
    & OperationalDueDiligenceDocument[]
    & IOddManager[]
    & IOddProductsAccess;

export default handleActions<IOddIQStore, oddStoreTypes>(
    reducerMap,
    oddIQInitialState,
);
