import {IHolding} from "../../api/HoldingsApi";
import {getEndOfMonthDate} from "../../utils/dateUtil";
import {filterBy, getAssetsForGroupAndMonth, getHoldingsForMonth} from "./InvestmentsDataUtil";
import {getValue} from "./cash-flows/CashFlowsUtils";
import {HoldingsGroupBy} from "./InvestmentsPage";

export interface IHoldingTrend {
    beginningAssets: number;
    endAssets: number;
    inflow: number;
    outflow: number;
    gains: number;
}
function getSums(holdings: IHolding[]): IHoldingTrend {
    const initialAcc = {
        beginningAssets: 0,
        endAssets: 0,
        inflow: 0,
        outflow: 0,
        gains: 0,
    };
    const accumulated = holdings
        .reduce((acc: IHoldingTrend, holding) => {return accumulator(holding, acc); },
            initialAcc);

    return {
        beginningAssets: 0,
        endAssets: 0,
        inflow: Math.round(accumulated.inflow),
        outflow: Math.round(accumulated.outflow),
        gains: Math.round(accumulated.gains)
    };
}

const accumulator = (holding: IHolding, acc: IHoldingTrend): IHoldingTrend => {
    acc.inflow = acc.inflow + getValue(holding.inflow);
    acc.outflow = acc.outflow + getValue(holding.outflow);
    acc.gains = acc.gains + getValue(holding.gains);
    return acc;
};

function getTrendYearAndMonth(currentYear: number, currentMonth: number, numberOfMonthsBack: number) {
    const monthBack = currentMonth - numberOfMonthsBack;
    const trendYear = (monthBack < 0) ? currentYear - 1 : currentYear;
    const trendMonth = (monthBack < 0) ? 12 + monthBack : currentMonth - numberOfMonthsBack;
    return {year: trendYear, month: trendMonth};
}

const getTrendSums =
    (holdings: IHolding[],
     yearStart: number,
     monthStart: number,
     yearEnd: number,
     monthEnd: number,
     groupName: string,
     viewBy: HoldingsGroupBy): IHoldingTrend => {
        const endOfMonthDate = getEndOfMonthDate(yearStart, monthStart);
        const end = getEndOfMonthDate(yearEnd, monthEnd);
        const filtered =  holdings
            .filter((holding) => {
                return holding.asOfDate > endOfMonthDate && holding.asOfDate <= end;
            })
            .filter((holding) => filterBy(holding, viewBy, groupName));

        return getSums(
           filtered
        );
    };

const hasData = (holdings: IHolding[],
                        currentYear: number,
                        currentMonth: number,
                        groupName: string,
                        viewBy: HoldingsGroupBy) => {


    return getHoldingsForMonth(holdings, currentYear, currentMonth)
        .filter((holding) => filterBy(holding, viewBy, groupName))
        .length > 0;

};


export const getTrendData = (holdings: IHolding[],
                             currentYear: number,
                             currentMonth: number,
                             numberOfMonthsBack: number,
                             groupName: string,
                             viewBy: HoldingsGroupBy) => {

    const trendYearAndMonth = getTrendYearAndMonth(currentYear, currentMonth, numberOfMonthsBack);

    if(!hasData(holdings, trendYearAndMonth.year, trendYearAndMonth.month, groupName, viewBy)) {
        return null;
    }

    const trend = getTrendSums(holdings,
        trendYearAndMonth.year,
        trendYearAndMonth.month,
        currentYear,
        currentMonth,
        groupName,
        viewBy);

    trend.beginningAssets = getAssetsForGroupAndMonth(
        holdings, trendYearAndMonth.year, trendYearAndMonth.month, groupName, viewBy);
    trend.endAssets = getAssetsForGroupAndMonth(holdings, currentYear, currentMonth, groupName, viewBy);

    return trend;
};
