import {ClientsAndPlans, IClientInfo, IClientPlanInfo} from "../../model/ClientsAndPlans.model";
import {byName} from "../../utils/listUtil";
import {LANGUAGE_SELECT_VALUES} from "./AdminCreatePlan.component";
import {COUNTRY_SELECT_VALUES, FilterBy, FilterOperator, IFilter} from "./AdminAllClients";
import {assertNever} from "../../utils/errorUtil";

export const ClientsAndPlansMapToArray = (clientsAndPlansMap: ClientsAndPlans): IClientPlanInfo[] => {
    const toArrayOfClientPlansReducer = (old: IClientPlanInfo[], client: IClientInfo) => {
        const clientPlansArray: IClientPlanInfo[] = client.plans
            .sort(byName)
            .valueSeq()
            .toArray()
            .map((plan) => {
                return {
                    clientId: client.id,
                    clientName: client.name,
                    planId: plan.id,
                    planName: plan.name,
                    powerbiId: plan.powerbiId,
                    country: COUNTRY_SELECT_VALUES.find(it => it.id === plan.countryId)!.name,
                    language: LANGUAGE_SELECT_VALUES.find(it => it.id === plan.languageId!)!.name
                };
            });
        return old.concat(clientPlansArray);
    };

    return clientsAndPlansMap
        .sort(byName)
        .valueSeq()
        .toArray()
        .reduce(toArrayOfClientPlansReducer, []);
};

export const filterClientsAndPlans = (
    clientsAndPlans: IClientPlanInfo[],
    filter: IFilter,
): IClientPlanInfo[] => {
    return clientsAndPlans.filter(getPredicate(filter));
};

const getFilterFieldValue = (clientPlan: IClientPlanInfo, filterBy: FilterBy): string | undefined => {
    switch(filterBy) {
        case FilterBy.CLIENT:
            return clientPlan.clientName;
        case FilterBy.PLAN:
            return clientPlan.planName;
        case FilterBy.POWERBI_ID:
            return clientPlan.powerbiId;
        case FilterBy.PLAN_LANGUAGE:
            return clientPlan.language;
        case FilterBy.PLAN_COUNTRY:
            return clientPlan.country;
        default:
            return assertNever(filterBy);
    }
};

const getPredicateCondition = (
    clientPlan: IClientPlanInfo,
    filter: IFilter): boolean => {
    const valueToCheck = getFilterFieldValue(clientPlan, filter.filterBy)?.toUpperCase() ?? "";
    const value = filter.value.toUpperCase();

    switch (filter.operator) {
        case FilterOperator.CONTAINS:
            return valueToCheck.indexOf(value) > -1;
        case FilterOperator.DOESNT_CONTAIN:
            return valueToCheck.indexOf(value) === -1;
        case FilterOperator.EQUALS:
            return valueToCheck === value;
        case FilterOperator.DOESNT_EQUAL:
            return valueToCheck !== value;
        case FilterOperator.NOT_EMPTY:
            return valueToCheck.trim().length > 0;
        default:
            return assertNever(filter.operator);
    }
};

const getPredicate = (filter: IFilter) => {
    return (clientPlan: IClientPlanInfo) => getPredicateCondition(
        clientPlan,
        filter
    );
};