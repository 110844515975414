import * as React from "react";
import {useState} from "react";
import {IInformESGQuestion} from "../../model/inform/InformESG";

export interface IInformESGResponsesRowProps {
    header: string,
    questions?: IInformESGQuestion[],
    index: number,
    expandedRowIndex: number | undefined,
    setExpandedRowIndex: (index: number) => void,
}

export const InformESGResponsesTable: React.FunctionComponent<IInformESGResponsesRowProps> = (props) => {
    const renderExpandRowIcon = () => {
        return (props.questions && props.questions.length > 0)
            ? <i data-testid="inform-esg-responses__expand-icon"
                 className={props.expandedRowIndex === props.index ? "fa fa-regular fa-minus minus" : "fa fa-regular fa-plus plus"}/>
            : null;
    };

    const renderResponseTable = () => {
        return props.expandedRowIndex === props.index && (props.questions && props.questions.length > 0)
            ? <tr>
                <td colSpan={2} className="inform-esg-responses__sub-table-container expanded">
                    <table className="inform-esg-responses__sub-table" data-testid="inform-esg-responses__sub-table">
                        <thead>
                        <tr>
                            <th className="inform-esg-responses__table-level">Level</th>
                            <th className="inform-esg-responses__table-question">Question</th>
                            <th className="inform-esg-responses__table-response">Response</th>
                        </tr>
                        </thead>
                        <tbody>
                        {renderTableBody()}
                        </tbody>
                    </table>
                </td>
            </tr>
            : null;
    };

    const renderTableBody = () => {
        return props.questions?.map((question, index) => {
            return <tr key={index}>
                <td>{question.level}</td>
                <td>{question.questionName}</td>
                <td className="inform-esg-responses__table-response">{question.response}</td>
            </tr>;
        });
    };

    return <div>
        <table className="inform-esg-responses__table">
            <thead className="inform-esg-responses__table-header">
            <tr className={`inform-esg-responses__table-row ${props.expandedRowIndex === props.index ? "expanded" : "collapsed"}`}
                data-testid="inform-esg-responses__table-row"
                onClick={() => props.setExpandedRowIndex(props.index)}>
                <td className="inform-esg-responses__table-column-icon">{renderExpandRowIcon()}</td>
                <td className="inform-esg-responses__table-column-header">{props.header}</td>
            </tr>
            {renderResponseTable()}
            </thead>
        </table>
    </div>;
};