import {UserTypeEnum} from "./UserInfo.model";

export interface IUserDetails {
    firstName: string;
    username: string;
    userType: UserTypeEnum;
}

export class UserDetails implements IUserDetails
{
    constructor(
        public firstName: string,
        public username: string,
        public userType: UserTypeEnum) {}
}

export const AdminLevelList = [UserTypeEnum.ADMIN];
export const ConsultantLevelList = [UserTypeEnum.ADMIN, UserTypeEnum.CONSULTANT];
export const ClientLevelList = [UserTypeEnum.ADMIN, UserTypeEnum.CONSULTANT, UserTypeEnum.CLIENT, UserTypeEnum.ATC_ADMIN];

export type AccessLevels = UserTypeEnum[];

export const doesUserHaveAccess = (role: UserTypeEnum
    , roleList: AccessLevels) => {
    return (roleList.find((item) => role === item) !== undefined);
};
