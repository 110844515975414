import {Map} from "immutable";
import * as React from "react";
import {connect} from "react-redux";
import {UserApi} from "../../api/UserApi";
import {ClientsAndPlans, IClientInfo, IPlanInfo} from "../../model/ClientsAndPlans.model";
import {UserInfo} from "../../model/UserInfo.model";
import {assertNever} from "../../utils/errorUtil";
import {byName} from "../../utils/listUtil";
import {IAdminState} from "../admin/AdminReducer";
import {ErrorComponent} from "../base/Error.component";
import {
    NOT_REQUESTED,
    REQUEST_FAILED,
    REQUEST_STATES,
    REQUEST_SUCCEEDED,
    REQUESTED,
    RequestIncompleteStates,
    RequestSucceeded,
} from "../common/commonStates";
import {LoadingSpinner} from "../icons/LoadingSpinner.component";
import {MyClient} from "./MyClient.component";
import {IApplicationRootState} from "../../../applicationState";

export type MyClientsComponentProps = IAdminState & {
    plans: IPlanInfo[],
};

export interface IMyClientsComponentPageState {
    clientsArray: IClientInfo[];
    clientToPlans: Map<number, IPlanInfo[]>;
    userInfoState: RequestIncompleteStates | RequestSucceeded<UserInfo[]>;
    expandAll: boolean;
}

export interface IClientPlanInfo {
    clientsArray: IClientInfo[];
}

export class MyClientsComponent extends React.Component<MyClientsComponentProps, IMyClientsComponentPageState> {

    constructor(props: MyClientsComponentProps) {
        super(props);
        this.state = {
            clientsArray: [],
            clientToPlans: Map(),
            userInfoState: NOT_REQUESTED,
            expandAll: false,
        };
    }

    public componentDidMount() {
        this.requestData();
    }

    public requestData() {
        if (this.props.adminClientInfoState.kind === REQUEST_STATES.REQUEST_SUCCEEDED) {
            const clientsAndPlans = (this.props.adminClientInfoState as RequestSucceeded<ClientsAndPlans>).data;

            const clientPlanInfo = this.getClientPlanInfoObject(clientsAndPlans);

            if (this.state.userInfoState.kind === REQUEST_STATES.NOT_REQUESTED) {
                this.getUsers();
            }
            this.setState({...clientPlanInfo});
        }
    }

    public render() {
        switch (this.state.userInfoState.kind) {
            case REQUEST_STATES.NOT_REQUESTED:
                return <LoadingSpinner/>;
            case REQUEST_STATES.REQUESTED:
                return <LoadingSpinner/>;
            case REQUEST_STATES.REQUEST_FAILED:
                return <ErrorComponent/>;
            case REQUEST_STATES.REQUEST_SUCCEEDED:
                return this.renderComponentWithUserInfo(this.state.userInfoState.data);
            default:
                return assertNever(this.state.userInfoState); // error here if there are missing cases
        }
    }

    private renderComponentWithUserInfo(userInfos: UserInfo[]) {
        const expandButtonText = this.state.expandAll ? "Collapse All" : "Expand All";
        return <div className="my-clients__page-container new-common-styles">
            <div className="my-clients__header-container">
                <h1 className="blue">My Clients</h1>
                {this.renderExpandButton(expandButtonText)}
            </div>
            {this.displayClients(userInfos)}
        </div>;
    }

    private renderExpandButton(expandButtonText: string) {
        if (this.state.clientsArray.length !== 0) {
            return <a id="my-clients__expand-collapse-all"
                      data-testid="my-clients__expand-collapse-all"
                      className="clickable"
                      onClick={() => {
                          this.handleExpandAll();
                      }}>
                {expandButtonText}
            </a>;
        } else {
            return null;
        }
    }

    private displayClients(userInfos: UserInfo[]) {
        if (this.state.clientsArray.length !== 0) {
            return this.renderClients(userInfos);
        } else {
            return <div className="my-clients__no-clients-message" data-testid="my-clients__no-clients-message">
                No Clients to Display
            </div>;
        }
    }

    private getClientPlanInfoObject(clientsAndPlans: ClientsAndPlans): IClientPlanInfo {
        const clientsArray = clientsAndPlans.valueSeq().toArray().filter((client) => {
            const plans = this.getPlansForClient(client);
            return plans.length > 0;
        });

        return {clientsArray};
    }

    private renderClients(userInfos: UserInfo[]) {
        return this.state.clientsArray
            .sort(byName)
            .map((it) =>
            <MyClient client={it}
                      users={userInfos}
                      allPlans={this.props.plans}
                      expandedFromParent={this.state.expandAll}
                      key={it.id}/>,
        );
    }

    private getPlansForClient(client: IClientInfo): IPlanInfo[] {
        const plansSet = new Set(this.props.plans.map((plan) => plan.id));

        return client.plans.valueSeq().toArray()
            .filter((plan) => plansSet.has(plan.id) && plan.name.toLowerCase() !== "empower results");
    }

    private getUsers() {
        this.setState({userInfoState: REQUESTED});
        UserApi.getUsersForMyPlansForAdmin()
            .then((response) => {
                if (response.error) {
                    this.setState({userInfoState: REQUEST_FAILED(response.error)});
                } else {
                    this.setState({userInfoState: REQUEST_SUCCEEDED(response.data!)});
                }
            }).catch((error) => {
            this.setState({userInfoState: REQUEST_FAILED(error)});
        });
    }

    private handleExpandAll() {
        const newValue = !this.state.expandAll;
        this.setState({expandAll: newValue});
    }
}

export const mapStateToProps = (state: IApplicationRootState): MyClientsComponentProps => {
    return {
        ...state.adminState!,
        plans: state.session!.plans,
    };
};

export default connect(mapStateToProps)(MyClientsComponent);
