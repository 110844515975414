import * as React from "react";
import {useEffect, useState} from "react";
import {ErrorComponent} from "../base/Error.component";
import {InformSubComponent} from "./InformSubComponent";
import {IInformSubComponent} from "../../model/inform/Inform";
import {IInformProduct} from "../../model/inform/InformProduct";
import {Product} from "../../model/product/Product.model";
import {RatingStatus} from "../product/rating/RatingStatus.component";
import {InformProductHealthCheck} from "./InformProductHealthCheck";
import {InformQuarterHighlights} from "./InformQuarterHighlights";

export interface IInformInvestmentDueDiligenceProps {
    product: Product;
    informProduct: IInformProduct | undefined;
    oddIqEnabled: boolean;
    renderErrorComponent: boolean;
}

export const InformInvestmentDueDiligence: React.FunctionComponent<IInformInvestmentDueDiligenceProps> = (props) => {
    const renderRatingStatus = (product: Product) => {
        return <RatingStatus {...{
            currentRating: product.currentRating.value,
            currentRatingDate: product.currentRating.date,
            previousRating: product.previousRating && product.previousRating.value,
            previousRatingDate: product.previousRating && product.previousRating.date,
            ratingType: product.currentRating.ratingType,
            isOperationalDueDiligenceRating: false,
            hasOddDetails: false,
            productBackstopId: product.backstopId,
            showTooltip: true,
            oddIqEnabled: props.oddIqEnabled,
            summary: props.informProduct?.summary,
        }} />;
    };

    const getRatingCommentary = () => {
        return props.informProduct?.summary?.aonRatingCommentary
            .split(".")
            .filter((value) => value.trim() !== "" && value.trim() !== "---")
            .map((value, index) =>
                <div key={index}
                     className="inform-product__rating-commentary"
                     data-testid="inform-product__rating-commentary">
                    {value.trim().concat(".")}
                </div>);
    };

    const renderRatingCommentary = () => {
        return <div className="inform-product__rating-commentary-container"
                    data-testid="inform-product__rating-commentary-container">
            {getRatingCommentary()}
        </div>;
    };

    const renderInformSubComponents = () => {
        const currency = props.informProduct?.summary?.reportingCurrency as string;
        return <div className="inform-product__idd" data-testid="inform-product__idd">
            <div>
                {renderRatingStatus(props.product)}
            </div>
            {props.informProduct?.summary && renderRatingCommentary()}
            {props.informProduct?.summary && <InformQuarterHighlights summary={props.informProduct?.summary}/>}
            {props.informProduct?.summary && <InformProductHealthCheck summary={props.informProduct?.summary}/>}
            <div className="inform-product__inform-header" data-testid="inform-product__inform-header">
                Aon InForm Assessment
            </div>
            <InformSubComponent name={"Business"} component={props.informProduct?.business as IInformSubComponent}
                                reportingCurrency={currency}/>
            <InformSubComponent name={"Investment Staff"} component={props.informProduct?.staff as IInformSubComponent}
                                reportingCurrency={currency}/>
            <InformSubComponent name={"Investment Process"} component={props.informProduct?.process as IInformSubComponent}
                                reportingCurrency={currency}/>
            <InformSubComponent name={"Risk Management"}
                                component={props.informProduct?.riskManagement as IInformSubComponent}
                                reportingCurrency={currency}/>
            <InformSubComponent name={"Performance"} component={props.informProduct?.performance as IInformSubComponent}
                                reportingCurrency={currency}/>
            <InformSubComponent name={"Terms & Conditions"}
                                component={props.informProduct?.termsAndConditions as IInformSubComponent}
                                reportingCurrency={currency}/>
            <InformSubComponent name={"ESG"} component={props.informProduct?.esg as IInformSubComponent}
                                reportingCurrency={currency}/>
        </div>;
    };

    return props.renderErrorComponent ? <ErrorComponent/> : renderInformSubComponents();
};