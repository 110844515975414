import * as React from "react";
import {hasConsultantLevelAccess} from "../../utils/sessionUtil";
import {NoAccessNotification} from "../base/header/NoAccessNotification";
import {CprKeyPoints} from "./CprKeyPoints";
import {navigateTo} from "../../../navigateTo";
import {useDispatch} from "react-redux";
import {setCurrentNavigationTab} from "../base/header/HeaderActions";
import PostQuarterUpdate from "./CprPostQuarterUpdate";
import {CprPlanSummary} from "./CprPlanSummary";
import {RaisedButton} from "../common/RaisedButton";
import {prominentButtonStyle} from "../common/buttonStyles";
import {CprManagerUpdates} from "./CprManagerUpdates";
import {CprPortfolioApi} from "../../api/CprPortfolioApi";
import {useEffect, useState} from "react";
import {IPlanSummary} from "../../model/PlanSummary.model";
import {FormattedMessage} from "react-intl";

export interface ICprPortfolioOverviewComponentProps {
    clientPerformanceReportingAccess: boolean;
    cprPortfolioOverviewEnabled: boolean;
}

export const CprPortfolioOverview: React.FunctionComponent<ICprPortfolioOverviewComponentProps> = (props) => {

    const [planSummary, setPlanSummary] = useState<IPlanSummary | undefined>(undefined);

    const dispatch = useDispatch();

    const fetchPlanSummary = () => {
        CprPortfolioApi.fetchPlanSummary().then((response) => {
            if (response) {
                setPlanSummary(response);
            }
        });
    };

    useEffect(() => {
        if(hasCprPortfolioOverviewAccess()) {
            fetchPlanSummary();
        }
    }, []);

    function hasCprPortfolioOverviewAccess() {
        return (hasConsultantLevelAccess()
            || (props.clientPerformanceReportingAccess && props.cprPortfolioOverviewEnabled));
    }

    const renderClientAccessNotification = () => {
        return (hasConsultantLevelAccess()
            && !(props.cprPortfolioOverviewEnabled && props.clientPerformanceReportingAccess))
            ? <div className="cpr-portfolio-overview__notification">
                <NoAccessNotification
                    message={"The Portfolio Overview section is currently hidden from client users of this plan."}/>
            </div>
            : null;
    };

    const handleClick = () => {
        navigateTo("/client-reporting");
        dispatch(setCurrentNavigationTab("clientReportingItem"));
    };

    const renderClientReportingPageLink = () => {
        return <div className="home-cpr-portfolio__client-reporting-link">
            <RaisedButton
                className="client-reporting-link-button"
                style={{...prominentButtonStyle, height: "70px", minWidth: "182px"}}
                primary={true}
                onClick={handleClick}>
                <FormattedMessage
                    id="home.view-investment-monitoring-report-button"
                    defaultMessage="View Investment Monitoring Report"/>
            </RaisedButton>
            </div>;
    };

    const renderPortfolioOverview = () => {
        return (hasCprPortfolioOverviewAccess())
            ? <div className="home-cpr-portfolio-overview" data-testid="home-cpr-portfolio-overview">
                {renderClientAccessNotification()}
                <div>
                    <div className="cpr-portfolio-overview__header">
                        <h1>
                            <FormattedMessage id="home.portfolio-overview-header" defaultMessage="Portfolio Overview"/>
                        </h1>
                        {renderClientReportingPageLink()}
                    </div>
                    <hr/>
                </div>
                {planSummary && <CprPlanSummary planSummary={planSummary}/>}
                <CprKeyPoints/>
                <PostQuarterUpdate/>
                {planSummary && <CprManagerUpdates asOfDate={planSummary.asOfDate}/>}
                <div className="stay-informed__section">
                    <div className="stay-informed__title">
                        <h1>
                            <FormattedMessage id="home.stay-informed-title" defaultMessage="Stay Informed"/>
                        </h1>
                    </div>
                    <hr/>
                </div>
            </div>
            :null;
    };

    return renderPortfolioOverview();
};