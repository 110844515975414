import * as React from "react";
import {useEffect, useRef, useState} from "react";
import {ExportStatus, IReportExportInfo, PowerBiReportingApi} from "../../api/PowerBiReportingApi";
import {FormattedMessage} from "react-intl";

export interface IPowerBiExportPollerProps {
    exportInfo: IReportExportInfo;
    exportPeriod: number | undefined;
    clearExportInfo: (success: boolean) => void;
    interval: number;
    numberOfTries: number;
}

export const PowerBiExportPoller: React.FunctionComponent<IPowerBiExportPollerProps> = (props: IPowerBiExportPollerProps) =>{
    const [exportStatus, setExportStatus] = useState(props.exportInfo.status);
    const [count, setCount] = useState(0);

    const downloadReport = (exportInfo: IReportExportInfo) => {
        const downloadPromise = props.exportInfo.clientReportId
            ? PowerBiReportingApi.downloadClientReportExport(exportInfo)
            :  PowerBiReportingApi.downloadPerformanceReportExport(exportInfo, props.exportPeriod!);

        downloadPromise
            .then(() => {
                setExportStatus(ExportStatus.SUCCEEDED);
                props.clearExportInfo(true);
            });
    };

    const pollReportStatus = (initExportInfo: IReportExportInfo) => {
        const statusPromise = props.exportInfo.clientReportId
        ? PowerBiReportingApi.getClientReportExportStatus(initExportInfo)
            :  PowerBiReportingApi.getPerformanceReportExportStatus(initExportInfo);

        statusPromise
            .then((exportInfoResult) => {
                const exportInfo: IReportExportInfo = exportInfoResult.data!;
                if (exportInfo.status === ExportStatus.SUCCEEDED.toString()) {
                    setExportStatus(ExportStatus.DOWNLOADING);
                    downloadReport(exportInfoResult.data!);
                } else {
                    setExportStatus(exportInfo.status);
                    if(count === props.numberOfTries){
                        setExportStatus(ExportStatus.TIMED_OUT);
                    }
                }
            });
    };

    const getDelay = () => {
        if(exportStatus === ExportStatus.SUCCEEDED.toString() || exportStatus === ExportStatus.DOWNLOADING.toString())
            return undefined;
        if(exportStatus === ExportStatus.FAILED.toString() || count > props.numberOfTries){
            props.clearExportInfo(false);
            return undefined;
        }
        if(count === 0)
            return 1000;
        return props.interval;
    };

    useInterval( async () => {
        setCount(count + 1);
        await pollReportStatus(props.exportInfo);
    }, getDelay());

    return <div className="powerbi-report__poller-exporting-message" data-testid="powerbi-report__export-poller">
        <div data-testid="powerbi-report__export-message">
            <FormattedMessage
                id="client-reporting.dont-refresh"
                defaultMessage={"Do not close or refresh the Iris website while the report is exporting to PDF, " +
                "it may take up to 10 minutes. Once complete, the .PDF will download in your browser."}/>
        </div>
        <div className="powerbi-report__poller-exporting-status">
            <FormattedMessage
                id={`client-reporting.status.${exportStatus}`.toLowerCase()}
                defaultMessage={`Status: ${exportStatus}`} />
        </div>
    </div>;
};

export function useInterval(callback: ()=>{}, delay?: number) {
    const savedCallback: any = useRef();

    useEffect(() => {
        savedCallback.current = callback;
    }, [callback]);

    useEffect(() => {
        function tick() {
            savedCallback.current();
        }
        if (delay) {
            const id = setInterval(tick, delay);
            return () => {
                clearInterval(id);
            };
        }
    }, [callback, delay]);
}