import {
    ICalendarMeeting,
    ICalendarQuarter,
    ICalendarTemplateTopic,
    ICalendarTopic,
    ICalendarTopicToSave
} from "../../api/CalendarApi";
import {Map as ImmutableMap} from "immutable";
import {Quarter} from "../../utils/dateUtil";
import {ISelectValue} from "../common/Select.component";
import {compareInsensitive} from "../../utils/listUtil";

export function getFrequencyById(id: number): string {
    return CalendarFrequencyMap.get(id)!;
}

export enum Frequency {
    ANNUAL = 1,
    TWO_YEARS,
    THREE_YEARS,
    FOUR_YEARS,
    FIVE_YEARS,
    AD_HOC,
    QUARTERLY,
}

export const CALENDAR_FREQUENCY_VALUES: ISelectValue[] = [
    {name: "Annual", id: Frequency.ANNUAL},
    {name: "Two Years", id: Frequency.TWO_YEARS},
    {name: "Three Years", id: Frequency.THREE_YEARS},
    {name: "Four Years", id: Frequency.FOUR_YEARS},
    {name: "Five Years", id: Frequency.FIVE_YEARS},
    {name: "Ad Hoc", id: Frequency.AD_HOC},
    {name: "Quarterly", id: Frequency.QUARTERLY}
];

export enum PLAN_TYPE {
    PUBLIC_DB = 1,
    CORPORATE_DB,
    DC,
    NON_PROFIT
}

export const CalendarFrequencyMap = ImmutableMap<number, string>()
    .set(1, "Annual")
    .set(2, "2 years")
    .set(3, "3 years")
    .set(4, "4 years")
    .set(5, "5 years")
    .set(6, "Ad Hoc")
    .set(7, "Quarterly");

export const PLAN_TYPE_VALUES: ISelectValue[] = [
    {name: "Public DB", id: PLAN_TYPE.PUBLIC_DB},
    {name: "Corporate DB", id: PLAN_TYPE.CORPORATE_DB},
    {name: "DC", id: PLAN_TYPE.DC},
    {name: "Non Profit", id: PLAN_TYPE.NON_PROFIT},
];

export const ALL_PLAN_TYPES = 0;
export const PLAN_TYPE_FILTER_VALUES = [...PLAN_TYPE_VALUES,
    {id: ALL_PLAN_TYPES, name: "All Plan Types"}];

export const getCalendarQuarters = (calendarYears: number[]): ICalendarQuarter[] => {
    const quarters: ICalendarQuarter[] = [];
    calendarYears.map((year: number) => {
        Object.values(Quarter).map((quarter: string, quarterIdx: number) => {
            const quarterNumber = quarterIdx + 1;
            quarters.push({
                key: `${year}-${quarterNumber}`,
                name: quarter,
                quarterNumber,
                year});
        });
    });
    return quarters;
};

export const getTopicsToSave = (
            calendarMeetings: ImmutableMap<string, ICalendarMeeting>,
            templateTopics: ICalendarTemplateTopic[],
            destinationQuarterKey: string,
            destinationIndex: number,
            templateTopicIndex: number,
            currentYear: number
    ): ICalendarTopicToSave[] => {

    const allowDupes = templateTopics.at( templateTopicIndex)?.allowDupes;
    const templateTopic = templateTopics[templateTopicIndex];

    const newTopic: ICalendarTopic = {
        id: -1,
        templateTopicId: templateTopic.id!,
        name: templateTopic.name,
        order: destinationIndex,
        frequencyId: templateTopic.frequencyId,
    };

    const yearAndQuarter = keyToYearAndQuarter(destinationQuarterKey);
    const year: number = yearAndQuarter.year;
    const quarter: number = yearAndQuarter.quarter;
    const frequency = templateTopic.frequencyId;

    //quarters to add to
    const quarterKeys: string[] = [destinationQuarterKey];

    if (frequency === Frequency.ANNUAL.valueOf()) {
        if (year < currentYear + 2) quarterKeys.push(`${year + 1}-${quarter}`);
        if (year < currentYear + 1) quarterKeys.push(`${year + 2}-${quarter}`);
    }

    if (frequency === Frequency.TWO_YEARS.valueOf() && year === currentYear) {
        quarterKeys.push(`${year + 2}-${quarter}`);
    }

    if (frequency === Frequency.QUARTERLY.valueOf()) {
        if (year < currentYear + 2) {
            addQuarterlyTopicToYear(quarterKeys, year + 1, 1);
        }
        if (year < currentYear + 1) {
            addQuarterlyTopicToYear(quarterKeys, year + 2, 1);
        }
        addQuarterlyTopicToYear(quarterKeys, year, quarter + 1);
    }

    const topicsToSave: ICalendarTopicToSave[] = [];

    quarterKeys.map((key) => {
        //add topic to destination quarter
        const yearAndQuarterToSave = keyToYearAndQuarter(key);
        if (allowDupes || !hasTemplateTopic(newTopic.templateTopicId, calendarMeetings, key)) {
            const order = (key === destinationQuarterKey)
                ? destinationIndex
                : calendarMeetings.has(key)
                    ? calendarMeetings.get(key)!.topics.length
                    : 0;
            topicsToSave.push({
                ...newTopic,
                year: yearAndQuarterToSave.year,
                quarter: yearAndQuarterToSave.quarter,
                order
            });
        }
    });

    return topicsToSave;
};

const addQuarterlyTopicToYear = (quarterKeys: string[], year: number, startQuarter: number) => {
    for (let i = startQuarter; i <= 4; i++) {
        quarterKeys.push(`${year}-${i}`);
    }
};

export const hasTemplateTopic =
    (templateTopicId: number, meetings: ImmutableMap<string, ICalendarMeeting>, key: string): boolean => {

        return meetings.has(key)
            ? meetings.get(key)!.topics
            .findIndex((topic: { templateTopicId: number; }) => topic.templateTopicId === templateTopicId) > -1
            : false;
    };

export const keyToYearAndQuarter = (key: string): {year: number, quarter: number} => {
    const yearAndQuarter = key.split("-");
    return {year:+yearAndQuarter[0], quarter:+yearAndQuarter[1]};
};

export function formatQuarterAndYear(quarter: number, year: number): string {
    return `Q${quarter}-${year}`;
}

export interface IPlanType {
    planTypeName: string;
}

export const byPlanType = (item1: IPlanType, item2: IPlanType) => {
    return compareInsensitive(item1.planTypeName, item2.planTypeName);
};