import {IDated} from "../../utils/dateUtil";
import {IProductInfo} from "../product/IProductInfo";
import {IManagerResearchModel} from "./IManagerResearchModel";

export class ManagerGroupWithDate implements IManagerResearchModel, IDated {
    constructor(public managerBackstopId: number,
                public name: string,
                public date: Date,
                public products: IProductInfo[] = []) {
    }
}
