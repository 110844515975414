import {Map as ImmutableMap} from "immutable";
import {Action, handleActions} from "redux-actions";
import {Product} from "../../model/product/Product.model";
import {ProductStoreActions} from "./productStoreActions";

export interface IProductStore {
    previews: ImmutableMap<number, Product>;
}

export const initialState: IProductStore = {
    previews: ImmutableMap<number, Product>(),
};

const addProductPreview = (state: IProductStore, action: Action<Product>) => {
    const product = action.payload!;

    const previews = state.previews.set(product.backstopId, product);

    return {...state, previews};
};

export const oddPreviewsInner = (state: IProductStore | undefined): ImmutableMap<number, Product> => {
    return state ? state.previews : initialState.previews;
};

const reducerMap = {
    [ProductStoreActions.ADD_PRODUCT_PREVIEW]: addProductPreview,
};

export default handleActions<IProductStore, any>(reducerMap, initialState);
