import * as chroma from "chroma-js";
import {Axis, BaseType, ScaleLinear, ScaleOrdinal, ScaleTime, Series} from "d3";
import * as d3 from "d3";
import {Selection} from "d3-selection";
import moment = require("moment");
import * as React from "react";
import {HOLDINGS_CHART_COLOR_SCALE} from "../../../../../css/Colors";
import {getQuarterAndYearByDate} from "../../../utils/dateUtil";
import {numberWithCommas} from "../../../utils/numberUtil";
import {createStackData, getStackKeys, toTitleCase} from "../InvestmentsDataUtil";

export interface IDataInterface {
    date: Date;
    name: string;
    value: number;
}

export interface IHoldingsChartProps {
    inputData?: IDataInterface[];
    month: number;
    year: number;
}

export interface IDataProperties {
    percent: string;
    value: string;
}

interface IDataInProps {
    date: Date;

    [propName: string]: IDataProperties | Date;
}

interface IStackedData {
    1: number;
    0: number;
    data: IDataInProps;
}

export class HoldingsAreaChartGenerator extends React.Component<IHoldingsChartProps> {

    private containerDimensions = {
        height: 400,
        width: 800,
    };

    private margins = {top: 25, right: 50, bottom: 50, left: 100};

    private chartDimensions = {
        height: 400,
        width: 640,
    };

    private data: IDataInProps[];
    private keys: string[];

    private stackedData: Series<IDataInProps, string>[];

    private chartContainer: Selection<BaseType, any, HTMLElement, any>;
    private chart: Selection<BaseType, any, HTMLElement, any>;
    private graph: Selection<BaseType, any, HTMLElement, any>;
    private legend: Selection<BaseType, any, HTMLElement, any>;
    private xAxis: Axis<Date | number | { valueOf(): number }>;
    private yAxis: Axis<number | { valueOf(): number }>;
    private xScale: ScaleTime<number, number>;
    private yScale: ScaleLinear<number, number>;
    private xdomain: ScaleTime<number, number>;
    private ydomain: ScaleLinear<number, number>;
    private slider: Selection<BaseType, any, HTMLElement, any>;
    private sliderHandle: Selection<BaseType, any, HTMLElement, any>;
    private tickBubble: Selection<BaseType, any, HTMLElement, any>;
    private dotBubble: Selection<BaseType, any, HTMLElement, any>;

    private dataPath: Selection<BaseType, Series<IDataInProps, string>, BaseType, any>;

    private color: ScaleOrdinal<string, any>;

    private currentIndex: number;

    // legend
    private markerDimension = 17;

    // cache the relative horizontal position of the slider handle
    private sliderHandleRelativeXPosition = 0;

    public componentDidMount(): void {
        this.renderAreaChart();
    }

    public render() {
        return <div id="holding-page__stacked-chart">
            <div id="stacked-chart__chart-title">Asset Allocation</div>
            <div id="stacked-chart__hover-date"/>
            <div id="stacked-chart__chart-container">
                <div id="stacked-chart__spacer-container"/>
                <div id="stacked-chart__chart-anchor"/>
                <div id="stacked-chart__spacer-container"/>
                <div id="stacked-chart__legend-container"/>
            </div>
            <div id="stacked-chart__tick-bubble" className="hidden"
                 style={{position: "absolute", width: "fit-content", alignSelf: "center",
                     transform: "translateX(-50%)"}}>
                <span id="stacked-chart__tick-bubble-text" style={{position: "relative"}}/>
            </div>
            <div id="stacked-chart__dot-bubble" className="hidden"
                       style={{position: "absolute", width: "fit-content", alignSelf: "center",
                           transform: "translateX(-50%)"}}>
            <span id="stacked-chart__dot-bubble-text" style={{position: "relative"}}/>
        </div>
        </div>;
    }

    public renderAreaChart() {
        const sourceData = this.props.inputData!
            .map((data) => ({
            ...data,
            name: toTitleCase(data.name),
            date: new Date(data.date.getFullYear(), data.date.getMonth()),
        }));

        this.keys = getStackKeys(sourceData, this.props.month, this.props.year);

        this.data = createStackData(sourceData, this.keys);

        this.color = d3.scaleOrdinal()
            .domain(this.keys)
            .range(chroma.scale(HOLDINGS_CHART_COLOR_SCALE).colors(this.keys.length));

        this.setChartContainer();
        this.setAxes();
        this.setupDefs();

        this.setChartOnHoverDate();
        this.createStackGraph(() => {
            this.createLegend();
            this.createPointLines();

            this.createSlider();

            this.selectDefaultDate();
        });
    }

    private setChartContainer() {
        this.chartContainer = d3
            .select("#stacked-chart__chart-anchor")
            .append("svg")
            .attr("width", this.containerDimensions.width)
            .attr("height", this.containerDimensions.height);

        this.chart = this.chartContainer
            .append("g")
            .attr("transform", `translate( ${this.margins.left}, ${this.margins.top} )`);

        this.graph = this.chart
            .append("g");

        this.legend = d3.select("#stacked-chart__legend-container");
    }

    private setAxes() {
        this.xScale = d3
            .scaleTime()
            .range([0, this.chartDimensions.width]);

        // defines of size graph
        this.yScale = d3
            .scaleLinear()
            .range([this.chartDimensions.height, 0]);

        this.tickBubble = d3.select("#stacked-chart__tick-bubble");

        // Create x and y axis and scale function
        const extent: [Date, Date] = d3.extent(this.data, (d: IDataInProps) => d.date) as [Date, Date];
        this.xdomain = this.xScale.domain(extent);
        this.ydomain = this.yScale.domain([0, 100]);

        this.yAxis = d3.axisLeft(this.ydomain).tickValues([0, 20, 40, 60, 80, 100]);

        this.chartContainer.append("text")
            .attr("class", "stacked-chart__y-axis-label")
            .attr("text-anchor", "middle")
            .attr("transform", "translate(" + 50 + "," + (this.chartDimensions.height / 2 + 25) + ")rotate(-90)")
            .text("ALLOCATION (%)");

        this.chartContainer.append("text")
            .attr("class", "stacked-chart__x-axis-label")
            .attr("text-anchor", "middle")
            .attr("transform", "translate(" + (this.margins.left - 50) + ","
                + (this.containerDimensions.height + 55) + ")")
            .text("MONTH");

        this.chart
            .append("g")
            .attr("class", "stacked-chart__y-axis")
            .call(this.yAxis);
    }

    // noinspection JSMethodCanBeStatic
    private setChartOnHoverDate() {
        d3.select("#stacked-chart__hover-date")
            .append("text")
            .attr("x", 10)
            .attr("y", 10)
            .attr("class", "stacked-chart__hover-date")
            .attr("color", "#2e2e2e")
            .style("opacity", 0)
            .text("MMMM YYYY")
            .attr("text-anchor", "left")
            .style("alignment-baseline", "middle");
    }

    private createStackGraph(cont: () => void) {
        // create stack data
        const stackedData: Series<IDataInProps, string>[] = d3.stack<IDataInProps>()
            .keys(this.keys)
            .value((d: any, key: string) => d[key].percent)
            .order(d3.stackOrderReverse)(this.data);
        this.stackedData = stackedData;

        // create area function
        const area = (startFromZero: boolean) => {
            const highestVal = 100;
            return d3
                .area<IStackedData>()
                .x((d) => {
                    return this.xScale(new Date((d as any).data.date))!;
                })
                .y0((d) => (startFromZero ? highestVal : this.yScale(d[0])!))
                .y1((d) => (startFromZero ? highestVal : this.yScale(d[1])!));
        };

        this.dataPath = this.graph
            .selectAll("dataShapes")
            .data(stackedData)
            .enter()
            .append("path")
            .attr("fill", (d: any) => this.color(d.key).toString())
            .style("opacity", "1")
            .attr("class", (d: any) => "stacked-chart__path stacked-chart__path-" + d.key.replace(/\s/g, "-"));

        let num = 0;
        this.dataPath
            .attr("d", area(true))
            .transition()
            .delay(200)
            .duration(700)
            .attr("d", area(false))
            .on("end", () => {
                num++;
                if (num === stackedData.length) {
                    this.dotBubble = d3.select("#stacked-chart__dot-bubble");
                    this.dataPath
                        .on("mouseover", (d) => this.hoverLegend(d.key))
                        .on("mouseout", () => this.unhoverLegend())
                    ;
                    cont();
                }
            })
        ;

    }

    private createPointLines() {
        this.dataPath.each((d: any) => {
            this.graph.selectAll("line-" + d.key)
                .data(d)
                .enter()
                .append("line")
                .attr("class", (a: any, i: number) => "stacked-chart__line stacked-chart__line-" + i)
                .attr("x1", (a: any) => this.xdomain(a.data.date)!)
                .attr("x2", (a: any) => this.xdomain(a.data.date)!)
                .attr("y1", () => this.ydomain(100)!)
                .attr("y2", () => this.ydomain(0)!)
                .attr("stroke", "white")
                .attr("stroke-dasharray", "4 4")
                .attr("opacity", 0);
        });

        this.dataPath
            .each((d: any) => {
                this.graph.selectAll("circle-" + d.key)
                    .data(d)
                    .enter()
                    .append("circle")
                    .attr("class", (a: any, i: number) => "stacked-chart__point stacked-chart__point-" + i)
                    .attr("cx", (a: any) => this.xdomain(a.data.date)!)
                    .attr("cy", (a: any) => this.ydomain(a[1])!)
                    .attr("r", 0)
                    .on("mouseover", () => this.hoverLegend(d.key))
                    .on("mouseout", () => this.unhoverLegend())
                ;
            });
    }

    private createLegend() {
        this.legend
            .append("div")
            .attr("class", "stacked-chart__legend-title")
            .text("Asset Class");

        const legendItem = this.legend
            .selectAll("mylabels")
            .data(this.keys)
            .enter()
            .append("div")
            .attr("class", (d) =>
                "stacked-chart__legend-div stacked-chart__legend-div-" + d.replace(/\s/g, "-"))
            .attr("text-anchor", "left")
            .style("alignment-baseline", "middle")
            .on("mouseover", this.hoverLegend)
            .on("mouseleave", this.unhoverLegend);

        legendItem
            .append("svg")
            .attr("width", this.markerDimension)    // set width
            .attr("height", this.markerDimension)  // set height
            .append("g")
            .append("rect")
            .attr("y", 4)
            .attr("rx", 2)
            .attr("width", this.markerDimension)
            .attr("height", this.markerDimension)
            .style("fill", (d: any) => this.color(d).toString());

        legendItem
            .append("span")
            .attr("class", "stacked-chart__legend-text")
            .text((d) => d);

        legendItem
            .append("span")
            .attr("class", "stacked-chart__value-text");
    }

    private getSliderXAndSliderY = () => {
        const sliderElement = this.slider.select(".tick > line").node() as Element;
        const scrollLeft = window.pageXOffset || document.documentElement.scrollLeft;
        const scrollTop = window.pageYOffset || document.documentElement.scrollTop;
        const sliderX = sliderElement.getBoundingClientRect().left + scrollLeft;
        const sliderY = sliderElement.getBoundingClientRect().top + scrollTop;
        return {sliderX, sliderY};
    };

    private showTickBubble = (date: Date) => {
        this.tickBubble.select("#stacked-chart__tick-bubble-text").selectAll("span").remove();
        this.tickBubble.select("#stacked-chart__tick-bubble-text")
            .append("span")
            .style("font-weight", 500)
            .text(moment(date).format("MMM YYYY"))
        ;

        const {sliderX, sliderY} = this.getSliderXAndSliderY();
        this.tickBubble
            .classed("hidden", false)
            .style("left", (sliderX + this.xScale(date)!) + "px")
            .style("top", (sliderY - 42) + "px")
        ;
    };

    private hideTickBubble = () => {
        this.tickBubble.classed("hidden", true);
        this.tickBubble.select("#stacked-chart__tick-bubble-text").selectAll("span").remove();
    };

    private createSlider() {
        this.xAxis = d3.axisBottom(this.xdomain)
            .ticks(d3.timeMonth)
            .tickSizeOuter(0)
            .tickSizeInner(0)
            .tickFormat((d: Date) => {
                if (d.getMonth() === 0) {
                    return d.getFullYear().toString();
                }
                return " ";
            });

        this.slider = this.chart
            .append("g")
            .attr("width", 100)
            .attr("height", 100)
            .attr("transform", "translate(0," + (this.chartDimensions.height + 20) + ")");

        this.slider
            .append("rect")
            .attr("width", this.chartDimensions.width)
            .attr("height", 8)
            .style("fill", "#dfdfdf")
            .attr("rx", 3)
            .attr("ry", 3);

        this.slider
            .append("g")
            .attr("class", "stacked-chart__x-axis")
            .attr("transform", "translate(0," + 4 + ")")
            .attr("y", 5)
            .call(this.xAxis);

        this.slider
            .select(".domain")
            .attr("stroke", "#dfdfdf");

        this.slider
            .append("circle")
            .attr("id", "stacked-chart__slider-handle-shadow")
            .attr("class", "stacked-chart__slider-handle")
            .attr("style", "opacity: 0")
            .attr("r", 15)
            .attr("cx", 0)
            .attr("cy", 5)
            .attr("fill", "#ebebeb")
            .attr("transform", "translate(0," + (5) + ")")
        ;

        this.sliderHandle = this.slider
            .append("circle")
            .attr("id", "stacked-chart__slider-handle-ball")
            .attr("class", "stacked-chart__slider-handle clickable")
            .attr("r", 10)
            .attr("cx", 0)
            .attr("cy", 0)
            .attr("fill", "#028eb2")
            .attr("transform", "translate(0," + (5) + ")")
            .on("mouseover", () => {
                this.showTickBubble(this.getClosestDateToXPosition(this.sliderHandleRelativeXPosition));
            })
            .on("mouseout", this.hideTickBubble)
            .on("mousedown", () => this.toggleSliderHandleClicked(true))
            .call(d3.drag()
                .on("drag", (event: any) => {
                    this.showTickBubble(this.getClosestDateToXPosition(event.x));
                    this.onDrag(event.x, false);
                })
                .on("end", (event: any) => {
                    this.toggleSliderHandleClicked(false);
                    this.onDrag(event.x, true);
                    this.hideTickBubble();
                }),
            )
        ;

        this.slider
            .selectAll(".tick > line")
            .attr("y1", -7)
            .attr("y2", 7)
            .attr("color", "#c0c0c0")
            .attr("stroke-width", (_: Date, i: number) => {
                if (i === 0 || i === this.data.length - 1 ) {
                    return "0px";
                }
                return "1px";
            });

        this.slider
            .selectAll(".tick > text")
            .attr("y", 20)
            .attr("class", "stacked-chart__tick-text");

        this.slider
            .selectAll(".tick")
            .append("circle")
            .attr("r", 10)
            .attr("fill", "red")
            .attr("opacity", 0)
            .attr("class", "stacked-chart__slider-tick-area")
            .attr("id", (d: Date) => {
                return `stacked-chart__slider-tick-area__${d.toDateString().replace(/\s/g, "_")}`;
            })
            .on("mouseover", this.showTickBubble)
            .on("mouseout", this.hideTickBubble)
            .on("click", this.dragHandle);
    }

    private setupDefs() {
        const filter = this.chartContainer
            .append("defs")
            .append("filter")
            .attr("id", "drop-shadow")
        ;

        filter.append("feGaussianBlur")
            .attr("in", "SourceAlpha")
            .attr("stdDeviation", 2)
            .attr("result", "blur");
    }

    ////////////////
    // events
    ////////////////

    private toggleSliderHandleClicked = (on: boolean) => {
        d3.select("#stacked-chart__hover-date")
            .transition()
            .duration(200)
            .attr("style", `opacity : ${on ? 0.2 : 1}`)
        ;

        d3.select("#stacked-chart__slider-handle-ball")
            .transition()
            .duration(200)
            .attr("r", on ? 12 : 10)
        ;

        d3.select("#stacked-chart__slider-handle-shadow")
            .transition()
            .duration(200)
            .attr("style", `opacity: ${on ? 0.1 : 0}`)
            .style("filter", "url(#drop-shadow)")
        ;
    };

    private getClosestDateToXPosition = (x: number) => {
        const xs = this.data.map((datum) => this.xScale(datum.date)!);

        // Please don't inline this.
        // noinspection UnnecessaryLocalVariableJS
        const idxOfClosestDate =
            xs.reduce(({idxOfMin, minDiff}, curr, currIdx) => {
                const currDiff = Math.abs(curr - x);
                return (currDiff < minDiff)
                    ? {idxOfMin: currIdx, minDiff: currDiff}
                    : {idxOfMin, minDiff}
                    ;
            }, {idxOfMin: 0, minDiff: Number.MAX_SAFE_INTEGER}).idxOfMin;

        return this.data.map((datum) => datum.date)[idxOfClosestDate];
    };

    private onDrag = (x: number, snap: boolean) => {
        const handle = d3.selectAll(".stacked-chart__slider-handle");

        if (snap) {
            this.dragHandle(this.getClosestDateToXPosition(x));
        } else {
            if (x > 0 && x <= this.chartDimensions.width) {
                handle.attr("cx", x);
            }
        }
    };

    private dragHandle = (date: any) => {
        this.clearSelected();

        const handles = d3.selectAll(".stacked-chart__slider-handle");
        const index = this.data.findIndex(
            (d) => d.date.getFullYear() === date.getFullYear() && d.date.getMonth() === date.getMonth(),
        );
        if (index > -1) {
            const newX = this.xScale(this.data[index].date)!;
            this.sliderHandleRelativeXPosition = newX;
            handles.attr("cx", newX);
            this.currentIndex = index;
            this.dropOnDate(this.data[index], index);
        }
    };

    // noinspection JSMethodCanBeStatic
    private clearSelected() {
        const points = d3.selectAll(".stacked-chart__point");
        const lines = d3.selectAll(".stacked-chart__line");
        points
            .classed("selected", !points.classed("selected"))
            .attr("r", 0);

        lines
            .classed("selected", !lines.classed("selected"))
            .attr("opacity", 0);

        d3.select(".stacked-chart__hover-date")
            .style("opacity", 0)
            .text("MMMM YYYY");
    }

    // when rect is hovered
    private dropOnDate = (d: any, index: number) => {
        const points = d3.selectAll(".stacked-chart__point-" + index);
        const lines = d3.selectAll(".stacked-chart__line-" + index);
        points
            .classed("selected", !points.classed("selected"))
            .attr("r", 4)
            .attr("fill", "white")
            .attr("stroke", "#9b9b9b")
            .attr("stroke-width", "1px");

        lines
            .classed("selected", !lines.classed("selected"))
            .attr("opacity", 1);

        const quarterYearObj = getQuarterAndYearByDate(d.date);

        d3.select(".stacked-chart__hover-date")
            .style("opacity", 1)
            .text(`${moment(d.date).format("MMMM YYYY")} (${quarterYearObj.quarter})`);

        Object.keys(d).forEach((key) => {
            if (d[key] === "date") {
                return;
            }

            d3.select(".stacked-chart__legend-div-" + key.replace(/\s/g, "-") + " .stacked-chart__value-text")
                .text(() =>
                    `  ${Number.parseFloat(d[key].percent).toFixed(2)}% | $${numberWithCommas(d[key].amount)}`);
        });

    };

    // when legend is hovered
    private hoverLegend = (d: string) => {
        this.applySelectedToItem(d);
        this.highlightSelectedArea(d);
        this.showDotBubble(d);
    };
    // when legend is unhovered

    private unhoverLegend = () => {
        this.removeSelectedFromAll();
        this.unHighlightAllAreas();
        this.hideDotBubble();
    };

    //////////
    // HIGHLIGHT SELECTED METHODS //
    //////////

    private legendNode = (key: string) => d3.select(`.stacked-chart__legend-div-${key.replace(/\s/g, "-")}`);
    private pathNode = (key: string) => d3.select(`.stacked-chart__path-${key.replace(/\s/g, "-")}`);

    private removeSelectedFromAll = () => {
        d3.selectAll(".stacked-chart__path").classed("selected", false);
        d3.selectAll(".stacked-chart__legend-div").classed("selected", false);
        // d3.selectAll(".stacked-chart__legend-div").classed("background", false);
    };

    private applySelectedToItem(key: string) {
        this.pathNode(key).classed("selected", true);
        this.legendNode(key).classed("selected", true);
    }

    private showDotBubble = (assetClassName: string) => {
        const theData = this.data[this.currentIndex];
        const currentValue: any = theData[assetClassName];

        this.dotBubble.select("#stacked-chart__dot-bubble-text").selectAll("*").remove();
        const bubbleSpan = this.dotBubble.select("#stacked-chart__dot-bubble-text").append("span");
        bubbleSpan.append("div")
            .style("font-weight", 500)
            .text(assetClassName);
        bubbleSpan.append("div")
            .style("font-weight", 300)
            .text(`${Number.parseFloat(currentValue.percent).toFixed(2)}% `
                + `| $${numberWithCommas(currentValue.amount)}`)
        ;

        const {sliderX, sliderY} = this.getSliderXAndSliderY();
        const bubbleX = sliderX + this.xScale(theData.date)!;
        const topPercentage = this.stackedData.find((x) => x.key === assetClassName)![this.currentIndex][1];
        const bubbleY = sliderY - this.chartDimensions.height - 76 + this.yScale(topPercentage)!;

        this.dotBubble
            .classed("hidden", false)
            .style("left", bubbleX + "px")
            .style("top", bubbleY + "px")
        ;
    };

    private hideDotBubble = () => {
        this.dotBubble.classed("hidden", true);
        this.dotBubble.select("#stacked-chart__dot-bubble-text").selectAll("*").remove();
    };

    // noinspection JSMethodCanBeStatic
    private unHighlightAllAreas() {
        d3.selectAll(".stacked-chart__path")
            .transition()
            .delay(100)
            .style("opacity", 1);

        d3.selectAll(".stacked-chart__legend-div")
            .transition()
            .delay(100)
            .style("opacity", 1);
    }

    private highlightSelectedArea(d: string) {
        d3.selectAll(".stacked-chart__path")
            .transition()
            .duration(200)
            .style("opacity", .35);

        d3.selectAll(".stacked-chart__legend-div")
            .transition()
            .duration(200)
            .style("opacity", .35);

        this.pathNode(d)
            .transition()
            .duration(200)
            .style("opacity", 1);

        this.legendNode(d)
            .transition()
            .duration(200)
            .style("opacity", 1);
    }

    private selectDefaultDate() {
        this.dragHandle(this.data[this.data.length - 1].date);
    }
}
