import {InsightsApi} from "../../api/InsightsApi";
import {DocumentType} from "../../utils/marketOutlookUtils";
import {IHomeContentBoxDownloadableDataItem, IHomeListItem} from "../IHomeListItem";

export interface IMediumTermView {
    date: Date;
    backstopId: number;
    frequency: string;
    title: string;
    fileName: string;
}

export class MediumTermView implements IMediumTermView, IHomeListItem {

    public static builder(): MediumTermViewBuilder {
        return new MediumTermViewBuilder();
    }

    public readonly frequency: ReportFrequency;

    constructor(public readonly date: Date,
                public readonly backstopId: number,
                frequency: string,
                public readonly title: string,
                public readonly fileName: string) {
        this.frequency = ReportFrequency[frequency.toUpperCase() as keyof typeof ReportFrequency] as
            ReportFrequency || ReportFrequency.MISSING;
    }

    public transform(): IHomeContentBoxDownloadableDataItem {
        return {
            title: this.title,
            date: this.date,
            backstopId: this.backstopId,
            url: "",
            type: DocumentType.MTV,
            download: () => InsightsApi.getDocumentDownload(this.backstopId, this.fileName, true),
        };
    }
}

class MediumTermViewBuilder {
    private mBackstopId: number;
    private mTitle: string;
    private mFrequency: string;
    private mDate: Date;
    private mFileName: string;

    public backstopId(value: number) {
        this.mBackstopId = value;
        return this;
    }

    public title(value: string) {
        this.mTitle = value;
        return this;
    }

    public frequency(value: string) {
        this.mFrequency = value;
        return this;
    }

    public date(value: Date) {
        this.mDate = value;
        return this;
    }

    public fileName(value: string) {
        this.mFileName = value;
        return this;
    }

    public build(): MediumTermView {
        return new MediumTermView(
            this.mDate || new Date(),
            this.mBackstopId || 0,
            this.mFrequency || "QUARTER1",
            this.mTitle || "",
            this.mFileName || "",
        );
    }
}

export enum ReportFrequency {
    MONTHLY = "MONTHLY",
    QUARTER1 = "QUARTER1",
    QUARTER2 = "QUARTER2",
    QUARTER3 = "QUARTER3",
    QUARTER4 = "QUARTER4",
    MISSING = "MISSING",
}
