import * as React from "react";
import DatePicker from "react-datepicker";
import {boldText} from "../../../../css/text-styles";
import {formatShortDate, reactDatePickerShortDateFormat} from "../../utils/dateUtil";
import {ComponentMode} from "../release-notes/ReleaseNotesPage";
import {forwardRef} from "react";

export interface IDateSelectorComponentProps {
    date: Date;
    mode: ComponentMode;
    onChange: (date: Date) => void;
}

export const DateSelectorComponent = (props: IDateSelectorComponentProps) => {
    const renderPreviewOrEditFields = () => {
        return props.mode === "preview" ? renderPreviewDate() : renderEditableDate();
    };

    const renderPreviewDate = () => {
        return <div className="date-selector-component__preview">
                {formatShortDate(props.date)}
            </div>;
    };

    const renderEditableDate = () => {
        return <DatePicker className="date-selector-component__date-picker"
                            customInput={<CustomInput value={props.date}/>}
                            dateFormat={reactDatePickerShortDateFormat}
                            peekNextMonth={true}
                            showMonthDropdown={true}
                            showYearDropdown={true}
                            dropdownMode="select"
                            selected={props.date}
                            onChange={handleDateChange}/>;
    };

    const handleDateChange = (date: Date) => {
        props.onChange(date);
    };

    return renderPreviewOrEditFields();

};

const CustomInput = forwardRef<HTMLInputElement, any>((props, ref) => {
    const {value, onChange, onClick} = props;
    return <input className="date-custom-input"
                  data-testid="date-custom-input"
                  id="date-custom-input"
                  style={{...boldText,
                      padding: "1px"}}
                  value={value}
                  onClick={onClick}
                  onChange={onChange}
                  ref={ref} />;
});