import {AxiosResponse} from "axios";
import {Holding} from "../model/holdings/Holding.model";
import {getQuarterAndYearByDate, IQuarterAndYear, parseDate} from "../utils/dateUtil";
import {ApiBase} from "./ApiBase";
import {IApiResult} from "./AxiosWealthManagementApi";

export interface IHolding {
    name: string;
    assets: number;
    assetClass: string;
    productId: number | null;
    cashflow: number;
    inflow: number;
    outflow: number;
    gains: number;
    asOfDate: Date;
    quarterAndYear: IQuarterAndYear;
    tier: string;
    tierId: number;
}

export enum Tier {
    TARGET_DATE_FUNDS = 1,
    PASSIVELY_MANAGED_FUNDS,
    ACTIVELY_MANAGED_FUNDS,
    SELF_DIRECTED_BROKERAGE,
}

export const tiersLookup = [
    {id: Tier.TARGET_DATE_FUNDS, name: "Target Date Funds"},
    {id: Tier.PASSIVELY_MANAGED_FUNDS, name: "Passively managed funds"},
    {id: Tier.ACTIVELY_MANAGED_FUNDS, name: "Actively Managed Funds"},
    {id: Tier.SELF_DIRECTED_BROKERAGE, name: "Self Directed Brokerage"},
];

export class HoldingsApi {
    public static getAllHoldings():Promise<IApiResult<IHolding[]>> {
        return ApiBase.processGet("/plans/all/holdings", (response: AxiosResponse) => {
            return response.data.map((holdingEntity: any) => {
                const asOfDate = parseDate(holdingEntity.asOfDate);
                return this.mapHolding(holdingEntity, asOfDate);
            });
        });
    }

    private static mapHolding(holdingEntity: any, asOfDate: Date): Holding {
        return new Holding(
            holdingEntity.name,
            holdingEntity.assets,
            holdingEntity.assetClass,
            holdingEntity.productId,
            holdingEntity.cashflow,
            holdingEntity.inflow,
            holdingEntity.outflow,
            holdingEntity.gains,
            asOfDate,
            getQuarterAndYearByDate(asOfDate),
            tiersLookup[holdingEntity.tierId - 1].name,
            holdingEntity.tierId);
    }

    public static getPlanHoldings(): Promise<IApiResult<IHolding[]>> {
        return ApiBase.processGet("/plans/current/holdings", (response: AxiosResponse) => {
            return response.data.map((holdingEntity: any) => {
                const asOfDate = parseDate(holdingEntity.asOfDate);
                return this.mapHolding(holdingEntity, asOfDate);
            });
        });
    }

    public static uploadHoldings(formData: FormData): Promise<IApiResult<boolean>> {
        return ApiBase.processPost("/holdings", formData);
    }
}
