import * as React from "react";
import {connect} from "react-redux";
import {ClientsAndPlans, IClientInfo, IPlanInfo} from "../../model/ClientsAndPlans.model";
import {byName} from "../../utils/listUtil";
import {getSuccessData, REQUEST_STATES, RequestSucceeded} from "../common/commonStates";
import {SelectComponentReact} from "../common/ReactSelectComponent";
import {IAdminState} from "./AdminReducer";
import {IApplicationRootState} from "../../../applicationState";

type AdminAddPlanComponentPropsFromReduxStore = IAdminState;

export interface IAdminAddPlanComponentPropsFromParent {
   onAddPlan: (arg: IPlanInfo | undefined) => void;
   renderRequired: boolean;
   submitClicked?: boolean;
   plansSelected?: boolean;
   isSinglePlanSelection?: boolean;
}

export type AdminAddPlanComponentProps = AdminAddPlanComponentPropsFromReduxStore
    & IAdminAddPlanComponentPropsFromParent;

export interface IAdminAddPlanState {
    currentClientId: number | undefined;
    clients: IClientInfo[];
    currentPlanIds: number[] | undefined;
    currentPlan: IPlanInfo | null;
    currentClient: IClientInfo | null;
}

export class AdminAddPlanComponent extends React.Component<AdminAddPlanComponentProps, IAdminAddPlanState> {
    public constructor(props: any) {
        super(props);

        this.state = {
            currentClientId: undefined,
            clients: [],
            currentPlanIds: undefined,
            currentPlan: null,
            currentClient: null,
        };
    }

    public componentDidMount() {
        if (this.props.adminClientInfoState.kind === REQUEST_STATES.REQUEST_SUCCEEDED) {
            const clients = this.getListOfClients();

            this.setState({clients});
        }
    }

    public render() {
        return <div className="admin-add-plan" data-testid="admin-add-plan">
            <div className="admin-add-plan__clients-dropdown" data-testid="admin-add-plan__clients-dropdown">
                <SelectComponentReact
                    menuIsOpen={undefined}
                    id={"clients-dropdown"}
                    options={this.state.clients}
                    value={this.state.currentClient}
                    onChange={this.handleClientChange}
                    submitClicked={false}
                    getOptionLabel={(option: IClientInfo) => option.name}
                    getOptionValue={(option: IClientInfo) => option.id.toString(10)}
                    renderRequired={false}
                    placeholder="Select Client..."
                    stylesObject={{width: "100%"}}
                />
            </div>
            <div className="admin-add-plan__dropdowns-spacer"/>
            <div className="admin-add-plan__plans-dropdown" data-testid="admin-add-plan__plans-dropdown">
                <SelectComponentReact
                    id={"plans-dropdown"}
                    options={this.getClientPlanList()}
                    value={this.state.currentPlan}
                    onChange={this.handlePlanChange}
                    submitClicked={false}
                    getOptionLabel={(option: IPlanInfo) => option.name}
                    getOptionValue={(option: IPlanInfo) => option.id.toString(10)}
                    renderRequired={false}
                    placeholder={"Select Plan..."}
                    stylesObject={{width: "100%"}}
                />
                {this.renderRequiredCaption(
                     this.props.plansSelected,
                    this.props.renderRequired,
                    this.props.submitClicked,
                )}
            </div>
        </div>;
    }

    private getListOfClients() {
        const clientsAndPlans = (this.props.adminClientInfoState as RequestSucceeded<ClientsAndPlans>).data;
        return clientsAndPlans.valueSeq().toArray().sort(byName);
    }

    private getClientPlanList() {
        if (this.state.currentClient) {
            return getSuccessData(this.props.adminClientInfoState)!
                .get(this.state.currentClient.id)!.plans.valueSeq().toArray().sort(byName);
        }
        return [];
    }

    private handleClientChange = (client: IClientInfo) => {
        this.setState({...this.state, currentClient: client});
        if (this.props.isSinglePlanSelection) {
            this.props.onAddPlan(undefined);
        }
    };

    private handlePlanChange = (plan: IPlanInfo) => {
        if (this.props.isSinglePlanSelection) {
            this.setState({...this.state, currentPlan: plan});
        }
        this.props.onAddPlan(plan);
    };

    private renderRequiredCaption =
        (plansSelected?: boolean, renderRequired?: boolean, submitClicked?: boolean) => {
        const className = (submitClicked && !plansSelected)
            ? "common__required-caption-red"
            : "common__required-caption";

        return renderRequired
            ? <span className={className}>required</span>
            : null;
    };

}

export const mapStateToProps = (state: IApplicationRootState): AdminAddPlanComponentPropsFromReduxStore => {
    return {
        ...state.adminState!,
    };
};

const connectedComponent = connect<AdminAddPlanComponentPropsFromReduxStore>(mapStateToProps)(AdminAddPlanComponent);
export default connectedComponent;
