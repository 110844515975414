import * as React from "react";
import {IInformSummary} from "../../model/inform/InformSummary";
import {getPositiveNegativeValues} from "./informUtil";
import {getQuarterAndYearByDate} from "../../utils/dateUtil";

export interface IInformQuarterHighlightsProps {
    summary: IInformSummary;
}

export const InformQuarterHighlights: React.FunctionComponent<IInformQuarterHighlightsProps> = (props) => {

    const verifyQuarterHighlights = (quarterHighlights: string) => {
        return quarterHighlights && quarterHighlights !== "---";
    };

    const renderSummaryValue = (quarterHighlights: string) => {
        return verifyQuarterHighlights(quarterHighlights)
            && <div className="inform-quarter-highlights__value"
                    data-testid="inform-quarter-highlights__summary-value">{quarterHighlights}</div>;
    };

    const renderSummary = () => {
        return <div className="inform-quarter-highlights__summary-container">
            <div className="inform-quarter-highlights__sub-header">Summary</div>
            {renderSummaryValue(props.summary.quarterHighlights1)}
            {renderSummaryValue(props.summary.quarterHighlights2)}
            {renderSummaryValue(props.summary.quarterHighlights3)}
            {renderSummaryValue(props.summary.quarterHighlights4)}
        </div>;
    };

    const renderPositiveNegativeValues = (values: string[]) => {
        return values.map((value, index) => {
            return <div key={index} className="inform-quarter-highlights__value">{value}</div>;
        });
    };

    const renderPositive = (positive: string[]) => {
        return <div data-testid="inform-quarter-highlights__positive-container">
            <div className="inform-quarter-highlights__sub-header">Positive</div>
            {renderPositiveNegativeValues(positive)}
        </div>;
    };

    const renderNegative = (negative: string[]) => {
        return <div className="inform-quarter-highlights__negative-container"
                    data-testid="inform-quarter-highlights__negative-container">
            <div className="inform-quarter-highlights__sub-header">Negative</div>
            {renderPositiveNegativeValues(negative)}
        </div>;
    };

    const renderPositiveNegative = () => {
        const positiveNegativeValue: Map<string, string[]> = getPositiveNegativeValues(props.summary);

        return <div className="inform-quarter-highlights__positive-negative-container">
            {renderPositive(positiveNegativeValue.get("positive")!)}
            {renderNegative(positiveNegativeValue.get("negative")!)}
        </div>;
    };

    const getQuarterNumber = () => {
        const [day, month, year] =
            props.summary.ifaRatingEffectiveDate.split('/').map(Number);

        return getQuarterAndYearByDate(new Date(year, month - 1, day)).quarter.slice(1);
    };

    return <div className="inform-quarter-highlights__container" data-testid="inform-quarter-highlights__container">
        <div className="inform-quarter-highlights__header">
            Quarter {getQuarterNumber()} Inform Assessment Highlights
        </div>
        <div className="inform-quarter-highlights__values-container">
            {renderSummary()}
            {renderPositiveNegative()}
        </div>
    </div>;
};