import {ISelectValue} from "../components/common/Select.component";
import {FormattedMessage, IntlShape} from "react-intl";
import * as React from "react";

let globalIntl: IntlShape;
export const setIntl = (intl: IntlShape) => {
    globalIntl = intl;
};

const getIntl = () => {
    return globalIntl;
};

export const formattedMessageTransform = (selectValue: ISelectValue) => {
    return <FormattedMessage
        id={`filters.${selectValue.name.toLowerCase().replace(/ /g, "-")}`}
        defaultMessage={selectValue.name} />;
};

export const getTranslation = (id: string, defaultMessage: string) => {
    const intl = getIntl();
    if (!intl)
        return defaultMessage;

    const messageDescriptor = {
        id,
        description: "",
        defaultMessage,
    };
    return intl.formatMessage(messageDescriptor);
};

export const getLocale = () => {
    const intl = getIntl();
    if (!intl)
        return;
    return intl.locale;
};
