import {ApiBase} from "./ApiBase";
import {AxiosResponse} from "axios";
import {IApiResult} from "./AxiosWealthManagementApi";
import {getConfig} from "../utils/envUtil";

export interface IMediaGroup {
    id?: number;
    name: string;
    description?: string;
    planSpecific: boolean;
    serviceTypeSpecific: boolean;
    regionSpecific: boolean;
    languageSpecific: boolean;
    documentId?: number;
    templateFile?: File;
}

export interface IMediaFile {
    id?: number;
    mediaGroupName: string;
    quarter: number;
    year: number;
    label: string | null;
    fileName: string;
    region: string | null;
    serviceType: string | null;
    language: string | null;
}

export interface IServiceType {
    id?: number;
    name: string;
}

export class MediaApi {
    public static fetchMediaGroups(): Promise<IMediaGroup[]> {
        return ApiBase.processGetUnwrapped("/media-groups",
            (response: AxiosResponse): IMediaGroup[] => {
                return response.data as IMediaGroup[];
            });
    }

    public static addMediaGroup(formData: FormData): Promise<IApiResult<number>> {
        return ApiBase.processPostWithResponseBody(
            "/media-groups",
            formData,
            (response: AxiosResponse): number => {
                return response.data;
            });
    }

    public static modifyMediaGroup(formData: FormData): Promise<void> {
        return ApiBase.processPost(
            `/media-groups/${formData.get("id")}`,
            formData,
        ).then(() => Promise.resolve());
    }

    public static deleteMediaGroup(id: number) {
        return ApiBase.processDelete(`/media-groups/${id}`);
    }

    public static fetchPlanSpecificMediaFiles(): Promise<IMediaFile[]> {
        return ApiBase.processGetUnwrapped("/plans/current/media-files",
            (response: AxiosResponse): IMediaFile[] => {
            return response. data as IMediaFile[];
        });
    }

    public static addPlanSpecificMediaFile(formData: FormData): Promise<IApiResult<number>> {
        return ApiBase.processPostWithResponseBody(
            "/plans/current/media-files",
            formData,
            (response: AxiosResponse): number => {
                return response.data;
            }
        );
    }

    public static getDocumentDownload(mediaFileId: number) {
        const baseURL = getConfig().FRONTEND_CONFIG_BACKEND_URL;
        const url = `${baseURL}/download/media-files/${mediaFileId}`;
        window.open(url, "_blank");
    }

    public static deleteMediaFile(mediaFileId: number) {
        return ApiBase.processDelete(
            `/media-files/${mediaFileId}`
        );
    }

    public static fetchNonPlanSpecificMediaFiles(): Promise<IMediaFile[]> {
        return ApiBase.processGetUnwrapped("/shared/media-files",
            (response: AxiosResponse): IMediaFile[] => {
                return response. data as IMediaFile[];
            });
    }

    public static addSharedMediaFile(formData: FormData): Promise<IApiResult<number>> {
        return ApiBase.processPostWithResponseBody(
            "/shared/media-files",
            formData,
            (response: AxiosResponse): number => {
                return response.data;
            }
        );
    }

    public static fetchServiceType(): Promise<IServiceType[]> {
        return ApiBase.processGetUnwrapped("/shared/service-type",
            (response: AxiosResponse): IServiceType[] => {
                return response.data as IServiceType[];
            });
    }

    public static getExportTemplatedDownload(templateDocumentId: number) {
        const baseURL = getConfig().FRONTEND_CONFIG_BACKEND_URL;
        const url = `${baseURL}/download/powerbi/media-groups/${templateDocumentId}`;
        window.open(url, "_blank");
    }
}