import {Action, handleActions} from "redux-actions";
import {FEEDBACK_SUBMIT_ACTION} from "./feedbackPageAction";

export interface IFeedbackPageState {
    feedbackText: string | undefined;
}

export const initialState = {
    feedbackText: undefined,
};

const reducerMap = {
    [FEEDBACK_SUBMIT_ACTION]: (state: IFeedbackPageState, action: Action<string>): IFeedbackPageState => {
        return{...state, feedbackText: action.payload!};
    },
};

export default handleActions<IFeedbackPageState, string>(reducerMap, { feedbackText: "" });
