import * as React from "react";
import {connect} from "react-redux";
import {userType} from "../../../mainReducerMapSelectors";
import AdminClientInfoRequester from "../admin/AdminClientInfoRequester";
import {ErrorComponent} from "../base/Error.component";
import MyClientsComponent from "./MyClients.component";
import {UserTypeEnum} from "../../model/UserInfo.model";
import {IApplicationRootState} from "../../../applicationState";

interface IMyClientsPagePropsFromStore {
    userType: UserTypeEnum;
}

export class MyClientsPage extends React.Component<IMyClientsPagePropsFromStore> {
    public render() {
        if (this.props.userType === UserTypeEnum.CLIENT) {
            return <ErrorComponent/>;
        }
        return <div id="my-clients-page" data-testid="my-clients-page">
            <AdminClientInfoRequester><MyClientsComponent/></AdminClientInfoRequester>
        </div>;
    }
}

export const mapStateToProps = (state: IApplicationRootState): IMyClientsPagePropsFromStore => {
    return {
        userType: userType(state),
    };
};

export default connect(mapStateToProps)(MyClientsPage);
