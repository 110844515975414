import {Action, Dispatch} from "redux";
import {MRT_ColumnFiltersState, MRT_PaginationState} from "material-react-table";

export enum ManagerStrategiesActionTypes {
    CLEAR_MANAGER_STRATEGIES = "CLEAR_MANAGER_STRATEGIES",
    UPDATE_MANAGER_STRATEGIES_FILTERS = "UPDATE_MANAGER_STRATEGIES_FILTERS",
    UPDATE_MANAGER_STRATEGIES_GLOBAL_FILTER = "UPDATE_MANAGER_STRATEGIES_GLOBAL_FILTER",
    UPDATE_MANAGER_STRATEGIES_PAGINATION = "UPDATE_MANAGER_STRATEGIES_PAGINATION",
    SET_MANAGER_STRATEGIES_SCROLL_TO = "SET_MANAGER_STRATEGIES_SCROLL_TO",
}

export const clearStrategiesAction = () => {
    return (dispatch: Dispatch<Action<ManagerStrategiesActionTypes>>) => {
        dispatch({type: ManagerStrategiesActionTypes.CLEAR_MANAGER_STRATEGIES, payload: {}});
    };
};

export const updateFiltersAction = (managerId: number, filtersState: MRT_ColumnFiltersState) => {
    const payload = {managerId, filtersState};
    return (dispatch: Dispatch<Action<ManagerStrategiesActionTypes>>) => {
        dispatch({type: ManagerStrategiesActionTypes.UPDATE_MANAGER_STRATEGIES_FILTERS, payload});
    };
};

export const updateGlobalFilterAction = (managerId: number, globalFilterState: string) => {
    const payload = {managerId, globalFilterState};
    return (dispatch: Dispatch<Action<ManagerStrategiesActionTypes>>) => {
        dispatch({type: ManagerStrategiesActionTypes.UPDATE_MANAGER_STRATEGIES_GLOBAL_FILTER, payload});
    };
};

export const updatePaginationAction = (managerId: number, pagination: MRT_PaginationState) => {
    const payload = {managerId, pagination};
    return (dispatch: Dispatch<Action<ManagerStrategiesActionTypes>>) => {
        dispatch({type: ManagerStrategiesActionTypes.UPDATE_MANAGER_STRATEGIES_PAGINATION, payload});
    };
};

export const setScrollToBackstopAction = (managerId: number, backstopId: number) => {
    const payload = {managerId, scrollToBackstopId: backstopId};
    return (dispatch: Dispatch<Action<ManagerStrategiesActionTypes>>) => {
        dispatch({type: ManagerStrategiesActionTypes.SET_MANAGER_STRATEGIES_SCROLL_TO, payload});
    };
};

export default {
    clearStrategiesAction,
    updateFiltersAction,
    updateGlobalFilterAction,
    updatePaginationAction,
    setScrollToBackstopAction
};