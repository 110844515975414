import * as React from "react";
import {SharedDocument, SharedDocumentType} from "../../model/SharedDocument.model";
import {getQuartersSelectValues, QuarterFilterItems} from "../../utils/dateUtil";
import {FiltersComponent} from "../common/Filters.component";
import {ISelectValue, SelectComponent} from "../common/Select.component";
import {
    ALL_DOCUMENT_TYPES,
    ALL_YEARS,
    IFilterActions,
} from "./SharedDocumentsPage";
import {FormattedMessage} from "react-intl";
import {formattedMessageTransform} from "../../utils/translationUtil";
import {getSortingItems, SortOptions} from "../../utils/sharedDocsUtil";

export interface IFilterSelectionsState {
    selectedDocumentType: number;
    selectedYear: number;
    selectedQuarter: QuarterFilterItems;
    selectedOrder: SortOptions;
}

export interface ISharedDocumentsFilters {
    documents: SharedDocument[];
    filterActions: IFilterActions;
    filterSelections: IFilterSelectionsState;
}

export const SharedDocumentsFilters: React.FunctionComponent<ISharedDocumentsFilters> = (props) => {
    function indexOfTypeName(name: string, list: SharedDocumentType[]) {
        return list.map((it) => it.name).indexOf(name);
    }

    function getDocumentTypes(): ISelectValue[] {
        const documentTypes = props.documents
            .map((it) => it.documentType)
            .filter((item, index, self) => indexOfTypeName(item.name, self) === index)
            .sort((item1, item2) => item1.name.toLowerCase().localeCompare(item2.name.toLowerCase()));

        return [{id: ALL_DOCUMENT_TYPES, name: "All Document Types"}, ...documentTypes];
    }

    function getYears(): ISelectValue[] {
        const documentYears = props.documents
            .map((it) => it.date.getFullYear())
            .filter((elem, index, self) => index === self.indexOf(elem))
            .sort((item1, item2) => item2 - item1)
            .map((year) => ({id: year, name: year.toString()}));

        return [{id: ALL_YEARS, name: "All Years"}, ...documentYears];
    }

    const handleFilterChange = (e: any, filterCode: string) => {
        if (filterCode === "by_type") {
            props.filterActions.handleDocumentTypeChange(e, 0, e.target.value);
        } else if (filterCode === "by_year") {
            props.filterActions.handleYearChange(e, 0, e.target.value);
        } else if (filterCode === "by_quarter") {
            props.filterActions.handleQuarterChange(e, 0, e.target.value);
        }
    };

    const sortChange = (e: any) => {
        props.filterActions.handleSortingChange(e, 0, e.target.value);
    };

    return <div className="shared-documents__filter-and-sort-bar">
        <FiltersComponent
            filters={[
                {
                    values: getYears(),
                    selectedValue: props.filterSelections.selectedYear,
                    code: "by_year",
                    width: 125,
                },
                {
                    values: getQuartersSelectValues(),
                    selectedValue: props.filterSelections.selectedQuarter,
                    code: "by_quarter",
                    width: 150,
                },
                {
                    values: getDocumentTypes(),
                    selectedValue: props.filterSelections.selectedDocumentType,
                    code: "by_type",
                    width: 195,
                },
            ]}
            onFilterChangeHandler={handleFilterChange}
            onClearFilters={() => {
                props.filterActions.handleDocumentTypeChange({}, 0, ALL_DOCUMENT_TYPES);
                props.filterActions.handleYearChange({}, 0, ALL_YEARS);
                props.filterActions.handleQuarterChange({}, 0, QuarterFilterItems.ALL);
            }}
        />
        <div className="separator"/>
        <div className="shared-documents__sort">
            <div className="shared-documents__sort-dropDown-label">
                <FormattedMessage id="shared-docs.sort-by" defaultMessage="Sort by:" />
            </div>
            <div className="shared-documents-sort-right">
                <SelectComponent
                    values={getSortingItems()}
                    selected={props.filterSelections.selectedOrder}
                    width={275}
                    handleChange={sortChange}
                    menuItemTransform={formattedMessageTransform}
                    classNameSuffix={"shared-documents__sort"}
                    id={"shared-documents__sort-dropDown"}/>
            </div>
        </div>
    </div>;
};
