import {Set} from "immutable";
import * as React from "react";
import {useState} from "react";
import {formatCurrency} from "../../utils/numberUtil";
import {NewSelectComponent} from "../common/NewSelect.component";
import {ISelectValue} from "../common/Select.component";
import {CashFlowsComponent} from "./cash-flows/CashFlows.component";
import {InvestmentsByGroupTableComponent} from "./InvestmentsByGroupTable.component";
import {getDataSum, getLatestMonth, getLatestYear, getSumsByAsset, IData} from "./InvestmentsDataUtil";
import {UserTypeEnum} from "../../model/UserInfo.model";
import {ErrorComponent} from "../base/Error.component";
import {NoAccessNotification} from "../base/header/NoAccessNotification";
import {IInvestmentsFromStore} from "./InvestmentsPage";
import moment = require("moment");

const lodash = require("lodash");

export interface IInvestmentsFromState {
    year?: number | undefined;
    month?: number;
}

export enum HoldingsGroupBy {
    BY_ASSET_CLASS,
    BY_TIER
}

export const INVESTMENT_CLIENT_HIDDEN_MESSAGE = "The Investments section is currently hidden from client users of this plan.";
export const INVESTMENT_NO_DATA_MESSAGE = "There is no holdings data coming through. There seems to be an issue with the mapping. " +
    "Please reach out to the Iris portal team to fix this.";

export const InvestmentsComponent: React.FunctionComponent<IInvestmentsFromStore & IInvestmentsFromState> = (props) => {

    const defaultYear = props.holdingsForPlan.length > 0 ? getLatestYear(props.holdingsForPlan) : 0;

    const [currentYear, setCurrentYear] = useState(defaultYear);
    const [currentMonth, setCurrentMonth] = useState(props.holdingsForPlan.length > 0
        ? getLatestMonth(props.holdingsForPlan, defaultYear) : 0);
    const [groupBy, setGroupBy] = useState(HoldingsGroupBy.BY_ASSET_CLASS);

    const monthlyHoldings = props.holdingsForPlan
        .filter((holding) => {
            const date = holding.asOfDate;
            return date.getMonth() === currentMonth && date.getFullYear() === currentYear;
        });

    const assets: IData[] = getSumsByAsset(monthlyHoldings);

    const renderAssetAllocationTable = () => {
        return <InvestmentsByGroupTableComponent holdingsForPlan={props.holdingsForPlan}
                                                 holdingsUniverse={props.holdingsUniverse}
                                                 year={currentYear!}
                                                 month={currentMonth!}
                                                 groupBy={groupBy}
        />;
    };

    const getYears = (): ISelectValue[] => {
        const years = Set(props.holdingsForPlan.map((holding) => holding.asOfDate.getFullYear()));
        return years.map((year: number) => ({name: year.toString(), id: year}))
            .toArray().sort((a, b) => b.id - a.id);
    };

    const getMonths = (year: number | undefined): ISelectValue[] => {
        if (!year) {
            return [];
        }

        return lodash.uniqWith(props.holdingsForPlan
                .filter((h) => h.asOfDate.getFullYear() === year)
                .map((h) => ({name: moment(h.asOfDate).format("MMMM"), id: h.asOfDate.getMonth()}))
            , lodash.isEqual)
            .sort((a: ISelectValue, b: ISelectValue) => (a.id as number) - (b.id as number));
    };

    const handleYearChange = (e: any) => {
        const year = e.target.value;
        setCurrentYear(year);
        const latestMonth = getLatestMonth(props.holdingsForPlan, year);
        setCurrentMonth(latestMonth);
    };

    const handleMonthChange = (e: any) => {
        setCurrentMonth(e.target.value);
    };

    const renderDateSelection = () => {
        return <div className="investments__date-selection">
            <div className="investments__months-container" data-testid="investments__months-container">
                <div className="investments__dropdown-label bold">Month</div>
                <NewSelectComponent
                    id={"investments__months"}
                    values={getMonths(currentYear)}
                    selected={currentMonth}
                    width={140}
                    handleChange={handleMonthChange}
                    classNameSuffix="filter-menu__asset-class"
                />
            </div>
            <div className="investments__years-container" data-testid="investments__years-container">
                <div className="investments__dropdown-label bold">Year</div>
                <NewSelectComponent
                    id="investments__years"
                    values={getYears()}
                    selected={currentYear}
                    width={120}
                    handleChange={handleYearChange}
                    classNameSuffix="filter-menu__asset-class"
                />
            </div>
        </div>;
    };

    function renderGroupByButtons() {
        return <span>
            <div className="investments__group-by-toggle">
                <div
                    id="investments__by-asset-class-button"
                    className={groupBy === HoldingsGroupBy.BY_ASSET_CLASS ? " activated" : " clickable"}
                    onClick={() => setGroupBy(HoldingsGroupBy.BY_ASSET_CLASS)}
                >
                    Asset Class
                </div>
                <div
                    id="investments__by-tier-button"
                    className={groupBy === HoldingsGroupBy.BY_TIER ? " activated" : " clickable"}
                    onClick={() => setGroupBy(HoldingsGroupBy.BY_TIER)}
                    data-testid="investments__by-tier-button"
                >
                    Tier
                </div>
            </div>
        </span>;
    }

    const showInvestments = () => {
        return props.hasHoldings
            ? (props.userType === UserTypeEnum.CLIENT
                ? props.investmentsEnabled
                : true)
            : false;
    };

    const renderNoInvestmentsClientAccessNotification = () => {
        return props.userType !== UserTypeEnum.CLIENT && !props.investmentsEnabled
            ? <NoAccessNotification
                message={INVESTMENT_CLIENT_HIDDEN_MESSAGE}/>
            : null;
    };

    const renderNoData = () => {
        return <div>
            {renderNoInvestmentsClientAccessNotification()}
            <div className="new-common-styles investments__page cash-flows__page">
                <div className="investments__header-container">
                    <h1 className="investments__header cash-flows__header">Investments</h1>
                </div>
                <div id="investments__no-data">
                    {INVESTMENT_NO_DATA_MESSAGE}
                </div>
            </div>
        </div>;
    };

    const renderInvestments = () => {
        return props.holdingsForPlan.length > 0
            ? <div data-testid="investments-component__container">
                {renderNoInvestmentsClientAccessNotification()}
                <div className="new-common-styles investments__page cash-flows__page">
                    <div className="investments__header-container">
                        <h1 className="investments__header cash-flows__header">Investments</h1>
                        {renderGroupByButtons()}
                    </div>
                    <div className="investments__table-container">
                        <div className="investments__table-header">
                            <div className="investments__title-selection">
                                <h2 className="gray">Plan Overview</h2>
                                {renderDateSelection()}
                            </div>
                            <h4 className="investments__total-assets gray" data-testid="investments__total-assets">
                                {`Total Assets: ${formatCurrency(getDataSum(assets), 0)}`}
                            </h4>
                        </div>
                        {renderAssetAllocationTable()}
                    </div>
                    <CashFlowsComponent holdings={props.holdingsForPlan} groupBy={groupBy}/>
                </div>
            </div>
            : renderNoData();
    };

    return showInvestments()
        ? renderInvestments()
        : <ErrorComponent/>;
};

