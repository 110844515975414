export const convertMapToArray = (map: Map<any, any[]>): any[][] => {
    const mapArray: any[] = [];
    map.forEach((value, key) => {
        mapArray.push([key, value]);
    });
    return mapArray;
};

export function copyMap(map: Map<any, any>): Map<any, any> {
    const newMap = new Map();
    map.forEach(((value, key) => {
        newMap.set(key, value);
    }));
    return newMap;
}

export function copyMapOfArrays(map: Map<any, any[]>): Map<any, any[]> {
    const newMap = new Map();
    map.forEach(((value, key) => {
        newMap.set(key, Array.from(value));
    }));
    return newMap;
}

export function convertDictionaryToArray<T>(dict: {[key: number]: T}): T[] {
   return Object.keys(dict)
        .map((key) => Number.parseInt(key, 10))
        .map((key) => (dict[key]));
}
