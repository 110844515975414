import * as React from "react";
import {DonutChartCommon} from "../DonutChartCommon";
import {getPercentagesFromResponseList, getSummaryFromResponseList} from "../../OddIqUtils";
import {IDonutChartData, IDonutChartProperties, summaryToDonutChartData} from "../../OddIqChartingUtils";
import {IOddResponse, IOddSectionsQuestions} from "../../../../api/OddIqApi";

export interface IOddTotalResultsByRiskCategoryProps {
    oddResponses: IOddResponse[];
    oddSectionsQuestions: IOddSectionsQuestions;
}

export const OddTotalResultsByRiskCategory: React.FunctionComponent<IOddTotalResultsByRiskCategoryProps> = (props) => {
    const responseSummary = getSummaryFromResponseList(props.oddResponses);
    const responseSummaryPercents = getPercentagesFromResponseList(props.oddResponses);

    const donutChartData: IDonutChartData[] = summaryToDonutChartData(responseSummary, responseSummaryPercents);

    const chartDimensions = 200;
    const chartPadding = {
        top: 100,
        bottom: 100,
        right: 175,
        left: 150,
    };
    const chartProperties: IDonutChartProperties = {
        innerRadius: 60,
        donutThickness: 20,
    };

    return <div className="odd-page__donut-chart-container" data-testid="odd-total-results-by-risk-category">
        <DonutChartCommon chartData={donutChartData}
                          prefix="odd-page"
                          totalLabel="Data Points"
                          title=""
                          chartProperties={chartProperties}
                          padding={chartPadding}
                          innerChartDimensions={chartDimensions}
        />
    </div>;
};