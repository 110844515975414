import * as React from "react";
import {useEffect, useState} from "react";
import {useDispatch, useSelector} from "react-redux";
import {ProductSummary} from "../../model/product/ProductSummary";
import {FormattedMessage} from "react-intl";
import {byName} from "../../utils/listUtil";
import {Map} from "immutable";
import {
    MaterialReactTable,
    MRT_Cell,
    MRT_Column,
    MRT_ColumnDef,
    MRT_ColumnFiltersState,
    MRT_PaginationState,
    MRT_ToggleGlobalFilterButton,
    useMaterialReactTable
} from "material-react-table";
import {Link} from "react-router-dom";
import {Box, IconButton, Tooltip} from "@mui/material";
import {AonColors} from "../../../../css/Colors";
import {DEFAULT_PAGINATION, IManagerStrategiesState} from "./ManagerStrategiesReducer";
import managerStrategiesActions from "./ManagerStrategiesActions";
import {IApplicationRootState} from "../../../applicationState";
import {
    bottomToolbarStyle,
    filterTextFieldStyle,
    headersStyle,
    tableStyle,
    textStyle,
    topToolbarStyle
} from "./ManagerStratergies.style";
import {scrollToElement} from "../../utils/browserUtil";

export interface IManagerStrategiesProps {
    managerId: number;
    productSummaries: Map<number, ProductSummary>;
}

export const ManagerStrategies: React.FunctionComponent<IManagerStrategiesProps> = (props) => {
    const [data, setData] = useState<ProductSummary[]>([]);
    const [columnFilters, setColumnFilters] = useState<MRT_ColumnFiltersState>([]);
    const [globalFilterState, setGlobalFilterState] = useState<string>("");
    const [showGlobalFilter, setShowGlobalFilter] = useState<boolean>(false);
    const [allowPaginationReset, setAllowPaginationReset] = useState<boolean>(false);

    const strategiesTableStateFromStore:IManagerStrategiesState | undefined
        = useSelector((state: IApplicationRootState) => state.managerStrategiesState);
    const dispatch = useDispatch();

    const getPagination = (): MRT_PaginationState => {
        if (strategiesTableStateFromStore?.managerId && strategiesTableStateFromStore.managerId === props.managerId) {
            return strategiesTableStateFromStore.pagination;
        } else {
            return DEFAULT_PAGINATION;
        }
    };

    const [pagination, setPagination] = useState<MRT_PaginationState>(getPagination());

    const resetTableState = () => {
        setColumnFilters([]);
        setGlobalFilterState("");
        setShowGlobalFilter(false);
        setPagination(DEFAULT_PAGINATION);
    };

    useEffect(() => {
        const productSummaries = getAllProductSummariesForManager(props.managerId).sort(byName);
        setData(productSummaries);
    }, [props]);

    useEffect(() => {
        dispatch(managerStrategiesActions.clearStrategiesAction());
        resetTableState();
    }, [props.managerId]);

    useEffect(() => {
        dispatch(managerStrategiesActions.updateFiltersAction(props.managerId, columnFilters));
    }, [columnFilters]);

    useEffect(() => {
        dispatch(managerStrategiesActions.updateGlobalFilterAction(props.managerId, globalFilterState));
    }, [globalFilterState]);

    useEffect(() => {
        //disable first reset to 0
        if (pagination.pageIndex === 0 && !allowPaginationReset) {
            const paginationFromStore = getPagination();
            if (paginationFromStore.pageIndex !== 0 || paginationFromStore.pageSize !== DEFAULT_PAGINATION.pageSize)
            {
                setPagination(paginationFromStore);
            }
            setAllowPaginationReset(true);
        } else {
            dispatch(managerStrategiesActions.updatePaginationAction(props.managerId, pagination));
        }
    }, [pagination]);

    useEffect(() => {
        if (strategiesTableStateFromStore?.managerId && strategiesTableStateFromStore.managerId === props.managerId) {
            setColumnFilters(strategiesTableStateFromStore.filtersState);
            setGlobalFilterState(strategiesTableStateFromStore.globalFilterState);
            setShowGlobalFilter(!!strategiesTableStateFromStore.globalFilterState);

            if (strategiesTableStateFromStore.scrollToBackstopId) {
                setTimeout(() => {
                    scrollToElement(`#product_${strategiesTableStateFromStore.scrollToBackstopId}`, 100, true);
                }, (50));
            }
        }
    }, []);

    const replaceBlankByUndefined = (productSummary: ProductSummary): ProductSummary => {
        return {
            ...productSummary,
            region: productSummary.region === "" ? "Undefined" : productSummary.region,
            category: productSummary.category === "" ? "Undefined" : productSummary.category,
            style: productSummary.style === "" ? "Undefined" : productSummary.style,
            approach: productSummary.approach === "" ? "Undefined" : productSummary.approach,
        };
    };

    const getAllProductSummariesForManager = (managerId: number): ProductSummary[] => {
        return props.productSummaries
            .filter((it: ProductSummary) => it ? it.manager.managerBackstopId === managerId : false)
            .valueSeq()
            .toArray()
            .map((it: ProductSummary) => replaceBlankByUndefined(it));
    };

    const customFilterFn = (row: any, id: string, filterValue: string[]) => {
        if (filterValue.length === 0) {
            return true;
        }
        filterValue = filterValue.map((item: string) => item.replace(/\s\(\d+\)/g, ""));
        return filterValue.includes(row.getValue(id));
    };

    const strategyNameLink = ({ cell }: { cell: MRT_Cell<ProductSummary> }) => {
        const productName = cell.getValue<string>();
        const backstopId = cell.row.original.backstopId;
        return (
            <Link to={`/products/${backstopId}`}
                onClick={()=>dispatch(managerStrategiesActions.setScrollToBackstopAction(props.managerId, backstopId))}
            >
                <div id={`product_${backstopId}`}>{productName}</div>
            </Link>
        );
    };

    const columns: MRT_ColumnDef<ProductSummary>[] = [
            { accessorKey: "name", header: "Strategy Name", Cell: strategyNameLink, size: 200},
            { accessorKey: "assetType", header: "Asset Class", size: 100},
            { accessorKey: "productRating", header: "Rating", size: 100},
            { accessorKey: "approach", header: "Approach", size: 100},
            { accessorKey: "category", header: "Category", size: 100},
            { accessorKey: "region", header: "Region", size: 100},
            { accessorKey: "style", header: "Style", size: 100},
        ].map(columnProps => ({
            ...columnProps,
            filterVariant: "multi-select",
            filterFn: customFilterFn,
            muiTableHeadCellProps:
                ({ column }: { column: MRT_Column<ProductSummary> }) => ({ sx: headersStyle(column) }),
            muiTableBodyCellProps: {sx: textStyle("400", "14px", "18px", AonColors.AonNavy, "12px") },
            muiFilterTextFieldProps: {sx: filterTextFieldStyle},
        }));

    const tableHeader = (
        <h2 className="title-medium gray">
            <FormattedMessage id="manager.strategies" defaultMessage="Strategies"/>
        </h2>
    );

    const handleFilterChange = (filters: MRT_ColumnFiltersState) => {
        table.resetPageIndex(true);
        setColumnFilters(filters);
    };

    const handleGlobalFilterChange = (value: string) => {
        table.resetPageIndex(true);
        setGlobalFilterState(value);
    };

    const resetTable = () => {
        resetTableState();
    };

    const table = useMaterialReactTable({
            columns,
            data,
            enableFacetedValues: true,
            initialState: {
                showColumnFilters: false,
                columnFilters,
            },
            autoResetPageIndex: false,
            enableTopToolbar: true,
            enableBottomToolbar: true,
            enableColumnActions: false,
            enableSorting: false,
            columnFilterDisplayMode: "popover",
            enableToolbarInternalActions: true,
            globalFilterFn: "contains",
            renderToolbarInternalActions: ({ table }) => (
                <Box>
                    <MRT_ToggleGlobalFilterButton table={table} />
                    <Tooltip title="Clear Filters">
                        <IconButton onClick={resetTable} data-testid="clear-filters">
                            <i className="strategies-clear-icon-color fa-solid fa-filter-slash"/>
                        </IconButton>
                    </Tooltip>
                </Box>
            ),
        renderTopToolbarCustomActions: () => tableHeader,
            onColumnFiltersChange: handleFilterChange,
            onShowGlobalFilterChange: setShowGlobalFilter,
            onGlobalFilterChange: handleGlobalFilterChange,
            onPaginationChange: setPagination,
            state: {
                columnFilters,
                globalFilter: globalFilterState,
                showGlobalFilter,
                pagination,
            },
            muiTableProps: {sx: tableStyle},
            muiTablePaperProps: {sx: {boxShadow: "none"}},
            muiBottomToolbarProps: {sx: bottomToolbarStyle},
            muiTableHeadRowProps: { sx: {boxShadow: "none"}},
            muiTopToolbarProps: {sx: topToolbarStyle},
            icons: {
                SearchIcon: () => <i className="strategies-search-icon-color far far fa-search" />,
                SearchOffIcon: () => <i className="strategies-search-icon-color far fa-light fa-magnifying-glass-minus"/>,
            }
        });

    return <div className="strategies-container" data-testid="strategies-container">
        <MaterialReactTable table={table}/>
    </div>;
};