import {Action, handleActions} from "redux-actions";
import {ManagerStrategiesActionTypes} from "./ManagerStrategiesActions";
import {MRT_ColumnFiltersState, MRT_PaginationState} from "material-react-table";

export const DEFAULT_PAGINATION: MRT_PaginationState = {pageIndex: 0, pageSize: 10};
export interface IManagerStrategiesState {
    managerId: number | undefined;
    filtersState: MRT_ColumnFiltersState;
    globalFilterState: string;
    pagination: MRT_PaginationState;
    scrollToBackstopId: number | undefined;
}

export const initialState: IManagerStrategiesState = {
    managerId: undefined,
    filtersState: [],
    globalFilterState: "",
    pagination: DEFAULT_PAGINATION,
    scrollToBackstopId: undefined,
};

const setScrollToBackstopId = (state: IManagerStrategiesState, action: Action<IManagerStrategiesState>):
    IManagerStrategiesState => {
    return {
        ...state,
        managerId: action.payload ? action.payload.managerId : initialState.managerId,
        scrollToBackstopId: action.payload ? action.payload.scrollToBackstopId : initialState.scrollToBackstopId,
    };
};

const updatePagination = (state: IManagerStrategiesState, action: Action<IManagerStrategiesState>):
    IManagerStrategiesState => {
    return {
        ...state,
        managerId: action.payload ? action.payload.managerId : initialState.managerId,
        pagination: action.payload ? action.payload.pagination : initialState.pagination,
    };
};

const updateFilters = (state: IManagerStrategiesState, {payload}: Action<IManagerStrategiesState>):
        IManagerStrategiesState => {
        return {
        ...state,
        managerId: payload ? payload.managerId : initialState.managerId,
        filtersState: payload ? payload.filtersState : initialState.filtersState,
    };
};

const updateGlobalFilter = (state: IManagerStrategiesState, {payload}: Action<IManagerStrategiesState>):
    IManagerStrategiesState => {
    return {
        ...state,
        managerId: payload ? payload.managerId : initialState.managerId,
        globalFilterState: payload ? payload.globalFilterState : initialState.globalFilterState
    };
};

const clearStrategies = (state: IManagerStrategiesState, {}: Action<{}>): IManagerStrategiesState => {
    return {
        ...state,
        managerId: initialState.managerId,
        filtersState: initialState.filtersState,
        globalFilterState: initialState.globalFilterState,
        pagination: initialState.pagination,
        scrollToBackstopId: initialState.scrollToBackstopId,
    };
};

const reducerMap = {
    [ManagerStrategiesActionTypes.UPDATE_MANAGER_STRATEGIES_FILTERS]: updateFilters,
    [ManagerStrategiesActionTypes.CLEAR_MANAGER_STRATEGIES]: clearStrategies,
    [ManagerStrategiesActionTypes.UPDATE_MANAGER_STRATEGIES_GLOBAL_FILTER]: updateGlobalFilter,
    [ManagerStrategiesActionTypes.UPDATE_MANAGER_STRATEGIES_PAGINATION]: updatePagination,
    [ManagerStrategiesActionTypes.SET_MANAGER_STRATEGIES_SCROLL_TO]: setScrollToBackstopId,
};

export default handleActions<IManagerStrategiesState, any>(reducerMap, initialState);
