import * as React from "react";
import {connect} from "react-redux";
import {AnyAction, bindActionCreators, Dispatch} from "redux";
import {FlashReportSummary} from "../../model/FlashReportSummary.model";
import {MeetingNoteSummary} from "../../model/MeetingNoteSummary.model";
import {formatLongDate} from "../../utils/dateUtil";
import {TrackedLink} from "../base/TrackedLink.component";
import {getFlashReportPDF} from "../flash-report/flashReportActions";
import {getMeetingNotePdf} from "../meeting-note/meetingNoteActions";

interface IRecentUpdateLinkPropsFromParent {
    recentUpdate: FlashReportSummary | MeetingNoteSummary;
    isLast: boolean;
    managerId: number | undefined;
}

export interface IRecentUpdateLinkPropsFromActions {
    actions: {
        getFlashReportPDF: typeof getFlashReportPDF;
        getMeetingNotePdf: typeof getMeetingNotePdf;
    };
}

export type RecentUpdateLinkProps = IRecentUpdateLinkPropsFromActions & IRecentUpdateLinkPropsFromParent;

const RecentUpdateLinkActions = {
    getFlashReportPDF,
    getMeetingNotePdf,
};

const formatDate = (myDate: Date | null): string => {
    return myDate === null ? "" : formatLongDate(myDate);
};

export const RecentUpdateLinkComponent = (props: RecentUpdateLinkProps) => {
    return (
        <div className="recent-update-row-container">
            <div id={"recent-update-row-" + props.recentUpdate.backstopId} key={props.recentUpdate.backstopId}
                className={`recent-update-row recent-update-row--vertical ${getIsLastClass(props.isLast)}`}>
                <div className="recent-update-row__link">
                    {getTrackedLink(props)}
                </div>
                <div className="recent-update-row__date">
                    <div id={"recent-update-date-" + props.recentUpdate.backstopId}
                         className="recent-update-date date-12">
                        {formatDate(props.recentUpdate.date)}
                    </div>
                </div>
            </div>
            <div className="download-icon fal fa-download" onClick={() => download(props.recentUpdate, props)}/>
        </div>
    );
};

const getTrackedLink = (props: RecentUpdateLinkProps) => {
    const link = isFlashReport(props) ?
        `/${props.recentUpdate.urlPrefix}/${props.recentUpdate.backstopId}` :
        `/managers/${props.managerId}/${props.recentUpdate.urlPrefix}/${props.recentUpdate.backstopId}`;

    return(
        <TrackedLink to={link}
                     className="link-16"
                     gaCategory={getGACategory(props)}
                     gaAction="Link"
                     gaLabel={getGALabel(props)}>
            <div id={"recent-update-title-" + props.recentUpdate.backstopId}>
                {props.recentUpdate.title}</div>
        </TrackedLink>
    );
};

const getIsLastClass = (isLast: boolean): string => {
    return isLast ? "recent-updates-last-row" : "";
};

const isFlashReport = (props: IRecentUpdateLinkPropsFromParent) => {
    return props.recentUpdate instanceof FlashReportSummary;

};
const getGACategory = (props: IRecentUpdateLinkPropsFromParent): string => {
    return isFlashReport(props) ? "Manager Page Flash Report" : "";

};
const getGALabel = (props: IRecentUpdateLinkPropsFromParent): string => {
    return isFlashReport(props) ? `${props.recentUpdate.title} ${props.recentUpdate.backstopId}` : "";

};
const download = (document: MeetingNoteSummary | FlashReportSummary, props: IRecentUpdateLinkPropsFromActions) => {
    if (document instanceof FlashReportSummary) {
        props.actions.getFlashReportPDF (document.backstopId, document.fileName);
    } else {
        props.actions.getMeetingNotePdf(
            document.backstopId,
            document.fileName);
    }
};

export const mapDispatchToProps = (dispatch: Dispatch<AnyAction>): IRecentUpdateLinkPropsFromActions => {
    return {
        actions: bindActionCreators(RecentUpdateLinkActions, dispatch),
    };
};

const RecentUpdateLink =
    connect<void, IRecentUpdateLinkPropsFromActions, IRecentUpdateLinkPropsFromParent, void>
    (null, mapDispatchToProps)(RecentUpdateLinkComponent);

export default RecentUpdateLink;
