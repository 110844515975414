import {CSSProperties} from "react";
import {AonColors} from "../../../../css/Colors";

export const productCardHeaderStyle: CSSProperties = {
    borderRadius: 0,
    boxShadow: "none",
    borderTopWidth: 1,
    borderTopColor: "rgba(0,0,0, 0.12)",
    borderTopStyle: "solid",
    borderLeft: 0,
    borderRight: 0,
    padding: "14.5px 30px",
};

export const productCardChildrenStyle: CSSProperties = {
    padding: "0 15px 30px",
};

export const productExpandableButtonStyle: CSSProperties = {
    borderRadius: "50%",
};

export const defaultCardTitleStyle: CSSProperties = {
    fontFamily: 'Helvetica Now Text", sans-serif',
    fontSize: "24px",
    lineHeight: "32px",
    fontWeight: "bold",
    color: AonColors.AonNavy,
    padding: "21px 16px 21px 31px",
};
