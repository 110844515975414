import * as React from "react";
import {useState} from "react";
import {IOddUploadResult, OddIqApi} from "../../api/OddIqApi";
import {RaisedButton} from "../common/RaisedButton";
import {defaultButtonStyle} from "../common/buttonStyles";
import {throwErrorOnNullOrUndefined} from "../../utils/errorUtil";
import {TimerComponent} from "../common/Timer.component";
import {LoadingSpinner} from "../icons/LoadingSpinner.component";

export const UploadOdd: React.FunctionComponent = () => {
    const [selectedFile, setSelectedFile] = useState<File | undefined>(undefined);
    const [uploadResult, setUploadResult] = useState<IOddUploadResult | undefined>(undefined);
    const [value, setValue] = useState<string>("");
    const [isLoading, setIsLoading] = useState<boolean>(false);


    const renderResult = () => {
        return uploadResult ?
            <div className="upload-holdings-page__result" data-testid="odd__result">
                <div>
                    {uploadResult?.isSuccess ? "Upload succeeded" : "Upload failed"}
                </div>
                <div>
                    {uploadResult?.errors?.map((it: string, idx: number) => {return renderError(it, idx);})}
                </div>
             </div>
            : null;
    };

    const renderError = (error: string, key: number) => {
        return <span key={key}>
                {error}
                <br/>
        </span>;
    };

    const uploadOdd = () => {
        setUploadResult(undefined);
        setIsLoading(true);
        const formData = new FormData();
        formData.append("file", throwErrorOnNullOrUndefined(selectedFile), selectedFile!.name);
        OddIqApi.uploadOdd(formData)
            .then(({data}) => {
                setUploadResult(data);
                setSelectedFile(undefined);
                clearFileInput();
                setIsLoading(false);
            })
            .catch(({error}) => {
                setSelectedFile(undefined);
                clearFileInput();
                setIsLoading(false);
                setUploadResult({isSuccess: false, errors: [`Upload failed: response: ${error.errorNumber} reason: ${error.message}`]});
            });
    };

    const handleFileSelection = (e: { target: { files: FileList | null, value: any } }) => {
        if (e.target.files === null) {
            return;
        }

        setSelectedFile(e.target.files[0]);
        setValue(e.target.value);
    };

    const clearFileInput = () => {
        setValue("");
    };

    return <div>
        <h3>Load ODD data</h3>
        <TimerComponent isRunning={isLoading}/>
        {isLoading ? <LoadingSpinner/> : null}
        <input
            id="file-input"
            onChange={handleFileSelection}
            type="file"
            multiple={false}
            value={value}
            disabled={isLoading}
            data-testid="odd-file-input"
        />

        <RaisedButton style={defaultButtonStyle}
                      primary={false}
                      onClick={() => uploadOdd()}
                      disabled={isLoading || !selectedFile}>
            Upload
        </RaisedButton>
        {renderResult()}
    </div>;

};
