import {Map as ImmutableMap} from "immutable";
import * as React from "react";
import {useEffect, useState} from "react";
import {IPercentAndProductSummary} from "../OddIqUtils";
import {IOddManager} from "../../../api/OddIqApi";
import {OddSummaryByProductTable} from "./OddSummaryByProductTable";
import {
    AssetClassFilter,
    assetClassFilterItems,
} from "../../research/research-filters/FilterAndSort.menuItems";
import {FiltersComponent, IFilter} from "../../common/Filters.component";
import {ISelectValue} from "../../common/Select.component";

export interface IOddSummaryByProductProps {
    productIdToSummaryMap: ImmutableMap<number, IPercentAndProductSummary>;
    hasClientResearchEnabled: boolean;
    oddManagers: IOddManager[];
}

export const OddSummaryByProduct: React.FunctionComponent<IOddSummaryByProductProps> = (props) => {
    const [selectedAssetClassId, setSelectedAssetClassId] = useState<AssetClassFilter>(AssetClassFilter.ALL);
    const [filteredMap, setFilteredMap] = useState(props.productIdToSummaryMap);

    const assetClassFilter: IFilter = {
        values: [
            assetClassFilterItems[0],
            ...getAssetClasses(),
        ],
        selectedValue: selectedAssetClassId,
        code: selectedAssetClassId,
        width: 250,
    };

    function transformAssetClassToSelectValue(assetClass: string): ISelectValue {
        return {
            id: assetClass,
            name: assetClass,
        };
    }

    function getAssetClasses(): ISelectValue[] {
        return props.productIdToSummaryMap.valueSeq()
            .map((e) => e!.productSummary.assetType)
            .toSet()
            .toArray()
            .map(transformAssetClassToSelectValue);
    }

    function byAssetClass(summary: IPercentAndProductSummary) {
        return selectedAssetClassId === AssetClassFilter.ALL
            || summary.productSummary.assetType as string === selectedAssetClassId.toString();
    }

    function handleFilterChange(e: any) {
        setSelectedAssetClassId(e.target.value);
    }

    function handleClearFilters() {
        setSelectedAssetClassId(AssetClassFilter.ALL);
    }

    useEffect(() => {
        setFilteredMap(props.productIdToSummaryMap.filter(byAssetClass).toMap());
    }, [selectedAssetClassId]);

    return <div id="odd-product-summary__container">
        <h3 className="odd-product-summary__table-header">Portfolio Summary</h3>
        <FiltersComponent filters={[assetClassFilter]}
                          onFilterChangeHandler={handleFilterChange}
                          onClearFilters={handleClearFilters} />
        <OddSummaryByProductTable productIdToSummaryMap={filteredMap}
                                  hasClientResearchEnabled={props.hasClientResearchEnabled}
                                  oddManagers={props.oddManagers} />
    </div>;
};
