import * as React from "react";
import {FlashReportSummary} from "../../../model/FlashReportSummary.model";
import {TrackedLink} from "../../base/TrackedLink.component";
import {formatShortDate} from "../../../utils/dateUtil";

export interface IFlashReportListingProps {
    flashReport: FlashReportSummary;
}

export const FlashReportListing: React.FunctionComponent<IFlashReportListingProps> = (props) => {
    return <div className="__flash-report" key={`flash-report-${props.flashReport.backstopId}`}
                data-testid="flash-report">
            <div className="icon flash-report__icon fa-light fa-bell" />
            <div>
                <div>
                    <TrackedLink to={`/flash-reports/${props.flashReport.backstopId}`}
                                 className="link-14"
                                 gaCategory=""
                                 gaAction="Link"
                                 gaLabel="">
                        {props.flashReport.title}
                    </TrackedLink>
                </div>
                <div className="flash-report__date date-12">{formatShortDate(props.flashReport.date)}</div>
            </div>
        </div>;
};