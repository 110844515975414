export class Ticker {
    public static builder(): TickerBuilder {
        return new TickerBuilder();
    }

    constructor(public name: string, public shareClass: string) {
    }
}

class TickerBuilder {
    private mName: string;
    private mShareClass: string;

    public name(value: string) {
        this.mName = value;
        return this;
    }

    public shareClass(value: string) {
        this.mShareClass = value;
        return this;
    }

    public build() {
        return new Ticker(
            this.mName || "",
            this.mShareClass || "",
        );
    }
}
