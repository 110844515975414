const mime = require("mime-types");

export const getMimeType = (name: string): string => {
    return mime.lookup(name.trim()) || "application/octet-stream";
};

export const getFileExtension = (fileName: string) => {
    return fileName.split(".").pop()!;
};

export const renameWordWithPdf = (fileName: string) => {
    return isWordDoc(fileName)
        ? replaceWordWithPdf(fileName)
        : fileName;
};

const isWordDoc = (fileName: string) => {
    const extension = getFileExtension(fileName).toLowerCase();
    return extension === "doc" || extension === "docx";
};

const replaceWordWithPdf = (fileName: string) => {
    const extension = getFileExtension(fileName);
    return fileName.replace(`.${extension}`, ".pdf");
};
