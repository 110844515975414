export interface IInReportGroup {
    inBriefSummary: InReportSummary | null,
    inTotalSummary: InReportSummary | null,
    inFormSummary: InReportSummary | null
}

export class InReportSummary {

    public static builder() {
        return new InReportSummaryBuilder();
    }

    constructor(
        public backstopId: number,
        public name: string,
        public effectiveDate: Date,
        public filename: string,
    ) {
    }
}

class InReportSummaryBuilder {
    private mBackstopId: number;
    private mName: string;
    private mEffectiveDate: Date;
    private mFilename: string;

    public backstopId(value: number) {
        this.mBackstopId = value;
        return this;
    }

    public name(value: string) {
        this.mName = value;
        return this;
    }

    public effectiveDate(value: Date) {
        this.mEffectiveDate = value;
        return this;
    }

    public filename(value: string) {
        this.mFilename = value;
        return this;
    }

    public build() {
        return new InReportSummary(
            this.mBackstopId || 0,
            this.mName || "name",
            this.mEffectiveDate || new Date(0),
            this.mFilename || "filename.xxx",
        );
    }

}
