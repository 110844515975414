import {Action, Dispatch} from "redux";
import {OddIqApi} from "../../api/OddIqApi";
import {OperationalDueDiligenceApi} from "../../api/OperationalDueDiligenceApi";

export enum OddIqActions {
    OPERATIONAL_DUE_DILIGENCE_REVIEW_REQUESTED = "OPERATIONAL_DUE_DILIGENCE_REVIEW_REQUESTED",
    OPERATIONAL_DUE_DILIGENCE_REVIEW_SUCCEEDED = "OPERATIONAL_DUE_DILIGENCE_REVIEW_SUCCEEDED",
    OPERATIONAL_DUE_DILIGENCE_REVIEW_FAILED = "OPERATIONAL_DUE_DILIGENCE_REVIEW_FAILED",
    CLEAR_OPERATIONAL_DUE_DILIGENCE_REVIEW = "CLEAR_OPERATIONAL_DUE_DILIGENCE_REVIEW",

    ODD_RESPONSES_BY_BACKSTOP_REQUESTED = "ODD_RESPONSES_BY_BACKSTOP_REQUESTED",
    ODD_RESPONSES_BY_BACKSTOP_SUCCEEDED = "ODD_RESPONSES_BY_BACKSTOP_SUCCEEDED",
    ODD_RESPONSES_BY_BACKSTOP_FAILED = "ODD_RESPONSES_BY_BACKSTOP_FAILED",

    ODD_SECTIONS_AND_QUESTIONS_REQUESTED = "ODD_SECTIONS_AND_QUESTIONS_REQUESTED",
    ODD_SECTIONS_AND_QUESTIONS_SUCCEEDED = "ODD_SECTIONS_AND_QUESTIONS_SUCCEEDED",
    ODD_SECTIONS_AND_QUESTIONS_FAILED = "ODD_SECTIONS_AND_QUESTIONS_FAILED",
    ODD_PRODUCT_DATE_RESPONDED_REQUESTED = "ODD_PRODUCT_DATE_RESPONDED_REQUESTED",
    ODD_PRODUCT_DATE_RESPONDED_SUCCEEDED = "ODD_PRODUCT_DATE_RESPONDED_SUCCEEDED",
    ODD_PRODUCT_DATE_RESPONDED_FAILED = "ODD_PRODUCT_DATE_RESPONDED_FAILED",
    ODD_SET_CURRENT_QUESTION_ID = "ODD_SET_CURRENT_QUESTION_ID",
    ODD_SET_PORTFOLIO_VIEW = "ODD_SET_PORTFOLIO_VIEW",
    ODD_DOCUMENTS_REQUESTED = "ODD_DOCUMENTS_REQUESTED",
    ODD_DOCUMENTS_SUCCEEDED = "ODD_DOCUMENTS_SUCCEEDED",
    ODD_DOCUMENTS_FAILED = "ODD_DOCUMENTS_FAILED",
    ODD_MANAGERS_REQUESTED = "ODD_MANAGERS_REQUESTED",
    ODD_MANAGERS_SUCCEEDED = "ODD_MANAGERS_SUCCEEDED",
    ODD_MANAGERS_FAILED = "ODD_MANAGERS_FAILED",
    ODD_PRODUCTS_ACCESS_REQUESTED = "ODD_PRODUCTS_ACCESS_REQUESTED",
    ODD_PRODUCTS_ACCESS_SUCCEEDED = "ODD_PRODUCTS_ACCESS_SUCCEEDED",
    ODD_PRODUCTS_ACCESS_FAILED = "ODD_PRODUCTS_ACCESS_FAILED",
}

export const clearOddReviewData = () =>
    (dispatch: Dispatch<Action<any>>) => {
        dispatch({
            type: OddIqActions.CLEAR_OPERATIONAL_DUE_DILIGENCE_REVIEW,
        });
    };

export const requestOddResponsesByBackstopForCurrentPlan = (dispatch: Dispatch<Action<OddIqActions>>) => {
    dispatch({
        type: OddIqActions.ODD_RESPONSES_BY_BACKSTOP_REQUESTED,
    });
    OddIqApi.requestOddResponsesByBackstopForCurrentPlan()
        .then((result) => {
            dispatch({
                type: OddIqActions.ODD_RESPONSES_BY_BACKSTOP_SUCCEEDED,
                payload: result,
            });
        }, (error) => {
            dispatch({
                type: OddIqActions.ODD_RESPONSES_BY_BACKSTOP_FAILED,
                payload: error,
            });
        });
};

export const requestOddSectionsAndQuestions = (dispatch: Dispatch<Action<OddIqActions>>) => {
    dispatch({
        type: OddIqActions.ODD_SECTIONS_AND_QUESTIONS_REQUESTED,
    });
    OddIqApi.requestOddSectionsAndQuestions()
        .then((result) => {
            dispatch({
                type: OddIqActions.ODD_SECTIONS_AND_QUESTIONS_SUCCEEDED,
                payload: result,
            });
        }, (error) => {
            dispatch({
                type: OddIqActions.ODD_SECTIONS_AND_QUESTIONS_FAILED,
                payload: error,
            });
        });
};

export const requestOddProductDateRespondedForCurrentPlan = (dispatch: Dispatch<Action<OddIqActions>>) => {
    dispatch({
        type: OddIqActions.ODD_PRODUCT_DATE_RESPONDED_REQUESTED,
    });
    OddIqApi.requestOddProductDateRespondedForCurrentPlan()
        .then((result) => {
            dispatch({
                type: OddIqActions.ODD_PRODUCT_DATE_RESPONDED_SUCCEEDED,
                payload: result,
            });
        }, (error) => {
            dispatch({
                type: OddIqActions.ODD_PRODUCT_DATE_RESPONDED_FAILED,
                payload: error,
            });
        });
};

export const setCurrentOddQuestionId = (questionId?: number) => {
    return (dispatch: Dispatch) => {
        dispatch({
            type: OddIqActions.ODD_SET_CURRENT_QUESTION_ID,
            payload: questionId,
        });
    };
};

export const setPortfolioView = (portfolioAccess: boolean) => {
    return (dispatch: Dispatch) => {
        dispatch({
            type: OddIqActions.ODD_SET_PORTFOLIO_VIEW,
            payload: portfolioAccess,
        });
    };
};

export const requestOddDocuments = (dispatch: Dispatch<Action<OddIqActions>>) => {
    dispatch({
        type: OddIqActions.ODD_DOCUMENTS_REQUESTED,
    });
    OperationalDueDiligenceApi.requestAllOddDocuments()
        .then((result) => {
            dispatch({
                type: OddIqActions.ODD_DOCUMENTS_SUCCEEDED,
                payload: result,
            });
        }, (error) => {
            dispatch({
                type: OddIqActions.ODD_DOCUMENTS_FAILED,
                payload: error,
            });
        });
};

export const requestOddManagers = (dispatch: Dispatch<Action<OddIqActions>>) => {
    dispatch({
        type: OddIqActions.ODD_MANAGERS_REQUESTED,
    });
    OddIqApi.requestOddManagers()
        .then((result) => {
            dispatch({
                type: OddIqActions.ODD_MANAGERS_SUCCEEDED,
                payload: result,
            });
        }, (error) => {
            dispatch({
                type: OddIqActions.ODD_MANAGERS_FAILED,
                payload: error,
            });
        });
};

export const requestOddProducts = (dispatch: Dispatch<Action<OddIqActions>>) => {
    dispatch({
        type: OddIqActions.ODD_PRODUCTS_ACCESS_REQUESTED,
    });
    OddIqApi.requestOddProductsAccess()
        .then((result) => {
            dispatch({
                type: OddIqActions.ODD_PRODUCTS_ACCESS_SUCCEEDED,
                payload: result,
            });
        }, (error) => {
            dispatch({
                type: OddIqActions.ODD_PRODUCTS_ACCESS_FAILED,
                payload: error,
            });
        });
};
