import {AxiosResponse} from "axios";
import {ApiBase} from "./ApiBase";
import {formatShortDate, parseDate, parseShortDate} from "../utils/dateUtil";
import {IApiResult} from "./AxiosWealthManagementApi";
import {ClientReport} from "../model/ClientReport.model";
import {getMimeType} from "../utils/fileUtil";
import {saveAs} from "file-saver";
import {InViewReportsApi} from "./InViewReportsApi";
import {IReportBookmark} from "powerbi-models";
import {planName} from "../utils/planUtil";
import {now} from "../utils/dateProvider";

export interface IReportConfig {
    token: string;
    embedUrl: string;
    reportId: string;
    allowExport: boolean;
}

export const buildIReportConfigForTest = () => {
    return {
        token: "token",
        embedUrl: "embedUrl",
        reportId: "reportId",
        allowExport: true,
    } as IReportConfig;
};

export enum ExportStatus {
    RUNNING = "Running",
    SUCCEEDED = "Succeeded",
    TIMED_OUT = "Timed Out",
    DOWNLOADING = "Downloading",
    FAILED = "Failed",
}

export interface IReportExportInfo {
    exportId: string;
    reportId: string;
    status: string;
    clientReportId?: number;
    clientReportName?: string;
}

export interface IClientReportCategory {
    id: number;
    name: string;
    workspaces: IReportWorkspace[]
}

export interface IReportWorkspace {
    id: string,
    name: string
}

export class PowerBiReportingApi {
    public static requestPerformanceReportConfig(): Promise<IReportConfig> {
        return ApiBase.processGetUnwrapped("/performance-reporting",
            (response: AxiosResponse): any => {
                return response.data;
            });
    }

    public static requestOddReportConfig(): Promise<IReportConfig> {
        return ApiBase.processGetUnwrapped("/odd-reporting",
            (response: AxiosResponse): any => {
                return response.data;
            });
    }

    public static requestClientReportsForCurrentPlan(): Promise<ClientReport[]> {
        return ApiBase.processGetUnwrapped("/plans/current/client-reports",
            (response: AxiosResponse): any => {
                return response.data.map(this.mapClientReport);
            });
    }

    public static requestAllClientReports(): Promise<ClientReport[]> {
        return ApiBase.processGetUnwrapped("/client-reports",
            (response: AxiosResponse): any => {
                return response.data.map(this.mapClientReport);
            });
    }

    private static mapClientReport(it: any): ClientReport {
        return {
            id: it.id,
            reportName: it.reportName,
            workspaceId: it.workspaceId,
            categoryName: it.categoryName,
            categoryId: it.categoryId,
            categorySequence: it.categorySequence,
            clientEnabled: it.clientEnabled,
            date: parseDate(it.date),
            documentTitle: it.documentTitle,
            documentId: it.documentId,
            documentFileName: it.documentFileName,
            planName: it.planName,
            reportId: it.reportId
        };
    }

    public static addClientReportToPlan(planId: number, formData: FormData): Promise<IApiResult<boolean>> {
        return ApiBase.processPost(`/plans/${planId}/client-report`, formData);
    }

    public static requestClientReportConfig(reportId: number): Promise<IReportConfig> {
        return ApiBase.processGetUnwrapped(`/client-reports/${reportId}/config`,
            (response: AxiosResponse): any => {
                return response.data;
            });
    }

    public static setReportClientViewable(reportId: number, isClientViewable: boolean): Promise<IApiResult<boolean>> {
        const requestBody = {
            clientViewable: isClientViewable,
        };

        return ApiBase.processPost(`/client-reports/${reportId}/edit`, requestBody);
    }

    public static deleteClientReport(reportId: number): Promise<IApiResult<boolean>> {
        return ApiBase.processDelete(`/client-reports/${reportId}`);
    }

    public static deleteClientReportForCurrentPlan(reportId: number): Promise<IApiResult<boolean>> {
        return ApiBase.processDelete(`/plan/current/client-reports/${reportId}`);
    }

    public static requestPerformanceReportExport(bookmark: IReportBookmark):
        Promise<IApiResult<IReportExportInfo>> {
        return ApiBase.processPostWithResponseBody(
            "/performance-reporting/export",
            bookmark,
            (response: AxiosResponse) => {
                return response.data as IReportExportInfo;
            });
    }

    public static requestClientReportExport(reportId: number, bookmark: IReportBookmark):
        Promise<IApiResult<IReportExportInfo>> {
        return ApiBase.processPostWithResponseBody(
            `/client-reports/${reportId}/export`,
            bookmark,
            (response: AxiosResponse) => {
                return response.data as IReportExportInfo;
            });
    }

    public static getPerformanceReportExportStatus(exportInfo: IReportExportInfo):
        Promise<IApiResult<IReportExportInfo>> {
           return ApiBase.processGet(
            `/performance-reports/${exportInfo.reportId}/export/${exportInfo.exportId}/status`,
               (response: AxiosResponse) => {
                return response.data as IReportExportInfo;
               });
    }

    public static getClientReportExportStatus(exportInfo: IReportExportInfo):
        Promise<IApiResult<IReportExportInfo>> {
        return ApiBase.processGet(
            `/client-reports/${exportInfo.clientReportId}/${exportInfo.reportId}/export/${exportInfo.exportId}/status`,
            (response: AxiosResponse) => {
                return response.data as IReportExportInfo;
            });
    }

    public static downloadPerformanceReportExport(exportInfo: IReportExportInfo, exportPeriod: number)
        : Promise<IApiResult<void>> {
        const date = formatShortDate(now());
        const fileName = `${planName()} Investment Monitoring Report - ${date}.pdf`;
        const quarter = exportPeriod.toString().substring(0, 1);
        const year = exportPeriod.toString().substring(1);
        return ApiBase.processGet(
            `/performance-reports/${exportInfo.reportId}/export/${exportInfo.exportId}/download/${quarter}/${year}`,
            (response: AxiosResponse) => {
                const blob = new Blob([response.data], {type: getMimeType(fileName)});
                saveAs(blob, fileName);
            },
            {responseType: "arraybuffer"}
        );
    }

    public static downloadClientReportExport(exportInfo: IReportExportInfo): Promise<IApiResult<void>> {
        const fileName = `${exportInfo.clientReportName}.pdf`;
        return ApiBase.processGet(
            `/client-reports/${exportInfo.clientReportId}/${exportInfo.reportId}/export/${exportInfo.exportId}/download`,
            (response: AxiosResponse) => {
                const blob = new Blob([response.data], {type: getMimeType(fileName)});
                saveAs(blob, fileName);
            },
            {responseType: "arraybuffer"}
        );
    }

    public static downloadLatestPowerBiFaq(): Promise<number> {
       return ApiBase.processGetUnwrapped(
            "/powerbi-faqs/latest",
            (response: AxiosResponse): any => {
                InViewReportsApi.downloadReport(response.data.backstopId, response.data.fileName, false);
                return response.data.backstopId;
            });
    }

    public static requestClientReportCategories(): Promise<IClientReportCategory[]> {
        return ApiBase.processGetUnwrapped("/client-reports/categories",
            (response: AxiosResponse): any => {
                return response.data;
            });
    }
}
