import {Action, handleActions} from "redux-actions";
import {IPlanInfo} from "../../model/ClientsAndPlans.model";
import {UserDetails} from "../../model/UserDetails.model";
import {SessionActions} from "./SessionActions";
import {SessionData} from "./SessionData.model";
import {UserTypeEnum} from "../../model/UserInfo.model";
import {orderPlans} from "../../utils/sessionUtil";

export interface ISessionState {
    username: string;
    firstName: string;
    currentPlan: IPlanInfo | undefined;
    plans: IPlanInfo[];
    userType: UserTypeEnum;
}

export const sessionInitialState: ISessionState = {
    username: "",
    firstName: "",
    currentPlan: undefined,
    plans: [],
    userType: UserTypeEnum.UNKNOWN,
};

type PayloadTypes = SessionData | UserDetails | IPlanInfo | IPlanInfo[];

const reducerMap = {
    [SessionActions.SET_USER_DETAILS]:
        (state: ISessionState, a: Action<PayloadTypes>): ISessionState => {

            const action = a as Action<UserDetails>;
            return {
                ...state,
                firstName: action.payload!.firstName,
                username: action.payload!.username,
                userType: action.payload!.userType,
            };
        },
    [SessionActions.SET_CURRENT_PLAN_SESSION]:
        (state: ISessionState, a: Action<PayloadTypes>): ISessionState => {

            const action = a as Action<IPlanInfo | undefined>;
            return {
                ...state,
                currentPlan: action.payload,
            };
        },
    [SessionActions.SET_PLAN_LIST]:
        (state: ISessionState, action: Action<PayloadTypes>): ISessionState => {
            const payload = (action as Action<IPlanInfo[]>).payload;
            if (!payload) { return state; }

            if (!currentPlanInNewPlans(payload, state)) {

                return {
                    ...state,
                    currentPlan: selectCurrentPlan(payload),
                    plans: payload,
                };
            } else {
                return {
                    ...state,
                    plans: payload,
                };
            }
        },
};

// region Session Selectors
export function hasPlansInner(state: ISessionState | undefined): boolean {
    return !!state && state.plans.length > 0;
}
// endregion Session Selectors

// region
export function currentPlanInNewPlans(newPlans: IPlanInfo[], state: ISessionState) {
    const isCurrentPlanEmpty = state.currentPlan === undefined;
    const anyMatchingPlans = isCurrentPlanEmpty ? false : newPlans.some((plan) => plan.id === state.currentPlan!.id);
    return anyMatchingPlans && !isCurrentPlanEmpty;
}

export function selectCurrentPlan(payload: IPlanInfo[]) {
    return orderPlans(payload)[0];
}
// endregion

export const currentPlanInner = (state: ISessionState | undefined): IPlanInfo => {
    return state!.currentPlan!;
};

export const currentUserEmailInner = (state: ISessionState | undefined): string => {
    return state!.username;
};

export const sessionPlansInner = (state: ISessionState | undefined): IPlanInfo[] => {
    return state!.plans;
};

export const userTypeInner = (state: ISessionState | undefined): UserTypeEnum => {
    return state!.userType;
};

export default handleActions<ISessionState, PayloadTypes>(reducerMap, sessionInitialState);
