import * as React from "react";
import {useState} from "react";
import {connect} from "react-redux";
import {navigateTo} from "../../../navigateTo";
import {prominentButtonStyle} from "../common/buttonStyles";
import {RaisedButton} from "../common/RaisedButton";
import {QuarterFilterItems} from "../../utils/dateUtil";
import {hasConsultantLevelAccess, isAdminUser, isAtcAdminUser, isThirdPartyUser} from "../../utils/sessionUtil";
import {ISharedDocumentsPropsFromActions, ISharedDocumentsPropsFromState} from "./SharedDocuments.reducer";
import {SharedDocumentsFilters} from "./SharedDocumentsFilters.component";
import {FormattedMessage} from "react-intl";
import {
    clientUploadsEnabled,
    currentUserEmail, hasAonTrustCompany,
    sharedDocumentsEnabled,
    sharedDocumentsSelector
} from "../../../mainReducerMapSelectors";
import {getSuccessData} from "../common/commonStates";
import {multipleApiRequesterWrapper} from "../common/MultipleApiRequesterWrapper";
import {sharedDocumentsPair} from "../common/RequesterPairs";
import {ErrorComponent} from "../base/Error.component";
import SharedDocumentsFileList from "./SharedDocumentsFileList";
import {SortOptions} from "../../utils/sharedDocsUtil";
import {ATC_DISCLAIMER_P1, ATC_DISCLAIMER_P2} from "../home-page/Home.component";
import {IApplicationRootState} from "../../../applicationState";

interface ISharedDocumentsRouteParams {
    match: {
        params: {
            initialDocumentTypeId?: string; // React Router passes this in as a string regardless of type specified
        }
    }
}

interface ISharedDocumentsPagePropsFromParent {
    sharedDocumentsEnabled: boolean;
    aonTrustCompany: boolean;
}

export type ISharedDocumentsProps = ISharedDocumentsPagePropsFromParent
    & ISharedDocumentsRouteParams
    & ISharedDocumentsPropsFromState;

export interface IFilterActions {
    handleDocumentTypeChange: any;
    handleYearChange: any;
    handleQuarterChange: any;
    handleSortingChange: any;
}

export const ALL_DOCUMENT_TYPES = 0;
export const ALL_YEARS = 0;

export const ACIT_TITLE = "ACIT Shared Documents";
export const TITLE = "Shared Documents";

export const SharedDocumentsPage: React.FunctionComponent<ISharedDocumentsProps> = (props) => {

    const [selectedYear, setSelectedYear] = useState<number>(ALL_YEARS);
    const [selectedQuarter, setSelectedQuarter] = useState<QuarterFilterItems>(QuarterFilterItems.ALL);
    const [selectedOrder, setSelectedOrder] = useState<SortOptions>(SortOptions.TIME_PERIOD);
    const [selectedDocumentType, setSelectedDocumentType] = useState<number>(props.match.params.initialDocumentTypeId
        ? parseInt(props.match.params.initialDocumentTypeId, 10)
        : ALL_DOCUMENT_TYPES);

    function hasEditAccess() {
        return props.aonTrustCompany
            ? (isAtcAdminUser() || isAdminUser())
            : hasConsultantLevelAccess()
                ? true
                : !isAtcAdminUser() && !isThirdPartyUser() && props.clientUploadsEnabled;
    }

    function getAddFileLink() {
        return hasEditAccess()
            ? <RaisedButton
                className="upload-file-button"
                style={prominentButtonStyle}
                primary={true}
                onClick={() => navigateTo("/shared-documents/upload-files")}>
                <FormattedMessage
                    id="shared-docs.upload-files"
                    defaultMessage="Upload Files"
                />
            </RaisedButton>
            : null;
    }

    function clearSharedDocumentsFilters() {
        setSelectedDocumentType(ALL_DOCUMENT_TYPES);
        setSelectedYear(ALL_YEARS);
        setSelectedQuarter(QuarterFilterItems.ALL);
    }

    function handleDocumentTypeChange(event: object, index: number, documentType: number) {
        setSelectedDocumentType(documentType);
    }

    function handleYearChange(event: object, index: number, year: number) {
        setSelectedYear(year);
    }

    function handleQuarterChange(event: object, index: number, quarter: QuarterFilterItems) {
        setSelectedQuarter(quarter);
    }

    function handleSortingChange(event: object, index: number, order: SortOptions) {
        setSelectedOrder(order);
    }

    const renderDisclaimer = () => {
        return props.aonTrustCompany
            ? <div className="shared-documents__atc-disclaimer">
                {ATC_DISCLAIMER_P1}
                <p>{ATC_DISCLAIMER_P2}</p>
            </div>
            : null;
    };

    const filterActions: IFilterActions = {
        handleDocumentTypeChange,
        handleYearChange,
        handleQuarterChange,
        handleSortingChange,
    };

    const filterSelections = {
        selectedDocumentType,
        selectedYear,
        selectedQuarter,
        selectedOrder,
    };

    return props.sharedDocumentsEnabled ?
        <div>
            <div id="container" className="main-content shared-documents__main-content new-common-styles">
                <div className="shared-documents__header-with-button">
                    <h1>
                        <FormattedMessage
                            id="shared-docs.header"
                            defaultMessage={props.aonTrustCompany ? ACIT_TITLE : TITLE}
                        />
                    </h1>
                    {getAddFileLink()}
                </div>
                <div className="shared-documents-main">
                    <SharedDocumentsFilters
                        documents={getSuccessData(props.documents)!}
                        filterActions={filterActions}
                        filterSelections={filterSelections}
                    />
                    <div className="shared-documents-table-container">
                        <SharedDocumentsFileList documents={getSuccessData(props.documents)!}
                                                 currentUserEmail={props.currentUserEmail}
                                                 selectedOrder={selectedOrder}
                                                 selectedYear={selectedYear}
                                                 selectedQuarter={selectedQuarter}
                                                 selectedDocumentType={selectedDocumentType}
                                                 aonTrustCompany={props.aonTrustCompany}
                                                 handleClearFilters={clearSharedDocumentsFilters} />
                    </div>
                </div>
                {renderDisclaimer()}
            </div>
        </div>
    : <ErrorComponent />;
};

export const mapStateToProps = (state: IApplicationRootState): ISharedDocumentsPropsFromState & ISharedDocumentsPagePropsFromParent => {
    return {
        ...state.sharedDocumentsRootState!,
        clientUploadsEnabled: clientUploadsEnabled(state),
        currentUserEmail: currentUserEmail(state),
        documents: sharedDocumentsSelector(state),
        sharedDocumentsEnabled: sharedDocumentsEnabled(state),
        aonTrustCompany: hasAonTrustCompany(state)
    };
};

const connectedComponent = connect<ISharedDocumentsPropsFromState,
    ISharedDocumentsPropsFromActions,
    ISharedDocumentsPagePropsFromParent>(mapStateToProps)(SharedDocumentsPage);

export default multipleApiRequesterWrapper(
    connectedComponent, [
        sharedDocumentsPair,
    ]
);
