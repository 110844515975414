import * as React from "react";
import {Link} from "react-router-dom";
import {ProductRatingType} from "../../../model/product/ProductRatingType";
import {viewOddDetailsButtonStyle} from "../../common/buttonStyles";
import {RaisedButton} from "../../common/RaisedButton";
import {getRatingTextClass} from "../../../utils/commonStyles";
import {isNullOrUndefined} from "../../../utils/commonUtil";
import {formatShortDate} from "../../../utils/dateUtil";
import {verbosePreviousRatingMessage} from "../../../utils/ratingFormatUtil";
import {NOT_RATED, NR_RATING, OddRatingDescriptions} from "../../../utils/ratingConstants";
import {FormattedMessage} from "react-intl";
import {getTranslation} from "../../../utils/translationUtil";
import {ITooltipProps, Tooltip} from "../../common/Tooltip";
import {IInformSummary} from "../../../model/inform/InformSummary";
import {Disclosures} from "./Disclosures";

export interface IOverallRatingProps {
    currentRating: string | null;
    currentRatingDate: string | null;
    previousRating: string | null;
    previousRatingDate?: string | null;
    isSmallText?: boolean;
    ratingType: ProductRatingType;
    isOperationalDueDiligenceRating: boolean;
    hasOddDetails: boolean;
    productBackstopId: number;
    showTooltip: boolean;
    oddIqEnabled: boolean;
    summary?: IInformSummary;
}

export class RatingStatus extends React.Component<IOverallRatingProps> {
    public render() {
        const {currentRating, previousRating, isSmallText} = this.props;
        const previousRatingMessage = this.props.currentRating === NOT_RATED
            ? ""
            :verbosePreviousRatingMessage(currentRating, previousRating);
        const currentRatingTextClass = getRatingTextClass(currentRating || "", this.ratingTextSize());
        const ratingChangeMessageClass = `previous-rating rating-change-${this.ratingTextSize()}`;

        return (
            <div className="brackets__box brackets__box__with-divider first odd-document-body"
                 data-testid="rating-status">
                <div className="rating__header-row">
                    <div className={isSmallText ? "" : `rating-status__container`}>
                        {this.ratingPrefix()}
                        <span className={currentRatingTextClass} data-testid="current-rating">
                            {this.getRatingText(this.props.currentRating)}
                        </span>
                        {this.props.currentRating === NOT_RATED
                            ? null
                            : <span className={ratingChangeMessageClass}>{`(${previousRatingMessage})`}</span>
                        }
                        {this.oddRatingDescriptionTooltip()}
                        <div className="rating__spacer"/>
                        {this.oddDetailsButton(this.props.productBackstopId)}
                        {this.props.summary && <Disclosures summary={this.props.summary}/>}
                    </div>
                </div>
                {this.renderReviewDate()}
            </div>);
    }

    private getRatingText(currentRating: string | null) {
        if (!currentRating) {
            return null;
        }

        const rating = this.props.currentRating === NR_RATING
            ? "N/R"
            : this.props.currentRating;

        return <FormattedMessage
            id={`research-product-rating.${rating}`}
            defaultMessage={rating!}
        />;
    }

    private ratingTextSize() {
        return this.props.isSmallText ? "small" : "big";
    }

    private ratingPrefix() {
        if (this.props.isOperationalDueDiligenceRating
            || this.props.ratingType !== ProductRatingType.INFORM
            || this.props.currentRating === NOT_RATED) {
            return null;
        }

        const grayRatingClass = getRatingTextClass(NR_RATING, this.ratingTextSize());
        return <span className={`rating-prefix ${grayRatingClass}`}>
             <FormattedMessage
                 id="research-product.inform-rating"
                 defaultMessage="InForm Rating"
             />:
        </span>;
    }

    private oddRatingDescriptionTooltip() {
        if (!this.props.isOperationalDueDiligenceRating || !this.props.showTooltip) {
            return null;
        }

        const tooltipProps: ITooltipProps = {
            iconClass: "fal fa-info-circle clickable rating__tooltip",
            hoverText: [OddRatingDescriptions(this.props.currentRating!)!],
            hoverTitle: this.props.currentRating ? this.props.currentRating : "",
        };

        return <Tooltip {...tooltipProps}/>;
    }

    private oddDetailsButton(productBackstopId: number) {
        if (!this.props.hasOddDetails || !this.props.oddIqEnabled) {
            return null;
        }

        return <Link className="rating__odd-details-link" to={`/odd/${productBackstopId}`}>
            <RaisedButton
            className="rating__odd-details-button"
            style={viewOddDetailsButtonStyle}
            primary={true}>
                <FormattedMessage id="product.view-details" defaultMessage="View Details" />
            </RaisedButton>
        </Link>;
    }

    private renderReviewDate() {
        return this.props.currentRating === NOT_RATED || this.props.currentRating === NR_RATING
        ? null
        : <div className="rating__review-date">
                <p className="small" data-testid="small">
                    {this.getDateText(this.props.currentRatingDate, this.props.previousRatingDate)}
                </p>
            </div>;
    }

    private getDateText(currentRatingDate: string | null, previousRatingDate?: string | null) {
        if (this.props.ratingType === ProductRatingType.FUNDAMENTAL || this.props.isOperationalDueDiligenceRating) {
            const ratedOn = getTranslation("research-product.rated-on", "Rated on");
            const lastRated = getTranslation("research-product.last-rated", "Last rated");
            const currentRatingDateText = `${ratedOn} ${formatShortDate(currentRatingDate!)}`;
            const previousRatingDateText = isNullOrUndefined(previousRatingDate) ? "" :
                ` / ${lastRated} ${formatShortDate(previousRatingDate)}`;
            return currentRatingDateText + previousRatingDateText;
        } else {
            return `${getTranslation(
                "product.aon-inform-rating-as-of", 
                "Aon InForm Assessment rating as of")} ${formatShortDate(currentRatingDate!)}`;
        }
    }
}

// export default RatingStatus;