import * as React from "react";
import {CSSProperties} from "react";
import Select, {
    components,
    createFilter,
    CSSObjectWithLabel,
    MenuListProps,
    OptionProps,
    StylesConfig
} from "react-select";
import {FixedSizeList} from "react-window";
import {AonColors} from "../../../../css/Colors";

export interface ISelectComponentReactProps<A> {
    id?: string;
    className?: string;
    classNamePrefix?: string;
    options: A[];
    menuIsOpen?: boolean;
    value: A | A[] | null;
    isMulti?: boolean;
    onChange: (option: A | A[], action?: any) => void;
    submitClicked: boolean;
    renderRequired?: boolean;
    getOptionLabel: (option: A) => string;
    getOptionValue: (option: A) => string;
    stylesObject?: IDropdownStyles;
    placeholder?: string;
    closeMenuOnSelect?: boolean;
    itemHeight?: number;
}

export function SelectComponentReact<A>(props: ISelectComponentReactProps<A>)
    : React.ReactElement<ISelectComponentReactProps<A>> {
    const errorState = !props.value && props.submitClicked;
    const renderRequired = !(props.renderRequired === false);

    const stylesConfig = createStylesConfig(props.stylesObject || {}, errorState);

    const MenuList: React.FunctionComponent<MenuListProps<any>> = (menuListProps) => {
        const {children, maxHeight} = menuListProps;
        const childrenArray = React.Children.toArray(children);

        return (
            <FixedSizeList
                height={maxHeight}
                itemCount={childrenArray.length}
                itemSize={props.itemHeight ? props.itemHeight : 40}
                width={"100%"}
            >
                {({index, style}) => <div style={style}>{childrenArray[index]}</div>}
            </FixedSizeList>
        );
    };

    return <div data-testid={props.id}>
        <Select
            {...props}
            styles={stylesConfig}
            components={{DropdownIndicator, MenuList}}
            filterOption={
                createFilter({
                    ignoreAccents: false,
                    stringify: (option) => option.label,
                })}
            classNamePrefix="react-select"
            aria-label={props.className}
        />
        {renderRequiredCaption(renderRequired, errorState)}
    </div>;
}

const renderRequiredCaption =
    (renderRequired: boolean, errorState: boolean) => {

        const className = errorState
            ? "select-field__required-caption-red"
            : "select-field__required-caption";

        return renderRequired
            ? <span className={className}>required</span>
            : null;
    };

const DropdownIndicator = (props: any) => {
    return components.DropdownIndicator && (
        <components.DropdownIndicator {...props}>
            <svg fill={AonColors.AonTealDark}
                 height="24"
                 viewBox="0 0 24 24"
                 width="24"
                 xmlns="http://www.w3.org/2000/svg">
                <path d="M7 10l5 5 5-5z"/>
            </svg>
        </components.DropdownIndicator>
    );
};

const activeStyles = {
    activeClicked: {
        "&:active": {
            backgroundColor: "gray",
        },
    },
    underlineHoverProperties: {
        "&:hover": {
            borderColor: AonColors.AonTealDark,
        },
    },
};

interface IDropdownStyles {
    width?: number | string;
    borderBottomColor?: string;
    hoverBorderColor?: string;
    placeHolderStyle?: CSSProperties;
}

export const defaultStyles = {
    width: 300 as number | undefined,
    borderBottomColor: AonColors.AonGray01,
    hoverBorderColor: AonColors.AonTealDark,
    placeHolderStyle: {
        color: AonColors.AonGray01,
    },
};

export const createStylesConfig = (styles: IDropdownStyles | {}, errorState: boolean): StylesConfig => {

    const myStyles: IDropdownStyles = {...defaultStyles, ...styles};

    return {
        control: (baseStyles: CSSObjectWithLabel) => {
            // styles applied to closed select box
            return {
                ...baseStyles,
                "&:hover": {
                    borderBottom: "solid 2px",
                    borderColor: myStyles.hoverBorderColor,
                },
                boxShadow: "none",
                border: 0,
                borderBottom: "solid 1px",
                borderBottomColor: errorState ? "#d0011b" : myStyles.borderBottomColor,
                borderRadius: 0,
                width: myStyles.width,
            };
        },
        valueContainer: (baseStyles: CSSObjectWithLabel) => {
            // container for single value
            return {
                ...baseStyles,
                paddingLeft: 0,
            };
        },
        singleValue: (baseStyles: CSSObjectWithLabel) => {
            // styles applied to selected text when dropdown closed
            return {
                ...baseStyles,
                marginLeft: 0,
                paddingTop: 10,
                color: AonColors.AonNavy,
            };
        },
        dropdownIndicator: (baseStyles: CSSObjectWithLabel) => {
            // styles applied to icon
            return {
                ...baseStyles,
                padding: 0,
                paddingTop: 10,
            };
        },
        indicatorSeparator: () => {
            return {};
        },
        menu: (baseStyles: CSSObjectWithLabel) => {
            // styles applied to outer container for open dropdown menu
            return {
                ...baseStyles,
                top: "92%",
                left: -15,
                width: myStyles.width
            };
        },
        option: (baseStyles: CSSObjectWithLabel, {isFocused, isSelected}: OptionProps<any>) => {
            // styles applied to menu item
            return {
                ...baseStyles,
                ...activeStyles.activeClicked,
                padding: "8px 16px",
                fontSize: "1rem",
                fontWeight: isSelected ? 500 : 300,
                fontFamily: `"Helvetica Now Text", "sans-serif"`,
                backgroundColor: isFocused ? AonColors.AonGray05 : AonColors.ActualWhite,
                color: isSelected ? AonColors.AonTealDark : AonColors.AonGray01,
            };
        },
        placeholder: (baseStyles: CSSObjectWithLabel) => {
            return {
                ...baseStyles,
                ...myStyles.placeHolderStyle,
                marginTop: "5px",
            };
        },
    };
};
