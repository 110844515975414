import * as React from 'react';
import {FormattedMessage} from "react-intl";

export const AonTrustCompanyDisclaimer: React.FunctionComponent = () => {
    return <div className="disclaimer__content main-content" data-testid="disclaimer__content-ATC">
        <h1 id="atc.disclaimer-title">
            <FormattedMessage id="disclaimer.header" defaultMessage="Aon Legal Notice"/>
        </h1>
        <div>
            <h4 className="disclaimer-subheader">
                <FormattedMessage id="atc.disclaimer.terms-of-use" defaultMessage="Terms of Use"/>
            </h4>
            <p>
                <FormattedMessage id="atc.disclaimer.terms-of-use-p1" defaultMessage={
                    "The terms of use (“Terms of Use”) provided below apply to the Aon Trust Company LLC website " +
                    "(“Website”) and to all products, services, resources, information, materials, and other content " +
                    "available on the Website."
                }/>
            </p>
            <p>
                <FormattedMessage id="atc.disclaimer.terms-of-use-p2" defaultMessage={
                    "Aon Trust Company LLC is a bank organized and existing under the laws of the State of Illinois. " +
                    "Financial products offered by Aon Trust Company LLC are not FDIC insured, are not guaranteed by " +
                    "Aon Trust Company LLC, and may lose value."
                }/>
            </p>
            <p>
                <FormattedMessage id="atc.disclaimer.terms-of-use-p3" defaultMessage={
                    "By accessing and using the Website you acknowledge that you have read, understood, and " +
                    "agree to accept and be bound by the terms of the Legal Notices set out below. We may modify " +
                    "these Terms at any time without prior notice by posting any changes on the Website. Your " +
                    "continued access or use of the services provided by the Website will mean you accept the " +
                    "terms of the Legal Notices including any changes made to them. If you do not accept any of " +
                    "the terms below, please discontinue use of the Website and any of its associated pages. " +
                    "The Website and its terms are governed by the laws of the state of New York. All disputes " +
                    "arising from these terms must be resolved exclusively by the courts of the state of New York."
                }/>
            </p>
            <h4 className="disclaimer-subheader">
                <FormattedMessage id="atc.disclaimer.eligibility-property" defaultMessage="Eligibility" />
            </h4>
            <p>
                <FormattedMessage id="atc.disclaimer.eligibility-property-p1" defaultMessage={
                    "The use of the Website is offered and available to users who are eighteen (18) years of age or " +
                    "older. You may not use Website if you are under the age of eighteen (18). By using the Website, " +
                    "you represent and warrant that you are of legal age to form a binding contract with Aon and meet " +
                    "all of the eligibility requirements. If you do not meet all of these requirements, you must not " +
                    "access or use the Aon IP or the Exchange."
                }/>
            </p>
            <dl>
                <dt>
                    <p>
                        <FormattedMessage id="atc.disclaimer.eligibility-property-p2" defaultMessage={
                            "By using the Website, you represent and warrant that:"
                        }/>
                    </p>
                </dt>
                <dd>
                    <ol type="a">
                        <li ><FormattedMessage id="atc.disclaimer.eligibility.legal" defaultMessage="You have the legal capacity and you agree to comply with these Terms of Use;"/></li>
                        <li ><FormattedMessage id="atc.disclaimer.eligibility.jurisdiction" defaultMessage="You are not a minor in the jurisdiction in which you reside;"/></li>
                        <li ><FormattedMessage id="atc.disclaimer.eligibility.automated" defaultMessage="You will not access the Website through automated or non-human means, whether through a bot, script, or otherwise;"/></li>
                        <li ><FormattedMessage id="atc.disclaimer.eligibility.unauthorized" defaultMessage="You will not use the Website or any information thereon for any illegal or unauthorized purpose; and"/></li>
                        <li ><FormattedMessage id="atc.disclaimer.eligibility.violation" defaultMessage="Your use of the Website will not violate any applicable law or regulation."/></li>
                    </ol>
                </dd>
            </dl>
            <p>
                <FormattedMessage id="atc.disclaimer.eligibility-property-p3" defaultMessage={
                    "If you provide any information that is untrue, inaccurate, not current, or incomplete, we have " +
                    "the right to suspend or terminate your account and refuse any and all current or future use of " +
                    "the Website (or any portion thereof)."
                }/>
            </p>
            <h4 className="disclaimer-subheader">
                <FormattedMessage id="atc.disclaimer.copyright" defaultMessage="Copyright" />
            </h4>
            <p>
                <FormattedMessage id="atc.disclaimer.copyright.p1" defaultMessage={
                    "The content contained in this Website, including but not limited to all design, text, sound " +
                    "recordings, and images, is owned, except as otherwise expressly stated, by Aon plc or one of its " +
                    "subsidiaries, including but not limited to Aon Trust Company LLC (collectively, \"Aon\"). Except " +
                    "as otherwise expressly stated herein or to the extent permitted by law, the content may not be " +
                    "copied, transmitted, displayed, performed, distributed, licensed, altered, framed, stored for " +
                    "subsequent use, or otherwise used in whole or in part in any manner without Aon’s prior written " +
                    "consent. Users of this site may be permitted to download certain material posted on this site " +
                    "(e.g. white papers). When using downloadable materials, all copyright, trademark, and other " +
                    "proprietary notices must be kept intact and remain in effect."
                }/>
            </p>
            <h4 className="disclaimer-subheader">
                <FormattedMessage id="atc.disclaimer.trademark" defaultMessage="Trademark" />
            </h4>
            <p>
                <FormattedMessage id="atc.disclaimer.trademark.p1" defaultMessage={
                    "The trademarks, service marks, trade names, and logos (“Marks”) associated with the Website are " +
                    "owned by Aon, or third parties who have authorized their use."
                }/>
            </p>
            <p>
                <FormattedMessage id="atc.disclaimer.trademark.p2" defaultMessage={
                    "Nothing contained on this Website should be construed as granting any license or right to use any " +
                    "Mark displayed on the Website without the written permission of Aon or such third party that may " +
                    "own the Mark. Misuse of any Marks, or any other content, displayed on this website is prohibited."
                }/>
            </p>
            <p>
                <FormattedMessage id="atc.disclaimer.trademark.p3" defaultMessage={
                    "The Website is made available to Aon’s institutional advisory clients. Nothing accessible through " +
                    "the Website should be treated as an authoritative statement of the law on any particular aspect " +
                    "or in any specific case. The information contained on the Website is current as of the date " +
                    "indicated. The information contained on the Website is derived from proprietary and " +
                    "non-proprietary sources deemed by Aon to be reliable and is not necessarily all inclusive. " +
                    "Aon does not guarantee the accuracy or completeness of information made available on the Website " +
                    "and cannot be held accountable for inaccurate data provided by third parties. Reliance upon " +
                    "information made available on the Website is at the sole discretion of the user."
                }/>
            </p>
            <p>
                <FormattedMessage id="atc.disclaimer.trademark.p4" defaultMessage={
                    "Investment information provided on the Website does not constitute an offer of securities or " +
                    "solicitation of any kind and may not be treated as such, (i) in any jurisdiction where such an " +
                    "offer or solicitation is against the law; (ii) to anyone to whom it is unlawful to make such an " +
                    "offer or solicitation; or (iii) if the person making the offer or solicitation is not qualified " +
                    "to do so. Users should seek professional advice from a financial adviser registered in their local " +
                    "jurisdiction." +
                    "We have not considered the suitability and/or appropriateness of any investment you may wish to " +
                    "make with us. It is your responsibility to be aware of and to observe all applicable laws and " +
                    "regulations of any relevant jurisdiction, including the one in which you reside."
                }/>
            </p>
            <h4 className="disclaimer-subheader">
                <FormattedMessage id="atc.disclaimer.login-ids-user-ids-and-passwords"
                                  defaultMessage="Login IDs, User IDs and Passwords" />
            </h4>
            <p>
                <FormattedMessage id="atc.disclaimer.login-ids-user-ids-and-passwords.p1" defaultMessage={
                    "If you have been issued a Login ID and/or an access code to enable you to access the non-public " +
                    "areas of the Website, you are solely responsible for all activities that occur in connection with " +
                    "your Login ID and ensuring the safety and security of the access codes. You are not permitted to " +
                    "use the access codes for any purposes other than to receive the particular service offered " +
                    "by Aon for which the access codes were issued. You are not authorized or permitted to share " +
                    "the access codes with any other party. Please notify Aon immediately if you become aware of any " +
                    "disclosure, loss, theft, or unauthorized use of your Login ID."
                }/>
            </p>
            <h4 className="disclaimer-subheader">
                <FormattedMessage id="atc.disclaimer.cookies" defaultMessage="Cookies and information collected"/>
            </h4>
            <p>
                <FormattedMessage id="atc.disclaimer.cookies-p" defaultMessage={
                    "When you visit the Website, the following information may be recorded: your IP address, pages " +
                    "visited, content downloaded and cookies. A cookie is a piece of information about an Internet " +
                    "session that may be created when a visitor accesses a website and which is used to identify " +
                    "you and store certain information such as Login information when you choose \"Remember my " +
                    "User ID,\" when logging onto our site. Aon uses this information to provide a more customized " +
                    "user experience and to help us understand the number of people that visit the Website and " +
                    "which sections of the Website are visited most frequently. This information may be used to " +
                    "determine which information is most useful, improve the Website and to provide better " +
                    "customer service."
                }/>
            </p>
            <h4 className="disclaimer-subheader">
                <FormattedMessage id="atc.disclaimer.content" defaultMessage="Content"/>
            </h4>
            <p>
                <FormattedMessage id="atc.disclaimer.content-p1" defaultMessage={
                    "Aon makes no representations about the suitability of the content of this site for any purpose. " +
                    "All content is provided \"as is\" without any warranty of any kind."
                }/>
            </p>
            <p>
                <FormattedMessage id="atc.disclaimer.content-p2" defaultMessage={
                    "AON HAS NOT MADE AND DOES NOT MAKE ANY WARRANTY OF ANY KIND WHATEVER, EXPRESS OR IMPLIED, " +
                    "WITH RESPECT TO THE CONTENTS OF THE WEBSITE; AND ALL IMPLIED WARRANTIES OF MERCHANTABILITY, " +
                    "TITLE, NON-INFRINGEMENT AND FITNESS FOR A PARTICULAR PURPOSE ARE HEREBY DISCLAIMED BY AON. " +
                    "AON WILL NOT BE LIABLE TO ANYONE WITH RESPECT TO ANY DAMAGES, LOSS OR CLAIM WHATSOEVER, NO " +
                    "MATTER HOW OCCASIONED, IN CONNECTION WITH ACCESS TO OR USE OF THE CONTENTS OF THIS SITE. IN " +
                    "NO EVENT SHALL AON BE LIABLE FOR ANY SPECIAL, INDIRECT, EXEMPLARY OR CONSEQUENTIAL DAMAGES OR " +
                    "ANY DAMAGES WHATSOEVER, INCLUDING BUT NOT LIMITED TO LOSS OF USE, DATA OR PROFITS, WITHOUT " +
                    "REGARD TO THE FORM OF ANY ACTION INCLUDING BUT NOT LIMITED TO CONTRACT OR NEGLIGENCE OR OTHER " +
                    "TORTIOUS ACTIONS, ARISING OUT OF OR IN CONNECTION WITH THE USE, COPYING OR DISPLAY OF THE CONTENT."
                }/>
            </p>
            <p>
                <FormattedMessage id="atc.disclaimer.content-p3" defaultMessage={
                    "While Aon believes the content to be accurate, complete and current, there may be inadvertent " +
                    "technical or factual inaccuracies and typographical errors and Aon does not warrant that the " +
                    "information is accurate or complete or current. It is your responsibility to verify any " +
                    "information before relying on it. At any time and from time to time Aon may make changes in " +
                    "the products and/or services described herein, however, Aon makes no commitment to update the " +
                    "information and expressly disclaims liability for errors or omissions in it."
                }/>
            </p>
            <h4 className="disclaimer-subheader">
                <FormattedMessage id="atc.disclaimer.links" defaultMessage="Links to Other Sites"/>
            </h4>
            <p>
                <FormattedMessage id="atc.disclaimer.links-p" defaultMessage={
                    "Some of the sites listed as links herein are not under the control of Aon. Accordingly, Aon " +
                    "makes no representations whatsoever concerning the content of those sites. The fact that Aon " +
                    "has provided a link to a site is not an endorsement, authorization, sponsorship or affiliation " +
                    "by Aon with respect to such site, its owners or its providers. Aon is providing these links " +
                    "only as a convenience to you. Aon has not tested any information, software or products found " +
                    "on these sites and therefore cannot make any representations whatsoever with respect thereto. " +
                    "There are risks in using any information, software or products found on the Internet, and Aon " +
                    "cautions you to make sure that you completely understand these risks before retrieving, using, " +
                    "relying upon or purchasing anything via the Internet."
                }/>
            </p>
            <h4 className="disclaimer-subheader">
                <FormattedMessage id="atc.disclaimer.recommendation-disclosure" defaultMessage="Recommendation Disclosure"/>
            </h4>
            <p>
                <FormattedMessage id="atc.disclaimer.recommendation-disclosure-p1" defaultMessage={
                    "Aon plc, including all of its subsidiaries and affiliates, maintains relationships with " +
                    "clients and business partners around the world. When Aon recommends or mentions a product " +
                    "or service provider, it is possible that the product or service is being offered by one of " +
                    "Aon's clients or business partners."
                }/>
            </p>
            <p>
                <FormattedMessage id="atc.disclaimer.recommendation-disclosure-p2" defaultMessage={
                    "Aon maintains a strict Code of Business Conduct covering many topics, including antitrust and " +
                    "competition law, conflicts of interest, anti-bribery, privacy, financial reporting, compliance " +
                    "with trade restrictions, protection of confidential information, and compliance with all laws " +
                    "and regulations applicable to the conduct of our business. Aon also files necessary disclosures " +
                    "with all governmental regulators."
                }/>
            </p>
            <p>
                <FormattedMessage id="atc.disclaimer.recommendation-disclosure-p3" defaultMessage={
                    "If you have any questions about an Aon recommendation or mention of another business entity (or " +
                    "about an entity with whom Aon conducts business), please follow-up with your Aon contact person. " +
                    "Aon is committed to serving our clients in a transparent manner."
                }/>
            </p>
            <h4 className="disclaimer-subheader">
                <FormattedMessage id="atc.disclaimer.export-controls" defaultMessage="Export Controls"/>
            </h4>
            <p>
                <FormattedMessage id="atc.disclaimer.export-controls-p" defaultMessage={
                    "Some software from this site may be subject to export controls imposed by the U.S. and may not " +
                    "be downloaded or otherwise exported or reexported: (a) into (or to a national resident of) any " +
                    "country to which the U.S. has placed an embargo; (b) to anyone on the U. S. Treasury Department's " +
                    "Specially Designated Nationals list; or (c) to anyone on the U. S. Commerce Department's Table " +
                    "of Denial Orders. If you download or use the software, you represent and warrant that you are " +
                    "not located in, or under the control of, or a national of any such country or on such list."
                }/>
            </p>
            <h4 className="disclaimer-subheader">
                <FormattedMessage id="atc.disclaimer.client-data-use" defaultMessage="Client Data, Collection and Use"/>
            </h4>
            <p>
                <FormattedMessage id="atc.disclaimer.client-data-use-p1" defaultMessage={
                    "Aon may gather data containing information about its clients or use of this online portal and " +
                    "is maintained in one or more databases."
                }/>
            </p>
            <p>
                <FormattedMessage id="atc.disclaimer.client-data-use-p2" defaultMessage={
                    "Aon may use or disclose information about its clients, if required to do so by law, Aon policy, " +
                    "pursuant to legal process or in response to a request from law enforcement authorities or other " +
                    "government officials. In addition to being used for the benefit of Aon’s clients, these " +
                    "databases also may be accessed by other Aon affiliates for other purposes, including providing " +
                    "consulting, and other services to insurance companies for which Aon or its affiliates may earn " +
                    "compensation."
                }/>
            </p>
            <p>
                <FormattedMessage id="atc.disclaimer.client-data-use-p3" defaultMessage={
                    "Due to the global nature of services provided by Aon, the personal information Client may " +
                    "provide may be transmitted, used, stored and otherwise processed outside the country where " +
                    "Client submitted that information."
                }/>
            </p>
            <p>
                <FormattedMessage id="atc.disclaimer.client-data-use-p4" defaultMessage={
                    "For further information regarding Aon's data privacy practices, please see Aon's "
                }/>
                {/* eslint-disable-next-line react/forbid-dom-props */}
                <a href={"https://www.aon.com/about-aon/privacy.jsp"}>
                    <FormattedMessage id="atc.disclaimer.client-data-use-p4-a" defaultMessage="Privacy Statement."/>
                </a>
            </p>
            <h4 className="atc.disclaimer-subheader">
                <FormattedMessage id="atc.disclaimer.safe-harbor-statement" defaultMessage="Safe Harbor Statement"/>
            </h4>
            <p>
                <FormattedMessage id="atc.disclaimer.safe-harbor-statement.p1" defaultMessage={
                    "The content contained in this Website contains certain statements related to future results, or " +
                    "states Aon's intentions, beliefs and expectations or predictions for the future which are " +
                    "forward-looking statements as that term is defined in the Private Securities Litigation " +
                    "Reform Act of 1995. These forward-looking statements are subject to certain risks and " +
                    "uncertainties that could cause actual results to differ materially from either historical or " +
                    "anticipated results depending on a variety of factors. These forward-looking statements include " +
                    "information about possible or assumed future results of Aon's operations, the uncertainty " +
                    "surrounding the COVID-19 pandemic, the termination of Aon's Business Combination Agreement with " +
                    "Willis Towers Watson Public Limited Company regarding the combination of the parties " +
                    "(the \"Combination\") and other matters. All statements other than statements of historical facts " +
                    "that address activities, events or developments that Aon expects or anticipates may occur in " +
                    "the future, including such things as its outlook, future capital expenditures, growth in " +
                    "commissions and fees, changes to the composition or level of its revenues, cash flow and " +
                    "liquidity, expected tax rates, business strategies, competitive strengths, goals, the benefits " +
                    "of new initiatives, growth of its business and operations, plans, references to future successes, " +
                    "the termination of the Combination and divestitures planned in connection therewith, and pending " +
                    "or potential litigation relating to the Combination and divestitures planned in connection " +
                    "therewith, including as a result of the termination or potential termination of such " +
                    "transactions, are forward-looking statements. Also, when Aon uses the words such " +
                    "as \"anticipate\", \"believe\", \"estimate\", \"expect\", \"intend\", \"plan\", \"probably\", " +
                    "\"potential\", \"looking forward\", or similar expressions, it is making forward-looking " +
                    "statements."
                }/>
            </p>
            <p>
                <FormattedMessage id="atc.disclaimer.safe-harbor-statement.p2" defaultMessage={
                    "The following factors, among others, could cause actual results to differ from those set forth " +
                    "in or anticipated by the forward-looking statements: general economic and political conditions " +
                    "in different countries in which Aon does business around the world, including the U.K.'s " +
                    "withdrawal from the European Union; changes in the competitive environment or damage to Aon's " +
                    "reputation; fluctuations in exchange and interest rates that could influence revenue and expenses;" +
                    " changes in global equity and fixed income markets that could affect the return on invested " +
                    "assets; changes in the funding status of Aon's various defined benefit pension plans and " +
                    "the impact of any increased pension funding resulting from those changes; the level of Aon's " +
                    "debt limiting financial flexibility or increasing borrowing costs; rating agency actions that " +
                    "could affect Aon's ability to borrow funds; volatility in Aon's tax rate due to a variety of " +
                    "different factors, including U.S. tax reform; changes in estimates or assumptions on Aon's " +
                    "financial statements; limits on Aon's subsidiaries to make dividend and other payments to Aon; " +
                    "the impact of lawsuits and other contingent liabilities and loss contingencies arising from " +
                    "errors and omissions and other claims against Aon; the impact of, and potential challenges in " +
                    "complying with, legislation and regulation in the jurisdictions in which Aon operates, " +
                    "particularly given the global scope of Aon's businesses and the possibility of conflicting " +
                    "regulatory requirements across jurisdictions in which Aon does business; the impact of any " +
                    "investigations brought by regulatory authorities in the U.S., Ireland, the U.K. and other " +
                    "countries; the impact of any inquiries relating to compliance with the U.S. Foreign Corrupt " +
                    "Practices Act and non-U.S. anti-corruption laws and with U.S. and non-U.S. trade sanctions " +
                    "regimes; failure to protect intellectual property rights or allegations that Aon infringes on " +
                    "the intellectual property rights of others; the effects of Irish law on Aon's operating " +
                    "flexibility and the enforcement of judgments against Aon; the failure to retain and attract " +
                    "qualified personnel, whether as a result of the termination of the Combination or otherwise; " +
                    "international risks associated with Aon's global operations; the effects of natural or man-made " +
                    "disasters, including the effects of COVID-19 and other health pandemics; the potential of a " +
                    "system or network breach or disruption resulting in operational interruption or improper " +
                    "disclosure of personal data; Aon's ability to develop and implement new technology; the damage " +
                    "to Aon's reputation among clients, markets or third parties; the actions taken by third parties " +
                    "that perform aspects of Aon's business operations and client services; the extent to which Aon " +
                    "manages certain risks created in connection with the services, including fiduciary and " +
                    "investments, consulting, and other advisory services, among others, that Aon currently provides, " +
                    "or will provide in the future, to clients; Aon's ability to continue, and the costs and the " +
                    "costs and risks associated with, growing, developing and integrating companies that it acquires " +
                    "or new lines of business; changes in commercial property and casualty markets, commercial " +
                    "premium rates or methods of compensation; changes in the health care system or Aon's " +
                    "relationships with insurance carriers; Aon's ability to implement initiatives intended to yield," +
                    " and the ability to achieve, cost savings; Aon's ability to realize the expected benefits from " +
                    "its restructuring plan; adverse effects on the market price of Aon's securities and/or operating " +
                    "results for any reason, including, without limitation, because of the failure to consummate " +
                    "the Combination; the failure to realize the expected benefits of the Combination (including " +
                    "anticipated revenue and growth synergies); significant transaction costs in connection with " +
                    "the terminated Combination and divestitures planned in connection with the Combination; " +
                    "litigation associated with the termination of the Combination and divestitures planned in " +
                    "connection with the Combination; the payment of the termination fee in connection with the " +
                    "termination of the Combination; the potential impact of the termination of the Combination " +
                    "on relationships, including with suppliers, customers, employees and regulators; and general " +
                    "economic, business and political conditions (including any epidemic, pandemic or disease outbreak," +
                    " including COVID-19) that affect the Company."
                }/>
            </p>
            <p>
                <FormattedMessage id="atc.disclaimer.safe-harbor-statement.p3" defaultMessage={
                    "Any or all of Aon's forward-looking statements may turn out to be inaccurate, and there are " +
                    "no guarantees about Aon's performance. The factors identified above are not exhaustive. " +
                    "Aon and its subsidiaries operate in a dynamic business environment in which new risks may " +
                    "emerge frequently. Accordingly, you should not place undue reliance on forward-looking statements," +
                    " which speak only as of the dates on which they are made. In addition, previous performance" +
                    " results are not necessarily indicative of results that may be expected for any future period," +
                    " particularly in light of the continuing effects of the COVID-19 pandemic. Further information" +
                    " concerning Aon and its businesses, including factors that potentially could materially affect" +
                    " Aon's financial results, is contained in Aon's filings with the Securities and Exchange " +
                    "Commission (the \"SEC\"). See Aon's Annual Report on Form 10-K for the year ended " +
                    "December 31, 2020 and its Quarterly Reports on Form 10-Q for the quarters ended March 31, 2021," +
                    " and June 30, 2021, for a further discussion of these and other risks and uncertainties " +
                    "applicable to Aon and its businesses. These factors may be revised or supplemented in subsequent" +
                    " reports filed with the SEC. Aon is not under, and expressly disclaims, any obligation to update" +
                    " or alter any forward-looking statement that it may make from time to time, whether as a result" +
                    " of new information, future events or otherwise."
                }/>
            </p>
        </div>
    </div>;
};