import {IPlanTreeNode, IPlanTreeNodeFlat} from "../../model/Portfolio.model";

export const getTotalPlanNode = (flatNodes: IPlanTreeNodeFlat[], date: string | undefined = undefined): IPlanTreeNode | undefined => {
    const nodes = date ? flatNodes.filter(node => node.date === date) : flatNodes;
    return nodes.find(node => node.parentId === null);
};

export const buildPortfolioTree = (flatNodes: IPlanTreeNodeFlat[]): IPlanTreeNode[] => {
    if (flatNodes.length === 0) {
        return [];
    }
    const totalPlanNode = getTotalPlanNode(flatNodes);
    return totalPlanNode ? getChildNodes(flatNodes, totalPlanNode.id) : [];
};

const getChildNodes = (flatNodes: IPlanTreeNodeFlat[], parentId: number): IPlanTreeNode[] => {
    return flatNodes
        .filter(it => it.parentId === parentId)
        .sort((a, b) => a.sortOrder - b.sortOrder)
        .map(node => ({
                    id: node.id,
                    name: node.name,
                    sortOrder: node.sortOrder,
                    accountType: node.accountType,
                    marketValue: node.marketValue,
                    marketValuePctOfTotal: node.marketValuePctOfTotal,
                    marketValuePctOfParent: node.marketValuePctOfParent,
                    backstopId: node.backstopId,
                    nodes: node.accountType === 1 ? null : getChildNodes(flatNodes, node.id),

        }));
};