/* tslint:disable:interface-name */
import {Map} from "immutable";
import {Dispatch} from "redux";
import {Action} from "redux-actions";
import CapitalMarketAssumptionsApi from "../../api/CapitalMarketAssumptionsApi";
import {InsightsApi} from "../../api/InsightsApi";
import {CapitalMarketAssumption} from "../../model/thought-leadership/CapitalMarketAssumption";
import {MediumTermView} from "../../model/thought-leadership/MediumTermView.model";
import {MarketOutlookDocument} from "../../model/thought-leadership/MarketOutlookDocument.model";
import {WhitePaper} from "../../model/thought-leadership/WhitePaper.model";

export const enum ThoughtLeadershipActions {
    THOUGHT_LEADERSHIP_DATA_REQUESTED = "THOUGHT_LEADERSHIP_DATA_REQUESTED",
    THOUGHT_LEADERSHIP_REQUEST_ERROR = "THOUGHT_LEADERSHIP_REQUEST_ERROR",
    THOUGHT_LEADERSHIP_DATA_RECEIVED = "THOUGHT_LEADERSHIP_DATA_RECEIVED",
}

export interface MediumTermViewRequested {
    kind: "MTV";
}

export interface CapitalMarketAssumptionRequested {
    kind: "CMA";
}

export interface WhitePaperRequested {
    kind: "White Paper";
}

export interface QuarterlyInvestmentOutlookRequested {
    kind: "QIO";
}

export interface MarketBackgroundsRequested {
    kind: "MB";
}

export interface MediumTermViewData {
    kind: "MTV";
    data: MediumTermView[];
}

export interface CapitalMarketAssumptionData {
    kind: "CMA";
    data: Map<number, CapitalMarketAssumption>;
}

export interface WhitePaperData {
    kind: "White Paper";
    data: WhitePaper[];
}

export interface QuarterlyInvestmentOutlookData {
    kind: "QIO";
    data: MarketOutlookDocument[];
}

export interface MarketBackgroundsData {
    kind: "MB";
    data: MarketOutlookDocument[];
}

export interface MediumTermViewRequestError {
    kind: "MTV";
    error: any;
}

export interface CapitalMarketAssumptionRequestError {
    kind: "CMA";
    error: any;
}

export interface WhitePaperRequestError {
    kind: "White Paper";
    error: any;
}

export interface QuarterlyInvestmentOutlookError {
    kind: "QIO";
    error: any;
}

export interface MarketBackgroundsError {
    kind: "MB";
    error: any;
}

export type ThoughtLeadershipDataRequested =
    MediumTermViewRequested
    | CapitalMarketAssumptionRequested
    | WhitePaperRequested
    | QuarterlyInvestmentOutlookRequested
    | MarketBackgroundsRequested;

export type ThoughtLeadershipDataResponse =
    MediumTermViewData
    | CapitalMarketAssumptionData
    | WhitePaperData
    | QuarterlyInvestmentOutlookData
    | MarketBackgroundsData;

export type ThoughtLeadershipRequestError =
    MediumTermViewRequestError
    | CapitalMarketAssumptionRequestError
    | WhitePaperRequestError
    | QuarterlyInvestmentOutlookError
    | MarketBackgroundsError;

export class ThoughtLeadershipDocuments {

    public static async requestMTVDocuments(dispatch: Dispatch<Action<any>>) {
        dispatch({
            type: ThoughtLeadershipActions.THOUGHT_LEADERSHIP_DATA_REQUESTED,
            payload: {
                kind: "MTV",
            },
        });

        return InsightsApi.requestMediumTermViews().then(({data}) => {
            dispatch({
                type: ThoughtLeadershipActions.THOUGHT_LEADERSHIP_DATA_RECEIVED,
                payload: {
                    kind: "MTV",
                    data,
                },
            });
        }).catch(({error}) => {
            dispatch({
                type: ThoughtLeadershipActions.THOUGHT_LEADERSHIP_REQUEST_ERROR,
                payload: {
                    kind: "MTV",
                    error,
                },
            });
        });
    }

    public static async requestCMADocuments(dispatch: Dispatch<Action<any>>) {
        dispatch({
            type: ThoughtLeadershipActions.THOUGHT_LEADERSHIP_DATA_REQUESTED,
            payload: {
                kind: "CMA",
            },
        });

        return CapitalMarketAssumptionsApi.requestCapitalMarketAssumptions().then(({data}) => {
            dispatch({
                type: ThoughtLeadershipActions.THOUGHT_LEADERSHIP_DATA_RECEIVED,
                payload: {
                    kind: "CMA",
                    data,
                },
            });
        }).catch(({error}) => {
            dispatch({
                type: ThoughtLeadershipActions.THOUGHT_LEADERSHIP_REQUEST_ERROR,
                payload: {
                    kind: "CMA",
                    error,
                },
            });
        });
    }

    public static async getWhitePaperDocuments(dispatch: Dispatch<Action<any>>) {
        dispatch({
            type: ThoughtLeadershipActions.THOUGHT_LEADERSHIP_DATA_REQUESTED,
            payload: {
                kind: "White Paper",
            },
        });

        return InsightsApi.requestWhitePapers().then(({data}) => {
            dispatch({
                type: ThoughtLeadershipActions.THOUGHT_LEADERSHIP_DATA_RECEIVED,
                payload: {
                    kind: "White Paper",
                    data,
                },
            });
        }).catch(({error}) => {
            dispatch({
                type: ThoughtLeadershipActions.THOUGHT_LEADERSHIP_REQUEST_ERROR,
                payload: {
                    kind: "White Paper",
                    error,
                },
            });
        });
    }

    public static async requestQIODocuments(dispatch: Dispatch<Action<any>>) {
        dispatch({
            type: ThoughtLeadershipActions.THOUGHT_LEADERSHIP_DATA_REQUESTED,
            payload: {
                kind: "QIO",
            },
        });

        return InsightsApi.requestQuarterlyInvestmentOutlook().then(({data}) => {
            dispatch({
                type: ThoughtLeadershipActions.THOUGHT_LEADERSHIP_DATA_RECEIVED,
                payload: {
                    kind: "QIO",
                    data,
                },
            });
        }).catch(({error}) => {
            dispatch({
                type: ThoughtLeadershipActions.THOUGHT_LEADERSHIP_REQUEST_ERROR,
                payload: {
                    kind: "QIO",
                    error,
                },
            });
        });
    }

    public static async requestMBDocuments(dispatch: Dispatch<Action<any>>) {
        dispatch({
            type: ThoughtLeadershipActions.THOUGHT_LEADERSHIP_DATA_REQUESTED,
            payload: {
                kind: "MB",
            },
        });

        return InsightsApi.requestMarketBackgrounds().then(({data}) => {
            dispatch({
                type: ThoughtLeadershipActions.THOUGHT_LEADERSHIP_DATA_RECEIVED,
                payload: {
                    kind: "MB",
                    data,
                },
            });
        }).catch(({error}) => {
            dispatch({
                type: ThoughtLeadershipActions.THOUGHT_LEADERSHIP_REQUEST_ERROR,
                payload: {
                    kind: "MB",
                    error,
                },
            });
        });
    }
}
