import * as React from "react";
import {ICalendarTemplateTopic} from "../../api/CalendarApi";
import {Draggable, DraggableProvided, Droppable} from "react-beautiful-dnd";
import {CalendarFrequencyMap} from "./CalendarUtils";

export interface IClalendarTemplateProps {
    templateTopics: ICalendarTemplateTopic[];
}
export const CalendarTemplate: React.FunctionComponent<IClalendarTemplateProps> = (props) => {
    const renderTemplateEntry = (entry: ICalendarTemplateTopic, entryIdx: number) => {
        return  <Draggable draggableId={`topic-${entry.id}`} index={entryIdx} key={entry.id}>
            {(dProv:DraggableProvided) => (
                <div
                    className="calendar-template__topic-with-frequency" key={entry.id}
                    ref={dProv.innerRef}
                    {...dProv.draggableProps}
                    {...dProv.dragHandleProps}
                >
                    <span className="client-calendar__topic">{entry.name}</span>
                    <span className="client-calendar__frequency">
                        {CalendarFrequencyMap.get(entry.frequencyId)}
                    </span>
                </div>
            )}
        </Draggable>;
    };

    return <div className="calendar-template__topics">
        <p>Topics</p>
        <Droppable key="template" droppableId="template">
            {(provided) => (
                <div className="topicsDroppable"
                     ref={provided.innerRef}
                     {...provided.droppableProps}>
                    {props.templateTopics.map(renderTemplateEntry)}
                    {provided.placeholder}
                </div>
            )}
        </Droppable>
    </div>;
};