import {IQuarterAndYear, Quarter} from "../utils/dateUtil";

export enum FsAssetClass {
    ALL = "All Asset Classes",
    EQUITIES = "Equities",
    FIXED_INCOME = "Fixed Income",
    LIQUID_ALTERNATIVES = "Liquid Alternatives",
    MULTI_ASSET = "Multi Asset",
    OTHER = "Other",
}

export enum FsCurrency {
    ALL = "All Currencies",
    CAD = "CAD",
    EUR = "EUR",
    GBP = "GBP",
    USD = "USD",
    OTHER = "Other",
}

export enum FsFrequency {
    ALL = "All Frequencies",
    MONTHLY = "Monthly",
    QUARTERLY = "Quarterly",
    OTHER = "Other",
}

export class FactSheet {
    public static builder() {
        return new FactSheetBuilder();
    }

    constructor(public backstopId: number,
                public title: string,
                public effectiveDate: Date,
                public quarterAndYear: IQuarterAndYear,
                public fileName: string,
                public assetClass: FsAssetClass,
                public currency: FsCurrency,
                public frequency: FsFrequency,
    ) {
    }
}

class FactSheetBuilder {
    private mBackstopId: number;
    private mTitle: string;
    private mEffectiveDate: Date;
    private mQuarterAndYear: IQuarterAndYear;
    private mFileName: string;
    private mAssetClass: FsAssetClass;
    private mCurrency: FsCurrency;
    private mFrequency: FsFrequency;

    public backstopId(value: number) {
        this.mBackstopId = value;
        return this;
    }

    public title(value: string) {
        this.mTitle = value;
        return this;
    }

    public effectiveDate(value: Date) {
        this.mEffectiveDate = value;
        return this;
    }


    public quarterAndYear(value: IQuarterAndYear) {
        this.mQuarterAndYear = value;
        return this;
    }

    public fileName(value: string) {
        this.mFileName = value;
        return this;
    }

    public assetClass(value: FsAssetClass) {
        this.mAssetClass = value;
        return this;
    }

    public currency(value: FsCurrency) {
        this.mCurrency = value;
        return this;
    }

    public frequency(value: FsFrequency) {
        this.mFrequency = value;
        return this;
    }

    public build() {
        return new FactSheet(
            this.mBackstopId || 0,
            this.mTitle || "title",
            this.mEffectiveDate || new Date (),
            this.mQuarterAndYear || {quarter: Quarter.Q1, year: 0},
            this.mFileName || "file name",
            this.mAssetClass || FsAssetClass.OTHER,
            this.mCurrency || FsCurrency.OTHER,
            this.mFrequency || FsFrequency.OTHER,
        );
    }
}