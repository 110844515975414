import {Axis} from "d3-axis";
import {ScaleBand, ScaleLinear, ScaleOrdinal} from "d3-scale";
import {BaseType, Selection} from "d3-selection";
import {Arc, Pie, PieArcDatum, Stack} from "d3-shape";
import {IOddResponsePercentSummary, IOddResponseSummary, OddResponseName} from "../../api/OddIqApi";
import {Map as ImmutableMap} from "immutable";
import {byBest} from "./OddIqUtils";

export interface IMargins {
    top: number;
    right: number;
    bottom: number;
    left: number;
}

export interface IHorizontalBarChartVariables {
    colorFn: ScaleOrdinal<string, any>;
    stackFn: Stack<any, IStack, string>;
    xScaleFn: ScaleLinear<number, number>;
    yScale0Fn: ScaleBand<string>;
    yScale1Fn?: ScaleBand<string>;
    yAxis0Fn?: Axis<string>;
    yAxis1Fn?: Axis<string>;
    chartRef?: Selection<BaseType, any, HTMLElement, any>;
    chartHeight: number;
    chartWidth?: number;
}

export interface IFieldData {
    name: string;
    id: number;
}

export interface IStack {
    [p: string]: number;
}

export interface IKeyedResponseSummary extends IStack {
    [OddResponseName.EXCEPTIONS]: number;
    [OddResponseName.BEST_PRACTICE]: number;
    [OddResponseName.NO_DATA]: number;
}

export interface IStackData {
    counts: IKeyedResponseSummary;
    percents: IKeyedResponseSummary;
    id: number;
    name: string;
}

export const RESPONSE_SUMMARY_KEYS = [
    OddResponseName.EXCEPTIONS,
    OddResponseName.BEST_PRACTICE,
    OddResponseName.NO_DATA];

export interface IDonutChartProperties {
    innerRadius: number;
    donutThickness: number;
}

export interface IDonutChartData {
    key: string;
    value: number;
    counts: number;
}

export interface IDonutChartVariables {
    keys: string[];
    colorGenerator: ScaleOrdinal<string, any>;
    chartRef: Selection<BaseType, any, HTMLElement, any>;
    legendRef?: Selection<BaseType, any, HTMLElement, any>;
    titleRef?: Selection<BaseType, any, HTMLElement, any>;
    arcBuilder: Arc<any, PieArcDatum<IDonutChartData>>;
    pieGenerator: Pie<any, IDonutChartData>;
    slices: PieArcDatum<IDonutChartData>[];
}

export function renderDataArcs(dc: IDonutChartVariables, arcClassName: string) {
    dc.chartRef
        .append("g")
        .selectAll("dataArcs")
        .data(dc.slices)
        .join("g")
        .attr("class", arcClassName)
        .append("path")
        .attr("d", dc.arcBuilder)
        .attr("fill", (d) => dc.colorGenerator(d.data.key));
}

export function addLegendRects(legends: Selection<BaseType, string, BaseType, any>, x: number, colorGenerator: any) {
    legends.append("rect")
        .attr("x", x)
        .attr("rx", 2)
        .attr("width", 16)
        .attr("height", 16)
        .attr("fill", colorGenerator);
}

export function summaryToDonutChartData(counts: IOddResponseSummary, percents: IOddResponsePercentSummary): IDonutChartData[] {
    return [
        {
            key: OddResponseName.EXCEPTIONS,
            value: percents.exceptions,
            counts: counts.exceptions,
        },
        {
            key: OddResponseName.BEST_PRACTICE,
            value: percents.bestPractice,
            counts: counts.bestPractice,
        },
        {
            key: OddResponseName.NO_DATA,
            value: percents.noData,
            counts: counts.noData,
        },
    ];
}

export function countsAndPercentsToStackData(
    countsMap: ImmutableMap<number, IOddResponseSummary>,
    percentsMap: ImmutableMap<number, IOddResponsePercentSummary>,
): ImmutableMap<number, IStackData> {
    return percentsMap
        .sort(byBest)
        .map((val, key): IStackData => {
            const counts = countsMap.get(key!);
            return {
                id: key!,
                name: val!.name!,
                percents: {
                    [OddResponseName.EXCEPTIONS]: val!.exceptions,
                    [OddResponseName.BEST_PRACTICE]: val!.bestPractice,
                    [OddResponseName.NO_DATA]: val!.noData,
                },
                counts: {
                    [OddResponseName.EXCEPTIONS]: counts ? counts.exceptions : 0,
                    [OddResponseName.BEST_PRACTICE]: counts ? counts.bestPractice : 0,
                    [OddResponseName.NO_DATA]: counts ? counts.noData : 0,
                },
            };
        })
        .toOrderedMap();
}