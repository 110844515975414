import * as React from "react";
import {connect} from "react-redux";
import {AnyAction, bindActionCreators, Dispatch} from "redux";
import {assertNever} from "../../utils/errorUtil";
import {ErrorComponent} from "../base/Error.component";
import {REQUEST_STATES} from "../common/commonStates";
import {withVerifyAccess} from "../common/withVerifyAccess";
import {LoadingSpinner} from "../icons/LoadingSpinner.component";
import {ISessionState} from "../session/SessionReducer";
import adminActions from "./adminActions";
import {IAdminState} from "./AdminReducer";
import {UserTypeEnum} from "../../model/UserInfo.model";
import {IApplicationRootState} from "../../../applicationState";

interface IAdminActions {
    actions: typeof adminActions;
}

interface IAdminPropsFromParent {
    children?: JSX.Element;
}

type IAdminPropsFromReduxStore = ISessionState & IAdminState;

export type AdminUserInfoRequesterProps = IAdminPropsFromParent & IAdminPropsFromReduxStore & IAdminActions;

export class AdminUserInfoRequester extends React.Component<AdminUserInfoRequesterProps> {
    public constructor(props: any) {
        super(props);
    }

    public componentDidMount() {
        const adminUsersInfoState = this.props.adminUsersInfoState;
        switch (adminUsersInfoState.kind) {
            case REQUEST_STATES.NOT_REQUESTED:
                break;
            case REQUEST_STATES.REQUESTED:
                break;
            case REQUEST_STATES.REQUEST_FAILED:
                break;
            case REQUEST_STATES.REQUEST_SUCCEEDED:
                break;
            default:
                return assertNever(adminUsersInfoState); // error here if there are missing cases
        }
    }

    public render() {
        const adminUsersInfoState = this.props.adminUsersInfoState;
        switch (adminUsersInfoState.kind) {
            case REQUEST_STATES.NOT_REQUESTED:
                this.props.actions.getUserDetailsForEdit();
                return <LoadingSpinner/>;
            case REQUEST_STATES.REQUESTED:
                return <LoadingSpinner/>;
            case REQUEST_STATES.REQUEST_FAILED:
                return <ErrorComponent/>;
            case REQUEST_STATES.REQUEST_SUCCEEDED:
                return this.renderChildren();
            default:
                return assertNever(adminUsersInfoState); // error here if there are missing cases
    }
    }

    private renderChildren() {
        return this.props.children;
    }
}

export const mapStateToProps = (state: IApplicationRootState): IAdminPropsFromReduxStore => {
    return {
        ...state.session!,
        ...state.adminState!,
    };
};

export const mapDispatchToProps = (dispatch: Dispatch<AnyAction>): IAdminActions =>  {
    return {
        actions: bindActionCreators({...adminActions}, dispatch),
    };
};

const connectedComponent = connect<IAdminPropsFromReduxStore, IAdminActions>(mapStateToProps, mapDispatchToProps)
    (AdminUserInfoRequester);

export default withVerifyAccess(connectedComponent, UserTypeEnum.ADMIN);
