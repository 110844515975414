import * as React from "react";
import {ButtonHTMLAttributes, CSSProperties, MutableRefObject, useRef, useState} from "react";
import {disabledButtonStyle} from "./buttonStyles";

export interface IRaisedButton<T> extends ButtonHTMLAttributes<T> {
    style: CSSProperties;
    primary: boolean;
    disabled?: boolean;
}

export const RaisedButton: React.FunctionComponent<IRaisedButton<any>> = (props) => {
    const buttonRef: React.MutableRefObject<HTMLButtonElement | null> = useRef(null);
    const [rippleArray, setRippleArray] = useState([]);
    const [hovered, setHovered] = useState(false);
    const hoveredClass = () => hovered ? "highlight" : "";
    const accentColor = () => props.primary ? "white" : "black";
    const accentStyle: CSSProperties = { "--overlay-color": accentColor() } as CSSProperties;

    function createRipple(e: any) {
        const height = buttonRef.current!.clientHeight;
        const rect = e.target!.getBoundingClientRect();
        const newX = e.clientX - rect.left;
        const newY = e.clientY - rect.top;

        return <Ripple key={newX} x={newX} y={newY} height={height} color={accentColor()}/>;
    }

    function handleHover(value: boolean) {
        !props.disabled ? setHovered(value) : setHovered(false);
    }

    return <div id={props.id}
                className="shared-components__raised-button"
                style={{display: "inline-block"}}
                onMouseEnter={() => handleHover(true)}
                onMouseLeave={() => handleHover(false)}>
        <button
            ref={buttonRef}
            className={`base shadow ${props.className}`}
            style={!props.disabled ? props.style : {...props.style, ...disabledButtonStyle}}
            onMouseDown={(event) => {
                if (!props.disabled) {
                    const newRip: any = createRipple(event);
                    setRippleArray(rippleArray.concat(newRip).slice());
                }
            }}
            onClick={!props.disabled ? props.onClick : undefined}>
            {props.children}
            {rippleArray}
            <span style={accentStyle} className={`overlay ${hoveredClass()}`} />
        </button>
    </div>;
};

RaisedButton.displayName = "RaisedButton";

interface IRipple {
    x: number;
    y: number;
    height: number;
    color: string;
}

const Ripple: React.FunctionComponent<IRipple> = ({x, y, height, color}) => {
    const spanRef: MutableRefObject<HTMLSpanElement | null> = useRef(null);

    const offsetX = x - height / 2;
    const offsetY = y - height / 2;
    const style: CSSProperties = {
        left: offsetX,
        top: offsetY,
        width: height,
        transform: "scale(0)",
        "--ripple-color": color,
    } as CSSProperties;

    return <span ref={spanRef} className="ripple" style={style}/>;
};
