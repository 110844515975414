import {AxiosResponse} from "axios";
import {saveAs} from "file-saver";
import {Map} from "immutable";
import {mapMeetingNoteDetail, mapMeetingNoteSummary} from "../components/meeting-note/MeetingNoteMapper";
import {MeetingNoteDetail} from "../model/MeetingNoteDetail.model";
import {MeetingNoteSummary} from "../model/MeetingNoteSummary.model";
import {getMimeType} from "../utils/fileUtil";
import {ApiBase} from "./ApiBase";
import {IApiResult} from "./AxiosWealthManagementApi";

class MeetingNotesApiImpl {

    public requestAllMeetingNoteSummariesMap(): Promise<IApiResult<Map<number, MeetingNoteSummary>>> {
        return ApiBase.processGet(`/meeting-note-summaries/all`,
            (response: AxiosResponse): any => {
                return (Object as any).entries(response.data)
                    .reduce(this.meetingNoteSummaryReducer, Map());
            });
    }

    public requestMeetingNoteDetailById(backstopId: number): Promise<IApiResult<MeetingNoteDetail>> {
        return ApiBase.processGet(`/meeting-notes/${backstopId}`, (response: AxiosResponse) => {
            return mapMeetingNoteDetail(response.data);
        });
    }

    public requestMeetingNoteDetailForProduct(productId: number,
                                              backstopId: number): Promise<IApiResult<MeetingNoteDetail>> {
        const url = `/products/${productId}/meeting-notes/${backstopId}`;
        return ApiBase.processGet(url, (response: AxiosResponse) => {
            return mapMeetingNoteDetail(response.data);
        });
    }

    public requestMeetingNoteDetailForManager(managerId: number,
                                              backstopId: number): Promise<IApiResult<MeetingNoteDetail>> {
        const url = `/managers/${managerId}/meeting-notes/${backstopId}`;
        return ApiBase.processGet(url, (response: AxiosResponse) => {
            return mapMeetingNoteDetail(response.data);
        });
    }

    public requestPdf(backstopId: number, fileName: string): Promise<IApiResult<void>> {
        return ApiBase.processGet(`/meeting-notes/${backstopId}/pdf`,
            (response: AxiosResponse) => {
                const blob = new Blob([response.data], {type: getMimeType(fileName)});
                saveAs(blob, fileName);
            },
            {responseType: "arraybuffer"});
    }

    private meetingNoteSummaryReducer = (all: Map<number, MeetingNoteSummary>, [key, value]: (any)[]) => {
        return all.set(parseInt(key, 10), mapMeetingNoteSummary(value));
    };
}

export const MeetingNotesApi = new MeetingNotesApiImpl();
