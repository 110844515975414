export class ManagerResponse {
    public static builder = () => {
        return new ManagerResponseBuilder();
    };

    constructor(public backstopId: number,
                public name: string,
                public website: string,
                public inPortfolio: boolean) {
    }
}

export class ManagerResponseBuilder {
    private mBackstopId: number;
    private mName: string;
    private mWebsite: string;
    private mInPortfolio: boolean;

    public backstopId = (value: number) => {
        this.mBackstopId = value;
        return this;
    };

    public name = (value: string) => {
        this.mName = value;
        return this;
    };

    public website = (value: string) => {
        this.mWebsite = value;
        return this;
    };

    public inPortfolio = (value: boolean) => {
        this.mInPortfolio = value;
        return this;
    };

    public build = () => {
        return new ManagerResponse(
            this.mBackstopId || -1,
            this.mName || "",
            this.mWebsite || "",
            (this.mInPortfolio !== undefined) ? this.mInPortfolio : false,
        );
    };
}

export class ManagerResponseFactory {
    public static create = (backstopId: number, name?: string, website?: string) =>
        ManagerResponse.builder()
            .backstopId(backstopId)
            .name(name || "Manager " + backstopId)
            .website(website || "")
            .inPortfolio(true)
            .build();
}
