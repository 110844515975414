import {AxiosResponse} from "axios";
import {Map} from "immutable";
import {CapitalMarketAssumption} from "../model/thought-leadership/CapitalMarketAssumption";
import {parseDate} from "../utils/dateUtil";
import {ApiBase} from "./ApiBase";
import {IApiResult} from "./AxiosWealthManagementApi";

class CapitalMarketAssumptionsApi {

    public requestCapitalMarketAssumptions(): Promise<IApiResult<Map<number, CapitalMarketAssumption>>> {
            return ApiBase.processGet("/capital-market-assumptions/all",
                (response: AxiosResponse) => {
                return (Object as any).entries(response.data)
                    .reduce(this.capitalMarketAssumptionReducer, Map<number, CapitalMarketAssumption>());
            });
    }

    private mapCapitalMarketAssumption = (data: any) => {
        return new CapitalMarketAssumption(
            data.backstopId,
            data.title,
            data.outlook,
            parseDate(data.effectiveDate),
            data.fileName,
        );
    };

    private capitalMarketAssumptionReducer = (all: Map<number, CapitalMarketAssumption>,
                                              [key, value]: (any)[]) => {
        return all.set(parseInt(key, 10), this.mapCapitalMarketAssumption(value));
    };
}

export default new CapitalMarketAssumptionsApi();
