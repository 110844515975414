import {getQuarterAndYearByDate} from "../../utils/dateUtil";
import {MarketOutlook} from "../../utils/marketOutlookUtils";

export function getYears(documents: MarketOutlook[]) {
    return  documents
        .map((document: MarketOutlook) => document.date.getFullYear())
        .filter((elem, index, self) => index === self.indexOf(elem))
        .sort((item1, item2) => item2 - item1)
        .map((year) => year.toString());
}

export function getYearQuarters(documents: MarketOutlook[], year: number) {
    return documents
        .filter((document) => document.date.getFullYear() === year)
        .map((document) => getQuarterAndYearByDate(document.date).quarter)
        .filter((elem, index, self) => index === self.indexOf(elem))
        .sort()
        .reverse();
}
