import * as React from "react";
import {useState} from "react";
import {ALL_PLAN_TYPES, PLAN_TYPE_FILTER_VALUES} from "../calendar/CalendarUtils";
import {FiltersComponent} from "../common/Filters.component";

export interface IAdminCalendarTopicFilterProps {
    onFilterChange: (plantype: number) => void
}

export const AdminCalendarTopicFilter: React.FunctionComponent<IAdminCalendarTopicFilterProps> = (props: IAdminCalendarTopicFilterProps) => {
    const [planType, setPlanType] = useState(ALL_PLAN_TYPES);

    const handleFilterChange = (e: any) => {
        setPlanType(e.target.value);
        props.onFilterChange(e.target.value);
    };

    const handleClearFilters = () => {
        setPlanType(ALL_PLAN_TYPES);
        props.onFilterChange(ALL_PLAN_TYPES);
    };

    return <div data-testid="admin-calendar-topic-filter__container">
        <div className="admin-calendar-topic-filter__plantype-filter"
             data-testid="admin-calendar-topic-filter__plantype-filter">
            <FiltersComponent
                filters={[
                    {
                        values: PLAN_TYPE_FILTER_VALUES,
                        selectedValue: planType,
                        code: "filter_field",
                        width: 150
                    },
                ]}
                onFilterChangeHandler={handleFilterChange}
                onClearFilters={handleClearFilters}
            />
        </div>
    </div>;
};