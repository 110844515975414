import * as React from "react";
import {OddReport} from "../../model/OddReport.model";
import {formatShortDate} from "../../utils/dateUtil";
import {InViewReportsApi} from "../../api/InViewReportsApi";

export interface IOddReportProps {
    report: OddReport;
    isLast: boolean;
}

export const OddReportComponent: React.FunctionComponent<IOddReportProps> = (props) => {

    const renderSeparator = () => {
        return props.isLast
            ? <div/>
            : <div className="odd-report__separator" data-testid="odd-report__separator">
                <div className="spacer-dash--small"/>
            </div>;
    };

    const downloadReport = (report: OddReport) => {
        InViewReportsApi.downloadReport(report.backstopId, report.fileName);
    };

    const renderUpdateTitle = (report: OddReport) => {
        return <div className="odd-report--top-align">
            <div className="odd-report__title clickable link-16" data-testid="odd-report__title"
                 onClick={() => downloadReport(report)}>
                {report.title}
            </div>
        </div>;
    };

    const getAttachments = (report: OddReport): string => {
        const allAttachments = report.managers.concat(report.products);
        return allAttachments.join(", ");
    };

    const renderAttachments = (report: OddReport) => {
        return <div className={"odd-report__attachments body-14"} data-testid="odd-report__attachments">
            {getAttachments(report)}
        </div>;
    };

    return <div key={props.report.backstopId} data-testid="odd-report-component__container">
        <div className={"odd-report__row"}>
            <div className={"odd-report__item"}>
                <div className={"odd-report__date date-12 strategy-update--top-align"} data-testid="odd-report__date">
                    {formatShortDate(props.report.effectiveDate)}
                </div>
                <div className="meeting-note__icon fa-light fa-memo"/>
                <div className={"odd-report__contents"}>
                    {renderUpdateTitle(props.report)}
                    {renderAttachments(props.report)}
                </div>
            </div>
        </div>
        {renderSeparator()}
    </div>;
};
