import {AxiosResponse} from "axios";
import {ISelectValue} from "../components/common/Select.component";
import {ApiBase} from "./ApiBase";
import {IApiResult} from "./AxiosWealthManagementApi";
import {IPlanInfo} from "../model/ClientsAndPlans.model";

export interface IClient {
    id?: number;
    name: string;
    crmId: number;
    countryId: number;
    clientType: string;
}

export class ClientApi {
    public static saveClient(client: IClient): Promise<IApiResult<ISelectValue>> {
        return  ApiBase.processPostWithResponseBody("/clients", client, (response: AxiosResponse) => {
            return {id: response.data, name: client.name};
        });
    }

    public static requestCurrentClientPlans(): Promise<IApiResult<IPlanInfo[]>> {
        return ApiBase.processGet("/clients/current/plans", (axiosResponse: AxiosResponse): IPlanInfo[] => {
            return axiosResponse.data as IPlanInfo[];
        });
    }

    public static requestCurrentClientName(): Promise<IApiResult<string>> {
        return ApiBase.processGet("/clients/current/name", (axiosResponse: AxiosResponse): string => {
            return axiosResponse.data;
        });
    }

    public static requestClient(clientId: number): Promise<IClient> {
        return ApiBase.processGetUnwrapped(`/clients/${clientId}`,
            (response: AxiosResponse): any => {
                return response.data as IClient;
            });
    }

    public static updateClient(client: IClient): Promise<IApiResult<ISelectValue>> {
        return  ApiBase.processPostWithResponseBody(`/clients/${client.id}`, client, (response: AxiosResponse) => {
            return {id: response.data, name: client.name};
        });
    }
}
