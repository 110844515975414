import * as React from "react";
import {DialogComponent} from "../common/Dialog.component";

export interface ICorsErrorModal {
    corsErrorModalOpen: boolean,
    setCorsErrorModalOpen: (is: boolean) => void
}

export const CorsErrorModal: React.FunctionComponent<ICorsErrorModal> = (props) => {
    return (
        <DialogComponent
            title= "Network Error"
            open={props.corsErrorModalOpen}
            noText="Cancel"
            yesText="Agree"
            onNo={() => props.setCorsErrorModalOpen(false)}
            onYes={() => props.setCorsErrorModalOpen(false)}
            description="Seems like logout API call resulted with CORS error"
            hideNoButton={true}
         />
    );
};
