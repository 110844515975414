import * as React from "react";
import {ComponentMode} from "../release-notes/ReleaseNotesPage";
import Markdown from "react-markdown";

export interface IMarkdownComponentProps {
    text: string;
    mode: ComponentMode;
    onChange: (newText: string) => void;
}

export const MarkdownComponent = (props: IMarkdownComponentProps) => {

    const renderPreviewOrEditFields = () => {
        return props.mode === "preview" ? renderPreviewBox() : renderEditableFields();
    };

    const renderPreviewBox = () => {
        return <Markdown className="markdown-component__preview-markdown"
                         skipHtml={false}>
            {props.text}
        </Markdown>;
    };

    const renderEditableFields = () => {
        return <textarea rows={3} className="markdown-component__edit-box"
                         data-testid="markdown-component__edit-box"
                         autoFocus
                         onChange={(e) => props.onChange(e.target.value)}
                         defaultValue={props.text}/>;
    };

    return renderPreviewOrEditFields();
};
