import * as React from "react";
import {OperationalDueDiligenceApi} from "../../../api/OperationalDueDiligenceApi";
import {OperationalDueDiligenceDocument} from "../../../model/OperationalDueDiligenceDocument.model";
import {formatShortDate} from "../../../utils/dateUtil";

export interface IDueDiligenceDocumentsPropsFromParent {
    productId: number;
    oddProductDocument: OperationalDueDiligenceDocument | null;
}

export type IDueDiligenceDocumentsComponentProps =
    IDueDiligenceDocumentsPropsFromParent;

export const DueDiligenceDocumentsContainer: React.FunctionComponent<IDueDiligenceDocumentsComponentProps>
    = (props) => {

    function renderDocument(document: OperationalDueDiligenceDocument) {
        return <div className="odd-list__document" data-testid="odd-document-container">
                <a className="odd-list__document-title small"
                     onClick={() => download(document)}
                >
                    {document.title}
                </a>
                <div className="odd-list__document-date date-12">
                    {formatShortDate(document.effectiveDate)}
                </div>
            </div>;
    }

    function makeNewFileName(oddDocument: OperationalDueDiligenceDocument) {
        const filename = oddDocument!.filename!.toString();
        return oddDocument.title + filename.substring(filename.lastIndexOf("."));
    }

    function download(oddDocument: OperationalDueDiligenceDocument) {
        OperationalDueDiligenceApi.getOddDocumentDownload (
            props.productId,
            oddDocument.backstopId,
            makeNewFileName(oddDocument),
            true,
        );
    }

    return <div>
        {props.oddProductDocument ? renderDocument(props.oddProductDocument) : null}
    </div>;
};
