import * as React from "react";
import {useEffect, useState} from "react";
import {hasConsultantLevelAccess} from "../../utils/sessionUtil";
import {SharedDocumentApi} from "../../api/SharedDocumentApi";
import {AllClientReports} from "./AllClientReports";
import {ClientReport} from "../../model/ClientReport.model";

export interface IClientReportingHeaderProps {
    currentReport: ClientReport;
    reports: ClientReport[];
    setCurrentReport: (report: ClientReport) => void;
}

export const ClientReportingHeader: React.FunctionComponent<IClientReportingHeaderProps> = (props) => {
    const [showAllReports, setShowAllReports] = useState(false);
    const [displayedReports, setDisplayedReports] = useState<ClientReport[]>([]);

    function getDisplayedReports(): ClientReport[] {
        return hasConsultantLevelAccess()
            ? props.reports
            : props.reports.filter((report) => report.clientEnabled);
    }

    const renderReportHidden = () => {
        const displayedReport = displayedReports.find((report) => report.id === props.currentReport.id);
        const isReportClientEnabled = displayedReport
            ? displayedReport.clientEnabled
            : props.currentReport.clientEnabled;

        return hasConsultantLevelAccess() && !isReportClientEnabled
            ? <div className="client-reporting__report-hidden data"><i>(Report hidden from client users)</i></div>
            : null;
    };

    const renderAllReportsLink = () => {
        return <a className="client-reporting__all-reports-link old-anchor" onClick={displayAllReports}>
                All Reports
            </a>;
    };

    const renderAllReportsComponent = () => {
        return showAllReports
            ? <AllClientReports reports={displayedReports}
                                showOverlay={setShowAllReports}
                                setCurrentReport={props.setCurrentReport}/>
            : null;
    };

    const displayAllReports = () => {
        setShowAllReports(!showAllReports);
    };

    const renderReportFileLink = () => {
        return props.currentReport.documentId
            ? <a className="client-reporting__report-file old-anchor"
                 data-testid="client-reporting__report-file"
                   onClick={() => SharedDocumentApi.getDocumentDownload(
                       props.currentReport.documentId!,
                       props.currentReport.documentFileName!,
                       true)}>
                {props.currentReport.documentTitle}
            </a>
            : null;
    };

    useEffect(() => {
        setShowAllReports(false);

        const updatedReports = getDisplayedReports();
        setDisplayedReports(updatedReports);
    }, [props]);

    return <div>
               <div className="client-reporting__header-container" >
                   <h2 className="client-reporting__report-name">{props.currentReport.reportName}</h2>
                   <span>
                       {renderAllReportsLink()}
                   </span>
               </div>
               <div className="client-reporting__document-container">
                   {renderReportFileLink()}
                   {renderReportHidden()}
               </div>
            {renderAllReportsComponent()}
       </div>;
};