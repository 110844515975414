import {Set as ImmutableSet} from "immutable";
import * as React from "react";
import {AonColors} from "../../../../css/Colors";
import {MeetingNoteSummary} from "../../model/MeetingNoteSummary.model";
import {Card} from "../common/Card";
import {groupItemsByYear} from "../../utils/listUtil";
import {convertMapToArray} from "../../utils/mapUtil";
import RecentUpdateLink from "../manager/RecentUpdateLink.component";
import {FormattedMessage} from "react-intl";

export interface IMeetingNotesPropsFromParent {
    meetingNotes: MeetingNoteSummary[];
    managerId: number;
}

interface IMeetingNotesLocalComponentState {
    expandedIndexes: ImmutableSet<number>;
}

export class MeetingNotes extends React.Component<IMeetingNotesPropsFromParent, IMeetingNotesLocalComponentState> {
    constructor(props: any) {
        super(props);

        this.state = {
            expandedIndexes: ImmutableSet<number>().add(0),
        };
    }

    public render() {
        return (
            <div className="meeting-notes-list paper-style" style={{marginTop: "30px"}}>
                <h2 className="meeting-notes__title gray">
                    <FormattedMessage id="manager.meeting-notes" defaultMessage="Meeting Notes" />
                </h2>
                {this.renderYearSections()}
            </div>
        );
    }

    private renderYearSections() {
        if (this.props.meetingNotes.length === 0) {
            return <div id="no-meeting-notes" className="meeting-notes__text">
                <FormattedMessage
                    id="research-product.no-updates"
                    defaultMessage="There are no updates at this time."
                />
            </div>;
        }

        const groups = this.groupedMeetingNotes();
        return groups.map((yearToMeetingNotes, yearIndex) => (
            (
                <div id={`meeting-notes-${yearToMeetingNotes[0]}`}
                     className="meeting-notes-year"
                     key={yearToMeetingNotes[0]}
                     data-testid="meeting-notes-year"
                >
                    <Card
                        className={`meeting-notes-cards ${this.state.expandedIndexes.includes(yearIndex) ? "open" : "close"}`}
                        expanded={this.state.expandedIndexes.includes(yearIndex)}
                        onExpandChange={() =>
                            this.handleExpandChange(yearIndex)}
                        header={<h5>{yearToMeetingNotes[0]}</h5>}
                        showExpandableButton={true}
                        childrenContainerStyle={{backgroundColor: AonColors.ActualWhite}}
                    >
                        {this.renderMeetingNotes(yearToMeetingNotes[1])}
                    </Card>
                </div>
            )
        ));
    }

    private renderMeetingNotes(meetingNotes: MeetingNoteSummary[]) {
        return meetingNotes.map((meetingNote, index) => (
            (
                <RecentUpdateLink
                    recentUpdate={meetingNote}
                    isLast={index === meetingNotes.length - 1}
                    key={meetingNote.backstopId}
                    managerId={this.props.managerId}
                />
            )
        ));
    }

    private groupedMeetingNotes(): any[][] {
        return convertMapToArray(groupItemsByYear(this.props.meetingNotes, (item) => item.date));
    }

    private handleExpandChange(index: number) {
        if (!this.state.expandedIndexes.includes(index)) {
            this.setState({
                expandedIndexes: this.state.expandedIndexes.add(index),
            });
        } else {
            this.setState({
                expandedIndexes: this.state.expandedIndexes.remove(index),
            });
        }
    }
}
