import * as React from "react";
import {useEffect, useState} from "react";
import {CalendarApi, ICalendarMeeting} from "../../api/CalendarApi";
import {CALENDAR_VIEW} from "./CalendarPage";
import {CalendarTopicDetails} from "../../model/CalendarTopicDetails.model";
import {hasConsultantLevelAccess} from "../../utils/sessionUtil";
import {Tooltip} from "@mui/material";
import {getErrorSpecificClientMessage, throwErrorOnNullOrUndefined} from "../../utils/errorUtil";
import {formatDateAndTime} from "../../utils/dateUtil";
import {setHeaderNotification} from "../base/header/HeaderActions";
import {NotificationTypes} from "../base/header/HeaderReducer";
import {clearClientReports} from "../power-bi-reporting/PowerBiReporting.actions";
import {useDispatch} from "react-redux";
import {IApiResult} from "../../api/AxiosWealthManagementApi";
import {LoadingSpinner} from "../icons/LoadingSpinner.component";
import {ApiError} from "../../model/ApiError.model";
import {SharedDocumentApi} from "../../api/SharedDocumentApi";
import {QuillEditor} from "../common/QuillEditor.component";
import {CalendarFrequencyMap, formatQuarterAndYear,} from "./CalendarUtils";
import {Map} from "immutable";
import {getLastAndNextReviewDates} from "./fn_getLastAndNextReviewDates";
import {CalendarTopicLinks} from "./CalendarTopicLinks";
import {showCalendarMeetingEnabled} from "../../utils/envUtil";
import {CalendarMeetingDetails} from "../../model/CalendarMeetingDetails.model";

export interface ICalendarMeetingTopicProps {
    topicId: number;
    onViewChange: (meetingOrTopicId: number | undefined, view: CALENDAR_VIEW) => void;
}

export const EDITOR_PLACEHOLDER = "Only consultant users will be able to view your comments";

export const CalendarTopic: React.FunctionComponent<ICalendarMeetingTopicProps> = (props) => {
    const [meetingDetails, setMeetingDetails] = useState<CalendarMeetingDetails | undefined>(undefined);
    const [topicDetails, setTopicDetails] = useState<CalendarTopicDetails | undefined>(undefined);
    const [allCalendarMeetingsWithTopics, setAllCalendarMeetingsWithTopics] =
        useState<Map<string, ICalendarMeeting> | undefined>(undefined);
    const [editingName, setEditingName] = useState(false);
    const [editingDescription, setEditingDescription] = useState(false);
    const [updatedName, setUpdatedName] = useState("");
    const [updatedDescription, setUpdatedDescription] = useState("");

    const [selectedFile, setSelectedFile] = useState<File | undefined>(undefined);
    const [fileUploading, setFileUploading] = useState<boolean>(false);
    const [selectionError, setSelectionError] = useState<boolean>(false);
    const [emptyCommentError, setEmptyCommentError] = useState<boolean>(false);
    const [htmlContent, setHtmlContent] = useState<string>("");
    const [value, setValue] = useState<any>("");

    const dispatch = useDispatch();

    const getAllCalendarMeetingsWithTopics = () => {
        CalendarApi.requestCalendarMeetingsWithTopics()
            .then((response) => {
                setAllCalendarMeetingsWithTopics(response);
            });
    };

    const refreshTopicDetails = () => {
        CalendarApi.requestCalendarTopicDetails(props.topicId)
            .then((topicResponse) => {
                setTopicDetails(topicResponse);
            });
    };

    useEffect(() => {
        CalendarApi.requestCalendarTopicDetails(props.topicId!)
            .then((topicResponse) => {
                CalendarApi.requestCalendarMeetingDetails(topicResponse.calendarClientMeetingId)
                    .then((meetingResponse) => {
                        getAllCalendarMeetingsWithTopics();
                        setTopicDetails(topicResponse);
                        setMeetingDetails(meetingResponse);
                    });
            })
            .catch(() => {
                // nothing to do
            });
    }, [props.topicId]);

    const saveDescription = () => {
        CalendarApi.updateTopicDescription(props.topicId, updatedDescription)
            .then(() => {
                refreshTopicDetails();
            });
        setUpdatedDescription("");
        setEditingDescription(false);
    };

    const saveName = () => {
        CalendarApi.updateTopicName(props.topicId, updatedName)
            .then(() => {
                refreshTopicDetails();
            });
        setUpdatedName("");
        setEditingName(false);
    };

    const renderMeetingBreadCrumbs = () => {
        return showCalendarMeetingEnabled() && meetingDetails
            ?  <p className="clickable back-to-meeting breadcrumb__item"
                   onClick={() =>
                       props.onViewChange(meetingDetails?.id, CALENDAR_VIEW.MEETING)}>
                {`Q${meetingDetails?.quarter}-${meetingDetails?.year} Meeting`}
               </p>
            : null;
    };

    const renderBreadCrumbs = () => {
        return <div className="calendar__breadcrumbs">
                    <p className="clickable back-to-calendar breadcrumb__item"
                       onClick={() => props.onViewChange(undefined, CALENDAR_VIEW.CALENDAR)}>
                        Calendar
                    </p>
                    {renderMeetingBreadCrumbs()}
                    <p className="breadcrumb__item">
                        Topic Content
                    </p>
                </div>;
    };

    const renderName = () => {
        return hasConsultantLevelAccess()
            ? editingName
                ? <div className="topic__name-description-wrapper">
                        <textarea rows={2} className="topic__edit-name-textarea" autoFocus
                                  onChange={(e) => setUpdatedName(e.target.value)}
                                  defaultValue={topicDetails?.name}/>
                    <p className="topic__edit-name-save-button clickable" onClick={saveName}>Save</p>
                    <p className="topic__edit-name-cancel-button clickable"
                       onClick={() => setEditingName(false)}>Cancel</p>
                </div>
                : <div className="topic__name-description-wrapper">
                    <p className="topic__display-name">{topicDetails?.name}</p>
                    <Tooltip title="">
                        <p className="topic__edit-name-pencil fas fa-pencil edit-pencil clickable"
                           onClick={() => setEditingName(true)}/>
                    </Tooltip>
                </div>
            : <div className="topic__name-description-wrapper">
                <p className="topic__display-name">{topicDetails?.name}</p>
            </div>;
    };

    const renderDescription = () => {
        return hasConsultantLevelAccess()
            ? editingDescription
                ? <div className="topic__name-description-wrapper">
                        <textarea rows={7} className="topic__edit-description-textarea" autoFocus
                                  onChange={(e) => setUpdatedDescription(e.target.value)}
                                  defaultValue={topicDetails?.description}/>

                    <p className="topic__edit-description-save-button clickable" onClick={saveDescription}>Save</p>
                    <p className="topic__edit-description-cancel-button clickable"
                       onClick={() => setEditingDescription(false)}>Cancel</p>
                </div>
                : <div className="topic__name-description-wrapper">
                    <p className="topic__display-description">{topicDetails?.description}</p>
                    <Tooltip title="">
                        <p className="topic__edit-description-pencil fas fa-pencil edit-pencil clickable"
                           onClick={() => setEditingDescription(true)}/>
                    </Tooltip>
                </div>
            : <p className="topic__display-description">{topicDetails?.description}</p>;
    };

    const renderFrequencyLabel = () => {
        return topicDetails &&
            <p className="topic__display-frequency">
                <span>Frequency </span>{CalendarFrequencyMap.get(topicDetails?.frequencyId)}</p>;
    };

    const renderReviewDates = () => {
        if (topicDetails && allCalendarMeetingsWithTopics) {
            const {lastReview, nextReview} =
                getLastAndNextReviewDates(allCalendarMeetingsWithTopics, topicDetails);
            return <div className="topic__review-dates">
                <p className="topic__last-review-date">
                    <span>Last Reviewed </span>
                    {lastReview
                        ? <a onClick={() => props.onViewChange(lastReview.id, CALENDAR_VIEW.TOPIC)}>
                            {formatQuarterAndYear(lastReview.quarter, lastReview.year)}
                        </a>
                        : "n/a"
                    }
                </p>
                <p className="topic__next-review-date">
                    <span>Next Reviewed </span>
                    {nextReview
                        ? <a onClick={() => props.onViewChange(nextReview.id, CALENDAR_VIEW.TOPIC)}>
                            {formatQuarterAndYear(nextReview.quarter, nextReview.year)}
                        </a>
                        : "n/a"
                    }
                </p>
            </div>;
        }
        return null;
    };

    const renderTemplateLinksSection = () => {
        return topicDetails && <CalendarTopicLinks topicLinks={topicDetails?.templateLinks}/>;
    };

    const handleFileSelection = (e: { target: { files: FileList | null, value: any } }) => {
        resetFileSelection();
        const targetFile = e.target.files;
        if (!targetFile || targetFile.length < 1) return;
        setSelectedFile(targetFile[0]);
        setValue(e.target.value);
    };

    const resetFileSelection = () => {
        setFileUploading(false);
        setSelectedFile(undefined);
        setValue("");
        setSelectionError(false);
    };

    const handleFileUpload = () => {
        if (!selectedFile) {
            setSelectionError(true);
            return;
        }
        setFileUploading(true);

        const formData = new FormData();
        formData.append("file", throwErrorOnNullOrUndefined(selectedFile));
        formData.append("title", selectedFile!.name);

        const processSuccess = () => {
            refreshTopicDetails();
            dispatch(setHeaderNotification(
                {message: "File uploaded successfully!", notificationType: NotificationTypes.SUCCESS},
                5000));
            dispatch(clearClientReports());
            resetFileSelection();
        };

        const processFailure = (errorNumber: number) => {
            setFileUploading(false);
            dispatch(
                setHeaderNotification({
                    message: `File upload failed${getErrorSpecificClientMessage(errorNumber)}`,
                    notificationType: NotificationTypes.FAILURE
                }, 5000)
            );
            resetFileSelection();
        };

        CalendarApi.uploadCalendarTopicDocument(topicDetails!.id, formData)
            .then((response: IApiResult<boolean>) => {
                response.data
                    ? processSuccess()
                    : processFailure(response.error ? response.error.errorNumber : -1);
            }).catch((error: ApiError) => {
            processFailure(error.errorNumber);
        });
    };

    const renderFilesSection = () => {
        return <div className="topic__files-section">
            <p className="topic__files-section-header">
                Files
            </p>
            <div className="topic__upload-spinner">
                {fileUploading && <LoadingSpinner/>}
            </div>
            {renderFileUploadControls()}
            {selectionError && <span className="common__required-caption-red">
                <i>Required</i></span>}
            {renderUploadedTopicDocuments()}
        </div>;
    };

    const renderFileUploadControls = () => {
        return hasConsultantLevelAccess()
            ? <div className="topic__file-upload-controls">
                <input
                    id="topic__file-upload-input"
                    type="file"
                    multiple={false}
                    onChange={handleFileSelection}
                    value={value}
                    disabled={fileUploading}
                />
                <button className="topic__file-upload-button clickable"
                        disabled={fileUploading}
                        onClick={handleFileUpload}>+ Upload
                </button>
            </div>
            : null;
    };

    function getDocument(documentId: number, filename: string) {
        SharedDocumentApi.getDocumentDownload(documentId, filename, true);
    }

    const renderUploadedTopicDocuments = () => {
        const sortedTopicDocuments = topicDetails?.topicDocuments.sort((a, b) =>
            new Date(b.createdAt).getTime() - new Date(a.createdAt).getTime());

        return <table className="topic__documents-display-table">
            <tbody>
            {sortedTopicDocuments?.map((doc, idx) => {
                return <tr key={idx}>
                    <td className="topic__document-fileName">
                        <a className="topic__document__row__title"
                           onClick={
                               () => getDocument(doc.documentId, doc.fileName)
                           }>
                            {doc.fileName}
                        </a>
                    </td>
                    <td className="topic__document-userEmail">{doc.userEmail}</td>
                    <td className="topic__document-createdDate">{formatDateAndTime(doc.createdAt)}</td>
                    {renderRemoveButton(doc.documentId)}
                </tr>;
            })}
            </tbody>
        </table>;
    };

    const renderRemoveButton = (documentId: number) => {
        return hasConsultantLevelAccess() ?
            <td className="topic__document-remove clickable" onClick={() => removeDocument(documentId)}>
                Remove
            </td>
            : null;
    };

    const removeDocument = (documentId: number) => {
        CalendarApi.deleteCalendarTopicDocument(documentId).then(() => {
            refreshTopicDetails();
        });
    };

    const removeComment = (commentId: number) => {
        CalendarApi.deleteCalendarTopicComment(commentId).then(() => {
            refreshTopicDetails();
        });
    };

    const frontAndBackSpaces = /^([\s]*<p>[\s<br>]*<\/p>)*|(<p>[\s<br>]*<\/p>[\s]*)*$/g;
    const submitComment = () => {
        const newContent = htmlContent.replace(frontAndBackSpaces, '').trim();

        if (newContent.length < 1) {
            setEmptyCommentError(true);
            return;
        }

        setEmptyCommentError(false);

        CalendarApi.addCalendarTopicComment(topicDetails!.id, {bodyHtml: newContent})
            .then((response: IApiResult<boolean>) => {
                throwErrorOnNullOrUndefined(response.data);
                setHtmlContent("");
                refreshTopicDetails();
            }).catch(() => {
            dispatch(setHeaderNotification(
                {message: "Failed to add comment!", notificationType: NotificationTypes.FAILURE},
                5000));
        });
    };

    const renderTopicComments = () => {
        const sortedTopicComments = topicDetails?.topicComments.sort((a, b) =>
            new Date(b.createdAt).getTime() - new Date(a.createdAt).getTime());

       return sortedTopicComments && sortedTopicComments.length > 0
           ? <table className="topic__comments-display-table">
               <thead>
               <tr>
                   <th>Comments</th>
                   <th>Created By</th>
                   <th>Date</th>
                   <th/>
               </tr>
               </thead>
                <tbody>
                {sortedTopicComments?.map((comment, idx) => {
                    return <tr key={idx}>
                        <td className="topic__comment-body" dangerouslySetInnerHTML={{__html: comment.bodyHtml}}/>
                        <td className="topic__comment-userEmail">{comment.userEmail}</td>
                        <td className="topic__comment-createdDate">{formatDateAndTime(comment.createdAt)}</td>
                        <td className="topic__comment-remove clickable" onClick={() => removeComment(comment.id)}>
                            Remove
                        </td>
                    </tr>;
                })}
                </tbody>
            </table>
           : null;
    };

    const renderCommentsSection = () => {
        return hasConsultantLevelAccess()
            ? <div className="topic__comments-section">
                <p className="topic__comments-section-header section-header">
                    Comments
                </p>

                <div className="topic__comments-editor-container">
                    <QuillEditor
                        htmlContent={htmlContent}
                        placeHolder={EDITOR_PLACEHOLDER}
                        readOnly={false}
                        toolbarEnabled={false}
                        onChange={(content) => {
                        setHtmlContent(content);
                    }}/>
                    <button onClick={submitComment} className="topic__add-comment-button clickable">
                        + Add
                    </button>
                </div>
                {emptyCommentError ?
                    <span className="common__required-caption-red">
                        <i>Required</i>
                    </span>
                    : null
                }
                <div className="topic__comments-display">
                    {renderTopicComments()}
                </div>
            </div>
            : null;
    };

    return <div className="calendar-meeting-topic">
        {renderBreadCrumbs()}
        <div className="calendar-meeting-topic__name-and-description">
            {renderName()}
            {renderDescription()}
        </div>
        <div className="topic__frequency-review-dates">
            {renderFrequencyLabel()}
            {renderReviewDates()}
        </div>
        {renderTemplateLinksSection()}
        {renderFilesSection()}
        {renderCommentsSection()}
    </div>;
};