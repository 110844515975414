
export interface IStrategyUpdate {
    backstopId: number;
    title: string;
    date: Date;
    type: StrategyUpdateType;
    urlPrefix: string;
    managerIds: number[];
    productIds: number[];
    hasProductAttachment: boolean | undefined;
    fileName: string;
}

export enum StrategyUpdateType {
    MEETING_NOTE = "meeting note",
    FLASH_REPORT = "flash report",
}
