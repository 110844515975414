import * as React from "react";
import {useEffect, useState} from "react";
import {hasConsultantLevelAccess, isAtcAdminUser, isClientUser, isThirdPartyUser} from "../../utils/sessionUtil";
import {connect, useDispatch} from "react-redux";
import {
    calendarCurrentYearSelector,
    hasCalendarEnabledForClients,
    hasCalendarEnabledForConsultants, planTypeIdSelector
} from "../../../mainReducerMapSelectors";
import {ErrorComponent} from "../base/Error.component";
import {CalendarApi, ICalendarTemplateTopic} from "../../api/CalendarApi";
import {byName} from "../../utils/listUtil";
import CalendarDragDrop from "./CalendarDragDrop";
import CalendarForClient from "./CalendarForClient";
import {CalendarMeeting} from "./CalendarMeeting";
import {CalendarTopic} from "./CalendarTopic";
import {clearCalendarData, setCalendarCurrentYear} from "./Calendar.actions";
import {getCurrentYear} from "../../utils/dateUtil";
import {NoAccessNotification} from "../base/header/NoAccessNotification";
import {SelectComponent} from "../common/Select.component";
import {PLAN_TYPE, PLAN_TYPE_VALUES} from "./CalendarUtils";
import {RaisedButton} from "../common/RaisedButton";
import {prominentButtonStyle} from "../common/buttonStyles";
import PlanApi from "../../api/PlanApi";
import {requestPlanSettings} from "../root/rootActions";
import {IApplicationRootState} from "../../../applicationState";

export interface ICalendarPageProps {
    calendarEnabledForConsultant: boolean;
    calendarEnabledForClients: boolean;
    calendarCurrentYear: number | undefined;
    planTypeId: PLAN_TYPE | null;
}

export enum CALENDAR_VIEW {
    CALENDAR = 1,
    MEETING = 2,
    TOPIC = 3
}

export interface ICalendarView {
    view: CALENDAR_VIEW,
    meetingOrTopicId?: number
}

export const CalendarPage: React.FunctionComponent<ICalendarPageProps> = (props) => {
    const [sliderClass, setSliderClass] = useState("");
    const [templateTopics, setTemplateTopics] = useState<ICalendarTemplateTopic[]>([]);
    const [calendarView, setCalendarView] = useState<ICalendarView>({view: CALENDAR_VIEW.CALENDAR});
    const [assetTypeId, setAssetTypeId] = useState<number | null>(null);
    const [submitClicked, setSubmitClicked] = useState<boolean>(false);

    const dispatch = useDispatch();

    const isCalendarEnabled = () => {
        if (isThirdPartyUser() || isAtcAdminUser()) return false;

        return hasConsultantLevelAccess()
            ? props.calendarEnabledForConsultant
            : isClientUser() && props.calendarEnabledForClients && props.calendarEnabledForConsultant;
    };

    useEffect(() => {
        if (isCalendarEnabled() && props.planTypeId) {
            CalendarApi.requestTemplateTopicsBasedOnPlanType()
                .then((response) => {
                    const sortedResponse = [...response]!
                        .sort(byName);
                    setTemplateTopics(sortedResponse!);
                });
            return () => setCalendarCurrentYear(dispatch, getCurrentYear());
        }
    }, [props.planTypeId]);

    const onViewChange = (meetingOrTopicId: number | undefined, view: CALENDAR_VIEW) => {
        const newSliderClass = sliderClass !== "slideLeft" ? "slideLeft" : "slideRight";
        setSliderClass(newSliderClass);
        switch (view) {
            case (CALENDAR_VIEW.CALENDAR):
                setCalendarView({view, meetingOrTopicId: undefined});
                clearCalendarData(dispatch);
                break;
            case (CALENDAR_VIEW.MEETING):
                setCalendarView({view, meetingOrTopicId});
                break;
            case (CALENDAR_VIEW.TOPIC):
                setCalendarView({view, meetingOrTopicId});
                break;
        }
    };

    const renderCalendar = () => {
        return isClientUser()
            ? <CalendarForClient
                currentYear={props.calendarCurrentYear}
                onViewChange={onViewChange}
            />
            : <CalendarDragDrop
                currentYear={props.calendarCurrentYear}
                templateTopics={templateTopics}
                onViewChange={onViewChange}
            />;
    };

    const renderCalendarView = () => {
        switch (calendarView.view) {
            case (CALENDAR_VIEW.CALENDAR):
                return <div className={`calendar-page__slider ${sliderClass}`}>
                    <div className="calendar__container">
                        {renderCalendar()}
                    </div>
                </div>;
            case (CALENDAR_VIEW.MEETING):
                return <div className={`calendar-page__slider ${sliderClass}`}>
                    <CalendarMeeting
                        meetingId={calendarView.meetingOrTopicId!}
                        onViewChange={onViewChange}
                    />
                </div>;
            case (CALENDAR_VIEW.TOPIC):
                return <div className={`calendar-page__slider ${sliderClass}`}>
                    <CalendarTopic
                        topicId={calendarView.meetingOrTopicId!}
                        onViewChange={onViewChange}
                    />
                </div>;
            default:
                return null;
        }
    };

    const renderClientAccessNotification = () => {
        return (hasConsultantLevelAccess()
            && (props.calendarEnabledForConsultant && !props.calendarEnabledForClients))
            ? <div className="calendar__notification">
                <NoAccessNotification
                    message={"The Calendar page is currently hidden from client users of this plan."}/>
            </div>
            : null;
    };

    const renderAssetTypeSelectionView = () => {
        const handleSave = () => {
            setSubmitClicked(true);
            if(assetTypeId !== null){
                PlanApi.savePlanTypeId(assetTypeId).then(r => {
                    dispatch(requestPlanSettings());
                });
            }
        };

        return <div className="calendar-page__asset-type-selection">
            <SelectComponent
                id="calendar-page__asset-type"
                values={PLAN_TYPE_VALUES}
                selected={assetTypeId}
                width={280}
                handleChange={(e: any) => setAssetTypeId(e.target.value)}
                placeholder="Select An Asset Type..."
                renderRequired={true}
                submitClicked={submitClicked}
            />
            <div className="calendar-page__create-calendar-button">
                <RaisedButton className="calendar-page__asset-type-save-button"
                              style={prominentButtonStyle}
                              primary={true}
                              onClick={handleSave}>
                    Create Calendar
                </RaisedButton>
            </div>
        </div>;
    };

    return <div className="new-common-styles page-container">
        {
            isCalendarEnabled()
                ? <div className="calendar__page">
                    {renderClientAccessNotification()}
                    <div className="calendar__header">
                        <h1 className="calendar__header-title">Calendar</h1>
                    </div>
                    <div className="calendar__content">
                        {props.planTypeId && renderCalendarView()}
                        {!props.planTypeId && renderAssetTypeSelectionView()}
                    </div>
                </div>
                : <ErrorComponent/>
        }
    </div>;

};

export const mapStateToProps = (state: IApplicationRootState): ICalendarPageProps => {
    return {
        calendarEnabledForConsultant: hasCalendarEnabledForConsultants(state),
        calendarEnabledForClients: hasCalendarEnabledForClients(state),
        calendarCurrentYear: calendarCurrentYearSelector(state),
        planTypeId: planTypeIdSelector(state)
    };
};

const connectedComponent = connect<ICalendarPageProps>(mapStateToProps)(CalendarPage);

export default connectedComponent;
