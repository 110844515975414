import {Map} from "immutable";
import {Action, handleActions} from "redux-actions";
import {CapitalMarketAssumption} from "../../model/thought-leadership/CapitalMarketAssumption";
import {AllCapitalMarketAssumptionsActions} from "./AllCapitalMarketAssumptionsActions";

export interface IAllCapitalMarketAssumptionsStore {
    allCapitalMarketAssumptions: Map<number, CapitalMarketAssumption>;
    areAllCapitalMarketAssumptionsLoaded: boolean;
    areAllCapitalMarketAssumptionsLoading: boolean;
}

export const initialState: IAllCapitalMarketAssumptionsStore = {
    allCapitalMarketAssumptions: Map<number, CapitalMarketAssumption>(),
    areAllCapitalMarketAssumptionsLoaded: false,
    areAllCapitalMarketAssumptionsLoading: false,
};

const allCapitalMarketAssumptionsRequested = (store: IAllCapitalMarketAssumptionsStore):
    IAllCapitalMarketAssumptionsStore => {
    return {
        ...store,
        areAllCapitalMarketAssumptionsLoading: true,
    };
};

const allCapitalMarketAssumptionsReceived = (store: IAllCapitalMarketAssumptionsStore,
                                             action: Action<Map<number, CapitalMarketAssumption>>):
    IAllCapitalMarketAssumptionsStore => {
    return {
        ...store,
        allCapitalMarketAssumptions: action.payload!,
        areAllCapitalMarketAssumptionsLoading: false,
        areAllCapitalMarketAssumptionsLoaded: true,
    };
};

const reducerMap = {
    [AllCapitalMarketAssumptionsActions.ALL_CAPITAL_MARKET_ASSUMPTIONS_REQUESTED]: allCapitalMarketAssumptionsRequested,
    [AllCapitalMarketAssumptionsActions.ALL_CAPITAL_MARKET_ASSUMPTIONS_RECEIVED]: allCapitalMarketAssumptionsReceived,
};

type Payloads = Map<number, CapitalMarketAssumption>;
export default handleActions<IAllCapitalMarketAssumptionsStore, Payloads>(reducerMap, initialState);
