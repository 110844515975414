import * as React from "react";
import {useState} from "react";
import {useEffect} from "react";

export const TimerComponent: React.FunctionComponent<{isRunning: boolean}> = (props) => {
    const [stateInterval, setStateInterval] = useState<any>(undefined);
    const [secondsCount, setSecondsCount] = useState<number>(0);
    let count = 0;

    const updateTimer = () => {
        count++;
        setSecondsCount(count);
    };

    const resetTimer = () => {
        count = 0;
        setSecondsCount(0);
    };

    const clearTimer = () => {
        if (stateInterval) {
            clearInterval(stateInterval);
        }
    };

    useEffect(() => {
        let intervalId: any;
        if (props.isRunning) {
            resetTimer();
            intervalId = setInterval(updateTimer, 1000);
            // console.error("=============> intervalId: ", intervalId);
            setStateInterval(intervalId);
        }
        else {
            clearTimer();
        }
    }, [props.isRunning]);

    return <div>
        <span id="timer-component__elapsed-label">{`Elapsed seconds: ${secondsCount}`}</span>
    </div>;
};