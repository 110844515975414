import * as React from "react";

export interface IPotentialRiskAreasCardProps {
    title: string;
    total: number;
    totalPercent: string;
    pctOfTotalManager: string;
    pctOfTotalStrategy: string;
}

export const PotentialRiskAreasCard: React.FunctionComponent<IPotentialRiskAreasCardProps> = (props) => {
    return <div className={`potential-risk-areas-card potential-risk-areas-card__${props.title.replace(" ", "-").toLowerCase()}`}>
        <p className="potential-risk-areas-card__title bold">{props.title}</p>
        <div className="potential-risk-areas-card__totals">
            <p className="potential-risk-areas-card__total-percent bold">{props.totalPercent}%</p>
            <p className="potential-risk-areas-card__total">{props.total} Risk Areas</p>
        </div>
        <div className="potential-risk-areas-card__percents">
            <span className="potential-risk-areas-card__percent-manager"><p>{props.pctOfTotalManager}% Manager</p></span>
            <span className="potential-risk-areas-card__percent-strategy"><p>{props.pctOfTotalStrategy}% Strategy</p></span>
        </div>
    </div>;
};