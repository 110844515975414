import {Map as ImmutableMap} from "immutable";
import * as React from "react";
import {useEffect, useState} from "react";
import {connect, useDispatch} from "react-redux";
import {ClientsAndPlans, IClientInfo} from "../../model/ClientsAndPlans.model";
import {ProductSummary} from "../../model/product/ProductSummary";
import {prominentButtonStyle} from "../common/buttonStyles";
import {RaisedButton} from "../common/RaisedButton";
import {byName} from "../../utils/listUtil";
import {getSuccessData} from "../common/commonStates";
import {SelectComponentReact} from "../common/ReactSelectComponent";
import {adminClientsWithPlansPair, allProductsRequestPair} from "../common/RequesterPairs";
import {ISelectValue} from "../common/Select.component";
import {withVerifyAccess} from "../common/withVerifyAccess";
import adminActions from "./adminActions";
import {AdminCreateClientComponent} from "./AdminCreateClient.component";
import {AdminCreatePlanComponent} from "./AdminCreatePlan.component";
import {adminCurrentClientInner} from "./AdminReducer";
import {UserTypeEnum} from "../../model/UserInfo.model";
import {multipleApiRequesterWrapper} from "../common/MultipleApiRequesterWrapper";
import {IApplicationRootState} from "../../../applicationState";

export interface IAdminCreateClientPlanProps {
    adminClientInfoState: ClientsAndPlans;
    productUniverseSummaries: ImmutableMap<number, ProductSummary>;
    currentClient: ISelectValue | undefined;
}

export enum AdminMode {
    None,
    Client,
    Plan,
}

export const AdminCreateClientPlan: React.FunctionComponent<IAdminCreateClientPlanProps> = (props) => {
    const [mode, setMode] = useState<AdminMode>(AdminMode.None);

    const dispatch = useDispatch();

    useEffect(() => {
        setMode(props.currentClient
            ? AdminMode.Plan
            : mode === AdminMode.Client ? AdminMode.Client : AdminMode.None);
    }, [props.currentClient]);

    const resetMode = () => {
        dispatch(adminActions.resetCurrentClient());
        setMode(AdminMode.None);
    };

    const clients: ISelectValue[] = props.adminClientInfoState
        .map((clientInfo: IClientInfo) => ({id: clientInfo.id, name: clientInfo.name}))
        .sort(byName)
        .valueSeq()
        .toArray();

    function renderClientDropdown() {
        const handleChange = (e: ISelectValue) => {
            dispatch(adminActions.setCurrentClient(e));
            setMode(AdminMode.Plan);
        };

        return <SelectComponentReact
            className="admin-client-plan__clients-dropdown"
            menuIsOpen={undefined}
            id={"clients-dropdown"}
            options={clients}
            value={props.currentClient ? props.currentClient : null}
            onChange={handleChange}
            submitClicked={false}
            getOptionLabel={(option: ISelectValue) => option.name}
            getOptionValue={(option: ISelectValue) => option.id!.toString(10)}
            renderRequired={false}
            placeholder="Select Client"
            stylesObject={{width: "100%"}}
        />;
    }

    function renderAddClientButton() {
        return <RaisedButton
            className="admin-client-plan__add-client-button"
            style={prominentButtonStyle}
            primary={true}
            onClick={() => {
                setMode(AdminMode.Client);
                dispatch(adminActions.resetCurrentClient());
            }}
        >
            Create New Client
        </RaisedButton>;
    }

    function renderCreateClientComponent() {
        if (mode !== AdminMode.Client) {
            return null;
        }

        return <AdminCreateClientComponent
            onCancel={resetMode}
        />;
    }

    function renderCreatePlanComponent() {
        if (mode !== AdminMode.Plan) {
            return null;
        }

        return <AdminCreatePlanComponent
            products={props.productUniverseSummaries}
            selectedClientId={props.currentClient ? parseInt(props.currentClient.id as string, 10) : null}
            onCancel={resetMode}
            hasPortfolioAccess={false}
        />;
    }

    return <div data-testid="admin-create-client-plan__container">
        <div>
            <p className="large">
                Select a client below, or click Create New Client, to add a new plan
            </p>
        </div>
        <div className="small bold">Client</div>
        <div className="admin-client-plan__client-select-container">
            {renderClientDropdown()}
            {renderAddClientButton()}
        </div>
        {renderCreateClientComponent()}
        {renderCreatePlanComponent()}
    </div>;
};

export const mapStateToProps = (state: IApplicationRootState): IAdminCreateClientPlanProps => {
    return {
        adminClientInfoState: getSuccessData(state.adminState!.adminClientInfoState)!,
        productUniverseSummaries: getSuccessData(state.allProductsStore!.productUniverseSummaries)!,
        currentClient: adminCurrentClientInner(state.adminState),
    };
};

const connectedAdminClientPlan =
    connect<IAdminCreateClientPlanProps>(mapStateToProps)(AdminCreateClientPlan);

const withRequester = multipleApiRequesterWrapper(
    connectedAdminClientPlan,
    [
        adminClientsWithPlansPair,
        allProductsRequestPair(),
    ],
);

export default withVerifyAccess(withRequester, UserTypeEnum.ADMIN);
