import {Dispatch} from "redux";
import {Action} from "redux-actions";
import {ISelectValue} from "../common/Select.component";

export enum PortfolioActions {
    SET_TREE_SELECTED_DATE = "SET_TREE_SELECTED_DATE",
    UPDATE_TREE_EXPANDED_ROWS = "UPDATE_TREE_EXPANDED_ROWS",
    SET_SELECTED_PRODUCT = "SET_SELECTED_PRODUCT",
}

export const updateTreeExpandedRowsAction = (payload: number[]) => {
    return (dispatch: Dispatch<Action<number[]>>) => {
       dispatch ({type: PortfolioActions.UPDATE_TREE_EXPANDED_ROWS, payload});
    };
};

export const setTreeDateAction = (payload: ISelectValue) => {
    return (dispatch: Dispatch<Action<ISelectValue>>) => {
        dispatch ({type: PortfolioActions.SET_TREE_SELECTED_DATE, payload});
    };
};

export const setTreeSelectedProductAction = (payload: number) => {
    return (dispatch: Dispatch<Action<number>>) => {
        dispatch ({type: PortfolioActions.SET_SELECTED_PRODUCT, payload});
    };
};