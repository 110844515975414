import {compareDates} from "./dateUtil";

export const sortCaseInsensitive = (list: string[]): string[] => {
    return list.slice().sort((a, b) => {
        return a.trim().toLowerCase().localeCompare(b.trim().toLowerCase());
    });
};

export function groupItemsByYear<T>(items: T[], getDate: (item: T) => Date | null): Map<number, T[]> {
    const map = new Map<number, T[]>();

    items
        .sort((item1, item2) => compareDates(getDate(item1), getDate(item2)))
        .forEach((item: T) => {
            const year = getDate(item) ? getDate(item)!.getFullYear() : 0;

            if (!map.has(year)) {
                map.set(year, []);
            }

            map.get(year)!.push(item);
        });

    return map;
}

export interface INamed {
    name: string;
}

export interface IHasEmail {
    email: string;
}

export const byName = (item1: INamed, item2: INamed) => {
    return compareInsensitive(item1.name, item2.name);
};

export const byEmail = (item1: IHasEmail, item2: IHasEmail) => {
    return compareInsensitive(item1.email, item2.email);
};

export const byNameReversed = (item1: INamed, item2: INamed) => {
    return compareInsensitive(item2.name, item1.name);
};

export const compareInsensitive = (s1: string, s2: string) => {
    if (s1.toLowerCase() > s2.toLowerCase()) {
        return 1;
    } else if (s1.toLowerCase() < s2.toLowerCase()) {
        return -1;
    } else {
        return 0;
    }
};
