import * as React from "react";
import {Map as ImmutableMap} from "immutable";
import {FlashReportSummary} from "../../../model/FlashReportSummary.model";
import {getSuccessData} from "../../common/commonStates";
import {allFlashReportsSummariesSelector} from "../../../../mainReducerMapSelectors";
import {connect} from "react-redux";
import {multipleApiRequesterWrapper} from "../../common/MultipleApiRequesterWrapper";
import {allFlashReportsSummariesRequestPair} from "../../common/RequesterPairs";
import {byDate} from "../../../utils/dateUtil";
import {FlashReportListing} from "./FlashReportListing";
import {FormattedMessage} from "react-intl";
import {IApplicationRootState} from "../../../../applicationState";

export interface IProductFlashReportsPropsFromParent {
   productBackstopId: number;
}

export interface IProductFlashReportsPropsFromStore {
    allFlashReports: ImmutableMap<number, FlashReportSummary>;
}

export type IProductFlashReportsProps = IProductFlashReportsPropsFromParent
    & IProductFlashReportsPropsFromStore;

export const ProductFlashReports: React.FunctionComponent<IProductFlashReportsProps> = (props) => {
    const flashReportsForProduct = props.allFlashReports.valueSeq().toArray().filter((flashReport) =>
        flashReport.productIds.includes(props.productBackstopId));

    function renderSeparator(index: number) {
        return index !== flashReportsForProduct.length - 1
            ? <div key={`separator-${index}`} className="__separator" data-testid="separator">
                <div className="spacer-dash--small"/>
            </div>
            : null;
    }

    function renderFlashReports() {
        return flashReportsForProduct.length > 0
            ? flashReportsForProduct
                .sort(byDate)
                .map((flashReport, index) => {
                    return <div key={index}>
                        <FlashReportListing flashReport={flashReport} key={flashReport.backstopId}/>
                        {renderSeparator(index)}
                    </div>;
                })
            : <div className="product__no-flash-reports">
                <FormattedMessage
                    id="product.no-flash-reports"
                    defaultMessage="There are no flash reports at this time."
                />
        </div>;
    }

    return <div className="product__flash-reports" data-testid="product__flash-reports">
        {renderFlashReports()}
    </div>;
};

export const mapStateToProps = (state: IApplicationRootState): IProductFlashReportsPropsFromStore => {
    return {
        allFlashReports: getSuccessData(allFlashReportsSummariesSelector(state))!,
    };
};

const connectedComponent = connect<IProductFlashReportsPropsFromStore>(mapStateToProps)(ProductFlashReports);

export default multipleApiRequesterWrapper(
    connectedComponent, [
        allFlashReportsSummariesRequestPair,
    ]
);

