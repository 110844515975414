import {CSSProperties} from "react";
import {LegacyColors} from "./Colors";

export const boldText: CSSProperties = {
    fontFamily: "Roboto, sans-serif",
    fontSize: "16px",
    fontWeight: 300,
    color: LegacyColors.Black94,
    textTransform: "none",
};
