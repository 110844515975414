import {
    NOT_REQUESTED,
    REQUEST_FAILED,
    REQUEST_STATES,
    REQUEST_SUCCEEDED,
    REQUESTED,
    RequestState
} from "../common/commonStates";
import {Action, handleActions} from "redux-actions";
import {CalendarActions} from "./Calendar.actions";
import {ICalendarMeeting} from "../../api/CalendarApi";
import {Map} from "immutable";
import {getCurrentYear} from "../../utils/dateUtil";

export interface ICalendarStore {
    calendarMeetings: RequestState<Map<string, ICalendarMeeting>>,
    calendarCurrentYear: number | undefined
}

export const calendarInitialState: ICalendarStore = {
    calendarMeetings: NOT_REQUESTED,
    calendarCurrentYear: getCurrentYear()
};

const reducerMap = {
    [CalendarActions.CALENDAR_DATA_REQUESTED]:
        (state: ICalendarStore) => {
            return {...state, calendarMeetings: REQUESTED};
        },
    [CalendarActions.CALENDAR_DATA_REQUEST_SUCCEEDED]:
        (state: ICalendarStore, action: Action<any>) => {
            return {...state, calendarMeetings: REQUEST_SUCCEEDED(action.payload)};
        },
    [CalendarActions.CALENDAR_DATA_REQUEST_FAILED]:
        (state: ICalendarStore, action: Action<any>) => {
            return {...state, calendarMeetings: REQUEST_FAILED(action.payload)};
        },
    [CalendarActions.CLEAR_CALENDAR_DATA]:
        (state: ICalendarStore) => {
            return {...state, calendarMeetings: NOT_REQUESTED};
        },
    [CalendarActions.CURRENT_CALENDAR_YEAR]:
        (state: ICalendarStore, action: Action<any>) => {
            return {...state, calendarCurrentYear: action.payload};
        },
    [CalendarActions.CALENDAR_MEETINGS_UPDATED]:
        (state: ICalendarStore, action: Action<any>) => {
            const payload: Map<string, ICalendarMeeting> = action.payload;
            const newState: ICalendarStore = {...state};
            if (newState.calendarMeetings.kind === REQUEST_STATES.REQUEST_SUCCEEDED) {
                newState.calendarMeetings.data = newState.calendarMeetings.data.merge(payload);
            }
            return newState;
        }
};

export function calendarMeetingsSelectorInner(state?: ICalendarStore): RequestState<Map<string, ICalendarMeeting>> {
    return state ? state.calendarMeetings : NOT_REQUESTED;
}
export function calendarCurrentYearSelectorInner(state?: ICalendarStore): number | undefined {
    return state ? state.calendarCurrentYear : undefined;
}

export default handleActions<ICalendarStore>(reducerMap, calendarInitialState);