import {AnyAction, Dispatch} from "redux";

export const ALL_MANAGERS = -1;

export enum StrategyUpdatePageTypes {
    UPDATE_SELECTED_MANAGER = "UPDATE_SELECTED_MANAGER",
}

const updateManagerSelectionAction = (payload: number) => {
    return {type: StrategyUpdatePageTypes.UPDATE_SELECTED_MANAGER, payload};
};

export const updateSelectedManager = (value: number) => {
    return (dispatch: Dispatch<AnyAction>) => {
        dispatch(updateManagerSelectionAction(value));
    };
};

export default {
    updateSelectedManager,
};
