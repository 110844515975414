import * as React from "react";
import {IInformSummary} from "../../model/inform/InformSummary";
import {InformRatingChanges} from "./InformRatingChanges";

export interface IInformProductHealthCheckProps {
    summary: IInformSummary;
}

export const InformProductHealthCheck: React.FunctionComponent<IInformProductHealthCheckProps> = (props) => {
    const renderKeyShortTermMonitoringTableHeader = () => {
        return <thead>
        <tr>
            <th>Factor</th>
            <th>Past Quarter</th>
            <th>Last 12 Months</th>
            <th>Result</th>
        </tr>
        </thead>;
    };

    const getMonitoringValue = (value: string) => {
        const hasPercentage = value && value.includes("%");
        const valueWithoutPercentage = value && value !== "---"
            ? Number.parseFloat(value).toFixed(1)
            : "---";
        return hasPercentage ? `${valueWithoutPercentage}%` : valueWithoutPercentage;
    };

    const getMonitoringResultValue = (value: string) => {
        return value ? value : "---";
    };

    const renderKeyShortTermMonitoringTableBody = () => {
        return <tbody>
        <tr>
            <td>Product Gross Outflow</td>
            <td>{getMonitoringValue(props.summary.productGrossOutflowPastQuarter)}</td>
            <td>{getMonitoringValue(props.summary.productGrossOutflowLast12Months)}</td>
            <td>{getMonitoringResultValue(props.summary.productGrossOutflowResults)}</td>
        </tr>
        <tr>
            <td>Portfolio Manager Turnover</td>
            <td>{getMonitoringValue(props.summary.pmTurnoverPastQuarter)}</td>
            <td>{getMonitoringValue(props.summary.pmTurnoverLast12Months)}</td>
            <td>{getMonitoringResultValue(props.summary.pmTurnoverResults)}</td>
        </tr>
        <tr>
            <td>Relative Performance</td>
            <td>{getMonitoringValue(props.summary.relativePerformanceMRQ)}</td>
            <td>{getMonitoringValue(props.summary.relativePerformanceLast12Months)}</td>
            <td>{getMonitoringResultValue(props.summary.relativePerformanceResults)}</td>
        </tr>
        </tbody>;
    };

    const renderKeyShortTermMonitoringTable = () => {
        return <table className="inform-product__short-term-monitoring-table">
            {renderKeyShortTermMonitoringTableHeader()}
            {renderKeyShortTermMonitoringTableBody()}
        </table>;
    };

    const renderKeyShortTermMonitoringFactors = () => {
        return <div className="inform-product__short-term-monitoring-container">
            <div className="inform-product__short-term-monitoring-header">
                <h3>Key Short Term Monitoring Factors</h3>
            </div>
            <div>
                {renderKeyShortTermMonitoringTable()}
            </div>
        </div>;
    };

    return <div className="inform-product-health__container" data-testid="inform-product-health__container">
        <div className="inform-product-health-header">
            Product Health Check and Update
        </div>
        <div className="inform-product-health__rating-monitoring-container">
            <InformRatingChanges summary={props.summary}/>
            {renderKeyShortTermMonitoringFactors()}
        </div>
    </div>;
};