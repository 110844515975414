import * as React from "react";
import {useEffect, useState} from "react";
import {FactSheet, FsAssetClass, FsCurrency, FsFrequency} from "../../model/FactSheet.model";
import {compareDates, QuarterFilterItems} from "../../utils/dateUtil";
import {
    ALL_ASSET_CLASSES_KEY,
    ALL_CURRENCIES_KEY, 
    ALL_FREQUENCIES_KEY,
    FactSheetsFilters,
    FsAssetClassKey,
    FsCurrencyKey,
    FsFrequencyKey
} from "./FactSheetsFilters";
import {InViewReportsApi} from "../../api/InViewReportsApi";
import {ALL_YEARS_ID, IPerformanceDocsFiltersProps, PerformanceSubNav} from "./PerformanceReportingTab";
import {LoadingSpinner} from "../icons/LoadingSpinner.component";
import {handleEnter} from "../../utils/commonUtil";
import {FormattedMessage} from "react-intl";

export interface IFactSheetsFromParent {
    selectedAssetClass: FsAssetClassKey;
    selectedCurrency: FsCurrencyKey;
    selectedFrequency: FsFrequencyKey,
    setSubNav: (subNav: PerformanceSubNav) => void;
}

export type FactSheetsProps = IFactSheetsFromParent & IPerformanceDocsFiltersProps;

export const FactSheets: React.FunctionComponent<FactSheetsProps> = (props) => {
    const [factSheets, setFactSheets] = useState<FactSheet[] | undefined>(undefined);
    const [filtered, setFiltered] = useState<FactSheet[]>([]);

    const downloadFactSheet = (fs: FactSheet) => {
        InViewReportsApi.downloadReport(fs.backstopId, fs.fileName);
    };

    const renderFactSheet = (fs: FactSheet) => {
        return <tr key={fs.backstopId} id={`fact-sheets__row-${fs.backstopId}`}>
            <td className="fact-sheets__fs-date" data-testid="fact-sheets__fs-date">
                {`${fs.quarterAndYear.quarter} ${fs.quarterAndYear.year}`}
            </td>
            <td>
                <a className="fact-sheets__fs-title"
                    data-testid="fact-sheets__fs-title"
                    tabIndex={0}
                    onKeyUp={handleEnter(() => downloadFactSheet(fs))}
                    onClick={() => downloadFactSheet(fs)}
                >
                {fs.title}
                </a>
            </td>
        </tr>;
    };

    const renderFactSheetsTable = () => {
        return <table className="performance-docs__table">
            <thead>
                <tr>
                    <th className="performance-docs__table-header-date">
                        <FormattedMessage id="shared-docs.time-period" defaultMessage="TIME PERIOD"/>
                    </th>
                    <th className="performance-docs__table-header-title">
                        <FormattedMessage id="shared-docs.doc-title" defaultMessage="DOCUMENT TITLE"/>
                    </th>
                </tr>
            </thead>
            <tbody>
            {filtered.map(renderFactSheet)}
            </tbody>
        </table>;
    };


    const renderFactSheets = () => {
        return filtered.length > 0
            ? renderFactSheetsTable()
            : <div className="fact-sheets__no-results">
                <FormattedMessage
                    id="shared-docs.no-docs"
                    defaultMessage="No documents match the filters you've selected."/>
        </div>;
    };

    const matchesAssetClassFilter = (fs: FactSheet) => {
        return props.selectedAssetClass === ALL_ASSET_CLASSES_KEY
        ? true
            : fs.assetClass === FsAssetClass[props.selectedAssetClass];
    };

    const matchesCurrencyFilter = (fs: FactSheet) => {
        return props.selectedCurrency === ALL_CURRENCIES_KEY
            ? true
            : fs.currency === FsCurrency[props.selectedCurrency];
    };

    const matchesFrequencyFilter = (fs: FactSheet) => {
        return props.selectedFrequency === ALL_FREQUENCIES_KEY
            ? true
            : fs.frequency === FsFrequency[props.selectedFrequency];
    };
    
    const matchesYearFilter = (fs: FactSheet) => {
        return props.selectedYear === ALL_YEARS_ID
            ? true
            : fs.quarterAndYear.year === props.selectedYear;
    };

    const matchesQuarterFilter = (fs: FactSheet) => {
        return props.selectedQuarter === QuarterFilterItems.ALL
            ? true
            : fs.quarterAndYear.quarter.toString() === props.selectedQuarter.toString();
    };

    const matchesAllFilters = (fs: FactSheet) => {
        return matchesAssetClassFilter(fs)
            && matchesCurrencyFilter(fs)
            && matchesFrequencyFilter(fs)
            && matchesYearFilter(fs)
            && matchesQuarterFilter(fs);
    };

    useEffect(() => {
        InViewReportsApi.requestAllFactSheets()
            .then((response) => {
                setFactSheets(response);
            });
    }, []);

    useEffect(() => {
        if (factSheets) {
            setFiltered(factSheets
                .filter((it) => matchesAllFilters(it))
                .sort((a: FactSheet, b: FactSheet) => compareDates(a.effectiveDate, b.effectiveDate))
            );
        }
    }, [props, factSheets]);

    return  factSheets
        ? <div data-testid="powerbi-reporting__fact-sheets">
                <div className="performance-docs__header-container ">
                    <h2>
                        <FormattedMessage id="client-reporting.fact-sheets" defaultMessage="Fact Sheets"/>
                    </h2>

                    <div className="powerbi-reporting__sub-nav-links">
                         <a className="powerbi-reporting__report-link old-anchor"
                            tabIndex={0}
                            onClick={() => props.setSubNav(PerformanceSubNav.REPORT)}
                            onKeyUp={handleEnter(() => props.setSubNav(PerformanceSubNav.REPORT))}
                         >
                             <FormattedMessage id="filters.performance-reports" defaultMessage="Performance Report"/>
                        </a>
                        <a className="powerbi-reporting__supplemental-link old-anchor"
                           tabIndex={0}
                           onClick={() => props.setSubNav(PerformanceSubNav.SUPPLEMENTAL)}
                           onKeyUp={handleEnter(() => props.setSubNav(PerformanceSubNav.SUPPLEMENTAL))}
                        >
                            <FormattedMessage id="filters.supplemental-documents" defaultMessage="Supplemental Reports"/>
                        </a>
                    </div>
                </div>
                <FactSheetsFilters
                    factSheets={factSheets}
                    selectedAssetClass={props.selectedAssetClass}
                    selectedQuarter={props.selectedQuarter}
                    selectedYear={props.selectedYear}
                    selectedCurrency={props.selectedCurrency}
                    selectedFrequency={props.selectedFrequency}
                    handleFilterChange={props.handleFilterChange}
                    resetFilters={props.resetFilters}
                />
                <div className="performance-docs__table-container">
                    {renderFactSheets()}
                </div>
            </div>
        : <LoadingSpinner/>;
};