import * as React from "react";
import {useEffect, useState} from "react";
import {
    getSuccessData,
    NOT_REQUESTED,
    REQUEST_FAILED,
    REQUEST_STATES,
    REQUEST_SUCCEEDED,
    REQUESTED,
    RequestState
} from "../../common/commonStates";
import {OperationalDueDiligenceDocument} from "../../../model/OperationalDueDiligenceDocument.model";
import {OperationalDueDiligenceApi} from "../../../api/OperationalDueDiligenceApi";
import {LoadingSpinner} from "../../icons/LoadingSpinner.component";
import {DueDiligenceDocumentsContainer} from "./DueDiligenceDocumentsContainer";
import {ErrorComponent} from "../../base/Error.component";
import {FormattedMessage} from "react-intl";

export interface AllOddDocumentsProps {
    productBackstopId: number;
}

export const AllOddDocumentsByProduct: React.FunctionComponent<AllOddDocumentsProps> = (props) => {
    const [documents, setDocuments] =
        useState<RequestState<OperationalDueDiligenceDocument[]>>(NOT_REQUESTED);

    useEffect(() => {
        setDocuments(REQUESTED);

        OperationalDueDiligenceApi.requestAllOddDocumentsAndReportsForProduct(props.productBackstopId)
            .then((response) => setDocuments(REQUEST_SUCCEEDED(response.data ? response.data : [])))
            .catch(() => setDocuments(REQUEST_FAILED([])));
    }, []);

    function byEffectiveDate(document1: OperationalDueDiligenceDocument, document2: OperationalDueDiligenceDocument) {
        return document2.effectiveDate.getTime() - document1.effectiveDate.getTime();
    }

    function renderLoadingSpinner() {
        return documents.kind === REQUEST_STATES.REQUESTED || documents.kind === REQUEST_STATES.NOT_REQUESTED
            ? <LoadingSpinner />
            : null;
    }

    function renderNoDocuments() {
        return <div className="product__odd-documents-none">
            <FormattedMessage
                id="product.no-docs"
                defaultMessage="No documents available for this strategy."
            />
        </div>;
    }

    function renderDocuments(documentList: OperationalDueDiligenceDocument[]) {
        return documentList.sort(byEffectiveDate).map((document) => {
            return <DueDiligenceDocumentsContainer key={document.backstopId}
                                                   productId={props.productBackstopId}
                                                   oddProductDocument={document} />;
        });
    }

    function renderDocumentsSection() {
        if (documents.kind !== REQUEST_STATES.REQUESTED && documents.kind !== REQUEST_STATES.NOT_REQUESTED)
        {
            const documentList = getSuccessData(documents);

            if (documentList && documentList.length > 0) {
                return renderDocuments(documentList);
            } else if (documentList && documentList.length === 0) {
                return renderNoDocuments();
            } else {
                return <ErrorComponent />;
            }
        }

        return null;
    }

    return <div data-testid="all-odd-documents">
        {renderLoadingSpinner()}
        {renderDocumentsSection()}
    </div>;
};