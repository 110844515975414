import * as React from "react";
import {useEffect, useState} from "react";
import {ClientUser} from "../../model/ClientUser.model";
import {connect, useDispatch} from "react-redux";
import {REQUEST_STATES, RequestState} from "../common/commonStates";
import {requestClientUsers} from "../shared-documents/SharedDocuments.actions";
import {clientUsers} from "../../../mainReducerMapSelectors";
import {UserTypeEnum} from "../../model/UserInfo.model";
import {MeetingAttendeesSection} from "./MeetingAttendeesSection";
import {hasConsultantLevelAccess} from "../../utils/sessionUtil";
import {ClientApi} from "../../api/ClientApi";
import {CalendarMeetingAttendee} from "../../model/CalendarMeetingAttendee.model";
import {CalendarApi} from "../../api/CalendarApi";
import {ISelectValue} from "../common/Select.component";
import {IApplicationRootState} from "../../../applicationState";


export interface ICalendarMeetingAttendeesPropsFromStore {
    currentClientUsers: RequestState<ClientUser[]>;
}

export interface ICalendarMeetingAttendeesPropsFromParent {
    meetingId: number;
}

export type CalendarMeetingAttendeesProps = ICalendarMeetingAttendeesPropsFromStore
    & ICalendarMeetingAttendeesPropsFromParent;

export const CalendarMeetingAttendees: React.FunctionComponent<CalendarMeetingAttendeesProps>= (props) => {
    const [searchUsers, setSearchUsers] = useState<ClientUser[]>([]);
    const [clientName, setClientName] = useState<string>("");
    const [attendees, setAttendees] = useState<CalendarMeetingAttendee[]>([]);

    const dispatch = useDispatch();

    const refreshAttendees = () => {
        CalendarApi.requestMeetingAttendees(props.meetingId)
            .then((response) => setAttendees(response));
    };

    useEffect(() => {
        if (props.currentClientUsers?.kind === REQUEST_STATES.NOT_REQUESTED
            && hasConsultantLevelAccess()) {
            dispatch(requestClientUsers());
        }
        ClientApi.requestCurrentClientName()
            .then((response) => setClientName(response.data!));
        refreshAttendees();
    }, []);

    useEffect(() => {
        if (props.currentClientUsers?.kind === REQUEST_STATES.REQUEST_SUCCEEDED) {
            setSearchUsers(props.currentClientUsers.data);
        }
    }, [props.currentClientUsers]);

    const filterByType = (users: any[], userTypes: UserTypeEnum[]) => {
        return users.filter((user) => userTypes.includes(user.userType));
    };

    const getClientUsers = (users: any[]) => {
        return filterByType(users, [UserTypeEnum.CLIENT, UserTypeEnum.THIRD_PARTY, UserTypeEnum.ATC_ADMIN]);
    };

    const getNonClientUsers = (users: any[]) => {
        return filterByType(users, [UserTypeEnum.ADMIN, UserTypeEnum.CONSULTANT]);
    };

    const getOtherAttendees = () => {
       return filterByType(attendees, [UserTypeEnum.UNKNOWN]);
    };

    const addAttendee = (selectedValue: ISelectValue) => {
        const selectedUser = searchUsers.find((user) => user.id === selectedValue.id)!;
        CalendarApi.addMeetingAttendee(props.meetingId,
            new CalendarMeetingAttendee(
                selectedUser.firstName,
                selectedUser.lastName,
                selectedUser.userType,
                selectedUser.email
                ))
            .then(() => refreshAttendees())
            .catch(() => {
                    // do nothing
                }
            );
    };

    const deleteAttendee = (attendeeId: number) => {
        CalendarApi.deleteMeetingAttendee(attendeeId)
            .then(() => refreshAttendees());
    };

    const renderSection = (name: string,
                           users: ClientUser[],
                           sectionAttendees: CalendarMeetingAttendee[],
                           renderSearch: boolean) => {
        return <MeetingAttendeesSection
            sectionName={name}
            users={users}
            renderSearch={renderSearch}
            attendees={sectionAttendees}
            onAddAttendee={addAttendee}
            onDeleteAttendee={deleteAttendee}
        />;
    };

    return <div className="meeting-attendees__container">
        {renderSection("Aon attendees", getNonClientUsers(searchUsers), getNonClientUsers(attendees),
            hasConsultantLevelAccess())}
        {renderSection(`${clientName} attendees`, getClientUsers(searchUsers), getClientUsers(attendees),
            hasConsultantLevelAccess())}
        {renderSection("Other attendees", [], getOtherAttendees(),false)}
    </div> ;
};


export const mapStateToProps = (state: IApplicationRootState): ICalendarMeetingAttendeesPropsFromStore => {
    return {
        currentClientUsers: clientUsers(state)
    };
};

export default connect<ICalendarMeetingAttendeesPropsFromStore>(
    mapStateToProps,
)(CalendarMeetingAttendees);
