import * as React from "react";
import {useEffect, useState} from "react";
import {generatePercentagesFromResponseSummary, getInitialResponseSummary} from "../OddIqUtils";
import {countsAndPercentsToStackData, IStackData} from "../OddIqChartingUtils";
import {
    IOddQuestionEntity, IOddQuestionSummary,
    IOddSectionEntity,
    IOddSectionsQuestions,
    IOddResponsePercentSummary,
    IOddResponseSummary,
    OddResponseName
} from "../../../api/OddIqApi";
import {Map as ImmutableMap} from "immutable";
import {useDispatch} from "react-redux";
import {setCurrentOddQuestionId} from "../OddIqActions";
import {OddHorizontalBarChart} from "../odd-page/odd-results-by-risk-category/OddHorizontalBarChart";
import {ProductSummary} from "../../../model/product/ProductSummary";

export interface IOddResultsByRiskCategoryChartProps {
    oddQuestionSummaries: IOddQuestionSummary[]
    oddSectionsQuestions: IOddSectionsQuestions;
    currentQuestionId?: number;
}

export const OddResultsByRiskCategoryChart: React.FunctionComponent<IOddResultsByRiskCategoryChartProps> = (props) => {
    const [currentSectionId, setCurrentSectionId] = useState<number | undefined>(undefined);
    const [currentSortedMap, setCurrentSortedMap] = useState(getSummaryMap());
    const dispatch = useDispatch();

    function getQuestionsStackDataMap(): ImmutableMap<number, IStackData> {
        const questionsForSection: IOddQuestionEntity[] = props.oddSectionsQuestions.questions
            .filter((question) => question.sectionId === currentSectionId);

        const questionsSummaryMap: ImmutableMap<number, IOddResponseSummary> =
            props.oddQuestionSummaries
                .reduce((acc: ImmutableMap<number, IOddResponseSummary>, oddQuestionSummary) => {
                    const q = (questionsForSection.find((question) => question.id === oddQuestionSummary.questionId));
                    if(!q) return acc;
                    const questionSummary: IOddResponseSummary = {
                        ...oddQuestionSummary.summary,
                        name: q.questionTitle,
                    };
                    return acc.set(oddQuestionSummary.questionId, questionSummary);
                }, ImmutableMap<number, IOddResponsePercentSummary>());

        const questionsPercentSummaryMap =
            questionsSummaryMap
            .reduce((acc: ImmutableMap<number, IOddResponsePercentSummary>,
                     summary:IOddResponseSummary, key: number) => {
                const pctSummary = generatePercentagesFromResponseSummary(summary);
                pctSummary.name = summary.name;
                return acc.set(key, pctSummary);
            }, ImmutableMap<number, IOddResponsePercentSummary>());


        return countsAndPercentsToStackData(questionsSummaryMap, questionsPercentSummaryMap);
    }

    function getSectionsStackDataMap(): ImmutableMap<number, IStackData> {
        const sectionsSummaryMap = props.oddSectionsQuestions.sections
            .reduce((acc: ImmutableMap<number, IOddResponseSummary>, section: IOddSectionEntity) => {
                const questionsForSection = props.oddSectionsQuestions.questions
                    .filter((question) => question.sectionId === section.id);

                const sectionSummaries: IOddResponseSummary[] = props.oddQuestionSummaries
                    .filter((summary) =>
                        questionsForSection.find((question) => question.id === summary.questionId)
                    )
                    .map((summary) => summary.summary);

                const initialQuestionSummary: IOddResponseSummary = {
                    ...getInitialResponseSummary(),
                    name: section.name,
                };

                const sectionSummary = sectionSummaries
                    .reduce((totalSummary: IOddResponseSummary, summary) => {
                        totalSummary.exceptions = totalSummary.exceptions + summary.exceptions;
                        totalSummary.bestPractice = totalSummary.bestPractice + summary.bestPractice;
                        totalSummary.noData = totalSummary.noData + summary.noData;

                        return totalSummary;
                    }, initialQuestionSummary);

                return acc.set(section.id, sectionSummary);

            }, ImmutableMap<number, IOddResponseSummary>());

        const sectionsPercentSummaryMap = sectionsSummaryMap
            .reduce((acc: ImmutableMap<number, IOddResponsePercentSummary>,
                     summary:IOddResponseSummary, key: number) => {
                const pctSummary = generatePercentagesFromResponseSummary(summary);
                pctSummary.name = summary.name;
                return acc.set(key, pctSummary);
            }, ImmutableMap<number, IOddResponsePercentSummary>());

        return countsAndPercentsToStackData(sectionsSummaryMap, sectionsPercentSummaryMap);
    }

    function getSummaryMap(): ImmutableMap<number, IStackData> {
        return currentSectionId
        ? getQuestionsStackDataMap()
        : getSectionsStackDataMap();
    }

    function handleDrillUp() {
        dispatch(setCurrentOddQuestionId(undefined));
        setCurrentSectionId(undefined);
    }

    function renderSectionTitle() {
        if (!currentSectionId) {
            return null;
        }

        const currentSectionInfo = getSectionsStackDataMap().get(currentSectionId)!;
        const totalResponses = currentSectionInfo.counts[OddResponseName.EXCEPTIONS]
            + currentSectionInfo.counts[OddResponseName.BEST_PRACTICE]
            + currentSectionInfo.counts[OddResponseName.NO_DATA];

        return currentSectionId
            ? <div className="__section-header">
                <i className="fal fa-arrow-left clickable __back-arrow"
                   onClick={handleDrillUp}/>
                <div className="__section-title">
                    <h4>{currentSectionInfo.name}</h4>
                </div>
                <div className="__section-count">{totalResponses} questions</div>
            </div>
            : null;
    }

    function getQuestionFromId(questionId: number | undefined) {
        return questionId
            ? props.oddSectionsQuestions.questions.find((q) => q.id === props.currentQuestionId)
            : undefined;
    }

    useEffect(() => {
        const currentQuestion = getQuestionFromId(props.currentQuestionId);
        if (currentQuestion) {
            setCurrentSectionId(currentQuestion.sectionId);
        }
    }, [props.currentQuestionId]);

    useEffect(() => {
        setCurrentSortedMap(getSummaryMap());
    }, [currentSectionId, props.oddQuestionSummaries]);

    const setCurrentQuestion = (questionId: number) => {
        dispatch(setCurrentOddQuestionId(questionId));
    };

    const setCurrentSection = (sectionId: number) => {
        dispatch(setCurrentOddQuestionId(undefined));
        setCurrentSectionId(sectionId);
    };

    return <div data-testid="odd-results-by-risk-category-chart">
        {renderSectionTitle()}
        <OddHorizontalBarChart key={currentSectionId}
                               portfolioProductSummaries={ImmutableMap<number, ProductSummary>()}
                               oddExceptionResponses={[]}
                               sortedMap={currentSortedMap}
                               currentSectionId={currentSectionId}
                               setCurrentSectionId={setCurrentSection}
                               currentQuestionId={props.currentQuestionId}
                               setCurrentQuestionId={setCurrentQuestion}
                               oddDocuments={[]}
                               byBackstop={false}
                               oddQuestionSummaries={props.oddQuestionSummaries}
        />
    </div>;
};
