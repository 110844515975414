import {Action, handleActions} from "redux-actions";
import {ApiError} from "../../model/ApiError.model";
import {FlashReport} from "../../model/FlashReport.model";
import {FlashReportSummary} from "../../model/FlashReportSummary.model";
import {throwErrorOnNullOrUndefined} from "../../utils/errorUtil";
import {FlashReportActions} from "./flashReportActions";

export interface IFlashReportState {
    readonly flashReport: FlashReport;
    readonly error?: ApiError;
    readonly flashReportSummaries: FlashReportSummary[];
}

export const initialState: IFlashReportState = {
    flashReport: new FlashReport(0, "Not Selected", "Not Selected"),
    flashReportSummaries: [],
};

const reducerMap = {
    [FlashReportActions.FLASH_REPORT_RECEIVED]: (state: IFlashReportState,
                                                 action: Action<FlashReport>): IFlashReportState => {
        return {...state, flashReport: throwErrorOnNullOrUndefined(action.payload, "action.payload"), error: undefined};
    },
    [FlashReportActions.ERROR_FLASH_REPORT]: (state: IFlashReportState,
                                              action: Action<ApiError>): IFlashReportState => {
        return {...state, error: action.payload};
    },
    [FlashReportActions.UPDATE_FLASH_REPORT_SUMMARIES]: (state: IFlashReportState,
                                                         action: Action<FlashReportSummary[]>): IFlashReportState => {
        return {...state, flashReportSummaries: action.payload!};
    },
};

export default handleActions<IFlashReportState,
    FlashReport | ApiError | FlashReportSummary[]>(reducerMap, initialState);
