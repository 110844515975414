import {AssetClass} from "../../components/research/research-filters/FilterAndSort.menuItems";
import {NR_RATING, OddRating} from "../../utils/ratingConstants";
import {Manager} from "../manager/Manager.model";
import {IProductInfo} from "./IProductInfo";
import {ProductOddRating} from "./OperationalDueDiligenceRatings";
import {Ticker} from "./Ticker";

export class ProductSummary implements IProductInfo {
    public static builder(): ProductSummaryBuilder {
        return new ProductSummaryBuilder();
    }

    public constructor(public readonly ratingType: string,
                       public readonly backstopId: number,
                       public readonly name: string,
                       public readonly manager: Manager,
                       public readonly currentRating: string,
                       public readonly currentRatingEffectiveDate: Date,
                       public readonly assetType: AssetClass,
                       public readonly nameForSearch: string,
                       public readonly tickers: Ticker[],
                       public readonly inPortfolio: boolean,
                       public readonly region: string,
                       public readonly category: string,
                       public readonly style: string,
                       public readonly approach: string,
                       public readonly ifaRating: string,
                       public readonly oddRating: ProductOddRating,
                       public readonly productRating: string) {
    }
}

class ProductSummaryBuilder {
    private mBackstopId: number;
    private mRatingType: string;
    private mName: string;
    private mManager: Manager;
    private mCurrentRating: string;
    private mAssetClass: AssetClass;
    private mCurrentRatingEffectiveDate: Date;
    private mManagerName: string;
    private mManagerBackstopId: number;
    private mNameForSearch: string;
    private mTickers: Ticker[];
    private mInPortfolio: boolean;
    private mRegion: string;
    private mCategory: string;
    private mStyle: string;
    private mApproach: string;
    private mIfaRating: string;
    private mOddRating: ProductOddRating;
    private mProductRating: string;

    public backstopId(value: number) {
        this.mBackstopId = value;
        return this;
    }

    public ratingType(value: string) {
        this.mRatingType = value;
        return this;
    }

    public manager(value: Manager) {
        this.mManager = value;
        return this;
    }

    public currentRating(value: string) {
        this.mCurrentRating = value;
        return this;
    }

    public assetClass(value: AssetClass) {
        this.mAssetClass = value;
        return this;
    }

    public currentRatingEffectiveDate(value: Date) {
        this.mCurrentRatingEffectiveDate = value;
        return this;
    }

    public managerName(value: string) {
        this.mManagerName = value;
        return this;
    }

    public managerBackstopId(value: number) {
        this.mManagerBackstopId = value;
        return this;
    }

    public name(value: string) {
        this.mName = value;
        return this;
    }

    public nameForSearch(value: string) {
        this.mNameForSearch = value;
        return this;
    }

    public tickers(value: Ticker[]) {
        this.mTickers = value;
        return this;
    }

    public inPortfolio(value: boolean) {
        this.mInPortfolio = value;
        return this;
    }

    public region(value: string) {
        this.mRegion = value;
        return this;
    }

    public category(value: string) {
        this.mCategory = value;
        return this;
    }

    public style(value: string) {
        this.mStyle = value;
        return this;
    }

    public approach(value: string) {
        this.mApproach = value;
        return this;
    }

    public ifaRating(value: string) {
        this.mIfaRating = value;
        return this;
    }

    public oddRating(rating: OddRating, dateString: string | undefined) {
        if (!dateString) {
            this.mOddRating = new ProductOddRating(NR_RATING, null);
        } else {
            this.mOddRating = new ProductOddRating(rating, dateString);
        }
        return this;
    }

    public productRating(value: string) {
        this.mProductRating = value;
        return this;
    }


    public build(): ProductSummary {
        return new ProductSummary(
            this.mRatingType || "INFORM",
            this.mBackstopId || 0,
            this.mName || "",
            this.mManager || new Manager(this.mManagerBackstopId, this.mManagerName),
            this.mCurrentRating,
            this.mCurrentRatingEffectiveDate || new Date(),
            this.mAssetClass,
            this.mNameForSearch || "",
            this.mTickers || [],
            (this.mInPortfolio !== undefined) ? this.mInPortfolio : false,
            this.mRegion || "",
            this.mCategory || "",
            this.mStyle || "",
            this.mApproach || "",
            this.mIfaRating || "",
            this.mOddRating || new ProductOddRating(NR_RATING, null),
            this.mProductRating || ""
        );
    }
}

export const createProductSummary = (backstopId: number, name?: string) =>
    ProductSummary.builder()
        .backstopId(backstopId)
        .name(name || "Product " + backstopId)
        .build();
