import * as React from "react";
import {ErrorComponent} from "../base/Error.component";
import {SharedDocumentApi} from "../../api/SharedDocumentApi";

export interface IDownloadDocumentState {
    isRejected: boolean;
    document?: Buffer;
}
export interface IDownloadDocumentPropsFromParent {
    match: {
        params: {
            documentId: number;
        };
    };
}
export class DownloadDocumentComponent extends React.Component<IDownloadDocumentPropsFromParent,
    IDownloadDocumentState> {

    public constructor(props: any) {
        super(props);

        this.state = {
            isRejected: false,
            document: undefined,
        };
    }

    public componentDidMount() {
        SharedDocumentApi.getSharedDocumentData(this.props.match.params.documentId)
            .then((result) => {
                if (result.data) {
                    SharedDocumentApi
                        .getDocumentDownload(this.props.match.params.documentId, result.data.fileName, false);
                    this.setState({isRejected: false});
                }
            }).catch(() => {
                this.setState({isRejected: true});
        });
    }

    public render() {
        return this.state.isRejected
            ? <ErrorComponent/>
            : <div style={{
                position: "absolute",
                top: 0,
                left: 0,
                width: "100%",
                height: "100%",
                zIndex: 9999,
                background: "white",
            }} data-testid="download-document-page"/>;
    }
}
