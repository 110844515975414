import * as React from "react";
import {PowerBiReportComponent} from "../power-bi-reporting/PowerBiReport.component";
import {useEffect, useState} from "react";
import {IReportConfig, PowerBiReportingApi} from "../../api/PowerBiReportingApi";
import {NOT_REQUESTED, REQUEST_FAILED, REQUEST_SUCCEEDED, REQUESTED, RequestState} from "../common/commonStates";
import {LoadingSpinner} from "../icons/LoadingSpinner.component";

export const OddIqReport: React.FunctionComponent = () => {
    const [reportConfig, setReportConfig] = useState<RequestState<IReportConfig>>(NOT_REQUESTED);
    useEffect(() => {
        if (reportConfig === NOT_REQUESTED) {
            setReportConfig(REQUESTED);
            PowerBiReportingApi.requestOddReportConfig()
                .then((response: IReportConfig) => {
                    setReportConfig(REQUEST_SUCCEEDED(response));
                })
                .catch(() => {
                    setReportConfig(REQUEST_FAILED("Could not request Performance Report"));
                });
        }
    }, []);


    return reportConfig
        ? <PowerBiReportComponent reportConfig={reportConfig}
                                   displayPrintLink={false}
            />
        : <LoadingSpinner/>;
};