import * as React from "react";
import {useEffect, useState} from "react";
import {
    clientPerformanceReportingAccess,
    showFactSheets,
    sharedDocumentsSelector
} from "../../../mainReducerMapSelectors";
import {connect} from "react-redux";
import {NoAccessNotification} from "../base/header/NoAccessNotification";
import {IReportConfig, PowerBiReportingApi} from "../../api/PowerBiReportingApi";
import {SharedDocument} from "../../model/SharedDocument.model";
import {
    getSuccessData,
    NOT_REQUESTED,
    REQUEST_FAILED,
    REQUEST_SUCCEEDED,
    REQUESTED,
    RequestState
} from "../common/commonStates";
import {multipleApiRequesterWrapper} from "../common/MultipleApiRequesterWrapper";
import {sharedDocumentsPair} from "../common/RequesterPairs";
import {FactSheets} from "./FactSheets";
import {
    ALL_ASSET_CLASSES_KEY,
    ALL_CURRENCIES_KEY, 
    ALL_FREQUENCIES_KEY,
    FsAssetClassKey,
    FsCurrencyKey,
    FsFrequencyKey
} from "./FactSheetsFilters";
import {QuarterFilterItems} from "../../utils/dateUtil";
import {PerformanceReport} from "./PerformanceReport";
import {SupplementalReports} from "./SupplementalReports";
import {SortOptions} from "../../utils/sharedDocsUtil";
import {IApplicationRootState} from "../../../applicationState";

export interface IPerformanceReportingPropsFromStore  {
    clientHasAccess: boolean;
    sharedDocuments: SharedDocument[];
    showFactSheets: boolean;
}

export enum PerformanceSubNav {
    REPORT,
    FACT_SHEETS,
    SUPPLEMENTAL,
}

export interface IPerformanceDocsFiltersProps {
    selectedYear: number;
    selectedQuarter: QuarterFilterItems;
    handleFilterChange: (e: any, filterCode: string) => void;
    resetFilters: () => void;
}

export const ALL_YEARS_ID = 0;

export const PerformanceReportingTab: React.FunctionComponent<IPerformanceReportingPropsFromStore> = (props) => {
    const [reportConfig, setReportConfig] = useState<RequestState<IReportConfig>>(NOT_REQUESTED);
    const [subNav, setSubNav] = useState(PerformanceSubNav.REPORT);

    const [selectedAssetClass, setSelectedAssetClass] = useState<FsAssetClassKey>(ALL_ASSET_CLASSES_KEY);
    const [selectedYear, setSelectedYear] = useState(ALL_YEARS_ID);
    const [selectedQuarter, setSelectedQuarter] = useState(QuarterFilterItems.ALL);
    const [selectedCurrency, setSelectedCurrency] = useState<FsCurrencyKey>(ALL_CURRENCIES_KEY);
    const [selectedFrequency, setSelectedFrequency] = useState<FsFrequencyKey>(ALL_FREQUENCIES_KEY);

    const [selectedYearSupplemental, setSelectedYearSupplemental] = useState(ALL_YEARS_ID);
    const [selectedQuarterSupplemental, setSelectedQuarterSupplemental] = useState(QuarterFilterItems.ALL);
    const [selectedOrderSupplemental, setSelectedOrderSupplemenetal] = useState(SortOptions.TIME_PERIOD);

    const handleFilterChange = (e: any, filterCode: string) => {
        if (filterCode === "by_asset_class") {
            setSelectedAssetClass(e.target.value);
        } else if (filterCode === "by_year") {
            setSelectedYear(e.target.value);
        } else if (filterCode === "by_quarter") {
            setSelectedQuarter(e.target.value);
        } else if (filterCode === "by_currency") {
            setSelectedCurrency(e.target.value);
        } else if (filterCode === "by_frequency") {
            setSelectedFrequency(e.target.value);
        }
    };

    const resetFilters = () => {
        setSelectedAssetClass(ALL_ASSET_CLASSES_KEY);
        setSelectedCurrency(ALL_CURRENCIES_KEY);
        setSelectedFrequency(ALL_FREQUENCIES_KEY);
        setSelectedYear(ALL_YEARS_ID);
        setSelectedQuarter(QuarterFilterItems.ALL);
    };

    const handleFilterChangeSupplemental = (e: any, filterCode: string) => {
        if (filterCode === "by_year") {
            setSelectedYearSupplemental(e.target.value);
        } else if (filterCode === "by_quarter") {
            setSelectedQuarterSupplemental(e.target.value);
        } else if (filterCode === "sort") {
            setSelectedOrderSupplemenetal(e.target.value);
        }
    };

    const resetFiltersSupplemental = () => {
        setSelectedYearSupplemental(ALL_YEARS_ID);
        setSelectedQuarterSupplemental(QuarterFilterItems.ALL);
    };

    function renderClientNoAccessNotification() {
        return !props.clientHasAccess
            ? <NoAccessNotification message={"The Performance section is currently hidden from client users of this plan."} />
            : null;
    }

    function renderReport() {
        return <PerformanceReport reportConfig={reportConfig}
                                  setSubNav={setSubNav}
                                  hide={subNav !== PerformanceSubNav.REPORT}
                                  showFactSheets={props.showFactSheets}
        />;
    }

    function renderFactSheets() {
        return subNav === PerformanceSubNav.FACT_SHEETS
        ? <FactSheets
                selectedAssetClass={selectedAssetClass}
                selectedQuarter={selectedQuarter}
                selectedYear={selectedYear}
                selectedFrequency={selectedFrequency}
                selectedCurrency={selectedCurrency}
                handleFilterChange={handleFilterChange}
                resetFilters={resetFilters}
                setSubNav={setSubNav}
            />
            :null;
    }

    function renderSupplemental() {
        return subNav === PerformanceSubNav.SUPPLEMENTAL
        ? <SupplementalReports setSubNav={setSubNav}
                               sharedDocuments={props.sharedDocuments}
                               selectedYear={selectedYearSupplemental}
                               selectedQuarter={selectedQuarterSupplemental}
                               handleFilterChange={handleFilterChangeSupplemental}
                               resetFilters={resetFiltersSupplemental}
                               selectedOrder={selectedOrderSupplemental}
                               showFactSheets={props.showFactSheets}
            />
        : null;
    }

    useEffect(() => {
        if (reportConfig === NOT_REQUESTED) {
            setReportConfig(REQUESTED);
            PowerBiReportingApi.requestPerformanceReportConfig()
                .then((response: IReportConfig) => {
                    setReportConfig(REQUEST_SUCCEEDED(response));
                })
                .catch(() => {
                    setReportConfig(REQUEST_FAILED("Could not request Performance Report"));
                });
        }
    }, []);

    return <div className="new-common-styles powerbi-reporting__performance-container">
            {renderClientNoAccessNotification()}
            {renderReport()}
            {renderFactSheets()}
            {renderSupplemental()}
        </div>;
};

export const mapStateToProps = (state: IApplicationRootState): IPerformanceReportingPropsFromStore => {
    return {
        clientHasAccess: clientPerformanceReportingAccess(state),
        sharedDocuments: getSuccessData(sharedDocumentsSelector(state))!,
        showFactSheets: showFactSheets(state),
    };
};

const connectedComponent = connect(mapStateToProps)(PerformanceReportingTab);

export default multipleApiRequesterWrapper(
    connectedComponent, [
        sharedDocumentsPair,
    ]
);