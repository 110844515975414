import {Map} from "immutable";
import {Action, Dispatch} from "redux";
import {ThunkAction} from "redux-thunk";
import {ManagerApi} from "../../api/ManagerApi";
import {ManagerResponse} from "../../model/manager/ManagerResponse.model";
import {throwErrorOnNullOrUndefined} from "../../utils/errorUtil";

export enum AllManagersActions {
    ALL_MANAGERS_REQUESTED = "ALL_MANAGERS_REQUESTED",
    ALL_MANAGERS_RECEIVED = "ALL_MANAGERS_RECEIVED",
}

const allManagersRequestedAction = () => {
    return {
        type: AllManagersActions.ALL_MANAGERS_REQUESTED,
    };
};

const allManagersReceivedAction = (payload?: Map<number, ManagerResponse>) => {
    return {
        type: AllManagersActions.ALL_MANAGERS_RECEIVED,
        payload,
    };
};

export const getAllManagers = (dispatch: Dispatch<Action<AllManagersActions>>): Promise<any> => {
    dispatch(allManagersRequestedAction());

    return ManagerApi.requestAllManagers().then(
        (allManagers) => dispatch(allManagersReceivedAction(throwErrorOnNullOrUndefined(allManagers.data))),
    );
};

export const requestAllManagers = (): ThunkAction<void, void, null, Action<void>> => {
    return (dispatch) => {
        getAllManagers(dispatch);
    };
};
