export interface IResearchNavigationMenuItems {
    id: string;
    name: string;
    targetUrl: string;
}

export enum ResearchMenuItems {
    RESEARCH_ID = "researchItem",
    INVESTMENTS_ID = "investmentsItem",
    STRATEGY_UPDATES_ID = "updatesItem",
}

export const researchItem: IResearchNavigationMenuItems = {
    id: ResearchMenuItems.RESEARCH_ID,
    name: "Research",
    targetUrl: "/research",
};

export const updatesItem: IResearchNavigationMenuItems = {
    id: ResearchMenuItems.STRATEGY_UPDATES_ID,
    name: "Updates",
    targetUrl: "/strategy-updates",
};

export const investmentsItem: IResearchNavigationMenuItems = {
    id: ResearchMenuItems.INVESTMENTS_ID,
    name: "Investments",
    targetUrl: "/investments",
};

export const allResearchItems = [researchItem, updatesItem, investmentsItem];

export const UPDATES_PATHS = ["strategy-updates", "meeting-notes", "flash-reports"];
