import {Action, Dispatch} from "redux";
import {ThunkAction} from "redux-thunk";
import {FlashReportsApi} from "../../api/FlashReportsApi";
import {ApiError} from "../../model/ApiError.model";
import {FlashReport} from "../../model/FlashReport.model";

export enum FlashReportActions {
    FLASH_REPORT_RECEIVED = "FLASH_REPORT_RECEIVED",
    FLASH_REPORT_PDF_DOWNLOADED = "FLASH_REPORT_PDF_DOWNLOADED",
    ERROR_FLASH_REPORT = "ERROR_FLASH_REPORT",
    UPDATE_FLASH_REPORT_SUMMARIES = "UPDATE_FLASH_REPORT_SUMMARIES",
}

const flashReportReceivedAction = (payload: FlashReport) => {
    return {
        type: FlashReportActions.FLASH_REPORT_RECEIVED,
        payload,
    };
};

const errorFlashReport = (payload?: ApiError) => {
    return {
        type: FlashReportActions.ERROR_FLASH_REPORT,
        payload,
    };
};

export const getFlashReport = (backstopId: number): ThunkAction<void, {}, null, Action<FlashReportActions>> => {
    return (dispatch: Dispatch<Action<FlashReportActions>>) => {
        FlashReportsApi.requestFlashReportById(backstopId)
            .then((flashReport) => {
                dispatch(flashReportReceivedAction(flashReport.data!));
            }, (errorResponse) => {
                dispatch(errorFlashReport(errorResponse.error));
            });
    };
};

export const getFlashReportPDF = (backstopId: number, fileName: string)
    : ThunkAction<void, {}, null, Action<number>> => {
    return (dispatch: Dispatch<Action<FlashReportActions>>) => {
        FlashReportsApi.requestPdf(backstopId, fileName)
            .then(() => {
                dispatch({type: FlashReportActions.FLASH_REPORT_PDF_DOWNLOADED, payload: backstopId});
            });
    };
};

export const flashReportActions = {
    getFlashReport,
    getFlashReportPDF,
};
