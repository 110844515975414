import * as React from "react";
import {IInformProps} from "../../../model/inform/InformProduct";
import {RelativePerformanceChart} from "../../inform/RelativePerformanceChart";
import {PerformanceComponent} from "../../inform/PerformanceComponent";
import {IInformPerformance} from "../../../model/inform/InformPerformance";


export interface IProductPerformanceAnalyticsProps {
    informProduct: IInformProps
}

export const ProductPerformanceAnalytics: React.FunctionComponent<IProductPerformanceAnalyticsProps> = (props) => {
    const renderRelativePerformanceChart = () => {
        return props.informProduct?.summary?.relativePerformance?.length
            ? <RelativePerformanceChart
                relativePerformance={props.informProduct.summary.relativePerformance}
                effectiveDate={props.informProduct.summary?.ifaRatingEffectiveDate ? props.informProduct.summary.ifaRatingEffectiveDate : ""}
                informBenchmark={props.informProduct.summary.inFormBenchmark}/>
            : null;
    };

    const renderCumulativeAbsoluteReturnChartWithPeriods = () => {
        return props.informProduct?.performance
            ? <PerformanceComponent {...props.informProduct.performance as IInformPerformance}/>
            : null;
    };

    return <div className="product-performance-analytics__container"
                data-testid="product-performance-analytics__container">
        {renderRelativePerformanceChart()}
        {renderCumulativeAbsoluteReturnChartWithPeriods()}
    </div>;
};