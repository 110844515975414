import * as React from "react";
import {useEffect, useState} from "react";
import {
    getProductResponsesWithResponseCode,
    mapQuestionIdToPercentResponseSummary,
    mapQuestionIdToResponseSummary,
    mapSectionToSummary,
    mapSectionToSummaryPercent
} from "../../OddIqUtils";
import {countsAndPercentsToStackData, IStackData} from "../../OddIqChartingUtils";
import {OddHorizontalBarChart} from "./OddHorizontalBarChart";
import {
    IOddResponse,
    IOddSectionsQuestions,
    OddResponseCode,
    OddResponseName
} from "../../../../api/OddIqApi";
import {Map as ImmutableMap} from "immutable";
import {ProductSummary} from "../../../../model/product/ProductSummary";
import {useDispatch} from "react-redux";
import {setCurrentOddQuestionId} from "../../OddIqActions";
import {OperationalDueDiligenceDocument} from "../../../../model/OperationalDueDiligenceDocument.model";

export interface IOddResultsByRiskCategoryBreakdownProps {
    oddResponses: IOddResponse[];
    oddSectionsQuestions: IOddSectionsQuestions;
    portfolioProductSummaries: ImmutableMap<number, ProductSummary>;
    currentQuestionId?: number;
    oddDocuments: OperationalDueDiligenceDocument[];
    byBackstop: boolean;
}

export const OddResultsByRiskCategoryBreakdown: React.FunctionComponent<IOddResultsByRiskCategoryBreakdownProps> = (props) => {
    const [currentSectionId, setCurrentSectionId] = useState<number | undefined>(undefined);
    const [currentSortedMap, setCurrentSortedMap] = useState(getSummaryMap());
    const dispatch = useDispatch();

    const exceptionResponses = getProductResponsesWithResponseCode(
        props.oddResponses,
        OddResponseCode.EXCEPTION,
    );

    function getQuestionsStackDataMap(): ImmutableMap<number, IStackData> {
        const questionsForSection = props.oddSectionsQuestions.questions
            .filter((question) => question.sectionId === currentSectionId);
        const questionsSummaryMap = mapQuestionIdToResponseSummary(
            questionsForSection,
            props.oddResponses,
        );
        const questionsPercentSummaryMap = mapQuestionIdToPercentResponseSummary(
            questionsForSection,
            props.oddResponses,
        );

        return countsAndPercentsToStackData(questionsSummaryMap, questionsPercentSummaryMap);
    }

    function getSectionsStackDataMap(): ImmutableMap<number, IStackData> {
        const sectionsSummaryMap = mapSectionToSummary(
            props.oddSectionsQuestions,
            props.oddResponses,
        );
        const sectionsPercentSummaryMap = mapSectionToSummaryPercent(
            props.oddSectionsQuestions,
            props.oddResponses,
        );

        return countsAndPercentsToStackData(sectionsSummaryMap, sectionsPercentSummaryMap);
    }

    function getSummaryMap(): ImmutableMap<number, IStackData> {
        return currentSectionId
        ? getQuestionsStackDataMap()
        : getSectionsStackDataMap();
    }

    function handleDrillUp() {
        dispatch(setCurrentOddQuestionId(undefined));
        setCurrentSectionId(undefined);
    }

    function renderSectionTitle() {
        if (!currentSectionId) {
            return null;
        }

        const currentSectionInfo = getSectionsStackDataMap().get(currentSectionId)!;
        const totalResponses = currentSectionInfo.counts[OddResponseName.EXCEPTIONS]
            + currentSectionInfo.counts[OddResponseName.BEST_PRACTICE]
            + currentSectionInfo.counts[OddResponseName.NO_DATA];

        return currentSectionId
            ? <div className="__section-header">
                <i className="fal fa-arrow-left clickable __back-arrow"
                   onClick={handleDrillUp}/>
                <div className="__section-title">
                    <h4>{currentSectionInfo.name}</h4>
                </div>
                <div className="__section-count">{totalResponses} questions</div>
            </div>
            : null;
    }

    function getQuestionFromId(questionId: number | undefined) {
        return questionId
            ? props.oddSectionsQuestions.questions.find((q) => q.id === props.currentQuestionId)
            : undefined;
    }

    useEffect(() => {
        const currentQuestion = getQuestionFromId(props.currentQuestionId);
        if (currentQuestion) {
            setCurrentSectionId(currentQuestion.sectionId);
        }
    }, [props.currentQuestionId]);

    useEffect(() => {
        setCurrentSortedMap(getSummaryMap());
    }, [currentSectionId, props.oddResponses]);

    const setCurrentQuestion = (questionId: number) => {
        dispatch(setCurrentOddQuestionId(questionId));
    };

    const setCurrentSection = (sectionId: number) => {
        dispatch(setCurrentOddQuestionId(undefined));
        setCurrentSectionId(sectionId);
    };

    return <div data-testid="odd-results-by-risk-category-breakdown">
        {renderSectionTitle()}
        <OddHorizontalBarChart key={currentSectionId}
                               portfolioProductSummaries={props.portfolioProductSummaries}
                               oddExceptionResponses={exceptionResponses}
                               sortedMap={currentSortedMap}
                               currentSectionId={currentSectionId}
                               setCurrentSectionId={setCurrentSection}
                               currentQuestionId={props.currentQuestionId}
                               setCurrentQuestionId={setCurrentQuestion}
                               oddDocuments={props.oddDocuments}
                               byBackstop={props.byBackstop}
        />
    </div>;
};
