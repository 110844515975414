import * as React from "react";
import {useEffect, useState} from "react";
import {IOddProduct, IOddProductsAccess, IOddResponse, IOddSectionsQuestions, OddIqApi} from "../../../api/OddIqApi";
import {oddIqEnabled} from "../../../../mainReducerMapSelectors";
import {getSuccessData} from "../../common/commonStates";
import {
    oddProductsAccessSelector,
    oddProductsRequestPairs,
    oddSectionsQuestionsRequestPair,
    operationalDueDiligenceSectionsQuestionsSelector
} from "../OddIqRequesterPairs";
import {multipleApiRequesterWrapper} from "../../common/MultipleApiRequesterWrapper";
import {connect} from "react-redux";
import {FormattedMessage} from "react-intl";
import {ErrorComponent} from "../../base/Error.component";
import {LoadingSpinner} from "../../icons/LoadingSpinner.component";
import {OddIqMethodologyComponent} from "../OddIqMethodology.component";
import {PotentialRiskAreas} from "./PotentialRiskAreas";
import {formatShortDate} from "../../../utils/dateUtil";
import {ExceptionsBreakdown} from "./ExceptionsBreakdown.component";
import moment = require("moment");
import {IApplicationRootState} from "../../../../applicationState";

export interface IOddProductPropsFromLocation {
    match: {
        params: {
            vehicleId: string;
        };
    };
}
export interface IOddProductPageFromStore {
    oddProductsAccess: IOddProductsAccess;
    oddIqEnabled: boolean;
    oddSectionsQuestions: IOddSectionsQuestions;
}

export type OddProductPageProps = IOddProductPropsFromLocation & IOddProductPageFromStore;

export const OddProductPage: React.FunctionComponent<OddProductPageProps> = (props) => {
    const [oddProduct, setOddProduct] = useState<IOddProduct | undefined>(undefined);
    const [loaded, setLoaded] = useState(false);
    const [oddResponses, setOddResponses] = useState<IOddResponse[] | undefined>(undefined);
    const vehicleId = props.match.params.vehicleId;

    const renderDateResponded = () => {
        return oddProduct!.dateResponded
            ? <div className="product-odd__iq-date-responded">
                Data Collection Date: {formatShortDate(moment(oddProduct!.dateResponded).toDate())}
            </div>
            : null;
    };

    useEffect(() => {
        setLoaded(false);
        const matchedProducts = props.oddProductsAccess.accessibleProducts
            .filter((product) => product.vehicleId === vehicleId);
        if (matchedProducts.length > 0){
            const product = matchedProducts[0];
            setOddProduct(product);
            OddIqApi.requestOddResponsesForProduct(product.id)
                .then((response) => {
                    setOddResponses(response);
                    setLoaded(true);
                })
                .catch(() => setLoaded(true));
            
        } else {
            setOddProduct(undefined);
            setLoaded(true);
        }
    }, [props.match.params.vehicleId]);

    const renderContent = () => {
        return <div className="main-content new-common-styles">
            <div className="product__content">
                <div>
                    <h1 style={{marginBottom: "20px"}}
                        className="product__title product__content-align">
                        {oddProduct!.name}
                    </h1>
                </div>
                <div className="product__tabs-container">
                    <div id="product__tab-odd"
                         className="product__tab clickable selected">
                        <FormattedMessage
                            id="product-tab.OPERATIONAL DUE DILIGENCE"
                            defaultMessage="OPERATIONAL DUE DILIGENCE"
                        />
                    </div>
                </div>
                <div className="product-odd__iq-container">
                    <div className="product-odd__iq-title-container">
                        <div className="product-odd__iq-title">
                            <h2>Potential Risk Areas or Exceptions</h2>
                        </div>
                        <OddIqMethodologyComponent mode="Product"/>
                    </div>
                    {renderDateResponded()}
                    <PotentialRiskAreas responsesForProduct={oddResponses!}
                                        oddSectionsQuestions={props.oddSectionsQuestions} />

                    <ExceptionsBreakdown
                        oddSectionsQuestions={props.oddSectionsQuestions}
                        oddProductQuestionResponse={oddResponses!}/>
                </div>
            </div>
        </div>;
    };

    return props.oddIqEnabled
    ? loaded
        ? oddProduct && oddResponses ? renderContent() : <ErrorComponent/>
        : <LoadingSpinner/>
    : <ErrorComponent/>;
};

export const mapStateToProps = (state: IApplicationRootState): IOddProductPageFromStore => {
    return {
        oddIqEnabled: oddIqEnabled(state),
        oddProductsAccess: getSuccessData(oddProductsAccessSelector(state))!,
        oddSectionsQuestions: getSuccessData(operationalDueDiligenceSectionsQuestionsSelector(state))!,
    };
};

const connected = connect<IOddProductPageFromStore, {}>(mapStateToProps)(OddProductPage);

export default multipleApiRequesterWrapper(
    connected, [
        oddProductsRequestPairs,
        oddSectionsQuestionsRequestPair,
    ]
);