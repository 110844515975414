import * as React from "react";
import {connect} from "react-redux";
import {userType} from "../../../mainReducerMapSelectors";
import {navigateTo} from "../../../navigateTo";
import {AdminLevelList, ConsultantLevelList, doesUserHaveAccess} from "../../model/UserDetails.model";
import {UserTypeEnum} from "../../model/UserInfo.model";
import {IApplicationRootState} from "../../../applicationState";

export interface IVerifyAccessPropsFromStore {
    userType: UserTypeEnum;
}

export interface IVerifyAccessProps {
    children?: React.ReactNode;
    accessLevel: UserTypeEnum;
}

export type VerifyAccessProps = IVerifyAccessProps & IVerifyAccessPropsFromStore;

export const VerifyAccessComponent: React.FunctionComponent<VerifyAccessProps> = (props) => {

    const accessList = props.accessLevel === UserTypeEnum.ADMIN ? AdminLevelList : ConsultantLevelList;
    if (props.accessLevel === UserTypeEnum.CLIENT || doesUserHaveAccess(props.userType, accessList)) {
        return <>{props.children}</>;
    } else {
        navigateTo("/home");
        return null;
    }
};

export const mapStateToProps = (state: IApplicationRootState): IVerifyAccessPropsFromStore => {
    return {
        userType: userType(state),
    };
};

const ConnectedComponent = connect<IVerifyAccessPropsFromStore>(mapStateToProps)(VerifyAccessComponent);

export const withVerifyAccess = <TPropsFromParent extends {}>
(Wrapped: React.FunctionComponent<TPropsFromParent> | React.ComponentClass<TPropsFromParent>,
 accessLevel: UserTypeEnum) =>
    (props: TPropsFromParent) => {
        return <ConnectedComponent accessLevel={accessLevel}>
            <Wrapped {...props}/>
        </ConnectedComponent>;
};
