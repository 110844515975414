import * as React from "react";
import {kebabCase} from "lodash";
import {TextFieldComponent} from "../common/TextField.component";
import {useEffect, useState} from "react";
import {ISelectValue, SelectComponent} from "../common/Select.component";
import {CALENDAR_FREQUENCY_VALUES, PLAN_TYPE_VALUES} from "../calendar/CalendarUtils";
import {CalendarApi, ICalendarTemplateTopic} from "../../api/CalendarApi";
import {FREQUENCY, PLAN_TYPE, TOPIC_NAME} from "./AdminCalendarTopicsPage";
import {RaisedButton} from "../common/RaisedButton";
import {prominentButtonStyle, secondaryButtonStyle} from "../common/buttonStyles";
import {setHeaderNotification} from "../base/header/HeaderActions";
import {NotificationTypes} from "../base/header/HeaderReducer";
import {ApiError} from "../../model/ApiError.model";
import {useDispatch} from "react-redux";
import {scrollToElement} from "../../utils/browserUtil";
import {ICalendarTemplateLink} from "../../model/CalendarTopicDetails.model";
import {useLocation} from "react-router-dom";
import {navigateTo} from "../../../navigateTo";
import {withVerifyAccess} from "../common/withVerifyAccess";
import {UserTypeEnum} from "../../model/UserInfo.model";

export const CalendarTopicAddEditPage: React.FunctionComponent = () => {
    const currentURL = useLocation().pathname;
    const props = useLocation().state as { calendarTopic: ICalendarTemplateTopic };
    const [topic, setTopic]
        = useState<ICalendarTemplateTopic | undefined>();
    const [submitClicked, setSubmitClicked] = useState<boolean>(false);
    const [isEditMode, setIsEditMode] = useState<boolean>(false);
    const dispatch = useDispatch();

    const TOPIC_DESCRIPTION_LABEL = "Topic Description";
    const ALLOW_DUPES_LABEL = "Allow Dupes";
    const ALLOW_DUPES_VALUES: ISelectValue[] = [{name: "Yes", id: "Yes"}, {name: "No", id: "No"}];
    const LINK_TYPE_LABEL = "Link Type";
    const LINK_TITLE_LABEL = "Link Title";
    const LINK_URL_LABEL = "Link URL";

    enum LINK_TYPE {
        TEMPLATE_LINK = 1,
        WHITE_PAPER_LINK
    }

    const LINK_TYPE_VALUES: ISelectValue[] = [
        {id: LINK_TYPE.TEMPLATE_LINK, name: "Template Link"},
        {id: LINK_TYPE.WHITE_PAPER_LINK, name: "Whitepaper Link"}
    ];

    useEffect(() => {
        if (currentURL === "/admin-calendar-topic/edit") {
            props ? setTopic(props.calendarTopic) : navigateTo("/admin-calendar-topics");
            setIsEditMode(true);
        }
    }, [props]);

    const handleSave = () => {
        setSubmitClicked(true);
        const modifiedTemplateTopic = topic!;
        const processSuccess = () => {
            setSubmitClicked(false);
            const message = isEditMode ? "Calendar template topic is modified successfully"
                : "Calendar template topic is added successfully";
            dispatch(setHeaderNotification(
                {
                    message,
                    notificationType: NotificationTypes.SUCCESS
                },
                5000));

            navigateTo("/admin-calendar-topics");
        };

        const processFailure = (reason: ApiError) => {
            setSubmitClicked(false);
            const message = isEditMode
                ? `Error: Failed to update calendar template topic ${topic?.name}. Reason: ${reason.message}`
                : `Error: Failed to add calendar template topic ${topic?.name}. Reason: ${reason.message}`;
            dispatch(setHeaderNotification(
                {
                    message,
                    notificationType: NotificationTypes.FAILURE
                },
                5000));
        };

        if (modifiedTemplateTopic && validateInputFields(modifiedTemplateTopic)) {
            const apiCall = isEditMode ? CalendarApi.modifyTemplateTopic : CalendarApi.addTemplateTopic;
            apiCall(modifiedTemplateTopic)
                .then(() => {
                    processSuccess();
                })
                .catch((reason: ApiError) => {
                    processFailure(reason);
                });
            scrollToElement(".main__header", 0);
        }
    };

    const validateInputFields = (modifiedTemplateTopic: ICalendarTemplateTopic): boolean => {
        const modifiedLinks = modifiedTemplateTopic.links;

        return modifiedTemplateTopic.name !== "" &&
            modifiedTemplateTopic.planTypeId !== 0 &&
            modifiedTemplateTopic.frequencyId !== 0 &&
            (modifiedLinks ?
                modifiedLinks.findIndex((it) => !it.link || it.calendarLinkTypeId === 0 || !it.title) === -1
                : true);
    };

    const renderTextField = (name: string, value: string | undefined, mandatory: boolean,
                             onChangeHandler: any) => {
        const formattedName = kebabCase(name.toLowerCase());
        return <div className="admin-calendar-topic__edit-input-field"
                    data-testid={`admin-calendar-topic__edit-${formattedName}`}>
            <TextFieldComponent
                name={`admin-calendar-topic__edit-${formattedName}`}
                className={`admin-calendar-topic__edit-${formattedName}-field`}
                type="text"
                fullWidth={true}
                value={value ? value : ""}
                handleChange=
                    {(e: any) => onChangeHandler(e.target.value)}
                placeholder={name}
                renderRequired={mandatory}
                submitClicked={submitClicked}
            />
        </div>;
    };

    const renderLinkTextField = (name: string, value: string | undefined, onChangeHandler: any,
                                 link: ICalendarTemplateLink, index: number) => {
        const formattedName = kebabCase(name.toLowerCase());
        return <div className="admin-calendar-topic__edit-input-field"
                    data-testid={`admin-calendar-topic__edit-${formattedName}`}>
            <TextFieldComponent
                name={`admin-calendar-topic__edit-${formattedName}`}
                className={`admin-calendar-topic__edit-${formattedName}-field`}
                type="text"
                fullWidth={true}
                value={value ? value : ""}
                handleChange=
                    {(e: any) => onChangeHandler(e.target.value, link, index)}
                placeholder={name}
                renderRequired={true}
                submitClicked={submitClicked}
            />
        </div>;
    };

    const renderDropdown = (name: string, values: ISelectValue[],
                            selectedValue: number | string | undefined, mandatory: boolean,
                            onChangeHandler: any) => {
        const formattedName = kebabCase(name.toLowerCase());
        return <div className="admin-calendar-topic__edit-input-field">
            <SelectComponent
                id={`admin-calendar-topic__edit-${formattedName}`}
                values={values}
                selected={selectedValue}
                width={280}
                handleChange=
                    {(e: any) => onChangeHandler(e.target.value)}
                placeholder={`Select ${name}`}
                renderRequired={mandatory}
                submitClicked={submitClicked}
            />
        </div>;
    };

    const renderLinkTypeDropdown = (selectedValue: number | undefined, link: ICalendarTemplateLink,
                                    index: number) => {
        return <div className="admin-calendar-topic__edit-input-field">
            <SelectComponent
                id={`admin-calendar-topic__edit-link-type`}
                values={LINK_TYPE_VALUES}
                selected={selectedValue}
                width={280}
                handleChange=
                    {(e: any) => updateLinkType(e.target.value, link, index)}
                placeholder={`Select ${LINK_TYPE_LABEL}`}
                renderRequired={true}
                submitClicked={submitClicked}
            />
        </div>;
    };

    const renderButtons = () => {
        return <div>
            <div className="buttons-spacer-top"/>
            <div className="save-and-cancel-buttons">
                <div className="buttons-spacer-left"/>
                <RaisedButton className="admin-calendar-topic-edit__cancel-button"
                              style={secondaryButtonStyle}
                              primary={false}
                              onClick={() => navigateTo("/admin-calendar-topics")}
                >
                    Cancel
                </RaisedButton>
                <div className="buttons-spacer-between"/>
                <RaisedButton className="admin-calendar-topic-edit__save-button"
                              style={prominentButtonStyle}
                              primary={true}
                              onClick={() => handleSave()}
                >
                    Save
                </RaisedButton>
            </div>
            <div className="buttons-spacer-bottom"/>
        </div>;
    };

    const createNewTopic = (): ICalendarTemplateTopic => {
        return {
            id: undefined,
            name: "",
            description: "",
            allowDupes: false,
            frequencyId: 0,
            frequency: "",
            planTypeName: "",
            planTypeId: 0,
            links: []
        };
    };

    const updateLinksState = (link: ICalendarTemplateLink, index: number) => {
        if (topic) {
            const newLinks = [...topic.links];
            newLinks.splice(index, 1, link);
            setTopic({...topic, links: newLinks});
        } else {
            const newTopic: ICalendarTemplateTopic = createNewTopic();
            setTopic({...newTopic, links: [link]});
        }
    };

    const updateLinkType = (linkType: number, link: ICalendarTemplateLink, index: number) => {
        link.calendarLinkTypeId = linkType;
        updateLinksState(link, index);
    };

    const updateLinkTitle = (linkTitle: string, link: ICalendarTemplateLink, index: number) => {
        link.title = linkTitle;
        updateLinksState(link, index);
    };

    const updateLinkUrl = (linkUrl: string, link: ICalendarTemplateLink, index: number) => {
        link.link = linkUrl;
        updateLinksState(link, index);
    };

    const deleteLink = (link: ICalendarTemplateLink, index: number) => {
        const newLinks = [...topic!.links];
        newLinks.splice(index, 1);
        setTopic({...topic!, links: newLinks});
    };

    const renderLink = (link: ICalendarTemplateLink, index: number) => {
        return <div className="admin-calendar-topic-edit__link"
                    data-testid={`admin-calendar-topic-edit__link-${index}`}>
            <div className="admin-calendar-topic__edit-dropdown-container">
                <div className="admin-calendar-topic__edit-input-label">Link Type</div>
                {renderLinkTypeDropdown(link.calendarLinkTypeId, link, index)}
            </div>
            <div className="admin-calendar-topic__edit-link-text-container">
                <div className="admin-calendar-topic__edit-input-label">{LINK_TITLE_LABEL}</div>
                {renderLinkTextField(LINK_TITLE_LABEL, link.title, updateLinkTitle, link, index)}
            </div>
            <div className="admin-calendar-topic__edit-link-text-container">
                <div className="admin-calendar-topic__edit-input-label">{LINK_URL_LABEL}</div>
                {renderLinkTextField(LINK_URL_LABEL, link.link, updateLinkUrl, link, index)}
            </div>
            <div className="admin-calendar-topic__edit-delete-container">
                <a onClick={() => deleteLink(link, index)}>Delete</a>
            </div>
        </div>;
    };

    const renderLinks = (links?: ICalendarTemplateLink[]) => {
        return links ? links.map(renderLink) : null;
    };

    const addNewLink = () => {
        const newLink: ICalendarTemplateLink = {
            id: undefined,
            link: "",
            calendarLinkTypeId: 0,
            title: ""
        };
        if (topic) {
            setTopic({...topic, links: [...topic.links, newLink]});
        } else {
            const newTopic = createNewTopic();
            setTopic({...newTopic, links: [newLink]});
        }
    };

    const renderAddLink = () => {
        return <div className="admin-calendar-topic__edit-add-container">
            <a onClick={addNewLink} data-testid="admin-calendar-topic__edit-add-container">+Add Link</a>
        </div>;
    };

    const updateTopicName = (value: string) => {
        const existingOrNewTopic = topic ? topic : createNewTopic();
        setTopic({...existingOrNewTopic, name: value});
    };

    const updatePlanType = (value: number) => {
        const existingOrNewTopic = topic ? topic : createNewTopic();
        setTopic({...existingOrNewTopic, planTypeId: value});
    };

    const updateFrequency = (value: number) => {
        const existingOrNewTopic = topic ? topic : createNewTopic();
        setTopic({...existingOrNewTopic, frequencyId: value});
    };

    const updateTopicDescription = (value: string) => {
        const existingOrNewTopic = topic ? topic : createNewTopic();
        setTopic({...existingOrNewTopic, description: value});
    };

    const updateDupes = (value: string) => {
        const existingOrNewTopic = topic ? topic : createNewTopic();
        setTopic({...existingOrNewTopic, allowDupes: value === "Yes"});
    };

    const renderContent = () => {
        return <div className="admin-calendar-topic__edit-input-fields">
            <div className="admin-calendar-topic__edit-text-container">
                <div className="admin-calendar-topic__edit-input-label">{TOPIC_NAME}</div>
                {renderTextField(TOPIC_NAME, topic?.name, true, updateTopicName)}
            </div>
            <div className="admin-calendar-topic__edit-dropdown-container">
                <div className="admin-calendar-topic__edit-input-label">{PLAN_TYPE}</div>
                {renderDropdown(PLAN_TYPE, PLAN_TYPE_VALUES, topic?.planTypeId, true, updatePlanType)}
            </div>
            <div className="admin-calendar-topic__edit-dropdown-container">
                <div className="admin-calendar-topic__edit-input-label">{FREQUENCY}</div>
                {renderDropdown
                (FREQUENCY, CALENDAR_FREQUENCY_VALUES, topic?.frequencyId, true, updateFrequency)}
            </div>
            <div className="admin-calendar-topic__edit-text-container">
                <div className="admin-calendar-topic__edit-input-label">{TOPIC_DESCRIPTION_LABEL}</div>
                {renderTextField
                (TOPIC_DESCRIPTION_LABEL, topic?.description, false, updateTopicDescription)}
            </div>
            <div className="admin-calendar-topic__edit-dropdown-container">
                <div className="admin-calendar-topic__edit-input-label">{ALLOW_DUPES_LABEL}</div>
                {renderDropdown
                (ALLOW_DUPES_LABEL, ALLOW_DUPES_VALUES, topic?.allowDupes ? "Yes" : "No", true, updateDupes)}
            </div>
            <div className="admin-calendar-topic__edit-links-container">
                {topic?.links && renderLinks(topic.links)}
                {renderAddLink()}
            </div>
        </div>;
    };

    const renderComponent = () => {
        return <div className="admin-calendar-topic__edit-container">
            <h1> {isEditMode ? "Edit Topic" : "Add Topic"} </h1>
            {renderContent()}
            {renderButtons()}
        </div>;
    };

    return renderComponent();
};

export default withVerifyAccess(CalendarTopicAddEditPage, UserTypeEnum.ADMIN);