import * as React from "react";
import {useEffect, useState} from "react";
import {IOddProductsAccess, IOddQuestionSummary, IOddSectionsQuestions, OddIqApi} from "../../../api/OddIqApi";
import {getSuccessData} from "../../common/commonStates";
import {
    oddCurrentQuestionId,
    oddPortfolioView,
    oddProductsAccessSelector,
    oddProductsRequestPairs,
    oddSectionsQuestionsRequestPair,
    operationalDueDiligenceSectionsQuestionsSelector
} from "../OddIqRequesterPairs";
import {connect, useDispatch} from "react-redux";
import {multipleApiRequesterWrapper} from "../../common/MultipleApiRequesterWrapper";
import {OddIqMethodologyComponent} from "../OddIqMethodology.component";
import {LoadingSpinner} from "../../icons/LoadingSpinner.component";
import {ResultsByRiskCategory} from "./ResultsByRiskCategory";
import {setCurrentOddQuestionId, setPortfolioView} from "../OddIqActions";
import {getPortfolioOddProducts} from "../OddIqUtils";
import {useHistory} from "react-router-dom";
import {SummaryByOddProduct} from "./SummaryByOddProduct";
import {TopRisks} from "./TopRisks";
import {IApplicationRootState} from "../../../../applicationState";

export interface IOddIqNoBackstopPageProps {
    oddProductsAccess: IOddProductsAccess;
    oddSectionsQuestions: IOddSectionsQuestions;
    currentQuestionId?: number;
    oddPortfolioView: boolean;
}

export interface IOddSummaries {
    portfolioSummaries: IOddQuestionSummary[];
    allSummaries: IOddQuestionSummary[];
}

export const OddIqNoBackstopPage: React.FunctionComponent<IOddIqNoBackstopPageProps> = (props) => {
    const history = useHistory();
    const dispatch = useDispatch();
    const [questionSummaries, setQuestionSummaries] =
        useState<IOddSummaries | undefined>(undefined);

    useEffect(() => {
        if (history.action !== "POP") {
            dispatch(setCurrentOddQuestionId(undefined));
        }

        OddIqApi.requestOddQuestionSummaries()
            .then((response) => {
                const allSummaries = response;
                    if (props.oddProductsAccess.allAndPortfolioAccess) {
                        OddIqApi.requestOddQuestionSummariesForPortfolio()
                            .then((portfolioResponse) => {
                                    setQuestionSummaries({
                                        portfolioSummaries: portfolioResponse,
                                        allSummaries
                                    });
                                }
                            );
                    } else {
                        setQuestionSummaries({
                            portfolioSummaries: [],
                            allSummaries
                        });
                    }
                }
            );

    }, []);

    const getRefreshKey = () => {
        return props.oddPortfolioView
            ? 1
            : 2;
    };

    const renderContent = () => {
        if(props.oddProductsAccess.accessibleProducts.length === 0) {
            return <span className="odd-page__no-products">No ODD products for current plan</span>;
        }

        if(!questionSummaries) {
            return <LoadingSpinner/>;
        }

        const oddProducts = props.oddProductsAccess.allAndPortfolioAccess && props.oddPortfolioView
            ? getPortfolioOddProducts(props.oddProductsAccess)
            : props.oddProductsAccess.accessibleProducts;

        const summaries = props.oddProductsAccess.allAndPortfolioAccess && props.oddPortfolioView
            ? questionSummaries?.portfolioSummaries
            : questionSummaries?.allSummaries;

        return <div key={getRefreshKey()}>
                <TopRisks oddQuestionSummaries={summaries}
                          oddSectionsQuestions={props.oddSectionsQuestions}
                />
                <ResultsByRiskCategory oddQuestionSummaries = {summaries}
                                       oddSectionsQuestions = {props.oddSectionsQuestions}
                                       currentQuestionId = {props.currentQuestionId}

                />
                <SummaryByOddProduct
                    oddProducts={oddProducts}
                />
            </div>;
    };

    const changeView =  (portfolioView: boolean) => {
        dispatch(setCurrentOddQuestionId(undefined));
        dispatch(setPortfolioView(portfolioView));
    };

    const renderAllPortfolioToggle = () => {
        return props.oddProductsAccess.allAndPortfolioAccess
            ? <span>
            <div className="odd-page__portfolio-all-toggle">
                <div
                    id="odd-page__portfolio-button"
                    className={props.oddPortfolioView ? " activated" : " clickable"}
                    onClick={() => changeView(true)}
                >
                    Portfolio Strategies
                </div>
                <div
                    id="odd-page__all-button"
                    className={props.oddPortfolioView ? " clickable" : " activated"}
                    onClick={() => changeView(false)}
                >
                    All Strategies
                </div>
            </div>
        </span>
            : null;
    };

    return <div>
                {renderAllPortfolioToggle()}
                <div className="odd-page__methodology-container">
                    <div className="odd-page__description">
                        The information below provides a high-level summary of potential operational risks within
                        a defined portfolio of Investment Strategies. The Portfolio summary allows users to easily
                        review risk assessment results in aggregate, identify concentrations, and prioritize unique
                        pockets of risk.
                    </div>
                    <div className="odd-iq__slider-container">
                        <OddIqMethodologyComponent mode="Portfolio"/>
                    </div>
                </div>
                {renderContent()}
            </div>;
};

export const mapStateToProps = (state: IApplicationRootState): IOddIqNoBackstopPageProps => {
    return {
        oddProductsAccess: getSuccessData(oddProductsAccessSelector(state))!,
        oddSectionsQuestions: getSuccessData(operationalDueDiligenceSectionsQuestionsSelector(state))!,
        currentQuestionId: oddCurrentQuestionId(state),
        oddPortfolioView: oddPortfolioView(state),
    };
};

const connected = connect<IOddIqNoBackstopPageProps, {}>(mapStateToProps)(OddIqNoBackstopPage);

export default multipleApiRequesterWrapper(
    connected, [
        oddProductsRequestPairs,
        oddSectionsQuestionsRequestPair,
    ]
);