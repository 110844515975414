import {Action, createAction} from "redux-actions";
import {ThunkAction} from "redux-thunk";
import ProductApi from "../../api/ProductApi";
import {Product} from "../../model/product/Product.model";

export enum ProductStoreActions {
    ADD_PRODUCT_PREVIEW = "ADD_PRODUCT_PREVIEW",
}

export const addProductPreview = createAction<Product, Product>(
    ProductStoreActions.ADD_PRODUCT_PREVIEW,
    (product: Product) => product,
);

const requestProductPreview = (backstopId: number): ThunkAction<Promise<void>, void, null, Action<Product>> => {
    return (dispatch) => {
        return ProductApi.requestProduct(backstopId)
            .then((product) => {
                dispatch(addProductPreview(product));
            });
    };
};

export default {
    requestProductPreview,
};
