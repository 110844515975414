import {Map as ImmutableMap} from "immutable";
import * as React from "react";
import {connect} from "react-redux";
import {withRouter} from "react-router";
import {bindActionCreators, Dispatch} from "redux";
import {navigateTo} from "../../../navigateTo";
import {IUserOktaInfo, UserApi} from "../../api/UserApi";
import {ClientsAndPlans, IClientInfo, IPlanInfo} from "../../model/ClientsAndPlans.model";
import {getUserTypes, IUserInfo, UserInfo, UserTypeEnum} from "../../model/UserInfo.model";
import {prominentButtonStyle, secondaryButtonStyle} from "../common/buttonStyles";
import {RaisedButton} from "../common/RaisedButton";
import {byName} from "../../utils/listUtil";
import {ErrorComponent} from "../base/Error.component";
import {setHeaderNotification} from "../base/header/HeaderActions";
import {NotificationTypes} from "../base/header/HeaderReducer";
import {RequestSucceeded} from "../common/commonStates";
import {ISelectValue, SelectComponent} from "../common/Select.component";
import adminActions from "./adminActions";
import AdminAddPlanComponent from "./AdminAddPlan.component";
import {IAdminState} from "./AdminReducer";
import {capitalizeFirstLetter} from "../../utils/commonUtil";
import {LoadingSpinner} from "../icons/LoadingSpinner.component";
import {IApplicationRootState} from "../../../applicationState";

export interface IAdminUserEditComponentPropsFromLocation {
    match: { params: { arbtSecurityId: string } };
    router?: any;
}

type IAdminUserEditComponentPropsFromReduxStore = IAdminState;

export interface IAdminUserEditActions {
    actions: {
        updateUserDetails: typeof adminActions.updateUserDetails;
        setHeaderNotification: typeof setHeaderNotification;
    };
}

export type AdminUserEditComponentProps =
    IAdminUserEditComponentPropsFromLocation
    & IAdminUserEditActions
    & IAdminUserEditComponentPropsFromReduxStore;

interface IAdminUserEditComponentState {
    loading: boolean;
    isUserFound: boolean;
    userInfo: IUserInfo;
    allPlans: ImmutableMap<number, IPlanInfo>;
    resendEmailClicked: boolean;
    resetPasswordClicked: boolean;
    submitClicked: boolean;
    userOktaInfo: IUserOktaInfo;
    originalUserOktaInfo: IUserOktaInfo;
    resetMfaFactorsClicked: boolean;
}

export const  MFA_SELECT_VALUES: ISelectValue [] = [
    {name: "MFA", id: 1},
    {name: "Non MFA", id: 0},
];


export class AdminUserEditComponent extends React.Component<AdminUserEditComponentProps, IAdminUserEditComponentState> {

    public constructor(props: any) {
        super(props);

        this.state = {
            loading: true,
            isUserFound: false,
            userInfo: {} as UserInfo,
            allPlans: ImmutableMap<number, IPlanInfo>(),
            resendEmailClicked: false,
            resetPasswordClicked: false,
            submitClicked: false,
            userOktaInfo: {} as IUserOktaInfo,
            originalUserOktaInfo: {} as IUserOktaInfo,
            resetMfaFactorsClicked: false
        };
    }


    public componentDidMount() {
        const userInfoMaybe = this.findUserInfo();

        if (userInfoMaybe !== null) {
            UserApi.getUserOktaInfo(userInfoMaybe.arbtSecurityId)
                .then((userOktaInfo) => {
                    this.setState({
                        loading: false,
                        isUserFound: true,
                        userInfo: userInfoMaybe,
                        allPlans: this.getAllPlans(),
                        userOktaInfo,
                        originalUserOktaInfo: userOktaInfo
                    });
                });
        } else {
            this.setState({
                loading: false
            });
        }
    }

    public render() {
        return this.state.loading
        ? <LoadingSpinner/>
        : this.state.isUserFound ? this.renderAllInfo() : <ErrorComponent/>;
    }

    public renderAllInfo() {
        return <div className="admin-user-edit">
            <h1>Editing User</h1>
            <h3>{`Okta Groups: ${this.state.userOktaInfo.groups}`}</h3>
            {this.renderUserInfoSection()}
            {this.renderUserPlansSection()}
            {this.renderAddPlanComponent()}
            {this.renderButtons()}
        </div>;
    }

    private renderAddPlanComponent() {
        return <div>
            <h2>Add Plan(s)</h2>
            <AdminAddPlanComponent
                onAddPlan={this.onAddPlan}
                renderRequired={true}
                submitClicked={this.state.submitClicked}
            />
        </div>;
    }

    private renderUserInfoSection() {
        const userInfo = this.state.userInfo!;
        const userOktaInfo = this.state.userOktaInfo;
        return <div className="admin-user-edit__user-info" data-testid="admin-user-edit__user-info">
            <table className="admin-user-edit__user-info-table">
                <tbody>
                    <tr className="admin-user-edit__user-table-row">
                        <td className="admin-user-edit__user-table-column admin-user-edit__user-info__first-name">
                            {userInfo.firstName}
                        </td>
                        <td className="admin-user-edit__user-table-column admin-user-edit__user-info__last-name">
                            {userInfo.lastName}
                        </td>
                        <td className="admin-user-edit__user-table-column admin-user-edit__user-info__email">
                            {userInfo.email}
                        </td>
                        <td className="admin-user-edit__user-table-column admin-user-edit__user-info__drop-down">
                            {this.renderUserTypeDropDown(capitalizeFirstLetter(userInfo.userType.toString()))}
                        </td>
                        <td className="admin-user-edit__user-table-column admin-user-edit__user-info__drop-down">
                            {this.renderMfaGroupDropDown(userOktaInfo.isMfa)}
                        </td>
                        <td className="admin-user-edit__user-table-column admin-user-edit__user-info__status">
                            {userOktaInfo.status}
                        </td>
                        <td className="admin-user-edit__user-table-column admin-user-edit__user-info__last-login-date">
                            {userInfo.lastLoginDate || "Never"}
                        </td>
                    </tr>
                </tbody>
            </table>
        </div>;
    }

    private getAllPlans(): ImmutableMap<number, IPlanInfo> {
        const clientsAndPlans = (this.props.adminClientInfoState as RequestSucceeded<ClientsAndPlans>).data;

        return clientsAndPlans
            .reduce(
                (old: ImmutableMap<number, IPlanInfo> , current: IClientInfo) => old.merge(current.plans),
                ImmutableMap<number, IPlanInfo>(),
            );
    }

    private getUserPlans() {
        return this.state.userInfo.planIds
            .map((planId: number) => {
                return this.state.allPlans.get(planId)!;
            })
            .sort(byName);
    }

    private renderUserPlansSection() {
        const renderUserPlan = (x: IPlanInfo) => {
            return <div className="admin-user-edit__user-plan" key={x.id}>
                <div className="remove-icon fas fa-x" onClick={() => this.onRemovePlan(x)}/>
                <div>{x.name}</div>
                <br/>
            </div>;
        };

        return <div className="admin-user-edit__user-plans">
            <h2>Current Plans</h2>
                {this.getUserPlans().map(renderUserPlan)}
        </div>;
    }

    private renderUserTypeDropDown(userType: string) {
        const userTypes: ISelectValue [] = getUserTypes();

        return <SelectComponent
                id={"user-edit__user-type"}
                values={userTypes}
                selected={userType}
                width={150}
                submitClicked={false}
                handleChange={this.handleUserTypeChange}
                placeholder={"User Type"}
                renderRequired={false}
            />;
    }

    private renderMfaGroupDropDown(mfa: boolean) {
        return <SelectComponent
            id={"user-edit__user-mfa"}
            values={MFA_SELECT_VALUES}
            selected={Number(mfa)}
            width={150}
            submitClicked={false}
            handleChange={this.handleMfaChange}
            placeholder={"User Type"}
            renderRequired={false}
        />;
    }

    private handleUserTypeChange = (e: any) => {
        this.setState({userInfo: {...this.state.userInfo, userType: e.target.value.toUpperCase() as UserTypeEnum}});
    };

    private handleMfaChange = (e: any) => {
        this.setState({userOktaInfo: {...this.state.userOktaInfo, isMfa: Boolean(e.target.value)}});
    };

    private handleApiResult = (apiResult: Promise<any>,
                               successMessage: string,
                               failureMessage: string,
                               finallyFunc: () => void) => {
        apiResult.then(
            () => {
                this.props.actions.setHeaderNotification({
                    message: successMessage,
                    notificationType: NotificationTypes.SUCCESS,
                }, 5000);
            },
            () => {
                this.props.actions.setHeaderNotification({
                    message: failureMessage,
                    notificationType: NotificationTypes.FAILURE,
                }, 5000);
            },
        ).finally(finallyFunc);
    };

    private handleResetMfaFactors = (arbtSecurityId: string) => {
        if (this.state.resetMfaFactorsClicked) {
            return;
        }
        this.setState({resetMfaFactorsClicked: true});
        window.scrollTo(0, 0);

        const func = () => {this.setState({resetMfaFactorsClicked: false});};
        const api = UserApi.resetMfaFactors(arbtSecurityId);
        this.handleApiResult(api, "MFA factors have been reset successfully",
            "Unable to reset MFA factors", func);
    };

    private renderResetMfaFactors = (arbtSecurityId: string) => {
        const linkClassName = this.state.resetMfaFactorsClicked ? "clicked-link-14" : "clickable";
        return this.state.originalUserOktaInfo.isMfa
        ? <div className="admin-user-edit__reset-mfa-container">
             <a className={`admin-user-edit__reset-mfa-link ${linkClassName}`}
              onClick={() => this.handleResetMfaFactors(arbtSecurityId)}>
                Reset MFA Factors
            </a>
         </div>
        : null;
    };

    private renderButtons() {
        const linkClassName = this.state.resendEmailClicked ? "clicked-link-14" : "clickable";
        const linkPwdClassName = this.state.resetPasswordClicked ? "clicked-link-14" : "clickable";
        const arbtSecurityId = this.props.match.params.arbtSecurityId;
        return <div>
            <div className="buttons-spacer-top"/>
            <div className="save-and-cancel-buttons">
                <a className={`admin-user-edit__send-email-link ${linkClassName}`}
                   onClick={() => this.handleSendEmailClick(arbtSecurityId)}>
                    Resend Welcome Email
                </a>
                <div className="buttons-spacer-left"/>
                <RaisedButton className="admin-user-edit__cancel-button"
                              style={secondaryButtonStyle}
                              primary={false}
                              onClick={() => navigateTo("/admin")}>
                    Cancel
                </RaisedButton>
                <div className="buttons-spacer-between"/>
                <RaisedButton className="admin-user-edit__save-button"
                              style={prominentButtonStyle}
                              primary={true}
                              onClick={() => this.handleSave()}>
                    Save
                </RaisedButton>
            </div>
            <a className={`admin-user-edit__reset-password-link ${linkPwdClassName}`}
               onClick={() => this.handleResetPassword(arbtSecurityId)}>
                Reset Password
            </a>
            {this.renderResetMfaFactors(arbtSecurityId)}
            <div className="buttons-spacer-bottom"/>
        </div>;
    }

    private handleSave() {
        if (this.state.userInfo.planIds.length === 0) {
            this.setState({submitClicked: true});
            this.props.actions.setHeaderNotification({
                message: "Please complete all required fields before saving",
                notificationType: NotificationTypes.FAILURE,
            }, 5000);
            return;
        }

        this.props.actions.updateUserDetails(
            this.state.userInfo.arbtSecurityId,
            this.state.userInfo.userType,
            this.state.userInfo.planIds,
            this.state.userOktaInfo.isMfa
        );
    }

    private handleSendEmailClick = (arbtSecurityId: string) => {
        if (this.state.resendEmailClicked) {
            return;
        }

        this.setState({resendEmailClicked: true});

        window.scrollTo(0, 0);

        const func = () => {this.setState({resendEmailClicked: false});};
        const api = UserApi.sendRegistrationEmail(arbtSecurityId);
        this.handleApiResult(api, "Registration email successfully sent",  "Error: Unable to send registration email", func);
    };

    private handleResetPassword = (arbtSecurityId: string) => {
        if (this.state.resetPasswordClicked) {
            return;
        }

        this.setState({resetPasswordClicked: true});

        window.scrollTo(0, 0);

        const func = () => {this.setState({resetPasswordClicked: false});};
        const api = UserApi.sendResetPasswordEmail(arbtSecurityId);
        this.handleApiResult(api, "Reset password email successfully sent",  "Error: Unable to send reset password email", func);
    };

    private findUserInfo() {
        const adminUsersInfoState =
            this.props.adminUsersInfoState as RequestSucceeded<IUserInfo[]>; // Do NOT render me without the loader
        const findResult = adminUsersInfoState.data
            .find((x) => x.arbtSecurityId === this.props.match.params.arbtSecurityId);
        return findResult || null;
    }

    private onAddPlan = (plan: IPlanInfo) => {
        const planIds = this.state.userInfo.planIds
            .filter((planId) => planId !== plan.id);

        planIds.push(plan.id);

        this.setState({
            userInfo: {...this.state.userInfo, planIds},
            submitClicked: false,
        });
    };

    private onRemovePlan = (plan: IPlanInfo) => {
        this.setState({
            userInfo: {...this.state.userInfo, planIds: this.state.userInfo.planIds.filter((x) => x !== plan.id)},
        });
    };

}

export const mapStateToProps = (state: IApplicationRootState): IAdminUserEditComponentPropsFromReduxStore => {
    return {
        ...state.adminState!,
    };
};

export const mapDispatchToProps = (dispatch: Dispatch): IAdminUserEditActions => {
    return {
        actions: bindActionCreators ({
            updateUserDetails: adminActions.updateUserDetails,
            setHeaderNotification,
        }, dispatch),
    };
};

const connectedComponent = connect<IAdminUserEditComponentPropsFromReduxStore, IAdminUserEditActions>
    (mapStateToProps, mapDispatchToProps)(withRouter(AdminUserEditComponent));

export default connectedComponent;
