import * as React from "react";
import {getMonthAndYearString, parseShortDate} from "../../../utils/dateUtil";
import {formatCurrency} from "../../../utils/numberUtil";
import {
    cashflowGroupAndSortByDate,
    cashflowGroupAndSortByQuarter,
    ICashflow,
    ICashflowsProps,
} from "./CashFlowsUtils";

export interface ICashflowsDataTableProps extends ICashflowsProps {
    byQuarter: boolean;
}

export const CashFlowsOverTimeDataTable: React.FunctionComponent<ICashflowsDataTableProps> = (props) => {
    const renderMonthlyRows = () => {
        return cashflowGroupAndSortByDate(props.holdings, false)
            .map((cashflow: ICashflow, key: string) => renderCashflow(
                cashflow,
                getMonthAndYearString(parseShortDate(key)),
            )).valueSeq().toArray();
    };

    const renderQuarterlyRows = () => {
        return cashflowGroupAndSortByQuarter(props.holdings, false)
            .map((cashflow: ICashflow, key: string) => renderCashflow(cashflow, key)).valueSeq().toArray();
    };

    const renderCashflow = (cashflow: ICashflow, key: string) => {
        return <tr key={key} className="small">
            <td>{key}</td>
            <td>{formatCurrency(cashflow.cashflow)}</td>
            <td>{formatCurrency(cashflow.inflow)}</td>
            <td>{formatCurrency(cashflow.outflow)}</td>
        </tr>;
    };

    return <div className="cash-flows__data-table-container" data-testid="cash-flows__data-table-container">
        <table>
            <thead>
            <tr className="small bold" data-testid="cash-flows__data-table-header">
                <th>{props.byQuarter ? "Quarter" : "Month"}</th>
                <th>Net Cashflow</th>
                <th>Inflow</th>
                <th>Outflow</th>
            </tr>
            </thead>
            <tbody>{props.byQuarter ? renderQuarterlyRows() : renderMonthlyRows()}</tbody>
        </table>
    </div>;
};

CashFlowsOverTimeDataTable.displayName = "CashFlowsDataTable";
