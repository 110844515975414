import * as React from "react";
import {IOddQuestionSummary, IOddSectionsQuestions} from "../../../api/OddIqApi";
import {compareInsensitive} from "../../../utils/listUtil";
import {useDispatch} from "react-redux";
import {setCurrentOddQuestionId} from "../OddIqActions";

export interface ITopRisksProps {
    oddQuestionSummaries: IOddQuestionSummary[];
    oddSectionsQuestions: IOddSectionsQuestions;
}

export const TopRisks: React.FunctionComponent<ITopRisksProps> = (props) => {
    const summariesWithExceptions = props.oddQuestionSummaries
        .filter((qs) => qs.summary.exceptions > 0);

    const getQuestionName = (questionId: number) => {
        const question = props.oddSectionsQuestions.questions.find((q) => q.id === questionId);
        return question ? question.questionTitle : "";
    };

    const byTopRisk = (item1: IOddQuestionSummary, item2: IOddQuestionSummary) => {
        const exceptionCompare = (item2.summary.exceptions - item1.summary.exceptions);
        return exceptionCompare === 0 ? compareInsensitive(
            getQuestionName(item1.questionId), getQuestionName(item2.questionId)
        ) : exceptionCompare;
    };

    const byLowRisk = (item1: IOddQuestionSummary, item2: IOddQuestionSummary) => {
        const exceptionCompare = (item1.summary.exceptions - item2.summary.exceptions);
        return exceptionCompare === 0 ? compareInsensitive(
            getQuestionName(item1.questionId), getQuestionName(item2.questionId)
        ) : exceptionCompare;
    };


    const getTopRiskQuestions = (): IOddQuestionSummary[] => {
        return summariesWithExceptions
            .sort(byTopRisk)
            .slice(0, 5);
    };

    const getTopUniqueQuestions = ():  IOddQuestionSummary[] => {
        return summariesWithExceptions
            .sort(byLowRisk)
            .slice(0, 5);
    };

    const dispatch = useDispatch();

    const setCurrentQuestion = (questionId: number) => () => {
        dispatch(setCurrentOddQuestionId(questionId));
    };
    const renderRiskQuestion = (className: string) => (value: IOddQuestionSummary, index: number) => {
        return <tr key={`${className}-${index}`} className={className}>
            <td className={`clickable ${className}-name`} onClick={setCurrentQuestion(value.questionId)}>
                {getQuestionName(value.questionId)}
            </td>
            <td className={`${className}-count`}>{value.summary.exceptions}</td>
        </tr>;
    };

    return <div className="odd-page__odd-top-risks">
        <div className="odd-top-risks__header-container">
            <h3>Top Portfolio Risks</h3>
        </div>
        <div className="odd-top-risks__container">
            <div className="odd-top-risks__questions-container">
                <h5>Top 5 Most Common Risks</h5>
                <div className="odd-top-risks__questions">
                    <table>
                        <thead>
                            <tr>
                                <th className="column-header">Risk</th>
                                <th className="column-header">Strategies</th>
                            </tr>
                        </thead>
                        <tbody>
                            {getTopRiskQuestions().map(renderRiskQuestion("odd-top-risks__question"))}
                        </tbody>
                    </table>
                </div>
            </div>
            <div className="odd-top-risks__separator" />
            <div className="odd-top-risks__unique-risks-container">
                <h5>Top 5 Unique Risks</h5>
                <div className="odd-unique-risks__questions">
                    <table>
                        <thead>
                            <tr>
                                <th className="column-header">Risk</th>
                                <th className="column-header">Strategies</th>
                            </tr>
                        </thead>
                        <tbody>
                            {getTopUniqueQuestions().map(renderRiskQuestion("odd-unique-risks__question"))}
                        </tbody>
                    </table>
                </div>
            </div>
        </div>
    </div>;
};