import * as React from "react";
import {InsightsApi} from "../../api/InsightsApi";
import {WhitePaper} from "../../model/thought-leadership/WhitePaper.model";
import {byDate, formatShortDate} from "../../utils/dateUtil";

export interface IWhitePaperDocumentsProps {
    documents: WhitePaper[];
}

export const WhitePaperDocumentsComponent: React.FunctionComponent<IWhitePaperDocumentsProps> =
    (props: IWhitePaperDocumentsProps) => {
    function renderSeparator() {
        return <div className="document-list__document-separator">
            <div className="spacer-dash--small"/>
        </div>;
    }

    function isLast(index: number) {
        return index === (props.documents.length - 1);
    }

    function open(document: WhitePaper) {
        if (document.isUri) {
            window.open(document.fileUri, "_blank");
        } else {
            InsightsApi.getDocumentDownload(document.backstopId, document.fileName, true);
        }
    }

    return <ul>
            {props.documents
                .sort(byDate)
                .map((document, index) =>
                    <li key={document.backstopId} className={`document-${document.backstopId}`}>
                        <div className="document-list__document" data-testid="document-list__document">
                            <div>
                                <div className="document-list__document__info__title clickable-document-title
                                                document-title-16"
                                     onClick={() => open(document)}>
                                    {document.title}
                                </div>
                                <div className="document-list__document-date date-12">
                                    {formatShortDate(document.date)}
                                </div>
                            </div>
                        </div>
                        {isLast(index) ? "" : renderSeparator()}
                    </li>)
            }
        </ul>;
};
