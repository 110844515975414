import {UserTypeEnum} from "../model/UserInfo.model";

export interface INavigationMenu {
    id: string;
    paths: string[];
    label: string;
    elementId: string;
    path: string;
}

export enum NavigationTab {
    SHARED_DOCUMENTS = "shared-documents",
    MANAGER_RESEARCH = "research",
    INSIGHTS = "insights",
    MASTER_LECTURE_SERIES = "master-lecture-series",
    ODD_IQ = "odd-iq",
    ODD_REPORTS = "odd-reports",
    CLIENT_CALENDAR = "calendar",
    CLIENT_REPORTING = "clientReportingItem",
    POWERBI_REPORT_ADMIN = "powerbi-report-admin",
    CPR_SHARED_MEDIA_ADMIN = "cpr-shared-media-admin",
    PORTFOLIO = "portfolio",
}

export const ResearchMenu: INavigationMenu = {
    id: NavigationTab.MANAGER_RESEARCH,
    paths: [
        "research",
        "products",
        "strategy-updates",
        "meeting-notes",
        "flash-reports",
        "managers",
        "investments",
        "performance-reporting",
    ],
    label: "Managers & Strategies",
    elementId: "navigation-tab__research",
    path: "/research",
};

export const PortfolioMenu: INavigationMenu = {
    id: NavigationTab.PORTFOLIO,
    paths: [
        "portfolio",
        "products",
        "strategy-updates",
        "meeting-notes",
        "flash-reports",
        "managers",
        "investments",
    ],
    label: "Portfolio",
    elementId: "navigation-tab__portfolio",
    path: "/portfolio",
};

export const ThoughtLeadershipMenu: INavigationMenu = {
    id: NavigationTab.INSIGHTS,
    paths: ["white-papers", "market-outlooks"],
    label: "Insights",
    elementId: "navigation-tab__insights",
    path: "/white-papers",
};

export const SharedDocumentsMenu: INavigationMenu = {
    id: NavigationTab.SHARED_DOCUMENTS,
    paths: ["shared-documents"],
    label: "Shared Documents",
    elementId: "navigation-tab__shared-documents",
    path: "/shared-documents",
};

export const MasterLectureSeriesMenu: INavigationMenu = {
    id: NavigationTab.MASTER_LECTURE_SERIES,
    paths: ["master-lecture-series"],
    label: "Master Lecture Series",
    elementId: "navigation-tab__master-lecture-series",
    path: "/master-lecture-series",
};

export const OddPortfolioMenu: INavigationMenu = {
    id: NavigationTab.ODD_IQ,
    paths: [
        "odd-iq",
        "odd",
    ],
    label: "Operations",
    elementId: "navigation-tab__odd-iq",
    path: "/odd-iq",
};

export const OddReportsMenu: INavigationMenu = {
    id: NavigationTab.ODD_REPORTS,
    paths: ["odd-reports"],
    label: "ODD Reports",
    elementId: "navigation-tab__odd-reports",
    path: "/odd-reports",
};

export const ClientCalendarMenu: INavigationMenu = {
    id: NavigationTab.CLIENT_CALENDAR,
    paths: ["calendar"],
    label: "Calendar",
    elementId: "navigation-tab__calendar",
    path: "/calendar",
};

export const ClientReportingMenu: INavigationMenu = {
    id: NavigationTab.CLIENT_REPORTING,
    paths: ["client-reporting"],
    label: "Client Reporting",
    elementId: "navigation-tab__client-reporting",
    path: "/client-reporting",
};

export const PowerbiReportAdminMenu: INavigationMenu = {
    id: NavigationTab.POWERBI_REPORT_ADMIN,
    paths: ["powerbi-report-admin"],
    label: "PowerBI Report Admin",
    elementId: "navigation-tab__powerbi-report-admin",
    path: "/powerbi-report-admin",
};

export const CprSharedMediaAdminMenu: INavigationMenu = {
    id: NavigationTab.CPR_SHARED_MEDIA_ADMIN,
    paths: ["cpr-shared-media-admin"],
    label: "CPR Shared Media Files",
    elementId: "navigation-tab__cpr-shared-media-admin",
    path: "/cpr-shared-media-admin",
};

export const AllNavigationMenus: INavigationMenu[] = [
    PortfolioMenu,
    ResearchMenu,
    ClientReportingMenu,
    OddPortfolioMenu,
    SharedDocumentsMenu,
    ThoughtLeadershipMenu,
    MasterLectureSeriesMenu,
    OddReportsMenu,
    ClientCalendarMenu,
    PowerbiReportAdminMenu,
    CprSharedMediaAdminMenu
];

export const showInvestments = (hasHoldings: boolean, userType: UserTypeEnum, investmentsEnabled: boolean): boolean => {
    return hasHoldings
        ? (userType === UserTypeEnum.CLIENT
            ? investmentsEnabled
            : true)
        : false;
};
