export class ReleaseNote {
    public static builder(): ReleaseNoteBuilder {
        return new ReleaseNoteBuilder();
    }

    public constructor(public readonly note: string,
                       public readonly date: Date,
                       public readonly id: number) {}
}

export interface INewReleaseNote {
    note: string;
    date: Date;
}

class ReleaseNoteBuilder {
    private mNote: string;
    private mDate: Date;
    private mId: number;

    public note(value: string) {
        this.mNote = value;
        return this;
    }

    public date(value: Date) {
        this.mDate = value;
        return this;
    }

    public id(value: number) {
        this.mId = value;
        return this;
    }

    public build(): ReleaseNote {
        return new ReleaseNote(this.mNote || "", this.mDate || new Date(), this.mId || 0);
    }
}
