import * as React from "react";
import {CONTENT_PLACEHOLDER, TOPIC_PLACEHOLDER} from "./CprKeyPoints";
import {ChangeEvent} from "react";
import {IKeyPoint} from "../../api/CprPortfolioApi";

export interface IKeyPointProps {
    index: number;
    editMode: boolean;
    keyPoint: IKeyPoint;
    onChange: (index: number, modified: IKeyPoint) => void;
    onDelete: (index: number) => void;
}

export const CprKeyPoint: React.FunctionComponent<IKeyPointProps> = (props: IKeyPointProps) => {

    const onTitleChange = (event: ChangeEvent<HTMLInputElement>) => {
        props.onChange(props.index,
            {...props.keyPoint, title: event.target.value});
    };

    const onContentChange = (event: ChangeEvent<HTMLTextAreaElement>) => {
        props.onChange(props.index,
            {...props.keyPoint, content: event.target.value});
    };

    const renderViewMode = () => {
        return (
            <div className="key-point__view-container" data-testid="kp-view-mode-container">
                <div className="key-point__view-title" data-testid="key-point__view-title">
                    <h5>{props.keyPoint.title}</h5>
                </div>
                <div className="key-point__view-content" data-testid="key-point__view-content">
                    <pre>{props.keyPoint.content}</pre>
                </div>
            </div>
        );
    };

    const renderEditMode = () => {
        return <div className="key-point__edit-container" data-testid="key-point__edit-container">
            <div className="key-point__editor">
                <div className="key-point__title">
                    <input
                        type="text"
                        className="key-point__title-input"
                        multiple={false}
                        onChange={onTitleChange}
                        value={props.keyPoint.title}
                        placeholder={TOPIC_PLACEHOLDER}
                        role="key-point__title-input"
                    />
                </div>
                <div className="key-point__delete">
                    <div data-testid="key-point__delete-icon" className="fas fa-trash trash-can"
                         onClick={() => props.onDelete(props.index)}/>
                </div>
            </div>
            <div className="key-point__content-editor">
                        <textarea
                            className="key-point__content-textarea"
                            placeholder={CONTENT_PLACEHOLDER}
                            onChange={onContentChange}
                            value={props.keyPoint.content}
                            role="key-point__content-textarea"
                        />
            </div>
        </div>;
    };

    const renderContent = () => {
        return props.editMode ? renderEditMode() : renderViewMode();
    };

    return renderContent();
};