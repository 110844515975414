import * as React from "react";

export interface IHiddenFileInputProps {
    action: (e: { target: { files: FileList | null } }) => void;
    multiple: boolean;
}

export class HiddenFileInput extends React.Component<IHiddenFileInputProps> {

    constructor(props: IHiddenFileInputProps) {
        super(props);
    }

    public render() {
        return <input
            className="hidden-file-input"
            id="the-hidden-file-input"
            data-testid="hidden-file-input"
            onChange={this.props.action}
            type="file"
            multiple={this.props.multiple}
        />;
    }
}
