import * as React from "react";
import {useState} from "react";
import {IHolding} from "../../api/HoldingsApi";
import {InvestmentsByGroupRow} from "./InvestmentsByGroupRow.component";
import {getHoldingsForMonth, getSumsByAsset, getSumsByTier, IData} from "./InvestmentsDataUtil";
import {HoldingsGroupBy} from "./InvestmentsPage";
import {StyledToggle} from "../common/material-ui-core/Toggle.component";
import {AonColors} from "../../../../css/Colors";

export interface IInvestmentAllocationFromParent {
    holdingsForPlan: IHolding[];
    holdingsUniverse: IHolding[];
    year: number;
    month: number;
    groupBy: HoldingsGroupBy;
}
export const InvestmentsByGroupTableComponent: React.FunctionComponent<IInvestmentAllocationFromParent>
    = (props) => {
    const [isExpandAll, setIsExpandAll] = useState<boolean>(false);
    const [showUniverse, setShowUniverse] = useState<boolean>(false);

    const monthlyHoldingsByPlan = getHoldingsForMonth(props.holdingsForPlan, props.year, props.month);
    const monthlyHoldingsUniverse = getHoldingsForMonth(props.holdingsUniverse, props.year, props.month);

    const assetsByPlan: IData[] = props.groupBy === HoldingsGroupBy.BY_ASSET_CLASS
             ? getSumsByAsset(monthlyHoldingsByPlan)
             : getSumsByTier(monthlyHoldingsByPlan);

    const assetsUniverse: IData[] = props.groupBy === HoldingsGroupBy.BY_ASSET_CLASS
        ? getSumsByAsset(monthlyHoldingsUniverse)
        : getSumsByTier(monthlyHoldingsUniverse);

    const renderAssetRow = (byPlanAsset: IData) => {
        const universeAsset =
            assetsUniverse.find((universeData) => universeData.name === byPlanAsset.name)!;

        return <InvestmentsByGroupRow key={byPlanAsset.name}
                                      holdingsForPlan={props.holdingsForPlan}
                                      year={props.year}
                                      month={props.month}
                                      currentGroupByPlanData={byPlanAsset}
                                      currentGroupUniverseData={universeAsset}
                                      allGroupsByPlanData={assetsByPlan}
                                      allGroupsUniverseData={assetsUniverse}
                                      monthlyHoldings={monthlyHoldingsByPlan}
                                      isExpanded={isExpandAll}
                                      groupBy={props.groupBy}
                                      showUniverse={showUniverse}
        />;
    };

    const renderWeightHeader = () => {
        return showUniverse
            ? <th className="column-header investments__weight">Over/Under Weight</th>
            : null;
    };

    return <table className="investments__asset-allocation-table">
            <thead>
            <tr className="investments__asset-allocation-table-row"
                data-testid="investments__asset-allocation-table-header-row">
                <th className="column-header">{props.groupBy === HoldingsGroupBy.BY_ASSET_CLASS ? "Asset Class" : "Tier"}</th>
                <th className={showUniverse ? "column-header" : "column-header investments__pct-no-weight"}
                    id="investments__percentage-column-header">
                    <span className="investments__percent">% Plan</span>
                    <span className="investments__universe-toggle">
                            <StyledToggle toggleState={showUniverse}
                                          setToggle={setShowUniverse}
                                          label={showUniverse ? "Hide Universe" : "Show Universe"}
                                          enabledColor={AonColors.AonDataLime}
                                          labelPlacement="start"
                            />
                        </span>
                    </th>
                    {renderWeightHeader()}
                <th className="column-header investments__amount">Amount (USD)</th>
                <th className="investments__expand-all">
                    <a className="table-anchor" data-testid="table-anchor"
                       onClick={() => setIsExpandAll(!isExpandAll)}>{isExpandAll ? "Collapse All" : "Expand All"}</a>
                </th>
                </tr>
            </thead>
            <tbody>
                {assetsByPlan.map(renderAssetRow)}
            </tbody>
        </table>;

};
