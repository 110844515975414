import {Map} from "immutable";
import {Action, handleActions} from "redux-actions";
import {ManagerResponse} from "../../model/manager/ManagerResponse.model";
import {Product} from "../../model/product/Product.model";
import {getSuccessData, NOT_REQUESTED, REQUEST_SUCCEEDED, REQUESTED, RequestState} from "../common/commonStates";
import {ProductActions} from "../product/productActions";
import {AllManagersActions} from "./AllManagersActions";

export interface IAllManagersStore {
    allManagers: RequestState<Map<number, ManagerResponse>>;
}

export const initialState: IAllManagersStore = {
    allManagers: NOT_REQUESTED,
};

export function allManagersInner(state: IAllManagersStore): RequestState<Map<number, ManagerResponse>> {
    return state.allManagers;
}

const allManagersReceived = (state: IAllManagersStore,
                             action: Action<Map<number, ManagerResponse>>): IAllManagersStore => {
    return {
        ...state,
        allManagers: REQUEST_SUCCEEDED(action.payload!),
    };
};

const allManagersRequested = (state: IAllManagersStore): IAllManagersStore => {
    return {
        ...state,
        allManagers: REQUESTED,
    };
};

const addProductManagerToPlanPortfolio = (
    state: IAllManagersStore,
    action: Action<Product>): IAllManagersStore => {
    const allManagers = getSuccessData(state.allManagers);

    if (!action.payload || !allManagers) {
        return state;
    }

    const managerId = action.payload.managerBackstopId;
    const manager = allManagers.get(managerId);

    if (!manager) {
        return state;
    }

    const managerInPortfolio = {...manager, inPortfolio: true};
    const newAllManagers = allManagers.set(managerId, managerInPortfolio);

    return {
        ...state,
        allManagers: REQUEST_SUCCEEDED(newAllManagers),
    };
};

const reducerMap = {
    [AllManagersActions.ALL_MANAGERS_REQUESTED]: allManagersRequested,
    [AllManagersActions.ALL_MANAGERS_RECEIVED]: allManagersReceived,
    [ProductActions.PRODUCT_ADDED_TO_PLAN_PORTFOLIO]: addProductManagerToPlanPortfolio,
};

type Payloads = Map<number, ManagerResponse> | Product;
export default handleActions<IAllManagersStore, Payloads>(reducerMap, initialState);
