import {Map} from "immutable";
import * as React from "react";
import {Link} from "react-router-dom";
import {ProductSummary} from "../../model/product/ProductSummary";
import {byName} from "../../utils/listUtil";

export interface IProductNameListProps {
    productIds: number[];
    productUniverseSummaries: Map<number, ProductSummary>;
    portfolioProductSummaries: Map<number, ProductSummary>;
}

interface ILinkData {
    id: number;
    name: string;
    url: string;
}

export type ProductNameListProps = IProductNameListProps;

export const ProductNameListComponent = (props: ProductNameListProps) => {
    return <div data-testid="product-name-list__container">
        <ul>
            {getProductLinks(props.productIds, props.portfolioProductSummaries)}
        </ul>
    </div>;
};

const getProductLinks = (productIds: number[], portfolioProductSummaries: Map<number, ProductSummary>) => {
    return getProductData(productIds, portfolioProductSummaries).map(getProductLink);
};

const getProductLink = (linkData: ILinkData) => {
    return <li className="attachment" key={linkData.id}>
        {renderLink(linkData.id, linkData.url, linkData.name)}
    </li>;
};

const getProductData = (productIds: number[], portfolioProductSummaries: Map<number, ProductSummary>) => {
    return productIds
        .map((backstopId: number) => portfolioProductSummaries.get(backstopId))
        .map(getLinkData)
        .sort(byName);
};

const getLinkData = (item: ProductSummary) => {
    return {
        id: item.backstopId,
        name: item.name,
        url: `/products/${item.backstopId}`,
    };
};

const renderLink = (id: number, url: string, name: string) => {
    return <Link to={url} className={"link-14"}>
        {name}
    </Link>;
};

export const ProductNameList = ProductNameListComponent;
