import moment = require("moment");
import {OddRating} from "../../utils/ratingConstants";

export class OperationalDueDiligenceRatings {
    constructor(public rating: string | null,
                public ratingComments: string | null,
                public ratingPrevious: string | null,
                public ratingEffectiveDate: string | null,
                public previousRatingEffectiveDate: string | null,
    ) {}
}

export class ProductOddRating {
    public date: Date;

    constructor(public rating: OddRating,
                private dateString: string | null,
    ) {

        if (dateString) {
            this.date = moment(dateString, "YYYY-MM-DD").toDate();
        } else {
            const date = new Date();
            date.setHours(0, 0, 0, 0);
            this.date = date;
        }

    }
}
