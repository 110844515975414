import {ISelectValue} from "../components/common/Select.component";
import {IHomeContentBoxDownloadableDataItem, IHomeListItem} from "./IHomeListItem";
import {SharedDocumentApi} from "../api/SharedDocumentApi";

export class SharedDocumentType implements ISelectValue {
    constructor(public readonly id: number, public readonly name: string) {
    }
}

export interface ISharedDocument {
    id: number;
    fileName: string;
    title: string;
    date: Date;
    documentType: SharedDocumentType;
    thirdPartyAccess: boolean;
}

export class SharedDocument implements IHomeListItem, ISharedDocument {

    public static builder() {
        return new SharedDocumentBuilder();
    }

    constructor(
        public readonly id: number,
        public readonly fileName: string,
        public readonly title: string,
        public readonly date: Date,
        public readonly documentType: SharedDocumentType,
        public readonly createdMillis: number,
        public readonly planIds: number[] = [],
        public readonly email: string = "",
        public readonly thirdPartyAccess: boolean = false
    ) {  }

    public transform(): IHomeContentBoxDownloadableDataItem {
        return {
            title: this.title || this.fileName,
            date: this.date,
            backstopId: this.id,
            url: "",
            type: "Shared Document",
            download: () => SharedDocumentApi.getDocumentDownload(this.id, this.fileName, true),
        };
    }
}

class SharedDocumentBuilder {
    private static nextId: number = -1;
    private mId: number;
    private mFileName: string;
    private mTitle: string;
    private mDate: Date;
    private mDocumentType: SharedDocumentType;
    private mCreatedMillis: number;
    private mPlanIds: number[];
    private mEmail: string;
    private mThirdPartyAccess: boolean;

    public build() {
        return new SharedDocument(
            this.mId || SharedDocumentBuilder.nextId--,
            this.mFileName || "",
            this.mTitle || "",
            this.mDate || new Date(),
            this.mDocumentType || new SharedDocumentType(0, "type0"),
            this.mCreatedMillis || 0,
            this.mPlanIds || [],
            this.mEmail || "",
            this.mThirdPartyAccess || false);
    }

    public id(value: number) {
        this.mId = value;
        return this;
    }

    public fileName(value: string) {
        this.mFileName = value;
        return this;
    }

    public title(value: string) {
        this.mTitle = value;
        return this;
    }

    public date(value: Date) {
        this.mDate = value;
        return this;
    }

    public documentType(value: SharedDocumentType) {
        this.mDocumentType = value;
        return this;
    }

    public createdMillis(value: number) {
        this.mCreatedMillis = value;
        return this;
    }

    public planIds(value: number[]) {
        this.mPlanIds = value;
        return this;
    }

    public email(value: string) {
        this.mEmail = value;
        return this;
    }

    public thirdPartyAccess(value: boolean) {
        this.mThirdPartyAccess = value;
        return this;
    }
}
