export interface IInformSubComponentFactor {
    factor: string,
    change: string,
    value: string[],
    rating?: number,
    threshold?: string,
    expandedData?: IInformSubComponentExpandedData
}

export class InformSubComponentFactor implements IInformSubComponentFactor {

    public static builder(): InformSubComponentDetailDataBuilder {
        return new InformSubComponentDetailDataBuilder();
    }

    constructor(
        public factor: string,
        public change: string,
        public value: string[],
        public rating?: number,
        public threshold?: string,
        public expandedData?: IInformSubComponentExpandedData
    ) {
    }
}

class InformSubComponentDetailDataBuilder {
    private mFactor: string;
    private mChange: string;
    private mValue: string[];
    private mRating?: number;
    private mThreshold?: string;
    private mExpandedData?: IInformSubComponentExpandedData;

    public factor(value: string) {
        this.mFactor = value;
        return this;
    }

    public rating(value: number) {
        this.mRating = value;
        return this;
    }

    public change(value: string) {
        this.mChange = value;
        return this;
    }

    public value(value: string[]) {
        this.mValue = value;
        return this;
    }

    public threshold(value: string) {
        this.mThreshold = value;
        return this;
    }

    public expandedData(value: IInformSubComponentExpandedData) {
        this.mExpandedData = value;
        return this;
    }

    public build(): InformSubComponentFactor {
        return new InformSubComponentFactor(
            this.mFactor || "",
            this.mChange || "",
            this.mValue || [""],
            this.mRating || undefined,
            this.mThreshold || undefined,
            this.mExpandedData || undefined
        );
    }
}

export interface IInformSubComponentDetails {
    data: IInformSubComponentFactor[] | undefined,
    hasThreshold: boolean
}

export class InformSubComponentDetails implements IInformSubComponentDetails {

    public static builder(): InformSubComponentDetailsBuilder {
        return new InformSubComponentDetailsBuilder();
    }

    constructor(
        public hasThreshold: boolean,
        public data: IInformSubComponentFactor[]
    ) {
    }
}

class InformSubComponentDetailsBuilder {
    private mHasThreshold: boolean;
    private mData: IInformSubComponentFactor[];

    public hasThreshold(value: boolean) {
        this.mHasThreshold = value;
        return this;
    }

    public data(value: IInformSubComponentFactor[]) {
        this.mData = value;
        return this;
    }

    public build(): InformSubComponentDetails {
        return new InformSubComponentDetails(
            this.mHasThreshold || true,
            this.mData || InformSubComponentFactor.builder().build()
        );
    }
}

interface IInformSubComponentExpandedData {
    description: string,
    responses?: string[]
}

export class InformSubComponentExpandedData implements IInformSubComponentExpandedData {

    public static builder(): InformSubComponentExpandedDataBuilder {
        return new InformSubComponentExpandedDataBuilder();
    }

    constructor(
        public description: string,
        public responses?: string[]
    ) {
    }
}

class InformSubComponentExpandedDataBuilder {
    private mDescription: string;
    private mResponses: string[];

    public description(value: string) {
        this.mDescription = value;
        return this;
    }

    public responses(value: string[]) {
        this.mResponses = value;
        return this;
    }

    public build(): InformSubComponentExpandedData {
        return new InformSubComponentExpandedData(
            this.mDescription || "",
            this.mResponses || undefined
        );
    }
}