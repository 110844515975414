import * as React from "react";
import {FlashReportSummary} from "../../model/FlashReportSummary.model";
import {byDate} from "../../utils/dateUtil";
import RecentUpdateLink from "../manager/RecentUpdateLink.component";
import {FormattedMessage} from "react-intl";

interface IFlashReportsPropsFromParent {
    flashReportSummaries: FlashReportSummary[];
}

export class FlashReports extends React.Component<IFlashReportsPropsFromParent> {

    public render() {
        return (
            <div className="flash-report-list" data-testid="flash-report-list">
                <div className="paper-style" style={{padding: "0 30px 15px"}}>
                    <h2 className="flash-reports__title gray">
                        <FormattedMessage id="manager.flash-reports" defaultMessage="Flash Reports" />
                    </h2>
                    {this.renderFlashReports()}
                </div>
            </div>
        );
    }

    private renderFlashReports() {
        if (this.props.flashReportSummaries.length === 0) {
            return <div id="no-flash-reports" data-testid="no-flash-reports">
                <FormattedMessage
                    id="research-product.no-updates"
                    defaultMessage="There are no updates at this time."
                />
            </div>;
        }

        return (<div className="recent-updates-list" data-testid="recent-updates-list">
            {this.renderRecentUpdateLinks()}
        </div>);
    }

    private renderRecentUpdateLinks() {
        return this.props.flashReportSummaries
            .sort(byDate)
            .map((flashReport: FlashReportSummary, index) => {
                return (
                    <RecentUpdateLink
                        recentUpdate={flashReport}
                        isLast={index === this.props.flashReportSummaries.length - 1}
                        key={flashReport.backstopId}
                        managerId={undefined}
                    />
                );
            });
    }
}
