export class MeetingNoteDetail {
    public static builder() {
        return new MeetingNoteDetailBuilder();
    }

    constructor(public backstopId: number,
                public description: string,
                public location: string | null) {
    }
}

class MeetingNoteDetailBuilder {
    private mBackstopId: number;
    private mDescription: string;
    private mLocation: string | null;

    public backstopId(value: number) {
        this.mBackstopId = value;
        return this;
    }

    public description(value: string) {
        this.mDescription = value;
        return this;
    }

    public location(value: string) {
        this.mLocation = value;
        return this;
    }

    public build() {
        return new MeetingNoteDetail(
            this.mBackstopId || 0,
            this.mDescription || "",
            this.mLocation,
        );
    }
}
