import * as React from "react";

export interface ITab {
    tabTitle: string;
    tabComponent: React.FunctionComponent | React.ComponentClass;
    tabComponentProps?: any;
}

export interface ITabsComponentProps {
    tabs: ITab[];
    selectedTabIndex: number;
    handleTabChange: (tabIndex: number) => void;
}

export const TabsComponent: React.FunctionComponent<ITabsComponentProps> = (props) => {
    function getSelectedClass(currentTabIndex: number) {
        return currentTabIndex === props.selectedTabIndex ? " selected" : "";
    }

    function renderTabs() {
        return props.tabs.map((tab, index) =>
            <div className={`__tab clickable ${getSelectedClass(index)}`}
                 onClick={() => props.handleTabChange(index)}
                 key={index}>
                {tab.tabTitle}
            </div>
        );
    }

    function renderComponent() {
        const DisplayedComponent = props.tabs[props.selectedTabIndex].tabComponent;
        const componentProps = props.tabs[props.selectedTabIndex].tabComponentProps;

        return <DisplayedComponent {...componentProps}/>;
    }

    return <div className="common__tabs-container">
        <div className="tabs__list">
            {renderTabs()}
        </div>
        <div key={props.selectedTabIndex} className="tabs__displayed-component">
            {renderComponent()}
        </div>
    </div>;
};