import {Map} from "immutable";
import * as React from "react";
import {connect} from "react-redux";
import {AnyAction, bindActionCreators, Dispatch} from "redux";
import {productUniverseSummariesSelector, shareClasses, tickers,} from "../../../mainReducerMapSelectors";
import ProductApi from "../../api/ProductApi";
import {ProductSummary} from "../../model/product/ProductSummary";
import {ProductTicker} from "../../model/product/ProductTicker";
import {byName} from "../../utils/listUtil";
import {getSuccessData} from "../common/commonStates";
import {SelectComponentReact} from "../common/ReactSelectComponent";
import {shareClassRequestPair, tickerRequestPair} from "../common/RequesterPairs";
import {ISelectValue} from "../common/Select.component";
import {TextFieldComponent} from "../common/TextField.component";
import {addProductTicker, deleteProductTicker} from "./productActions";
import {multipleApiRequesterWrapper} from "../common/MultipleApiRequesterWrapper";
import {IApplicationRootState} from "../../../applicationState";

export interface IMarketIdentifiersActions {
    actions: {
        addProductTicker: typeof addProductTicker;
        deleteProductTicker: typeof deleteProductTicker;
    };
}

export interface IMarketIdentifiersPropsFromParent {
    productBackstopId: number;
}

export interface IMarketIdentifiersPropsFromStore {
    tickers: ProductTicker[];
    allShareClasses: ISelectValue[];
    productSummaryUniverse: Map<number, ProductSummary>;
}

export interface IMarketIdentifiersState {
    selectedShareClass: ISelectValue | null;
    newTicker: string;
    submitClicked: boolean;
    productBackstopIdForDuplicateTicker: number | null;
}

export type MarketIdentifiersProps = IMarketIdentifiersPropsFromParent &
    IMarketIdentifiersPropsFromStore & IMarketIdentifiersActions;

export class MarketIdentifiers extends React.Component<MarketIdentifiersProps, IMarketIdentifiersState> {
    public constructor(props: MarketIdentifiersProps) {
        super(props);

        this.state = {
            selectedShareClass: null,
            newTicker: "",
            submitClicked: false,
            productBackstopIdForDuplicateTicker: null,
        };
    }

    public render() {
        return <div className="product__market-identifiers">
            <h2 className="product__market-identifiers-title gray">Market Identifiers</h2>
            <div className="product__market-identifiers-ticker-section">
                <h3 className="product__market-identifiers-ticker-title gray">Tickers</h3>
                {this.renderTickerList()}
            </div>
            <div className="product__market-identifiers-add-tickers">
                <h3 className="product__market-identifiers-add-tickers__title gray">Add Ticker</h3>
                <div className="product__market-identifiers-add-tickers__section">
                    <div className="product__market-identifiers-add-tickers__ticker-input-container">
                        <TextFieldComponent
                            name="ticker"
                            className="product__market-identifiers-add-tickers__ticker-input"
                            type="text"
                            fullWidth={false}
                            value={this.state.newTicker}
                            handleChange={(e) => {
                                this.setState({
                                    newTicker: (e ? (e.target as any).value : ""),
                                    productBackstopIdForDuplicateTicker: null,
                                });
                            }}
                            placeholder="Ticker"
                            renderRequired={true}
                            submitClicked={this.state.submitClicked}
                        />
                    </div>
                    <SelectComponentReact
                        id="share-class-dropdown"
                        className="product__market-identifiers-add-tickers__share-class-dropdown"
                        options={this.props.allShareClasses.sort(byName)}
                        placeholder="Share Class"
                        isMulti={false}
                        value={this.state.selectedShareClass}
                        onChange={this.handleShareClassChange}
                        submitClicked={this.state.submitClicked}
                        getOptionLabel={(option) => option.name}
                        getOptionValue={(option) => option.name}
                        renderRequired={true}
                    />
                    <div className="product__market-identifiers-add-tickers__button"
                         onClick={this.handleAddClick}>Add
                    </div>
                </div>
                {this.renderDuplicateTickerMessage()}
            </div>
        </div>;
    }

    private handleShareClassChange = (shareClass: ISelectValue) => {
        this.setState({selectedShareClass: shareClass});

    };

    private renderDuplicateTickerMessage = () => {
        if (this.state.productBackstopIdForDuplicateTicker) {
            const newTickerName = (this.state.newTicker).toUpperCase();

            const productSummary: ProductSummary = this.props.productSummaryUniverse
                .get(this.state.productBackstopIdForDuplicateTicker)!;

            return <div className="product__market-identifiers-duplicate-ticker-error">
                <span className="product__market-identifiers-duplicate-ticker-error-message">
                    <div className="product__market-identifiers-duplicate-ticker-error-message-text">
                        {newTickerName} already exists for {/* this comment needs to be here for the space */}
                        {/* eslint-disable-next-line react/forbid-dom-props */}
                        <a href={`/products/${this.state.productBackstopIdForDuplicateTicker}`} target="_blank"
                           className="product__market-identifiers-duplicate-ticker-error-product-link">
                            {productSummary.name}
                        </a>
                        . A ticker can only be assigned to one fund.
                        Switch to Empower Results to ensure you can view this product.
                    </div>
                </span>
            </div>;
        }
    };

    private handleAddClick = () => {
        this.setState({...this.state, submitClicked: true});
        if (this.state.newTicker === undefined
            || this.state.newTicker.trim() === ""
            || this.state.selectedShareClass === null) {
            return;
        }
        ProductApi.getProductByTicker(this.state.newTicker).then(
            (data) => {
                if (!data) {
                    this.props.actions.addProductTicker(
                        this.props.productBackstopId,
                        this.state.newTicker.toUpperCase(),
                        this.state.selectedShareClass!.id!.toString(),
                    );
                    this.setState({...this.state, submitClicked: false});
                } else {
                    this.setState({productBackstopIdForDuplicateTicker: data});
                }
            },
        );
    };

    private renderTickerList() {
        return this.props.tickers.map((ticker: ProductTicker, index: number) => {
            return <div key={index} className="product__market-identifiers-ticker-item">
                <div className="remove-icon" onClick={() => {
                    this.props.actions.deleteProductTicker(this.props.productBackstopId, ticker.id);
                }}/>
                <div className="product__market-identifiers-tickers">
                    {`${ticker.shareClassName} (${ticker.ticker})`}
                </div>
            </div>;
        });
    }
}

export const mapStateToProps = (state: IApplicationRootState): IMarketIdentifiersPropsFromStore => {
    return {
        tickers: getSuccessData(tickers(state)) || [],
        allShareClasses: getSuccessData(shareClasses(state)) || [],
        productSummaryUniverse: getSuccessData(productUniverseSummariesSelector(state)) || Map(),
    };
};

export const mapDispatchToProps = (dispatch: Dispatch<AnyAction>): IMarketIdentifiersActions => {
    return {
        actions: bindActionCreators({
            deleteProductTicker,
            addProductTicker,
        }, dispatch),
    };
};

const connectedMarketIdentifiers = (productBackstopId: number) => {
    const mergeProps = (stateProps: IMarketIdentifiersPropsFromStore, dispatchProps: IMarketIdentifiersActions) => ({
        ...stateProps,
        ...dispatchProps,
        productBackstopId,
    });

    return connect<IMarketIdentifiersPropsFromStore, IMarketIdentifiersActions, IMarketIdentifiersPropsFromParent>(
        mapStateToProps,
        mapDispatchToProps,
        mergeProps,
    )(MarketIdentifiers);
};

const generator = (productBackstopId: number) => multipleApiRequesterWrapper(
    connectedMarketIdentifiers(productBackstopId),
    [
        tickerRequestPair(productBackstopId),
        shareClassRequestPair,
    ],
);

export default generator;
