import {Map} from "immutable";
import {Action, handleActions} from "redux-actions";
import {ApiError} from "../../model/ApiError.model";
import {MeetingNoteSummary} from "../../model/MeetingNoteSummary.model";
import {NOT_REQUESTED, REQUEST_FAILED, REQUEST_SUCCEEDED, REQUESTED, RequestState} from "../common/commonStates";
import {AllMeetingNoteSummariesActions} from "./AllMeetingNoteActions";

export interface IAllMeetingNotesStore {
    allMeetingNoteSummaries: RequestState<Map<number, MeetingNoteSummary>>;
}

export const initialState: IAllMeetingNotesStore = {
    allMeetingNoteSummaries: NOT_REQUESTED,
};

export function allMeetingNotesInner(state: IAllMeetingNotesStore):
    RequestState<Map<number, MeetingNoteSummary>> {
    return state.allMeetingNoteSummaries;
}

const allMeetingNoteSummariesReceived = (state: IAllMeetingNotesStore,
                                         action: Action<Map<number, MeetingNoteSummary>>): IAllMeetingNotesStore => {
    return {
        ...state,
        allMeetingNoteSummaries: REQUEST_SUCCEEDED(action.payload!),
    };
};

const allMeetingNoteSummariesRequested = (state: IAllMeetingNotesStore): IAllMeetingNotesStore => {
    return {
        ...state,
        allMeetingNoteSummaries: REQUESTED,
    };
};

const allMeetingNoteSummariesRequestFailed = (state: IAllMeetingNotesStore,
                                              action: Action<ApiError>):
    IAllMeetingNotesStore => {
    return {
        ...state,
        allMeetingNoteSummaries: REQUEST_FAILED(action.payload),
    };
};

const reducerMap = {
    [AllMeetingNoteSummariesActions.ALL_MEETING_NOTE_SUMMARIES_REQUESTED]: allMeetingNoteSummariesRequested,
    [AllMeetingNoteSummariesActions.ALL_MEETING_NOTE_SUMMARIES_MAP_RECEIVED]: allMeetingNoteSummariesReceived,
    [AllMeetingNoteSummariesActions.ALL_MEETING_NOTE_SUMMARIES_REQUEST_FAILED]: allMeetingNoteSummariesRequestFailed,
};

type Payloads = Map<number, MeetingNoteSummary> | ApiError;
export default handleActions<IAllMeetingNotesStore, Payloads>(reducerMap, initialState);
