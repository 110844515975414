import {Map as ImmutableMap} from "immutable";
import {
    IOddProduct,
    IOddResponse,
    IOddQuestionEntity,
    IOddSectionEntity,
    IOddSectionsQuestions,
    IOddResponsePercentSummary,
    IOddResponseSummary,
    OddLevelId,
    OddResponseCode, IOddProductsAccess,
} from "../../api/OddIqApi";
import {ProductSummary} from "../../model/product/ProductSummary";

export type ProductIdToSummaryMap = ImmutableMap<number, IOddResponseSummary>;
export type SectionIdToSummaryMap = ImmutableMap<number, IOddResponseSummary>;
export type SectionIdToResponses = ImmutableMap<number, IOddResponse[]>;

export interface IPercentAndProductSummary {
    productSummary: ProductSummary;
    questionPercentSummary: IOddResponseSummary;
}

export interface IOddProductWithPercentSummary {
    oddProduct: IOddProduct;
    questionPercentSummary: IOddResponseSummary;
}

export const getInitialResponseSummary = (): IOddResponseSummary => {
  return {
      exceptions: 0,
      bestPractice: 0,
      noData: 0,
  };
};

export function mapSectionToQuestions(sections: IOddSectionEntity[], questions: IOddQuestionEntity[]) {
    return sections.reduce((acc: ImmutableMap<number, IOddQuestionEntity[]>, section: IOddSectionEntity) => {
        const sectionQuestions = questions.filter((q) => q.sectionId === section.id);

        return acc.set(section.id, sectionQuestions);

    }, ImmutableMap<number, IOddQuestionEntity[]>());
}

export function mapQuestionIdToQuestionEntity(questions: IOddQuestionEntity[]) {
    return questions.reduce((acc: ImmutableMap<number, IOddQuestionEntity>, question: IOddQuestionEntity) => {

        return acc.set(question.id, question);

    }, ImmutableMap<number, IOddQuestionEntity>());

}

export function mapQuestionIdToResponses(
    questions: IOddQuestionEntity[],
    responses: IOddResponse[]) {
    return questions
        .reduce((acc: ImmutableMap<number, IOddResponse[]>, question: IOddQuestionEntity) => {
        const questionResponses = responses.filter((response) => response.questionId === question.id);

        return acc.set(question.id, questionResponses);

    }, ImmutableMap<number, IOddResponse[]>());

}

export function mapQuestionIdToResponseSummary(
    questions: IOddQuestionEntity[],
    responses: IOddResponse[]) {
    return questions
        .reduce((acc: ImmutableMap<number, IOddResponseSummary>, question: IOddQuestionEntity) => {
            const questionResponses = responses.filter((response) => response.questionId === question.id);
            if (questionResponses.length > 0) {
                const responseSummary = getSummaryFromResponseList(questionResponses);
                responseSummary.name = question.questionTitle;
                return acc.set(question.id, responseSummary);
            } else {
                return acc;
            }

        }, ImmutableMap<number, IOddResponseSummary>());

}

export function mapQuestionIdToPercentResponseSummary(
    questions: IOddQuestionEntity[],
    responses: IOddResponse[]) {
    return questions
        .reduce((acc: ImmutableMap<number, IOddResponsePercentSummary>, question: IOddQuestionEntity) => {
            const questionResponses = responses.filter((response) => response.questionId === question.id);

            if (questionResponses.length > 0) {
                const responseSummary = generatePercentagesFromResponseSummary(
                    getSummaryFromResponseList(questionResponses));
                responseSummary.name = question.questionTitle;

                return acc.set(question.id, responseSummary);
            } else {
                return acc;
            }

        }, ImmutableMap<number, IOddResponsePercentSummary>());
}

export function getSummaryFromResponseList(
    responseList: IOddResponse[]): IOddResponseSummary {
        return responseList
            .reduce((acc: IOddResponseSummary, response: IOddResponse) => {
                if (response.responseCodeId === OddResponseCode.EXCEPTION) {
                    acc.exceptions++;
                } else if (response.responseCodeId === OddResponseCode.BEST_PRACTICE) {
                    acc.bestPractice++;
                } else if (response.responseCodeId === OddResponseCode.NO_DATA) {
                    acc.noData++;
                }

                return acc;
            }, getInitialResponseSummary());
}

export function getSummaryPercentsByLevel(oddSectionsQuestions: IOddSectionsQuestions,
                                          oddResponses: IOddResponse[],
                                          level: OddLevelId): IOddResponsePercentSummary {

    const questions = oddSectionsQuestions.questions.filter((question) =>
        oddSectionsQuestions.sections.find((s) => s.id === question.sectionId && question.levelId === level));

    const responses = oddResponses
        .filter((response) => questions.find((q) => q.id === response.questionId));

    return getPercentagesFromResponseList(responses);
}

export function getLevelPercentSummary(responsesByQuestion: ImmutableMap<number, IOddResponseSummary>,
                                       sectionsQuestions: IOddSectionsQuestions,
                                       levelId: OddLevelId): IOddResponsePercentSummary {

    const responsesByLevel = responsesByQuestion.filter((summary, questionId) => {
        return sectionsQuestions.questions.find((question) => question.id === questionId)!.levelId === levelId;
    });

    const total = responsesByQuestion.reduce((acc: number, response: IOddResponseSummary) => {
        return acc + response.exceptions + response.bestPractice + response.noData;
    }, 0);

    const starter: IOddResponseSummary = {
        exceptions: 0,
        bestPractice: 0,
        noData: 0,
    };

    if (total === 0) {
        return starter;
    }

    const totalForResponseCode = responsesByLevel
        .reduce((acc: IOddResponseSummary, response: IOddResponseSummary) => {
            return {
                exceptions: acc.exceptions + response.exceptions,
                bestPractice: acc.bestPractice + response.bestPractice,
                noData: acc.noData + response.noData,
            };
        }, starter);

    return {
        exceptions: totalForResponseCode.exceptions / total * 100,
        bestPractice: totalForResponseCode.bestPractice / total * 100,
        noData: totalForResponseCode.noData / total * 100,
    };
}

export function getSectionsQuestionsByLevel(sectionsQuestions: IOddSectionsQuestions, levelId: OddLevelId): IOddSectionsQuestions {
    const questionsByLevel = sectionsQuestions.questions
        .filter((question) =>
            question.levelId === levelId);
    const sectionsByLevel = sectionsQuestions.sections
        .filter((section) =>
            questionsByLevel.find((question) =>
                question.sectionId === section.id));

    return {
        sections: sectionsByLevel,
        questions: questionsByLevel,
    };
}

export function getResponsesForQuestions(responseList: IOddResponse[], questions: IOddQuestionEntity[]): IOddResponse[] {
    return responseList.filter((productQuestionResponse) =>
        questions.find((question) =>
            productQuestionResponse.questionId === question.id));
}

export function getProductResponsesWithResponseCode(responseList: IOddResponse[], responseCodeId: OddResponseCode) : IOddResponse[] {
    return responseList.filter((response) => response.responseCodeId === responseCodeId);
}

export function generatePercentagesFromResponseSummary(
    summary: IOddResponseSummary,
    decimalPlaces: number = 0): IOddResponsePercentSummary {
        const total = summary.exceptions + summary.bestPractice + summary.noData;

        if (total === 0) {
            return {
                exceptions: 0,
                bestPractice: 0,
                noData: 0,
            };
        }

        const exceptions = Number.parseFloat((summary.exceptions * 100 / total).toFixed(decimalPlaces));
        const bestPractice = Number.parseFloat((summary.bestPractice * 100 / total).toFixed(decimalPlaces));
        const noData = Number.parseFloat((100 - exceptions - bestPractice).toFixed(decimalPlaces));

        return {
            exceptions,
            bestPractice,
            noData,
        };
}

export function getPercentagesFromResponseList(
    responseList: IOddResponse[],
): IOddResponsePercentSummary {
    return generatePercentagesFromResponseSummary(getSummaryFromResponseList(responseList), 0);
}

export function mapProductIdsToResponseSummary(
    productBackstopIds: number[],
    responses: IOddResponse[]): ProductIdToSummaryMap {
    return productBackstopIds.reduce((acc: ProductIdToSummaryMap, productId: number) => {
        const productResponses = responses.filter((response) => response.productBackstopId === productId);
        const summary = getSummaryFromResponseList(productResponses);

        return acc.set(productId, summary);
    }, ImmutableMap<number, IOddResponseSummary>());
}

export function addPercentSummaryToProductMap(
    portfolioProductSummaries: ImmutableMap<number, ProductSummary>,
    responses: IOddResponse[]): ImmutableMap<number, IPercentAndProductSummary> {
    return portfolioProductSummaries
        .entrySeq()
        .toArray()
        .reduce((acc: ImmutableMap<number, IPercentAndProductSummary>, [productId, productSummary]) => {
        const productResponses = responses.filter((response) => response.productBackstopId === productId);
        const questionPercentSummary = getPercentagesFromResponseList(productResponses);

        return acc.set(productId, {productSummary, questionPercentSummary});
    }, ImmutableMap<number, IPercentAndProductSummary>());
}

export function getOddProductsWithSummaryPercents(
    oddProducts: IOddProduct[],
    responses: IOddResponse[]): IOddProductWithPercentSummary[] {
    return oddProducts
        .reduce((acc: IOddProductWithPercentSummary[], product) => {
            const productResponses = responses.filter((response) => response.productId === product.id);
            const questionPercentSummary = getPercentagesFromResponseList(productResponses);

            acc[acc.length] = {oddProduct: product, questionPercentSummary};
            return acc;
        }, []);
}

export const mapSectionToResponses =
    (
        oddSectionsQuestions: IOddSectionsQuestions,
        oddProductQuestionResponse: IOddResponse[],
    ): ImmutableMap<number, IOddResponse[]> => {
        const sectionToQuestionMap: ImmutableMap<number, IOddQuestionEntity[]> =
            mapSectionToQuestions(oddSectionsQuestions.sections, oddSectionsQuestions.questions);

        const questionToResponsesMap: ImmutableMap<number, IOddResponse[]> =
            mapQuestionIdToResponses(oddSectionsQuestions.questions, oddProductQuestionResponse);

        return oddSectionsQuestions.sections.reduce((acc: SectionIdToResponses, section: IOddSectionEntity) => {
            let responseList: IOddResponse[] = [];

            sectionToQuestionMap.get(section.id)!.forEach((q) => {
                responseList = responseList.concat(questionToResponsesMap.get(q.id)!);
            });

            return acc.set(section.id, responseList);
        }, ImmutableMap<number, IOddResponse[]>());
    };

export const mapSectionToSummary =
    (
        oddSectionsQuestions: IOddSectionsQuestions,
        oddProductQuestionResponse: IOddResponse[],
    ): ImmutableMap<number, IOddResponseSummary> => {
        const sectionToQuestionMap: ImmutableMap<number, IOddQuestionEntity[]> =
            mapSectionToQuestions(oddSectionsQuestions.sections, oddSectionsQuestions.questions);

        const questionToResponsesMap: ImmutableMap<number, IOddResponse[]> =
            mapQuestionIdToResponses(oddSectionsQuestions.questions, oddProductQuestionResponse);

        return oddSectionsQuestions.sections.reduce((acc: SectionIdToSummaryMap, section: IOddSectionEntity) => {
            let responseList: IOddResponse[] = [];

            sectionToQuestionMap.get(section.id)!.forEach((q) => {
                responseList = responseList.concat(questionToResponsesMap.get(q.id)!);
            });
            const summaryFromResponseList = getSummaryFromResponseList(responseList);

            const sectionQuestionSummary: IOddResponseSummary = {
                ...summaryFromResponseList,
                name: section.name,
            };

            return acc.set(section.id, sectionQuestionSummary);
        }, ImmutableMap<number, IOddResponseSummary>());
    };

export const mapSectionToSummaryPercent =
    (
        oddSectionsQuestions: IOddSectionsQuestions,
        oddProductQuestionResponse: IOddResponse[],
    ): ImmutableMap<number, IOddResponsePercentSummary> => {
        const sectionToQuestionMap: ImmutableMap<number, IOddQuestionEntity[]> =
            mapSectionToQuestions(oddSectionsQuestions.sections, oddSectionsQuestions.questions);

        const questionToResponsesMap: ImmutableMap<number, IOddResponse[]> =
            mapQuestionIdToResponses(oddSectionsQuestions.questions, oddProductQuestionResponse);

        return oddSectionsQuestions.sections.reduce((acc: SectionIdToSummaryMap, section: IOddSectionEntity) => {
            let responseList: IOddResponse[] = [];

            sectionToQuestionMap.get(section.id)!.forEach((q) => {
                responseList = responseList.concat(questionToResponsesMap.get(q.id)!);
            });
            const summaryFromQuestionList = getPercentagesFromResponseList(responseList);

            const sectionQuestionSummary: IOddResponseSummary = {
                ...summaryFromQuestionList,
                name: section.name,
            };

            return acc.set(section.id, sectionQuestionSummary);
        }, ImmutableMap<number, IOddResponsePercentSummary>());
    };

export const byExceptions = (item1: IOddResponsePercentSummary, item2: IOddResponsePercentSummary) => {
    return (item1.exceptions - item2.exceptions);
};

export const byNoData = (item1: IOddResponsePercentSummary, item2: IOddResponsePercentSummary) => {
    return (item1.noData - item2.noData);
};

export const byBest = (item1: IOddResponsePercentSummary, item2: IOddResponsePercentSummary) => {
    const exceptionCompare = byExceptions(item1, item2);
    return exceptionCompare === 0 ? byNoData(item1, item2) : exceptionCompare ;
};

export const getPortfolioOddProducts = (oddProductsAccess: IOddProductsAccess) => {
    return oddProductsAccess.accessibleProducts
            .filter((product) =>
                oddProductsAccess.portfolioProducts
                    .findIndex((vehicleId) => vehicleId === product.vehicleId) > -1);
};

