import * as React from "react";
import {connect} from "react-redux";
import {planSettingsAreAvailable} from "../../../mainReducerMapSelectors";
import Footer from "./Footer.component";
import {IApplicationRootState} from "../../../applicationState";

interface ILayoutFooterPropsFromState {
    planSettingsAreAvailable: boolean;
}

export type ILayoutFooterProps =
    ILayoutFooterPropsFromState;

export class LayoutFooter extends React.Component<ILayoutFooterProps> {

    public render() {
        if (!this.props.planSettingsAreAvailable) {
            return <div/>;
        }
        return <Footer/>;
    }

}

export const mapStateToProps = (state: IApplicationRootState): ILayoutFooterPropsFromState => {
    return {
        planSettingsAreAvailable: planSettingsAreAvailable(state)
    };
};

const connectedComponent = connect<ILayoutFooterPropsFromState>(mapStateToProps)(LayoutFooter);
export default connectedComponent;
