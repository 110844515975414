import * as React from "react";
import {InformESGResponsesTable} from "./InformESGResponsesTable";
import {ESGQuestionOutcome, IInformESG} from "../../model/inform/InformESG";
import {filterQuestionsByOutcome} from "./informUtil";
import {useState} from "react";

export interface IInformESGResponsesProps {
    esg: IInformESG,
    moduleName: string
}

export const InformESGResponses: React.FunctionComponent<IInformESGResponsesProps> = (props) => {
    const esgQuestions = props.esg.modules.find(module => module.name === props.moduleName)?.module.questions;
    const acceptableResponse = filterQuestionsByOutcome(ESGQuestionOutcome.PASS, esgQuestions);
    const flaggedResponse = filterQuestionsByOutcome(ESGQuestionOutcome.ALERT, esgQuestions);
    const noResponse = filterQuestionsByOutcome(ESGQuestionOutcome.NOT_ASSESSED, esgQuestions);
    const [expandedRowIndex, setExpandedRowIndex] = useState<number | undefined>(undefined);

    function handleExpandedRowChange(value: number) {
        setExpandedRowIndex(expandedRowIndex === value? undefined : value);
    }

    return <div data-testid="inform-esg-responses__container">
        <InformESGResponsesTable header={`Acceptable Responses: ${acceptableResponse?.length}`}
                                 questions={acceptableResponse}
                                 index={0}
                                 expandedRowIndex={expandedRowIndex}
                                 setExpandedRowIndex={handleExpandedRowChange}/>
        <InformESGResponsesTable header={`Flagged Responses: ${flaggedResponse?.length}`}
                                 questions={flaggedResponse}
                                 index={1}
                                 expandedRowIndex={expandedRowIndex}
                                 setExpandedRowIndex={handleExpandedRowChange}/>
        <InformESGResponsesTable header={`No Responses: ${noResponse?.length}`} questions={noResponse}
                                 index={2}
                                 expandedRowIndex={expandedRowIndex}
                                 setExpandedRowIndex={handleExpandedRowChange}/>
    </div>;
};