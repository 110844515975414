import {Map} from "immutable";
import {Action, Dispatch} from "redux";
import {ThunkAction} from "redux-thunk";
import CapitalMarketAssumptionsApi from "../../api/CapitalMarketAssumptionsApi";
import {CapitalMarketAssumption} from "../../model/thought-leadership/CapitalMarketAssumption";
import {throwErrorOnNullOrUndefined} from "../../utils/errorUtil";

export enum AllCapitalMarketAssumptionsActions {
    ALL_CAPITAL_MARKET_ASSUMPTIONS_REQUESTED = "ALL_CAPITAL_MARKET_ASSUMPTIONS_REQUESTED",
    ALL_CAPITAL_MARKET_ASSUMPTIONS_RECEIVED = "ALL_CAPITAL_MARKET_ASSUMPTIONS_RECEIVED",
}

const allCapitalMarketAssumptionsRequestedAction = () => {
    return {
        type: AllCapitalMarketAssumptionsActions.ALL_CAPITAL_MARKET_ASSUMPTIONS_REQUESTED,
    };
};

const allCapitalMarketAssumptionsReceivedAction = (payload: Map<number, CapitalMarketAssumption>) => {
    return {
        type: AllCapitalMarketAssumptionsActions.ALL_CAPITAL_MARKET_ASSUMPTIONS_RECEIVED,
        payload,
    };
};

const getAllCapitalMarketAssumptions = (dispatch: Dispatch<Action<AllCapitalMarketAssumptionsActions>>) => {
    dispatch(allCapitalMarketAssumptionsRequestedAction());

    CapitalMarketAssumptionsApi.requestCapitalMarketAssumptions().then(
        (allCapitalMarketAssumptions) => dispatch(allCapitalMarketAssumptionsReceivedAction(
            throwErrorOnNullOrUndefined(allCapitalMarketAssumptions.data))),
    );
};

export const requestAllCapitalMarketAssumptions = ()
    : ThunkAction<void, void, null, Action<AllCapitalMarketAssumptionsActions>> => {
    return (dispatch: Dispatch<Action<AllCapitalMarketAssumptionsActions>>) => {
        getAllCapitalMarketAssumptions(dispatch);
    };
};

export default {
    requestAllCapitalMarketAssumptions,
};
