import * as React from "react";
import {useEffect, useState} from "react";
import {Tooltip} from "@mui/material";
import {prominentButtonStyle, secondaryButtonStyle} from "../common/buttonStyles";
import {RaisedButton} from "../common/RaisedButton";
import {IPerformance, CprPortfolioApi} from "../../api/CprPortfolioApi";
import {setHeaderNotification} from "../base/header/HeaderActions";
import {scrollToElement} from "../../utils/browserUtil";
import {NotificationTypes} from "../base/header/HeaderReducer";
import {useDispatch} from "react-redux";
import {hasConsultantLevelAccess} from "../../utils/sessionUtil";
import {FormattedMessage} from "react-intl";

export const PLACEHOLDER_TIME_PERIOD = "Time Period (ex. YTD through 15 June 22)";
export const PLACEHOLDER_PERFORMANCE_VALUE = "Performance (ex. 2.2%)";
export const PLACEHOLDER_PERFORMANCE_TYPE = "Performance Type (ex. relative to benchmark)";

export interface IPerformanceProps {
    performance: IPerformance | undefined;
    fetchPerformance: () => void;
    isPostQuarterUpdate: boolean;
}

export const CprPerformance: React.FunctionComponent<IPerformanceProps> = (props: IPerformanceProps) => {
    const [editPerformance, setEditPerformance] = useState<boolean>(false);
    const [timePeriod, setTimePeriod] = useState<string>("");
    const [value, setValue] = useState<string>("");
    const [type, setType] = useState<string>("");
    const [saveClicked, setSaveClicked] = useState<boolean>(false);
    const dispatch = useDispatch();

    useEffect(() => {
        resetEditValues();
    }, [props]);

    function resetEditValues(){
        setTimePeriod(props.performance ? props.performance.timePeriod: "" );
        setValue(props.performance ? props.performance.value : "" );
        setType(props.performance ? props.performance.type: "" );
    }

    const renderButtons = () => {
        return (
            editPerformance
                ?
                <div className="performance__buttons">
                    <RaisedButton className="performance__cancel-button"
                                  style={secondaryButtonStyle}
                                  primary={false}
                                  onClick={handlePerformanceCancel}>
                        Cancel
                    </RaisedButton>
                    <RaisedButton className="performance__save-button"
                                  style={prominentButtonStyle}
                                  primary={true}
                                  onClick={handlePerformanceSave}>
                        Save
                    </RaisedButton>
                </div>
                : null
        );
    };

    const handlePerformanceSave = () => {
        setSaveClicked(true);
        if(!timePeriod || timePeriod.trim() === "" || !value || value.trim() === "" || !type || type.trim() === "") {
            return;
        }
        const processSuccess = () => {
            setEditPerformance(false);
            props.fetchPerformance();
            setSaveClicked(false);
        };

        const processFailure = () => {
            dispatch(setHeaderNotification({
                message: 'Failed to save performance',
                notificationType: NotificationTypes.FAILURE
            }, 5000));
            scrollToElement(".main__header", 0);
        };

        const performance: IPerformance = { timePeriod, value, type };

        CprPortfolioApi.savePerformance(performance)
            .then(processSuccess)
            .catch(processFailure);
    };

    const handlePerformanceCancel = () => {
        setEditPerformance(false);
        setSaveClicked(false);
        resetEditValues();
    };

    const renderPerformanceContentInViewMode = () => {
        return <div className="performance__view-content">
            <div className="performance__view-type" data-testid="performance__view-type">{type}</div>
            <div className={renderClassForPerformanceValue()} data-testid="performance__view-value">{value}</div>
            {props.isPostQuarterUpdate &&
                <div className="performance__view-time-period" data-testid="performance__view-time-period">
                    {timePeriod}
                </div>}
        </div>;
    };

    const renderRequired = (valueToCheck: string | undefined, id: string) => {
        const className = valueToCheck
            ?  valueToCheck.trim() === "" ? "require_fail" : "require_success"
            : "require_fail";
        return saveClicked
            ? <span id={id} className={className} data-testid={id}>
                required
              </span>
            : null;
    };

    const renderClassForPerformanceValue = () => {
        return value.trim().startsWith("-") ? "performance__view-value negative" : "performance__view-value positive";
    };

    const renderPerformanceContentInEditMode = () => {
        return <div className="performance__edit-content">
            <div className="performance_edit-time-period">
                <input
                    name="time-period"
                    className="performance__time-period"
                    value={timePeriod}
                    onChange={(e) => setTimePeriod(e.target.value)}
                    placeholder={PLACEHOLDER_TIME_PERIOD}/>
                {renderRequired(timePeriod, "time-period-required")}
            </div>
            <div className="performance__edit-value">
                <input
                    name="performanceValue"
                    className="performance__value"
                    value={value}
                    onChange={(e) => setValue(e.target.value)}
                    placeholder={PLACEHOLDER_PERFORMANCE_VALUE}/>
                {renderRequired(value, "value-required")}
            </div>
            <div className="performance__edit-type">
                <input
                    name="performance-type"
                    className="performance__type"
                    value={type}
                    onChange={(e) => setType(e.target.value)}
                    placeholder={PLACEHOLDER_PERFORMANCE_TYPE}/>
                {renderRequired(type, "type-required")}
            </div>
        </div>;
    };

    const renderToolTip = () => {
        return hasConsultantLevelAccess() && !editPerformance && props.isPostQuarterUpdate
            ? <Tooltip title="" onClick= { () => setEditPerformance(true)}>
                <p className="performance__edit-pencil fas fa-pencil edit-pencil clickable"/>
            </Tooltip>
            : null;
    };

    const renderPerformanceContent = () => {
        return editPerformance ? renderPerformanceContentInEditMode() : renderPerformanceContentInViewMode();
    };

    return <div className="performance__container" data-testid="performance__container">
        <div className="performance__header">
            <div className="performance__title">
                <h3><FormattedMessage id="home.performance-title" defaultMessage="Performance"/></h3>
            </div>
            {renderToolTip()}
            {renderButtons()}
        </div>
        {renderPerformanceContent()}
    </div>;
};