import {
    createTheme,
    FormControl,
    InputLabel,
    MenuItem,
    ThemeProvider,
    Select,
} from "@mui/material";
import * as React from "react";
import {ReactNode} from "react";
import {FormattedMessage} from "react-intl";
import {AonColors} from "../../../../css/Colors";
import {isNullOrUndefined} from "../../utils/commonUtil";
import {ISelectComponentPropsFromParent, ISelectValue} from "./Select.component";
import {styled} from "@mui/material/styles";

export const NewStyledSelect = styled(Select)`
    select {
        padding: 12px 10px;
        margin-bottom: -2px;
        &:focus {
            background: none;
        }
    }
`;

export const NewStyledInputLabel = styled(InputLabel)`
    .MuiInputLabel-shrink {
        display: none;
    }
`;

const newTheme = createTheme({
    components: {
        MuiOutlinedInput: {
            styleOverrides: {
                root: {
                    border: "1px solid " + AonColors.AonGray01,
                    padding: "1px",
                    borderRadius: "4px",

                    "&:hover": {
                        border: "2px solid " + AonColors.AonTealDarkHover,
                        padding: "0",
                    },
                    "&$focused": {
                        border: "2px solid " + AonColors.AonTealDark,
                        padding: "0",
                    },
                },
                notchedOutline: {
                    border: "0",
                },
            }
        },
    },
});

export const NewSelectComponent = (props: ISelectComponentPropsFromParent) => {
    const suffix = props.classNameSuffix ? props.classNameSuffix : props.placeholder;

    const transform = props.menuItemTransform ? props.menuItemTransform : (value: ISelectValue) => value.name;

    const maxHeight = props.selectMaxHeight;
    const MenuProps = props.selectMaxHeight
        ? {
            PaperProps: {
                style: {
                    maxHeight,
                },
            },
        }
        : {};

    const values: ISelectValue[] = props.valuesAsStrings
        ? props.valuesAsStrings.map((value: string) => ({id: value, name: value}))
        : props.values as ISelectValue[];

    return (
        <FormControl variant="outlined">
            <NewStyledInputLabel
                className={`select-${suffix}`}
                htmlFor="placeholder"
                disableAnimation={true}
            >
                {props.placeholder}
            </NewStyledInputLabel>
            <ThemeProvider theme={newTheme}>
                <NewStyledSelect
                    className={`select-inner-select-${suffix}`}
                    id={props.id}
                    data-testid={props.id}
                    value={!isNullOrUndefined(props.selected)
                        ? props.selected : (props.multiple ? [] : "")}
                    onChange={(e, node: any) => {
                        props.handleChange(e, node);
                    }}
                    displayEmpty={false}
                    onClose={() => {
                        return;
                    }}
                    style={{width: props.width + "px"}}
                    inputProps={{
                        id: "placeholder",
                    }}
                    renderValue={props.renderValue}
                    multiple={props.multiple ? props.multiple : false}
                    MenuProps={MenuProps}
                    size="small"
                >
                    {values.map((value) => renderMenuItem(value, props.intlPrefix, transform))}
                </NewStyledSelect>

            </ThemeProvider>

            {renderRequiredCaption(props.submitClicked, props.selected, props.renderRequired)}
        </FormControl>
    );
};

const renderMenuItem =
    (value: ISelectValue, intlPrefix: string | undefined, transform: (value: ISelectValue) => ReactNode) => {
        return <MenuItem
            className={"select-component__menu-item"}
            key={!isNullOrUndefined(value.id) ? value.id : value.name}
            value={!isNullOrUndefined(value.id) ? value.id : value.name}
        >
            {
                intlPrefix
                    ? <FormattedMessage id={`${intlPrefix}.${value.id}`} defaultMessage={value.name}/>
                    : transform(value)
            }
        </MenuItem>;
    };

const renderRequiredCaption = (submitClicked?: boolean, selected?: ISelectValue, renderRequired?: boolean) => {
    const className = (submitClicked && !selected)
        ? "select-field__required-caption-red"
        : "select-field__required-caption";

    return renderRequired
        ? <span className={className}>required</span>
        : null;
};
