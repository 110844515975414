import {
    adminClientsInfoStateSelector,
    adminUsersInfoStateSelector,
    allFlashReportsSummariesSelector,
    allHoldingsSelector,
    allManagersSelector,
    allMeetingNotesSelector, calendarMeetingsSelector,
    capitalMarketAssumptionsSelector, fundingLevelSelector,
    holdingsSelector,
    marketBackgroundsSelector,
    mediumTermViewsSelector,
    portfolioProductSummariesSelector,
    powerBiClientReportsSelector,
    productUniverseSummariesSelector,
    quarterlyInvestmentOutlookSelector,
    shareClasses,
    sharedDocumentsSelector,
    tickers,
    whitePapersSelector,
} from "../../../mainReducerMapSelectors";
import {hasConsultantLevelAccess} from "../../utils/sessionUtil";
import {getAllUserDetails, getClientsAndPlansForAdmin} from "../admin/adminActions";
import {getAllFlashReportsMap} from "../flash-report/AllFlashReportsActions";
import {getAllHoldings, getHoldingsForPlan} from "../investments/holdings/HoldingsActions";
import {getAllManagers} from "../manager/AllManagersActions";
import {getAllMeetingNoteSummaries} from "../meeting-note/AllMeetingNoteActions";
import {getAllProducts, requestShareClasses} from "../product/AllProductsActions";
import {requestTickersForProduct} from "../product/productActions";
import {ThoughtLeadershipDocuments} from "../thought-leadership/ThoughtLeadershipActions";
import {IRequesterApiPair} from "./MultiDataGenericRequester";
import {requestAllSharedDocuments} from "../shared-documents/SharedDocuments.actions";
import {requestClientReports} from "../power-bi-reporting/PowerBiReporting.actions";
import {requestCalendarData} from "../calendar/Calendar.actions";
import {requestFundingLevel} from "../home-page/CprPortfolioActions";

export const allMeetingsNotesRequestPair = {
    apiRequest: getAllMeetingNoteSummaries,
    selector: allMeetingNotesSelector,
};

export const allManagersRequestPair: IRequesterApiPair = {
    apiRequest: getAllManagers,
    selector: allManagersSelector,
};

export const allFlashReportsSummariesRequestPair: IRequesterApiPair = {
    apiRequest: getAllFlashReportsMap,
    selector: allFlashReportsSummariesSelector,
};

export const holdingsRequestPair: IRequesterApiPair = {
    apiRequest: getHoldingsForPlan,
    selector: holdingsSelector,
};

export const allHoldingsRequestPair: IRequesterApiPair = {
    apiRequest: getAllHoldings,
    selector: allHoldingsSelector,
};

export function allProductsRequestPair(): IRequesterApiPair {
    const productSelector = hasConsultantLevelAccess()
        ? productUniverseSummariesSelector
        : portfolioProductSummariesSelector;

    return ({
        apiRequest: getAllProducts,
        selector: productSelector,
    });
}

export const adminUserDetailsPair: IRequesterApiPair = {
    apiRequest: getAllUserDetails,
    selector: adminUsersInfoStateSelector,
};

export const adminClientsWithPlansPair: IRequesterApiPair = {
    apiRequest: getClientsAndPlansForAdmin,
    selector: adminClientsInfoStateSelector,
};

export const capitalMarketAssumptionsRequestPair: IRequesterApiPair = {
    apiRequest: ThoughtLeadershipDocuments.requestCMADocuments,
    selector: capitalMarketAssumptionsSelector,
};

export const mediumTermViewsRequestPair: IRequesterApiPair = {
    apiRequest: ThoughtLeadershipDocuments.requestMTVDocuments,
    selector: mediumTermViewsSelector,
};

export const whitePapersRequestPair: IRequesterApiPair = {
    apiRequest: ThoughtLeadershipDocuments.getWhitePaperDocuments,
    selector: whitePapersSelector,
};

export const quarterlyInvestmentOutlookRequestPair: IRequesterApiPair = {
    apiRequest: ThoughtLeadershipDocuments.requestQIODocuments,
    selector: quarterlyInvestmentOutlookSelector,
};

export const marketBackgroundsRequestPair: IRequesterApiPair = {
    apiRequest: ThoughtLeadershipDocuments.requestMBDocuments,
    selector: marketBackgroundsSelector,
};

export const tickerRequestPair = (productBackstopId: number): IRequesterApiPair => {
    return {
        apiRequest: requestTickersForProduct(productBackstopId),
        selector: tickers,
    };
};

export const shareClassRequestPair: IRequesterApiPair = {
    apiRequest: requestShareClasses,
    selector: shareClasses,
};

export const sharedDocumentsPair: IRequesterApiPair = {
    apiRequest: requestAllSharedDocuments,
    selector: sharedDocumentsSelector,
};

export const powerBiClientReportsPair: IRequesterApiPair = {
    apiRequest: requestClientReports,
    selector: powerBiClientReportsSelector,
};

export const calendarMeetingsPair: IRequesterApiPair = {
    apiRequest: requestCalendarData,
    selector: calendarMeetingsSelector,
};

export const fundingLevelPair: IRequesterApiPair = {
    apiRequest: requestFundingLevel,
    selector: fundingLevelSelector
};
