import {Action} from "redux";
import {ThunkAction} from "redux-thunk";
import {AuthApi} from "../../api/AuthApi";

export class RootActionTypes {
    public static SET_PLAN_SETTINGS = "SET_PLAN_SETTINGS";
    public static RESET_PLAN_SETTINGS = "RESET_PLAN_SETTINGS";
}

export const requestPlanSettings = (): ThunkAction<Promise<void>, void, null, Action<string>> => {
    return (dispatch) => {
        return AuthApi.requestPlanSettings()
            .then((response) => {
                    dispatch({payload: response.data, type: RootActionTypes.SET_PLAN_SETTINGS});
                },
                () => {dispatch({type: RootActionTypes.RESET_PLAN_SETTINGS});
                });
    };
};

export const resetPlanSettings = (): ThunkAction<void, void, null, Action<string>> => {
    return (dispatch) => {
        dispatch({type: RootActionTypes.RESET_PLAN_SETTINGS});
    };
};

export default {
    requestPlanSettings,
    resetPlanSettings,
};
