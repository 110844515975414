import * as React from "react";
import {useEffect} from "react";
import {useQuill} from 'react-quilljs';
import 'quill/dist/quill.snow.css';

export interface IQuillEditorProps {
    onChange?: (value: string ) => void;
    htmlContent: string;
    placeHolder: string;
    toolbarEnabled: boolean;
    readOnly: boolean;
}

export const QuillEditor: React.FunctionComponent<IQuillEditorProps> = (props) => {
    const {quill, quillRef} = useQuill({
        modules: {
            toolbar: props.toolbarEnabled,
        },
        readOnly: props.readOnly,
        placeholder: props.placeHolder,
    });

    useEffect(() => {
        if (quill && props.onChange) {
            quill.on(
                'text-change',
                () => {
                    props.onChange!(quill!.root.innerHTML);
                }
            );
        }
    }, [quill]);

    useEffect(() => {
        if (quill) {
            if(quill!.root.innerHTML !== props.htmlContent)
                quill?.clipboard.dangerouslyPasteHTML(props.htmlContent);
        }
    }, [quill, props.htmlContent]);

    return <div className="quill-editor" ref={quillRef}/>;
};