import {AxiosResponse} from "axios";
import {IInReportGroup, InReportSummary} from "../model/product/inreports/InReportSummary.model";
import {parseDate} from "../utils/dateUtil";
import {getConfig} from "../utils/envUtil";
import {getFileExtension, renameWordWithPdf} from "../utils/fileUtil";
import {ApiBase} from "./ApiBase";
import {IApiResult} from "./AxiosWealthManagementApi";

class InReportApiImpl {
    public requestLatestInReportSummaryForProductId(productBackstopId: number)
        : Promise<IApiResult<IInReportGroup | undefined>> {
        return ApiBase.processGet(
            `/products/${productBackstopId}/inreports/latest`,
            (response: AxiosResponse) => {
                const inBriefReport = response.data.inBriefSummary;
                const inTotalReport = response.data.inTotalSummary;
                const inFormReport = response.data.inFormSummary;
                const inBriefSummary = inBriefReport ? new InReportSummary(
                    inBriefReport.backstopId,
                    inBriefReport.name,
                    parseDate(inBriefReport.effectiveDate),
                    inBriefReport.filename)
                : null;
                const inTotalSummary = inTotalReport ? new InReportSummary(
                    inTotalReport.backstopId,
                    inTotalReport.name,
                    parseDate(inTotalReport.effectiveDate),
                    inTotalReport.filename)
                : null;
                const inFormSummary = inFormReport ? new InReportSummary(
                    inFormReport.backstopId,
                    inFormReport.name,
                    parseDate(inFormReport.effectiveDate),
                    inFormReport.filename)
                : null;

                return {
                    inBriefSummary,
                    inTotalSummary,
                    inFormSummary,
                };
            });
    }

    public downloadInReportPdf(productBackstopId: number, inReportBackstopId: number, fileName: string) {
        const extension = getFileExtension(fileName);
        const newFileName = renameWordWithPdf(fileName);
        const baseURL = getConfig().FRONTEND_CONFIG_BACKEND_URL;

        const url = `${baseURL}/download/products/${productBackstopId}/inreports/${inReportBackstopId}/${extension}/${encodeURIComponent(newFileName)}`;

        window.open(url, "_blank");
    }
}

export const InReportApi = new InReportApiImpl();
