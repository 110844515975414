import {Map as ImmutableMap} from "immutable";
import * as React from "react";
import {useEffect} from "react";
import {connect, useDispatch} from "react-redux";
import {
    hasClientResearchEnabled,
    portfolioProductSummariesSelector,
} from "../../../../mainReducerMapSelectors";
import {IOddManager, IOddResponse, IOddSectionsQuestions} from "../../../api/OddIqApi";
import {ProductSummary} from "../../../model/product/ProductSummary";
import {getSuccessData} from "../../common/commonStates";
import {
    allProductsRequestPair,
} from "../../common/RequesterPairs";
import {OddIqMethodologyComponent} from "../OddIqMethodology.component";
import {addPercentSummaryToProductMap,} from "../OddIqUtils";
import {multipleApiRequesterWrapper} from "../../common/MultipleApiRequesterWrapper";
import {OddTopRisks} from "./OddTopRisks";
import {OddResultsByRiskCategory} from "./odd-results-by-risk-category/OddResultsByRiskCategory";
import {OddSummaryByProduct} from "./OddSummaryByProduct";
import {setCurrentOddQuestionId} from "../OddIqActions";
import {useHistory} from "react-router-dom";
import {OperationalDueDiligenceDocument} from "../../../model/OperationalDueDiligenceDocument.model";
import {
    oddCurrentQuestionId,
    oddDocumentsRequestPair,
    oddDocumentsSelector,
    oddManagersRequestPair,
    oddManagersSelector, oddResponsesByBackstopForCurrentPlan, oddSectionsQuestionsRequestPair,
    operationalDueDiligenceSectionsQuestionsSelector,
    oddResponsesByBackstopSelector
} from "../OddIqRequesterPairs";
import {IApplicationRootState} from "../../../../applicationState";

export interface IOperationalDueDiligencePagePropsFromStore {
    portfolioProductSummaries: ImmutableMap<number, ProductSummary>;
    oddProductQuestionResponse: IOddResponse[];
    oddSectionsQuestions: IOddSectionsQuestions;
    hasClientResearchEnabled: boolean;
    currentQuestionId?: number;
    oddDocuments: OperationalDueDiligenceDocument[];
    oddManagers: IOddManager[];
}


export const OddIqPage: React.FunctionComponent<IOperationalDueDiligencePagePropsFromStore> = (props) => {

    const productIdToSummaryMap =
        addPercentSummaryToProductMap(props.portfolioProductSummaries, props.oddProductQuestionResponse);

    const history = useHistory();
    const dispatch = useDispatch();
    useEffect(() => {
        if (history.action !== "POP") {
            dispatch(setCurrentOddQuestionId(undefined));
        }
    }, []);

    return <div data-testid="odd-iq-page">
                <div className="odd-page__methodology-container">
                    <div className="odd-page__description">
                        The information below provides a high-level summary of potential operational risks within
                        a defined portfolio of Investment Strategies. The Portfolio summary allows users to easily
                        review risk assessment results in aggregate, identify concentrations, and prioritize unique
                        pockets of risk.
                    </div>
                    <div className="odd-iq__slider-container">
                         <OddIqMethodologyComponent mode="Portfolio"/>
                    </div>
                </div>
                <br/>
                <OddTopRisks oddProductQuestionResponse={props.oddProductQuestionResponse}
                             oddSectionsQuestions={props.oddSectionsQuestions}
                />
                <OddResultsByRiskCategory oddSectionsQuestions={props.oddSectionsQuestions}
                                          oddResponses={props.oddProductQuestionResponse}
                                          portfolioProductSummaries={props.portfolioProductSummaries}
                                          currentQuestionId={props.currentQuestionId}
                                          oddDocuments={props.oddDocuments}
                                          byBackstop={true}
                />
                <OddSummaryByProduct
                    oddManagers={props.oddManagers}
                    productIdToSummaryMap={productIdToSummaryMap}
                    hasClientResearchEnabled={props.hasClientResearchEnabled}
                />
            </div>;
};

export const mapStateToProps = (state: IApplicationRootState): IOperationalDueDiligencePagePropsFromStore => {
    return {
        portfolioProductSummaries: getSuccessData(portfolioProductSummariesSelector(state))!,
        oddProductQuestionResponse: getSuccessData(oddResponsesByBackstopSelector(state))!,
        oddSectionsQuestions: getSuccessData(operationalDueDiligenceSectionsQuestionsSelector(state))!,
        hasClientResearchEnabled: hasClientResearchEnabled(state),
        currentQuestionId: oddCurrentQuestionId(state),
        oddDocuments: getSuccessData(oddDocumentsSelector(state))!,
        oddManagers: getSuccessData(oddManagersSelector(state))!,
    };
};

const connectedOddPage = connect<IOperationalDueDiligencePagePropsFromStore, {}>(mapStateToProps)(OddIqPage);

export default multipleApiRequesterWrapper(
    connectedOddPage,
    [
        allProductsRequestPair(),
        oddResponsesByBackstopForCurrentPlan,
        oddSectionsQuestionsRequestPair,
        oddDocumentsRequestPair,
        oddManagersRequestPair,
    ],
);
