import {IPlanInfo} from "../../model/ClientsAndPlans.model";
import {UserTypeEnum} from "../../model/UserInfo.model";

export class SessionData {
    public static builder() {
        return new SessionBuilder();
    }

    constructor(public readonly userType: UserTypeEnum,
                public readonly currentPlan: IPlanInfo | undefined,
                public readonly firstName: string,
                public readonly logoutTimestamp?: number | undefined) {
    }
}

export class SessionBuilder {
    private mUserType: UserTypeEnum;
    private mCurrentPlan: IPlanInfo | undefined;
    private mFirstName: string  | undefined;
    private mLogoutTimestamp: number | undefined;

    public userType(userType: UserTypeEnum) {
        this.mUserType = userType;
        return this;
    }
    public currentPlan(currentPlan: IPlanInfo) {
        this.mCurrentPlan = currentPlan;
        return this;
    }
    public firstName(firstName: string) {
        this.mFirstName = firstName;
        return this;
    }

    public logoutTimestamp(logoutTimestamp: number | undefined) {
        this.mLogoutTimestamp = logoutTimestamp;
        return this;
    }

    public buildForTest() {
        return new SessionData(
            this.mUserType || UserTypeEnum.UNKNOWN,
            this.mCurrentPlan || undefined,
            this.mFirstName || "",
            this.mLogoutTimestamp || undefined
        );
    }
}

export const emptySessionData = SessionData.builder().buildForTest();
