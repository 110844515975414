import * as React from "react";
import {useEffect, useState} from "react";
import {IHolding} from "../../../api/HoldingsApi";
import {HoldingsGroupBy} from "../InvestmentsPage";
import {
    allAssetClasses,
    allTiers,
    orderByCashflow,
    orderByName,
    orderByProductName,
    orderByTierName
} from "./CashFlows.component";
import {Switch} from "@mui/material";
import {
    cashflowSortedByKey,
    filterByGroupAndProduct,
    get12MostRecentMonthsOfHoldings,
    getTitle,
    ICashflow
} from "./CashFlowsUtils";
import {compareDates, formatDate} from "../../../utils/dateUtil";
import {NewSelectComponent} from "../../common/NewSelect.component";
import {CashFlowsByKeyDataTable} from "./CashFlowsByKeyDataTable";
import {CashFlowsByKeyChart} from "./CashFlowsByKeyChart";
import {CashFlowsExport} from "./CashFlowsExport";
import {OrderedMap} from "immutable";

export interface ICashFlowsByKeyProps {
    holdings: IHolding[]
    groupBy: HoldingsGroupBy;
    selectedGroup: string;
    selectedProduct: string;
    showChart: boolean;
}

export const CashFlowsByKey: React.FunctionComponent<ICashFlowsByKeyProps> = (props) => {
    const [selectedGroupSort, setSelectedGroupSort] = useState<string>(getOrderByName(props.selectedGroup));
    const [showInflowOutflow, setShowInflowOutflow] = useState<boolean>(false);

    useEffect(() => {
        setSelectedGroupSort(selectedGroupSort === orderByCashflow
            ? orderByCashflow
            : getOrderByName(props.selectedGroup));

        if(showInflowOutflow && !props.showChart) {
            setShowInflowOutflow(false);
        }
    }, [props]);
    const mostRecentHoldingsOverTime = get12MostRecentMonthsOfHoldings(props.holdings);

    const asOfDate = mostRecentHoldingsOverTime.map((h) => h.asOfDate).sort(compareDates)[0];

    const filteredHoldingsByKey: IHolding[] = filterByGroupAndProduct(
        mostRecentHoldingsOverTime,
        props.selectedGroup,
        props.selectedProduct,
        props.groupBy);

    const cashflowByKey: OrderedMap<string, ICashflow> = cashflowSortedByKey(
        filteredHoldingsByKey,
        props.selectedGroup,
        selectedGroupSort
    );


    function getOrderByName(groupName: string) {
        return groupName === allAssetClasses
            ? orderByName
            : (groupName === allTiers ? orderByTierName : orderByProductName);
    }

    const handleChange = (e: any) => {
        setSelectedGroupSort(e.target.value);
    };

    function renderToggleHorizontal() {
        return props.showChart
            ? <div className="cash-flows__toggle" data-testid="cash-flows__toggle">
                     <span>
                         {showInflowOutflow ? "Hide Inflow/Outflow" : "Show Inflow/Outflow"}
                     </span>
                <Switch
                    id="cashflow__switch-horizontal"
                    onChange={() => setShowInflowOutflow(!showInflowOutflow)}
                    color="primary"
                    checked={showInflowOutflow}
                    data-testid="cashflow__switch-horizontal"
                />
            </div>
            : null;
    }

    const groupSortValues = [getOrderByName(props.selectedGroup), orderByCashflow,]; // to remove MUI warnings in test

    return <div className="cash-flows__chart-border" data-testid="cash-flows__chart-border">
        <div className="cash-flows__by-key-header-row">
            <h3 className="cash-flows__chart-title aon-data-viz-blue">
                {getTitle(false, props.selectedGroup, props.selectedProduct)}
            </h3>
            <span className="investments__as-of-date">
                    {`1 Year as of ${formatDate(asOfDate, "DD MMM YYYY")}`}
                </span>
            <div className="cash-flows__sort-by-container">
                {renderToggleHorizontal()}
                <div className="small bold cash-flows__sort-by-label">Sort By</div>
                <span className="cash-flows__alignment-span" data-testid="cash-flows__alignment-span">
                                <NewSelectComponent
                                    selected={groupSortValues.includes(selectedGroupSort) ? selectedGroupSort : ''}
                                    classNameSuffix="cash-flows__asset-class-sort"
                                    valuesAsStrings={groupSortValues}
                                    width={200}
                                    handleChange={handleChange}
                                    id="cash-flows__asset-class-sort"/>
                    {renderCsvExportButton(false)}
                </span>
            </div>
        </div>
        {props.showChart ? renderByKeyChart() : renderByKeyDataTable()}
    </div>;

    function renderByKeyDataTable() {
        return <CashFlowsByKeyDataTable
            cashflows={cashflowByKey}
            isProduct={props.selectedGroup !== allAssetClasses}/>;
    }

    function renderByKeyChart() {
        return <CashFlowsByKeyChart
            cashflows={cashflowByKey}
            showInflowOutflow={showInflowOutflow}/>;
    }

    function renderCsvExportButton(isOvertime: boolean) {
        return props.showChart
            ? null
            : <CashFlowsExport holdings={filteredHoldingsByKey}

                               isOvertime={isOvertime}
                               quarterlySelected={false}
                               selectedAssetClass={props.selectedGroup}
                               selectedProduct={props.selectedProduct}
                               selectedAssetClassSort={selectedGroupSort}
                               id={"export-by-key"}
            />;
    }
};
