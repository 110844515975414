/* tslint:disable:interface-name */

export const enum COMBINED_REQUEST_STATES {
    SOME_NOT_REQUESTED = "SOME_NOT_REQUESTED",
    ALL_REQUESTED = "ALL_REQUESTED",
    NOT_SUCCESSFUL = "NOT_SUCCESSFUL",
    ALL_SUCCESSFUL = "ALL_SUCCESSFUL",
}

export interface SomeNotRequested {
    kind: COMBINED_REQUEST_STATES.SOME_NOT_REQUESTED;
}

export interface AllRequested {
    kind: COMBINED_REQUEST_STATES.ALL_REQUESTED;
}

export interface AllCompleted {
    kind: COMBINED_REQUEST_STATES.ALL_SUCCESSFUL;
}

export interface AllFailed {
    kind: COMBINED_REQUEST_STATES.NOT_SUCCESSFUL;
}

export type combinedStatesTypes = SomeNotRequested | AllRequested | AllCompleted | AllFailed;

export const SOME_NOT_REQUESTED: SomeNotRequested = {
    kind: COMBINED_REQUEST_STATES.SOME_NOT_REQUESTED,
};
export const ALL_REQUESTED: AllRequested = {
    kind: COMBINED_REQUEST_STATES.ALL_REQUESTED,
};
export const ALL_SUCCESSFUL: AllCompleted = {
    kind: COMBINED_REQUEST_STATES.ALL_SUCCESSFUL,
};
export const NOT_SUCCESSFUL: AllFailed = {
    kind: COMBINED_REQUEST_STATES.NOT_SUCCESSFUL,
};
