import * as React from "react";
import {UploadFile} from "../../model/UploadFile.model";
import UploadFileComponent from "./UploadFile.component";
import {SharedDocumentType} from "../../model/SharedDocument.model";
import {IApiResult} from "../../api/AxiosWealthManagementApi";
import {FormattedMessage} from "react-intl";
import {useSelector} from "react-redux";
import {hasThirdPartyAccess} from "../../../mainReducerMapSelectors";

export interface IUploadFilesProps {
    selectedFiles: UploadFile[];
    documentTypes: IApiResult<SharedDocumentType[]>;
    setSelectedFiles: React.Dispatch<React.SetStateAction<UploadFile[]>>;
    submitClicked: boolean;
}

export const UploadFilesComponent: React.FunctionComponent<IUploadFilesProps> = (props) => {

    const thirdPartyAccessEnabledPlan = useSelector(state => hasThirdPartyAccess(state));

    const onFilePropChange = (updatedFile: UploadFile) => {
        const {title, selectedDocumentType, date, thirdPartyAccess} = updatedFile;
        const selectedFiles = props.selectedFiles
            .map((x) => (x.key === updatedFile.key) ? {...x, title, selectedDocumentType, date, thirdPartyAccess} : x);

        props.setSelectedFiles(selectedFiles);
    };

    const onApplyDocumentTypeToAll = (docTypeIdx: number) => {
        const newSelectedFiles = props.selectedFiles
            .map((x) => ({...x, selectedDocumentType: docTypeIdx}));

        props.setSelectedFiles(newSelectedFiles);
    };

    const onApplyDateToAll = (date: Date) => {
        const newSelectedFiles = props.selectedFiles
            .map((x) => ({...x, date}));

        props.setSelectedFiles(newSelectedFiles);
    };

    const onDelete = (selectedFile: UploadFile) => {
        props.setSelectedFiles(props.selectedFiles.filter((file) => file.key !== selectedFile.key));
    };

    const renderFile = (f: UploadFile) => {
        const fileProps = {
            selectedFile: f,
            documentTypes: props.documentTypes.data || [],
            onFilePropChange,
            submitClicked: props.submitClicked,
            onApplyDocumentTypeToAll,
            onApplyDateToAll,
            onDelete,
            showThirdPartyAccessColumn: thirdPartyAccessEnabledPlan
        };

        return (
            <UploadFileComponent key={f.key} {...fileProps}/>
        );
    };

    const renderThirdPartyColumn = () => {
        return thirdPartyAccessEnabledPlan
            ? <th className="shared-document-list-combined-header" style={{minWidth: "73px", maxWidth: "73px"}}>
                <span className="file-third_party_access">
                    <FormattedMessage id="upload.3rd-party-access" defaultMessage="3RD PARTY ACCESS" />
                </span>
            </th>
            : null;
    };

    return <div className="shared-document-list-combined" data-testid="upload-files-component">
            <table>
                <thead>
                <tr>
                    <th style={{width: "24px", paddingLeft: 0}}/>
                    <th className="shared-document-list-combined-header" style={{width: "150px"}}>
                        <div className="file-name">
                            <FormattedMessage id="upload.file-name" defaultMessage="FILENAME" />
                        </div>
                    </th>
                    <th className="shared-document-list-combined-header" style={{width: "100%"}}>
                        <div className="file-title">
                            <FormattedMessage id="upload.document-title" defaultMessage="DOCUMENT TITLE" />
                        </div>
                    </th>
                    <th className="shared-document-list-combined-header" style={{width: "260px"}}>
                        <span className="file-document-type">
                            <FormattedMessage id="upload.document-type" defaultMessage="DOCUMENT TYPE" />
                        </span>
                    </th>
                    <th className="shared-document-list-combined-header" style={{width: "230px"}}>
                        <span className="file-date">
                            <FormattedMessage id="upload.time-period" defaultMessage="TIME PERIOD" />
                        </span>
                    </th>
                    <th/>
                    { renderThirdPartyColumn() }
                </tr>
                </thead>
                <tbody>{props.selectedFiles.map(renderFile)}</tbody>
            </table>
        </div>;

};
