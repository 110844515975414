import * as React from "react";
import {useDispatch, useSelector} from "react-redux";
import {powerBiReportExportSelector} from "../../../mainReducerMapSelectors";
import {IReportBookmark} from "powerbi-models";
import {PowerBiReportingActions} from "./PowerBiReporting.actions";
import {PowerBiReportingApi} from "../../api/PowerBiReportingApi";
import {getPerformanceSelectedPeriod, powerBiCaptureBookmarks} from "../../utils/powerBiUtil";
import {IconButton, Tooltip} from "@mui/material";
import {REQUEST_STATES} from "../common/commonStates";
import {FormattedMessage} from "react-intl";

export interface IPowerBiExportProps {
    reportContainerId: string;
    clientReportId?: number;
    showExportButton: boolean;
}

export const PowerBiExport: React.FunctionComponent<IPowerBiExportProps> = (props: IPowerBiExportProps) => {
    const dispatch = useDispatch();
    const exportInfoSelector = useSelector(state => powerBiReportExportSelector(state));

    const requestPerformanceReportExport = (bookmark: IReportBookmark) => {
        dispatch({type: PowerBiReportingActions.EXPORT_REPORT_REQUESTED});

        PowerBiReportingApi.requestPerformanceReportExport(bookmark)
            .then((exportInfoResult) => {
                dispatch({
                    type: PowerBiReportingActions.EXPORT_REPORT_REQUEST_SUCCEEDED,
                    payload: exportInfoResult.data,
                });
            }).catch((error) => {
            dispatch({
                type: PowerBiReportingActions.EXPORT_REPORT_REQUEST_FAILED,
                payload: error,
            });
        });
    };

    const requestClientReportExport = (bookmark: IReportBookmark) => {
        dispatch({type: PowerBiReportingActions.EXPORT_REPORT_REQUESTED});

        PowerBiReportingApi.requestClientReportExport(props.clientReportId!, bookmark)
            .then((exportInfoResult) => {
                dispatch({
                    type: PowerBiReportingActions.EXPORT_REPORT_REQUEST_SUCCEEDED,
                    payload: exportInfoResult.data,
                });
            }).catch((error) => {
            dispatch({
                type: PowerBiReportingActions.EXPORT_REPORT_REQUEST_FAILED,
                payload: error,
            });
        });
    };

    const exportPerformanceReportToPdf = () => {
        powerBiCaptureBookmarks(props.reportContainerId)
            .then ((bookmark) => {
                getPerformanceSelectedPeriod(props.reportContainerId).then((period) => {
                    dispatch({
                        type: PowerBiReportingActions.SET_EXPORT_REPORT_PERIOD,
                        payload: period,
                    });
                    requestPerformanceReportExport(bookmark);
                })
                    .catch((error) => {
                        // eslint-disable-next-line
                        // console.error(`Failed to get selected period: ${error}`);
                        dispatch({
                            type: PowerBiReportingActions.EXPORT_REPORT_REQUEST_FAILED,
                            payload: error,
                        });
                });

            });
    };

    const exportClientReportToPdf = () => {
        powerBiCaptureBookmarks(props.reportContainerId)
            .then ((bookmark) => {
                requestClientReportExport(bookmark);
            });
    };

    const renderExportStatus = () => {
        return exportRunning()
            ? <div className="powerbi-report__exporting-message" data-testid="powerbi-report__exporting-message">
                <FormattedMessage id="client-reporting.export-running" defaultMessage="Export Running" />
                <span className="running-dots">.</span>
                <span className="running-dots">.</span>
                <span className="running-dots">.</span>
            </div>
            : null;
    };

    const renderExportError = () => {
        return exportInfoSelector.kind === REQUEST_STATES.REQUEST_FAILED
            ? <div className="powerbi-report__exporting-message" data-testid="powerbi-report__exporting-message">
                Export failed
                </div>
            : null;
    };

    const exportRunning = ():boolean => {
        return exportInfoSelector.kind === REQUEST_STATES.REQUESTED
        || exportInfoSelector.kind === REQUEST_STATES.REQUEST_SUCCEEDED;
    };

    const onExportClick = () => {
        return props.clientReportId ? exportClientReportToPdf() : exportPerformanceReportToPdf();
    };

   const renderExportButton = () => {
       if(!props.showExportButton) return <></>;

       const buttonId = props.clientReportId
           ? "powerbi-report__export-client-report-button"
           : "powerbi-report__export-button";

       return <IconButton
               id={buttonId}
               tabIndex={0}
               onClick={onExportClick}>
                   <span className="powerbi-report__export-button-wrapper">
                       <div className="export-to-pdf fal fa-download fa-lg" data-testid="export-to-pdf"/>
                    </span>
           </IconButton>;
   };

    const renderExportButtonAndTooltip = () => {
        return exportRunning()
            ? null
            : <Tooltip title={<FormattedMessage id="client-reporting.export-to-pdf" defaultMessage="Export To PDF"/>}
                         PopperProps={{
                             disablePortal: true,
                         }}>
                    {renderExportButton()}
                </Tooltip>;
    };

    return <>
        {renderExportStatus()}
        {renderExportError()}
        {renderExportButtonAndTooltip()}
    </>;
};