import * as React from "react";
import {CprFundingLevel} from "./CprFundingLevel";
import {CprAssets} from "./CprAssets";
import {CprPerformance} from "./CprPerformance";
import {IAssets, IFundingLevel, IPerformance} from "../../api/CprPortfolioApi";
import {formatShortDate} from "../../utils/dateUtil";
import {useEffect, useState} from "react";
import {formatCurrencyBasedOnLocale, formatPercentBasedOnLocale} from "../../utils/numberUtil";
import {IPlanSummary} from "../../model/PlanSummary.model";
import {FormattedMessage} from "react-intl";
import {getTranslation} from "../../utils/translationUtil";

export interface CprPlanSummaryProps {
    planSummary: IPlanSummary;
}

export const TYPE_SUFFIX = "Relative to Benchmark";

export const CprPlanSummary: React.FunctionComponent<CprPlanSummaryProps> = (props: CprPlanSummaryProps) => {
    const {planSummary} = props;

    const [fundingLevel, setFundingLevel] = useState<IFundingLevel | undefined>(undefined);
    const [assets, setAssets] = useState<IAssets | undefined>(undefined);
    const [performance, setPerformance] = useState<IPerformance | undefined>(undefined);
    const [asOfDate, setAsOfDate] = useState<string | undefined>("");

    const translatePerformanceTimePeriod = (performanceTimePeriod: string) => {
        const monthOrYear = performanceTimePeriod.replace(/[0-9]/g, '').trim();
        const numberOfMonthsOrYears = performanceTimePeriod.replace(monthOrYear, '');
        return numberOfMonthsOrYears + getTranslation(`home.performance-${monthOrYear.toLowerCase()}`, monthOrYear);
    };

    useEffect(() => {
        setAsOfDate(planSummary.asOfDate);

        if (planSummary.fundingValue && planSummary.fundingBasis) {
            setFundingLevel(
                {asOfDate: planSummary.asOfDate, basis: planSummary.fundingBasis!, value: planSummary.fundingValue}
            );
        }
        if (planSummary.assets && planSummary.currency) {
            setAssets({
                assetsAsOf: planSummary.asOfDate,
                assetsValue: formatCurrencyBasedOnLocale(Number(planSummary.assets), planSummary.currency)
            });
        }
        if (planSummary.performance && planSummary.performanceTimePeriod) {
            setPerformance({
                type: translatePerformanceTimePeriod(planSummary.performanceTimePeriod) + " "
                    + getTranslation("home.performance-relative-to-bench-mark", TYPE_SUFFIX),
                value: formatPercentBasedOnLocale(Number(planSummary.performance)),
                timePeriod: ""
            });
        }
    }, [props]);

    const displayPlanSummary = () => {
        return fundingLevel || assets || performance;
    };

    const renderContent = () => {

        return <div className="plan-summary__container" data-testid="plan-summary__container">
            <div className="plan-summary__title">
                <h2>
                    <FormattedMessage id="home.plan-summary-title"
                                      defaultMessage="Plan Summary as of"/> {formatShortDate(asOfDate!)}
                </h2>
            </div>
            <div className="plan-summary__content">
                {fundingLevel && <CprFundingLevel fundingLevel={fundingLevel} isPostQuarterUpdate={false}/>}
                {assets && <CprAssets assets={assets} fetchAssets={() => void 0} isPostQuarterUpdate={false}/>}
                {performance &&
                    <CprPerformance
                        performance={performance} fetchPerformance={() => void 0} isPostQuarterUpdate={false}/>}
            </div>
        </div>;
    };

    return displayPlanSummary() ? renderContent() : null;
};