import {logError} from "./main/api/BrowserWrapper";
import {globalErrorHandlerEnabled} from "./main/utils/envUtil";

export function registerUnhandledRejectionHandler() {
     if (globalErrorHandlerEnabled()) {
         window.addEventListener("unhandledrejection", (promiseRejectionEvent: PromiseRejectionEvent) => {
             logError("Unhandled Promise Rejection:", promiseRejectionEvent.reason);
             window.location.assign("/error.html");
         });
     }
}
