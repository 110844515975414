import * as React from "react";
import {useEffect, useState} from "react";
import {PowerBiReportComponent} from "./PowerBiReport.component";
import {byDate} from "../../utils/dateUtil";
import {ClientReportingHeader} from "./ClientReportingHeader";
import {IReportConfig, PowerBiReportingApi} from "../../api/PowerBiReportingApi";
import {NOT_REQUESTED, REQUEST_FAILED, REQUEST_SUCCEEDED, REQUESTED, RequestState} from "../common/commonStates";
import {ClientReport} from "../../model/ClientReport.model";

export interface IClientReportingProps {
    currentTab: string;
    clientReports: ClientReport[];
}
export const ClientReporting: React.FunctionComponent<IClientReportingProps> = (props) => {
    const [currentReport, setCurrentReport] = useState<ClientReport | undefined>(undefined);
    const [reportConfig, setReportConfig] = useState<RequestState<IReportConfig>>(NOT_REQUESTED);

    const getCurrentClientReport = (): ClientReport | undefined => {
        return getClientReportsForCurrentTab().sort(byDate)[0];
    };

    const getClientReportsForCurrentTab = () => {
        return props.clientReports.filter((r) => r.categoryName.toUpperCase() === props.currentTab);
    };

    const renderReport = () => {
        return currentReport ?
            <PowerBiReportComponent
                reportConfig={reportConfig}
                displayPrintLink={false}
                clientReportId={currentReport.id}
        />
        : null;
    };

    const setReportAndConfig = (report: ClientReport) => {
        setCurrentReport(report);
        setReportConfig(REQUESTED);
        PowerBiReportingApi.requestClientReportConfig(report!.id)
            .then((config:IReportConfig) => setReportConfig(REQUEST_SUCCEEDED(config)))
            .catch(() => setReportConfig(REQUEST_FAILED("Could not request report config")));
    };

    const resetReportAndConfig = () => {
        setCurrentReport(undefined);
        setReportConfig(NOT_REQUESTED);
    };

    useEffect(() => {
        const report = getCurrentClientReport();
        if (report) {
            setReportAndConfig(report);
        } else {
            resetReportAndConfig();
        }
    }, [props.currentTab, props.clientReports]);

    return currentReport
        ? <div className="powerbi-reporting__client-reporting-container" data-testid="client-reporting">
            <ClientReportingHeader currentReport={currentReport}
                                   reports={getClientReportsForCurrentTab()}
                                   setCurrentReport={setReportAndConfig}/>
            {renderReport()}
        </div>
       : null;
};