import * as React from "react";
import {useEffect, useState} from "react";
import {SharedDocument} from "../../model/SharedDocument.model";
import {FormattedMessage} from "react-intl";
import {getQuarterAndYearByDate, QuarterFilterItems} from "../../utils/dateUtil";
import {ALL_DOCUMENT_TYPES, ALL_YEARS} from "./SharedDocumentsPage";
import {DialogComponent} from "../common/Dialog.component";
import {throwErrorOnNullOrUndefined} from "../../utils/errorUtil";
import SharedDocumentsFile from "./SharedDocumentsFile";
import {useDispatch, useSelector} from "react-redux";
import {deleteSharedDocumentFile} from "./SharedDocuments.actions";
import {getSortBy, SortOptions} from "../../utils/sharedDocsUtil";
import {hasConsultantLevelAccess} from "../../utils/sessionUtil";
import {hasThirdPartyAccess} from "../../../mainReducerMapSelectors";
import {getTranslation} from "../../utils/translationUtil";

export interface ISharedDocumentsFileListProps {
    documents: SharedDocument[];
    currentUserEmail: string;
    selectedOrder: SortOptions;
    selectedYear: number;
    selectedQuarter: QuarterFilterItems;
    selectedDocumentType: number;
    aonTrustCompany: boolean;
    handleClearFilters: () => void;
}

export const SharedDocumentsFileList: React.FunctionComponent<ISharedDocumentsFileListProps> = (props) => {
    const [dialogOpen, setDialogOpen] = useState(false);
    const [currentDocuments, setCurrentDocuments] = useState<SharedDocument[]>(props.documents);
    const [deletedDocumentId, setDeletedDocumentId] = useState<number | undefined>(undefined);

    const thirdPartyAccessEnabledPlan = useSelector(state => hasThirdPartyAccess(state));

    const dispatch = useDispatch();

    function getDocTitle(doc: SharedDocument) {
        return (doc.title === "") ? doc.fileName : doc.title;
    }

    function addFileName(doc: SharedDocument): SharedDocument {
        return new SharedDocument(
            doc.id,
            doc.fileName,
            getDocTitle(doc),
            doc.date,
            doc.documentType,
            doc.createdMillis,
            [],
            doc.email,
            doc.thirdPartyAccess);
    }

    function matchesDocumentTypeFilter(sharedDocument: SharedDocument) {
        if (props.selectedDocumentType === ALL_DOCUMENT_TYPES) {
            return true;
        }
        return sharedDocument.documentType.id === props.selectedDocumentType;
    }

    function matchesYearFilter(sharedDocument: SharedDocument) {
        if (props.selectedYear === ALL_YEARS) {
            return true;
        }

        return sharedDocument.date.getFullYear() === props.selectedYear;
    }

    function matchesQuarterFilter(sharedDocument: SharedDocument) {
        if (props.selectedQuarter === QuarterFilterItems.ALL) {
            return true;
        }

        return getQuarterAndYearByDate(sharedDocument.date).quarter.toString() === props.selectedQuarter.toString();
    }

    function matchesAllFilters(sharedDocument: SharedDocument) {
        return matchesDocumentTypeFilter(sharedDocument)
            && matchesYearFilter(sharedDocument)
            && matchesQuarterFilter(sharedDocument);
    }

    function renderNoMatchesFound() {
        return <div className="shared-documents__no-documents-container">
            <div className="shared-documents__no-documents-message">
                <FormattedMessage id="shared-docs.no-docs" defaultMessage="No documents match the filters you've selected." />
            </div>
            <a className="link-16 clickable" onClick={() => props.handleClearFilters()}>
                <FormattedMessage id="filters.clear-filters" defaultMessage="Clear Filters" />
            </a>
        </div>;
    }

    function renderTableHeader() {
        const getIconHeadersArray = (n: number) => new Array(n).fill(0)
            .map((_, index) => <th className="shared-documents__table-header-icon" key={index}/>);

        return <tr>
            <th className="shared-documents__table-header-date">
                <span>
                    <FormattedMessage id="shared-docs.time-period" defaultMessage="TIME PERIOD" />
                </span>
            </th>
            <th className="shared-documents__table-header-title">
                <div>
                    <FormattedMessage id="shared-docs.doc-title" defaultMessage="DOCUMENT TITLE" />
                </div>
            </th>
            {
                hasConsultantLevelAccess() && thirdPartyAccessEnabledPlan &&
                <th className="shared-documents__table-header-icon thirdPartyAccess">
                    <div>
                        <FormattedMessage id="upload.3rd-party-access" defaultMessage="3RD PARTY ACCESS" />
                    </div>
                </th>
            }

            {getIconHeadersArray(3)}
        </tr>;
    }

    function handleOpen(documentId: number) {
        setDialogOpen(true);
        setDeletedDocumentId(documentId);
    }

    function renderDocuments() {
        return currentDocuments.map((doc, index) =>
            <SharedDocumentsFile document={doc}
                                 key={`file-key-${index}`}
                                 currentUserEmail={props.currentUserEmail}
                                 index={index}
                                 handleOpenDialog={handleOpen}
                                 showThirdPartyColumn = {thirdPartyAccessEnabledPlan}
                                 aonTrustCompany={props.aonTrustCompany}
            />
        );
    }

    function renderTableWithDocuments() {
        return <table className="shared-documents__table advanced-file-management">
            <thead>
            {renderTableHeader()}
            </thead>
            <tbody>
            {renderDocuments()}
            </tbody>
        </table>;
    }

    function renderSharedDocumentsTable() {
        if (currentDocuments.length === 0) {
            return renderNoMatchesFound();
        }

        return renderTableWithDocuments();
    }

    function handleDelete() {
        dispatch(deleteSharedDocumentFile(throwErrorOnNullOrUndefined(deletedDocumentId)));
        setDialogOpen(false);
    }

    function handleClose() {
        setDialogOpen(false);
    }

    function renderConfirmDeleteDialog() {
        return <DialogComponent
            title={getTranslation(
                  "shared-docs.remove-file-title",
                  "Remove this file from all associated plans?")}
            open={dialogOpen}
            description={getTranslation(
                "shared-docs.remove-file-desc",
                "This file will no longer be accessible from any plan.")}
            noText={getTranslation( "shared-docs.no-cancel","No, cancel")}
            yesText={getTranslation("shared-docs.yes-delete","Yes, delete")}
            onNo={handleClose}
            onYes={handleDelete}
        />;
    }

    useEffect(() => {
        const filteredDocuments = props.documents
            .filter((it) => matchesAllFilters(it)).map(addFileName)
            .sort(getSortBy(props.selectedOrder));
        setCurrentDocuments(filteredDocuments);
    },[props.documents, props.selectedOrder, props.selectedYear, props.selectedQuarter, props.selectedDocumentType]);

    return <>
        {renderConfirmDeleteDialog()}
        {renderSharedDocumentsTable()}
    </>;
};

export default SharedDocumentsFileList;