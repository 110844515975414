import * as React from "react";

export const LoadingSpinner = () => {
    return <div className="loading" data-testid="loading__spinner">
        <div className="loading__wrapper">
            <div className="loading__animation">
                <div className="dot"/>
                <div className="dot2"/>
                <div className="dot3"/>
                <div className="dot4"/>
            </div>
            <p>loading&nbsp;data.&nbsp;.&nbsp;.</p>
        </div>
    </div>;
};
