import {IManagerUpdate} from "../../api/CprPortfolioApi";
import {byName} from "../../utils/listUtil";

const RatingsMap = new Map([
    ["NOT RATED", 0],
    ["NOT RECOMMENDED", 1],
    ["SELL", 2],
    ["QUALIFIED", 3],
    ["QUALIFIED INFORM", 3],
    ["BUY", 5],
]);

export const findRatingValue = (rating: string) => {
    const value = RatingsMap.get(rating.toUpperCase());
    return value ?? -1;
};

export const filterManagerUpdate = (resp: IManagerUpdate[], isUpgrade: boolean = true) => {
    return resp.filter(update => {
        const currentRatingValue = findRatingValue(update.currentRating);
        const lastRatingValue = findRatingValue(update.lastReportingRating);
        if (currentRatingValue !== -1 && lastRatingValue !== -1) {
            return isUpgrade ? currentRatingValue > lastRatingValue : currentRatingValue < lastRatingValue;
        }
    }).sort(byName);
};