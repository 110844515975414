import {Map} from "immutable";
import {IManagerResearchModel} from "../model/manager/IManagerResearchModel";
import {Manager} from "../model/manager/Manager.model";
import {ManagerGroup} from "../model/manager/ManagerGroup";
import {ManagerGroupWithDate} from "../model/manager/ManagerGroupWithDate";
import {ProductSummary} from "../model/product/ProductSummary";
import {Ticker} from "../model/product/Ticker";
import {ResearchSearchItem} from "../model/Search.model";
import {byName} from "./listUtil";

export const sortProductsByName = (manager: IManagerResearchModel): IManagerResearchModel => {
    manager.products = manager.products.sort(byName);
    return manager;
};

export const groupProductSummariesByManager = (productSummaries: ProductSummary[]): ManagerGroup[] => {
    const managerAndProductReducer = (managerGroups: ManagerGroup[], productSummary: ProductSummary) => {
        const managerGroup =
            getManagerGroup(
                managerGroups,
                productSummary.manager,
            );
        managerGroup.products.push(productSummary);

        return managerGroups;
    };
    return productSummaries.reduce(managerAndProductReducer, []);
};

export const getManagerGroup = (managerGroups: ManagerGroup[], manager: Manager): ManagerGroup => {
    return managerGroups.find((it) => it.managerBackstopId === manager.managerBackstopId) ||
        addNewManagerGroup(managerGroups, manager.managerBackstopId, manager.name);
};

export const groupProductSummariesByManagerAndDate = (productSummaries: ProductSummary[]): ManagerGroupWithDate[] => {
    const managerWithDateAndProductReducer =
        (managerGroups: ManagerGroupWithDate[], productSummary: ProductSummary) => {
            const managerGroup =
                managerGroups.find(
                    managerWithIdAndDate(
                        productSummary.manager.managerBackstopId,
                        productSummary.currentRatingEffectiveDate),
                )
                || addNewManagerGroupWithDate(
                    managerGroups,
                    productSummary.manager,
                productSummary.currentRatingEffectiveDate,
                );

            managerGroup.products.push(productSummary);

            return managerGroups;
        };
    return productSummaries.reduce(managerWithDateAndProductReducer, []);
};

function managerWithIdAndDate(managerBackstopId: number, date: Date) {
    return (it: ManagerGroupWithDate) => {
        return it.managerBackstopId === managerBackstopId && it.date.getTime() === date.getTime();
    };
}

const addNewManagerGroupWithDate = (managerGroups: ManagerGroupWithDate[], manager: Manager, date: Date) => {
    const managerGroupWithDate = new ManagerGroupWithDate(manager.managerBackstopId, manager.name, date, []);
    managerGroups.push(managerGroupWithDate);
    return managerGroupWithDate;
};

const addNewManagerGroup = (managerGroups: ManagerGroup[], backstopId: number, name: string): ManagerGroup => {
    const newManagerGroup = new ManagerGroup(backstopId, name);
    managerGroups.push(newManagerGroup);
    return newManagerGroup;
};

const productSearchItemsReducer = (searchItems: ResearchSearchItem[],
                                   productSummary: ProductSummary): ResearchSearchItem[] => {
    return [...searchItems, ...getSearchItemsFromTickers(productSummary)];
};

const getSearchItemsForProducts = (productSummaries: ProductSummary[]) => {
    return productSummaries.reduce(productSearchItemsReducer, []);
};

export const getSearchItemsFromProducts = (productSummaries: ProductSummary[]): ResearchSearchItem[] => {
    const productResearchItems = getSearchItemsForProducts(productSummaries);
    const managerResearchItems = getSearchItemsForManagers(productSummaries);
    return [...productResearchItems, ...managerResearchItems];
};

const getResearchItemForProduct = (productSummary: ProductSummary,
                                   productName: string,
                                   tickerId: string | undefined = undefined) => {
    return new ResearchSearchItem(
        productSummary.backstopId,
        productName,
        "PRODUCT",
        `/products/${productSummary.backstopId}`,
        tickerId);
};

const getResearchItemForTicker = (productSummary: ProductSummary) => {
    return (ticker: Ticker) =>
        getResearchItemForProduct(productSummary, `${productSummary.name} (${ticker!.name})`, ticker!.name);
};

const getSearchItemsFromTickers = (productSummary: ProductSummary): ResearchSearchItem[] => {
    return productSummary.tickers.length === 0
        ? [getResearchItemForProduct(productSummary, productSummary.name)]
        : productSummary.tickers
            .sort(byName)
            .map(getResearchItemForTicker(productSummary));
};

function getSearchItemsForManagers(productSummaries: ProductSummary[]): ResearchSearchItem[] {
    return productSummaries.reduce(managerSearchItemReducer, Map()).valueSeq().toArray();
}

const managerSearchItemReducer = (managersResearchItemsByBackstopId: Map<number, ResearchSearchItem>,
                                  productSummary: ProductSummary) => {
    const backstopId = productSummary.manager.managerBackstopId;
    const researchSearchItem = getResearchItemForManager(productSummary);
    return managersResearchItemsByBackstopId.set(backstopId, researchSearchItem);
};

const getResearchItemForManager = (productSummary: ProductSummary) => {
    const backstopId = productSummary.manager.managerBackstopId;
    return new ResearchSearchItem(backstopId,
        productSummary.manager.name,
        "MANAGER",
        `/managers/${backstopId}`,
        undefined,
    );
};
