import {
    createTheme,
    FormControl,
    InputLabel,
    MenuItem, ThemeProvider,
    Select
} from "@mui/material";
import * as React from "react";
import {ReactNode, useState} from "react";
import {FormattedMessage} from "react-intl";
import {AonColors} from "../../../../css/Colors";
import {isNullOrUndefined} from "../../utils/commonUtil";
import {styled} from "@mui/material/styles";

export const StyledSelect = styled(Select)`
    .MuiSelect-select {
        margin-bottom: -2px;
        &:focus {
            background: none;
        }       
    },
    .MuiSelect-icon.MuiSelect-iconStandard {
        color: ${AonColors.AonTealDark};
    }`;

const theme = createTheme({
    components: {
        MuiInputBase: {
            styleOverrides: {
                input: {
                    fontWeight: 300,
                },
            },
        },
        MuiInput: {
            styleOverrides: {
                underline: {
                    "&:hover:before": {
                        borderBottom: `2px solid ${AonColors.AonTealDark} !important`,
                    },
                    "&:after": {
                        borderBottomColor: AonColors.AonTealDark,
                    },
                },
                root: {
                    marginTop: '0px !important'
                }
            },
        },
    },
});

export const NewStyledInputLabel = styled(InputLabel)`
  .MuiInputLabel-shrink {
    display: none;
  }
`;

const inputLabelTheme = createTheme({
    components: {
        MuiFormLabel: {
            styleOverrides: {
                root: {
                    color: `${AonColors.AonBlack} !important`,
                    opacity: '0.4 !important'
                },
            }
        },
        MuiInputLabel: {
            styleOverrides: {
                root: {
                    top: "-18px !important",
                    left: "3px !important"
                }
            }
        }
    }
});

export interface ISelectValue {
    name: string;
    id: number | string | null;
}

export function createSelectValue(value: string) {
    return {name: value, id: value};
}

export interface ISelectComponentPropsFromParent {
    values?: ISelectValue[];
    valuesAsStrings?: string[];
    selected: any;
    width: number;
    submitClicked?: boolean;
    handleChange: (e: any, node?: any) => void;
    id: string;
    placeholder?: string;
    renderRequired?: boolean;
    multiple?: boolean;
    classNameSuffix?: string;
    menuItemTransform?: (value: ISelectValue) => ReactNode;
    renderValue?: (selectedId: any) => ReactNode;
    selectMaxHeight?: number;
    intlPrefix?: string | undefined;
}

export const SelectComponent = (props: ISelectComponentPropsFromParent) => {
    const [showLabel, setShowLabel] = useState(true);
    const suffix = props.classNameSuffix ? props.classNameSuffix : props.placeholder;

    const transform = props.menuItemTransform ? props.menuItemTransform : (value: ISelectValue) => value.name;

    const maxHeight = props.selectMaxHeight;
    const MenuProps = props.selectMaxHeight
        ? {
            PaperProps: {
                style: {
                   maxHeight,
                },
            },
        }
        : {};

    const values: ISelectValue[] = props.valuesAsStrings
        ? props.valuesAsStrings.map((value: string) => ({id: value, name: value}))
        : props.values as ISelectValue[];

    return (
        <FormControl variant="standard">
            {(showLabel && props.selected === undefined) &&
                <ThemeProvider theme={inputLabelTheme}>
                    <NewStyledInputLabel
                        className={`select-${suffix}`}
                        htmlFor="placeholder"
                        disableAnimation={true}
                        shrink={false}>
                        {props.placeholder}
                    </NewStyledInputLabel>
                </ThemeProvider>}
            <ThemeProvider theme={theme}>
                <StyledSelect className={`select-inner-select-${suffix}`}
                              id={props.id}
                              value={!isNullOrUndefined(props.selected) ? props.selected : (props.multiple ? [] : "")}
                              onChange={(e: any, node: any) => {
                                  props.handleChange(e, node);
                                  setShowLabel(false);
                              }}
                              displayEmpty={false}
                              onClose={() => {
                                  return;
                              }}
                              style={{width: props.width + "px"}}
                              inputProps={{
                                  id: "placeholder",
                                  MenuProps: {disableScrollLock: true}
                              }}
                              renderValue={props.renderValue}
                              multiple={props.multiple ? props.multiple : false}
                              MenuProps={MenuProps}
                              data-testid={props.id}
                              labelId={props.id}
                              color={"secondary"}
                >
                    {values.map((value) => renderMenuItem(value, props.intlPrefix, transform))}
                </StyledSelect>
            </ThemeProvider>

            {renderRequiredCaption(props.submitClicked, props.selected, props.renderRequired)}
        </FormControl>
    );
};

export const renderMenuItem =
    (value: ISelectValue, intlPrefix: string | undefined, transform: (value: ISelectValue) => ReactNode) => {
        return <MenuItem
            className={"select-component__menu-item"}
            key={!isNullOrUndefined(value.id) ? value.id : value.name}
            value={!isNullOrUndefined(value.id) ? value.id : value.name}
        >
            {
                intlPrefix
                ? <FormattedMessage id={`${intlPrefix}.${value.id}`} defaultMessage={value.name}/>
                : transform(value)
            }
        </MenuItem>;
};

const renderRequiredCaption = (submitClicked?: boolean, selected?: ISelectValue, renderRequired?: boolean) => {
    const className = (submitClicked && !selected)
        ? "select-field__required-caption-red"
        : "select-field__required-caption";

    return renderRequired
        ? <span className={className}>
            <FormattedMessage id="select.required"
                              defaultMessage="required" />
            </span>
        : null;
};
