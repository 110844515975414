import {Action, Dispatch} from "redux";
import {HoldingsApi} from "../../../api/HoldingsApi";

export enum HoldingsActions {
    HOLDINGS_RECEIVED = "HOLDINGS_RECEIVED",
    HOLDINGS_REQUESTED = "HOLDINGS_REQUESTED",
    GET_HOLDINGS_REQUEST_FAILED = "GET_HOLDINGS_REQUEST_FAILED",

    ALL_HOLDINGS_RECEIVED = "ALL_HOLDINGS_RECEIVED",
    ALL_HOLDINGS_REQUESTED = "ALL_HOLDINGS_REQUESTED",
    ALL_GET_HOLDINGS_REQUEST_FAILED = "ALL_GET_HOLDINGS_REQUEST_FAILED",
}

export const getHoldingsForPlan = (dispatch: Dispatch<Action<HoldingsActions>>) => {
    dispatch({type: HoldingsActions.HOLDINGS_REQUESTED});

    HoldingsApi.getPlanHoldings().then((result) => {
        dispatch({
            type: HoldingsActions.HOLDINGS_RECEIVED,
            payload: result.data,
        });
    }).catch(() => {
        dispatch({type: HoldingsActions.GET_HOLDINGS_REQUEST_FAILED});
    });
};

export const getAllHoldings = (dispatch: Dispatch<Action<HoldingsActions>>) => {
    dispatch({type: HoldingsActions.ALL_HOLDINGS_REQUESTED});

    HoldingsApi.getAllHoldings().then((result) => {
        dispatch({
            type: HoldingsActions.ALL_HOLDINGS_RECEIVED,
            payload: result.data,
        });
    }).catch(() => {
        dispatch({type: HoldingsActions.ALL_GET_HOLDINGS_REQUEST_FAILED});
    });
};
