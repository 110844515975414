import * as React from "react";
import {useEffect, useState} from "react";
import {IPerformanceDocsFiltersProps, PerformanceSubNav} from "./PerformanceReportingTab";
import {SharedDocument} from "../../model/SharedDocument.model";
import {formatQuarterAndYear, getQuarterAndYearByDate, QuarterFilterItems} from "../../utils/dateUtil";
import {SharedDocumentApi} from "../../api/SharedDocumentApi";
import {SupplementalReportsFilters} from "./SupplementalReportsFilters";
import {getSortBy, SortOptions} from "../../utils/sharedDocsUtil";
import {ALL_YEARS} from "../shared-documents/SharedDocumentsPage";
import {handleEnter} from "../../utils/commonUtil";
import {FormattedMessage} from "react-intl";

export interface ISupplementalReportsFromParent {
    setSubNav: (subNav: PerformanceSubNav) => void;
    sharedDocuments: SharedDocument[];
    selectedOrder: SortOptions;
    showFactSheets: boolean;
}

export type SupplementalReportsProps = ISupplementalReportsFromParent & IPerformanceDocsFiltersProps;

export const PERFORMANCE_DOC_TYPE = "Performance Reports";

export const SupplementalReports: React.FunctionComponent<SupplementalReportsProps> = (props) => {
    const [filtered, setFiltered] = useState<SharedDocument[]>([]);

    function getDocument(documentId: number, filename: string) {
        SharedDocumentApi.getDocumentDownload(documentId, filename, true);
    }

    const renderSharedDoc = (sharedDocument: SharedDocument) => {
        return <tr key={sharedDocument.id} id={`performance-docs__row-${sharedDocument.id}`}>
            <td className="performance-docs__doc-date" data-testid="performance-docs__doc-date">
                {formatQuarterAndYear(getQuarterAndYearByDate(sharedDocument.date))}
            </td>
            <td>
                <a className="performance-docs__doc-title"
                   data-testid="performance-docs__doc-title"
                   tabIndex={0}
                   onClick={() => getDocument(sharedDocument.id, sharedDocument.fileName)}
                   onKeyUp={handleEnter(() => getDocument(sharedDocument.id, sharedDocument.fileName))}>
                    {sharedDocument.title === "" ? sharedDocument.fileName : sharedDocument.title}
                </a>
            </td>
        </tr>;
    };

    const renderFactSheetsTable = () => {
        return <table className="performance-docs__table">
            <thead>
            <tr>
                <th className="performance-docs__table-header-date">
                    <FormattedMessage id="shared-docs.time-period" defaultMessage="TIME PERIOD"/>
                </th>
                <th className="performance-docs__table-header-title">
                    <FormattedMessage id="shared-docs.doc-title" defaultMessage="DOCUMENT TITLE"/>
                </th>
            </tr>
            </thead>
            <tbody>
            {filtered.map(renderSharedDoc)}
            </tbody>
        </table>;
    };

    const renderSharedDocs = () => {
        return filtered.length > 0
            ? renderFactSheetsTable()
            : <div className="performance-docs__no-results">
                <FormattedMessage
                    id="shared-docs.no-docs"
                    defaultMessage="No documents match the filters you've selected."/>
        </div>;
    };

    const getPerformanceReports = (): SharedDocument[] => {
        return props.sharedDocuments
            .filter(((document) => document.documentType.name === PERFORMANCE_DOC_TYPE));
    };

    function matchesYearFilter(sharedDocument: SharedDocument) {
        return props.selectedYear === ALL_YEARS
            ? true
            : sharedDocument.date.getFullYear() === props.selectedYear;
    }

    function matchesQuarterFilter(sharedDocument: SharedDocument) {
        return props.selectedQuarter === QuarterFilterItems.ALL
            ? true
            : getQuarterAndYearByDate(sharedDocument.date).quarter.toString() === props.selectedQuarter.toString();
    }

    function matchesAllFilters(sharedDocument: SharedDocument) {
        return matchesYearFilter(sharedDocument)
            && matchesQuarterFilter(sharedDocument);
    }

    useEffect(() => {
        const performanceReports = getPerformanceReports()
            .filter((it) => matchesAllFilters(it))
            .sort(getSortBy(props.selectedOrder));

        setFiltered(
            performanceReports
        );
    }, [props]);

    function renderFactSheetsLink() {
        return props.showFactSheets
            ? <a className="powerbi-reporting__fact-sheets-link old-anchor"
                 tabIndex={0}
                 onClick={() => props.setSubNav(PerformanceSubNav.FACT_SHEETS)}
                 onKeyUp={handleEnter(() => props.setSubNav(PerformanceSubNav.FACT_SHEETS))}>
                <FormattedMessage id="client-reporting.fact-sheets" defaultMessage="Fact Sheets"/>
            </a>
            : null;
    }

    return <div className="supplemental-reports__container" data-testid="powerbi-reporting__supplemental-reports">
        <div className="performance-docs__header-container">
            <h2>
                <FormattedMessage id="filters.supplemental-documents" defaultMessage="Supplemental Reports"/>
            </h2>
            <div className="powerbi-reporting__sub-nav-links">
                <a className="powerbi-reporting__report-link old-anchor"
                   tabIndex={0}
                   onClick={() => props.setSubNav(PerformanceSubNav.REPORT)}
                   onKeyUp={handleEnter(() => props.setSubNav(PerformanceSubNav.REPORT))}>
                    <FormattedMessage id="filters.performance-reports" defaultMessage="Performance Report"/>
                </a>
                {renderFactSheetsLink()}
            </div>
        </div>
        <SupplementalReportsFilters selectedYear={props.selectedYear}
                                    selectedQuarter={props.selectedQuarter}
                                    handleFilterChange={props.handleFilterChange}
                                    resetFilters={props.resetFilters}
                                    documents={getPerformanceReports()}
                                    selectedOrder={props.selectedOrder}
        />
        <div className="performance-docs__table-container">
            {renderSharedDocs()}
        </div>
    </div>;
};