require("./polyfill.js");

import {getWindowConfig} from "../api/BrowserWrapper";
import {isDateBetween} from "./dateUtil";

export class Config {
    // Settings:
    public FRONTEND_CONFIG_ENVIRONMENT: string;
    public FRONTEND_CONFIG_HOSTNAME: string;
    public FRONTEND_CONFIG_BACKEND_URL: string;
    public FRONTEND_CONFIG_RESEARCH_PAGE_SIZE: string;
    public FRONTEND_CONFIG_PAGE_SIZE: string;
    public FRONTEND_CONFIG_OKTA_CLIENT_ID: string;
    public FRONTEND_CONFIG_OKTA_ISSUER: string;

    // Feature flags:
    public FRONTEND_CONFIG_GLOBAL_ERROR_HANDLER: string | undefined;
    public FRONTEND_CONFIG_SHOW_CALENDAR_MEETING: string | undefined;

    // Home Page Message:
    public FRONTEND_CONFIG_HOME_PAGE_MESSAGE_START_DATE: string | undefined;
    public FRONTEND_CONFIG_HOME_PAGE_MESSAGE_END_DATE: string | undefined;
    public FRONTEND_CONFIG_HOME_PAGE_MESSAGE_MESSAGE: string | undefined;
}

export const MAX_SECONDS_SINCE_LOGOUT = 10;

export const getConfig = (): Config =>  {
    return getWindowConfig() as Config;
};

function isTrue(flag: string| undefined) {
    return !flag
        ? false
        : flag.toLowerCase() === "true";
}

export const globalErrorHandlerEnabled = () => {
    return isTrue(getConfig().FRONTEND_CONFIG_GLOBAL_ERROR_HANDLER);
};

export const showCalendarMeetingEnabled = () => {
    return isTrue(getConfig().FRONTEND_CONFIG_SHOW_CALENDAR_MEETING);
};

export const showHomePageMessage = () => {
    const message = getConfig().FRONTEND_CONFIG_HOME_PAGE_MESSAGE_MESSAGE;
    const startingDate = getConfig().FRONTEND_CONFIG_HOME_PAGE_MESSAGE_START_DATE;
    const endingDate = getConfig().FRONTEND_CONFIG_HOME_PAGE_MESSAGE_END_DATE;

    if (!message || !startingDate || !endingDate) {
        return null;
    }

    const currentDate = new Date(Date.now());
    const startDate = new Date(startingDate);
    const endDate = new Date(endingDate);

    if (!isDateBetween(currentDate, startDate, endDate)) {
        return null;
    }

    return message;
};
