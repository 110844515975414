import * as React from "react";

class FeedbackSuccessPage extends React.Component {
    public render() {
        return (
            <div className="main-content" data-testid="feedback-success-page">
                <h1>Thank you for your feedback!</h1>
                Your message was successfully sent.
            </div>
        );
    }
}

export default FeedbackSuccessPage;
