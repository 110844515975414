import * as React from "react";
import {IOddQuestionSummary, IOddResponseSummary} from "../../../api/OddIqApi";
import {generatePercentagesFromResponseSummary, getInitialResponseSummary} from "../OddIqUtils";
import {IDonutChartData, IDonutChartProperties, summaryToDonutChartData} from "../OddIqChartingUtils";
import {DonutChartCommon} from "../odd-page/DonutChartCommon";


export interface IOddTotalResultsByRiskCategoryChartProps {
    oddQuestionSummaries: IOddQuestionSummary[];
}

export const OddTotalResultsByRiskCategoryChart: React.FunctionComponent<IOddTotalResultsByRiskCategoryChartProps> = (props) => {
    const responseSummary = props.oddQuestionSummaries
        .reduce((acc: IOddResponseSummary, questionSummary) => {
            acc.noData = acc.noData + questionSummary.summary.noData;
            acc.exceptions = acc.exceptions + questionSummary.summary.exceptions;
            acc.bestPractice = acc.bestPractice + questionSummary.summary.bestPractice;
            return acc;
        }, getInitialResponseSummary());

    const responseSummaryPercents = generatePercentagesFromResponseSummary(responseSummary);

    const donutChartData: IDonutChartData[] = summaryToDonutChartData(responseSummary, responseSummaryPercents);

    const chartDimensions = 200;
    const chartPadding = {
        top: 100,
        bottom: 100,
        right: 175,
        left: 150,
    };
    const chartProperties: IDonutChartProperties = {
        innerRadius: 60,
        donutThickness: 20,
    };

    return <div className="odd-page__donut-chart-container">
        <DonutChartCommon chartData={donutChartData}
                          prefix="odd-page"
                          totalLabel="Data Points"
                          title=""
                          chartProperties={chartProperties}
                          padding={chartPadding}
                          innerChartDimensions={chartDimensions}
        />
    </div>;
};