import {Map} from "immutable";
import * as React from "react";
import {connect} from "react-redux";
import {Action, bindActionCreators, Dispatch} from "redux";
import {
    allManagersSelector,
    portfolioProductSummariesSelector,
    productUniverseSummariesSelector,
} from "../../../mainReducerMapSelectors";
import {FlashReport} from "../../model/FlashReport.model";
import {FlashReportSummary} from "../../model/FlashReportSummary.model";
import {ManagerResponse} from "../../model/manager/ManagerResponse.model";
import {ProductSummary} from "../../model/product/ProductSummary";
import {prominentButtonStyle} from "../common/buttonStyles";
import {RaisedButton} from "../common/RaisedButton";
import {Brackets, BracketsBox} from "../base/Brackets.component";
import {getSuccessData} from "../common/commonStates";
import {allManagersRequestPair, allProductsRequestPair} from "../common/RequesterPairs";
import {ManagerNameList} from "../manager/ManagerNameList.component";
import {ProductNameList} from "../product/ProductNameList.component";
import {flashReportActions} from "./flashReportActions";
import {multipleApiRequesterWrapper} from "../common/MultipleApiRequesterWrapper";
import {FormattedMessage} from "react-intl";
import {IApplicationRootState} from "../../../applicationState";

interface IFlashReportPropsFromParent {
    id: string;
    flashReport: FlashReport;
    flashReportSummary: FlashReportSummary;
}

interface IFlashReportPropsFromActions {
    actions: {
        getFlashReport: typeof flashReportActions.getFlashReport,
        getFlashReportPDF: typeof flashReportActions.getFlashReportPDF,
    };
}

export interface IFlashReportPropsFromState {
    allManagers: Map<number, ManagerResponse>;
    productUniverseSummaries: Map<number, ProductSummary>;
    portfolioProductSummaries: Map<number, ProductSummary>;
}

interface IFlashReportState {
    collapseManagersAndProducts: boolean;
}

export type FlashReportComponentProps = IFlashReportPropsFromActions
    & IFlashReportPropsFromParent
    & IFlashReportPropsFromState;

export class FlashReportComponent extends React.Component<FlashReportComponentProps, IFlashReportState> {

    constructor(props: FlashReportComponentProps) {
        super(props);

        this.state = {
            collapseManagersAndProducts: true,
        };
    }

    public render() {
        return (
            <div className="note-container no-bullets new-common-styles">
                {this.renderTitle()}
                {this.renderLinks()}
                {this.renderContent()}
            </div>
        );
    }

    private renderTitle() {
        return <div className="strategy-update__header">
            <div className={"header-wrapper"}>
                <h1 id="flashreport-title" className="blue">
                    {this.props.flashReportSummary.title}
                </h1>
            </div>
            {this.getDownloadButton()}
        </div>;
    }

    private getDownloadButton() {
        return <RaisedButton
            className="pdf-download-button"
            style={prominentButtonStyle}
            primary={true}
            onClick={this.onDownloadButtonClick}>
            <span className="pdf-download-button-body">
                <span className="pdf-download-button-text">
                    <FormattedMessage
                        id = "updates.download-pdf"
                        defaultMessage="Download PDF"
                        />
                </span>
                <span className="fal fa-download" />
            </span>
        </RaisedButton>;
    }

    private onDownloadButtonClick = () => {
        if (this.props.actions) {
            this.props.actions.getFlashReportPDF(this.props.flashReportSummary.backstopId,
                this.props.flashReportSummary.fileName);
        }
    };

    private renderLinks() {

        const {managerIds, productIds} = this.getManagerAndProductIds();

        return (<div className="attachments">
            <ManagerNameList managerIds={managerIds} allManagers={this.props.allManagers}/>
            <ProductNameList productIds={productIds}
                             productUniverseSummaries={this.props.productUniverseSummaries}
                             portfolioProductSummaries={this.props.portfolioProductSummaries}/>
            {this.renderViewAll()}
        </div>);
    }

    private getManagerAndProductIds() {
        const allManagerIds = this.props.flashReportSummary.managerIds;
        const allProductIds = this.props.flashReportSummary.productIds;

        const [numManagerIds, numProductIds] =
            (this.hasLongList() && this.state.collapseManagersAndProducts)
                ? ((allManagerIds.length > 3) ? [3, 0] : [allManagerIds.length, 3 - allManagerIds.length])
                : [allManagerIds.length, allProductIds.length];

        return {managerIds: allManagerIds.slice(0, numManagerIds), productIds: allProductIds.slice(0, numProductIds)};
    }

    private hasLongList() {
        const {managerIds, productIds} = this.props.flashReportSummary;
        return managerIds.length + productIds.length > 3;
    }

    private renderViewAll() {
        if (!this.hasLongList()) {
            return;
        }
        const collapsed = this.state.collapseManagersAndProducts;
        const viewText = collapsed ? "View All" : "View Less";
        // TODO: Remove
        return <div className={`view-all link-14 clickable ${collapsed ? "list-collapsed" : "list-expanded"}`}
                    onClick={this.handleViewAllClick}>{viewText}</div>;
    }

    private handleViewAllClick = () => {
        this.setState({collapseManagersAndProducts: !this.state.collapseManagersAndProducts});
    };

    private renderContent() {
        return <Brackets>
            <BracketsBox>
                <div className="body-16">
                    <div id="flashreport-content"
                         className="update-content"
                         dangerouslySetInnerHTML={
                             createMarkup(this.props.flashReport.descriptionHtml || "NA")
                         }
                    data-testid="flashreport-content"/>
                </div>
            </BracketsBox>
        </Brackets>;
    }
}

function createMarkup(somehtml: string) {
    return {__html: somehtml};
}

export const mapStateToProps = (state: IApplicationRootState) => {
    return {
        allManagers: getSuccessData(allManagersSelector(state))!,
        productUniverseSummaries: getSuccessData(productUniverseSummariesSelector(state))!,
        portfolioProductSummaries: getSuccessData(portfolioProductSummariesSelector(state))!,
    };
};

export const mapDispatchToProps = (dispatch: Dispatch<Action<void>>): IFlashReportPropsFromActions => {
    return {
        actions: bindActionCreators(flashReportActions, dispatch),
    };
};

const connectedComponent = connect<IFlashReportPropsFromState,
    IFlashReportPropsFromActions>(
    mapStateToProps,
    mapDispatchToProps,
)(FlashReportComponent);

export default multipleApiRequesterWrapper(
    connectedComponent,
    [
        allManagersRequestPair,
        allProductsRequestPair(),
    ],
);
