import {Map} from "immutable";
import * as React from "react";
import {connect} from "react-redux";
import {AnyAction, bindActionCreators, Dispatch} from "redux";
import {allMeetingNotesSelector} from "../../../mainReducerMapSelectors";
import {ApiError} from "../../model/ApiError.model";
import {MeetingNoteDetail} from "../../model/MeetingNoteDetail.model";
import {MeetingNoteSummary} from "../../model/MeetingNoteSummary.model";
import {ErrorComponent} from "../base/Error.component";
import {getSuccessData} from "../common/commonStates";
import {allMeetingsNotesRequestPair} from "../common/RequesterPairs";
import MeetingNoteComponent from "./MeetingNote.component";
import meetingNoteActions from "./meetingNoteActions";
import {IMeetingNoteState} from "./meetingNoteReducer";
import {multipleApiRequesterWrapper} from "../common/MultipleApiRequesterWrapper";
import {IApplicationRootState} from "../../../applicationState";

export class MeetingNoteParams {
    public meetingNoteId: string;
    public productId: number | undefined;
    public managerId: number | undefined;
}

export interface IMeetingNotePageAction {
    getMeetingNoteDetail: typeof meetingNoteActions.getMeetingNoteDetail;
    getMeetingNoteDetailForProduct: typeof meetingNoteActions.getMeetingNoteDetailForProduct;
    getMeetingNoteDetailForManager: typeof meetingNoteActions.getMeetingNoteDetailForManager;
}

export interface IMeetingNotePagePropsFromActions {
    actions: IMeetingNotePageAction;
}

export interface IMeetingNotePagePropsFromStore {
    allMeetingNoteSummaries: Map<number, MeetingNoteSummary>;
    meetingNoteDetail: MeetingNoteDetail | null;
    match: {
        params: MeetingNoteParams;
    };
    error?: ApiError;
}

export const MeetingNoteLoadingPlaceholder = () => (
    <div className="wealth-parent main-content">
        Loading...
    </div>
);

export type MeetingNotePageProps = IMeetingNotePagePropsFromStore
    & IMeetingNotePagePropsFromActions;

export class MeetingNotePage extends React.Component<MeetingNotePageProps, IMeetingNoteState> {

    public render() {
        if (this.props.error) {
            return <ErrorComponent/>;
        }

        if (this.props.meetingNoteDetail === null) {
            return <MeetingNoteLoadingPlaceholder/>;
        }

        const meetingNoteId = parseInt(this.props.match.params.meetingNoteId, 10);
        const summary = this.props.allMeetingNoteSummaries.get(meetingNoteId);

        if (!summary) {
            return <ErrorComponent/>;
        }

        const hasValidMeetingNotes = (productId: number| undefined, managerId: number | undefined) => {
            if(!productId && !managerId) return true;
            return (summary.productIds.includes(Number(productId)) || summary.managerIds.includes(Number(managerId)));
        };

        return <div className="wealth-parent main-content">
            {hasValidMeetingNotes(this.props.match.params.productId, this.props.match.params.managerId)
                ? <MeetingNoteComponent
                    meetingNoteSummary={summary}
                    meetingNoteDetail={this.props.meetingNoteDetail}
                    productId={this.props.match.params.productId}
                    managerId={this.props.match.params.managerId}
                />
                : <ErrorComponent/>}
        </div>;
    }

    public componentDidMount() {
        const meetingNoteId = parseInt(this.props.match.params.meetingNoteId, 10);
        if (this.props.match.params.productId) {
            this.props.actions.getMeetingNoteDetailForProduct(this.props.match.params.productId, meetingNoteId);
        } else if (this.props.match.params.managerId) {
            this.props.actions.getMeetingNoteDetailForManager(this.props.match.params.managerId, meetingNoteId);
        } else {
            this.props.actions.getMeetingNoteDetail(meetingNoteId);
        }
    }
}

export const mapStateToProps = (state: IApplicationRootState): any => {
    return {
        ...state.meetingNoteRootState,
        allMeetingNoteSummaries: getSuccessData(allMeetingNotesSelector(state))!,
    };
};

export const mapDispatchToProps = (dispatch: Dispatch<AnyAction>): IMeetingNotePagePropsFromActions => {
    return {
        actions: bindActionCreators(meetingNoteActions, dispatch),
    };
};

const connectedComponent = connect<IMeetingNotePagePropsFromStore, IMeetingNotePagePropsFromActions>(
    mapStateToProps,
    mapDispatchToProps,
)(MeetingNotePage);

export default multipleApiRequesterWrapper(
    connectedComponent,
    [
        allMeetingsNotesRequestPair,
    ],
    undefined,
    null,
);
