import * as React from "react";
import {ChangeEvent} from "react";
import {connect} from "react-redux";
import {Action, bindActionCreators, Dispatch} from "redux";
import {
    currentPlan,
    currentUserEmail,
    hasAonTrustCompany,
    hasThirdPartyAccess,
    sessionPlans,
    userType
} from "../../../mainReducerMapSelectors";
import {navigateTo} from "../../../navigateTo";
import {IApiResult} from "../../api/AxiosWealthManagementApi";
import {IPlanInfo} from "../../model/ClientsAndPlans.model";
import {SharedDocument, SharedDocumentType} from "../../model/SharedDocument.model";
import {ConsultantLevelList, doesUserHaveAccess} from "../../model/UserDetails.model";
import {prominentButtonStyle, secondaryButtonStyle} from "../common/buttonStyles";
import {RaisedButton} from "../common/RaisedButton";
import {getDateByQuarter, getQuarterAndYearByDate, getQuarters, getRecentYears, Quarter} from "../../utils/dateUtil";
import {throwErrorOnNullOrUndefined} from "../../utils/errorUtil";
import {ErrorComponent} from "../base/Error.component";
import {LoadingComponent} from "../base/Loading.component";
import {ISelectValue, SelectComponent} from "../common/Select.component";
import {TextFieldComponent} from "../common/TextField.component";
import SharedDocumentsAction from "./SharedDocuments.actions";
import {ISharedDocumentsPropsFromState} from "./SharedDocuments.reducer";
import {PlanSelect} from "../upload-page/PlanSelect";
import {UserTypeEnum} from "../../model/UserInfo.model";
import {SharedDocumentApi} from "../../api/SharedDocumentApi";
import {Checkbox} from "@mui/material";
import {AonColors, LegacyColors} from "../../../../css/Colors";
import {getTranslation} from "../../utils/translationUtil";
import {IApplicationRootState} from "../../../applicationState";

export interface IAdvancedEditFileRouteParams {
    documentId: number | undefined;
}

export interface IAdvancedEditFilePropsFromParent {
    match: {
        params: IAdvancedEditFileRouteParams;
    };
    router?: any;
}

export interface IAdvancedEditFileComponentState {
    selectedFile: File | undefined;
    title: string;
    quarter: Quarter;
    year: number;
    selectedDocumentType: number | null;
    documentTypeError: boolean;
    documentDataPending: boolean;
    documentDataResult?: IApiResult<SharedDocument>;
    documentError: boolean;
    errorMessage: string;
    selectedPlans: number [];
    years: ISelectValue [];
    quarters: ISelectValue [];
    thirdPartyAccess: boolean;
}

export interface IAdvancedEditFilePropsFromState {
    userType: UserTypeEnum;
    currentPlan: IPlanInfo;
    sessionPlans: IPlanInfo[];
    thirdPartyEnabledPlan: boolean;
    isAtcPlan: boolean;
}

export interface IAdvancedEditFilePropsFromActions {
    actions: {
        requestDocumentTypes: typeof SharedDocumentsAction.requestDocumentTypes;
        notifyFileUpdateSuccess: typeof SharedDocumentsAction.notifyFileUpdateSuccess;
    };
}

export type AdvancedEditFilePropsFromStateCombined =
    IAdvancedEditFilePropsFromState
    & ISharedDocumentsPropsFromState;

export type IAdvancedEditFileProps =
    IAdvancedEditFilePropsFromActions
    & ISharedDocumentsPropsFromState
    & IAdvancedEditFilePropsFromState
    & IAdvancedEditFilePropsFromParent;

export class AdvancedEditFileComponent extends React.Component
    <IAdvancedEditFileProps, IAdvancedEditFileComponentState> {

    private isCancelled: boolean = false;

    public constructor(props: any) {
        super(props);

        this.state = {
            selectedFile: undefined,
            title: "",
            year: 1990,
            quarter: Quarter.Q1,
            selectedDocumentType: null,
            documentTypeError: false,
            documentError: false,
            documentDataPending: false,
            errorMessage: "",
            selectedPlans: [],
            years: getRecentYears(),
            quarters: getQuarters(),
            thirdPartyAccess: false,
        };
    }

    public componentWillUnmount() {
        this.isCancelled = true;
    }

    public componentDidMount() {
        this.isCancelled = false;
        const maybeDocumentTypes = this.props.documentTypes.data;
        if (!maybeDocumentTypes || maybeDocumentTypes.length <= 0) {
            this.props.actions.requestDocumentTypes();
        }

        this.setState({
            documentDataPending: true
        });

        SharedDocumentApi.getSharedDocumentData(throwErrorOnNullOrUndefined(this.props.match.params.documentId))
            .then((result) => {
                if(!this.isCancelled) {
                    this.setState({documentDataResult: result, documentDataPending: false});

                    if (result.data) {
                        this.setState({
                            title: result.data.title,
                            year: result.data.date.getFullYear(),
                            quarter: getQuarterAndYearByDate(result.data.date).quarter,
                            selectedDocumentType: result.data.documentType.id,
                            documentTypeError: false,
                            documentDataPending: false,
                            selectedPlans: result.data.planIds,
                            thirdPartyAccess: result.data.thirdPartyAccess || false
                        });
                    }
                }
            });
    }

    public render() {
        const documentTypes = this.props.documentTypes.data || [];

        if (this.state.documentDataPending) {
            return <LoadingComponent/>;
        }

        if (this.props.documentTypes.error) {
            return <ErrorComponent/>;
        }

        if (this.state.documentDataResult &&
            this.state.documentDataResult!.error) {
            return <ErrorComponent/>;
        }

        if (!this.accessibleToUser()) {
            return <ErrorComponent/>;
        }

        const widthYear = 100;
        const widthQuarter = 100;

        return (
            <div id="container" className="main-content" data-testid="shared-document-edit">
                <h1 id="page-title">Edit File - {this.props.currentPlan.name}</h1>
                <div className="shared-document-list-combined">
                    <table style={{marginLeft: "-35px"}}>
                        <thead>
                        <tr>
                            <th>
                                <div>FILENAME</div>
                            </th>
                            <th>
                                <div>DOCUMENT TITLE</div>
                            </th>
                            <th>
                                <span>DOCUMENT TYPE</span>
                            </th>
                            <th>
                                <span>TIME PERIOD</span>
                            </th>
                            {
                                this.props.thirdPartyEnabledPlan &&
                                <th>
                                    <span>3RD PARTY ACCESS</span>
                                </th>
                            }
                        </tr>
                        </thead>
                        <tbody>
                        <tr>
                            <td style={{width: "150px"}}>
                                <div className="shared-document-list-combined filename">
                                    <div id="filename">{this.getFilename()}</div>
                                    <div style={{minHeight: "15px"}}/>
                                </div>
                            </td>
                            <td>
                                {this.renderFileTitle()}
                            </td>
                            <td style={{width: "260px"}}>
                                {this.renderTypeDropDown(documentTypes)}
                            </td>
                            <td style={{width: "230px"}}>
                                {this.renderTimePeriodDropDowns(widthYear, widthQuarter)}
                            </td>
                            { this.renderThirdPartyAccess() }
                        </tr>
                        </tbody>
                    </table>
                    <PlanSelect
                        onSelectElementsCallBack={this.onSelectPlansChange}
                        selectedElements={this.state.selectedPlans}
                        currentPlan={this.props.currentPlan}
                        userType={this.props.userType}
                    >
                        <div className="plans_header">PLANS</div>
                    </PlanSelect>
                    <div className="shared-document-list-combined submit_cancel_buttons">
                        <RaisedButton
                            className="edit-file__cancel-button"
                            style={{...secondaryButtonStyle}}
                            primary={false}
                            onClick={() => navigateTo("/shared-documents")}>
                            Cancel
                        </RaisedButton>

                        <RaisedButton
                            className="edit-file__save-button"
                            style={{...prominentButtonStyle, marginLeft: 15}}
                            primary={true}
                            onClick={this.submitForm}>
                            Save Changes
                        </RaisedButton>
                    </div>
                </div>
                {this.renderDocumentError()}
            </div>
        );
    }

    private renderThirdPartyAccess() {
        return this.props.thirdPartyEnabledPlan
            ? <td>
                <Checkbox
                    className={`thirdPartyAccessCheckbox`}
                    onChange={this.handleThirdPartyAccess}
                    checked={this.state.thirdPartyAccess}
                    style={{color: this.state.thirdPartyAccess ? AonColors.AonTealDark : LegacyColors.Black54}}
                />
            </td>
            : null;
    }

    private renderFileTitle() {
        return <div className="shared-document-list-combined field title edit-page">
            <TextFieldComponent
                id="title"
                name="title"
                placeholder="Enter a title for the document"
                className="title-input"
                type="text"
                fullWidth={true}
                value={this.state.title}
                handleChange={this.handleTitleChange}
                renderRequired={false}
            />
        </div>;
    }

    private renderTypeDropDown(documentTypes: SharedDocumentType[]) {
        return <div className="shared-document-list-combined field document_type edit-page">
            <SelectComponent
                id={"upload-file__document-type"}
                values={documentTypes}
                selected={this.state.selectedDocumentType}
                width={230}
                submitClicked={false}
                handleChange={this.handleDocumentTypeChange}
                placeholder={getTranslation("upload.document-type","Document Type")}
                renderRequired={true}
            />
        </div>;
    }

    private renderTimePeriodDropDowns(widthYear: number, widthQuarter: number) {
        return <div className="shared-document-list-combined time_period">
            <div className="shared-document-list-combined field year edit-page" id="year-picker">
                <SelectComponent
                    values={this.state.years}
                    selected={this.state.year}
                    width={widthYear}
                    submitClicked={false}
                    handleChange={this.handleYearChange}
                    id={"edit-file__date-year"}
                    placeholder={getTranslation("upload.year","Year")}
                    renderRequired={true}
                    selectMaxHeight={120}
                />
            </div>
            <div className="shared-document-list-combined field quarter edit-page" id="quarter-picker">
                <SelectComponent
                    values={this.state.quarters}
                    selected={this.state.quarter}
                    width={widthQuarter}
                    submitClicked={false}
                    handleChange={this.handleQuarterChange}
                    id={"edit-file__date-quarter"}
                    placeholder={getTranslation("upload.quarter","Quarter")}
                    renderRequired={true}
                />
            </div>
        </div>;
    }

    private handleThirdPartyAccess = (event: any) => {
        this.setState({thirdPartyAccess: event.target.checked});
    };

    private onSelectPlansChange = (planIds: number []) => {
        this.setState({selectedPlans: planIds});
    };

    private accessibleToUser() {
        return doesUserHaveAccess(this.props.userType, ConsultantLevelList)
            || this.props.currentUserEmail === this.state.documentDataResult?.data?.email
            || (this.props.isAtcPlan && this.props.userType === UserTypeEnum.ATC_ADMIN);
    }

    private handleYearChange = (e: any) => {
        this.setState({year: e.target.value});
    };

    private handleQuarterChange = (e: any) => {
        this.setState({quarter: e.target.value});
    };

    private getFilename = (): any => {
        let fileName: string | null = null;

        if (this.state.documentDataResult && this.state.documentDataResult!.data) {
            fileName = this.state.documentDataResult!.data!.fileName;
        }

        if (!fileName) {
            return;
        }

        return fileName;
    };

    private renderDocumentError() {
        if (this.state.documentError) {
            return <div id="upload-error">
                <div className="upload-error-logo"/>
                <div>{this.state.errorMessage}</div>
            </div>;
        }
    }

    private handleTitleChange = (e: ChangeEvent<HTMLInputElement | HTMLTextAreaElement | HTMLSelectElement>) => {
        this.setState({...this.state, title: e.target.value});
    };

    private handleDocumentTypeChange = (e: any) => {
        this.setState({selectedDocumentType: e.target.value, documentTypeError: false});
    };

    private submitForm = () => {
        if (this.state.selectedPlans.length === 0) {
            return;
        }

        const noDocumentType = this.state.selectedDocumentType == null;
        if (noDocumentType) {
            this.setState({...this.state, documentTypeError: true});
        }

        if (noDocumentType) {
            return;
        }

        const docType = this.props.documentTypes.data!
            .find((x) => x.id === this.state.selectedDocumentType) as SharedDocumentType;

        const sharedDocumentData = this.state.documentDataResult!.data!;

        const sharedDocument = new SharedDocument(
            sharedDocumentData.id,
            sharedDocumentData.fileName,
            this.state.title,
            getDateByQuarter(this.state.quarter, this.state.year),
            docType,
            sharedDocumentData.createdMillis,
            this.state.selectedPlans,
            sharedDocumentData.email,
            this.state.thirdPartyAccess
        );

        SharedDocumentApi.updateSharedDocument(sharedDocument)
            .then(() => {
                this.setState({documentError: false});
                this.props.actions.notifyFileUpdateSuccess();
                navigateTo("/shared-documents");
            }, () => {
                this.setState({documentError: true, errorMessage: "File save unsuccessful"});
            });
    };
}

export const mapStateToProps = (state: IApplicationRootState):
    ISharedDocumentsPropsFromState & IAdvancedEditFilePropsFromState => {
    return {
        ...state.sharedDocumentsRootState!,
        userType: userType(state),
        currentPlan: currentPlan(state),
        sessionPlans: sessionPlans(state),
        currentUserEmail: currentUserEmail(state),
        thirdPartyEnabledPlan: hasThirdPartyAccess(state),
        isAtcPlan: hasAonTrustCompany(state)
    };
};

export const mapDispatchToProps = (dispatch: Dispatch<Action<void>>): IAdvancedEditFilePropsFromActions => {
    return {
        actions: bindActionCreators({
            requestDocumentTypes: SharedDocumentsAction.requestDocumentTypes,
            notifyFileUpdateSuccess: SharedDocumentsAction.notifyFileUpdateSuccess,
        }, dispatch),
    };
};

export default connect<AdvancedEditFilePropsFromStateCombined,
    IAdvancedEditFilePropsFromActions,
    void>(mapStateToProps, mapDispatchToProps)(AdvancedEditFileComponent);
