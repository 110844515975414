import {removeSessionData, setUserLoggingOut} from "./sessionUtil";
import {SignoutOptions} from "@okta/okta-auth-js/types/lib/oidc/types/api";
import {AuthApi} from "../api/AuthApi";
import {getOktaAuth} from "./oktaAuthCreator";

export const triggerOktaLogin =  async () => {
    // console.error("=============> in triggerOktaLogin");
    const oktaAuth = getOktaAuth();
    removeSessionData();
    await oktaAuth.signInWithRedirect()
        .catch((error) => console.error("=============> oktaAuth.signInWithRedirect in error: ", error));
};

export const oktaSignOut = async (opts?: SignoutOptions) => {
    const oktaAuth = getOktaAuth();
    setUserLoggingOut();
    // eslint-disable-next-line @typescript-eslint/no-empty-function
    await AuthApi.logout().catch(() => {});
    // console.error("=============>before signed out");
    // await oktaAuth.revokeAccessToken();
    //     .catch((err) => console.error("=============> oktaAuth.revokeAccessToken error: ", err));
    await oktaAuth.signOut(opts)
        .catch((err) => console.error("=============> oktaAuth.signOut error: ", err));
    // console.error("=============> signed out");
    removeSessionData();
};
