import * as React from "react";
import {Map as ImmutableMap} from "immutable";
import {MeetingNoteSummary} from "../../../model/MeetingNoteSummary.model";
import {byDate} from "../../../utils/dateUtil";
import {MeetingNotesListing} from "./MeetingNotesListing";
import {getSuccessData} from "../../common/commonStates";
import {allMeetingNotesSelector} from "../../../../mainReducerMapSelectors";
import {connect} from "react-redux";
import {multipleApiRequesterWrapper} from "../../common/MultipleApiRequesterWrapper";
import {allMeetingsNotesRequestPair} from "../../common/RequesterPairs";
import {FormattedMessage} from "react-intl";
import {IApplicationRootState} from "../../../../applicationState";

export interface IProductMeetingNotesPropsFromParent {
    productBackstopId: number;
}

export interface IProductMeetingNotesPropsFromStore {
    allMeetingNotes: ImmutableMap<number, MeetingNoteSummary>;
}

export type IProductMeetingNotesProps = IProductMeetingNotesPropsFromParent & IProductMeetingNotesPropsFromStore;

export const ProductMeetingNotes: React.FunctionComponent<IProductMeetingNotesProps> = (props) => {
    const meetingNotesForProduct = props.allMeetingNotes.valueSeq().toArray().filter((meetingNote) =>
        meetingNote.productIds.includes(props.productBackstopId));

    function renderSeparator(index: number) {
        return index !== meetingNotesForProduct.length - 1
            ? <div key={`separator-${index}`} className="__separator">
                <div className="spacer-dash--small"/>
            </div>
            : null;
    }

    function renderMeetingNotes() {
        return meetingNotesForProduct.length > 0
            ? meetingNotesForProduct
                .sort(byDate)
                .map((meetingNote, index) => {
                    return <div key={index}>
                        <MeetingNotesListing meetingNote={meetingNote}
                                             productBackstopId={props.productBackstopId}
                                             key={meetingNote.backstopId} />
                        {renderSeparator(index)}
                    </div>;
                })
            : <div className="product__no-meeting-notes">
                <FormattedMessage
                    id="product.no-meeting-notes"
                    defaultMessage="There are no meeting notes at this time."
                />
        </div>;
    }

    return <div className="product__meeting-notes" data-testid="product__meeting-notes">
        {renderMeetingNotes()}
    </div>;
};

export const mapStateToProps = (state: IApplicationRootState): IProductMeetingNotesPropsFromStore => {
    return {
        allMeetingNotes: getSuccessData(allMeetingNotesSelector(state))!,
    };
};

const connectedComponent = connect<IProductMeetingNotesPropsFromStore>(mapStateToProps)(ProductMeetingNotes);

export default multipleApiRequesterWrapper(
    connectedComponent,
    [
        allMeetingsNotesRequestPair,
    ],
);