import {InsightsApi} from "../../api/InsightsApi";
import {DocumentType} from "../../utils/marketOutlookUtils";
import {IHomeContentBoxDownloadableDataItem, IHomeListItem} from "../IHomeListItem";

export interface IMarketOutlookDocument extends IHomeListItem {
    title: string;
    backstopId: number;
    date: Date;
    fileName: string;
    activityTag: string;
    type: DocumentType;
}

export class MarketOutlookDocument implements IMarketOutlookDocument {
    public static builder(): MarketOutlookDocumentBuilder {
        return new MarketOutlookDocumentBuilder();
    }

    constructor(
        public readonly title: string,
        public readonly backstopId: number,
        public readonly date: Date,
        public readonly fileName: string,
        public readonly activityTag: string,
        public readonly type: DocumentType
    ) {

    }

    public transform(): IHomeContentBoxDownloadableDataItem {
        return {
            title: this.title,
            date: this.date,
            backstopId: this.backstopId,
            url: "",
            type: this.type,
            download: () => InsightsApi.getDocumentDownload(this.backstopId, this.fileName, true),
        };
    }
}

class MarketOutlookDocumentBuilder {
    private mTitle: string;
    private mBackstopId: number;
    private mDate: Date;
    private mFileName: string;
    private mActivityTag: string;
    private mType: DocumentType;

    public title(title: string) {
        this.mTitle = title;
        return this;
    }

    public backstopId(value: number) {
        this.mBackstopId = value;
        return this;
    }

    public date(value: Date) {
        this.mDate = value;
        return this;
    }

    public fileName(value: string) {
        this.mFileName = value;
        return this;
    }

    public activityTag(value: string) {
        this.mActivityTag = value;
        return this;
    }

    public type(value: DocumentType) {
        this.mType = value;
        return this;
    }

    public build(): IMarketOutlookDocument {
        return new MarketOutlookDocument(
            this.mTitle || "title",
            this.mBackstopId || 0,
            this.mDate || new Date(0),
            this.mFileName || "fileName",
            this.mActivityTag || "activityTag",
            this.mType || DocumentType.QIO,
        );
    }
}
