import * as React from "react";
import {ComponentRating} from "../../../model/ComponentRating";
import {ProductRatingType} from "../../../model/product/ProductRatingType";
import {
    getEsgMovementIconClass,
    getEsgRatingIconClass,
    getEsgRatingText,
    getInformRatingTranslatedComments
} from "../../../utils/ratingFormatUtil";
import {ProductCard} from "../ProductCard";

export interface IEsgRatingDetailProps {
    title: string;
    componentRating: ComponentRating;
    ratingType: ProductRatingType;
}

export const EsgRatingDetail: React.FunctionComponent<IEsgRatingDetailProps> = (props) => {
    const renderESGCardContent = () => {
        return <div className="component-rating__comments-container">
            <div className="component-rating__comments" data-testid="component-rating__comments">
                {props.ratingType === ProductRatingType.INFORM
                    ? getInformRatingTranslatedComments(props.componentRating.rating, props.componentRating.comments)
                    : props.componentRating.comments}
            </div>
        </div>;
    };

    return <ProductCard
        name={props.title}
        rating={getEsgRatingText(props.componentRating.rating)}
        ratingClass={`${getEsgRatingIconClass(props.componentRating.rating)} 
                        ${getEsgMovementIconClass(props.componentRating.rating, props.componentRating.movement)}`}>
        {renderESGCardContent()}
    </ProductCard>;
};

export default EsgRatingDetail;