import {Action} from "redux";
import {ThunkAction, ThunkDispatch} from "redux-thunk";
import {IPlanInfo} from "../../../model/ClientsAndPlans.model";
import {INotificationObject} from "./HeaderReducer";

export interface IHeaderAction {
    type: HeaderActions;
    payload: boolean;
}

export enum HeaderActions {
    SET_MENU_VISIBILITY = "SET_MENU_VISIBILITY",
    SET_CURRENT_PLAN = "SET_CURRENT_PLAN",
    SET_CURRENT_NAVIGATION_TAB = "SET_CURRENT_NAVIGATION_TAB",
    SET_HEADER_NOTIFICATION = "SET_HEADER_NOTIFICATION",
    CLEAR_HEADER_NOTIFICATION = "CLEAR_HEADER_NOTIFICATION",
}

const setMenuVisibilityAction = (payload: boolean) => {
    return {
        type: HeaderActions.SET_MENU_VISIBILITY,
        payload,
    };
};

const setCurrentPlanAction = (payload: IPlanInfo) => {
    return {
        type: HeaderActions.SET_CURRENT_PLAN,
        payload,
    };
};

const setCurrentNavigationTabAction = (payload?: string) => {
    return {
        type: HeaderActions.SET_CURRENT_NAVIGATION_TAB,
        payload,
    };
};

export const setMenuVisibility = (visible: boolean): ThunkAction<void, {}, null, Action<string>> => {
    return (dispatch) => {
        dispatch(setMenuVisibilityAction(visible));
    };
};

export const setCurrentPlan = (planInfo: IPlanInfo): ThunkAction<void, {}, null, Action<string>> => {
    return (dispatch) => {
        dispatch(setCurrentPlanAction(planInfo));
    };
};

export const setCurrentNavigationTab = (tabName: string): ThunkAction<void, {}, null, Action<string>> => {
    return (dispatch) => {
        dispatch(setCurrentNavigationTabAction(tabName));
    };
};

export const clearCurrentNavigationTab = (): ThunkAction<void, {}, null, Action<string>> => {
    return (dispatch) => {
        dispatch(setCurrentNavigationTabAction(undefined));
    };
};

export const dispatchHeaderNotificationActions = (notificationObject: INotificationObject, milliseconds: number) =>
    (dispatch: ThunkDispatch<{}, null, Action<string>>) => {
    dispatch({
        type: HeaderActions.SET_HEADER_NOTIFICATION,
        payload: notificationObject,
    });

    if (milliseconds >= 0) {
        setTimeout(() => dispatch({
            type: HeaderActions.CLEAR_HEADER_NOTIFICATION,
        }), milliseconds);
    }
};

export const setHeaderNotification = (notificationObject: INotificationObject, milliseconds: number):
    ThunkAction<void, {}, null, Action<string>> => dispatchHeaderNotificationActions(notificationObject, milliseconds);

export default {
    setMenuVisibility,
    setCurrentPlan,
    setCurrentNavigationTab,
    clearCurrentNavigationTab,
    setHeaderNotification,
};
