import * as React from "react";
import {getQuartersSelectValues} from "../../utils/dateUtil";
import {SharedDocument} from "../../model/SharedDocument.model";
import {ALL_YEARS_ID, IPerformanceDocsFiltersProps} from "./PerformanceReportingTab";
import {ISelectValue, SelectComponent} from "../common/Select.component";
import {FiltersComponent} from "../common/Filters.component";
import {FormattedMessage} from "react-intl";
import {formattedMessageTransform} from "../../utils/translationUtil";
import {getSortingItems, SortOptions} from "../../utils/sharedDocsUtil";

interface ISupplementalReportsFiltersFromParent {
    documents: SharedDocument[];
    selectedOrder: SortOptions;
}

export type SupplementalReportsFiltersProps = IPerformanceDocsFiltersProps & ISupplementalReportsFiltersFromParent;

export const SupplementalReportsFilters: React.FunctionComponent<SupplementalReportsFiltersProps> = (props) => {
    function getYears(): ISelectValue[] {
        const documentYears = props.documents
            .map((it) => it.date.getFullYear())
            .filter((elem, index, self) => index === self.indexOf(elem))
            .sort((item1, item2) => item2 - item1)
            .map((year) => ({id: year, name: year.toString()}));

        return [{id: ALL_YEARS_ID, name: "All Years"}, ...documentYears];
    }

    const sortChange = (e: any) => {
        props.handleFilterChange(e, "sort");
    };

    return <div className="shared-documents__filter-and-sort-bar">
        <FiltersComponent
            filters={[
                {
                    values: getYears(),
                    selectedValue: props.selectedYear,
                    code: "by_year",
                    width: 125,
                },
                {
                    values: getQuartersSelectValues(),
                    selectedValue: props.selectedQuarter,
                    code: "by_quarter",
                    width: 150,
                },
            ]}
            onFilterChangeHandler={props.handleFilterChange}
            onClearFilters={props.resetFilters}
            />
        <div className="separator"/>
        <div className="shared-documents__sort">
            <div className="shared-documents__sort-dropDown-label">
                <FormattedMessage id="shared-docs.sort-by" defaultMessage="Sort by:" />
            </div>
            <div className="shared-documents-sort-right">
                <SelectComponent
                    values={getSortingItems()}
                    selected={props.selectedOrder}
                    width={275}
                    handleChange={sortChange}
                    menuItemTransform={formattedMessageTransform}
                    classNameSuffix={"shared-documents__sort"}
                    id={"shared-documents__sort-dropDown"}/>
            </div>
        </div>
    </div>;
};