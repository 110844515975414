import * as React from "react";
import {IInformSummary} from "../../model/inform/InformSummary";
import {useState} from "react";
import {InformSubcomponentsRatingChanges} from "./InformSubcomponentsRatingChanges";

export interface IInformRatingChangesProps {
    summary: IInformSummary;
}
export const InformRatingChanges: React.FunctionComponent<IInformRatingChangesProps> = (props) => {
    const [showRating, setShowRating] = useState(false);

    const renderViewMoreLink = () => {
        return showRating
            ? <a onClick={() => setShowRating(false)}>View less
                <div className="fa-regular fa-chevron-up"/>
            </a>
            : <a onClick={() => setShowRating(true)}>View more
                <div className="fa-regular fa-chevron-down"/>
            </a>;
    };

    const renderRatingChanges = () => {
        return <div className="inform-product__ratings-changes-container"
                    data-testid="inform-product__ratings-changes-container">
            <div className="inform-product__ratings-changes-overall">
                <div className="inform-product__ratings-changes-header">
                    <h3>Inform Ratings Changes</h3>
                </div>
                <div className="inform-product__ratings-changes-description">
                    <p>The number of changes in Aon InForm assessment ratings across all subcomponents</p>
                </div>
                <div className="inform-product__ratings-changes-factors">
                    <div className="inform-product__ratings-changes-factors-upgrades">
                        <div>
                        <span className="inform-product__ratings-changes-factors-upgrades-numbers"
                              data-testid="inform-product__ratings-changes-factors-upgrades-numbers">
                                {props.summary?.upgradeOfAonFactors
                                    ? props.summary.upgradeOfAonFactors
                                    : " - "
                                }
                            </span>
                            <i className={`fa fa-regular fa-arrow-up icon up icon-size`}
                               data-testid="inform-product__ratings-changes-factors-upgrades-arrow-up"/>
                        </div>
                        <p>Upgrades</p>
                    </div>
                    <div className="inform-product__ratings-changes-factors-downgrades">
                        <div>
                        <span className="inform-product__ratings-changes-factors-downgrades-numbers"
                              data-testid="inform-product__ratings-changes-factors-downgrades-numbers">
                                {props.summary?.downgradeOfAonFactors
                                    ? props.summary.downgradeOfAonFactors
                                    : " - "
                                }
                            </span>
                            <i className={`fa fa-regular fa-arrow-down icon down icon-size`}
                               data-testid="inform-product__ratings-changes-factors-upgrades-arrow-down"/>
                        </div>
                        <p>Downgrades</p>
                    </div>
                </div>
            </div>
            <div className="inform-product__ratings-changes-details">
                <div className="inform-product__ratings-changes-view-more"
                     data-testid="inform-product__ratings-changes-view-more">
                    {renderViewMoreLink()}
                </div>
                {showRating && <InformSubcomponentsRatingChanges summary={props.summary}/>}
            </div>
        </div>;
    };

    return renderRatingChanges();
};