import * as React from "react";
import {InsightsApi} from "../../api/InsightsApi";
import {WhitePaper} from "../../model/thought-leadership/WhitePaper.model";
import WhitePapers from "../white-paper/WhitePapers.component";

interface IWhitePaperState {
    whitePaperList: WhitePaper[];
}

export class WhitePapersPage extends React.Component<{}, IWhitePaperState> {
    public constructor(props: any) {
        super(props);
        this.state = {
            whitePaperList: [],
        };
    }

    public componentDidMount() {
        InsightsApi.requestWhitePapers().then((response) => {
            this.setState({whitePaperList: response.data!});
        });
    }

    public render() {
        return <div className={"main-content"} data-testid="white-papers-page">
            <WhitePapers whitePaperList={this.state.whitePaperList}/>
        </div>;
    }
}
