import {
    createTheme,
    FormControl,
    Input,
    ThemeProvider,
} from "@mui/material";
import * as React from "react";
import {AonColors} from "../../../../css/Colors";
import {FormattedMessage} from "react-intl";
import {styled} from "@mui/material/styles";

const PREFIX = 'StyledInputLabel';

const classes = {
    root: `${PREFIX}-root`
};

const Root = styled('span')({
    [`& .${classes.root}`]: {
        "&:after": {
            borderBottomColor: AonColors.AonTealDark,
        },
        disabled: {},
        error: {},
        focused: {},
        paddingBottom: 0,
        marginTop: 0,
    },
});

export const StyledInput = ((Input));

export interface ITextFieldComponentPropsFromParent {
    id?: string;
    name: string;
    className: string;
    type: string;
    fullWidth: boolean;
    value: string;
    handleChange: (e: any) => void;
    placeholder: string;
    renderRequired: boolean;
    submitClicked?: boolean;
    placeholderStyle?: object;
}

export const TextFieldComponent = (props: ITextFieldComponentPropsFromParent) => {

    let placeholderStyle = {
        color: `${AonColors.AonBlack}`,
        opacity: "0.4 !important",
    };
    placeholderStyle = props.placeholderStyle ? {...placeholderStyle, ...props.placeholderStyle} : placeholderStyle;

    const theme = createTheme({
        components: {
            MuiInputBase: {
                styleOverrides: {
                    input: {
                        padding: '4px 0 6px 0',
                        '&::placeholder': {
                            ...placeholderStyle
                        }
                    }
                },
            },
            MuiInput: {
                styleOverrides: {
                    underline: {
                        "&:hover:before": {
                            borderBottom: `2px solid ${AonColors.AonTealDark} !important`,
                        },
                        "&:after": {
                            borderBottomColor: AonColors.AonTealDark,
                        },
                    },
                    formControl: {
                        'marginTop': '16px'
                    }
                },
            },
        },
    });

    return (
        <FormControl data-testid={props.className}>
            <ThemeProvider theme={theme}>
                <StyledInput
                    id={props.id}
                    name={props.name}
                    className={props.className}
                    type={props.type}
                    fullWidth={props.fullWidth}
                    value={props.value}
                    onChange={props.handleChange}
                    placeholder={props.placeholder}
                />
            </ThemeProvider>
            {renderRequiredCaption(props.value, props.renderRequired, props.submitClicked)}
        </FormControl>
    );
};

const renderRequiredCaption = (text: string, renderRequired?: boolean, submitClicked?: boolean) => {
    const className = (submitClicked && text.length === 0)
        ? "common__required-caption-red"
        : "common__required-caption";

    return renderRequired
        ? <Root className={className}>
            <FormattedMessage id="select.required"
                              defaultMessage="required" />
            </Root>
        : null;
};
