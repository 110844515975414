export class Manager {
    public static builder(): ManagerBuilder {
        return new ManagerBuilder();
    }

    constructor(public managerBackstopId: number, public name: string) {
    }
}

export class ManagerBuilder {

    private mManagerBackstopId: number;
    private mName: string;

    public managerBackstopId(value: number) {
        this.mManagerBackstopId = value;
        return this;
    }

    public name(value: string) {
        this.mName = value;
        return this;
    }

    public build(): Manager {
        return new Manager(
            (this.mManagerBackstopId !== undefined) ? this.mManagerBackstopId : -1,
            this.mName ? this.mName : "manager name",
        );
    }
}
