import {CSSProperties} from "react";
import {AonColors, LegacyColors} from "../../../../css/Colors";

export const defaultButtonStyle: CSSProperties = {
    cursor: "pointer",
    height: 44,
    minWidth: 130,
    opacity: 1,
    backgroundColor: AonColors.AonTealDark,
    border: 0,
    outline: "none",
    borderRadius: "4px",
    color: AonColors.ActualWhite,
    padding: "6px 16px",
    fontFamily: "Helvetica Now Text, sans-serif",
    justifyContent: "center",
    fontWeight: 400,
    fontSize: "16px",
    lineHeight: "20px",
    textTransform: "none",
    transition: "background-color 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms,box-shadow 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms,border 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms",
};

export const disabledButtonStyle: CSSProperties = {
    pointerEvents: "none",
    cursor: "default",
    boxShadow: "none",
    border: 0,
    opacity: 0.5,
    backgroundColor: AonColors.AonTealDark,
};

export const prominentButtonStyle: CSSProperties = {
    ...defaultButtonStyle,
    color: AonColors.AonLightTextButton,
    backgroundColor: AonColors.AonTealDark,
    boxShadow: "rgba(0, 0, 0, 0.12) 0px 1px 6px, rgba(0, 0, 0, 0.12) 0px 1px 4px",
};

export const secondaryButtonStyle: CSSProperties = {
    ...defaultButtonStyle,
    backgroundColor: AonColors.ActualWhite,
    color: AonColors.AonNavy,
};

export const removeFromPortfolioButtonStyle: CSSProperties = {
    ...prominentButtonStyle,
    backgroundColor: AonColors.AonGray04,
    color: AonColors.AonNavy,
};

export const viewOddDetailsButtonStyle: CSSProperties = {
    ...defaultButtonStyle,
    backgroundColor: AonColors.AonTealDark,
};

export const uploadFileButtonStyle: CSSProperties = {
    ...prominentButtonStyle,
    marginLeft: "15px",
};

export const watchVideosButtonStyle: CSSProperties = {
    ...prominentButtonStyle,
    padding: "6px 12px",
    height: "44px",
};

export const addUserButtonStyle: CSSProperties = {
    ...prominentButtonStyle,
    marginTop: 27,
};

export const applyMenuButtonStyle: CSSProperties = {
    border: 0,
    minWidth: 10,
    borderRadius: "4px",
    boxShadow: "none",
    paddingTop: "4px",
    backgroundColor: "transparent",
};
