import {LOCATION_CHANGE} from "redux-first-history";
import {Action, handleActions} from "redux-actions";
import {AdminActionTypes} from "./editPortfolioActions";

export interface IPortfolioAdminState {
    editingPlanPortfolio: boolean;
}

export const initialState: IPortfolioAdminState = {
    editingPlanPortfolio: false,
};

const beginEditPlanPortfolio = (state: IPortfolioAdminState, {}: Action<{}>): IPortfolioAdminState => {
    return {...state, editingPlanPortfolio: true};
};

const endEditPlanPortfolio = (state: IPortfolioAdminState, {}: Action<{}>): IPortfolioAdminState => {
    return {...state, editingPlanPortfolio: false};
};

const handleLocationChange =
    (state: IPortfolioAdminState, locationChangeAction: Action<{ location: { pathname: string } }>):
        IPortfolioAdminState => {
        return shouldStayInEditMode(locationChangeAction) ? state : endEditPlanPortfolio(state, locationChangeAction);
    };

const reducerMap = {
    [AdminActionTypes.BEGIN_EDIT_PORTFOLIO]: beginEditPlanPortfolio,
    [LOCATION_CHANGE]: handleLocationChange,
    [AdminActionTypes.END_EDIT_PORTFOLIO]: endEditPlanPortfolio,
};

const shouldStayInEditMode = (locationChangeAction: Action<{ location: { pathname: string } }>): boolean => {

    return editRoutes.map(goingToPage).some((check) => check(locationChangeAction));
};

const editRoutes = [
    "/products",
    "/research",
    "/managers",
    "/strategy-updates",
    "/meeting-notes",
    "/flash-reports",
];

const goingToPage = (path: string) => (locationChangeAction: Action<{ location: { pathname: string } }>): boolean => {
    const pathname = locationChangeAction.payload ? locationChangeAction.payload.location.pathname : undefined;
    return (pathname !== undefined) && pathname.startsWith(path);
};

export default handleActions<IPortfolioAdminState, { location: { pathname: string } }>(reducerMap, initialState);

export function isEditingPlanPortfolioInner(state: IPortfolioAdminState | undefined): boolean {

    return state !== undefined && state.editingPlanPortfolio;
}
