import * as React from "react";
import {QuarterFilterItems} from "../../utils/dateUtil";
import {getDocumentType, MarketOutlook} from "../../utils/marketOutlookUtils";
import {FiltersComponent} from "../common/Filters.component";
import {createSelectValue} from "../common/Select.component";
import {getYears} from "./MarketOutlookUtils";
import {
    ALL_TYPES,
    ALL_YEARS,
    IMarketOutlookFilterSelections,
    MarketOutlookFilterByOptions,
} from "./MarketOutlook.page";

export interface IMarketOutlooksFilterPropsFromParent {
    documents: MarketOutlook[];
    handleFilterChange: (event: any, id: MarketOutlookFilterByOptions) => void;
    handleClearFilters: () => void;
    selections: IMarketOutlookFilterSelections;
}

export const MarketOutlooksFilter: React.FunctionComponent<IMarketOutlooksFilterPropsFromParent> = (props) => {
    function getYearsMenuItems() {
        const yearsItems = getYears(props.documents);

        return [ALL_YEARS, ...yearsItems].map(createSelectValue);
    }

    function getQuarterMenuItems() {
        return Object.values(QuarterFilterItems).map(createSelectValue);
    }

    function getDocumentTypes() {
        const types = props.documents
            .map((it) => getDocumentType(it))
            .filter((elem, index, self) => index === self.indexOf(elem))
            .sort((item1, item2) => item1.toLowerCase().localeCompare(item2.toLowerCase()));

        return [ALL_TYPES, ...types].map(createSelectValue);
    }

    return <div>
        <FiltersComponent
            filters={[
                {
                    values: getYearsMenuItems(),
                    selectedValue: props.selections.yearSelection,
                    code: "by_year",
                    width: 150,
                },
                {
                    values: getQuarterMenuItems(),
                    selectedValue: props.selections.quarterSelection,
                    code: "by_quarter",
                    width: 150,
                },
                {
                    values: getDocumentTypes(),
                    selectedValue: props.selections.typeSelection,
                    code: "by_type",
                    width: 250,
                },
            ]}
            onFilterChangeHandler={props.handleFilterChange}
            onClearFilters={props.handleClearFilters}/>
    </div>;

};
