import * as React from "react";
import {useEffect, useState} from "react";
import {PowerBiReportingApi} from "../../api/PowerBiReportingApi";
import {compareDates, formatShortDate} from "../../utils/dateUtil";
import {ClientReport} from "../../model/ClientReport.model";
import {DialogComponent} from "../common/Dialog.component";
import {FiltersComponent} from "../common/Filters.component";
import {ISelectValue} from "../common/Select.component";
import {compareInsensitive} from "../../utils/listUtil";

export enum SortByHeader {
    PLAN = "PLAN",
    REPORT_NAME = "REPORT NAME",
    REPORT_DATE = "REPORT DATE",
    REPORT_TYPE = "REPORT TYPE",
    WORKSPACE_ID = "WORKSPACE ID",
    REPORT_ID = "REPORT ID"
}

export const PowerBiAdminAllReports: React.FunctionComponent = () => {
    const allReportTypeOption: ISelectValue = {id: 0, name: "All Report Types"};
    const [reports, setReports] = useState<ClientReport[]>([]);
    const [reportToDelete, setReportToDelete] = useState<ClientReport | undefined>(undefined);
    const [selectedFilter, setSelectedFilter] = useState<ISelectValue>(allReportTypeOption);
    const [reportTypes, setReportTypes] = useState<ISelectValue[]>([{id: 0, name: "All Report Types"}]);
    const [sortKey, setSortKey] = useState({header: SortByHeader.REPORT_DATE, descending: true});
    const doSort = (primarySortKey: SortByHeader) => {
        const comparisonOrder = (a: any, b: any,
                                 primarySort: (a2: any, b2: any) => number) => {
            return sortKey.descending ? primarySort(a, b) : primarySort(b, a);
        };

        switch (primarySortKey) {
            case SortByHeader.PLAN:
                return reports.sort((rep1, rep2) =>
                    comparisonOrder(rep1.planName, rep2.planName, compareInsensitive)
                    || compareDates(rep1.date, rep2.date));
            case SortByHeader.REPORT_NAME:
                return reports.sort((rep1, rep2) =>
                    comparisonOrder(rep1.reportName, rep2.reportName, compareInsensitive)
                    || compareDates(rep1.date, rep2.date));
            case SortByHeader.WORKSPACE_ID:
                return reports.sort((rep1, rep2) =>
                    comparisonOrder(rep1.workspaceId, rep2.workspaceId, compareInsensitive)
                    || compareDates(rep1.date, rep2.date));
            case SortByHeader.REPORT_DATE:
                return reports.sort((rep1, rep2) =>
                    comparisonOrder(rep1.date, rep2.date, compareDates)
                    || compareInsensitive(rep1.planName, rep2.planName));
            case SortByHeader.REPORT_TYPE:
                return reports.sort((rep1, rep2) =>
                    comparisonOrder(rep1.categoryName, rep2.categoryName, compareInsensitive)
                    || compareDates(rep1.date, rep2.date));
            case SortByHeader.REPORT_ID:
                return reports.sort((rep1, rep2) =>
                    comparisonOrder(rep1.reportId, rep2.reportId, compareInsensitive)
                    || compareDates(rep1.date, rep2.date));
        }
    };

    const handleHeaderClick = (header: SortByHeader) => {
        if(header === sortKey.header) setSortKey({...sortKey, descending: !sortKey.descending});
        else setSortKey({header, descending: true});
    };

    const renderTitleAndArrow = (header: SortByHeader) => {
        let name = <div className="clickable header-name">{header}</div>;
        let arrow = null;

        if (header === sortKey.header) {
            name = <div className="clickable selected-header-name">{header}</div>;
            if (sortKey.descending) arrow = <div className="fal fa-arrow-down sort-arrow"/>;
            else arrow = <div className="fal fa-arrow-up sort-arrow"/>;
        }

        return <div onClick={() => handleHeaderClick(header)} className="clickable all-reports-table__headers-inner">
            {name} {arrow}
        </div>;
    };

    const getReports = () => {
        PowerBiReportingApi.requestAllClientReports()
            .then((response) => {
                setReports(response);
            });
    };

    const getCategories = () => {
        PowerBiReportingApi.requestClientReportCategories()
            .then((response) => {
                const dbReportTypes = response.map(it => {
                    return {id: it.id, name: it.name};
                });
                setReportTypes([allReportTypeOption, ...dbReportTypes]);
            });
    };

    useEffect(() => {
        getReports();
        getCategories();
    }, []);

    const deleteReport = () => {
        PowerBiReportingApi.deleteClientReport(reportToDelete!.id)
            .then(() => {
                setReportToDelete(undefined);
                getReports();
            });
    };

    const renderDeleteDialogue = () => {
        return <DialogComponent title={`Delete "${reportToDelete?.reportName}" from reports?`}
                               description="Users will no longer have access to this report"
                               noText="No, keep it"
                               yesText="Yes, delete it"
                               open={reportToDelete !== undefined}
                               onYes={deleteReport}
                               onNo={() => {setReportToDelete(undefined);}} />;
    };

    const renderReport = (report: ClientReport) => {
        return <tr key={report.id} className="admin__report-row">
            <td className="admin__report-plan">{report.planName}</td>
            <td className="admin__report-name">{report.reportName}</td>
            <td className="admin__report-date">{formatShortDate(report.date).toUpperCase()}</td>
            <td className="admin__report-type" data-testid="admin__report-type">{report.categoryName}</td>
            <td className="admin__workspace-id">{report.workspaceId}</td>
            <td className="admin__report-id">{report.reportId}</td>
            <td className="admin__report-delete" data-testid="admin__report-delete">
                <span className="clickable" onClick={() => {
                    setReportToDelete(report);
                }}>
                    Delete
                </span>
            </td>
        </tr>;
    };

    const renderReports = () => {
        const sortedReports = doSort(sortKey.header);
        if (selectedFilter.id !== allReportTypeOption.id) {
            return sortedReports.filter(report => report.categoryId === selectedFilter.id).map(renderReport);
        }
        return sortedReports.map(renderReport);
    };

    const renderFilter = () => {
        return <div className="powerbi-report-admin__filter">
            <FiltersComponent
                filters={[
                    {
                        values: reportTypes,
                        selectedValue: selectedFilter.id,
                        code: selectedFilter.name,
                        width: 180,
                    },
                ]}
                onFilterChangeHandler={(e: any) =>
                    setSelectedFilter(reportTypes.filter(it => it.id === e.target.value)[0])
                }
                onClearFilters={() => setSelectedFilter(allReportTypeOption)}
            />
        </div>;
    };

    return <div data-testid="powerbi-admin-all-reports-container">
        <div className="powerbi-report-admin__sub-header-and-filter-container">
            <h2 className="powerbi-report-admin__sub-header">All PowerBI Reports</h2>
            {renderFilter()}
        </div>

        <table className="powerbi-admin__all-reports-table">
            <thead>
                <tr>
                    <th>{renderTitleAndArrow(SortByHeader.PLAN)}</th>
                    <th>{renderTitleAndArrow(SortByHeader.REPORT_NAME)}</th>
                    <th>{renderTitleAndArrow(SortByHeader.REPORT_DATE)}</th>
                    <th>{renderTitleAndArrow(SortByHeader.REPORT_TYPE)}</th>
                    <th>{renderTitleAndArrow(SortByHeader.WORKSPACE_ID)}</th>
                    <th>{renderTitleAndArrow(SortByHeader.REPORT_ID)}</th>
                    <th>{}</th>
                </tr>
            </thead>
            <tbody>
                {renderReports()}
            </tbody>
        </table>
        {renderDeleteDialogue()}
    </div>;
};