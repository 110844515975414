import * as d3 from "d3";
import {Pie, PieArcDatum} from "d3";
import * as React from "react";
import {useEffect} from "react";
import {ODD_CHART_SUMMARY_COLOR_SCALE} from "../../../../../css/Colors";
import {generateColorScale} from "../../../utils/d3Util";
import {numberWithCommas} from "../../../utils/numberUtil";
import {addLegendRects, IDonutChartData, IDonutChartProperties, IDonutChartVariables} from "../OddIqChartingUtils";

export interface IDonutChartCommonProps {
    chartData: IDonutChartData[];
    prefix: string;
    totalLabel: string;
    title?: string;
    chartProperties?: IDonutChartProperties;
    innerChartDimensions?: number;
    padding?: {
        top: number;
        right: number;
        bottom: number;
        left: number;
    }
}

export const DonutChartCommon: React.FunctionComponent<IDonutChartCommonProps> = (props) => {
    const innerChart = props.innerChartDimensions
        ? { x: props.innerChartDimensions, y: props.innerChartDimensions }
        : { x: 262, y: 262 };

    const padding = props.padding
        ? props.padding
        : {
            top: 75,
            right: 100,
            bottom: 110,
            left: 100,
        };

    const chartDimensions = {
        y: innerChart.y + padding.top + padding.bottom,
        x: innerChart.x + padding.left + padding.right,
    };

    const chartMidPts = {
        y: innerChart.y * .5 + padding.top,
        x: innerChart.x * .5 + padding.left,
    };

    const donutChartProperties: IDonutChartProperties = props.chartProperties
        ? props.chartProperties
        : {
            innerRadius: 90,
            donutThickness: 32,
        };

    const CHART_MOUNT_ID = `${props.prefix}-donut-chart__chart-mount`;
    const CHART_CONTAINER_ID = `${props.prefix}-donut-chart__chart-container`;
    const CHART_ID = `${props.prefix}-donut-chart__chart`;
    const CHART_LEGEND_ID = `${props.prefix}-donut-chart__legend`;
    const CHART_TITLE_ID = `${props.prefix}-donut-chart__title`;

    useEffect(() => {
        const total = props.chartData.reduce((acc, d) => acc + d.counts!, 0);

        const keys = props.chartData.map((q) => q.key);

        const colorGenerator = generateColorScale(keys, ODD_CHART_SUMMARY_COLOR_SCALE);

        const chartRef = d3.select(`#${CHART_ID}`);
        const legendRef = d3.select(`#${CHART_LEGEND_ID}`);

        const arcBuilder = d3.arc<any, PieArcDatum<IDonutChartData>>()
            .outerRadius(donutChartProperties.innerRadius + donutChartProperties.donutThickness)
            .innerRadius(donutChartProperties.innerRadius);

        const pieGenerator: Pie<any, IDonutChartData> = d3.pie<IDonutChartData>()
            .sort(null)
            .value((d: IDonutChartData) => d.value);

        const slices: PieArcDatum<IDonutChartData>[] = pieGenerator(props.chartData);

        const chartVariables: IDonutChartVariables = {
            keys,
            colorGenerator,
            chartRef,
            legendRef,
            arcBuilder,
            pieGenerator,
            slices,
        };

        renderDataArcs(chartVariables);
        renderTotal(CHART_MOUNT_ID, total, props.totalLabel);
        slices.forEach((d) => renderLabel(d, chartVariables));
        renderLegend(chartVariables);
    }, []);

    function getLegendXoffset() {
        const keys = props.chartData.map((data) => data.key);

        return (chartDimensions.x - calculateOffset(keys, props.chartData.length)) / props.chartData.length;
    }

    function renderDataArcs(dc: IDonutChartVariables) {
        dc.chartRef
            .append("g")
            .selectAll("dataArcs")
            .data(dc.slices)
            .join("g")
            .attr("class", "odd-donut-chart__arc")
            .append("path")
            .attr("d", dc.arcBuilder)
            .attr("fill", (d) => dc.colorGenerator(d.data.key));
    }

    function renderTotal(chartMountId: string, totalQuestions: number, totalLabel: string) {
        const formattedTotal = numberWithCommas(totalQuestions);

        const totalHeight = 64;

        const translateX = props.chartProperties ? donutChartProperties.innerRadius / props.chartData.length : 0;
        const translateY = -(((innerChart.y + padding.top + padding.bottom) / 2) + (totalHeight / 2));

        const centerDiv = d3.select(`#${chartMountId}`)
            .append("div")
            .attr("class", "odd-donut-chart__total")
            .style("top", `${translateY}px`)
            .style("left", `${translateX}px`);

        centerDiv.append("div")
            .attr("class", "donut-number-large")
            .text(formattedTotal);

        centerDiv.append("div")
            .append("p")
            .attr("class", "odd-donut-chart__text small bold")
            .html(totalLabel);
    }

    function renderLabel(slice: PieArcDatum<IDonutChartData>, dc: IDonutChartVariables ) {
        const labelText1 = `${slice.data.value}%`;

        const labelText2 = `(${slice.data.counts})`;

        const pos = dc.arcBuilder.centroid(slice).map((it) => it * 1.3);
        const midpointAngle = slice.startAngle + (slice.endAngle - slice.startAngle) / 2;

        let xOffset = 0;
        if (midpointAngle > Math.PI) {
            xOffset = -((labelText1 + labelText2).length * 9 + 10);
        }

        const labelGroup = dc.chartRef
            .append("g");

        labelGroup
            .append("text")
            .attr("class", "odd-donut-chart__data-label1 bold data")
            .attr("x", pos[0])
            .attr("y", pos[1])
            .attr("dx", xOffset || 0)
            .text(labelText1);

        labelGroup
            .append("text")
            .attr("class", "odd-donut-chart__data-label2 data")
            .attr("x", pos[0])
            .attr("y", pos[1])
            .attr("dx", xOffset + (labelText1).length * 9 + 10 || 0)
            .text(labelText2);
    }

    function renderLegend(dc: IDonutChartVariables) {
        const legends = dc.legendRef!
            .selectAll("g")
            .data(dc.keys)
            .join("g")
            .attr("transform", (d, i) => {
                const offset = calculateOffset(dc.keys, i);
                return `translate(${offset}, 0)`;
            })
            .attr("class", "odd-donut-chart__legend-item body-14");

        addLegendRects(legends, -16, dc.colorGenerator);
        legends.append("text")
            .attr("x", 10)
            .attr("y", 9.5)
            .attr("dy", "0.35em")
            .text((key) => key);
    }

    function calculateOffset(keys: string[], i: number): number {
        const multiplier = 6;
        const rectOffset = 60;

        if (i > 0) {
            return (keys[i - 1].length * multiplier + rectOffset) + calculateOffset(keys, i - 1);
        }
        return chartDimensions.x / 5;
    }

    function renderTitle() {
        return props.title
            ? <span className="dashboard-title-small">{props.title}</span>
            : null;
    }

    return <div className="donut-chart__outer">
        {renderTitle()}
        <div id={CHART_MOUNT_ID} className="donut-chart__chart-mount">
            <svg width={chartDimensions.x}
                 height={chartDimensions.y}
                 id={CHART_CONTAINER_ID} className="donut-chart__container">
                <g id={CHART_ID}
                   transform={`translate(${chartMidPts.x}, ${(chartMidPts.y)})`} />
                <g id={CHART_LEGEND_ID}
                   transform={`translate(${getLegendXoffset()}, ${chartDimensions.y - padding.bottom / 2 })`}/>
                <g id={CHART_TITLE_ID}
                   transform={`translate(20,40)`}/>
            </svg>
        </div>
    </div>;
};
