import * as React from "react";
import {ReactElement, useEffect} from "react";
import {useDispatch, useSelector} from "react-redux";
import {Dispatch} from "redux";
import {Action} from "redux-actions";
import {assertNever} from "../../utils/errorUtil";
import {ErrorComponent} from "../base/Error.component";
import {LoadingSpinner} from "../icons/LoadingSpinner.component";
import {
    ALL_REQUESTED,
    ALL_SUCCESSFUL,
    COMBINED_REQUEST_STATES,
    combinedStatesTypes,
    NOT_SUCCESSFUL,
    SOME_NOT_REQUESTED,
} from "./combinedCommonStates";
import {REQUEST_STATES, RequestState} from "./commonStates";
import {IApplicationRootState} from "../../../applicationState";

export type TStateSelector = (state: IApplicationRootState) => RequestState<any>;
export type TDispatchAction = (dispatch: Dispatch<Action<any>>) => Promise<any> | void;

export type TThunkAction = () => (dispatch: Dispatch) => void;

export interface IMultiApiRequesterPropsFromParent {
    apiActions: TThunkAction;
    requestStateSelectors: TStateSelector[];
    loadingComponent?: React.Component | React.ReactNode;
}

export interface IMultiApiRequesterChildren {
    children: ReactElement;
}

export interface IMultiApiRequesterValuesFromStore {
    requestStates: RequestState<any>[];
}

export type MultiApiRequesterProps = IMultiApiRequesterPropsFromParent & IMultiApiRequesterChildren;

export const MultiApiRequester: React.FunctionComponent<MultiApiRequesterProps> = (props: MultiApiRequesterProps) => {

    const valuesFromStore: IMultiApiRequesterValuesFromStore = useSelector((state: IApplicationRootState) => ({
        requestStates: props.requestStateSelectors.map((selector) => selector(state)),
    }));

    const dispatch = useDispatch();
    useEffect(() => {

        const combinedRequestState = getRequestState(valuesFromStore.requestStates);
        switch (combinedRequestState.kind) {
            case COMBINED_REQUEST_STATES.SOME_NOT_REQUESTED:
                props.apiActions()(dispatch);
                break;
            case COMBINED_REQUEST_STATES.ALL_REQUESTED:
                break;
            case COMBINED_REQUEST_STATES.NOT_SUCCESSFUL:
                break;
            case COMBINED_REQUEST_STATES.ALL_SUCCESSFUL:
                break;
            default: assertNever(combinedRequestState);
        }
    }, []);

    useEffect(() => {
        const combinedRequestState = getRequestState(valuesFromStore.requestStates);
        switch (combinedRequestState.kind) {
            case COMBINED_REQUEST_STATES.SOME_NOT_REQUESTED:
                props.apiActions()(dispatch);
                break;
            case COMBINED_REQUEST_STATES.ALL_REQUESTED:
                break;
            case COMBINED_REQUEST_STATES.NOT_SUCCESSFUL:
                break;
            case COMBINED_REQUEST_STATES.ALL_SUCCESSFUL:
                break;
            default: assertNever(combinedRequestState);
        }
    }, [props, valuesFromStore.requestStates]);


    const loadingState: any = props.loadingComponent !== undefined
        ? props.loadingComponent : <LoadingSpinner/>;
    const combinedRequestState = getRequestState(valuesFromStore.requestStates);

    switch (combinedRequestState.kind) {
        case COMBINED_REQUEST_STATES.SOME_NOT_REQUESTED:
            return loadingState;
        case COMBINED_REQUEST_STATES.ALL_REQUESTED:
            return loadingState;
        case COMBINED_REQUEST_STATES.NOT_SUCCESSFUL:
            return <ErrorComponent/>;
        case COMBINED_REQUEST_STATES.ALL_SUCCESSFUL:
            return renderChildren();
        default: return assertNever(combinedRequestState); // error here if there are missing cases
    }

    function getRequestState(requestStateArray: RequestState<any>[]): combinedStatesTypes {

        if (requestStateArray.some((requestState) => requestState.kind === REQUEST_STATES.NOT_REQUESTED)) {
            return SOME_NOT_REQUESTED;
        } else if (allAreOfType(requestStateArray, REQUEST_STATES.REQUEST_SUCCEEDED)) {
            return ALL_SUCCESSFUL;
        } else if (requestStateArray.some((requestState) => requestState.kind === REQUEST_STATES.REQUESTED)) {
            return ALL_REQUESTED;
        }
        return NOT_SUCCESSFUL;
    }

    function renderChildren() {
        const child = React.Children.only(props.children);
        return React.cloneElement(child as ReactElement<any>, {requeststates: valuesFromStore.requestStates});
    }

    function allAreOfType(requestStateArray: RequestState<any>[], requestState: REQUEST_STATES): boolean {
        return requestStateArray.reduce((acc, arr) => {
            if (arr.kind === requestState) {
                return acc + 1;
            }
            return acc;
        }, 0) === requestStateArray.length;
    }
};


export interface IRequesterApiPair {
    selector: TStateSelector;
    apiRequest: TDispatchAction;
}


