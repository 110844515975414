import * as React from "react";
import {useEffect, useState} from "react";
import {IAssets, CprPortfolioApi} from "../../api/CprPortfolioApi";
import {useDispatch} from "react-redux";
import {formatShortDate, parseShortDate, reactDatePickerShortDateFormat} from "../../utils/dateUtil";
import {setHeaderNotification} from "../base/header/HeaderActions";
import {NotificationTypes} from "../base/header/HeaderReducer";
import {scrollToElement} from "../../utils/browserUtil";
import DatePicker from "react-datepicker";
import {RaisedButton} from "../common/RaisedButton";
import {prominentButtonStyle, secondaryButtonStyle} from "../common/buttonStyles";
import {Tooltip} from "@mui/material";
import {hasConsultantLevelAccess} from "../../utils/sessionUtil";
import {FormattedMessage} from "react-intl";

export interface IAssetsProps {
    assets: IAssets | undefined;
    fetchAssets: () => void;
    isPostQuarterUpdate: boolean;
}

export const CprAssets: React.FunctionComponent<IAssetsProps> = (props: IAssetsProps) => {
    const [editAssets, setEditAssets] = useState<boolean>(false);
    const [assetsAsOf, setAssetsAsOf] = useState<Date | undefined>(undefined);
    const [assetsValue, setAssetsValue] = useState<string>("");
    const [assetsSaveClicked, setAssetsSaveClicked] = useState<boolean>(false);

    const dispatch = useDispatch();

    useEffect(() => {
        resetEditValues();
    }, [props]);


    function resetEditValues(){
        if (props.assets) {
            setAssetsValue(props.assets.assetsValue );
            setAssetsAsOf(parseShortDate(props.assets.assetsAsOf));
        } else {
            setAssetsValue("");
            setAssetsAsOf(undefined);
        }
    }

    const handleAssetsCancel = () => {
        setEditAssets(false);
        setAssetsSaveClicked(false);
        resetEditValues();
    };

    const handleAssetsSave = () => {
        setAssetsSaveClicked(true);
        if(!assetsAsOf || !assetsValue || assetsValue.trim() === ""){
            return;
        }
        const assets: IAssets = {
            assetsAsOf: formatShortDate(assetsAsOf!),
            assetsValue: assetsValue!
        };
        const processSuccess = () => {
            setEditAssets(false);
            setAssetsSaveClicked(false);
            props.fetchAssets();
        };
        const processFailure = () => {
            dispatch(setHeaderNotification(
                {message: 'Failed to save assets',
                    notificationType: NotificationTypes.FAILURE},
                5000));
            scrollToElement(".main__header", 0);
        };

        CprPortfolioApi.saveAssets(assets)
            .then(() => processSuccess())
            .catch(() => processFailure());
    };

    const renderRequireForAssetsDate = () => {
        return assetsSaveClicked
            ? <span id="assets-date-required" className={assetsAsOf ? "require_success" : "require_fail"}>
                required
              </span>
            : null;
    };

    const renderDateInput = () => {
        return <div className="assets__edit-date">
            <DatePicker
                className="assets__date-picker"
                placeholderText="Assets as of"
                dateFormat={reactDatePickerShortDateFormat}
                dropdownMode="select"
                selected={assetsAsOf}
                onChange={(date: Date) => {
                    setAssetsAsOf(date);
                }}
            />
            {renderRequireForAssetsDate()}
        </div>;
    };

    const renderRequireForAssetsValue = () => {
        const className = assetsValue
            ?  assetsValue.trim() === "" ? "require_fail" : "require_success"
            : "require_fail";
        return assetsSaveClicked
            ? <span id="assets-value-required" className={className}>
                required
              </span>
            : null;
    };

    const renderAssetsContentInEditMode = () => {
        return <div className="assets__edit">
            <div> {renderDateInput()} </div>
            <div className="assets__edit-value">
                <input
                    type="text"
                    className="assets__value-input"
                    placeholder="Asset Value"
                    onChange={(e) => {
                        setAssetsValue(e.target.value);
                    }}
                    value={assetsValue}
                    data-testid="assets__value-input"
                />
                {renderRequireForAssetsValue()}
            </div>
        </div>;
    };

    const renderAssetsContentInViewMode = () => {
        return <div className="assets__view-content">
            <div className="assets__view-value center"
                 data-testid="assets__view-value">{props.assets?.assetsValue}</div>
            {props.isPostQuarterUpdate && <div className="assets__view-basis" data-testid="assets__view-basis">As
                of {props.assets?.assetsAsOf}</div>}
        </div>;
    };

    const renderAssetsContent = () => {
        return editAssets ? renderAssetsContentInEditMode() : renderAssetsContentInViewMode();
    };

    const renderButtons = () => {
        return (
            editAssets
                ?
                <div className="assets__buttons">
                    <RaisedButton className="assets__cancel-button"
                                  style={secondaryButtonStyle}
                                  primary={false}
                                  onClick={handleAssetsCancel}>
                        Cancel
                    </RaisedButton>
                    <RaisedButton className="assets__save-button"
                                  style={prominentButtonStyle}
                                  primary={true}
                                  onClick={handleAssetsSave}>
                        Save
                    </RaisedButton>
                </div>
                : null
        );
    };

    const renderToolTip = () => {
        return hasConsultantLevelAccess() && !editAssets && props.isPostQuarterUpdate
            ? <Tooltip title="" onClick= { () => {setEditAssets(true);} }>
                <p className="assets__edit-pencil fas fa-pencil edit-pencil clickable"/>
              </Tooltip>
            : null;
        };

    return <div className="assets__container" data-testid="assets__container">
        <div className="assets__header">
            <div className="assets__title">
                <h3><FormattedMessage id="home.assets-title" defaultMessage="Assets"/></h3>
            </div>
            {renderToolTip()}
            {renderButtons()}
        </div>
        {renderAssetsContent()}
    </div>;

};