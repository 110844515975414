import * as React from "react";
import {hasConsultantLevelAccess} from "../../utils/sessionUtil";
import {byDate, formatShortDate} from "../../utils/dateUtil";
import {CheckBoxComponent} from "../common/CheckBox.component";
import {PowerBiReportingApi} from "../../api/PowerBiReportingApi";
import {useState} from "react";
import {DialogComponent} from "../common/Dialog.component";
import {useDispatch} from "react-redux";
import {clearClientReports, setCurrentCategory} from "./PowerBiReporting.actions";
import { ClientReport } from "src/main/model/ClientReport.model";
import {requestPlanSettings} from "../root/rootActions";

export interface IAllClientReportsProps {
    showOverlay: (show: boolean) => void;
    reports: ClientReport[];
    setCurrentReport: (report: ClientReport) => void;
}

export const AllClientReports: React.FunctionComponent<IAllClientReportsProps> = (props) => {
    const [dialogOpen, setDialogOpen] = useState<boolean>(false);
    const [reportToDelete, setReportToDelete] = useState<ClientReport | undefined>(undefined);

    const dispatch = useDispatch();

    function setReportClientEnabled(report: ClientReport) {
        PowerBiReportingApi.setReportClientViewable(report.id, !report.clientEnabled)
            .then(() => {
                dispatch(clearClientReports());
                dispatch(requestPlanSettings());
            });
    }

    function renderClientViewableCheckbox(report: ClientReport) {
        return hasConsultantLevelAccess()
            ? <td className="all-client-reports__client-viewable">
                <CheckBoxComponent
                    name="all-client-reports__client-viewable"
                    checked={report.clientEnabled}
                    onChange={() => setReportClientEnabled(report)}
                    label=""/>
            </td>
            : null;
    }

    function renderDeleteLink(report: ClientReport) {
        return hasConsultantLevelAccess()
            ? <td>
                <a className="old-anchor all-client-reports__delete-link"
                   onClick={() => {
                       setDialogOpen(true);
                       setReportToDelete(report);
                   }}>
                    Delete
                </a>
            </td>
            : null;
    }

    function renderClientViewableHeader() {
        return hasConsultantLevelAccess()
            ? <th className="small bold all-client-reports__client-viewable">Client Viewable</th>
            : null;
    }

    function renderDeleteHeader() {
        return hasConsultantLevelAccess()
            ? <th className="all-client-reports__delete-header"/>
            : null;
    }

    function renderReports() {
        return props.reports.sort(byDate).map((report) => {
            return <tr key={report.reportName}>
                <td className="all-client-reports__report-name"
                    data-testid="all-client-reports__report-name"
                    onClick={() => props.setCurrentReport(report)}>
                    <a className="old-anchor">{report.reportName}</a>
                </td>
                <td className="all-client-reports__report-date data">{formatShortDate(report.date).toUpperCase()}</td>
                {renderClientViewableCheckbox(report)}
                {renderDeleteLink(report)}
            </tr>;
        });
    }

    function deleteReport() {
        setDialogOpen(false);
        PowerBiReportingApi.deleteClientReport(reportToDelete!.id)
            .then(() => {
                setReportToDelete(undefined);
                dispatch(clearClientReports());
                dispatch(requestPlanSettings());
                if (props.reports.length - 1 === 0) {
                    dispatch(setCurrentCategory(""));
                }
            });
    }

    function renderDeleteDialog() {
        return hasConsultantLevelAccess()
            ? <DialogComponent title="Delete report from this plan?"
                               description="Users will no longer have access to this report"
                               noText="No, keep it"
                               yesText="Yes, delete it"
                               open={dialogOpen}
                               onYes={deleteReport}
                               onNo={() => {
                                   setDialogOpen(false);
                                   setReportToDelete(undefined);
                               }} />
           : null;
    }

    function getWidthClass() {
        return hasConsultantLevelAccess()
            ? "for-consultants"
            : "for-clients";
    }

    return <>
            <div className={`all-client-reports__container-container ${getWidthClass()}`}>
                <div className={`all-client-reports__container ${getWidthClass()}`}>
                <div id="all-client-reports__close-button"
                     className="fal fa-times clickable"
                     onClick={() => props.showOverlay(false)}
                />
                <table className="all-client-reports__table">
                    <thead>
                    <tr>
                        <th className="small bold all-client-reports__report-names">Historic Reports</th>
                        <th className="small bold all-client-reports__date">Date</th>
                        {renderClientViewableHeader()}
                        {renderDeleteHeader()}
                    </tr>
                    </thead>
                    <tbody>
                    {renderReports()}
                    </tbody>
                </table>
            </div>
        </div>
        {renderDeleteDialog()}
    </>;
};
