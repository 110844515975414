import {Action, AnyAction, Dispatch, Middleware, MiddlewareAPI} from "redux";
import {Action as GenericAction} from "redux-actions";
import {IPlanInfo} from "../../../model/ClientsAndPlans.model";
import {UserDetails} from "../../../model/UserDetails.model";
import {getSessionData, storeSessionData} from "../../../utils/sessionUtil";
import {SessionActions} from "../SessionActions";
import {emptySessionData, SessionData} from "../SessionData.model";
import {currentPlanInNewPlans, selectCurrentPlan} from "../SessionReducer";
import {UserSessionPersistenceActions} from "./userSessionPersistenceActions";

function handlePersistUserDetails(action: Action<string>) {
    const oldSessionData = getSessionData() || emptySessionData;
    const userDetails = (action as GenericAction<UserDetails>).payload;
    const {userType, firstName} = userDetails!;

    storeSessionData({
        ...oldSessionData,
        userType, firstName,
    });
}

function handlePersistToken() {
    const oldSessionData = getSessionData() || emptySessionData;

    storeSessionData({
        ...oldSessionData,
    });
}

function handleUnpersistCurrentPlan() {
    const oldSessionData = getSessionData();
    if (oldSessionData) {
        storeSessionData({
            ...oldSessionData,
            currentPlan: undefined,
        });
    }
}

function handleSetPlanList(action: Action<string>, api: MiddlewareAPI<Dispatch<AnyAction>>) {
    const payload = (action as GenericAction<IPlanInfo[]>).payload;
    if (!payload) {
        return;
    }

    if (!currentPlanInNewPlans(payload, api.getState().session!)) {
        const oldSessionData: SessionData | null = getSessionData();
        storeSessionData({
            ...oldSessionData!,
            currentPlan: selectCurrentPlan(payload),
        });
    }
}

export const userSessionPersistence: Middleware =
    (api: MiddlewareAPI<Dispatch<AnyAction>>) =>
        (next: Dispatch<Action<void>>) =>
            <A extends Action>(action: A) => {
                switch (action.type) {
                    case (UserSessionPersistenceActions.PERSIST_USER_DETAILS):
                        handlePersistUserDetails(action);
                        break;

                    case (UserSessionPersistenceActions.PERSIST_TOKEN):
                        handlePersistToken();
                        break;

                    case (UserSessionPersistenceActions.UNPERSIST_CURRENT_PLAN):
                        handleUnpersistCurrentPlan();
                        break;

                    case (SessionActions.SET_PLAN_LIST):
                        handleSetPlanList(action, api);
                        break;
                }

                return next(action);
            };
