import {Action, handleActions} from "redux-actions";
import {IHolding} from "../../../api/HoldingsApi";
import {NOT_REQUESTED, REQUEST_FAILED, REQUEST_SUCCEEDED, REQUESTED, RequestState} from "../../common/commonStates";
import {HoldingsActions} from "./HoldingsActions";

export interface IHoldingsState {
    holdingsByPlan: RequestState<IHolding[]>;
    allHoldings: RequestState<IHolding[]>
}

export const initialState: IHoldingsState = {
    holdingsByPlan: NOT_REQUESTED,
    allHoldings: NOT_REQUESTED,
};

const reducerMap = {
    [HoldingsActions.HOLDINGS_REQUESTED]:
        (state: IHoldingsState): IHoldingsState => {
            return {...state, holdingsByPlan: REQUESTED};
        },
    [HoldingsActions.HOLDINGS_RECEIVED]:
        (state: IHoldingsState, action: Action<IHolding[]>): IHoldingsState => {
            return {...state, holdingsByPlan: REQUEST_SUCCEEDED(action.payload!)};
        },
    [HoldingsActions.GET_HOLDINGS_REQUEST_FAILED]:
        (state: IHoldingsState, action: Action<any>): IHoldingsState => {
            return {...state, holdingsByPlan: REQUEST_FAILED(action.payload!)};
        },
    [HoldingsActions.ALL_HOLDINGS_REQUESTED]:
        (state: IHoldingsState): IHoldingsState => {
            return {...state, allHoldings: REQUESTED};
        },
    [HoldingsActions.ALL_HOLDINGS_RECEIVED]:
        (state: IHoldingsState, action: Action<IHolding[]>): IHoldingsState => {
            return {...state, allHoldings: REQUEST_SUCCEEDED(action.payload!)};
        },
    [HoldingsActions.ALL_GET_HOLDINGS_REQUEST_FAILED]:
        (state: IHoldingsState, action: Action<any>): IHoldingsState => {
            return {...state, allHoldings: REQUEST_FAILED(action.payload!)};
        },
};

export const holdingsByPlanSelectorInner = (state?: IHoldingsState): RequestState<IHolding[]> => {
    return state ? state.holdingsByPlan : NOT_REQUESTED;
};

export const allHoldingsSelectorInner = (state?: IHoldingsState): RequestState<IHolding[]> => {
    return state ? state.allHoldings : NOT_REQUESTED;
};

export default handleActions<IHoldingsState, IHolding[]>(reducerMap, initialState);
