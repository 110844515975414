import * as React from 'react';
import {FormattedMessage} from "react-intl";


export const DefaultDisclaimer: React.FunctionComponent = () => {
    return (
        <div className="disclaimer__content main-content" data-testid="disclaimer__content-non-ATC">
            <h1 id="disclaimer-title">
                <FormattedMessage id="disclaimer.header" defaultMessage="Aon Legal Notice"/>
            </h1>
            <div>
                <h4 className="disclaimer-subheader">
                    <FormattedMessage id="disclaimer.terms-of-use" defaultMessage="Terms of Use"/>
                </h4>
                <p>
                    <FormattedMessage id="disclaimer.terms-of-use-p1" defaultMessage={
                        "The terms of use provided below apply to the Aon Iris online website (“Website”) and  " +
                        "to all products, services, resources, information, materials and other content available " +
                        "on the Website."
                    }/>
                </p>
                <p>
                    <FormattedMessage id="disclaimer.terms-of-use-p2" defaultMessage={
                        "By accessing and using this Website you acknowledge that you have read, understood, and " +
                        "agree to accept and be bound by the terms of the Legal Notices set out below. We may modify " +
                        "these Terms at any time without prior notice, by posting any changes on this Website. Your " +
                        "continued access or use of the services provided by this Website will mean you accept the " +
                        "terms of the Legal Notices including any changes made to them. If you do not accept any of " +
                        "the terms below, please discontinue use of this Website and any of its associated pages. " +
                        "This Website and its terms are governed by the laws applicable in the Province of Ontario, " +
                        "Canada. All disputes arising from these terms must be resolved exclusively by the courts of the " +
                        "Province of Ontario."
                    }/>
                </p>
                <h4 className="disclaimer-subheader">
                    <FormattedMessage id="disclaimer.intellectual-property" defaultMessage="Intellectual Property" />
                </h4>
                <p>
                    <FormattedMessage id="disclaimer.intellectual-property-p1" defaultMessage={
                        "The content contained on this Website, including but not limited to reports, charts, articles, " +
                        "other text, photographs, images, illustrations, graphics, video material, audio material, " +
                        "software, Aon logos, titles, characters, names, graphics and button icons " +
                        "(collectively \"Intellectual Property\"), are protected by copyright, trademark, and other laws " +
                        "of the Province of Ontario, Canada, as well as international conventions and the laws of other " +
                        "countries. The Website Intellectual Property is owned or controlled by Aon plc or one of its " +
                        "subsidiaries (\"Aon\") or by third parties that have provided material to the Website. Except " +
                        "as otherwise expressly stated herein or to the extent permitted by law, users of this Website " +
                        "may not reproduce, license, publish, enter into a database, display, modify, create derivative " +
                        "works from, transmit, post, distribute, or perform publicly by any means, method, or process " +
                        "now known or later developed, decompile, reverse engineer, disassemble, transfer, or sell any " +
                        "Intellectual Property, information, software, or products obtained from or through the Website, " +
                        "in whole or in part, without the express written permission of Aon. Users of this site may be " +
                        "permitted to download certain material posted on this site (e.g. white papers). When using " +
                        "downloadable materials, all copyright, trade mark, and other proprietary notices must be kept " +
                        "intact and remain in effect."
                    }/>
                </p>
                <p>
                    <FormattedMessage id="disclaimer.intellectual-property-p2" defaultMessage={
                        "Other trademarks, service marks, product names, and company names or logos appearing on the " +
                        "Website that are not owned by Aon may not be used without express permission from their owners."
                    }/>
                </p>
                <h4 className="disclaimer-subheader">
                    <FormattedMessage id="disclaimer.investment-related-content" defaultMessage="Investment Related Content" />
                </h4>
                <p>
                    <FormattedMessage id="disclaimer.investment-related-content-p1" defaultMessage={
                        "This website is made available to Aon’s institutional advisory clients. Nothing accessible " +
                        "through the client portal website should be treated as an authoritative statement of the law " +
                        "on any particular aspect or in any specific case. The information contained on this website is " +
                        "given as of the date indicated. The information contained herein is derived from proprietary " +
                        "and non-proprietary sources deemed by Aon to be reliable and are not necessarily all " +
                        "inclusive. Aon does not guarantee the accuracy or completeness of information made available " +
                        "on this website and cannot be held accountable for inaccurate data provided by third parties. " +
                        "Reliance upon information made available on this website is at the sole discretion of the user."
                    }/>
                </p>
                <p>
                    <FormattedMessage id="disclaimer.investment-related-content-p2" defaultMessage={
                        "Investment information provided here does not constitute an offer of securities or " +
                        "solicitation of any kind and may not be treated as such, i) in any jurisdiction where such " +
                        "an offer or solicitation is against the law; ii) to anyone to whom it is unlawful to make " +
                        "such an offer or solicitation; or iii) if the person making the offer or solicitation is " +
                        "not qualified to do so. Users should seek professional advice from a financial adviser " +
                        "registered in their local jurisdiction."
                    }/>
                </p>
                <p>
                    <FormattedMessage id="disclaimer.investment-related-content-p3" defaultMessage={
                        "We have not considered the suitability and/or appropriateness of any investment you may wish " +
                        "to make with us. It is your responsibility to be aware of and to observe all applicable laws " +
                        "and regulations of any relevant jurisdiction, including the one in which you reside."
                    }/>
                </p>
                <p>
                    <FormattedMessage id="disclaimer.investment-related-content-p4" defaultMessage={
                        "In the UK, Aon Solutions UK Limited is authorized and regulated by the Financial Conduct Authority. " +
                        "Registered in England & Wales No. 4396810."
                    }/>
                </p>
                <p>
                    <FormattedMessage id="disclaimer.investment-related-content-p5" defaultMessage={
                        "In the US, Aon Investments USA Inc. is a registered investment adviser " +
                        "with the Securities and Exchange Commission (“SEC”)."
                    }/>
                </p>
                <p>
                    <FormattedMessage id="disclaimer.investment-related-content-p6" defaultMessage={
                        "In Canada, investment advice to Canadian investors is provided through Aon Hewitt Investment " +
                        "Management Inc. (“AHIM”), a portfolio manager, investment fund manager and exempt market " +
                        "dealer registered under applicable Canadian securities laws and is a wholly owned subsidiary " +
                        "of Aon Hewitt Inc."
                    }/>
                </p>
                <p>
                    <FormattedMessage id="disclaimer.investment-related-content-p7" defaultMessage={
                        "Aon Solutions UK Limited, Aon Investments USA Inc., AHIM and Aon Hewitt Inc. are wholly owned indirect subsidiaries of " +
                        "Aon plc, a public company trading on the NYSE (“AON”)."
                    }/>
                </p>
                <p>
                    <FormattedMessage id="disclaimer.investment-related-content-p8" defaultMessage={
                        "Regional distribution and contact information is provided below. Contact your local Aon " +
                        "representative for contact information relevant to your local country if not included below."
                    }/>
                </p>
                <div id="addresses" className="disclaimer-addresses no-bullets">
                    <table className="address__table">
                        <tbody>
                        <tr className="address__table-row">
                            <td className="address__table-cell">
                                <ul className="address__content">
                                    <li>
                                        <FormattedMessage id="disclaimer.aon-plc" defaultMessage="Aon plc/Aon Solutions UK Limited"/>
                                    </li>
                                    <li>
                                        <FormattedMessage id="disclaimer.registered-office" defaultMessage="Registered office"/>
                                    </li>
                                    <li>
                                        <FormattedMessage id="disclaimer.aon-center" defaultMessage="The Aon Center"/>
                                    </li>
                                    <li>
                                        <FormattedMessage id="disclaimer.leadenhall-building" defaultMessage="The Leadenhall Building"/>
                                    </li>
                                    <li>
                                        <FormattedMessage id="disclaimer.leadenhall-street" defaultMessage="122 Leadenhall Street"/>
                                    </li>
                                    <li>
                                        <FormattedMessage id="disclaimer.london" defaultMessage="London"/>
                                    </li>
                                    <li>
                                        <FormattedMessage id="disclaimer.ec3v-4an" defaultMessage="EC3V 4AN"/>
                                    </li>
                                </ul>
                            </td>
                            <td className="address__table-cell">
                                <ul className="address__content">
                                    <li>
                                        <FormattedMessage id="disclaimer.aon-hewitt-ic" defaultMessage="Aon Investments USA Inc."/>
                                    </li>
                                    <li>
                                        <FormattedMessage id="disclaimer.200-randolph" defaultMessage="200 E. Randolph Street"/>
                                    </li>
                                    <li>
                                        <FormattedMessage id="disclaimer.suite-700" defaultMessage="Suite 700"/>
                                    </li>
                                    <li>
                                        <FormattedMessage id="disclaimer.chicago" defaultMessage="Chicago, IL 60601"/>
                                    </li>
                                    <li>
                                        <FormattedMessage id="disclaimer.usa" defaultMessage="USA"/>
                                    </li>
                                </ul>
                            </td>
                            <td className="address__table-cell">
                                <ul className="address__content">
                                    <li>
                                        <FormattedMessage id="disclaimer.aon-hewitt" defaultMessage="Aon Hewitt Inc./Aon Hewitt"/>
                                    </li>
                                    <li>
                                        <FormattedMessage id="disclaimer.investment-management" defaultMessage="Investment Management Inc."/>
                                    </li>
                                    <li>
                                        <FormattedMessage id="disclaimer.king-street" defaultMessage="225 King Street West, Suite 1600"/>

                                    </li>
                                    <li>
                                        <FormattedMessage id="disclaimer.toronto" defaultMessage="Toronto, ON"/>
                                    </li>
                                    <li>
                                        <FormattedMessage id="disclaimer.m5v-3m2" defaultMessage="M5V 3M2"/>
                                    </li>
                                    <li>
                                        <FormattedMessage id="disclaimer.canada" defaultMessage="Canada"/>
                                    </li>
                                </ul>
                            </td>
                        </tr>
                        </tbody>
                    </table>
                </div>

                <h4 className="disclaimer-subheader">
                    <FormattedMessage id="disclaimer.login-ids" defaultMessage="Login IDs, User IDs and Passwords"/>
                </h4>
                <p>
                    <FormattedMessage id="disclaimer.login-ids-p" defaultMessage={
                        "If you have been issued a Login ID and/or an access code to enable you to access the non-public " +
                        "areas of this Website, you are solely responsible for all activities that occur in connection " +
                        "with your Login ID and ensuring the safety and security of the access codes. You are not " +
                        "permitted to use the access codes for any purposes other than to receive the particular service " +
                        "offered by Aon for which the access codes were issued. You are not authorized or permitted to " +
                        "share the access codes with any other party. Please notify Aon immediately if you become aware " +
                        "of any disclosure, loss, theft or unauthorized use of your Login ID."
                    }/>
                </p>
                <h4 className="disclaimer-subheader">
                    <FormattedMessage id="disclaimer.cookies" defaultMessage="Cookies and information collected"/>
                </h4>
                <p>
                    <FormattedMessage id="disclaimer.cookies-p" defaultMessage={
                        "When you visit this website, the following information may be recorded: your IP address, pages " +
                        "visited, content downloaded and cookies. A cookie is a piece of information about an Internet " +
                        "session that may be created when a visitor accesses a website and which is used to identify " +
                        "you and store certain information such as Login information when you choose \"Remember my " +
                        "User ID,\" when logging onto our site. Aon uses this information to provide a more customized " +
                        "user experience and to help us understand the number of people that visit this website and " +
                        "which sections of the Website are visited most frequently. This information may be used to " +
                        "determine which information is most useful, improve this website and to provide better " +
                        "customer service."
                    }/>
                </p>
                <h4 className="disclaimer-subheader">
                    <FormattedMessage id="disclaimer.content" defaultMessage="Content"/>
                </h4>
                <p>
                    <FormattedMessage id="disclaimer.content-p1" defaultMessage={
                        "Aon makes no representations about the suitability of the content of this site for any purpose. " +
                        "All content is provided \"as is\" without any warranty of any kind."
                    }/>
                </p>
                <p>
                    <FormattedMessage id="disclaimer.content-p2" defaultMessage={
                        "AON HAS NOT MADE AND DOES NOT MAKE ANY WARRANTY OF ANY KIND WHATEVER, EXPRESS OR IMPLIED, " +
                        "WITH RESPECT TO THE CONTENTS OF THIS SITE; AND ALL IMPLIED WARRANTIES OF MERCHANTABILITY, " +
                        "TITLE, NON-INFRINGEMENT AND FITNESS FOR A PARTICULAR PURPOSE ARE HEREBY DISCLAIMED BY AON. " +
                        "AON WILL NOT BE LIABLE TO ANYONE WITH RESPECT TO ANY DAMAGES, LOSS OR CLAIM WHATSOEVER, NO " +
                        "MATTER HOW OCCASIONED, IN CONNECTION WITH ACCESS TO OR USE OF THE CONTENTS OF THIS SITE. IN " +
                        "NO EVENT SHALL AON BE LIABLE FOR ANY SPECIAL, INDIRECT, EXEMPLARY OR CONSEQUENTIAL DAMAGES OR " +
                        "ANY DAMAGES WHATSOEVER, INCLUDING BUT NOT LIMITED TO LOSS OF USE, DATA OR PROFITS, WITHOUT " +
                        "REGARD TO THE FORM OF ANY ACTION INCLUDING BUT NOT LIMITED TO CONTRACT OR NEGLIGENCE OR OTHER " +
                        "TORTIOUS ACTIONS, ARISING OUT OF OR IN CONNECTION WITH THE USE, COPYING OR DISPLAY OF THE " +
                        "CONTENT."
                    }/>
                </p>
                <p>
                    <FormattedMessage id="disclaimer.content-p3" defaultMessage={
                        "While Aon believes the content to be accurate, complete and current, there may be inadvertent " +
                        "technical or factual inaccuracies and typographical errors and Aon does not warrant that the " +
                        "information is accurate or complete or current. It is your responsibility to verify any " +
                        "information before relying on it. At any time and from time to time Aon may make changes in " +
                        "the products and/or services described herein, however, Aon makes no commitment to update the " +
                        "information and expressly disclaims liability for errors or omissions in it."
                    }/>
                </p>
                <h4 className="disclaimer-subheader">
                    <FormattedMessage id="disclaimer.links" defaultMessage="Links to Other Sites"/>
                </h4>
                <p>
                    <FormattedMessage id="disclaimer.links-p" defaultMessage={
                        "Some of the sites listed as links herein are not under the control of Aon. Accordingly, Aon " +
                        "makes no representations whatsoever concerning the content of those sites. The fact that Aon " +
                        "has provided a link to a site is not an endorsement, authorization, sponsorship or affiliation " +
                        "by Aon with respect to such site, its owners or its providers. Aon is providing these links " +
                        "only as a convenience to you. Aon has not tested any information, software or products found " +
                        "on these sites and therefore cannot make any representations whatsoever with respect thereto. " +
                        "There are risks in using any information, software or products found on the Internet, and Aon " +
                        "cautions you to make sure that you completely understand these risks before retrieving, using, " +
                        "relying upon or purchasing anything via the Internet."
                    }/>
                </p>
                <h4 className="disclaimer-subheader">
                    <FormattedMessage id="disclaimer.recommendation-disclosure" defaultMessage="Recommendation Disclosure"/>
                </h4>
                <p>
                    <FormattedMessage id="disclaimer.recommendation-disclosure-p1" defaultMessage={
                        "Aon plc, including all of its subsidiaries and affiliates, maintains relationships with " +
                        "clients and business partners around the world. When Aon recommends or mentions a product " +
                        "or service provider, it is possible that the product or service is being offered by one of " +
                        "Aon's clients or business partners."
                    }/>
                </p>
                <p>
                    <FormattedMessage id="disclaimer.recommendation-disclosure-p2" defaultMessage={
                        "Aon maintains a strict Code of Business Conduct covering many topics, including antitrust and " +
                        "competition law, conflicts of interest, anti-bribery, privacy, financial reporting, compliance " +
                        "with trade restrictions, protection of confidential information, and compliance with all laws " +
                        "and regulations applicable to the conduct of our business. Aon also files necessary disclosures " +
                        "with all governmental regulators."
                    }/>
                </p>
                <p>
                    <FormattedMessage id="disclaimer.recommendation-disclosure-p3" defaultMessage={
                        "If you have any questions about an Aon recommendation or mention of another business entity (or " +
                        "about an entity with whom Aon conducts business), please follow-up with your Aon contact person. " +
                        "Aon is committed to serving our clients in a transparent manner."
                    }/>
                </p>
                <h4 className="disclaimer-subheader">
                    <FormattedMessage id="disclaimer.export-controls" defaultMessage="Export Controls"/>
                </h4>
                <p>
                    <FormattedMessage id="disclaimer.export-controls-p" defaultMessage={
                        "Some software from this site may be subject to export controls imposed by the United States of " +
                        "America and may not be downloaded or otherwise exported or reexported: (a) into (or to a " +
                        "national resident of) any country to which the U. S. has placed an embargo; (b) to everyone " +
                        "on the U. S. Treasury Department's Specially Designated Nationals list; or (c) the U. S. " +
                        "Commerce Department's Table of Denial Orders. If you download or use the software, you " +
                        "represent and warrant that you are not located in, or under the control of, or a national of " +
                        "any such country or on such list."
                    }/>
                </p>
                <h4 className="disclaimer-subheader">
                    <FormattedMessage id="disclaimer.client-data-use" defaultMessage="Client Data, Collection and Use"/>
                </h4>
                <p>
                    <FormattedMessage id="disclaimer.client-data-use-p1" defaultMessage={
                        "Aon may gather data containing information about its clients or use of this online portal and " +
                        "is maintained in one or more databases."
                    }/>
                </p>
                <p>
                    <FormattedMessage id="disclaimer.client-data-use-p2" defaultMessage={
                        "Aon may use or disclose information about its clients, if required to do so by law, Aon policy, " +
                        "pursuant to legal process or in response to a request from law enforcement authorities or other " +
                        "government officials. In addition to being used for the benefit of Aon’s clients, these " +
                        "databases also may be accessed by other Aon affiliates for other purposes, including providing " +
                        "consulting, and other services to insurance companies for which Aon or its affiliates may earn " +
                        "compensation."
                    }/>
                </p>
                <p>
                    <FormattedMessage id="disclaimer.client-data-use-p3" defaultMessage={
                        "Due to the global nature of services provided by Aon, the personal information Client may " +
                        "provide may be transmitted, used, stored and otherwise processed outside the country where " +
                        "Client submitted that information."
                    }/>
                </p>
                <p>
                    <FormattedMessage id="disclaimer.client-data-use-p4" defaultMessage={
                        "For further information regarding Aon's data privacy practices, please see Aon's "
                    }/>
                    {/* eslint-disable-next-line react/forbid-dom-props */}
                    <a href={"https://www.aon.com/about-aon/privacy.jsp"}>
                        <FormattedMessage id="disclaimer.client-data-use-p4-a" defaultMessage="Privacy Statement."/>
                    </a>
                </p>
            </div>
        </div>
    );
};