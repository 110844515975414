import * as React from "react";
import {useEffect, useState} from "react";
import {IOddResponse, IOddSectionsQuestions, OddLevelId} from "../../../../api/OddIqApi";
import {ITab, TabsComponent} from "../../../common/Tabs.component";
import {getResponsesForQuestions, getSectionsQuestionsByLevel} from "../../OddIqUtils";
import {
    OddResultsByRiskCategoryContainer,
    OddResultsByRiskCategoryContainerProps
} from "./OddResultsByRiskCategoryContainer";
import {Map as ImmutableMap} from "immutable";
import {ProductSummary} from "../../../../model/product/ProductSummary";
import {OperationalDueDiligenceDocument} from "../../../../model/OperationalDueDiligenceDocument.model";

export interface IOddResultsByRiskCategoryContainerProps {
    oddSectionsQuestions: IOddSectionsQuestions;
    oddResponses: IOddResponse[];
    portfolioProductSummaries: ImmutableMap<number, ProductSummary>;
    currentQuestionId?: number;
    oddDocuments: OperationalDueDiligenceDocument[];
    byBackstop: boolean;
}

export const managerLevelDescription = <div>
        <p>
            Manager level risk factors are items that we generally consider firm-level business operating policies and
            procedures which typically apply to all strategies that a firm manages.
        </p>
        <p>
            The summary information below considers exceptions within these areas. It is designed to help identify which
            Managers within the defined portfolio demonstrate either a higher number of total exceptions or exceptions
            that are unusual relative to other managers within the portfolio.
        </p>
    </div>;


export const strategyLevelDescription = <div>
    <p>
        Strategy level risk factors are items that we generally consider investment strategy (or fund)-level
        operating policies and procedures which may apply only to the strategy in scope.
    </p>
    <p>
        The summary information below considers exceptions within these areas. It is designed to help identify which
        strategies within the defined portfolio demonstrate either a higher number of total exceptions or exceptions
        that are unusual relative to other strategies within the portfolio.
    </p>
</div>;


export const OddResultsByRiskCategory: React.FunctionComponent<IOddResultsByRiskCategoryContainerProps> = (props) => {
    const [selectedTabIndex, setSelectedTabIndex] = useState<number>(0);

    const managerLevelSectionsQuestions = getSectionsQuestionsByLevel(props.oddSectionsQuestions, OddLevelId.Manager);
    const strategyLevelSectionsQuestions = getSectionsQuestionsByLevel(props.oddSectionsQuestions, OddLevelId.Strategy);

    function renderSummaryByProductTabs() {
        const managerLevelProps: OddResultsByRiskCategoryContainerProps = {
            oddSectionsQuestions: managerLevelSectionsQuestions,
            oddResponses: getResponsesForQuestions(
                props.oddResponses, managerLevelSectionsQuestions.questions),
            portfolioProductSummaries: props.portfolioProductSummaries,
            descriptionComponent: managerLevelDescription,
            currentQuestionId: props.currentQuestionId,
            oddDocuments: props.oddDocuments,
            byBackstop: props.byBackstop,
        };

        const strategyLevelProps: OddResultsByRiskCategoryContainerProps = {
            oddSectionsQuestions: strategyLevelSectionsQuestions,
            oddResponses: getResponsesForQuestions(
                props.oddResponses, strategyLevelSectionsQuestions.questions),
            portfolioProductSummaries: props.portfolioProductSummaries,
            descriptionComponent: strategyLevelDescription,
            currentQuestionId: props.currentQuestionId,
            oddDocuments: props.oddDocuments,
            byBackstop: props.byBackstop,
        };

        const tabs: ITab[] = [
            {
                tabTitle: "Manager Level Categories",
                tabComponent: OddResultsByRiskCategoryContainer,
                tabComponentProps: managerLevelProps,
            },
            {
                tabTitle: "Strategy Level Categories",
                tabComponent: OddResultsByRiskCategoryContainer,
                tabComponentProps: strategyLevelProps,
            }
        ];

        return <TabsComponent tabs={tabs}
                              selectedTabIndex={selectedTabIndex}
                              handleTabChange={setSelectedTabIndex}
        />;
    }

    useEffect(() => {
        if (props.currentQuestionId) {
            const newTabIndex = managerLevelSectionsQuestions.questions
                .find((q) => q.id === props.currentQuestionId)
                ? 0
                : 1;
            setSelectedTabIndex(newTabIndex);
        }
    }, [props.currentQuestionId]);

    return <div id="odd-horizontal-bar__component">
        <div id="odd-page__bar-chart-title-and-about-container">
            <div id="bar-chart__title">
                <h3 className="odd-page__bar-chart-header">Results by Risk Category</h3>
            </div>
        </div>
        {renderSummaryByProductTabs()}
    </div>;
};