import {Map} from "immutable";
import {Action, Dispatch} from "redux";
import {ThunkAction} from "redux-thunk";
import {FlashReportsApi} from "../../api/FlashReportsApi";
import {FlashReportSummary} from "../../model/FlashReportSummary.model";
import {throwErrorOnNullOrUndefined} from "../../utils/errorUtil";

export enum AllFlashReportsActions {
    ALL_FLASH_REPORT_SUMMARIES_REQUESTED = "ALL_FLASH_REPORT_SUMMARIES_REQUESTED",
    ALL_FLASH_REPORT_SUMMARIES_RECEIVED = "ALL_FLASH_REPORT_SUMMARIES_RECEIVED",
}

const allFlashReportsRequestedAction = () => {
    return {
        type: AllFlashReportsActions.ALL_FLASH_REPORT_SUMMARIES_REQUESTED,
    };
};

const allFlashReportsReceivedAction = (payload: Map<number, FlashReportSummary>) => {
    return {
        type: AllFlashReportsActions.ALL_FLASH_REPORT_SUMMARIES_RECEIVED,
        payload,
    };
};

export const getAllFlashReportsMap = (dispatch: Dispatch<Action<AllFlashReportsActions>>) => {
    dispatch(allFlashReportsRequestedAction());

    FlashReportsApi.requestAllFlashReportSummariesMap().then(
        (allFlashReports) => dispatch(allFlashReportsReceivedAction(throwErrorOnNullOrUndefined(allFlashReports.data))),
    );
};

export const requestAllFlashReportSummaries = (): ThunkAction<void, void, null, Action<AllFlashReportsActions>> => {
    return (dispatch) => {
        getAllFlashReportsMap(dispatch);
    };
};

export default {
    requestAllFlashReportSummaries,
};
