import {applyMiddleware, createStore, Store} from "redux";
import logger from "redux-logger";
import thunk from "redux-thunk";
import {adminMiddleware} from "./main/components/admin/admin.middleware";
import {userSessionInitializer} from "./main/components/initialize/userSessionInitializer.middleware";
import {userSessionPersistence} from "./main/components/session/persistence/userSessionPersistence.middleware";
import {getConfig} from "./main/utils/envUtil";
import {applicationInitialState} from "./applicationState";
import {createReduxHistory, routerMiddleware} from "./reduxHistoryContext";
import {createRootReducer} from "./mainReducerMap";

const createLocalStore = () => {
    return createStore(
        createRootReducer(),
        applicationInitialState,
        applyMiddleware(
            logger,
            thunk,
            routerMiddleware,
            userSessionInitializer,
            userSessionPersistence,
            adminMiddleware,
        ),
    );
};

const createDefaultStore = () => {
    return createStore(
        createRootReducer(),
        applicationInitialState,
        applyMiddleware(
            thunk,
            routerMiddleware,
            userSessionInitializer,
            userSessionPersistence,
            adminMiddleware,
        ),
    );
};

export const store: Store<any> = (getConfig() && getConfig().FRONTEND_CONFIG_ENVIRONMENT === "local") ?
    createLocalStore() : createDefaultStore();

export const history = createReduxHistory(store);