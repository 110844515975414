import {now} from "../utils/dateProvider";

export class UploadFile {
    public static builder() {
        return new UploadFileBuilder();
    }

    public constructor(public file: File,
                       public key: number,
                       public title: string,
                       public thirdPartyAccess: boolean,
                       public selectedDocumentType?: number,
                       public date?: Date) {
    }
}

class UploadFileBuilder {
    private mFile: File;
    private mKey: number;
    private mSelectedDocumentType?: number;
    private mDate?: Date;
    private mTitle: string;
    private mPlanIds: number[];
    private mEmail: string;
    private mThirdPartyAccess: boolean;

    public file(value: File) {
        this.mFile = value;
        return this;
    }

    public key(value: number) {
        this.mKey = value;
        return this;
    }

    public selectedDocumentType(value?: number) {
        this.mSelectedDocumentType = value;
        return this;
    }

    public date(value?: Date) {
        this.mDate = value;
        return this;
    }

    public title(value: string) {
        this.mTitle = value;
        return this;
    }

    public planIds(value: number[]) {
        this.mPlanIds = value;
        return this;
    }

    public email(value: string) {
        this.mEmail = value;
        return this;
    }

    public thirdPartyAccess(value: boolean) {
        this.mThirdPartyAccess = value;
        return this;
    }

    public build() {
        return new UploadFile(
            this.mFile,
            this.mKey,
            this.mTitle,
            this.mThirdPartyAccess,
            this.mSelectedDocumentType,
            this.mDate

        );
    }
}

export const createUploadFile = (parameters: {
    file?: File,
    key?: number,
    date?: Date,
    title?: string,
    selectedDocumentType?: number,
    thirdPartyAccess?: boolean
} = {}) => {
    return UploadFile.builder()
        .file(parameters.file || new File([], ""))
        .key(parameters.key || 0)
        .date(parameters.date || now())
        .title(parameters.title || "")
        .selectedDocumentType(parameters.selectedDocumentType)
        .thirdPartyAccess(parameters.thirdPartyAccess || false)
        .build();
};
