import * as React from "react";
import {formatShortDate} from "../../utils/dateUtil";
import {useEffect, useState} from "react";
import {CprPortfolioApi, IManagerUpdate} from "../../api/CprPortfolioApi";
import {filterManagerUpdate} from "./CprUtils";
import {FormattedMessage} from "react-intl";

export const MANAGER_UPDATES_TITLE = "Manager Updates since";
export const MANAGER_UPGRADES_TITLE = "Manager Upgrades";
export const MANAGER_DOWNGRADES_TITLE = "Manager Downgrades";
export const NO_MANAGER_UPGRADES_MSG = "There have been no manager upgrades";
export const NO_MANAGER_DOWNGRADES_MSG = "There have been no manager downgrades";

export interface CprManagerUpdatesProps {
    asOfDate: string
}

export const CprManagerUpdates: React.FunctionComponent<CprManagerUpdatesProps> = (props: CprManagerUpdatesProps) => {
    const {asOfDate} = props;
    const [upgrades, setUpgrades] = useState<IManagerUpdate[]>([]);
    const [downgrades, setDowngrades] = useState<IManagerUpdate[]>([]);

    useEffect(() => {
        CprPortfolioApi.fetchManagerUpdates().then((resp) => {
            setUpgrades(filterManagerUpdate(resp));
            setDowngrades(filterManagerUpdate(resp, false));
        });
    }, []);

    const managerUpdatesTable = (managerUpdates: IManagerUpdate[]) => {
        return <table className="manager-updates__table">
            <thead>
            <tr className="manager-updates__header">
                <th className="manager-updates__header-fund">
                    <h6><FormattedMessage id="home.manager-updates-fund" defaultMessage="Fund"/></h6></th>
                <th className="manager-updates__header-ratings">
                    <h6><FormattedMessage id="home.manager-updates-current-rating" defaultMessage="Current Rating"/>
                    </h6></th>
                <th className="manager-updates__header-ratings">
                    <h6><FormattedMessage id="home.manager-updates-previous-rating" defaultMessage="Previous Rating"/>
                    </h6></th>
            </tr>
            </thead>
            <tbody>{renderManagerUpdates(managerUpdates)}</tbody>
        </table>;
    };

    const renderManagerUpdates = (managerUpdates: IManagerUpdate[]) => {
        return managerUpdates.map(renderManagerUpdate);
    };

    const renderManagerUpdate = (managerUpdate: IManagerUpdate) => {
        return <tr className="manager-updates__row" key={managerUpdate.name} data-testid="manager-updates__row">
            <td className="manager-updates__fund" data-testid="manager-updates__fund">{managerUpdate.name}</td>
            <td className="manager-updates__current-rating" data-testid="manager-updates__current-rating">
                {managerUpdate.currentRating}
            </td>
            <td className="manager-updates__previous-rating" data-testid="manager-updates__previous-rating">
                {managerUpdate.lastReportingRating}
            </td>
        </tr>;
    };

    return <div className="manager-updates__container" data-testid="manager-updates__container">
        <div className="manager-updates__title">
            <h2>
                <FormattedMessage id="home.manager-updates-title" defaultMessage={MANAGER_UPDATES_TITLE}/>
                {` ${formatShortDate(asOfDate)}`}
            </h2>
        </div>
        <div className="manager-updates__content">
            <div className="manager-updates__upgrades-container" data-testid="manager-updates__upgrades-container">
                <div className="manager-updates__upgrades-title">
                    <h3><FormattedMessage id="home.manager-updates-upgrades-title"
                                          defaultMessage={MANAGER_UPGRADES_TITLE}/></h3></div>
                <div className="manager-updates__upgrades-content">
                    {upgrades.length ? managerUpdatesTable(upgrades)
                        : <FormattedMessage id="home.manager-updates-no-upgrades-message"
                                            defaultMessage={NO_MANAGER_UPGRADES_MSG}/>}
                </div>
            </div>
            <div className="manager-updates__downgrades-container" data-testid="manager-updates__downgrades-container">
                <div className="manager-updates__downgrades-title">
                    <h3><FormattedMessage id="home.manager-updates-downgrades-title"
                                          defaultMessage={MANAGER_DOWNGRADES_TITLE}/></h3></div>
                <div className="manager-updates__downgrades-content">
                    {downgrades.length ? managerUpdatesTable(downgrades)
                        : <FormattedMessage id="home.manager-updates-no-downgrades-message"
                                            defaultMessage={NO_MANAGER_DOWNGRADES_MSG}/>}
                </div>
            </div>
        </div>
    </div>;
};