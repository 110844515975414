import {models} from "powerbi-client";
import {IReportBookmark} from "powerbi-models";
import {Report} from "report";
import {IReportConfig} from "../api/PowerBiReportingApi";

export const powerBiEmbedReport = (elementId: string, reportConfig: IReportConfig) => {
    const config = {
        type: "report",
        tokenType: models.TokenType.Embed,
        accessToken: reportConfig.token,
        embedUrl: encodeURI(`${reportConfig.embedUrl}`),
        id: reportConfig.reportId,
        settings: {
            filterPaneEnabled: false,
            navContentPaneEnabled: false
        }
    };
    window.powerbi.embed(document.getElementById(elementId)!, config);
};

export const powerBiReset = (elementId: string) => {
    window.powerbi.reset(document.getElementById(elementId)!);
};

export const powerBiFullScreen = (elementId: string) => {
    const report = window.powerbi.get(document.getElementById(elementId)!) as Report;
    report.fullscreen();
};

export const powerBiPrint = (elementId: string) => {
    const report = window.powerbi.get(document.getElementById(elementId)!) as Report;
    report.print();
};

export const powerBiCaptureBookmarks = (elementId: string): Promise<IReportBookmark> => {
    const report = window.powerbi.get(document.getElementById(elementId)!) as Report;

    return report.bookmarksManager.capture({
        personalizeVisuals: true
    });
};

// Assumptions:
// The second page has a slicer
// Period slicer has title "Select Quarter"
// Format of the period options: "Q2 2020" or "T2 2020" for FR
// There might or might not be "Latest Data" option in the slicer. ("Dernières données" for FR).
//      If this option is selected grab the next available option with quarter and year
export const getPerformanceSelectedPeriod = (elementId: string): Promise<number> => {
    const report = window.powerbi.get(document.getElementById(elementId)!) as Report;
    // return report.getActivePage().then((pageWithSlicer) => {
    return report.getPages().then(pages => {
        // const pageWithSlicer = pages.filter(page => page.displayName.indexOf("Exec Summ") > -1)[0];
        const pageWithSlicer = pages[1];
        return pageWithSlicer.setActive().then(() => {
            // console.error("=============> pageWithSlicer: ", pageWithSlicer);
            return pageWithSlicer.getVisuals().then(visuals => {
                const slicer = visuals
                    .filter(visual => visual.type === "slicer" && visual.title === "Select Quarter")[0];
                // console.error("=============> slicer: ", slicer);
                return slicer.exportData().then((data) => {
                    // console.error("=============> data: ", data);
                    const datesArray = data.data.split("\r\n");
                    // console.error("=============> datesArray[1]: ", datesArray[1]);
                    return slicer.getSlicerState().then(state => {
                        // console.error("=============> state: ", state);
                        let selectedValue = (state.filters[0] as any).values[0];
                        // check if it's a valid period option. If not, it's `Latest Data` and need to grab next option
                        if (!validatePeriodOption(selectedValue)) {
                            selectedValue = datesArray[2];
                        }
                        // console.error("=============> selectedValue: ", selectedValue);
                        // const period = selectedValue.toUpper().indexOf("LATEST DATA") > -1 ? datesArray[2] : selectedValue;
                        return selectedPeriodToNumber(selectedValue);
                   });
                });
            });
        });
    });
};

const validatePeriodOption = (periodAsString: string): string | undefined => {
    const sanitized = periodAsString.trim().replace(" ", "").substring(1);
    if (isNaN(parseInt(sanitized, 10))) {
        return undefined;
    }
    return sanitized;
};

export const selectedPeriodToNumber = (periodAsString: string): number => {
    const validated = validatePeriodOption(periodAsString);
    if (!validated) {
        throw new Error(`Invalid report period: ${periodAsString}!`);
    }
    return parseInt(validated, 10);
};
