import {ApiBase} from "./ApiBase";
import {AxiosResponse} from "axios";
import {IPlanTreeNodeFlat} from "../model/Portfolio.model";


export class PortfolioApi {
    public static getCurrentPlanPortfolio(): Promise<IPlanTreeNodeFlat[]> {
        return ApiBase.processGetUnwrapped("/plans/current/portfolio-plan-tree", (response: AxiosResponse) => {
            return response.data;
        });
    }
}