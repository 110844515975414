import {Map} from "immutable";
import * as React from "react";
import {connect} from "react-redux";
import {IApiResult} from "../../api/AxiosWealthManagementApi";
import {alertBox} from "../../api/BrowserWrapper";
import ReleaseNotesApi from "../../api/ReleaseNotesApi";
import {ReleaseNote} from "../../model/ReleaseNote.model";
import {AdminLevelList, doesUserHaveAccess} from "../../model/UserDetails.model";
import {defaultButtonStyle} from "../common/buttonStyles";
import {RaisedButton} from "../common/RaisedButton";
import {compareDates} from "../../utils/dateUtil";
import {ErrorComponent} from "../base/Error.component";
import {DateSelectorComponent} from "../common/DateSelector.component";
import {DialogComponent} from "../common/Dialog.component";
import {MarkdownComponent} from "../common/Markdown.component";
import {ISessionState} from "../session/SessionReducer";
import {IApplicationRootState} from "../../../applicationState";

type ReleaseNoteEditMode = "new" | "existing unedited" | "existing edited";
export type ComponentMode = "preview" | "edit";

interface IReleaseNoteState {
    mode: ComponentMode;
    status: ReleaseNoteEditMode;
    releaseNote: ReleaseNote;
}

interface IReleaseNotesPageState {
    savedReleaseNotes: Map<number, IReleaseNoteState>;
    newReleaseNotes: Map<number, IReleaseNoteState>;
    backupSavedNotes: Map<number, IReleaseNoteState>;
    maxTempNoteId: number;
    loadError: boolean;
    releaseNoteToBeRemovedId?: number;
}

export type IReleaseNotesPageProps = ISessionState;

export class ReleaseNotesPage extends React.Component<IReleaseNotesPageProps, IReleaseNotesPageState> {

    constructor(props: any) {
        super(props);
        this.state = {
            savedReleaseNotes: Map(),
            newReleaseNotes: Map(),
            backupSavedNotes: Map(),
            maxTempNoteId: -1,
            loadError: false,
            releaseNoteToBeRemovedId: undefined,
        };
    }

    public componentDidMount() {
        ReleaseNotesApi.getReleaseNotes()
            .then((response: IApiResult<ReleaseNote[]>) => {
                const newSavedReleaseNotesMap =
                    response.data!.reduce((accumulator: Map<number, IReleaseNoteState>, note: ReleaseNote) => {
                        return accumulator.set(note.id, {
                                mode: "preview",
                                status: "existing unedited",
                                releaseNote: note,
                            },
                        );
                    }, Map<number, IReleaseNoteState>());
                this.setState({
                    savedReleaseNotes: newSavedReleaseNotesMap,
                    backupSavedNotes: newSavedReleaseNotesMap,
                });
            })
            .catch(() => {
                this.setState({loadError: true});
            });
    }

    public render() {
        return this.state.loadError ? <ErrorComponent/>
            : (<div className="main-content" data-testid="release-notes-page">
                    <div className="release-note-page__title-spacer">
                        {this.renderAddNewButton()}
                        <h1>Release Notes</h1>
                    </div>
                    {this.renderDeleteReleaseNoteDialog()}
                    <div>
                        {this.renderReleaseNotes()}
                    </div>
            </div>
        );
    }

    private renderSaveButton(noteState: IReleaseNoteState) {
        if (!doesUserHaveAccess(this.props.userType, AdminLevelList)) {
            return null;
        }

        const disabled = (noteState.status === "new" || noteState.status === "existing edited") ? "" : "disabled";

        return <a
            className={`release-note-page__save-button link-16-light clickable ${disabled}`}
            onClick={() => this.handleSaveReleaseNotes(noteState)}>
            Save
        </a>;
    }

    private renderPreviewButton(noteState: IReleaseNoteState) {
        if (!doesUserHaveAccess(this.props.userType, AdminLevelList)) {
            return null;
        }

        const disabled = noteState.mode === "preview" ? "disabled" : "";

        return <a className={`release-note-page__preview-button link-16-light clickable ${disabled}`}
                  onClick={() => this.handlePreviewClick(noteState)}>
            Preview
        </a>;
    }

    private renderEditButton(noteState: IReleaseNoteState) {
        if (!doesUserHaveAccess(this.props.userType, AdminLevelList)) {
            return null;
        }

        const enabled = noteState.mode === "preview";
        const className = `release-note-page__edit-button${enabled ? " enabled" : ""}`;

        return <div className={className}>
            <span className="fas fa-edit"
                  onClick={() => this.handleEditClick(noteState)}/>
        </div>;
    }

    private renderDeleteButton(noteState: IReleaseNoteState) {
        if (!doesUserHaveAccess(this.props.userType, AdminLevelList)) {
            return null;
        }

        const enabled = noteState.status !== "new";
        const className = `release-note-page__delete-button${enabled ? " enabled" : ""}`;

        return <div className={className}>
            <span className="fas fa-trash"
                  onClick={() => this.handleDeleteClick(noteState)}/>
        </div>;
    }

    private renderCancelButton(noteState: IReleaseNoteState) {
        if (!doesUserHaveAccess(this.props.userType, AdminLevelList)) {
            return null;
        }

        return <a className={`release-note-page__cancel-button link-16-light clickable`}
                  onClick={() => this.handleCancelClick(noteState)}>
            Cancel
        </a>;
    }

    private renderAddNewButton() {
        if (!doesUserHaveAccess(this.props.userType, AdminLevelList)) {
            return null;
        }

        return <RaisedButton
                id="release-note-page__add-note-button"
                style={defaultButtonStyle}
                primary={true}
                onClick={() => this.handleAddNewReleaseNote()}>
            Add New
        </RaisedButton>;
    }

    private handleAddNewReleaseNote() {
        const newReleaseNote = new ReleaseNote("", new Date(), this.state.maxTempNoteId);
        const noteState: IReleaseNoteState = {mode: "edit", status: "new", releaseNote: newReleaseNote};

        this.setState({
            newReleaseNotes: this.state.newReleaseNotes.set(newReleaseNote.id, noteState),
            maxTempNoteId: this.state.maxTempNoteId - 1,
        });
    }

    private handleSaveReleaseNotes(noteState: IReleaseNoteState) {
        const releaseNote = noteState.releaseNote;
        switch (noteState.status) {
            case "new":
                this.addReleaseNote(releaseNote);
                break;
            case "existing edited":
                this.updateReleaseNote(releaseNote);
                break;
            default:
                break;
        }
    }

    private updateReleaseNote(releaseNote: ReleaseNote) {
            ReleaseNotesApi.updateReleaseNote(releaseNote)
                .then(() => {
                    this.updateAfterSave(releaseNote);
                })
                .catch((error) => {
                    alertBox("Failed to update! " + error);
                });
    }

    private addReleaseNote(releaseNote: ReleaseNote) {
        ReleaseNotesApi.addReleaseNote({note: releaseNote.note, date: releaseNote.date})
            .then((response: IApiResult<ReleaseNote>) => {

                    this.updateAfterSave(response.data!, releaseNote.id);
                })
            .catch((error) => {
                const newReleaseNoteState: IReleaseNoteState = {mode: "edit", status: "new", releaseNote};
                const newReleaseNotes = this.state.newReleaseNotes
                    .set(newReleaseNoteState.releaseNote.id, newReleaseNoteState);

                this.setState({
                    newReleaseNotes,
                });

                alertBox("Failed to add. Setting back to edit mode! " + error);
            });
    }

    private updateAfterSave(savedReleaseNote: ReleaseNote, originalId?: number) {
        const newReleaseNoteState: IReleaseNoteState = {
            mode: "preview",
            status: "existing unedited",
            releaseNote: savedReleaseNote,
        };

        let newReleaseNotes = this.state.newReleaseNotes;

        if (originalId) {
            newReleaseNotes = newReleaseNotes.delete(originalId);
        }

        const savedReleaseNotes = this.state.savedReleaseNotes
            .set(newReleaseNoteState.releaseNote.id, newReleaseNoteState);

        const backupSavedNotes = this.state.backupSavedNotes
            .set(newReleaseNoteState.releaseNote.id, newReleaseNoteState);

        this.setState({
            savedReleaseNotes,
            newReleaseNotes,
            backupSavedNotes,
        });
    }

    private renderReleaseNotes() {

        const newEntries = this.state.newReleaseNotes.valueSeq().toArray()
            .sort((a, b) => a.releaseNote.id - b.releaseNote.id);

        const existingEntries = this.state.savedReleaseNotes.valueSeq().toArray()
            .sort((a, b) => compareDates(a.releaseNote.date, b.releaseNote.date));

        const allEntries = ([] as IReleaseNoteState[]).concat(newEntries).concat(existingEntries);
        const entriesLength = allEntries.length;

        return allEntries.map((noteState: IReleaseNoteState, index: number) =>
            this.renderReleaseNote(noteState, index, entriesLength));
    }

    private renderReleaseNote(noteState: IReleaseNoteState, index: number, entriesLength: number) {

        const note = noteState.releaseNote;
        const verticalLineOrNull = index === (entriesLength - 1)
            ? <div className="release-note-component__vertical-noline"/>
            : <div className="release-note-component__vertical-line"/>;

        return <div key={note.id} className="release-note-page__release-note"
                    data-testid="release-note-page__release-note">
            <div className="release-note-component__date-container">
                <DateSelectorComponent
                    date={noteState.releaseNote.date}
                    mode={noteState.mode}
                    onChange={(date: Date) => {
                        this.handleDateChange(date, noteState);
                    }}
                />
            </div>
            <div className="release-note-component__spacer">
                <div className="release-note-component__preview-hexagon fa-regular fa-circle"/>
                {verticalLineOrNull}
            </div>
            <div className="release-note-component__markdown-container">
                <div className="release-note-component__markdown-component">
                    <MarkdownComponent
                        mode={noteState.mode}
                        text={noteState.releaseNote.note}
                        onChange={(newText: string) => {
                            this.handleMarkdownChange(newText, noteState);
                        }}
                    />
                </div>
            </div>
            <div className="release-note-component__menu-container">
                <div className="release-note-component__edit-delete">
                    {this.renderEditButton(noteState)}
                    {this.renderDeleteButton(noteState)}
                </div>
                {this.renderMenuButtons(noteState)}
            </div>
        </div>;
    }

    private renderMenuButtons(noteState: IReleaseNoteState) {
        const inEditMode = noteState.mode === "edit";
        const isChanged = noteState.status === "existing edited" || noteState.status === "new";
        const showButtons = inEditMode || isChanged;

        return<div className="release-note-component__buttons-container">
                {showButtons && this.renderPreviewButton(noteState)}
                {showButtons && this.renderSaveButton(noteState)}
                {showButtons && this.renderCancelButton(noteState)}
            </div>;
    }

    private handleEditClick(noteState: IReleaseNoteState) {

        const newNoteState: IReleaseNoteState = {...noteState, mode: "edit"};

        this.saveStateChanges(newNoteState);
    }

    private handlePreviewClick(noteState: IReleaseNoteState) {

        const newNoteState: IReleaseNoteState = {...noteState, mode: "preview"};

        this.saveStateChanges(newNoteState);
    }

    private handleCancelClick(noteState: IReleaseNoteState) {
            const releaseNote = noteState.releaseNote;
            const backupSavedNotes = this.state.backupSavedNotes;

            switch (noteState.status) {
                case "new":
                    this.setState({ newReleaseNotes: this.state.newReleaseNotes.delete(releaseNote.id)});
                    break;
                case "existing edited":
                    const backupNoteState = backupSavedNotes.has(releaseNote.id) ?
                        backupSavedNotes.get(releaseNote.id)!
                        : noteState;

                    this.saveStateChanges(
                        backupNoteState,
                    );
                    break;
                default:
                    this.handlePreviewClick(noteState);
                    break;
            }
    }

    private handleMarkdownChange = (text: string, noteState: IReleaseNoteState) => {
        const noteStatus = noteState.status === "new" ? "new" : "existing edited";

        this.saveStateChanges({
            ...noteState,
            status: noteStatus,
            releaseNote: {
                ...noteState.releaseNote, note: text,
            },
        });

    };

    private handleDateChange = (date: Date, noteState: IReleaseNoteState) => {
        const noteStatus = noteState.status === "new" ? "new" : "existing edited";

        this.saveStateChanges({
            ...noteState,
            status: noteStatus,
            releaseNote: {
                ...noteState.releaseNote, date,
            },
        });

    };

    private saveStateChanges(noteState: IReleaseNoteState) {
        if (noteState.status === "new") {
            this.setState({
                newReleaseNotes:
                    this.state.newReleaseNotes.set(noteState.releaseNote.id, noteState),
            });
        } else {
            this.setState({
                savedReleaseNotes:
                    this.state.savedReleaseNotes.set(noteState.releaseNote.id, noteState),
            });
        }
    }

    private handleDeleteClick(noteState: IReleaseNoteState) {
        if (noteState.status !== "new") {
            this.setState({
                releaseNoteToBeRemovedId: noteState.releaseNote.id,
            });
        }
    }

    private renderDeleteReleaseNoteDialog() {
        return <div className="release-note-page__confirm-delete-dialog">
            <DialogComponent
                    title="Remove this Release Note?"
                    open={this.state.releaseNoteToBeRemovedId !== undefined}
                    description="Once deleted, it can not be recovered."
                    noText="No, keep it"
                    yesText="Yes, remove"
                    onYes={this.handleDeleteReleaseNote}
                    onNo={this.handleCancelDeleteReleaseNote}
             />
        </div>;
    }

    private handleDeleteReleaseNote = () => {
        this.deleteReleaseNote(this.state.releaseNoteToBeRemovedId!);
        this.setState({releaseNoteToBeRemovedId: undefined});
    };

    private handleCancelDeleteReleaseNote = () => {
        this.setState({releaseNoteToBeRemovedId: undefined});
    };

    private deleteReleaseNote(releaseNoteId: number) {
        ReleaseNotesApi.deleteReleaseNote(releaseNoteId)
            .then(() => {
                this.afterDelete(releaseNoteId);
            })
            .catch((error) => {
                alertBox("Failed to delete! " + error);
            });
    }

    private afterDelete(id: number) {
        this.setState({
            savedReleaseNotes: this.state.savedReleaseNotes.delete(id),
            backupSavedNotes: this.state.backupSavedNotes.delete(id),
            newReleaseNotes: this.state.newReleaseNotes.delete(id),
        });
    }
}

export const mapStateToProps = (state: IApplicationRootState): ISessionState => {
    return {...state.session!};
};

export default connect<IReleaseNotesPageProps>(mapStateToProps)(ReleaseNotesPage);
