import {Action} from "redux";
import {ThunkAction} from "redux-thunk";
import {IApiResult} from "../../api/AxiosWealthManagementApi";
import {MeetingNotesApi} from "../../api/MeetingNotesApi";
import {ApiError} from "../../model/ApiError.model";
import {MeetingNoteDetail} from "../../model/MeetingNoteDetail.model";

export interface IMeetingNoteAction {
    type: MeetingNoteActions;
    payload: MeetingNoteDetail | undefined;
}

export enum MeetingNoteActions {
    MEETING_NOTE_DETAIL_RECEIVED = "MEETING_NOTE_DETAIL_RECEIVED",
    MEETING_NOTE_PDF_DOWNLOADED = "MEETING_NOTE_PDF_DOWNLOADED",
    ERROR_MEETING_NOTE_DETAIL = "ERROR_MEETING_NOTE_DETAIL",
    MEETING_NOTE_DETAIL_REQUESTED = "MEETING_NOTE_DETAIL_REQUESTED",
}

const meetingNoteDetailReceivedAction = (payload?: MeetingNoteDetail) => {
    return {
        type: MeetingNoteActions.MEETING_NOTE_DETAIL_RECEIVED,
        payload,
    };
};

const meetingNoteDetailRequestedAction = (payload: number) => {
    return {
        type: MeetingNoteActions.MEETING_NOTE_DETAIL_REQUESTED,
        payload,
    };
};

const errorMeetingNoteDetail = (payload?: ApiError) => {
    return {
        type: MeetingNoteActions.ERROR_MEETING_NOTE_DETAIL,
        payload,
    };
};

const meetingNotePdfDownloadedAction = (payload?: number) => {
    return {type: MeetingNoteActions.MEETING_NOTE_PDF_DOWNLOADED, payload};
};

export const getMeetingNotePdf
    = (backstopId: number, fileName: string): ThunkAction<void, {}, null, Action<MeetingNoteActions>> => {
    return (dispatch) => {
        MeetingNotesApi.requestPdf(backstopId, fileName)
            .then(() => {
                dispatch(meetingNotePdfDownloadedAction(backstopId));
            });
    };
};

export const getMeetingNoteDetail =
    (backstopId: number): ThunkAction<void, IMeetingNoteAction, null, Action<MeetingNoteActions>> => {
    return (dispatch) => {
        dispatch(meetingNoteDetailRequestedAction(backstopId));
        MeetingNotesApi.requestMeetingNoteDetailById(backstopId)
            .then((result: IApiResult<MeetingNoteDetail>) => {
                dispatch(meetingNoteDetailReceivedAction(result.data!));
            }, (errorResponse) => {
                dispatch(errorMeetingNoteDetail(errorResponse));
            });
    };
};

export const getMeetingNoteDetailForProduct =
    (productId: number, backstopId: number)
        : ThunkAction<void, IMeetingNoteAction, null, Action<MeetingNoteActions>> => {
        return (dispatch) => {
            dispatch(meetingNoteDetailRequestedAction(backstopId));
            MeetingNotesApi.requestMeetingNoteDetailForProduct(productId, backstopId)
                .then((result: IApiResult<MeetingNoteDetail>) => {
                    dispatch(meetingNoteDetailReceivedAction(result.data!));
                }, (errorResponse) => {
                    dispatch(errorMeetingNoteDetail(errorResponse));
                });
        };
    };

export const getMeetingNoteDetailForManager =
    (managerId: number, backstopId: number)
        : ThunkAction<void, IMeetingNoteAction, null, Action<MeetingNoteActions>> => {
        return (dispatch) => {
            dispatch(meetingNoteDetailRequestedAction(backstopId));
            MeetingNotesApi.requestMeetingNoteDetailForManager(managerId, backstopId)
                .then((result: IApiResult<MeetingNoteDetail>) => {
                    dispatch(meetingNoteDetailReceivedAction(result.data!));
                }, (errorResponse) => {
                    dispatch(errorMeetingNoteDetail(errorResponse));
                });
        };
    };

export default {
    getMeetingNoteDetail,
    getMeetingNotePdf,
    getMeetingNoteDetailForProduct,
    getMeetingNoteDetailForManager,
};
