type LocalStorageKeys = "SessionData" | "LoggingOutTimestamp";

export const putItemInLocalStorage = (key: LocalStorageKeys, item: any) => {
    localStorage.setItem(key, item);
};

export const findElementById = (id: string): (HTMLElement | null) => {
    return document.getElementById(id);
};

export const getItemFromLocalStorage = (key: LocalStorageKeys): any => {
    return localStorage.getItem(key);
};

export const removeItemFromLocalStorage = (key: LocalStorageKeys): void => {
    localStorage.removeItem(key);
};

export const getLocationHostname = (): string => {
    return window.location.hostname;
};

export const setBrowserLocation = (url: string): void => {
    window.location.href = url;
};

export const getBrowserLocation = (): string => {
    return window.location.href;
};

export const getBrowserLocationPath = (): string => {
    return window.location.pathname;
};

export const getElementsByTagName = (parent: HTMLElement, tagName: string): HTMLCollectionOf<Element> => {
    return parent.getElementsByTagName(tagName) as HTMLCollectionOf<Element>;
};

export const getScrollY = (): number | null => {
    return window.scrollY;
};

export const getPageYOffset = (): number | null => {
    return window.pageYOffset;
};

export const getScrollTop = (): number | null => {
    return document.documentElement ? document.documentElement.scrollTop : null;
};

export const getWindowConfig = (): any =>  {
    return (window as any).config as any;
};

export const logError = (arg1: any, arg2?: any, arg3?: any) => {
    // eslint-disable-next-line
    console.error(arg1, arg2, arg3);
};

export const alertBox = (message: string) => {
    window.alert(message);
};
