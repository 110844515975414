import {Movement} from "../../utils/ratingConstants";
import {ComponentRating} from "../ComponentRating";
import {OperationalDueDiligenceRatings} from "./OperationalDueDiligenceRatings";
import {ProductRatingType} from "./ProductRatingType";

export class Product {
    public static builder(): ProductBuilder {
        return new ProductBuilder();
    }

    constructor(public backstopId: number,
                public name: string,
                public managerBackstopId: number,
                public businessRating: ComponentRating,
                public staffRating: ComponentRating,
                public processRating: ComponentRating,
                public riskRating: ComponentRating,
                public performanceAnalysisRating: ComponentRating,
                public tncRating: ComponentRating,
                public esgRating: ComponentRating | null,
                public currentRating: Rating,
                public previousRating: Rating | null,
                public overallRatingComments?: string,
                public oppDueDiligenceRatings?: OperationalDueDiligenceRatings,
                public monitoringPoints?: string,
                ) {
    }
}

export class ProductError {
    constructor(public errorNumber: number) {
    }
}

export class Rating {
    constructor(public value: string, public date: string, public ratingType: ProductRatingType) {
    }
}

export class ProductBuilder {
    private mBackstopId: number;
    private mName: string;
    private mOverallRatingComments?: string;
    private mBusinessRating: string | null;
    private mBusinessRatingComments: string | null;
    private mBusinessRatingPrevious: string | null;
    private mBusinessRatingMovement: Movement | null;
    private mStaffRating: string | null;
    private mStaffRatingComments: string | null;
    private mStaffRatingPrevious: string | null;
    private mStaffRatingMovement: Movement | null;
    private mProcessRating: string | null;
    private mProcessRatingComments: string | null;
    private mProcessRatingPrevious: string | null;
    private mProcessRatingMovement: Movement | null;
    private mRiskRating: string | null;
    private mRiskRatingComments: string | null;
    private mRiskRatingPrevious: string | null;
    private mRiskRatingMovement: Movement | null;
    private mOppDueDiligenceRating: string | null;
    private mOppDueDiligenceRatingComments: string | null;
    private mOppDueDiligenceRatingPrevious: string | null;
    private mOppDueDiligenceRatingEffectiveDate: string | null;
    private mOppDueDiligencePreviousRatingEffectiveDate: string | null;
    private mPerformanceAnalysisRating: string | null;
    private mPerformanceAnalysisRatingComments: string | null;
    private mPerformanceAnalysisRatingPrevious: string | null;
    private mPerformanceAnalysisRatingMovement: Movement | null;
    private mTncRating: string | null;
    private mTncRatingComments: string | null;
    private mTncRatingPrevious: string | null;
    private mTncRatingMovement: Movement | null;

    private mEsgRating: string | null;
    private mEsgRatingComments: string | null;
    private mEsgRatingPrevious: string | null;
    private mEsgRatingMovement: Movement | null;

    private mManagerBackstopId: number;
    private mCurrentRating: Rating;
    private mPreviousRating: Rating | null;

    private mMonitoringPoints?: string;

    public backstopId(value: number) {
        this.mBackstopId = value;
        return this;
    }

    public name(value: string) {
        this.mName = value;
        return this;
    }

    public overallRatingComments(value: string) {
        this.mOverallRatingComments = value;
        return this;
    }

    public businessRating(value: string | null) {
        this.mBusinessRating = value;
        return this;
    }

    public businessRatingComments(value: string | null) {
        this.mBusinessRatingComments = value;
        return this;
    }

    public businessRatingPrevious(value: string | null) {
        this.mBusinessRatingPrevious = value;
        return this;
    }

    public businessRatingMovement(value: Movement) {
        this.mBusinessRatingMovement = value;
        return this;
    }

    public staffRating(value: string | null) {
        this.mStaffRating = value;
        return this;
    }

    public staffRatingComments(value: string | null) {
        this.mStaffRatingComments = value;
        return this;
    }

    public staffRatingPrevious(value: string | null) {
        this.mStaffRatingPrevious = value;
        return this;
    }

    public staffRatingMovement(value: Movement) {
        this.mStaffRatingMovement = value;
        return this;
    }

    public processRating(value: string | null) {
        this.mProcessRating = value;
        return this;
    }

    public processRatingComments(value: string | null) {
        this.mProcessRatingComments = value;
        return this;
    }

    public processRatingPrevious(value: string | null) {
        this.mProcessRatingPrevious = value;
        return this;
    }

    public processRatingMovement(value: Movement) {
        this.mProcessRatingMovement = value;
        return this;
    }

    public riskRating(value: string | null) {
        this.mRiskRating = value;
        return this;
    }

    public riskRatingComments(value: string | null) {
        this.mRiskRatingComments = value;
        return this;
    }

    public riskRatingPrevious(value: string | null) {
        this.mRiskRatingPrevious = value;
        return this;
    }

    public riskRatingMovement(value: Movement) {
        this.mRiskRatingMovement = value;
        return this;
    }

    public oppDueDiligenceRating(value: string | null) {
        this.mOppDueDiligenceRating = value;
        return this;
    }

    public oppDueDiligenceRatingComments(value: string | null) {
        this.mOppDueDiligenceRatingComments = value;
        return this;
    }

    public oppDueDiligenceRatingPrevious(value: string | null) {
        this.mOppDueDiligenceRatingPrevious = value;
        return this;
    }

    public oppDueDiligenceRatingEffectiveDate(value: string | null) {
        this.mOppDueDiligenceRatingEffectiveDate = value;
        return this;
    }

    public oppDueDiligencePreviousRatingEffectiveDate(value: string | null) {
        this.mOppDueDiligencePreviousRatingEffectiveDate = value;
        return this;
    }

    public performanceAnalysisRating(value: string | null) {
        this.mPerformanceAnalysisRating = value;
        return this;
    }

    public performanceAnalysisRatingComments(value: string | null) {
        this.mPerformanceAnalysisRatingComments = value;
        return this;
    }

    public performanceAnalysisRatingPrevious(value: string | null) {
        this.mPerformanceAnalysisRatingPrevious = value;
        return this;
    }

    public performanceAnalysisRatingMovement(value: Movement) {
        this.mPerformanceAnalysisRatingMovement = value;
        return this;
    }

    public tncRating(value: string | null) {
        this.mTncRating = value;
        return this;
    }

    public tncRatingComments(value: string | null) {
        this.mTncRatingComments = value;
        return this;
    }

    public tncRatingPrevious(value: string | null) {
        this.mTncRatingPrevious = value;
        return this;
    }

    public tncRatingMovement(value: Movement) {
        this.mTncRatingMovement = value;
        return this;
    }

    public esgRating(value: string | null) {
        this.mEsgRating = value;
        return this;
    }

    public esgRatingComments(value: string | null) {
        this.mEsgRatingComments = value;
        return this;
    }

    public esgRatingPrevious(value: string | null) {
        this.mEsgRatingPrevious = value;
        return this;
    }

    public esgRatingMovement(value: Movement) {
        this.mEsgRatingMovement = value;
        return this;
    }

    public managerBackstopId(value: number = 0) {
        this.mManagerBackstopId = value;
        return this;
    }

    public currentRating(value: Rating) {
        this.mCurrentRating = value;
        return this;
    }

    public previousRating(value: Rating) {
        this.mPreviousRating = value;
        return this;
    }

    public monitoringPoints(value: string) {
        this.mMonitoringPoints = value;
        return this;
    }

    public build() {
        const businessRating = new ComponentRating(
            this.mBusinessRating || null,
            this.mBusinessRatingComments || null,
            this.mBusinessRatingPrevious || null,
            this.mBusinessRatingMovement || Movement.NO_CHANGE,
        );
        const staffRating = new ComponentRating(
            this.mStaffRating || null,
            this.mStaffRatingComments || null,
            this.mStaffRatingPrevious || null,
            this.mStaffRatingMovement || Movement.NO_CHANGE,
        );
        const processRating = new ComponentRating(
            this.mProcessRating || null,
            this.mProcessRatingComments || null,
            this.mProcessRatingPrevious || null,
            this.mProcessRatingMovement || Movement.NO_CHANGE,
        );
        const riskRating = new ComponentRating(
            this.mRiskRating || null,
            this.mRiskRatingComments || null,
            this.mRiskRatingPrevious || null,
            this.mRiskRatingMovement || Movement.NO_CHANGE,
        );
        const performanceAnalysisRating = new ComponentRating(
            this.mPerformanceAnalysisRating || null,
            this.mPerformanceAnalysisRatingComments || null,
            this.mPerformanceAnalysisRatingPrevious || null,
            this.mPerformanceAnalysisRatingMovement || Movement.NO_CHANGE,
        );
        const termsAndConditionsRating = new ComponentRating(
            this.mTncRating || null,
            this.mTncRatingComments || null,
            this.mTncRatingPrevious || null,
            this.mTncRatingMovement || Movement.NO_CHANGE,
        );
        const esgRating = new ComponentRating(
            this.mEsgRating || null,
            this.mEsgRatingComments || null,
            this.mEsgRatingPrevious || null,
            this.mEsgRatingMovement || Movement.NO_CHANGE,
        );

        const operationalDueDiligence = new OperationalDueDiligenceRatings(
            this.mOppDueDiligenceRating || null,
            this.mOppDueDiligenceRatingComments || null,
            this.mOppDueDiligenceRatingPrevious || null,
            this.mOppDueDiligenceRatingEffectiveDate || null,
            this.mOppDueDiligencePreviousRatingEffectiveDate || null,
        );

        const currentRating = new Rating("", "", ProductRatingType.FUNDAMENTAL);

        return new Product(this.mBackstopId || -1,
            this.mName || "",
            this.mManagerBackstopId,
            businessRating,
            staffRating,
            processRating,
            riskRating,
            performanceAnalysisRating,
            termsAndConditionsRating,
            esgRating,
            this.mCurrentRating || currentRating,
            this.mPreviousRating || null,
            this.mOverallRatingComments,
            operationalDueDiligence,
            this.mMonitoringPoints || "");
    }
}
