import {AxiosResponse} from "axios";
import {saveAs} from "file-saver";
import {Map} from "immutable";
import {mapFlashReportSummaryWithProducts} from "../components/flash-report/FlashReportMapper";
import {FlashReport} from "../model/FlashReport.model";
import {FlashReportSummary} from "../model/FlashReportSummary.model";
import {getMimeType} from "../utils/fileUtil";
import {ApiBase} from "./ApiBase";
import {IApiResult} from "./AxiosWealthManagementApi";

class FlashReportsApiImpl {
    public requestAllFlashReportSummariesMap(): Promise<IApiResult<Map<number, FlashReportSummary>>> {
        return ApiBase.processGet("/flash-reports/all",
            (response: AxiosResponse): any => {
                return (Object as any).entries(response.data)
                    .reduce(this.flashReportSummaryReducer, Map());
            });
    }

    public requestFlashReportById(backstopId: number): Promise<IApiResult<FlashReport>> {
        return ApiBase.processGet(
            `/flash-reports/${backstopId}`,
            (response: AxiosResponse) => {
                const flashReport = response.data;
                return new FlashReport(flashReport.backstopId, flashReport.title, flashReport.descriptionHtml);
            });
    }

    public requestPdf(backstopId: number, fileName: string): Promise<IApiResult<void>> {
        return ApiBase.processGet(`/flash-report/${backstopId}/pdf`,
            (response: AxiosResponse) => {
                const blob = new Blob([response.data], {type: getMimeType(fileName)});
                saveAs(blob, fileName);
            },
            {responseType: "arraybuffer"});
    }

    private flashReportSummaryReducer = (all: Map<number, FlashReportSummary>, [key, value]: (any)[]) => {
        return all.set(parseInt(key, 10), mapFlashReportSummaryWithProducts(value));
    };

}

export const FlashReportsApi = new FlashReportsApiImpl();
