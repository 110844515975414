import * as React from "react";
import {Link} from "react-router-dom";

export class ErrorComponent extends React.Component {
    public render() {
        return (
            <div className={"error-page"} data-testid="error-page">
                <div className="main-content">
                    <h1 className="error-header">We can't find the page you're looking for.</h1>
                    <br/>
                    Consider us downgraded.
                    <br/><br/>
                    If you see this page repeatedly or arrived here from an email, please <Link
                    to="/feedback" className="feedback-page-link">visit our feedback page</Link> to let us know.
                </div>
            </div>
        );
    }
}
