import * as React from "react";
import {useEffect, useState} from "react";
import {generatePercentagesFromResponseSummary, IOddProductWithPercentSummary} from "../OddIqUtils";
import {IOddProduct, IOddResponseSummary, OddIqApi} from "../../../api/OddIqApi";
import {compareDates, getShortMonthAndYearString, parseDate} from "../../../utils/dateUtil";
import {byName, compareInsensitive, INamed} from "../../../utils/listUtil";
import {Link} from "react-router-dom";
import {Map as ImmutableMap} from "immutable";

export enum SortCategory {
    EXCEPTIONS = "exceptions",
    BEST_PRACTICE = "bestPractice",
    NO_DATA = "noData",
    RATING = "Rating",
    STRATEGY = "Strategy",
    ASSET_CLASS = "assetClass",
    RATING_DATE = "ratingDate",
    MANAGER_RESPONSE = "responseDate",
    MANAGER_NAME = "managerName",
}

export const QuestionSummaryCategory = ["exceptions", "bestPractice", "noData"] as const;
export type QuestionCategory = typeof QuestionSummaryCategory[number];

export interface ISummaryByOddProductProps {
    oddProducts: IOddProduct[];
}
export const SummaryByOddProduct: React.FunctionComponent<ISummaryByOddProductProps> = (props) => {
    const [sortCategory, setSortCategory] = useState({category: SortCategory.EXCEPTIONS, descending: true});

    const[summariesByProduct, setSummariesByProduct] =
        useState<ImmutableMap<number, IOddResponseSummary> | undefined>(undefined);

    useEffect(() => {
        OddIqApi.requestOddSummariesByProduct()
            .then((response) => setSummariesByProduct(response));
    }, []);

    const getProductsWithSummaryPercents = (): IOddProductWithPercentSummary[] => {
        return  props.oddProducts.
        reduce((acc: IOddProductWithPercentSummary[], product) => {
            const questionPercentSummary = generatePercentagesFromResponseSummary(summariesByProduct!.get(product.id)!);
            acc[acc.length] = {oddProduct: product, questionPercentSummary};
            return acc;
        }, []);
    };

    function renderTitleAndArrow(category: SortCategory, displayName: string) {
        let name = <div className="clickable">{displayName}</div>;
        let arrow = <div/>;

        if (category === sortCategory.category) {
            name = <div className="header-text color-teal clickable">{displayName}</div>;
            if (sortCategory.descending) {
                arrow = <div className="fal fa-arrow-down"/>;
            } else {
                arrow = <div className="fal fa-arrow-up"/>;
            }
        }

        return <div className="odd-product-summary__header">
            {name}
            {arrow}
        </div>;
    }

    function sortOnCategory(category: SortCategory) {
        let descending = true;

        if (sortCategory.category === category) {
            descending = !sortCategory.descending;
        }

        setSortCategory({category, descending});
    }

    const convertToFirstDayOfMonth = (dateString?: string): Date | null => {
        return dateString
            ? new Date (
                parseDate(dateString).getFullYear(),
                parseDate(dateString).getMonth(),
                1, 0, 0, 0)
            : null;
    };

    const byExceptions = (ps1:IOddProductWithPercentSummary, ps2:IOddProductWithPercentSummary): number => {
       return ps2.questionPercentSummary.exceptions - ps1.questionPercentSummary.exceptions;
    };

    const byManagerName = (reverseSort: number) =>
        (ps1:IOddProductWithPercentSummary, ps2:IOddProductWithPercentSummary): number => {
        const name1 = ps1.oddProduct.managerName ? ps1.oddProduct.managerName : "";
        const name2 = ps2.oddProduct.managerName ? ps2.oddProduct.managerName : "";
        return name1 === name2
            ? byExceptions(ps1, ps2)
            : reverseSort * (compareInsensitive(name1, name2));
    };

    const byProductName = (reverseSort: number) =>
        (ps1:IOddProductWithPercentSummary, ps2:IOddProductWithPercentSummary): number => {
        return reverseSort * (byName( ps1.oddProduct as INamed, ps2.oddProduct as INamed));
    };

    const byPercentSummary = (reverseSort: number) =>
        (ps1:IOddProductWithPercentSummary, ps2:IOddProductWithPercentSummary): number => {
            const category = sortCategory.category as QuestionCategory;
            return ps2.questionPercentSummary[category] === ps1.questionPercentSummary[category]
                ? byProductName(1)(ps1, ps2)
                : reverseSort * (ps2.questionPercentSummary[category] - ps1.questionPercentSummary[category]);
    };

    const byDateResponded = (reverseSort: number) =>
        (ps1:IOddProductWithPercentSummary, ps2:IOddProductWithPercentSummary): number => {
            const date1 = convertToFirstDayOfMonth(ps1.oddProduct.dateResponded);
            const date2 = convertToFirstDayOfMonth(ps2.oddProduct.dateResponded);
            return date1 === date2 || date1?.getTime() === date2?.getTime()
                ? ps2.questionPercentSummary.exceptions - ps1.questionPercentSummary.exceptions
                : reverseSort * (compareDates(date1, date2));
    };

    const getComparator = (ps1:IOddProductWithPercentSummary, ps2:IOddProductWithPercentSummary) => {
        const reverseSort = sortCategory.descending ? 1 : -1;

        if (QuestionSummaryCategory.includes(sortCategory.category as QuestionCategory)) {
           return byPercentSummary(reverseSort)(ps1, ps2);
        } else if (sortCategory.category === SortCategory.MANAGER_NAME) {
           return byManagerName(reverseSort)(ps1, ps2);
        } else if (sortCategory.category === SortCategory.MANAGER_RESPONSE) {
            return byDateResponded(reverseSort)(ps1, ps2);
        } else {
            return byProductName(reverseSort)(ps1, ps2);
        }
    };

    function renderProductDataTable() {
        return getProductsWithSummaryPercents()
            .sort(getComparator)
            .map((productWithSummary: IOddProductWithPercentSummary) => {
                const oddProduct = productWithSummary.oddProduct;
                const summary = productWithSummary.questionPercentSummary;

                return <tr key={oddProduct.id} className="odd-summary__product-summary-row" data-testid="product-summary-row">
                        <td>
                            <Link className="clickable odd-summary__product-link" to={`/odd-products/${oddProduct.vehicleId}`}>
                                <span className="odd-summary__product-name">{oddProduct.name}</span>
                            </Link>
                        </td>
                        <td>{oddProduct.managerName ? oddProduct.managerName : ""}</td>
                        <td>{summary.exceptions}%</td>
                        <td>{summary.bestPractice}%</td>
                        <td>{summary.noData}%</td>
                        <td>{oddProduct.dateResponded
                            ? getShortMonthAndYearString(parseDate(oddProduct.dateResponded))
                            : "N/A"}
                        </td>
                    </tr>;
            });
    }

    return summariesByProduct
        ? <div id="odd-product-summary__container">
        <h3 className="odd-product-summary__table-header">Portfolio Summary</h3>
            <div id="odd-product-summary__table-container">
                <table>
                    <thead>
                    <tr className="odd-product-summary__column-headers">
                        <th className="small bold strategy-header header-start"
                            onClick={() => sortOnCategory(SortCategory.STRATEGY)}>
                            {renderTitleAndArrow(SortCategory.STRATEGY, "Strategy")}
                        </th>
                        <th className="small bold manager-name-header"
                            onClick={() => sortOnCategory(SortCategory.MANAGER_NAME)}>
                            {renderTitleAndArrow(SortCategory.MANAGER_NAME, "Manager Name")}
                        </th>
                        <th className="small bold exceptions-header"
                            onClick={() => sortOnCategory(SortCategory.EXCEPTIONS)}>
                            {renderTitleAndArrow(SortCategory.EXCEPTIONS, "Exceptions")}
                        </th>
                        <th className="small bold best-practice-header"
                            onClick={() => sortOnCategory(SortCategory.BEST_PRACTICE)}>
                            {renderTitleAndArrow(SortCategory.BEST_PRACTICE, "Best Practice")}
                        </th>
                        <th className="small bold no-data-header"
                            onClick={() => sortOnCategory(SortCategory.NO_DATA)}>
                            {renderTitleAndArrow(SortCategory.NO_DATA, "No Data")}
                        </th>
                        <th className="small bold manager-response-header"
                            onClick={() => sortOnCategory(SortCategory.MANAGER_RESPONSE)}>
                            {renderTitleAndArrow(SortCategory.MANAGER_RESPONSE, "Manager Response")}
                        </th>
                    </tr>
                    </thead>
                    <tbody>
                    {renderProductDataTable()}
                    </tbody>
                </table>
            </div>
        </div>
        : null;
};