import {Set} from "immutable";
import * as React from "react";
import {Link} from "react-router-dom";
import {IHolding} from "../../../api/HoldingsApi";

export interface IHoldingsProps {
    holdings: IHolding[];
}

export class HoldingsComponent extends React.Component<IHoldingsProps> {

    private static renderHolding(holding: IHolding, index: number) {
        const rowClassName = `holdings-component__holding${(index % 2 === 0) ? " even" : ""}`;

        return <tr className={rowClassName} key={holding.name + holding.asOfDate}>
            <td>{HoldingsComponent.renderHoldingName(holding)}</td>
            <td><div className="holdings-component__holding-mkt-value">
                {HoldingsComponent.numberWithCommas(Math.round(holding.assets))}
            </div></td>
        </tr>;
    }

    private static numberWithCommas(x: any) {
        return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
    }

    private static toTitleCase(str: string) {
        return str.replace(
            /\w\S*/g,
            (txt) => {
                return txt.charAt(0).toUpperCase() + txt.substr(1).toLowerCase();
            },
        );
    }

    private static renderHoldingName(holding: IHolding) {
        const className = "holdings-component__holding-name";
        return (holding.productId !== null)
            ? <Link className={className} to={`/products/${holding.productId}`}>{holding.name}</Link>
            : <div className={className}>{holding.name}</div>
            ;
    }

    public render() {
        return <div className="holdings-component">
            <table className="holdings-component__data">
                <tbody>
                    <tr className="holdings-component__table-header">
                        <th><div className="holdings-component__strategies-header">
                            STRATEGIES BY ASSET CLASS
                        </div></th>
                        <th><div className="holdings-component__MKT-value-header">MKT VALUE ($)*</div></th>
                    </tr>
                    {
                        this.getSumsByAsset()
                            .map(({assetClassName, sum}: { assetClassName: string; sum: number; }) =>
                                [
                                    this.renderHeaderForAssetClass(assetClassName, sum),
                                    this.renderHoldingsForAssetClass(assetClassName),
                                ],
                            )
                    }
                </tbody>
            </table>
            <div className="holdings-component__disclaimer">
                {`*Values provided by Fidelity.`}
            </div>
        </div>;
    }

    private getSumsByAsset() {
        return Set(this.props.holdings.map((it) => it.assetClass))
            .map((assetClass: string) => {
                return {
                    assetClassName: HoldingsComponent.toTitleCase(assetClass),
                    sum: this.getHoldingsSumForAssetClass(assetClass),
                };
            })
            .sort((valueA, valueB) => valueB.sum - valueA.sum);
    }

// noinspection JSMethodCanBeStatic
    private renderHeaderForAssetClass(assetClassName: any, sum: any) {
        return (
            <tr className="holdings-component__asset-class-row" key={`${assetClassName}-row`}>
                <td className="holdings-component__asset-class-name" key={`${assetClassName}-name`}>
                    {assetClassName}
                </td>
                <td className="holdings-component__asset-class-sum" key={`${assetClassName}-sum`}>
                    {HoldingsComponent.numberWithCommas(sum)}
                </td>
            </tr>);
    }

    private getHoldingsSumForAssetClass(assetClass: string) {
        return Math.round(
            this.props.holdings
                .filter((holding) => holding.assetClass === assetClass)
                .map((holding) => holding.assets)
                .reduce((asset1, asset2) => asset1 + asset2),
        );
    }

    private renderHoldingsForAssetClass(assetClass: string) {
       return this.props.holdings
           .filter((holding) => holding.assetClass.toUpperCase() === assetClass.toUpperCase())
           .sort((h1, h2) => h2.assets - h1.assets)
           .map((it, index) => HoldingsComponent.renderHolding(it, index));
    }
}
