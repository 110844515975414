import {Menu} from "@mui/material";
import {MenuItem} from "@mui/material";
import * as React from "react";
import {ChangeEvent} from "react";
import {SharedDocumentType} from "../../model/SharedDocument.model";
import {UploadFile} from "../../model/UploadFile.model";
import {applyMenuButtonStyle} from "../common/buttonStyles";
import {RaisedButton} from "../common/RaisedButton";
import {getDateByQuarter, getQuarterAndYearByDate, getQuarters, getRecentYears, Quarter} from "../../utils/dateUtil";
import {DialogComponent} from "../common/Dialog.component";
import {ISelectValue, SelectComponent} from "../common/Select.component";
import {TextFieldComponent} from "../common/TextField.component";
import {FormattedMessage} from "react-intl";
import {formattedMessageTransform, getTranslation} from "../../utils/translationUtil";
import {Checkbox} from "@mui/material";
import {AonColors} from "../../../../css/Colors";

export interface IUploadFileProps {
    selectedFile: UploadFile;
    documentTypes: SharedDocumentType[];
    onFilePropChange: (updatedFile: UploadFile) => void;
    submitClicked: boolean;
    onApplyDocumentTypeToAll: (docTypeIdx: number) => void;
    onApplyDateToAll: (date: Date) => void;
    onDelete: (selectedFile: UploadFile) => void;
    showThirdPartyAccessColumn: boolean;
}

export interface IUploadFileState {
    quarters: ISelectValue[];
    years: ISelectValue[];
    quarter: Quarter | null;
    year: number | null;
    anchorEl: null;
    dialogOpen: boolean;
    thirdPartyAccess: boolean;
}

export class UploadFileComponent extends React.Component<IUploadFileProps, IUploadFileState> {

    public constructor(props: any) {
        super(props);

        this.state = {
            quarters: [],
            years: [],
            quarter: null,
            year: null,
            anchorEl: null,
            dialogOpen: false,
            thirdPartyAccess: false
        };
    }

    public componentDidMount() {
        this.setState({
            years: getRecentYears(),
            quarters: getQuarters(),
        });
    }

    public componentDidUpdate(prevProps: IUploadFileProps) {
        if (prevProps.selectedFile.date !== this.props.selectedFile.date) {
            const {quarter, year} = this.props.selectedFile.date
                ? getQuarterAndYearByDate(this.props.selectedFile.date)
                : {quarter: null, year: null}
            ;
            this.setState({quarter, year});
        }
    }

    public render() {

        return (
            <tr key={this.props.selectedFile.key}>
                <td style={{paddingLeft: 0}}>
                    <div className="shared-document-list-combined remove-icon fa-regular fa-x" onClick={this.handleRemoveClick}/>
                    {this.renderRemoveFileDialog()}
                </td>
                <td>
                    <div className="shared-document-list-combined filename">
                        {this.props.selectedFile.file.name}
                    </div>
                </td>
                <td>
                    <div className="shared-document-list-combined field title">
                        {this.renderFileTitle()}
                    </div>
                </td>
                <td>
                    <div className="shared-document-list-combined field document_type">
                        {this.renderTypeDropDown()}
                    </div>
                </td>
                <td>
                    {this.renderTimePeriod()}
                </td>
                <td>
                    <div className="shared-document-list-combined apply_to_all">
                        {this.renderApplyToAll()}
                    </div>
                </td>
                { this.renderThirdPartyAccessCheckbox() }
            </tr>
        );
    }

    private renderRemoveFileDialog() {
        return <div className="upload-page__confirm-remove-dialog">
            <DialogComponent
                title="Remove this file?"
                noText="No, keep it"
                onNo={() => {
                    this.setState({dialogOpen: false});
                }}
                yesText="Yes, remove"
                onYes={() => {
                    this.props.onDelete(this.props.selectedFile);
                }}
                open={this.state.dialogOpen}
                description="It won't be included in your upload."
            />
        </div>;
    }

    private renderFileTitle() {
        return <TextFieldComponent
            id="title"
            name="title"
            placeholder="Enter a title for the document"
            className="title-input"
            type="text"
            fullWidth={true}
            value={this.props.selectedFile.title}
            handleChange={this.handleTitleChange}
            renderRequired={false}
        />;
    }

    private renderTypeDropDown() {
        return <SelectComponent
            id={"upload-file__document-type"}
            values={this.props.documentTypes}
            selected={this.props.selectedFile.selectedDocumentType
                ? this.props.selectedFile.selectedDocumentType
                : undefined}
            width={260}
            submitClicked={this.props.submitClicked}
            handleChange={this.handleDocumentTypeChange}
            placeholder={getTranslation("upload.document-type", "Document Type")}
            renderRequired={true}
            menuItemTransform={formattedMessageTransform}
        />;
    }

    private renderTimePeriod() {
        return <div className="shared-document-list-combined time_period">
            <div className="shared-document-list-combined field year">
                <SelectComponent
                    values={this.state.years}
                    selected={this.state.year ? this.state.year : undefined}
                    width={100}
                    submitClicked={this.props.submitClicked}
                    handleChange={this.handleTimePeriodYearChange}
                    id={"upload-file__date-year"}
                    placeholder={getTranslation("upload.year","Year")}
                    renderRequired={true}
                    selectMaxHeight={120}
                />
            </div>
            <div className="shared-document-list-combined field quarter">
                <SelectComponent
                    values={this.state.quarters}
                    selected={this.state.quarter ? this.state.quarter : undefined}
                    width={100}
                    submitClicked={this.props.submitClicked}
                    handleChange={this.handleTimePeriodQuarterChange}
                    id={"upload-file__date-quarter"}
                    placeholder={getTranslation("upload.quarter","Quarter")}
                    renderRequired={true}
                />
            </div>
        </div>;
    }

    private renderApplyToAllMenu() {
        return (
            <div>
                <RaisedButton
                    primary={false}
                    style={applyMenuButtonStyle}
                    onClick={this.handleClick}
                    aria-owns={this.state.anchorEl ? "simple-menu" : undefined}
                    aria-haspopup="true"
                >
                    <div className="apply-all-menu-button fa-regular fa-ellipsis-vertical"/>
                </RaisedButton>
                <Menu
                    id="simple-menu"
                    anchorEl={this.state.anchorEl}
                    open={Boolean(this.state.anchorEl)}
                    onClose={this.handleClose}
                >
                    <MenuItem onClick={this.handleApplyDocType}>
                        <FormattedMessage id="upload.type-to-all" defaultMessage="Apply Document Type to All" />
                    </MenuItem>
                    <MenuItem onClick={this.handleApplyTimePeriod}>
                        <FormattedMessage id="upload.period-to-all" defaultMessage="Apply Time Period to All" />
                    </MenuItem>
                </Menu>
            </div>
        );
    }

    private renderThirdPartyAccessCheckbox() {
        return this.props.showThirdPartyAccessColumn
            ? <td>
                <div className="shared-document-list-combined field third_party_access">
                    <Checkbox
                        className={`uploadThirdPartyAccessCheckbox`}
                        onChange={this.handleThirdPartyAccess}
                        checked={this.state.thirdPartyAccess}
                        style={{
                            color: this.state.thirdPartyAccess ? AonColors.AonTealDark : ""
                        }}
                    />
                </div>
            </td>
            : null;
    }

    private handleThirdPartyAccess = (event: any) => {
        const checkedValue = event.target.checked;
        this.setState({thirdPartyAccess: checkedValue});
        this.props.onFilePropChange(
            {...this.props.selectedFile, thirdPartyAccess: checkedValue}
        );
    };

    private handleTimePeriodQuarterChange = (e: any) => {
        this.setState({...this.state, quarter: e.target.value}, () => {
            this.handleTimePeriodChange();
        });
    };

    private handleTimePeriodYearChange = (e: any) => {
        this.setState({...this.state, year: e.target.value}, () => {
            this.handleTimePeriodChange();
        });
    };
    private handleApplyTimePeriod = () => {
        this.resetAnchorEl();
        if (this.props.selectedFile.date) {
            this.props.onApplyDateToAll(this.props.selectedFile.date!);
        }
    };

    private handleClick = (event: any) => {
        this.setState({...this.state, anchorEl: event.currentTarget});
    };

    private handleClose = () => {
        this.resetAnchorEl();
    };

    private handleApplyDocType = () => {
        this.resetAnchorEl();
        if (this.props.selectedFile.selectedDocumentType) {
            this.props.onApplyDocumentTypeToAll(this.props.selectedFile.selectedDocumentType!);
        }
    };

    private handleRemoveClick = () => {
        this.setState({dialogOpen: true});
    };

    private handleTitleChange = (e: ChangeEvent<HTMLInputElement | HTMLTextAreaElement | HTMLSelectElement>) => {
        this.props.onFilePropChange({...this.props.selectedFile, title: e.target.value});
    };

    private handleDocumentTypeChange = (e: any) => {
        this.props.onFilePropChange({...this.props.selectedFile, selectedDocumentType: e.target.value});
    };

    private renderApplyToAll() {
        return this.renderApplyToAllMenu();
    }

    private handleTimePeriodChange() {
        if (!this.state.quarter || !this.state.year) {
            return;
        }
        const date = getDateByQuarter(this.state.quarter!, this.state.year!);
        this.props.onFilePropChange({...this.props.selectedFile, date});
    }

    private resetAnchorEl() {
        this.setState({...this.state, anchorEl: null});
    }
}

export default UploadFileComponent;
