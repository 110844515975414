import * as React from "react";
import {useEffect, useState} from "react";
import {CalendarApi} from "../../api/CalendarApi";
import {CALENDAR_VIEW} from "./CalendarPage";
import {CalendarMeetingInfo} from "./CalendarMeetingInfo";
import {CalendarMeetingDetails} from "../../model/CalendarMeetingDetails.model";
import CalendarMeetingAttendees from "./CalendarMeetingAttendees";

export interface ICalendarMeetingProps {
    meetingId: number;
    onViewChange: (meetingOrTopicId: number | undefined, view: CALENDAR_VIEW) => void;
}

export enum MeetingSubNav {
    INFO = "Info",
    ATTENDEES = "Attendees",
    AGENDA = "Agenda (Topics)",
    DELIVERABLES = "Deliverables",
    MINUTES = "Minutes",
}

export const CalendarMeeting: React.FunctionComponent<ICalendarMeetingProps> = (props) => {
    const [meetingDetails, setMeetingDetails] = useState<CalendarMeetingDetails | undefined>(undefined);
    const [currentSubNav, setCurrentSubNav] = useState<MeetingSubNav>(MeetingSubNav.INFO);

    const requestMeetingDetails = () => {
        CalendarApi.requestCalendarMeetingDetails(props.meetingId!)
            .then((meetingResponse) => {
                setMeetingDetails(meetingResponse);
            });
    };

    useEffect(() => {
        requestMeetingDetails();
    }, []);

    const renderSubNavs = () => {
        return <div className="calendar-meeting__sub-navs">
            <ul>
                {Object.values(MeetingSubNav).map((item, index) => {
                    return <li key={index}
                               className="clickable calendar-meeting__sub-nav-item"
                               onClick={() => setCurrentSubNav(item)}>
                        {item}
                    </li>;
                })}
            </ul>
        </div>;
    };

    const renderNavItem = (currentSubNav: MeetingSubNav) => {
        if (!meetingDetails) return null;

        switch (currentSubNav) {
            case (MeetingSubNav.INFO) :
                return <CalendarMeetingInfo
                    meetingDetails ={meetingDetails}
                    onViewChange={onViewChange}
                    onSave={requestMeetingDetails}
                />;
            case (MeetingSubNav.ATTENDEES) :
                return <CalendarMeetingAttendees meetingId={props.meetingId}/>;
            default:
                return null;
        }
    };

    const renderSubNavContent = () => {
        return <div className="calendar-meeting__sub-nav-container">
            <h3 className="calendar-meeting__nav-header">{currentSubNav}</h3>
            {renderNavItem(currentSubNav)}
        </div>;
    };

    const onViewChange = () => {
        props.onViewChange(undefined, CALENDAR_VIEW.CALENDAR);
    };

    return <div className="calendar-meeting">
        <div className="calendar__breadcrumbs">
            <p className="clickable back-to-calendar breadcrumb__item"
               onClick={() => props.onViewChange(undefined, CALENDAR_VIEW.CALENDAR)}>
                Calendar
            </p>

            <p className="calendar-meeting__meeting-title breadcrumb__item">
                {`Q${meetingDetails?.quarter}-${meetingDetails?.year}`} Meeting
            </p>
        </div>
        <div className="calendar-meeting__body">
            {renderSubNavs()}
            {renderSubNavContent()}
        </div>
    </div>;
};