import {Action, handleActions} from "redux-actions";
import {IApiResult} from "../../api/AxiosWealthManagementApi";
import {ApiError} from "../../model/ApiError.model";
import {IInReportGroup, InReportSummary} from "../../model/product/inreports/InReportSummary.model";
import {Product, ProductError} from "../../model/product/Product.model";
import {ProductTicker} from "../../model/product/ProductTicker";
import {IRecentUpdates} from "../../model/recent-updates/RecentUpdates";
import {throwErrorOnNullOrUndefined} from "../../utils/errorUtil";
import {NOT_REQUESTED, REQUEST_FAILED, REQUEST_SUCCEEDED, REQUESTED, RequestState} from "../common/commonStates";
import {ProductActions, TickerSelection} from "./productActions";
import {ProductPageTab} from "./ProductPage";

export interface IProductState {
    readonly product?: Product;
    readonly productLoading?: boolean;
    readonly productError?: ApiError;
    readonly recentUpdates?: IRecentUpdates;
    readonly selectedTicker?: TickerSelection | null;
    readonly mostRecentInBrief?: IApiResult<InReportSummary>;
    readonly mostRecentInBriefLoading?: boolean;
    readonly mostRecentInTotal?: IApiResult<InReportSummary>;
    readonly mostRecentInTotalLoading?: boolean;
    readonly inBriefError?: ApiError;
    readonly inTotalError?: ApiError;
    readonly tickers: RequestState<ProductTicker[]>;
    readonly mostRecentInReports: RequestState<IInReportGroup>;
    currentTab?: ProductPageTab;
}

export const initialState: IProductState = {
    product: undefined,
    productLoading: undefined,
    productError: undefined,
    selectedTicker: undefined,
    mostRecentInBrief: undefined,
    mostRecentInBriefLoading: false,
    mostRecentInTotal: undefined,
    mostRecentInTotalLoading: false,
    inBriefError: undefined,
    inTotalError: undefined,
    tickers: NOT_REQUESTED,
    mostRecentInReports: NOT_REQUESTED,
};

const productReceived = (state: IProductState, action: Action<Product>): IProductState => {
    return {
        ...state,
        product: throwErrorOnNullOrUndefined(action.payload, "action.payload"),
        productLoading: false,
    };
};

const reducerMap = {
    [ProductActions.PRODUCT_RECEIVED]: productReceived,
    [ProductActions.PRODUCT_REQUESTED]: (state: IProductState): IProductState => {
        return {
            ...state,
            productLoading: true,
        };
    },
    [ProductActions.PRODUCT_ERROR_RECEIVED]: (state: IProductState, action: Action<ProductError>): IProductState => {
        return {
            ...state,
            productLoading: false,
            productError: throwErrorOnNullOrUndefined(action.payload, "action.payload"),
        };
    },
    [ProductActions.PRODUCT_TICKER_SELECTED]:
        (state: IProductState, {payload}: Action<TickerSelection>): IProductState => {
            return {
                ...state,
                selectedTicker: payload,
            };
        },
      [ProductActions.TICKER_DATA_REQUESTED]:
        (state: IProductState): IProductState => {
            return {...state, tickers: REQUESTED};
        },
    [ProductActions.REQUEST_TICKER_DATA_FAILED]:
        (state: IProductState, action: Action<any>): IProductState => {
          return {...state, tickers: REQUEST_FAILED(action.payload)};
        },
    [ProductActions.REQUEST_TICKER_DATA_SUCCEEDED]:
        (state: IProductState, action: Action<any>): IProductState => {
            return {...state, tickers: REQUEST_SUCCEEDED(action.payload)};
        },
    [ProductActions.CLEAR_TICKER_DATA]:
        (state: IProductState): IProductState => {
            return {...state, tickers: NOT_REQUESTED};
        },
    [ProductActions.MOST_RECENT_INREPORT_SUMMARY_REQUESTED]:
        (state: IProductState): IProductState => {
            return {...state, mostRecentInReports: REQUESTED};
        },
    [ProductActions.MOST_RECENT_INREPORT_SUMMARY_ERROR_RECEIVED]:
        (state: IProductState, action: Action<any>): IProductState => {
          return {...state, mostRecentInReports: REQUEST_FAILED(action.payload)};
        },
    [ProductActions.MOST_RECENT_INREPORT_SUMMARY_RESOLVED]:
        (state: IProductState, action: Action<any>): IProductState => {
            return {...state, mostRecentInReports: REQUEST_SUCCEEDED(action.payload)};
        },
    [ProductActions.CLEAR_RECENT_INREPORT]:
        (state: IProductState): IProductState => {
            return {...state, mostRecentInReports: NOT_REQUESTED};
        },
    [ProductActions.SET_CURRENT_PRODUCT_TAB]:
        (state: IProductState, action: Action<ProductPageTab>): IProductState => {
            return {...state, currentTab: action.payload};
        },
};

export function mostRecentInBriefInner(state: IProductState | undefined): InReportSummary | undefined {
    if (state && state.mostRecentInBrief) {
        return state.mostRecentInBrief.data as InReportSummary;
    }
}

export function mostRecentInTotalInner(state: IProductState | undefined): InReportSummary | undefined {
    if (state && state.mostRecentInTotal) {
        return state.mostRecentInTotal.data as InReportSummary;
    }
}

export function mostRecentInBriefLoadingInner(state: IProductState | undefined): boolean | undefined {
    if (state && state.mostRecentInBriefLoading !== undefined) {
        return state.mostRecentInBriefLoading;
    }
}

export function mostRecentInTotalLoadingInner(state: IProductState | undefined): boolean | undefined {
    if (state && state.mostRecentInTotalLoading !== undefined) {
        return state.mostRecentInTotalLoading;
    }
}

export function mostRecentInReportsInner(state: IProductState | undefined): RequestState<IInReportGroup> {
    return state?.mostRecentInReports ?? NOT_REQUESTED;
}

export function tickersInner(state: IProductState | undefined): RequestState<ProductTicker[]> {
    if (state) {
        return state.tickers;
    } else {
        return NOT_REQUESTED;
    }
}

export function currentProductPageTabInner (state?: IProductState | undefined): ProductPageTab | undefined {
    return state ? state.currentTab : undefined;
}

type PayloadTypes = Product
    | ProductError
    | ApiError
    | TickerSelection
    | IApiResult<InReportSummary>
    | boolean
    | ProductPageTab;

export default handleActions<IProductState, PayloadTypes>(reducerMap, initialState);
