import * as React from "react";
import {IClientPlanInfo} from "../../model/ClientsAndPlans.model";
import {Link} from "react-router-dom";

export interface IAdminAllClientsTable {
    clientsAndPlans: IClientPlanInfo[]
}

export const AdminAllClientsTable: React.FunctionComponent<IAdminAllClientsTable> = (props: IAdminAllClientsTable) => {
    const renderClientPlan = (clientPlan: IClientPlanInfo) => {
        return <tr className="admin-all-clients__client-plan" key={clientPlan.planId}>
            <td className="admin-all-clients__client-name">
                <Link to={`/admin/client/${clientPlan.clientId}`} id={`edit-client-link-${clientPlan.clientId}`}>
                    {clientPlan.clientName}
                </Link>
            </td>
            <td className="admin-all-clients__plan-name">
                <Link to={`/admin/plan/${clientPlan.planId}`} id={`edit-plan-link-${clientPlan.planId}`}>
                    {clientPlan.planName}
                </Link>
            </td>
            <td className="admin-all-clients__country-name">{clientPlan.country}</td>
            <td className="admin-all-clients__language">{clientPlan.language}</td>
            <td className="admin-all-clients__powerbi-id">{clientPlan.powerbiId}</td>
        </tr>;
    };

    const renderClientPlans = () => {
        return props.clientsAndPlans
            .map(renderClientPlan);
    };

    return <table className="admin-all-clients__table">
        <thead>
        <tr>
            <th>Client</th>
            <th>Plan</th>
            <th>Plan Country</th>
            <th>Plan Language</th>
            <th>PowerBi ID</th>
        </tr>
        </thead>
        <tbody>
        {renderClientPlans()}
        </tbody>
    </table>;
};