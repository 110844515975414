import * as React from "react";
import {useState} from "react";
import {INFORM_RATINGS} from "../../utils/ratingFormatUtil";
import {IInformSubComponent, InformSubcomponentType} from "../../model/inform/Inform";
import {IInformSubComponentFactor} from "../../model/inform/InformSubComponentDetails";
import {InformSubComponentRow} from "./InformSubComponentRow";
import {DataCompletionDonutChart} from "./DataCompletionDonutChart";
import {ITooltipProps, Tooltip} from "../common/Tooltip";
import {ProductCard} from "../product/ProductCard";

export interface IInformSubComponentProps {
    name: string;
    component: IInformSubComponent | undefined;
    reportingCurrency: string;
}

export const RATING_DESCRIPTION = ["3 - OK:  The component or factor in isolation meets our desired criteria.",
    "2 - Poor:  The component or factor in isolation falls short in meeting our desired criteria.",
    "1 - Very Poor:  The component or factor in isolation fails to meet our desired criteria."];

export const InformSubComponent: React.FunctionComponent<IInformSubComponentProps> = (props) => {
    const informRating = INFORM_RATINGS.find(x => x.rating === props.component?.summary?.rating?.toLowerCase());
    const [expandedRowIndex, setExpandedRowIndex] = useState<number | undefined>(undefined);

    function handleExpandedRowChange(value: number) {
        setExpandedRowIndex(expandedRowIndex === value ? undefined : value);
    }

    const ratingTooltip = () => {
        const tooltipProps: ITooltipProps = {
            iconClass: "inform__tooltip fal fa-info-circle",
            hoverText: RATING_DESCRIPTION,
            hoverTitle: "Ratings",
        };
        return <Tooltip {...tooltipProps}/>;
    };

    const renderDetailsTable = () => {
        return <table className="inform-sub-component__details-table">
            <thead className="inform-sub-component__details-table-header">
            <tr>
                <th className="inform-sub-component__details-table-header-cell-no-border">Factor</th>
                <th>Aon Rating {ratingTooltip()}</th>
                <th>Change</th>
                <th>Value</th>
                {props.component?.details?.hasThreshold && <th>Threshold</th>}
                <th className="inform-sub-component__details-table-header-cell border"/>
            </tr>
            </thead>
            {renderDetailsTableBody()}
        </table>;
    };

    const renderDetailsTableBody = () => {
        return <tbody>
        {
            props.component?.details?.data?.map((detail: IInformSubComponentFactor, index) => {
                return <InformSubComponentRow
                    key={"inform-sub-component-row-" + index}
                    index={index}
                    detail={detail}
                    component={props.component}
                    hasThreshold={props.component?.details?.hasThreshold}
                    expandedRowIndex = {expandedRowIndex}
                    setExpandedRowIndex = {handleExpandedRowChange}
                    reportingCurrency={props.reportingCurrency}
                />;
            })
        }
        </tbody>;
    };

    const renderDetails = () => {
        return <div className="inform-sub-component__details" data-testid="inform-sub-component__details">
            <div className="inform-sub-component__details__header" data-testid="inform-sub-component__details__header">
                <div className="inform-sub-component__details__header-name">Details</div>
            </div>
            <div className="inform-sub-component__details__body">
                {renderDetailsTable()}
            </div>
        </div>;
    };

    const renderSummary = () => {
        const upgradeFactor = props.component?.type === InformSubcomponentType.ESG
            ? "Acceptable Responses"
            : "Upgraded Factors";
        const downGradeFactor = props.component?.type === InformSubcomponentType.ESG
            ? "Flagged Responses"
            : "Downgraded Factors";
        return <div className="inform-sub-component__summary" data-testid="inform-sub-component__summary">
            <div className="inform-sub-component__summary-rating-container">
                <div className="inform-sub-component__summary-header-name">Summary</div>
                <div className="inform-sub-component__summary-description-container">
                    <div className="inform-sub-component__summary-description-rating-change">
                        <p className="inform-sub-component__summary-rating-change">{props.component?.summary?.ratingChange}</p>
                        <p className="inform-sub-component__summary-description">{informRating?.ratingText}</p>
                    </div>
                    <div className="inform-sub-component__summary-description-rating-name">
                        <p className="inform-sub-component__summary-rating-text"
                           data-testid="inform-sub-component__summary-rating-text">
                            {props.name}
                        </p>
                        <div className="inform-sub-component__summary-arrow-block">
                            <span className="inform-sub-component__summary-rating-upgrade-downgrade-numbers"
                                  data-testid="inform-sub-component__summary-rating-numbers">
                                {props.component?.summary?.rating}
                            </span>
                        </div>
                    </div>
                    <div className="inform-sub-component__summary-body-factors-up">
                        <p className="inform-sub-component__summary-body-factors-up-text">{upgradeFactor}</p>
                        <div className="inform-sub-component__summary-arrow-block">
                            <span className="inform-sub-component__summary-rating-upgrade-downgrade-numbers"
                                  data-testid="inform-sub-component__summary-upgrade-numbers">
                                {props.component?.summary?.upgraded}
                            </span>
                            <i className={`fa fa-regular fa-arrow-up icon up ${informRating?.ratingClass} inform-sub-component__summary-arrow`}
                               data-testid="inform-sub-component__summary-body-factors-arrow-up"/>
                        </div>
                    </div>
                    <div className="inform-sub-component__summary-body-factors-down">
                        <p className="inform-sub-component__summary-body-factors-down-text">
                            {downGradeFactor}
                        </p>
                        <div className="inform-sub-component__summary-arrow-block">
                            <span className="inform-sub-component__summary-rating-upgrade-downgrade-numbers"
                                  data-testid="inform-sub-component__summary-downgrade-numbers">
                                {props.component?.summary?.downgraded}
                            </span>
                            <i className={`fa fa-regular fa-arrow-down icon down ${informRating?.ratingClass} inform-sub-component__summary-arrow`}
                               data-testid="inform-sub-component__summary-body-factors-arrow-down"/>
                        </div>
                    </div>
                </div>
            </div>
            <div className="inform-sub-component__summary-data-completion"
                 data-testid="inform-sub-component__summary-data-completion">
                <DataCompletionDonutChart completion={props.component?.summary?.dataCompletion}/>
            </div>
        </div>;
    };

    const renderCardContent = () => {
        return <div className="inform-sub-component__card-expand-container"
                    data-testid="inform-sub-component__card-expand-container">
            {props.component?.summary && renderSummary()}
            {props.component?.details && renderDetails()}
        </div>;
    };

    return <ProductCard
        name={props.name}
        rating={props.component?.summary?.rating}
        ratingClass={informRating?.ratingClass}>
        {renderCardContent()}
    </ProductCard>;
};