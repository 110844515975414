import {Map as ImmutableMap, OrderedMap, Set} from "immutable";
import {IHolding} from "../../../api/HoldingsApi";
import {isIE} from "../../../utils/browserUtil";
import {capitalizeFirstLetter} from "../../../utils/commonUtil";
import {
    compareDates,
    compareQuarterAndYear,
    compareQuarterAndYearString,
    formatQuarterAndYear,
    formatShortDate,
    getMonthAndYearString,
    parseShortDate,
} from "../../../utils/dateUtil";
import {compareInsensitive} from "../../../utils/listUtil";
import {formatCurrency} from "../../../utils/numberUtil";
import {
    allAssetClasses,
    allProducts, allTiers,
    defaultAssetClassTitle,
    defaultOverTimeTitle, defaultTierTitle,
    orderByName,
    orderByProductName, orderByTierName
} from "./CashFlows.component";
import {HoldingsGroupBy} from "../InvestmentsPage";
import {bySpecial, needSpecialSorting} from "../InvestmentsDataUtil";
import moment = require("moment");

export enum CashflowKeys {
   INFLOW = "inflow",
   OUTFLOW = "outflow",
   CASHFLOW = "cashflow",
}

export type ICashflow = Record<CashflowKeys, number>;

export interface ICashflowsProps {
    holdings: IHolding[];
}

export function cashflowGroupAndSortByDate(holdings: IHolding[], isAscending: boolean)
    : OrderedMap<string, ICashflow> {
    // spread into new array is for making a new copy of the array so we do not mutate the original
    // could also do holdings.slice() theoretically
    const orderedHoldings = [...holdings].sort((h1, h2) => compareDates(h1.asOfDate, h2.asOfDate));
    if (isAscending) {
        orderedHoldings.reverse();
    }

    return orderedHoldings.reduce((acc: OrderedMap<string, ICashflow>, holding) => {
        const key = formatShortDate(holding.asOfDate);

        return accumulator(key, holding, acc);
    }, OrderedMap<string, ICashflow>());
}

export function cashflowGroupAndSortByQuarter(holdings: IHolding[], isAscending: boolean)
    : OrderedMap<string, ICashflow> {
    const ordered = holdings.reduce((acc: ImmutableMap<string, ICashflow>, holding) => {
        const key = formatQuarterAndYear(holding.quarterAndYear);

        return accumulator(key, holding, acc);
    }, ImmutableMap<string, ICashflow>())
        .sortBy((_, key: string) => {
            const splitString = key.split(" ");
            return {quarter: splitString[0], year: Number.parseInt(splitString[1], 10)};
        }, compareQuarterAndYearString);

    return isAscending ? ordered.toOrderedMap() : ordered.reverse().toOrderedMap();
}

export function cashflowGroupByAssetClass(holdings: IHolding[]) {
    return holdings.reduce((acc: OrderedMap<string, ICashflow>, holding) => {
        const key = holding.assetClass;
        return accumulator(key, holding, acc);
    }, OrderedMap<string, ICashflow>());
}

export function cashflowGroupByTier(holdings: IHolding[]) {
    return holdings.reduce((acc: OrderedMap<string, ICashflow>, holding) => {
        const key = holding.tier;
        return accumulator(key, holding, acc);
    }, OrderedMap<string, ICashflow>());
}

export function cashflowGroupByProductName(holdings: IHolding[]) {
    return holdings.reduce((acc: OrderedMap<string, ICashflow>, holding) => {
        const key = holding.name;
        return accumulator(key, holding, acc);
    }, OrderedMap<string, ICashflow>());
}

export function cashflowSortByKeyAlpha(assetClasses: OrderedMap<string, ICashflow>)
    : OrderedMap<string, ICashflow> {
    return assetClasses
        .sortBy((_, key: string) => key, compareInsensitive)
        .toOrderedMap();
}

export function cashflowSortBySpecial(assetClasses: OrderedMap<string, ICashflow>)
    : OrderedMap<string, ICashflow> {
    return assetClasses
        .sortBy((_, key: string) => key, bySpecial)
        .toOrderedMap();
}

export function cashflowSortByCashflow(assetClasses: OrderedMap<string, ICashflow>)
    : OrderedMap<string, ICashflow> {
    return assetClasses
        .sortBy((value: ICashflow) => value.cashflow)
        .reverse()
        .toOrderedMap();
}

export function cashflowSortedByKey(holdings: IHolding[], selectedGroupName: string, selectedSort: string) {
    const selectedMap = selectedGroupName === allAssetClasses
        ? cashflowGroupByAssetClass(holdings)
        : (selectedGroupName === allTiers
            ? cashflowGroupByTier(holdings)
            : cashflowGroupByProductName(holdings));

    return selectedSort === orderByName || selectedSort === orderByProductName || selectedSort === orderByTierName
        ? (needSpecialSorting(selectedGroupName)
            ? cashflowSortBySpecial(selectedMap)
            : cashflowSortByKeyAlpha(selectedMap))
        : cashflowSortByCashflow(selectedMap);
}

export function getTitle(isOvertime: boolean, selectedGroupName: string, selectedProduct: string) {
    if (selectedGroupName === allAssetClasses) {
        return isOvertime ? defaultOverTimeTitle : defaultAssetClassTitle;
    }

    if (selectedGroupName === allTiers) {
        return isOvertime ? defaultOverTimeTitle : defaultTierTitle;
    }

    return isOvertime
    ? (selectedProduct === allProducts ? capitalizeFirstLetter(selectedGroupName) : selectedProduct)
        : capitalizeFirstLetter(selectedGroupName) + " by Product";
}

export function getCashflowCsvContent(cashflowsMap: OrderedMap<string, ICashflow>,
                                      isOvertime: boolean,
                                      isQuarterly: boolean,
                                      selectedAssetClass: string) {
    const headerRow = [
        getKeyColumnHeader(isOvertime, isQuarterly, selectedAssetClass),
        "Net Cashflow",
        "Inflow",
        "Outflow",
    ].join(",") + "\n";
    return headerRow + cashflowsMap.map((cashflow: ICashflow, key: string) => {
        return [
            formatKey(key, isOvertime, isQuarterly),
            formatCurrencyCsv(cashflow.cashflow),
            formatCurrencyCsv(cashflow.inflow),
            formatCurrencyCsv(cashflow.outflow),
        ].join(",");
    }).toArray().join("\n");
}

export function getProductsForGroup(holdings: IHolding[], groupName: string, groupBy: HoldingsGroupBy) {
    return groupBy === HoldingsGroupBy.BY_ASSET_CLASS
    ? getProductsForAssetClass(holdings, groupName)
        : getProductsForTier(holdings, groupName);
}

export function filterByGroupAndProduct(holdings: IHolding[], groupName: string, productName: string, groupBy: HoldingsGroupBy) {
    return filterByProduct(
        filterByGroup(holdings, groupName, groupBy),
        productName
    );
}

function filterByGroup(holdings: IHolding[], groupName: string, groupBy: HoldingsGroupBy) {
    if (holdings.length === 0 || groupName === allAssetClasses || groupName === allTiers) {
        return holdings;
    }

    return holdings.filter(({assetClass, tier}) =>
        groupBy === HoldingsGroupBy.BY_ASSET_CLASS
            ? assetClass.toUpperCase() === groupName.toUpperCase()
            : tier.toUpperCase() === groupName.toUpperCase());
}

function filterByProduct(holdings: IHolding[], productName: string) {
    if (holdings.length === 0 || productName === allProducts) {
        return holdings;
    }

    return holdings.filter(({name}) => name.toUpperCase() === productName.toUpperCase());
}

function getProductsForAssetClass(holdings: IHolding[], groupName: string) {
    return Set(holdings
        .filter((holding) => holding.assetClass.toUpperCase() === groupName.toUpperCase())
        .map((holding) => holding.name))
        .toArray()
        .sort((item1, item2) => compareInsensitive(item1, item2));
}

function getProductsForTier(holdings: IHolding[], groupName: string) {
    return Set(holdings
        .filter((holding) => holding.tier.toUpperCase() === groupName.toUpperCase())
        .map((holding) => holding.name))
        .toArray()
        .sort(getProductsSortingFunction(groupName, compareInsensitive));
}

const getProductsSortingFunction = (groupName: string,
                                    defaultSortingFn: (valueA: string, valueB: string) => number) => {
    return needSpecialSorting(groupName)
        ? bySpecial
        : defaultSortingFn;
};


const formatKey = (key: string, isOvertime: boolean, isQuarterly: boolean): string => {
    if (isIE()) {
        return (isOvertime && !isQuarterly) ?
            moment(parseShortDate(key)).format("MMM-YY")
            : capitalizeFirstLetter(key);
    }

    return (isOvertime && !isQuarterly)
        ?  getMonthAndYearString(parseShortDate(key))
        : capitalizeFirstLetter(key);
};

const getKeyColumnHeader = (isOvertime: boolean, isQuarterly: boolean, selectedAssetClass: string) => {
    return isOvertime
        ? (isQuarterly ? "Quarter" : "Month")
        : selectedAssetClass === allAssetClasses ? "Asset Class" : "Product Name";
};

const formatCurrencyCsv = (value: number) => {
    return  `\"${formatCurrency(value)}\"`;
};

const accumulator = (key: string, holding: IHolding, acc: OrderedMap<string, ICashflow>) => {
    if (acc.has(key)) {
        return acc.set(key, accumulateCashFlow(acc.get(key)!, holding));
    } else {
        return acc.set(key, accumulateCashFlow(null, holding));
    }
};

function accumulateCashFlow(cashFlow: ICashflow | null, holding: IHolding): ICashflow {
    const newCashFlow = cashFlow ? cashFlow : {inflow: 0, outflow: 0, cashflow: 0};
    newCashFlow.inflow = newCashFlow.inflow + getValue(holding.inflow);
    newCashFlow.outflow = newCashFlow.outflow + getValue(holding.outflow);
    newCashFlow.cashflow = newCashFlow.inflow + newCashFlow.outflow;
    return newCashFlow;
}

export function getValue(val: any) {
    return isNaN(val) ? 0 : val;
}

function getTwelveMonthsAgo(date: Date) {
    return new Date(date.getFullYear() - 1, date.getMonth() + 1, 1);
}

export function get12MostRecentMonthsOfHoldings(holdings: IHolding[]) {
    if (holdings.length === 0) {
        return holdings;
    }

    const maxDate = holdings.map((h) => h.asOfDate).sort(compareDates)[0];
    const minDate = getTwelveMonthsAgo(maxDate);

    return holdings.filter(({asOfDate}) => asOfDate <= maxDate && asOfDate >= minDate);
}

function filterOutPartialQuarter(holdings: IHolding[]) {
    const latestMonth = holdings.map((h) => h.asOfDate).sort(compareDates)[0].getMonth();

    if ((latestMonth + 1) % 3 === 0) {
        return holdings;
    }

    const latestQuarterAndYear = holdings.map((h) => h.quarterAndYear).sort(compareQuarterAndYear).reverse()[0];

    return holdings.filter((h) => !(h.quarterAndYear.year === latestQuarterAndYear.year
        && h.quarterAndYear.quarter === latestQuarterAndYear.quarter));
}

export function get4MostRecentQuartersOfHoldings(holdings: IHolding[]) {
    const holdingsFullQuarter = filterOutPartialQuarter(holdings);

    return get12MostRecentMonthsOfHoldings(holdingsFullQuarter);
}
