import * as React from "react";
import {WhitePaper} from "../../model/thought-leadership/WhitePaper.model";
import {groupItemsByYear} from "../../utils/listUtil";
import {convertMapToArray} from "../../utils/mapUtil";
import {WhitePaperDocumentsComponent} from "./WhitePaperDocuments.component";
import {FormattedMessage} from "react-intl";

interface IWhitePaperProps {
    whitePaperList: WhitePaper[];
}

export class WhitePapersComponent extends React.Component<IWhitePaperProps> {
    public render() {
        return (
            <div className="new-common-styles" data-testid="new-common-styles">
                <h1 className="white-papers__title">
                    <FormattedMessage id="white-papers.title" defaultMessage="White Papers" />
                </h1>
                <ul data-testid="white-papers__years-section">
                    {this.renderYearSections()}
                </ul>
            </div>
        );
    }

    private groupedWhitePapers(): any[][] {
        return convertMapToArray(groupItemsByYear(this.props.whitePaperList, (item) => item.date));
    }

    private renderYearSections(): any {
        return this.groupedWhitePapers()
            .map((yearToWhitePapers, yearIndex) => {
                const [year, documents] = yearToWhitePapers;
                return (
                    <li key={yearIndex}
                        className={`white-papers__section-content no-bullets white-papers__section-${year} new-common-styles`}>
                        <div className="white-papers__left">
                            <h2 className="white-papers__year gray">{year}</h2>
                        </div>
                        <div className="document-list" data-testid="document-list">
                            <WhitePaperDocumentsComponent documents={documents}/>
                        </div>
                    </li>
                );
            });
    }
}

const WhitePapers = WhitePapersComponent;

export default WhitePapers;
