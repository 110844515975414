import * as React from "react";
import {DialogComponent} from "../common/Dialog.component";

export interface IAuthRequiredModal{
    authRequiredModalOpen: boolean,
    setAuthRequiredModalOpen: (is: boolean) => void,
    triggerLogin: () => Promise<void>
}
export const AuthRequiredModal: React.FunctionComponent<IAuthRequiredModal> = (props)  => {
    const noHandler = () => {
        // props.setAuthRequiredModalOpen(false);
    };

    const yesHandler = () => {
        props.setAuthRequiredModalOpen(false);
        props.triggerLogin()
            // eslint-disable-next-line @typescript-eslint/no-empty-function
            .then(() => {});
    };

    return <DialogComponent
        title= "Session Timeout"
        open={props.authRequiredModalOpen}
        noText="Cancel"
        yesText="Login"
        onNo={noHandler}
        onYes={yesHandler}
        description="Click below to re-login to Aon Iris"
        hideNoButton={true}
    />;
};