import * as React from "react";
import {navigateTo} from "../../../navigateTo";
import {getBrowserLocation} from "../../api/BrowserWrapper";
import SubNav from "../base/SubNav.component";

export enum ThoughtLeadershipNavItems {
    WHITE_PAPERS = "white-papers",
    MARKET_OUTLOOKS = "market-outlooks",
}

export const ThoughtLeadershipNav: React.FunctionComponent = () => {

    const marketOutLooks = {
        id: ThoughtLeadershipNavItems.MARKET_OUTLOOKS,
        name: "Market Outlooks",
    };

    const whitePapers = {
        id: ThoughtLeadershipNavItems.WHITE_PAPERS,
        name: "White Papers",
    };

    function isSelected(id: string) {
        return getBrowserLocation()!.includes(`${id}`);
    }

    function getMenuItems() {
        return [
            whitePapers,
            marketOutLooks,
        ];
    }

    function getSelectedMenuItem() {
        return getMenuItems()
            .map((item) => item.id)
            .find(isSelected);
    }

    function selectNavItem(id: string) {
        const selectedItem = findMenuItem(id);
        navigateTo(`/${selectedItem.id}`);
    }

    function findMenuItem(id: string) {
        return getMenuItems().find((item) => item.id.toString() === id)!;
    }

    return <SubNav
        menuItems={getMenuItems()}
        searchEnabled={false}
        onItemSelected={selectNavItem}
        selectedMenuItem={getSelectedMenuItem()}
    />;
};
