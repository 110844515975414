import {Dispatch} from "redux";
import {Action} from "redux-actions";
import {PowerBiReportingApi} from "../../api/PowerBiReportingApi";
import {ClientReport} from "../../model/ClientReport.model";

export enum PowerBiReportingActions {
    CLIENT_REPORTS_REQUESTED = "CLIENT_REPORTS_REQUESTED",
    CLIENT_REPORTS_REQUEST_SUCCEEDED = "CLIENT_REPORTS_REQUEST_SUCCEEDED",
    CLIENT_REPORTS_REQUEST_FAILED = "CLIENT_REPORTS_REQUEST_FAILED",
    CLEAR_CLIENT_REPORTS = "CLEAR_CLIENT_REPORTS",
    SET_CURRENT_CATEGORY = "SET_CURRENT_CATEGORY",
    EXPORT_REPORT_REQUESTED = "EXPORT_REPORT_REQUESTED",
    EXPORT_REPORT_REQUEST_SUCCEEDED = "EXPORT_REPORT_REQUEST_SUCCEEDED",
    EXPORT_REPORT_REQUEST_FAILED = "EXPORT_REPORT_REQUEST_FAILED",
    EXPORT_REPORT_CLEAR = "EXPORT_REPORT_CLEAR",
    SET_EXPORT_REPORT_PERIOD = "SET_EXPORT_REPORT_PERIOD",
}

export function requestClientReports(dispatch: Dispatch<Action<ClientReport[]>>) {
    dispatch({type: PowerBiReportingActions.CLIENT_REPORTS_REQUESTED});

    return PowerBiReportingApi.requestClientReportsForCurrentPlan()
        .then((response) => {
            dispatch({
                type: PowerBiReportingActions.CLIENT_REPORTS_REQUEST_SUCCEEDED,
                payload: response,
            });
        }).catch((error) => {
            dispatch({
                type: PowerBiReportingActions.CLIENT_REPORTS_REQUEST_FAILED,
                payload: error,
            });
        });
}

export function clearClientReports() {
    return (dispatch: Dispatch) => {
        dispatch({
            type: PowerBiReportingActions.CLEAR_CLIENT_REPORTS,
        });
    };
}

export function setCurrentCategory(category: string) {
    return (dispatch: Dispatch) => {
        dispatch({
            type: PowerBiReportingActions.SET_CURRENT_CATEGORY,
            payload: category,
        });
    };
}

export function setExportReportPeriod(period: number) {
    return (dispatch: Dispatch) => {
        dispatch({
            type: PowerBiReportingActions.SET_EXPORT_REPORT_PERIOD,
            payload: period,
        });
    };
}

