import {Action, AnyAction, bindActionCreators, Dispatch, Middleware, MiddlewareAPI} from "redux";
import {getUserDetailsFromBackend} from "../../api/userDetailsApi";
import {UserDetails} from "../../model/UserDetails.model";
import {getSessionData, getUserLoggingOut} from "../../utils/sessionUtil";
import {createPersistUserSessionAction} from "../session/persistence/userSessionPersistenceActions";
import {createSetCurrentPlanSessionAction, createSetUserDetailsAction, requestPlans} from "../session/SessionActions";
import {UserSessionInitializerActions} from "./userSessionInitializerActions";
import {getOktaAuth} from "../../utils/oktaAuthCreator";

export const userSessionInitializer: Middleware =
    (api: MiddlewareAPI<Dispatch<AnyAction>>) =>
        (next: Dispatch<Action<any>>) =>
            async <A extends Action>(action: A) => {
                switch (action.type) {
                    case UserSessionInitializerActions.INITIALIZE_USER_SESSION:
                        await handleInitializeUserSession(api.dispatch)
                            .then(() => next(action))
                            .catch((reason) => { throw reason; });
                        break;
                }

                return next(action);
            };

function setUpUserDetails(dispatch: Dispatch<Action<string>>, userDetails: UserDetails) {
    dispatch(createSetUserDetailsAction(userDetails));
    dispatch(createPersistUserSessionAction(userDetails));
    bindActionCreators({requestPlans}, dispatch).requestPlans();
}

const handleInitializeUserSession = async (dispatch: Dispatch<Action<string>>) => {
    const oktaAuth = getOktaAuth();
    const isAuthenticated = oktaAuth.authStateManager.getAuthState()?.isAuthenticated;
    if (isAuthenticated && !getUserLoggingOut()) {
        const sessionData = getSessionData();
        const maybeToken = sessionData ? undefined : oktaAuth.getAccessToken();
        getUserDetailsFromBackend(maybeToken)
            .then((userDetails: UserDetails) => {
                setUpUserDetails(dispatch, userDetails);
                if(sessionData) {
                    dispatch(createSetCurrentPlanSessionAction(sessionData!.currentPlan));
                }
            })
            .catch((err) => {
                // console.error("=============> getUserDetailsFromBackend error: ", err);
            });
        }
};

