import * as React from "react";
import {HoldingsApi} from "../../../api/HoldingsApi";
import {defaultButtonStyle} from "../../common/buttonStyles";
import {RaisedButton} from "../../common/RaisedButton";
import {throwErrorOnNullOrUndefined} from "../../../utils/errorUtil";
import {IUploadComponentProps} from "../../admin/AdminUploadPage";

export interface IUploadHoldingsState {
    selectedFile?: File;
    uploadResult: string;
    value: any;
}

export class UploadHoldings extends React.Component<IUploadComponentProps, IUploadHoldingsState> {
    constructor(props: any) {
        super(props);
        this.state = {uploadResult: "", value: ""};
    }

    public render() {
        return <div>
            Load Holdings
            <input
                id="file-input"
                onChange={this.handleFileSelection}
                type="file"
                multiple={false}
                value={this.state.value}
                disabled={this.props.isLoading}
                data-testid={"file-input"}
            />

            <RaisedButton style={defaultButtonStyle}
                          primary={false}
                          onClick={() => this.uploadHoldings()}
                          disabled={this.props.isLoading || !this.state.selectedFile}>
                Upload
            </RaisedButton>
            {this.renderResult()}
        </div>;
    }

    private renderResult = () => {
        return <div className="upload-holdings-page__result" data-testid="upload-holdings-page__result">
            {this.state.uploadResult}
        </div>;

    };

    private uploadHoldings = () => {
        this.setState({uploadResult: ""});
        this.props.setIsLoading(true);
        const formData = new FormData();
        formData.append("file", throwErrorOnNullOrUndefined(this.state.selectedFile), this.state.selectedFile!.name);
        HoldingsApi.uploadHoldings(formData)
            .then(({ data, error }) => {
                this.setState({
                    uploadResult: `Upload succeeded: ${data && (!error)}`,
                    selectedFile: undefined,
                });
                this.clearFileInput();
                this.props.setIsLoading(false);
            })
            .catch((error) => {
                this.clearFileInput();
                this.props.setIsLoading(false);
                this.setState({
                    uploadResult:
                        `Upload failed: response: ${error.errorNumber} reason: ${error.message}`,
                    selectedFile: undefined,
                });
            });
    };

    private handleFileSelection = (e: { target: { files: FileList | null, value: any } }) => {
        if (e.target.files === null) {
            return;
        }
        this.setState({selectedFile: e.target.files[0], value: e.target.value});
    };

    private clearFileInput = () => {
        this.setState({value: ""});
    };

}
