import {Map as ImmutableMap} from "immutable";
import * as React from "react";
import {connect} from "react-redux";
import {Link} from "react-router-dom";
import {Action, AnyAction, bindActionCreators, Dispatch} from "redux";
import {ThunkAction} from "redux-thunk";
import {oddPreviews} from "../../../mainReducerMapSelectors";
import {IManagerResearchModel} from "../../model/manager/IManagerResearchModel";
import {IProductInfo} from "../../model/product/IProductInfo";
import {Product} from "../../model/product/Product.model";
import {getScrollOffset} from "../../utils/browserUtil";
import {ProductCard} from "./ProductCard.component";
import productStoreActions from "./productStoreActions";
import {getPageSize} from "./researchPageUtil";
import {IApplicationRootState} from "../../../applicationState";

export interface IManagerCardsPropsFromActions {
    actions: {
        requestProductPreview: (backstopId: number) => ThunkAction<Promise<void>, void, null, Action<void>>;
    };
}

export interface IManagerCardsPropsFromStore {
    productPreviews: ImmutableMap <number, Product>;
}

export interface IManagerCardsPropsFromParent {
    data: IManagerResearchModel[];
}

export interface IManagerCardsState {
    lastCardIndex: number;
    pageSize: number;
}

export type ManagerCardsProps = IManagerCardsPropsFromActions
    & IManagerCardsPropsFromStore
    & IManagerCardsPropsFromParent;

export class ManagerCardsComponent extends React.Component<ManagerCardsProps, IManagerCardsState> {
    private static isScrollingDown() {
        return (window.innerHeight + getScrollOffset()) >= (document.body.offsetHeight - 500);
    }

    constructor(props: ManagerCardsProps) {
        super(props);
        const pageSize = getPageSize();
        this.state = {
            lastCardIndex: pageSize,
            pageSize,
        };
    }

    public componentWillUnmount() {
        window.removeEventListener("scroll", this.onScroll, false);
    }

    public componentDidMount() {
        window.addEventListener("scroll", this.onScroll, false);
    }

    public render() {
        return <div className="updated-ratings-container"> {
            this.props.data.slice(0, this.state.lastCardIndex)
                .map((manager, index) => {
                    return this.renderManger(manager, index);
                })}
        </div>;
    }

    private renderManger(manager: IManagerResearchModel, index: number) {
        return <div className="manager-card-container" key={manager.name + index} data-testid="manager-card-container">
            <div className="manager-card">
                <div className="paper-style">
                    <Link to={`/managers/${manager.managerBackstopId}`}
                          className="manager-card__header old-anchor card--hover">
                        <div className="manager-card__title clickable title-xs" data-testid="manager-card__title">
                            {manager.name}
                        </div>
                        <div className="manager-card__forward-arrow fa-regular fa-arrow-right"/>
                    </Link>
                    <div>
                        {this.buildProductCards(manager)}
                    </div>
                </div>
            </div>
        </div>;
    }

    private buildProductCards(manager: IManagerResearchModel): any {
        return manager.products
            .map((productInfo: IProductInfo) => this.buildIndividualCard(productInfo));
    }

    private buildIndividualCard(summary: IProductInfo) {
        const detail = this.props.productPreviews
            .filter((product) => product!.backstopId === summary.backstopId).first();

        return <ProductCard
            key={summary.backstopId}
            productSummary={summary}
            product={detail !== undefined ? detail : null}
            productExpandedCallback={this.productExpandedCallback}/>;
    }

    private productExpandedCallback = (productBackstopId: number) => {
        this.props.actions.requestProductPreview(productBackstopId);
    };

    private onScroll = () => {
        if (ManagerCardsComponent.isScrollingDown() && (this.state.lastCardIndex < this.props.data.length)) {
            let upperLimit = this.state.lastCardIndex + this.state.pageSize;
            if (upperLimit > this.props.data.length) {
                upperLimit = this.props.data.length;
            }

            this.setState({lastCardIndex: upperLimit});
        }
    };
}

export const mapStateToProps = (state: IApplicationRootState): IManagerCardsPropsFromStore => {
    return {
        productPreviews: oddPreviews(state),
    };
};

export const mapDispatchToProps = (dispatch: Dispatch<AnyAction>) => {
    return {
        actions: bindActionCreators({
                requestProductPreview: productStoreActions.requestProductPreview,
            },
            dispatch),
    };
};

const connector = connect<IManagerCardsPropsFromStore, IManagerCardsPropsFromActions, IManagerCardsPropsFromParent>(
    mapStateToProps,
    mapDispatchToProps,
);

const ManagerCards = connector(ManagerCardsComponent);

export default ManagerCards;
