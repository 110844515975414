import * as React from "react";
import {Link} from "react-router-dom";

interface TrackedLinkProps {
    to: string;
    className: string;
    gaCategory: string;
    gaAction: string;
    gaLabel: string;
    children?: React.ReactNode;
}

export const TrackedLink: React.FunctionComponent<TrackedLinkProps> = (props) => {
    return <Link to={props.to} className={props.className} data-testid="tracked-link">
        {props.children}
    </Link>;
};
