import {UserTypeEnum} from "./UserInfo.model";

export class CalendarMeetingAttendee {
    public static builder(): CalendarMeetingAttendeeBuilder {
        return new CalendarMeetingAttendeeBuilder();
    }

    constructor(
        public firstName: string,
        public lastName: string,
        public userType: UserTypeEnum,
        public email: string,
        public id?: number,) {}
}

class CalendarMeetingAttendeeBuilder {
    private mFirstName: string;
    private mLastName: string;
    private mEmail: string;
    private mUserType: UserTypeEnum;
    private mId: number;

    public firstName(value: string) {
        this.mFirstName = value;
        return this;
    }

    public lastName(value: string) {
        this.mLastName = value;
        return this;
    }

    public email(value: string) {
        this.mEmail = value;
        return this;
    }

    public userType(value: UserTypeEnum) {
        this.mUserType = value;
        return this;
    }

    public id(value: number) {
        this.mId = value;
        return this;
    }

    public build() {
        return new CalendarMeetingAttendee(
            this.mFirstName || "firstName",
            this.mLastName || "lastName",
            this.mUserType || UserTypeEnum.UNKNOWN,
            this.mEmail || "email",
            this.mId || -1,
        );
    }
}
