import {Action, handleActions} from "redux-actions";
import {ApiError} from "../../model/ApiError.model";
import {MeetingNoteDetail} from "../../model/MeetingNoteDetail.model";
import {MeetingNoteSummary} from "../../model/MeetingNoteSummary.model";
import {throwErrorOnNullOrUndefined} from "../../utils/errorUtil";
import {MeetingNoteActions} from "./meetingNoteActions";

export interface IMeetingNoteState {
    readonly meetingNoteDetail: MeetingNoteDetail | null;
    readonly error?: ApiError;
}

export const initialState: IMeetingNoteState = {
    meetingNoteDetail: null,
};

const meetingNoteDetailReceived = (state: IMeetingNoteState,
                                   {payload}: Action<MeetingNoteDetail>): IMeetingNoteState => {
    return {
        ...state,
        meetingNoteDetail: throwErrorOnNullOrUndefined(payload, "action.payload"),
        error: undefined,
    };
};

const errorMeetingNoteDetail = (state: IMeetingNoteState,
                                {payload}: Action<ApiError>): IMeetingNoteState => {
    return {
        ...state,
        error: payload,
    };
};

const meetingNoteDetailRequested = (state: IMeetingNoteState): IMeetingNoteState => {
    return {
        ...state,
        meetingNoteDetail: null,
        error: undefined,
    };
};

const reducerMap = {
    [MeetingNoteActions.MEETING_NOTE_DETAIL_RECEIVED]: meetingNoteDetailReceived,
    [MeetingNoteActions.ERROR_MEETING_NOTE_DETAIL]: errorMeetingNoteDetail,
    [MeetingNoteActions.MEETING_NOTE_DETAIL_REQUESTED]: meetingNoteDetailRequested,
};

type Payload = MeetingNoteDetail | MeetingNoteSummary[] | ApiError;
export default handleActions<IMeetingNoteState, Payload>(reducerMap, initialState);
