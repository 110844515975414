import {Action, handleActions} from "redux-actions";
import {ClientsAndPlans} from "../../model/ClientsAndPlans.model";
import {IUserInfo} from "../../model/UserInfo.model";
import {NOT_REQUESTED, REQUEST_FAILED, REQUEST_SUCCEEDED, REQUESTED, RequestState} from "../common/commonStates";
import {ISelectValue} from "../common/Select.component";
import {AdminActions} from "./adminActions";

export enum UpdateStatus {
    COMPLETED = "COMPLETED",
    ERRORED = "ERRORED",
}

export interface IAdminState {
    adminUsersInfoState: RequestState<IUserInfo[]>;
    adminClientInfoState: RequestState<ClientsAndPlans>;
    adminUpdateUserState?: UpdateStatus;
    currentClient?: ISelectValue;
}

export const initialState: IAdminState = {
    adminUsersInfoState: NOT_REQUESTED,
    adminClientInfoState: NOT_REQUESTED,
};

const reducerMap = {
    [AdminActions.GET_ALL_USER_EDIT_DETAILS]:
        (state: IAdminState): IAdminState => {
            return {...state, adminUsersInfoState: REQUESTED};
    },
    [AdminActions.SET_ALL_USER_EDIT_DETAILS]:
        (state: IAdminState, action: Action<IUserInfo[]>): IAdminState => {
            return {...state, adminUsersInfoState: REQUEST_SUCCEEDED(action.payload!)};
    },
    [AdminActions.FAILED_GET_ALL_USER_EDIT_DETAILS]:
        (state: IAdminState, action: Action<any>): IAdminState => {
            return {...state, adminUsersInfoState: REQUEST_FAILED(action.payload!)};
    },
    [AdminActions.GET_ALL_CLIENTS_AND_PLANS]:
        (state: IAdminState): IAdminState => {
            return {...state, adminClientInfoState: REQUESTED};
    },
    [AdminActions.FAILED_GET_ALL_CLIENTS_AND_PLANS]:
        (state: IAdminState, action: Action<any>): IAdminState => {
            return {...state, adminClientInfoState: REQUEST_FAILED(action.payload!)};
        },
    [AdminActions.SET_ALL_CLIENTS_AND_PLANS]:
        (state: IAdminState, action: Action<ClientsAndPlans>): IAdminState => {
            return {...state, adminClientInfoState: REQUEST_SUCCEEDED(action.payload!)};
        },
    [AdminActions.UPDATE_USER_FAILED]:
        (state: IAdminState): IAdminState => {
            return {...state, adminUpdateUserState: UpdateStatus.ERRORED};
    },
    [AdminActions.UPDATE_USER_SUCCESS]:
        (state: IAdminState): IAdminState => {
            return {...state,
                adminUpdateUserState: UpdateStatus.COMPLETED,
                adminUsersInfoState: NOT_REQUESTED};
        },
    [AdminActions.CLEAR_USER_LIST]:
        (state: IAdminState): IAdminState => {
            return {...state,
                adminUsersInfoState: NOT_REQUESTED,
            };
        },
    [AdminActions.CLEAR_CLIENTS_AND_PLANS]:
        (state: IAdminState): IAdminState => {
            return {...state,
                adminClientInfoState: NOT_REQUESTED,
            };
        },
    [AdminActions.SET_CURRENT_CLIENT]:
        (state: IAdminState, action: Action<ISelectValue | undefined>): IAdminState => {
            return {...state,
                currentClient: action.payload,
            };
        },
};

export const adminUsersInfoStateSelectorInner = (state?: IAdminState): RequestState<IUserInfo[]> => {
    return state ? state.adminUsersInfoState : NOT_REQUESTED;
};

export const adminClientsInfoStateSelectorInner = (state?: IAdminState): RequestState<ClientsAndPlans> => {
    return state ? state.adminClientInfoState : NOT_REQUESTED;
};

export const adminCurrentClientInner = (state?: IAdminState): ISelectValue | undefined => {
    return state ? state.currentClient : undefined;
};

type adminActionTypes = IUserInfo[] | ClientsAndPlans | ISelectValue | undefined;

export default handleActions<IAdminState, adminActionTypes>(reducerMap, initialState);
